import React from "react";
import Collapse from "react-bootstrap/Collapse";
import CollapseButton from "../common/CollapseButton";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import AddTextItem from "../project/PDFPages/AddTextItem";
import { Row, Card, Col, Button, CardFooter, FormSelect } from "shards-react";
import Dropzone from "react-dropzone";
import EnvironmentForm from "./EnvironmentForm";

function RoomForm({
  onChange,
  activeStep,
  enterprise,
  questions,
  room,
  isLoading,
  handleDrop,
}) {
  function onBlueprintDeleteClick() {
    console.log("deletes");
  }

  const uploadImage = (file) => {
    handleDrop([file.target.files[0]]).then((responses) => {
      room.img = responses[0].link;
      onChange({ ...enterprise }, activeStep);
    });
  };

  return (
    <div
      style={{
        border: "1px dashed",
        padding: "10px",
      }}
      className="mb-3"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <CollapseButton
          collapsed={room.open}
          onClick={() => {
            room.open = !room.open;
            onChange({ ...enterprise }, activeStep);
          }}
        />
        <div className="mr-1" style={{ fontWeight: "300" }}>
          Nome:{" "}
        </div>
        <strong>{room.name}</strong>
      </div>

      <Collapse in={room.open} timeout={0}>
        <div
          style={{
            marginLeft: "60px ",
          }}
          id="example-collapse-texts"
        >
          <Row>
            <Col>
              <h6 className="text-center m-0 mt-2">Foto da planta</h6>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="custom-file mb-3">
                <input
                  type="file"
                  className="custom-file-input"
                  id="logo"
                  onChange={(e) => uploadImage(e)}
                />
                <label className="custom-file-label" htmlFor="customFile">
                  Enviar foto da planta
                </label>
              </div>
              {room.img && (
                <div>
                  <img src={room.img} alt="logo" style={{ width: "300px" }} />
                </div>
              )}
            </Col>
          </Row>

          <hr className="w-100" />

          <Row>
            <Col>
              <h6 className="text-center m-0 mt-2">Arquivos da planta</h6>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Dropzone
                onDrop={(files) =>
                  handleDrop(files).then((responses) => {
                    responses.forEach((res) => {
                      if (room.bluePrints) {
                        room.bluePrints.push(res);
                      } else {
                        room.bluePrints = [res];
                      }
                    });
                    onChange({ ...enterprise }, activeStep);
                    if (enterprise._id) {
                      //save enterprise
                    }
                    toast.success("Upload feito com sucesso", {
                      autoClose: 5000,
                    });
                  })
                }
              >
                {({ getRootProps, getInputProps }) => {
                  return (
                    <div
                      {...getRootProps()}
                      className="dropzone--isActive dropzone"
                    >
                      <input {...getInputProps()} />
                      <p className="m-0">
                        {!isLoading &&
                          "Tente soltar alguns arquivos aqui ou clique para selecionar os arquivos a serem enviados."}
                        {isLoading && <Loader type="Rings" color="#0a775a" />}
                      </p>
                    </div>
                  );
                }}
              </Dropzone>
            </Col>

            <Col xs="12">
              {room.bluePrints && room.bluePrints.length ? (
                <div className="p-4 mb-3">
                  <Row>
                    <Col>
                      <h6 className="text-center m-0 mt-2">Arquivos</h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div
                        className="d-flex"
                        style={{ "flex-flow": "row wrap" }}
                      >
                        {room.bluePrints.map((bluePrint, idx) => (
                          <div
                            key={idx}
                            className="mx-2 mt-5"
                            style={{
                              height: "200px",
                              width: "200px",
                            }}
                          >
                            <Card
                              className="ml-2 mr-2"
                              large
                              style={{
                                height: "100%",
                                width: "100%",
                                justifyContent: "center",
                              }}
                            >
                              <CardFooter>
                                <h6>{bluePrint.name}</h6>
                              </CardFooter>
                            </Card>
                            <Button
                              className="w-100 mx-2"
                              outline
                              theme="danger"
                              onClick={onBlueprintDeleteClick}
                            >
                              Excluir
                            </Button>
                          </div>
                        ))}
                      </div>
                    </Col>
                  </Row>
                </div>
              ) : (
                <div
                  className="text-center m-3"
                  style={{
                    fontSize: "40px",
                    textShadow: "-1px 1px 0px #fff, 0px -1px #848484",
                    color: "#d4d4d4",
                  }}
                >
                  Nenhum arquivo enviado
                </div>
              )}
            </Col>

            <Col xs="12" className="mt-3">
              <hr className="w-100" />
              <h4 className="text-center">Ambientes</h4>
            </Col>
            <Col xs="12">
              <ol>
                {room.environments.map((environment, envIndex) => {
                  return (
                    <li
                      style={{ border: "1px dashed" }}
                      className="p-3 mt-2"
                      key={envIndex}
                    >
                      <EnvironmentForm
                        onChange={onChange}
                        room={room}
                        environmentIndex={envIndex}
                        activeStep={activeStep}
                        enterprise={enterprise}
                        environment={environment}
                        questions={questions}
                      />
                    </li>
                  );
                })}
              </ol>
            </Col>
            <Col xs="12">
              <Row className="d-flex align-items-center justify-content-md-center">
                <Col xs="5" className="mt-3">
                  <AddTextItem
                    onAdd={(name) => {
                      room.environments.push({
                        name,
                        hash: "",
                        variations: [],
                        refRooms: [],
                      });
                      onChange({ ...enterprise }, activeStep);
                    }}
                    placeholder="Digite o nome do cômodo..."
                  />
                </Col>
                <Col
                  md="auto"
                  className="d-flex align-items-center justify-content-center"
                >
                  <span>
                    <strong>OU</strong>
                  </span>
                </Col>

                <Col xs="5">
                  <FormSelect
                    style={{
                      height: "fit-content",
                    }}
                    value={""}
                    defaultValue=""
                    onChange={(ev) => {
                      if (ev.target.value) {
                        const [roomName, environmentName] =
                          ev.target.value.split(" - ");
                        const chosenRoom = enterprise.rooms.find(
                          (r) => r.name === roomName
                        );
                        const chosenEnvironment = chosenRoom.environments.find(
                          (e) => e.name === environmentName
                        );

                        if (
                          !room.environments.find(
                            (e) => e.name === chosenEnvironment.name
                          )
                        ) {
                          if (chosenEnvironment.refRooms) {
                            if (
                              !chosenEnvironment.refRooms.find(
                                (r) => r === room.name
                              )
                            ) {
                              chosenEnvironment.refRooms = [
                                ...chosenEnvironment.refRooms,
                                room.name,
                              ];
                            }
                          } else {
                            chosenEnvironment.refRooms = [room.name];
                          }
                          room.environments.push({
                            ...chosenEnvironment,
                            refRooms: [],
                            ref: {
                              room: roomName,
                              environment: environmentName,
                            },
                          });
                          onChange({ ...enterprise }, activeStep);
                        }
                      }
                    }}
                  >
                    <option value="" disabled>
                      Escolha o cômodo...
                    </option>
                    {enterprise.rooms.reduce((a, r) => {
                      if (r.name === room.name || r.ref) {
                        return a;
                      }
                      const options = r.environments.reduce((acc, env) => {
                        acc.push(
                          <option
                            value={`${r.name} - ${env.name}`}
                          >{`${r.name} - ${env.name}`}</option>
                        );
                        return acc;
                      }, []);
                      a = a.concat(options);
                      return a;
                    }, [])}
                  </FormSelect>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Collapse>
    </div>
  );
}

RoomForm.propTypes = {};

export default RoomForm;

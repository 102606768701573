import React, { useState } from "react";
import { Row, Card, Col, CardBody, Button } from "shards-react";
import { sendFilesDropBox } from "../../actions/projects";
import { toast } from "react-toastify";
import DropzoneCustomer from "./DropzoneCustomer";
import Viewer from "./PDFPages/Viewer";
import { YourSpaceOrInspiration } from "../common/Environments";
import { BUCKET_URL } from "./../../config";

const LearningEnvironment = ({
  learningEnvironment,
  id,
  sendLightbox,
  onLearningEnvironmentChanged,
}) => {
  const [link, setLink] = useState("");

  const downloadFiles = () => {
    learningEnvironment.files.map((img) => {
      window.open(BUCKET_URL + "/projects/" + id + "/customer/" + img.source);
    });
  };

  const sendFilesDrop = (id) => {
    sendFilesDropBox(id).then((res) => {
      toast.success(res.msg, { autoClose: 5000 });
    });
  };

  return (
    <Card small className="mb-4">
      <CardBody className="p-0">
        <Row>
          <Col>
            <h6 className="text-center m-0 mt-2">Conhecendo seu espaço</h6>
            <div className="user-details__user-data border-top border-bottom p-4">
              <DropzoneCustomer
                projectId={id}
                folder={"customer"}
                step={learningEnvironment}
                stepName={"learningEnvironment"}
                onLearningEnvironmentChanged={onLearningEnvironmentChanged}
              />
              <Row>
                <Col>
                  <Button onClick={() => downloadFiles()}>
                    Baixar arquivos
                  </Button>
                  <Button
                    onClick={() => sendFilesDrop(id)}
                    style={{ marginLeft: "12px" }}
                  >
                    Arquivos Drop
                  </Button>
                </Col>
              </Row>
              <YourSpaceOrInspiration
                data={learningEnvironment}
                sendLightbox={sendLightbox}
                setLink={setLink}
                id={id}
              />
            </div>
          </Col>
        </Row>
      </CardBody>
      <Viewer
        link={link}
        toggle={() => {
          if (link) setLink("");
        }}
      />
    </Card>
  );
};

export default LearningEnvironment;

import React from "react";
import PropTypes from "prop-types";
import StepContainer from "./StepContainer";
import StepIcon from "./StepIcon";
import { Button } from "shards-react";
import StepDescription from "./StepDescription";
const DoneIcon = (
  <i className="material-icons" style={{ color: "green", fontSize: "25px" }}>
    done
  </i>
);

const CorrectedSteps = ({ steps }) => {
  return (
    <>
      {steps.map((step, index) => {
        return (
          <StepContainer key={`${index}-${step.key}`}>
            <StepIcon>{DoneIcon}</StepIcon>
            <StepDescription text={step.description} />
            <div className="ml-auto">
              <Button
                outline
                disabled
                theme="success"
                className="ml-auto"
                size="sm"
              >
                Concluído
              </Button>
            </div>
          </StepContainer>
        );
      })}
    </>
  );
};

CorrectedSteps.propTypes = {
  steps: PropTypes.array.isRequired,
};

export default CorrectedSteps;

import React from "react";
import { Row, Card, Col, CardBody } from "shards-react";
import Moment from "react-moment";

const LogDates = ({ logDates }) => {
  return (
    <Card small className="mb-4">
      <CardBody className="p-0">
        <Row>
          <Col>
            <h6 className="text-center m-0 mt-2">Resumo</h6>
          </Col>
        </Row>
        <Row>
          <Col md="2" />
          <Col md="8">
            <Row className={"border-top border-bottom"}>
              <Col md="7">Status</Col>
              <Col md="5">Data</Col>
            </Row>
            <Row className={"border-top border-bottom pb-1 pt-1"}>
              <Col md="7">Enviado para aprovação</Col>
              <Col md="5">
                {logDates && logDates.submitted ? (
                  <Moment format="DD/MM/YYYY HH:mm">
                    {logDates.submitted.date}
                  </Moment>
                ) : (
                  "Não encontrado"
                )}
              </Col>
            </Row>
            {/* ------------------ */}
            <Row className={"border-top border-bottom pb-1 pt-1"}>
              <Col md="7">Aprovado</Col>
              <Col md="5">
                {logDates && logDates.approved ? (
                  <Moment format="DD/MM/YYYY HH:mm">
                    {logDates.approved.date}
                  </Moment>
                ) : (
                  "Não encontrado"
                )}
              </Col>
            </Row>
            {/* ------------------ */}
            <Row className={"border-top border-bottom pb-1 pt-1"}>
              <Col md="7">Aguardando qualidade</Col>
              <Col md="5">
                {logDates && logDates.waiting_quality ? (
                  <Moment format="DD/MM/YYYY HH:mm">
                    {logDates.waiting_quality.date}
                  </Moment>
                ) : (
                  "Não encontrado"
                )}
              </Col>
            </Row>
            {/* ------------------ */}
            <Row className={"border-top border-bottom pb-1 pt-1"}>
              <Col md="7">Revisão Qualidade</Col>
              <Col md="5">
                {logDates && logDates.reviewing_quality ? (
                  <Moment format="DD/MM/YYYY HH:mm">
                    {logDates.reviewing_quality.date}
                  </Moment>
                ) : (
                  "Não encontrado"
                )}
              </Col>
            </Row>
            {/* ------------------ */}
            <Row className={"border-top border-bottom pb-1 pt-1"}>
              <Col md="7">Aguardando resposta cliente</Col>
              <Col md="5">
                {logDates && logDates.waiting_user_feedback ? (
                  <Moment format="DD/MM/YYYY HH:mm">
                    {logDates.waiting_user_feedback.date}
                  </Moment>
                ) : (
                  "Não encontrado"
                )}
              </Col>
            </Row>
            {/* ------------------ */}
            <Row className={"border-top border-bottom pb-1 pt-1"}>
              <Col md="7">Em Produção</Col>
              <Col md="5">
                {logDates && logDates.customer_reproved ? (
                  <Moment format="DD/MM/YYYY HH:mm">
                    {logDates.customer_reproved.date}
                  </Moment>
                ) : (
                  "Não encontrado"
                )}
              </Col>
            </Row>
            {/* ------------------ */}
            <Row className={"border-top border-bottom pb-1 pt-1"}>
              <Col md="7">Proposta Inicial</Col>
              <Col md="5">
                {logDates && logDates.preview ? (
                  <Moment format="DD/MM/YYYY HH:mm">
                    {logDates.preview.date}
                  </Moment>
                ) : (
                  "Não encontrado"
                )}
              </Col>
            </Row>
            {/* ------------------ */}
            <Row className={"border-top border-bottom pb-1 pt-1"}>
              <Col md="7">1º Revisão</Col>
              <Col md="5">
                {logDates && logDates.first_review ? (
                  <Moment format="DD/MM/YYYY HH:mm">
                    {logDates.first_review.date}
                  </Moment>
                ) : (
                  "Não encontrado"
                )}
              </Col>
            </Row>
            {/* ------------------ */}
            <Row className={"border-top border-bottom pb-1 pt-1"}>
              <Col md="7">2º Revisão</Col>
              <Col md="5">
                {logDates && logDates.second_review ? (
                  <Moment format="DD/MM/YYYY HH:mm">
                    {logDates.second_review.date}
                  </Moment>
                ) : (
                  "Não encontrado"
                )}
              </Col>
            </Row>
            {/* ------------------ */}
            <Row className={"border-top border-bottom pb-1 pt-1"}>
              <Col md="7">Projeto Aprovado</Col>
              <Col md="5">
                {logDates && logDates.waiting_render_approved ? (
                  <Moment format="DD/MM/YYYY HH:mm">
                    {logDates.waiting_render_approved.date}
                  </Moment>
                ) : (
                  "Não encontrado"
                )}
              </Col>
            </Row>
            {/* ------------------ */}
            <Row className={"border-top border-bottom pb-1 pt-1"}>
              <Col md="7"> Solicitação de revisão</Col>
              <Col md="5">
                {logDates && logDates.waiting_confirm_review ? (
                  <Moment format="DD/MM/YYYY HH:mm">
                    {logDates.waiting_confirm_review.date}
                  </Moment>
                ) : (
                  "Não encontrado"
                )}
              </Col>
            </Row>
            {/* ------------------ */}
            <Row className={"border-top border-bottom pb-1 pt-1"}>
              <Col md="7">Projeto Final</Col>
              <Col md="5">
                {logDates && logDates.final_project ? (
                  <Moment format="DD/MM/YYYY HH:mm">
                    {logDates.final_project.date}
                  </Moment>
                ) : (
                  "Não encontrado"
                )}
              </Col>
            </Row>
            {/* ------------------ */}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default LogDates;

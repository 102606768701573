import React, { useState } from "react";

import { createContext } from "react";
import FuzzySearch from "fuzzy-search";
import { debounce } from "../../utils/throttle";
import { sanitizeString, getDescendantProp } from "../../utils/sanitize";

const composePipe =
  (...fns) =>
  (x) =>
    fns.reduce((v, f) => f(v), x);

export const TableContext = createContext({});

export const TableProvider = ({ children, value }) => {
  const { furnitures, searchableColumns, customFilters, pageSize } = value;

  const filterInitialValues = Object.keys(customFilters).reduce((acc, cur) => {
    if (customFilters[cur].value !== undefined) {
      acc[customFilters[cur].name] = { value: customFilters[cur].value };
    }
    return acc;
  }, {});

  const [filter, setFilter] = useState({
    search: {
      value: "",
    },
    pageSize: {
      value: pageSize,
    },
    page: {
      value: 0,
    },
    ...filterInitialValues,
  });
  const filterData = composePipe(
    searchFilter,
    ...customFilters.map((customFilter) => (data) => {
      if (filter[customFilter.name] && filter[customFilter.name].value) {
        return customFilter.onUpdate(filter[customFilter.name].value, data);
      }
      return data;
    })
  );

  const updateFiltersWithDebounce = debounce(function (filter) {
    setFilter({ ...filter });
  }, 800);

  function onFilterUpdate(name, value) {
    filter[name] = { value };
    if (name !== "page") {
      filter.page.value = 0;
    }
    if (name === "search") {
      updateFiltersWithDebounce(filter, filterData(furnitures));
    } else {
      setFilter({ ...filter });
    }
  }

  function searchFilter(data) {
    // data = data.map((d) => {
    //   d.name = d.name + "";
    //   d.sku = d.sku + "";
    //   return d;
    // });
    if (searchableColumns) {
      let searcher = new FuzzySearch(
        data,
        searchableColumns.map((column) => `searchables.${column}`),
        {
          caseSensitive: false,
          sort: true,
        }
      );
      if (filter.search.value)
        data = searcher.search(sanitizeString(filter.search.value));
    }

    return data;
  }

  function getFilteredData(data) {
    const teste = filterData(
      searchableColumns
        ? data.map((item) => {
            item.searchables = {};
            searchableColumns.forEach((column) => {
              item.searchables[column] = sanitizeString(
                getDescendantProp(item, column)
              );
            });
            return item;
          })
        : data
    );
    return teste;
  }

  return (
    <TableContext.Provider
      value={{
        data: getFilteredData(furnitures),
        filter: filter,
        isSearchable: !!searchableColumns,
        onFilterUpdate: onFilterUpdate,
      }}
    >
      {children}
    </TableContext.Provider>
  );
};

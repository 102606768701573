import React from "react";
import DefaultPageItem from "./DefaultPageItem";

function DefaultPageItems(props) {
  const { items } = props;
  return (
    items.length &&
    items.map((item, idx) => {
      return <DefaultPageItem {...props} key={idx} item={item} />;
    })
  );
}

DefaultPageItems.propTypes = {};

export default DefaultPageItems;

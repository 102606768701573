import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Button } from "shards-react";

function ButtonPictureUpload({
  value,
  onChange,
  placeholder,
  validate,
  onlyPictures,
}) {
  const fileInput = useRef(null);
  const imgRef = useRef();
  const [imgSource, setImgSource] = useState("");
  const [imgReader] = useState(new FileReader());

  useEffect(() => {
    imgReader.addEventListener(
      "load",
      function () {
        imgRef.current.src = imgReader.result;
        setImgSource(imgReader.result);
      },
      false
    );
  }, []);

  useEffect(() => {
    setImgSource(imgSource || value || "");
  }, [value, imgSource]);

  function onFileSelect(ev) {
    const file = ev.target.files[0];

    if (
      onlyPictures &&
      !file.type.toLowerCase().match("(.jpeg|.png|.jpg|.jfif|.heic)")
    ) {
      throw new Error("Not a valid favicon");
    }

    if (file && (!validate || validate(file))) {
      imgReader.readAsDataURL(file);
      onChange(file, file.name);
    }
  }

  function onUploadPictureButtonClick() {
    fileInput.current && fileInput.current.click();
  }
  return (
    <div className="d-flex align-items-end">
      <div className="d-flex align-items-center flex-column">
        <div className="edit-user-details__avatar mx-auto">
          <img
            className={`${imgSource ? "" : "hidden"}`}
            ref={imgRef}
            width="120"
            src={imgSource}
            alt=""
          />

          <label className="edit-user-details__avatar__change">
            <i className="material-icons mr-1">&#xE439;</i>
            <input type="file" className="d-none" onChange={onFileSelect} />
          </label>
        </div>
        <div className="d-flex flex-row  align-items-center">
          <input
            type="file"
            ref={fileInput}
            className="d-none"
            onChange={onFileSelect}
          />
          {/*TODO Add load properties */}
          <Button
            size="sm"
            theme="primary"
            onClick={onUploadPictureButtonClick}
            className="mx-auto mt-4 d-block"
          >
            <i className="material-icons">&#xE2C3;</i>
            Enviar foto
          </Button>{" "}
        </div>
      </div>
      {!imgSource ? (
        <span className="ml-2">
          <i>{placeholder}</i>
        </span>
      ) : null}
    </div>
  );
}

ButtonPictureUpload.propTypes = {
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  validate: PropTypes.func,
  onlyPictures: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

ButtonPictureUpload.defaultProps = {
  placeholder: "Nenhuma foto selecionada.",
  required: false,
  onlyPictures: true,
};

export default ButtonPictureUpload;

import { API } from "../config";
import { getCookie } from "../actions/auth";

import { requestWrapper } from "../utils/api";

export const getStats = (date, params) => {
  const [start, end] = date;
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/stats?category=${params.company}`, {
      method: "POST",
      body: JSON.stringify({
        start,
        end,
      }),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    })
  );
};

import React, { useContext, useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import { FormCheckbox } from "shards-react";

import CollapseButton from "../../common/CollapseButton";
import TextItem from "../PDFPages/TextItem";
import AddTextItem from "../PDFPages/AddTextItem";
import RemovableTextItem from "../PDFPages/RemovableTextItem";
import IfAllowed from "../../common/IfAllowed";
import { MetadataContext } from "../../metadata/MetadataContext";

const CategorySetting = ({
  name,
  enabled,
  items,
  customSettings,
  onAddCustomSetting,
  onDeleteCustomSetting,
  onToggleSetting,
  onToggleByCategory,
}) => {
  const [open, setOpen] = useState(false);
  const { isAllowed } = useContext(MetadataContext);

  function onAddClick(text) {
    onAddCustomSetting({
      category: name,
      text: text,
    });
  }

  function onToggleSettingClick(id) {
    onToggleSetting(name, id);
  }

  function onToggleByCategoryClick() {
    onToggleByCategory(name);
  }

  function onDeleteCustomSettingClick(id) {
    onDeleteCustomSetting(id, name);
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <CollapseButton collapsed={open} onClick={() => setOpen(!open)} />
        <FormCheckbox
          toggle
          checked={enabled}
          onChange={
            isAllowed("admin-ladecora:project", "update:any")
              ? onToggleByCategoryClick
              : null
          }
          small
        >
          <strong>{name}</strong>
        </FormCheckbox>
      </div>

      <Collapse in={open} timeout={0}>
        <div
          style={{
            marginLeft: "60px ",
          }}
          id="example-collapse-texts"
        >
          {items.map((item, idx) => (
            <TextItem
              key={`setting_${idx}`}
              text={item.text}
              enabled={item.enabled}
              id={item._id}
              toggle={onToggleSettingClick}
            />
          ))}
          {customSettings &&
            customSettings.map((setting, index) => (
              <RemovableTextItem
                key={`custom_setting_${index}`}
                id={setting.id}
                onDelete={onDeleteCustomSettingClick}
                text={setting.text}
              />
            ))}
          <IfAllowed resource="admin-ladecora:project" perform="update:any">
            <AddTextItem onAdd={onAddClick} />
          </IfAllowed>
        </div>
      </Collapse>
    </div>
  );
};

export default CategorySetting;

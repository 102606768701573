export class Enterprise {
  constructor({ tag, environment, room, style, categories, id }) {
    this.tag = tag;
    this.environment = environment;
    this.room = room;
    this.style = style;
    this.categories = categories;
    this.id = id;
  }
}

export class Review {
  constructor({ details, files }) {
    this.details = details;
    this.files = files;
  }
}

export class User {
  constructor({
    firstName,
    lastName,
    documentType,
    document,
    birthDate,
    phone,
    _id,
  }) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.documentType = documentType;
    this.document = document;
    this.birthDate = birthDate;
    this.phone = phone;
    this._id = _id;
  }
}

export class ProjectInfo {
  constructor({
    title,
    id,
    furnitures,
    createdAt,
    updatedAt,
    status,
    lastStep,
    render,
    forcedStep,
    owner,
    ownerReview,
    company,
    complexity,
    trelloCardUrl,
    previewData,
    firstReviewDate,
    secondReviewDate,
    dropBoxFolder,
    answers,
    learningEnvironment,
    inspirations,
    furnitureMeasures,
    finalProject,
    preview,
    firstReview,
    secondReview,
    lastVersion,
    logEmail,
    logDates,
    checkList,
    checkListBook,
    template,
    enterprise,
    trelloCardName,
    trelloCardListName,
  }) {
    this.title = title;
    this.id = id;
    this.furnitures = furnitures;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.status = status;
    this.lastStep = lastStep;
    this.render = render;
    this.forcedStep = forcedStep;
    this.owner = owner;
    this.ownerReview = ownerReview;
    this.company = company;
    this.complexity = complexity;
    this.trelloCardUrl = trelloCardUrl;
    this.previewData = previewData;
    this.firstReviewDate = firstReviewDate;
    this.secondReviewDate = secondReviewDate;
    this.dropBoxFolder = dropBoxFolder;
    this.answers = answers;
    this.learningEnvironment = learningEnvironment;
    this.inspirations = inspirations;
    this.furnitureMeasures = furnitureMeasures;
    this.finalProject = finalProject;
    this.preview = new Review(preview);
    this.firstReview = new Review(firstReview);
    this.secondReview = new Review(secondReview);
    this.lastVersion = lastVersion;
    this.logEmail = logEmail;
    this.logDates = logDates;
    this.checkList = checkList;
    this.checkListBook = checkListBook;
    this.template = template;
    this.enterprise = new Enterprise(enterprise);
    this.trelloCardName = trelloCardName;
    this.trelloCardListName = trelloCardListName;
  }
}

export class ProjectData {
  constructor({
    comments,
    companyName,
    user,
    region,
    createdUserDate,
    tokStokToken,
    company,
    email,
    answerDefaultQuestion,
    projectInfo,
  }) {
    this.comments = comments;
    this.companyName = companyName;
    this.user = new User(user);
    this.region = region;
    this.createdUserDate = createdUserDate;
    this.tokStokToken = tokStokToken;
    this.company = company;
    this.email = email;
    this.answerDefaultQuestion = answerDefaultQuestion;
    this.projectInfo = new ProjectInfo(projectInfo);
  }
}

/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { useEffect, useState, useContext } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormInput,
  FormSelect,
  Button,
  FormCheckbox,
} from "shards-react";
import Loader from "react-loader-spinner";

import { updateVoucher, createVoucher } from "../../actions/voucher";
import { getAuthenticatedUser } from "../../actions/auth";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MetadataContext } from "../metadata/MetadataContext";
import { getCompany } from "../../actions/company";
import { format } from "date-fns";

const FormUser = (props) => {
  const currentUser = getAuthenticatedUser();
  const { metadata } = useContext(MetadataContext);
  const [values, setValues] = useState({
    _id: "",
    name: "",
    code: "",
    expiration: "",
    userLimit: 0,
    limit: 0,
    isActive: true,
    amount: 0,
    typeValue: "percent",
    company: metadata.myCompany.label,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [dashboardLinks, setDashboardLinks] = useState();

  const companies = metadata.companies.map((company) => {
    return {
      tag: company.name,
      value: company.label,
    };
  });

  const valueTypes = [
    { value: "percent", tag: "Percentual" },
    { value: "fixed", tag: "Fixo" },
  ];

  useEffect(() => {
    if (props.voucher) {
      const voucher = structuredClone(props.voucher);
      if (voucher.expiration) {
        voucher.expiration = format(new Date(voucher.expiration), "YYYY-MM-DD");
      }
      setValues(voucher);
    }
  }, []);

  useEffect(() => {
    if (props.voucher?._id) {
      getCompany(props.voucher.company).then((c) => {
        setDashboardLinks({
          lp: `${c.host}/landing-page?voucher=${props.voucher.code}`,
          environments: `${c.host}/environments?voucher=${props.voucher.code}`,
        });
      });
    }
  }, [props.voucher?._id]);

  const { company } = values;

  const onSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);
    if (!values._id) {
      createVoucher(values)
        .then((res) => {
          if (res.error) {
            toast.error(res.error, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.success(res.msg, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            setTimeout(() => {
              props.refreshTableData();
            }, 1000);
            setTimeout(() => {
              props.modalVoucher();
            }, 3000);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
          toast.error(err.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    } else {
      updateVoucher(values._id, values)
        .then((res) => {
          setIsLoading(false);
          toast.success(res.msg, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          setTimeout(() => {
            props.refreshTableData();
          }, 1000);
          setTimeout(() => {
            props.modalVoucher();
          }, 3000);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
          toast.error(err.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }

    return false;
  };

  const handleChange = (name) => (e) => {
    if (name === "amount") {
      e.target.value = e.target.value.replace(",", ".");
    }
    setValues({ ...values, [name]: e.target.value });
  };

  const handleSelectCompany = (e) => {
    setValues({
      ...values,
      company: e.target.value,
    });
  };

  const handleSelectTypeValue = (e) => {
    setValues({
      ...values,
      typeValue: e.target.value,
    });
  };

  const canCreate = () => {
    if (currentUser.role === "admin" || currentUser.role === "master") {
      return true;
    }
    return false;
  };

  const onCopyLink = (ev) => {
    const btName = ev.target.name;
    const link = dashboardLinks[btName];

    if (navigator.clipboard) {
      navigator.clipboard.writeText(link).then(() => {
        toast.success("Link copiado com sucesso!");
      });
    }

    return false;
  };

  return (
    <Container>
      <Row noGutters className="h-100">
        <Col lg="12" sm="12" className="auth-form mx-auto my-auto">
          {/* Logo */}
          {/* Title */}
          <h5 className="auth-form__title text-center mb-4">
            Adicionar novo cupom
          </h5>

          {/* Form Fields */}
          <Form onSubmit={onSubmit}>
            <Row form>
              <Col md="6" className="form-group">
                <div htmlFor="exampleInputEmail1">Nome</div>
                <FormInput
                  required
                  type="text"
                  placeholder="Informe o identificador do cupom"
                  autoComplete="name"
                  value={values.name}
                  onChange={handleChange("name")}
                />
              </Col>
              <Col md="6" className="form-group">
                <div className="d-flex">
                  <div>Código &nbsp;</div>
                </div>

                <FormInput
                  required
                  type="text"
                  placeholder="Informe o código"
                  autoComplete="values.code"
                  value={values.code}
                  onChange={handleChange("code")}
                />
              </Col>
              {/* <Col
                md="2"
                className="form-group d-flex align-items-end justify-content-end"
              >
                <Button>tete</Button>
              </Col> */}
              <Col md="6" className="form-group">
                <div>Tipo desconto</div>

                {
                  <FormSelect
                    size="sm"
                    value={values.typeValue}
                    onChange={handleSelectTypeValue}
                  >
                    {valueTypes.map((type, idx) => (
                      <option key={idx} value={type.value}>
                        {type.tag}
                      </option>
                    ))}
                  </FormSelect>
                }
              </Col>
              <Col md="6" className="form-group">
                <div>Valor desconto</div>
                <FormInput
                  required
                  type="text"
                  placeholder="Informe o valor desejado para aplicar de desconto"
                  autoComplete="values.amount"
                  value={values.amount}
                  onChange={handleChange("amount")}
                />
              </Col>
              <Col md="6" className="form-group">
                <div>Data de expiração {values.expiration}</div>
                <FormInput
                  type="date"
                  value={values.expiration}
                  onChange={handleChange("expiration")}
                />
              </Col>
              <Col md="12" className="form-group">
                <FormCheckbox
                  toggle
                  checked={values.userLimit === "" || values.userLimit > 0}
                  onChange={() => {
                    setValues({
                      ...values,
                      userLimit: values.userLimit ? 0 : 1,
                    });
                  }}
                  small
                >
                  <strong>Limite por usuário</strong>
                </FormCheckbox>
              </Col>
              <Col
                md="6"
                className="form-group"
                hidden={!(values.userLimit === "" || values.userLimit > 0)}
              >
                <FormInput
                  required
                  type="number"
                  placeholder=""
                  autoComplete="values.userLimit"
                  value={values.userLimit}
                  onChange={handleChange("userLimit")}
                />
              </Col>
              <Col md="12" className="form-group">
                <FormCheckbox
                  toggle
                  checked={values.limit === "" || values.limit > 0}
                  onChange={() => {
                    setValues({
                      ...values,
                      limit: values.limit ? 0 : 1,
                    });
                  }}
                  small
                >
                  <strong>Limite de uso</strong>
                </FormCheckbox>
              </Col>
              <Col
                md="6"
                className="form-group"
                hidden={!(values.limit === "" || values.limit > 0)}
              >
                <FormInput
                  required
                  type="number"
                  placeholder=""
                  autoComplete="values.limit"
                  value={values.limit}
                  onChange={handleChange("limit")}
                />
              </Col>
            </Row>

            <Row className={!canCreate ? "hidden" : ""}>
              <Col>
                <span>Contexto</span>
                {
                  <FormSelect
                    size="sm"
                    value={company}
                    onChange={handleSelectCompany}
                    defaultValue=""
                  >
                    <option value="" disabled>
                      Selecione...
                    </option>
                    {companies.map((comp, idx) => (
                      <option key={idx} value={comp.value}>
                        {comp.tag}
                      </option>
                    ))}
                  </FormSelect>
                }
              </Col>
            </Row>

            {dashboardLinks ? (
              <Row>
                <Col xs="12" className="mt-3">
                  <div className="text-center">Links para cliente</div>
                </Col>
                <Col xs="12">
                  <div>LP</div>
                  <div className="d-flex">
                    <FormInput
                      className="mr-1"
                      required
                      placeholder=""
                      value={dashboardLinks.lp}
                    />
                    <Button onClick={onCopyLink} name="lp">
                      Copiar
                    </Button>
                  </div>
                </Col>
                <Col xs="12" className="mt-1">
                  <div>Escolha de ambientes</div>
                  <div className="d-flex">
                    <FormInput
                      className="mr-1"
                      required
                      placeholder=""
                      value={dashboardLinks.environments}
                    />
                    <Button name="environments" onClick={onCopyLink}>
                      Copiar
                    </Button>
                  </div>
                </Col>
              </Row>
            ) : null}

            <Button
              pill
              theme="accent"
              className="d-table mx-auto mt-5"
              type="submit"
              disabled={isLoading}
            >
              {isLoading && (
                <Loader
                  width="30px"
                  height="15px"
                  type="Rings"
                  color="#ffffff"
                />
              )}
              {!isLoading && "Salvar"}
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default FormUser;

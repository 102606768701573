import React, { useState, useContext } from "react";

import { ModalHeader, ModalBody, ModalFooter, Button } from "shards-react";
import { RenderItemTextContext } from "./RenderItemTextContext";
import CategorySetting from "./CategorySetting";
import RemovableCategorySetting from "./RemovableCategorySetting";
import AddTextItem from "../PDFPages/AddTextItem";

import { toast } from "react-toastify";
import IfAllowed from "../../common/IfAllowed";

const Settings = ({ name, onSave }) => {
  const {
    settings,
    customSettings,
    customCategorySettings,
    addCustomCategorySetting,
    addCustomCategory,
    addCustomSetting,
    deleteCustomSetting,
    deleteCustomCategorySetting,
    deleteCustomCategory,
    toggleSetting,
    toggleSettingsByCategory,
  } = useContext(RenderItemTextContext);

  const [isSaving, setIsSaving] = useState(false);

  function onSaveClick() {
    setIsSaving(true);
    let itemsToSave = settings.reduce((acc, current) => {
      let enabledItems = current.items
        .filter((item) => item.enabled)
        .map((item) => item._id);
      acc = acc.concat(enabledItems);
      return acc;
    }, []);

    let newCustomCategorySettings = customCategorySettings.filter(
      (categorySetting) => {
        delete categorySetting.isNew;
        return !!categorySetting.items.length;
      }
    );

    onSave(itemsToSave, newCustomCategorySettings.concat(customSettings))
      .then(() => {
        setIsSaving(false);
        toast.success("Configurações salvas com sucesso.", {
          autoClose: 2000,
        });
      })
      .catch(() => {
        toast.error(
          "Ocorreu um erro desconhecido, por favor, tente novamente.",
          {
            autoClose: 2000,
          }
        );
        setIsSaving(false);
      });
  }

  function getCustomSettingsByCategory(category) {
    return customSettings
      .map((setting, index) => {
        setting = JSON.parse(JSON.stringify(setting));
        setting.id = index;
        return setting;
      })
      .filter((item) => item.category === category);
  }

  return (
    <>
      <ModalHeader>{name}</ModalHeader>
      <ModalBody style={{ maxHeight: "450px", overflow: "auto" }}>
        {settings.length &&
          settings.map((setting, idx) => {
            return (
              <CategorySetting
                key={idx}
                onAddCustomSetting={addCustomSetting}
                onDeleteCustomSetting={deleteCustomSetting}
                onToggleSetting={toggleSetting}
                onToggleByCategory={toggleSettingsByCategory}
                name={setting.name}
                enabled={setting.enabled}
                items={setting.items}
                customSettings={getCustomSettingsByCategory(setting.name)}
              />
            );
          })}
        {!!customCategorySettings.length &&
          customCategorySettings.map((setting, i) => {
            return (
              <RemovableCategorySetting
                key={i}
                onAddCustomSetting={addCustomCategorySetting}
                onDeleteCustomSetting={deleteCustomCategorySetting}
                onDeleteCategory={deleteCustomCategory}
                onToggleSetting={toggleSetting}
                onToggleByCategory={toggleSettingsByCategory}
                name={setting.name}
                id={setting.id}
                enabled={setting.enabled}
                items={setting.items}
                item={setting}
                customSettings={getCustomSettingsByCategory(setting.name)}
              />
            );
          })}
        <IfAllowed resource="admin-ladecora:project" perform="update:any">
          <AddTextItem
            onAdd={(value) => {
              addCustomCategory(
                {
                  name: value,
                  items: [],
                },
                true
              );
            }}
            placeholder="Digite o texto da categoria aqui..."
          />
        </IfAllowed>
      </ModalBody>
      <ModalFooter>
        <IfAllowed resource="admin-ladecora:project" perform="update:any">
          <Button disabled={isSaving} theme="primary" onClick={onSaveClick}>
            {isSaving ? "Salvando..." : "Salvar"}
          </Button>
        </IfAllowed>
      </ModalFooter>
    </>
  );
};

export default Settings;

import React from "react";

import PageTextItem from "./PageTextItem";

function DefaultPageItem({ item, onToggleSetting, onToggleByCategory }) {
  switch (item.type) {
    case "text":
      return (
        <PageTextItem
          onToggleSetting={onToggleSetting}
          onToggleByCategory={onToggleByCategory}
          text={item.text}
          item={item}
          enabled={item.enabled}
        />
      );
    default:
      return null;
  }
}

DefaultPageItem.propTypes = {};

export default DefaultPageItem;

import React from "react";

import { Row, Card, Col } from "shards-react";

import AspectRatioPicture from "./AspectRatioPicture";
import { BUCKET_URL } from "../../config";
import SetLinkIntoString from "./SetLinkIntoString";

export const YourSpaceOrInspiration = ({ data, sendLightbox, id, setLink }) => {
  function getImgFullLink(source) {
    return source.startsWith("http")
      ? source
      : BUCKET_URL + "/projects/" + id + "/customer/" + source;
  }

  return (
    <>
      {data.details && (
        <div className="user-activity__item pr-3 py-3">
          <div className="user-activity__item__icon">
            <i className="material-icons">&#xE7FE;</i>
          </div>
          <div className="user-activity__item__content w-100">
            <span className="text-light">Comentarios</span>
            <SetLinkIntoString string={data.details} />
          </div>
        </div>
      )}
      {data.files && (
        <div className="user-activity__item pr-3 py-3">
          <div className="user-activity__item__icon">
            <i className="material-icons">&#xE7FE;</i>
          </div>
          <div className="user-activity__item__content">
            <span className="text-light">Arquivos</span>
            <Row>
              {data.files.map((img, i) => {
                return (
                  <Col key={i} className="col-file">
                    <Card lg="3" sm="6" className="card-file">
                      <AspectRatioPicture
                        height="150px"
                        width="150px"
                        isDeletable={false}
                        src={getImgFullLink(img.source)}
                        thumbnail={
                          img.thumbnail ? getImgFullLink(img.thumbnail) : null
                        }
                        alt={img.name}
                        viewPdf={() => {
                          setLink(getImgFullLink(img.source));
                        }}
                        slide={true}
                        showSlide={() => {
                          sendLightbox(
                            data.files.map((file) => {
                              if (file.source.startsWith("http")) {
                                return file.source;
                              }

                              return getImgFullLink(file.source);
                            }),
                            i
                          );
                        }}
                      />
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
      )}
    </>
  );
};

export const ShowFurnitureMeasures = ({ data, id, sendLightbox, setLink }) => {
  function getImgFullLink(source) {
    return (
      BUCKET_URL +
      "/projects/" +
      id +
      "/customer/" +
      source.replace(".HEIC", ".jpeg").replace(".heic", ".jpeg")
    );
  }
  return (
    <>
      {data.details && (
        <div className="user-activity__item pr-3 py-3">
          <div className="user-activity__item__icon">
            <i className="material-icons">&#xE7FE;</i>
          </div>
          <div className="user-activity__item__content w-100">
            <span className="text-light">Comentarios</span>
            <p className="pr-5">{data.details}</p>
          </div>
        </div>
      )}
      {data.files && (
        <div className="user-activity__item pr-3 py-3">
          <div className="user-activity__item__icon">
            <i className="material-icons">&#xE7FE;</i>
          </div>
          <div className="user-activity__item__content">
            <span className="text-light">Arquivos</span>

            {data.files.map((furniture, idx) => {
              return (
                <div
                  style={{
                    borderBottom: "2px solid #E9ECEF",
                    marginTop: "20px",
                  }}
                  key={idx}
                >
                  <span className="text-light" style={{ fontSize: "14px" }}>
                    Nome:{furniture.nameMeasure}
                  </span>
                  <p>
                    Altura: {furniture.height} cm; Largura: {furniture.width}{" "}
                    cm; Comprimento: {furniture.length} cm; Quantidade:{" "}
                    {furniture.quantity}; Podemos reformar?{" "}
                    {furniture.reform
                      ? furniture.reform == "yes"
                        ? "Sim"
                        : "Não"
                      : "Não Informado"}
                    ; É obrigatório manter este móvel?{" "}
                    {furniture.keep
                      ? furniture.keep == "yes"
                        ? "Sim"
                        : "Não"
                      : "Não Informado"}
                  </p>
                  {furniture.files && (
                    <Row>
                      {furniture.files.map((img, idx) => {
                        return (
                          <Col key={idx} className="col-file">
                            <Card lg="3" sm="6" className="card-file">
                              <AspectRatioPicture
                                height="150px"
                                width="150px"
                                isDeletable={false}
                                src={getImgFullLink(img.source)}
                                thumbnail={
                                  img.thumbnail
                                    ? getImgFullLink(img.thumbnail)
                                    : null
                                }
                                alt={img.name}
                                viewPdf={() => {
                                  setLink(getImgFullLink(img.source));
                                }}
                                slide={true}
                                showSlide={() => {
                                  sendLightbox(
                                    furniture.files.map((file) => {
                                      if (file.source.startsWith("http")) {
                                        return file.source;
                                      }

                                      return getImgFullLink(file.source);
                                    }),
                                    idx
                                  );
                                }}
                              />
                            </Card>
                          </Col>
                        );
                      })}
                    </Row>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

import React from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalHeader,
  ModalFooter,
  Button,
  ModalBody,
} from "shards-react";

function ConfirmationModal({
  text,
  warningMessage,
  onCancelClick,
  onContinueClick,
  show,
}) {
  return (
    <Modal
      size="md"
      modalContentClassName="card card-small"
      open={show}
      toggle={() => {}}
    >
      <ModalHeader
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {text}
      </ModalHeader>
      {warningMessage ? (
        <ModalBody>
          <p style={{ color: "red" }}>{warningMessage}</p>
        </ModalBody>
      ) : null}

      <ModalFooter>
        <Button onClick={onCancelClick} theme="danger">
          Cancelar
        </Button>
        <Button
          onClick={onContinueClick}
          theme="success"
          className="ml-1"
          id="confirmation-button-continue"
        >
          Continuar
        </Button>
      </ModalFooter>
    </Modal>
  );
}

ConfirmationModal.propTypes = {
  text: PropTypes.string.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  onContinueClick: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  warningMessage: PropTypes.string,
};

export default ConfirmationModal;

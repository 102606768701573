import React from "react";

import { Card, CardBody, Row, Col, CardFooter } from "shards-react";
import FurniturePicture from "./FurniturePicture";

function FurnitureCard({ mobi, children }) {
  return (
    <Col sm="3">
      <Card small className="mb-3" style={{ borderRadius: "0.625rem" }}>
        <FurniturePicture mobi={mobi} />
        <CardBody
          style={{
            padding: "0px",
            justifyContent: "center",
            marginTop: "10px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          {children}
        </CardBody>
        <CardFooter
          className="border-top"
          style={{
            justifyContent: "center",
            padding: ".4rem 1rem",
            minHeight: "57px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <Row>
            <Col>
              Disponivel
              {mobi.available === true ? (
                <i
                  className="fas fa-check-circle fa-lg"
                  style={{
                    color: "#0a775a",
                    cursor: "pointer",
                  }}
                />
              ) : mobi.available === false ? (
                <i
                  className="fas fa-times-circle fa-lg"
                  style={{
                    color: "#da1919",
                    cursor: "pointer",
                  }}
                />
              ) : (
                <>
                  <br />
                  Não Inf.
                </>
              )}
            </Col>
            <Col>
              Pode Usar <br />
              {mobi.notUse ? (
                <i
                  className="fas fa-times-circle fa-lg"
                  style={{
                    color: "#da1919",
                    cursor: "pointer",
                  }}
                />
              ) : (
                <i
                  className="fas fa-check-circle fa-lg"
                  style={{
                    color: "#0a775a",
                    cursor: "pointer",
                  }}
                />
              )}
            </Col>
          </Row>
        </CardFooter>
      </Card>
    </Col>
  );
}

FurnitureCard.propTypes = {};

export default FurnitureCard;

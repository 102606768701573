import React, { useState } from "react";
import {
  Row,
  Card,
  Col,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "shards-react";
import Moment from "react-moment";
import { updateComments } from "../../actions/projects";
import { toast } from "react-toastify";

const Notes = ({ comments, onCommentsChanged, reload, projectId }) => {
  const [comment, setComment] = useState("");
  const [show, setShow] = useState(false);

  const addComment = () => {
    const date = new Date().toISOString();
    const user = JSON.parse(localStorage.getItem("user"));
    updateComments(projectId, {
      comment: comment,
      date: date,
      user: user._id,
    }).then((data) => {
      onCommentsChanged([
        ...comments,
        { comment: comment, date: date, user: user._id },
      ]);
      toast.success(data.msg, {
        autoClose: 5000,
      });
      reload();
      setShow(!show);
    });
  };
  return (
    <Card small className="mb-4">
      <CardBody className="p-0">
        <Row>
          <Col>
            <h6 className="text-center m-0 mt-2">Comentários</h6>

            <Button
              outline
              size="sm"
              theme="primary"
              style={{ float: "right" }}
              className="text-center m-0 m-2"
              onClick={() => {
                setShow(true);
              }}
            >
              Adicionar Comentário
            </Button>
          </Col>
        </Row>

        <Modal
          size="lg"
          modalContentClassName="card card-small"
          open={show}
          toggle={() => setShow(!show)}
        >
          <ModalHeader>Adicionar Comentário</ModalHeader>
          <ModalBody>
            <textarea
              rows="10"
              className="form-control"
              placeholder="Adicionar comentário"
              onChange={(e) => {
                setComment(e.target.value);
              }}
            />
            <Button
              outline
              size="sm"
              theme="primary"
              className="mb-2 mr-1"
              onClick={addComment}
            >
              Enviar Comentário
            </Button>
          </ModalBody>
        </Modal>
        <Row>
          <Col>
            <div className="user-details__user-data border-top border-bottom p-4">
              {comments &&
                comments.map((comentario, i) => {
                  return (
                    <div className="user-activity__item pr-3 py-3" key={i}>
                      <div className="user-activity__item__icon">
                        {comentario.user ? (
                          <i className="material-icons">&#xE7FE;</i>
                        ) : (
                          <i className="material-icons">&#xe8b8;</i>
                        )}
                      </div>
                      <div className="user-activity__item__content">
                        <span className="text-light">
                          <Moment format="DD/MM/YYYY HH:mm:ss">
                            {comentario.date}
                          </Moment>
                        </span>
                        <p className="text-light">
                          {comentario.user && comentario.user.email}
                        </p>
                        <p>{comentario.comment}</p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default Notes;

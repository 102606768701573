import React, { useState } from "react";
import PropTypes from "prop-types";

import { FormSelect } from "shards-react";
import ConfirmationButton from "../../common/ConfirmationButton";
import Steps from "./Steps";

import { toast } from "react-toastify";

const StepOptions = ({ steps }) =>
  steps.map((step, index) => (
    <option key={index} value={step.key}>
      {step.description}
    </option>
  ));

const StepCorrection = ({
  projectCorrectionSteps,
  onAddStepCorrection,
  onDeleteStepCorrection,
}) => {
  if (projectCorrectionSteps === null) {
    projectCorrectionSteps = [];
  }
  const DEFAULT_STEPS = [
    {
      key: "1",
      description: "Nome do projeto",
    },
    {
      key: "2",
      description: "Questionário do ambiente",
    },
    {
      key: "3",
      description: "Conhecendo seu espaço",
    },
    {
      key: "4",
      description: "Móveis e medidas",
    },
    {
      key: "5",
      description: "Inspiração",
    },
  ];
  const DEFAULT_INITIAL_VALUE = DEFAULT_STEPS[0];
  const stepsToCorrect = DEFAULT_STEPS.reduce((acc, step) => {
    const projectStep = projectCorrectionSteps.find(
      (stepToBeCorrected) => step.key === stepToBeCorrected.key
    );
    if (projectStep) {
      step.isPending = projectStep.isPending;
      acc.push(step);
    }
    return acc;
  }, []);
  const [step, setStep] = useState(DEFAULT_INITIAL_VALUE);
  const [isLoading, setIsLoading] = useState(false);

  function onLocalStepChange(ev) {
    const step = DEFAULT_STEPS.find(
      (defaultStep) => defaultStep.key === ev.target.value
    );

    setStep(step);
  }

  function onRequestCorrectionClick() {
    const stepToCorrect = projectCorrectionSteps.find(
      (stepsToCorrect) => stepsToCorrect.key === step.key
    );

    const newProjectCorrectionSteps = [...projectCorrectionSteps];
    if (!stepToCorrect) {
      newProjectCorrectionSteps.push({
        key: step.key,
        isPending: true,
      });
    } else {
      stepToCorrect.isPending = true;
    }

    setIsLoading(true);

    onAddStepCorrection({
      key: step.key,
      isPending: true,
    })
      .then(() => {
        toast.success("Solicitação realizada com sucesso", { autoClose: 5000 });
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        toast.error("Ocorreu um erro desconhecido", { autoClose: 5000 });
      });
  }

  function onStepRemoved(step) {
    return onDeleteStepCorrection(step.key);
  }

  return (
    <div className=" border-bottom pl-4 pr-4 pb-4">
      <div className=" d-flex align-items-center flex-row justify-content-center ">
        <FormSelect onChange={onLocalStepChange}>
          <StepOptions steps={DEFAULT_STEPS} />
        </FormSelect>
        <ConfirmationButton
          onClick={onRequestCorrectionClick}
          outline
          disabled={isLoading}
          className="ml-1"
          style={{ minWidth: "140px" }}
        >
          {isLoading ? "Solicitando..." : "Solicitar correção"}
        </ConfirmationButton>
      </div>

      <Steps steps={stepsToCorrect} onRemove={onStepRemoved} />
    </div>
  );
};

StepCorrection.propTypes = {
  onUpdate: PropTypes.func,
  projectCorrectionSteps: PropTypes.array,
};

StepCorrection.defaultProps = {
  projectCorrectionSteps: [],
};

export default StepCorrection;

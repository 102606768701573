import React from "react";
import { CardSubtitle } from "shards-react";

function FurnitureSubtitle({ subtitle }) {
  return (
    <CardSubtitle className="wrap-text-ellipsis p-2">
      <span title={subtitle}>{subtitle}</span>
    </CardSubtitle>
  );
}

FurnitureSubtitle.propTypes = {};

export default FurnitureSubtitle;

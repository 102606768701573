import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  FormSelect,
  Card,
  Col,
  CardBody,
  FormCheckbox,
} from "shards-react";
import PageTitle from "../common/PageTitle";

import User from "../project/user";
import ProjectInfo from "../project/projectInfo";
import Owner from "../project/Owner/index";
import ButtonCheckList from "../project/Checklist/ButtonCheckList";

import AnswerDefaultQuestion from "../project/answerDefaultQuestion";
import Answers from "../project/answers";
import LearningEnvironment from "../project/learningEnvironment";
import Inspirations from "../project/inspirations";
import FurnitureMeasures from "../project/furnitureMeasures";
import FurnitureTable from "../project/FurnitureTable";
import Renders from "../project/renders";
import Notifications from "../project/Notifications/index";
import PDFPages from "../project/PDFPages";
import AdminStatusChange from "../project/AdminStatusChange";
import Notes from "../project/notes";
import LogDates from "../project/logDates";
import {
  updateApproveProject,
  updateFurnitures,
  updateProjectStatus,
  addStepCorrection,
  deleteStepCorrection,
  updateProjectOwner,
  updateReviewProject,
  waitUserFeedback,
  updateProjectComplexity,
  updateLogDates,
} from "../../actions/projects";
import { updateComments } from "../../actions/projects";
import Editor from "../add-new-post/Editor";
import LogEmail from "../project/logEmail";

import "@ladecora/react-image-lightbox/style.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import IfAllowed from "../common/IfAllowed";
import ConfirmationButton from "../common/ConfirmationButton";
import StepCorrection from "../project/StepCorrection/index";
import { ProjectData } from "./data";

const ProjectManager = ({
  reload,
  projectData,
  chatLength,
  showChat,
  onDataChanged,
  numberProjects,
  adminTokenAccess,
  setShowChat,
  sendLightbox,
  messages,
}) => {
  if (!projectData) return null;
  const {
    answerDefaultQuestion,
    comments,
    company,
    user,
    projectInfo,
    email,
    region,
    createdUserDate,
    tokStokToken,
  } = projectData;

  const {
    preview,
    secondReview,
    answers,
    learningEnvironment,
    inspirations,
    logEmail,
    furnitureMeasures,
    logDates,
    checkListBook,
    checkList,
    template,
    firstReview,
    id,
    finalProject,
  } = projectInfo;

  const rendersProps = {
    projectInfo,
    sendLightbox,
    updateProjectStatus,
    onProjectInfoChanged,
    showQualityButton: true,
    showActions: true,
    reload,
  };

  const [showPreview, setShowPreview] = useState(false);

  const [showFirstReview, setShowFirstReview] = useState(false);

  const [showSecondReview, setShowSecondReview] = useState(false);

  const [showStatusChange, setShowStatusChange] = useState(false);

  const [showFurnitureList, setShowFurnitureList] = useState(false);
  const [showProjectRenderConfig, setShowProjectRenderConfig] = useState(false);

  const [questionarioPerfil, setQuestionarioPerfil] = useState(false);

  const [questionarioAmbiente, setQuestionarioAmbiente] = useState(false);

  const [questionarioConhecendo, setQuestionarioConhecendo] = useState(false);

  const [questionarioInspiration, setQuestionarioInspiration] = useState(false);

  const [showEditor, setShowEditor] = useState(false);

  const [showLogEmail, setShowLogEmail] = useState(false);

  const [showComments, setShowComments] = useState(false);

  const [showNotifications, setShowNotifications] = useState(false);

  const [showLogDates, setShowLogDates] = useState(false);
  const [questionarioFurnitureMeasures, setQuestionarioFurnitureMeasures] =
    useState(false);

  const approveProject = () => {
    updateLogDates(id, "approved").then(() => {
      updateApproveProject(id, email, user.firstName).then((result) => {
        const comment = "Projeto Aprovado";
        const date = new Date().toISOString();
        const user = JSON.parse(localStorage.getItem("user"));
        updateComments(id, {
          comment: comment,
          date: date,
          user: user._id,
        }).then((data) => {
          onDataChanged({
            ...projectData,
            comments: [
              ...comments,
              { comment: comment, date: date, user: user._id },
            ],

            projectInfo: {
              ...projectData.projectInfo,
              status: "approved",
              lastStep: 8,
            },
          });

          toast.success(data.msg, {
            autoClose: 5000,
          });
          reload();
        });
        toast.success(result.msg, { autoClose: 5000 });
      });
    });
  };

  const onSaveFurnitures = (furnitures) => {
    updateFurnitures(
      id,
      furnitures.map((furniture) => {
        return {
          count: furniture.count,
          refx: furniture.refx,
          refy: furniture.refy,
          refz: furniture.refz,
          id: furniture.data._id,
        };
      })
    ).then(() => {
      toast.success("Produtos alterados com sucesso", { autoClose: 5000 });
      reload();
    });
  };

  const onAddStepCorrection = (data) => {
    return addStepCorrection(projectInfo.id, data).then(reload);
  };

  const onDeleteStepCorrection = (id) => {
    return deleteStepCorrection(projectInfo.id, id).then(reload);
  };

  function approveReview() {
    updateLogDates(id, "customer_reproved").then(() => {
      updateReviewProject(id, email, user.firstName).then((result) => {
        const comment = "Revisão aprovada";
        const date = new Date().toISOString();
        const user = JSON.parse(localStorage.getItem("user"));
        updateComments(id, {
          comment: comment,
          date: date,
          user: user._id,
        }).then((data) => {
          onDataChanged({
            ...projectData,
            comments: [
              ...comments,
              { comment: comment, date: date, user: user._id },
            ],
            projectInfo: {
              ...projectData.projectInfo,
              status: "customer-reproved",
              lastStep: 8,
            },
          });
          toast.success(data.msg, {
            autoClose: 5000,
          });
          reload();
        });
        toast.success(result.msg, { autoClose: 5000 });
      });
    });
  }

  function waitUserConfirmData() {
    waitUserFeedback(id, email, user.firstName).then((result) => {
      const comment = "Aguardando resposta do cliente";
      const date = new Date().toISOString();
      const user = JSON.parse(localStorage.getItem("user"));
      updateComments(id, {
        comment: comment,
        date: date,
        user: user._id,
      }).then((data) => {
        onDataChanged({
          ...projectData,
          comments: [
            ...comments,
            { comment: comment, date: date, user: user._id },
          ],
          projectInfo: {
            ...projectData.projectInfo,
            status: "waiting_user_feedback",
            lastStep: 8,
          },
        });
        toast.success(data.msg, {
          autoClose: 5000,
        });
        reload();
      });
      toast.success(result.msg, { autoClose: 5000 });
      updateLogDates(id, "waiting_user_feedback");
    });
  }

  function onComplexityChange(ev) {
    const value = ev.target.value ? parseInt(ev.target.value) : null;
    const oldValue = projectInfo.complexity;
    onDataChanged({
      ...projectData,
      projectInfo: {
        ...projectData.projectInfo,
        complexity: value,
      },
    });

    updateProjectComplexity(id, value)
      .then((res) => {
        toast.success(res.msg, { autoClose: 5000 });
      })
      .catch((err) => {
        onDataChanged({
          ...projectData,
          projectInfo: {
            ...projectData.projectInfo,
            complexity: oldValue,
          },
        });

        toast.error(err.message, { autoClose: 5000 });
        reload();
      });
  }
  function onProjectInfoChanged(value) {
    onDataChanged({
      ...projectData,
      projectInfo: {
        ...projectData.projectInfo,
        ...value,
      },
    });
  }
  return (
    <Container
      fluid
      className="main-content-container px-4"
      style={{ whiteSpace: "pre-line" }}
    >
      <Row className="page-header py-4">
        <Col md="6">
          <PageTitle
            title="Projeto"
            subtitle="Visão Geral"
            className="text-sm-left mb-3"
          />
        </Col>
        <Col md="5" className="col d-flex align-items-center" />
      </Row>
      <Row className="mt-4">
        <Col lg="4" sm="12">
          <User
            user={user}
            email={email}
            company={company}
            location={region}
            createUser={createdUserDate}
            tokenTokStok={tokStokToken}
            adminTokenAccess={adminTokenAccess}
            numberProjects={numberProjects}
          />

          <Card small className="mb-4">
            <CardBody className="p-0">
              <IfAllowed resource="admin-ladecora:project:checklist-project">
                <Row>
                  <Col>
                    <h6 className="text-center m-0 mt-2">
                      CheckList Projeto{" "}
                      {checkList.length > 0 && "(" + checkList.length + ")"}{" "}
                    </h6>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-center mt-1 mb-3">
                    <ButtonCheckList
                      checklist={checkList}
                      setCheckList={(value) =>
                        onDataChanged({
                          ...projectData,
                          projectInfo: {
                            ...projectData.projectInfo,
                            checkList: value,
                          },
                        })
                      }
                      projectId={id}
                      type={"checklist"}
                    />
                  </Col>
                </Row>
              </IfAllowed>
              <IfAllowed resource="admin-ladecora:project:step-input">
                <Row>
                  <Col>
                    <h6 className="text-center m-0 mt-2">Responsável</h6>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h6 className="text-center m-0 mt-2">Projeto</h6>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-center mt-1 mb-3">
                    <Owner
                      id={id}
                      value={projectInfo.owner}
                      type={"owner"}
                      updateProjectOwner={updateProjectOwner}
                      toast={toast}
                      reload={reload}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h6 className="text-center m-0 mt-2">Revisão</h6>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-center mt-1 mb-3">
                    <Owner
                      id={id}
                      value={projectInfo.ownerReview}
                      type={"ownerReview"}
                      updateProjectOwner={updateProjectOwner}
                      toast={toast}
                      reload={reload}
                    />
                  </Col>
                </Row>
              </IfAllowed>
              <IfAllowed resource="admin-ladecora:project:complexity">
                <Row className="border-top">
                  <Col>
                    <h6 className="text-center m-0 mt-2">Complexidade</h6>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-center mt-1  mb-3">
                    <FormSelect
                      style={{ height: "fit-content", width: "95px" }}
                      value={projectInfo.complexity || "0"}
                      onChange={onComplexityChange}
                    >
                      <option className="text-center" value="0">
                        Nenhum
                      </option>
                      <option className="text-center" value="1">
                        1
                      </option>
                      <option className="text-center" value="2">
                        2
                      </option>
                      <option className="text-center" value="3">
                        3
                      </option>
                      <option className="text-center" value="4">
                        4
                      </option>
                      <option className="text-center" value="5">
                        5
                      </option>
                    </FormSelect>
                  </Col>
                </Row>
              </IfAllowed>
              <IfAllowed resource="admin-ladecora:project:step-input">
                <Row className="border-top">
                  <Col>
                    <h6 className="text-center m-0 mt-2 mb-1">
                      Correção de step
                    </h6>
                  </Col>
                </Row>
                <StepCorrection
                  projectCorrectionSteps={projectInfo.forcedStep}
                  onAddStepCorrection={onAddStepCorrection}
                  onDeleteStepCorrection={onDeleteStepCorrection}
                />
              </IfAllowed>
              <Row>
                <Col>
                  <h6 className="text-center m-0 mt-2">Questionários</h6>
                </Col>
              </Row>
              <div className="border-bottom  pb-4 pl-4 pr-4 pt-1">
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <FormCheckbox
                          toggle
                          checked={questionarioPerfil}
                          onChange={() =>
                            setQuestionarioPerfil(!questionarioPerfil)
                          }
                          small
                        >
                          Questionário Perfil
                        </FormCheckbox>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <FormCheckbox
                          toggle
                          checked={questionarioAmbiente}
                          onChange={() =>
                            setQuestionarioAmbiente(!questionarioAmbiente)
                          }
                          small
                        >
                          Questionário do ambiente
                        </FormCheckbox>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <FormCheckbox
                          toggle
                          checked={questionarioConhecendo}
                          onChange={() =>
                            setQuestionarioConhecendo(!questionarioConhecendo)
                          }
                          small
                        >
                          Conhecendo seu espaço
                        </FormCheckbox>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <FormCheckbox
                          toggle
                          checked={questionarioFurnitureMeasures}
                          onChange={() =>
                            setQuestionarioFurnitureMeasures(
                              !questionarioFurnitureMeasures
                            )
                          }
                          small
                        >
                          Móveis e medidas
                        </FormCheckbox>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <FormCheckbox
                          toggle
                          checked={questionarioInspiration}
                          onChange={() =>
                            setQuestionarioInspiration(!questionarioInspiration)
                          }
                          small
                        >
                          Questionário Inspirações
                        </FormCheckbox>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <IfAllowed resource="admin-ladecora:project:approval-button">
                <Row>
                  <Col>
                    <h6 className="text-center m-0 mt-2">Aprovar Projeto</h6>
                  </Col>
                </Row>
                <div className="border-top border-bottom p-4 d-flex justify-content-center mt-1 mb-1">
                  <ConfirmationButton
                    disabled={projectInfo.status === "submitted" ? false : true}
                    className="text-center"
                    size="sm"
                    id="project-button-approve-project"
                    theme="success"
                    onClick={approveProject}
                  >
                    Aprovar Informações
                  </ConfirmationButton>
                </div>

                <div
                  style={{
                    display:
                      projectInfo.status === "waiting_confirm_review" ||
                      projectInfo.status === "waiting_user_feedback"
                        ? "block"
                        : "none",
                  }}
                >
                  <Row>
                    <Col>
                      <h6 className="text-center m-0">Confirmar revisão</h6>
                    </Col>
                  </Row>
                  <div className="border-top border-bottom p-4 d-flex justify-content-center mt-1 mb-3">
                    <ConfirmationButton
                      className="text-center"
                      size="sm"
                      theme="success"
                      onClick={() => approveReview()}
                    >
                      Aprovar
                    </ConfirmationButton>
                    <ConfirmationButton
                      className="text-center ml-2"
                      size="sm"
                      theme="secondary"
                      outline
                      disabled={projectInfo.status === "waiting_user_feedback"}
                      onClick={() => waitUserConfirmData()}
                    >
                      {projectInfo.status === "waiting_user_feedback"
                        ? "Aguardando cliente"
                        : "Aguardar cliente"}
                    </ConfirmationButton>
                  </div>
                </div>
              </IfAllowed>

              <Row>
                <Col>
                  <h6 className="text-center m-0 mt-2">Ações</h6>
                </Col>
              </Row>
              <div className="border-top border-bottom p-4">
                <IfAllowed resource="admin-ladecora:project:actions:comments">
                  <Row>
                    <Col>
                      <FormCheckbox
                        toggle
                        checked={showComments}
                        onChange={() => {
                          setShowComments(!showComments);
                        }}
                        small
                      >
                        Comentários ({comments.length})
                      </FormCheckbox>
                    </Col>
                  </Row>
                </IfAllowed>
                <Row>
                  <Col>
                    <FormCheckbox
                      toggle
                      checked={showChat}
                      onChange={() => {
                        setShowChat(!showChat);
                      }}
                      small
                    >
                      Chat ({chatLength})
                    </FormCheckbox>
                  </Col>
                </Row>
                <IfAllowed resource="admin-ladecora:project:actions:comments">
                  <Row>
                    <Col>
                      <FormCheckbox
                        toggle
                        checked={showNotifications}
                        onChange={() =>
                          setShowNotifications(!showNotifications)
                        }
                        small
                      >
                        Notificações
                      </FormCheckbox>
                    </Col>
                  </Row>
                </IfAllowed>
                <IfAllowed resource="admin-ladecora:project:actions:log-email">
                  <Row>
                    <Col>
                      <FormCheckbox
                        toggle
                        checked={showLogEmail}
                        onChange={() => setShowLogEmail(!showLogEmail)}
                        small
                      >
                        Log email
                      </FormCheckbox>
                    </Col>
                  </Row>
                </IfAllowed>
                <IfAllowed resource="admin-ladecora:project:actions:send-email">
                  <Row>
                    <Col>
                      <FormCheckbox
                        toggle
                        checked={showEditor}
                        onChange={() => setShowEditor(!showEditor)}
                        small
                      >
                        Enviar email
                      </FormCheckbox>
                    </Col>
                  </Row>
                </IfAllowed>
                <IfAllowed resource="admin-ladecora:project:actions:preview">
                  <Row>
                    <Col>
                      <FormCheckbox
                        toggle
                        id="project-checkbox-preview"
                        checked={showPreview}
                        onChange={() => setShowPreview(!showPreview)}
                        small
                      >
                        Proposta Inicial
                        {preview.files.length > 0 &&
                          "(" + preview.files.length + ")"}
                      </FormCheckbox>
                    </Col>
                  </Row>
                </IfAllowed>
                <IfAllowed resource="admin-ladecora:project:actions:first-review">
                  <Row>
                    <Col>
                      <FormCheckbox
                        toggle
                        checked={showFirstReview}
                        onChange={() => setShowFirstReview(!showFirstReview)}
                        small
                      >
                        1º Revisão
                        {firstReview.files.length > 0 &&
                          "(" + firstReview.files.length + ")"}
                      </FormCheckbox>
                    </Col>
                  </Row>
                </IfAllowed>
                <IfAllowed resource="admin-ladecora:project:actions:second-review">
                  <Row>
                    <Col>
                      <FormCheckbox
                        toggle
                        checked={showSecondReview}
                        onChange={() => setShowSecondReview(!showSecondReview)}
                        small
                      >
                        2º Revisão
                        {secondReview.files.length > 0 &&
                          "(" + secondReview.files.length + ")"}
                      </FormCheckbox>
                    </Col>
                  </Row>
                </IfAllowed>
                <IfAllowed resource="admin-ladecora:project:actions:furniture-list">
                  <Row>
                    <Col>
                      <FormCheckbox
                        toggle
                        checked={showFurnitureList}
                        onChange={() =>
                          setShowFurnitureList(!showFurnitureList)
                        }
                        small
                      >
                        Lista de produtos
                      </FormCheckbox>
                    </Col>
                  </Row>
                </IfAllowed>
                <IfAllowed resource="admin-ladecora:project:actions:render-config">
                  <Row>
                    <Col>
                      <FormCheckbox
                        toggle
                        checked={showProjectRenderConfig}
                        onChange={() =>
                          setShowProjectRenderConfig(!showProjectRenderConfig)
                        }
                        small
                      >
                        Versão final - PDF
                      </FormCheckbox>
                    </Col>
                  </Row>
                </IfAllowed>
                <IfAllowed resource="admin-ladecora:project:checklist-book">
                  <div className="border-top border-bottom">
                    <Row>
                      <Col>
                        <h6 className="text-center m-0 mt-2">
                          CheckList Book{" "}
                          {checkListBook.length > 0 &&
                            "(" + checkListBook.length + ")"}{" "}
                        </h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="d-flex justify-content-center mt-1 mb-3">
                        <ButtonCheckList
                          checklist={checkListBook}
                          setCheckList={(value) =>
                            onDataChanged({
                              ...projectData,
                              projectInfo: {
                                ...projectData.projectInfo,
                                checkListBook: value,
                              },
                            })
                          }
                          projectId={id}
                          type={"checklistbook"}
                        />
                      </Col>
                    </Row>
                  </div>
                </IfAllowed>

                <Row>
                  <Col>
                    <FormCheckbox
                      toggle
                      checked={showLogDates}
                      onChange={() => setShowLogDates(!showLogDates)}
                      small
                    >
                      Resumo
                    </FormCheckbox>
                  </Col>
                </Row>
                <IfAllowed resource="admin-ladecora:project:actions:status-change">
                  <Row>
                    <Col>
                      <FormCheckbox
                        toggle
                        checked={showStatusChange}
                        onChange={() => setShowStatusChange(!showStatusChange)}
                        small
                      >
                        <span style={{ color: "red" }}>Alterar Step</span>
                      </FormCheckbox>
                    </Col>
                  </Row>
                </IfAllowed>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg="8">
          <ProjectInfo
            projectData={projectData}
            finalProject={finalProject}
            reload={reload}
          />
          {showStatusChange && (
            <AdminStatusChange
              projectId={id}
              logDates={logDates}
              reload={reload}
              projectData={projectData}
              messages={messages}
            />
          )}

          {showPreview && (
            <Renders
              titleStep={"Proposta Inicial"}
              stepName={"preview"}
              statusName={"preview"}
              {...rendersProps}
            />
          )}
          {showFirstReview && (
            <Renders
              titleStep={"1º Revisão"}
              stepName={"firstReview"}
              statusName={"first_review"}
              {...rendersProps}
            />
          )}
          {showSecondReview && (
            <Renders
              titleStep={"2º Revisão"}
              stepName={"secondReview"}
              statusName={"second_review"}
              {...rendersProps}
            />
          )}
          {showLogEmail && <LogEmail logEmail={logEmail} />}
          {showEditor && (
            <Editor
              projectId={id}
              user={user}
              token={tokStokToken}
              email={email}
            />
          )}
          {showFurnitureList && (
            <FurnitureTable
              data={projectData.projectInfo}
              projectId={id}
              onSave={onSaveFurnitures}
              onUpdate={reload}
            />
          )}
          {showProjectRenderConfig && (
            <PDFPages
              onUpdate={reload}
              sendLightbox={sendLightbox}
              user={user}
              email={email}
              token={tokStokToken}
              onProjectInfoChanged={onProjectInfoChanged}
              projectInfo={projectInfo}
            />
          )}
          {questionarioPerfil && (
            <AnswerDefaultQuestion
              answerDefaultQuestion={answerDefaultQuestion}
              sendLightbox={sendLightbox}
              user={user}
            />
          )}
          {questionarioAmbiente && (
            <Answers
              answers={answers}
              sendLightbox={sendLightbox}
              originalQuestion={template}
            />
          )}
          {questionarioConhecendo && (
            <LearningEnvironment
              learningEnvironment={learningEnvironment}
              id={id}
              sendLightbox={sendLightbox}
              onLearningEnvironmentChanged={(value) =>
                onDataChanged({
                  ...projectData,
                  projectInfo: {
                    ...projectData.projectInfo,
                    learningEnvironment: value,
                  },
                })
              }
            />
          )}
          {questionarioInspiration && (
            <Inspirations
              inspirations={inspirations}
              id={id}
              sendLightbox={sendLightbox}
              onInspirationsChanged={(value) =>
                onDataChanged({
                  ...projectData,
                  projectInfo: {
                    ...projectData.projectInfo,
                    inspirations: value,
                  },
                })
              }
            />
          )}
          {questionarioFurnitureMeasures && (
            <FurnitureMeasures
              sendLightbox={sendLightbox}
              furnitureMeasures={furnitureMeasures}
              id={id}
            />
          )}
          {showComments && (
            <>
              <Notes
                comments={comments}
                onCommentsChanged={(value) =>
                  onDataChanged({
                    ...projectData,
                    comments: value,
                  })
                }
                reload={reload}
                projectId={id}
              />
            </>
          )}
          {showLogDates && <LogDates logDates={logDates} />}
          {showNotifications && <Notifications projectId={id} />}
        </Col>
      </Row>
    </Container>
  );
};

ProjectManager.propTypes = {
  reload: PropTypes.func.isRequired,
  chatLength: PropTypes.number.isRequired,
  showChat: PropTypes.bool.isRequired,
  onDataChanged: PropTypes.func.isRequired,
  projectData: PropTypes.instanceOf(ProjectData),
  numberProjects: PropTypes.number,
  adminTokenAccess: PropTypes.string,
  setShowChat: PropTypes.func.isRequired,
  sendLightbox: PropTypes.func.isRequired,
};
export default ProjectManager;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormCheckbox } from "shards-react";
import ConfirmationModal from "./ConfirmationModal";

const ConfirmationCheckbox = (props) => {
  const [show, setShow] = useState(false);
  const { options } = props;
  const attrs = { ...props, options: undefined };

  function onContinueClick() {
    setShow(false);
    props.onChange();
  }

  function onChange() {
    setShow(true);
  }

  function onCancelClick() {
    setShow(false);
  }
  return (
    <>
      <FormCheckbox {...attrs} onChange={onChange}>
        {options.text}
      </FormCheckbox>

      {show && (
        <ConfirmationModal
          onCancelClick={onCancelClick}
          onContinueClick={onContinueClick}
          text={
            options.title ? options.title : "Tem certeza que deseja continuar?"
          }
          warningMessage={options.warningMessage}
          show={show}
        />
      )}
    </>
  );
};

ConfirmationCheckbox.propTypes = {
  options: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

ConfirmationCheckbox.defaultProps = {
  text: "",
};

export default ConfirmationCheckbox;

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { MobiTagTinderParams, Tag } from "../data/mobi.ts";
import Configuration from "../components/TinderMobiTag/Configuration";

const View = () => {
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const typology = query.get("typology");
  const category = query.get("category");
  const subCategory = query.get("subCategory");

  const value = query.get("value");
  const params = {};
  params.typology = new Tag({ _id: typology || "" });
  params.category = new Tag({ _id: category || "" });
  params.subCategory = new Tag({ _id: subCategory || "" });
  if (value) {
    params.value = new Tag(JSON.parse(atob(value)));
  } else {
    params.value = new Tag();
  }

  const mobiTagTinderParams = new MobiTagTinderParams(params);

  useEffect(() => {
    document.getElementsByTagName("html")[0].style.overflow = "hidden";
    return () =>
      (document.getElementsByTagName("html")[0].style.overflow = "unset");
  }, []);
  return <Configuration params={mobiTagTinderParams} />;
};

export default View;

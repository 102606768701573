import React from "react";
import { CardTitle } from "shards-react";

function FurnitureTitle({ title }) {
  return (
    <CardTitle
      className="pl-2 pr-2 wrap-text-ellipsis"
      style={{ fontSize: "15px" }}
    >
      <span title={title}>{title}</span>
    </CardTitle>
  );
}

FurnitureTitle.propTypes = {};

export default FurnitureTitle;

import { API } from "../config";
import { getCookie } from "../actions/auth";
import { getCompanyUrlParams } from "../utils/sanitize";
import { requestWrapper } from "../utils/api";

export const listAllProject = (params) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/projects/all${getCompanyUrlParams(params)}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const listAllProjectFiltered = (
  company,
  filterStatus,
  enterprise = null
) => {
  const token = getCookie("token");
  let strStatus = "";
  if (filterStatus === "active") {
    strStatus = "&status=active";
  }
  if (filterStatus === "enabled") {
    strStatus = "&status=enabled";
  }
  if (filterStatus === "final_project") {
    strStatus = "&status=final_project";
  }

  let enterpriseStr = "";
  if (enterprise) {
    strStatus = `&enterprise=${enterprise}`;
  }
  return requestWrapper(
    fetch(
      `${API}/projects/all${getCompanyUrlParams(
        company
      )}${strStatus}${enterpriseStr}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "x-access-token": token,
        },
      }
    )
  );
};

export const createProject = (hash) => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(`${API}/project-admin`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(hash),
    })
  );
};

export const uploadFile = (file, projectId, folder) => {
  const token = getCookie("token");
  const formData = new FormData();
  if (file) {
    formData.append("file", file);
  }

  return requestWrapper(
    fetch(`${API}/project/upload/${folder}/${projectId}`, {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const uploadRenderFile = (opt) => {
  const { projectId, files, type } = opt;
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project/${projectId}/render`, {
      method: "PUT",
      body: JSON.stringify({ files, type }),
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
        "Content-Type": "application/json",
      },
    })
  );
};

export const getPDFTextItems = () => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(`${API}/project/render/textItems`, {
      method: "GET",
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const postPDFTextItems = (data) => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(`${API}/project/render/textItems`, {
      method: "POST",
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify(data),
    })
  );
};

export const getPDFDefaultSettings = (type) => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(`${API}/project/render/${type}`, {
      method: "GET",
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const updatePDFContractorSettings = (
  projectId,
  textItemsIds,
  customTextItems
) => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(`${API}/project/${projectId}/render/contractorSettings`, {
      method: "POST",
      body: JSON.stringify({
        textItemsIds,
        customTextItems,
      }),
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    })
  );
};

export const updatePDFTextItems = (
  projectId,
  textItemsIds,
  customTextItems
) => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(`${API}/project/${projectId}/render/textItems`, {
      method: "POST",
      body: JSON.stringify({
        textItemsIds,
        customTextItems,
      }),
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    })
  );
};

export const getPDFDefaultPageItemsByCompany = (params) => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(
      `${API}/project/pdf/default_page_items/${getCompanyUrlParams(params)}`,
      {
        method: "GET",
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${token}`,
          "x-access-token": token,
        },
      }
    )
  );
};

export const updatePDFWoodWorkerSettings = (
  projectId,
  textItemsIds,
  customTextItems
) => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(`${API}/project/${projectId}/render/woodWorker`, {
      method: "POST",
      body: JSON.stringify({
        textItemsIds,
        customTextItems,
      }),
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    })
  );
};

export const deleteRenderFile = (opt) => {
  const { objectId, projectId, type } = opt;
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project/${projectId}/render/${type}/${objectId}`, {
      method: "DELETE",
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const generatePDF = (projectId) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project/${projectId}/generatePdfReport`, {
      method: "GET",
      headers: {
        accept: "application/pdf",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const addFurniture = (projectId, furniture, step) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project/${projectId}/furnitures/${step}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(furniture),
    })
  );
};

export const removeFurniture = (projectId, furnitureId, step) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project/${projectId}/furniture/${furnitureId}/${step}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const updateFurnitures = (projectId, furnitures, step) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project/${projectId}/furnitures/${step}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(furnitures),
    })
  );
};

export const updateFinalProject = (projectId, finalProject) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project-final-project/${projectId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(finalProject),
    })
  );
};

export const updateComments = (projectId, comments) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/comments/${projectId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(comments),
    })
  );
};

export const updateChecklist = (projectId, type, checklist) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/checklist/${projectId}/${type}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(checklist),
    })
  );
};

export const updateProjectOwner = (projectId, ownerId, ownerType) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project/${projectId}/owner/${ownerType}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify({
        ownerId,
      }),
    })
  );
};

export const updateProjectComplexity = (projectId, value) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project/${projectId}/complexity`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify({
        value,
      }),
    })
  );
};

export const updateApproveProject = (projectId) => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(`${API}/project/approve-project/${projectId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const getProject = (projectId, opt) => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(
      `${API}/project/${projectId}${
        opt && opt.isDetailed ? "?detailed=1" : ""
      }`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "x-access-token": token,
        },
      }
    )
  );
};

export const updateStep = (projectId, step) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project-completed-step/${projectId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify({ step: step }),
    })
  );
};

export const deleteStepCorrection = (projectId, stepCorrectionId) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project/${projectId}/step-correction/${stepCorrectionId}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const addStepCorrection = (projectId, data) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project/${projectId}/step-correction`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(data),
    })
  );
};

export const addGroupToProject = (projectId, groupId) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project/${projectId}/addFurnituresGroup/${groupId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const updateProjectStatus = (projectId, status) => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(`${API}/project/update-status/${projectId}/${status}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const updateReviewProject = (projectId, userEmail, userName) => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(`${API}/project/approve-review/${projectId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify({ email: userEmail, name: userName }),
    })
  );
};

export const waitUserFeedback = (projectId, userEmail, userName) => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(`${API}/project/wait-user-feedback/${projectId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify({ email: userEmail, name: userName }),
    })
  );
};

export const updateLogDates = (projectId, status) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project/log-dates/${projectId}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify({ nameValuePair: status }),
    })
  );
};

export const updateRender = (projectId, type, data) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project/${projectId}/render/${type}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(data),
    })
  );
};

export const sendProjectNotification = (projectId, notification) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project/${projectId}/notification`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(notification),
    })
  );
};

export const getNumberProjectsByUser = (userId) => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(`${API}/project/user/number/${userId}`, {
      method: "GET",
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const sendFilesDropBox = (projectId) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project/${projectId}/dropbox/learning`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const updateLearningEnvironmentAndInspirations = (
  projectId,
  step,
  data
) => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(`${API}/project/${projectId}/${step}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(data),
    })
  );
};

export const selectUniqueFurniture = (projectId, furnitureId, value, step) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(
      `${API}/project/${projectId}/furniture/${furnitureId}/select/${step}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "x-access-token": token,
        },
        body: JSON.stringify({ value }),
      }
    )
  );
};

export const archiveProject = (projectId) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project/archive/${projectId}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const copyFurnituresTo = (opt) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project/copy_furnitures_to`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(opt),
    })
  );
};

export const reactivateProjectStatus = (projectId, status) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project/${projectId}/reactivate`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify({ status }),
    })
  );
};

export const addOtherFilesFromFurnitures = (id) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project/${id}/add_other_files`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const updateReviewAdmin = (projectId, status, action) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project/admin/update/${projectId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify({ status, action }),
    })
  );
};

export const postPDFDefaultPageItemsByCompany = (data) => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(`${API}/project/pdf/default_page_items`, {
      method: "POST",
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify(data),
    })
  );
};

export const updateProjectName = (projectId, newValue) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project/admin/update-project-name/${projectId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify({ newValue }),
    })
  );
};

export const createDropboxFolder = (projectId) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project/${projectId}/create-dropbox-folder`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const updateRenderImageByWorkPlanLayout = async (opt) => {
  const { source, thumbnail, review, type, workPlanLayout, labels, projectId } =
    opt;
  const token = getCookie("token");

  const data = {
    source: source,
    thumbnail: thumbnail,
    workPlanLayout: workPlanLayout,
    review,
    labels,
    type,
  };
  await fetch(`${API}/project/${projectId}/update-render-image`, {
    method: "POST",
    headers: {
      accept: "application/json",
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
      "x-access-token": token,
    },
    body: JSON.stringify({
      categoryName: type,
      data,
    }),
  });
};

export const uploadRenderFileV2 = async (opt) => {
  const { file, projectId, folder, type, workPlanLayout } = opt;
  const token = getCookie("token");
  const formData = new FormData();

  if (file) {
    formData.append("file", file);
    formData.append("folder", folder);
    formData.append("type", type);
    formData.append("workPlanLayout", workPlanLayout);
  }

  const res = await requestWrapper(
    fetch(`${API}/project/${projectId}/render/uploadV2`, {
      method: "POST",
      body: formData,
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
  await fetch(res.uploadUrl, {
    method: "PUT",
    body: file,
  });

  const awsUrl = new URL(res.uploadUrl);
  const fileUrl = `${awsUrl.protocol}//${awsUrl.host}${awsUrl.pathname}`;
  return fileUrl;
};

export const copyWorkPlanLayoutToRender = async (projectId, data) => {
  const token = getCookie("token");

  return await requestWrapper(
    fetch(`${API}/project/${projectId}/copy-work-plan-layout-to-render`, {
      method: "POST",
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${token}`,
        "content-type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify(data),
    })
  );
};

export const getProjectsByUser = (userId) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project/projects-by-user/${userId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const removeAllFurnituresByReviewType = (projectId, reviewType) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(
      `${API}/project/${projectId}/remove_all_furnitures_by_review_type/${reviewType}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "x-access-token": token,
        },
      }
    )
  );
};
export const removeFurnitureCustomSenttings = (
  projectId,
  furnitureId,
  step
) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(
      `${API}/project/${projectId}/remove-furniture-custom-senttings/${furnitureId}/${step}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "x-access-token": token,
        },
      }
    )
  );
};

export const extendReviewAutomation = (projectId, date) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project/${projectId}/extend_review_automation`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify({ date }),
    })
  );
};

import React, { useState } from "react";

import ConfirmationButton from "../../common/ConfirmationButton";
import Loader from "react-loader-spinner";

function DeleteStepButton({ onRemove }) {
  const [isLoading, setIsloading] = useState(false);

  function onRemoveClick() {
    setIsloading(true);
    onRemove().catch(() => {
      setIsloading(false);
    });
  }
  return (
    <ConfirmationButton
      outline
      size="sm"
      theme="light"
      onClick={onRemoveClick}
      disabled={isLoading}
      className="ml-1"
      style={{ padding: "0px", border: "none" }}
    >
      {isLoading ? (
        <Loader width="15px" height="15px" type="Rings" color="black" />
      ) : (
        <span className="material-icons" style={{ color: "red" }}>
          delete
        </span>
      )}
    </ConfirmationButton>
  );
}

DeleteStepButton.propTypes = {};

export default DeleteStepButton;

import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { Row, Card, Col, CardBody, Button } from "shards-react";
import { guestAllQuestion } from "../../utils/util";
import { ShowAnswers } from "../common/ShowAnswers";

import { getDefaultQuestionByUser } from "../../actions/questions";

const AnswerDefaultQuestion = ({
  answerDefaultQuestion,
  sendLightbox,
  user,
}) => {
  const [allQuestions, setAllQuestions] = useState([]);
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const [inputList, setInputList] = useState([]);

  useEffect(() => {
    getDefaultQuestionByUser(user._id).then((data) => {
      setAllQuestions(guestAllQuestion(data.template));
    });
  }, []);

  return (
    <Card small className="mb-4">
      <CardBody className="p-0">
        <Row>
          <Col>
            <h6 className="text-center m-0 mt-2">Questionário Perfil</h6>
          </Col>
        </Row>
        <div className="user-details__user-data border-top border-bottom p-4">
          <CSVLink
            data={answerDefaultQuestion}
            filename={"questionarioPerfil.csv"}
          >
            <Button theme="accent" className="btn-outline-primary float-right">
              Exportar CSV
            </Button>
          </CSVLink>
          <ShowAnswers
            answers={answerDefaultQuestion}
            allQuestions={allQuestions}
            sendLightbox={sendLightbox}
            setInputList={setInputList}
            setShow={setShow}
            setTitle={setTitle}
            title={title}
            show={show}
            inputList={inputList}
            seeAlternative={true}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default AnswerDefaultQuestion;

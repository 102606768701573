import React, { useState, useCallback } from "react";

import { FormSelect, FormInput, Button, FormCheckbox } from "shards-react";
import SelectSearch from "react-select-search";
import { debounce } from "../../utils/throttle";
import MobiTagManager from "./MobiTagManager.js";
import IfAllowed from "../common/IfAllowed";
import "./MobiFilter.scss";

function Filters({
  suppliers,
  styles,
  colors,
  typologies,
  categories,
  subCategories,
  projectTemplateNames,
  initialFilterValue,
  onFilterUpdate,
  filter,
  refreshTags,
  colorTones,
  features,
  oldTags,
}) {
  const [search, setSearch] = useState("");
  const onSearchUpdate = useCallback(
    debounce((text) => {
      onFilterUpdate({ search: text });
    }, 800),
    []
  );

  return (
    <div>
      <div className="d-flex filter-row">
        <div className="mt-2 mr-1">
          <div className="label">Nome/SKU</div>
          <FormInput
            value={search}
            onChange={(ev) => {
              onSearchUpdate(ev.target.value);
              setSearch(ev.target.value);
            }}
            placeholder="Digite aqui..."
          />
        </div>
        <div className="mt-2 mr-1 ml-1">
          <div className="label">Disponivel</div>
          <FormSelect
            size="sm"
            value={filter.onlyAvailable && filter.onlyAvailable}
            onChange={(ev) => {
              onFilterUpdate({
                onlyAvailable:
                  ev.target.value === "null"
                    ? "null"
                    : ev.target.value === "true",
              });
            }}
          >
            <option value="null">Todos</option>
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </FormSelect>
        </div>
        <div className="mt-2 mr-1 ml-1">
          <div className="label">Buscar por Fornecedor</div>
          <SelectSearch
            options={suppliers.map((sup) => {
              return { name: sup, value: sup };
            })}
            search={true}
            printOptions="on-focus"
            closeOnSelect={false}
            value={filter.supplier}
            onChange={(value) => {
              onFilterUpdate({
                supplier: value === filter.supplier ? "" : value,
              });
            }}
            placeholder="Selecione"
          />
        </div>
        <div className="mt-2 mr-1 ml-1">
          <div className="label">Pode Usar</div>
          <FormSelect
            size="sm"
            value={filter.notUse && filter.notUse}
            onChange={(ev) => {
              onFilterUpdate({
                notUse:
                  ev.target.value === "null"
                    ? "null"
                    : ev.target.value === "true",
              });
            }}
          >
            <option value="null">Todos</option>
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </FormSelect>
        </div>
        <div className="mt-2 d-flex align-items-end mr-1 ml-1">
          <FormCheckbox
            toggle
            checked={filter.hasFile}
            onChange={() => {
              onFilterUpdate({
                hasFile: !filter.hasFile,
              });
            }}
            small
          >
            <strong>Apenas com bloco</strong>
          </FormCheckbox>
        </div>
        <div />
      </div>

      <div className="d-flex filter-row">
        <IfAllowed
          resource="admin-ladecora:mobi-tag"
          ifNot={() => {
            return (
              <>
                <div className="mt-2 mr-1 ml-1">
                  <div className="label">Categorias</div>
                  <div className="d-flex">
                    <SelectSearch
                      options={oldTags.categories.map((tag) => {
                        return { name: tag.name, value: tag._id };
                      })}
                      search={true}
                      multiple
                      closeOnSelect={false}
                      printOptions="on-focus"
                      value={filter.oldTags.categories}
                      onChange={(values) => {
                        onFilterUpdate({
                          oldTags: {
                            ...filter.oldTags,
                            categories: values,
                          },
                        });
                      }}
                      placeholder="Selecione"
                    />
                  </div>
                </div>
                <div className="mt-2 mr-1 ml-1">
                  <div className="label">Estilos</div>
                  <div className="d-flex">
                    <SelectSearch
                      options={oldTags.styles.map((tag) => {
                        return { name: tag.name, value: tag._id };
                      })}
                      search={true}
                      multiple
                      closeOnSelect={false}
                      printOptions="on-focus"
                      value={filter.oldTags.styles}
                      onChange={(values) => {
                        onFilterUpdate({
                          oldTags: {
                            ...filter.oldTags,
                            styles: values,
                          },
                        });
                      }}
                      placeholder="Selecione"
                    />
                  </div>
                </div>
                <div className="mt-2 mr-1 ml-1">
                  <div className="label">Cores</div>
                  <div className="d-flex">
                    <SelectSearch
                      options={oldTags.colors.map((tag) => {
                        return { name: tag.name, value: tag._id };
                      })}
                      search={true}
                      multiple
                      closeOnSelect={false}
                      printOptions="on-focus"
                      value={filter.oldTags.colors}
                      onChange={(values) => {
                        onFilterUpdate({
                          oldTags: {
                            ...filter.oldTags,
                            colors: values,
                          },
                        });
                      }}
                      placeholder="Selecione"
                    />
                  </div>
                </div>
                <div className="mt-2 mr-1 ml-1">
                  <div className="label">Tipologias</div>
                  <div className="d-flex">
                    <SelectSearch
                      options={oldTags.typologies.map((tag) => {
                        return { name: tag.name, value: tag._id };
                      })}
                      search={true}
                      multiple
                      closeOnSelect={false}
                      printOptions="on-focus"
                      value={filter.oldTags.typologies}
                      onChange={(values) => {
                        onFilterUpdate({
                          oldTags: {
                            ...filter.oldTags,
                            typologies: values,
                          },
                        });
                      }}
                      placeholder="Selecione"
                    />
                  </div>
                </div>
              </>
            );
          }}
        >
          <div className="mt-2 mr-1 ml-1">
            <div className="label">Estilos</div>
            <div className="d-flex">
              <SelectSearch
                options={styles.map((tag) => {
                  return { name: tag.name, value: tag._id };
                })}
                search={true}
                multiple
                closeOnSelect={false}
                printOptions="on-focus"
                value={filter.styles}
                onChange={(values) => {
                  onFilterUpdate({ styles: values });
                }}
                placeholder="Selecione"
              />
              <MobiTagManager onChange={refreshTags} category="style" />
            </div>
          </div>
          <div className="mt-2 mr-1 ml-1">
            <div className="label">Cores</div>
            <div className="d-flex">
              <SelectSearch
                options={colors.map((tag) => {
                  return { name: tag.name, value: tag._id };
                })}
                search={true}
                closeOnSelect={false}
                multiple
                printOptions="on-focus"
                value={filter.colors}
                onChange={(values) => {
                  onFilterUpdate({ colors: values });
                }}
                placeholder="Selecione"
              />
              <MobiTagManager onChange={refreshTags} category="color" />
            </div>
          </div>
          <div className="mt-2 mr-1 ml-1">
            <div className="label">Tom do ambiente</div>
            <div className="d-flex">
              <SelectSearch
                options={colorTones.map((tag) => {
                  return { name: tag.name, value: tag._id };
                })}
                search={true}
                closeOnSelect={false}
                multiple
                printOptions="on-focus"
                value={filter.colorTones}
                onChange={(values) => {
                  onFilterUpdate({ colorTones: values });
                }}
                placeholder="Selecione"
              />
              <MobiTagManager onChange={refreshTags} category="colorTone" />
            </div>
          </div>
        </IfAllowed>

        <div className="mt-2 mr-1 ml-1">
          <div className="label">Ambientes</div>
          <SelectSearch
            options={projectTemplateNames.map((tag) => {
              return { name: tag, value: tag };
            })}
            search={true}
            multiple
            closeOnSelect={false}
            printOptions="on-focus"
            value={filter.projectTemplateNames}
            onChange={(values) => {
              onFilterUpdate({
                projectTemplateNames: values,
              });
            }}
            placeholder="Selecione"
          />
        </div>

        <div className="mt-2 d-flex align-items-end mr-1 ml-1">
          <div className="label" />
          <FormCheckbox
            toggle
            checked={filter.hasOtherFiles}
            onChange={() => {
              onFilterUpdate({
                hasOtherFiles: !filter.hasOtherFiles,
              });
            }}
            small
          >
            <strong>Possui detalhamento</strong>
          </FormCheckbox>
        </div>
      </div>
      <div className="d-flex filter-row">
        <IfAllowed resource="admin-ladecora:mobi-tag">
          <div className="mt-2 mr-1 ml-1">
            <div className="label">Tipologia</div>
            <div className="d-flex">
              <SelectSearch
                options={typologies.map((tag) => {
                  return { name: tag.name, value: tag._id };
                })}
                search={true}
                closeOnSelect={true}
                printOptions="on-focus"
                value={filter.typology}
                onChange={(value) => {
                  onFilterUpdate({
                    typology: value === filter.typology ? "" : value,
                  });
                }}
                placeholder="Selecione"
              />
              <MobiTagManager
                allowNested={true}
                onChange={refreshTags}
                category="typology"
              />
            </div>
          </div>
          <div className="mt-2 mr-1 ml-1">
            <div className="label">Categoria</div>
            <div className="d-flex">
              <SelectSearch
                options={categories.map((tag) => {
                  return { name: tag.name, value: tag._id };
                })}
                search={true}
                closeOnSelect={true}
                printOptions="on-focus"
                value={filter.category}
                onChange={(value) => {
                  onFilterUpdate({
                    category: value === filter.category ? "" : value,
                  });
                }}
                placeholder="Selecione"
              />
              {filter.typology && (
                <MobiTagManager
                  allowNested={true}
                  onChange={() => {
                    onFilterUpdate({ typology: filter.typology });
                  }}
                  parent={[
                    typologies.filter((t) => t._id === filter.typology)[0],
                  ]}
                  category="typology"
                />
              )}
            </div>
          </div>
          <div className="mt-2 mr-1 ml-1">
            <div className="label">Sub Categoria</div>
            <div className="d-flex">
              <SelectSearch
                options={subCategories.map((tag) => {
                  return { name: tag.name, value: tag._id };
                })}
                search={true}
                closeOnSelect={true}
                printOptions="on-focus"
                value={filter.subCategory}
                onChange={(value) => {
                  onFilterUpdate({
                    subCategory: value === filter.subCategory ? "" : value,
                  });
                }}
                placeholder="Selecione"
              />
              {filter.category && (
                <MobiTagManager
                  allowNested={true}
                  onChange={() => {
                    onFilterUpdate({ category: filter.category });
                  }}
                  parent={[
                    typologies.filter((t) => t._id === filter.typology)[0],
                    categories.filter((t) => t._id === filter.category)[0],
                  ]}
                  category="typology"
                />
              )}
            </div>
          </div>
        </IfAllowed>
        <div className="mt-2 mr-1 ml-1">
          <div className="label">Características</div>
          <div className="d-flex">
            <SelectSearch
              options={features.map((tag) => {
                return { name: tag.name, value: tag._id };
              })}
              search={true}
              closeOnSelect={false}
              multiple
              printOptions="on-focus"
              value={filter.features}
              onChange={(values) => {
                onFilterUpdate({ features: values });
              }}
              placeholder="Selecione"
            />
            <MobiTagManager onChange={refreshTags} category="feature" />
          </div>
        </div>
        <div className="mt-2 d-flex align-items-end mr-1 ml-1">
          <FormCheckbox
            toggle
            checked={filter.active}
            onChange={() => {
              onFilterUpdate({
                active: !filter.active,
              });
            }}
            small
          >
            <strong>Ativos</strong>
          </FormCheckbox>
        </div>
      </div>

      <div className="d-flex">
        <div className="mt-2 mr-1 ml-1">
          <div className="label">Ordem</div>
          <FormSelect
            size="sm"
            value={filter.order}
            className="form-select"
            onChange={(ev) => {
              onFilterUpdate({
                order: ev.target.value,
              });
            }}
          >
            <option value="frequency_used">Frequência</option>
            <option value="likes">Likes</option>
            <option value="createdAt">Data</option>
          </FormSelect>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <Button
          theme="success"
          size="md"
          className="mr-4"
          outline
          onClick={() => {
            onFilterUpdate(initialFilterValue);
            setSearch("");
          }}
        >
          Limpar filtros
        </Button>
      </div>
    </div>
  );
}

Filters.propTypes = {};

export default Filters;

import React, { useEffect, useState } from "react";

import {
  Container,
  Row,
  Card,
  Col,
  CardBody,
  Button,
  Form,
  FormInput,
  FormCheckbox,
  FormFeedback,
} from "shards-react";
import ConfirmationCheckbox from "../common/ConfirmationCheckbox";

import ButtonPictureUpload from "../common/ButtonPictureUpload";
import DropzonePicture from "../common/DropzonePicture";

import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { BlockPicker } from "react-color";
import Loader from "react-loader-spinner";
import IfAllowed from "../common/IfAllowed";
import { formatBytes, getDescendantProp } from "../../utils/sanitize";
import { generateToken } from "../../actions/company";
import PhoneBR from "../shared/PhoneBR";
import { phoneValidation } from "../../utils/form_validation";
import ReactTags from "../ReactTags/ReactTags";
import { listAllSuppliers } from "../../actions/mobi";

function Partner({ company, onChange, onSave, isLoading }) {
  const isNew = !company._id;
  const history = useHistory();
  const [token, setToken] = useState("");
  const [hasExternalLoginIntegration, setHasExternalLoginIntegration] =
    useState(!!company.externalLoginUrl);
  const [suppliers, setSuppliers] = useState([]);

  const [logo, setLogo] = useState({
    name: company.logo,
    file: null,
  });
  const [favIcon, setFavIcon] = useState({
    name: company.favIcon,
    file: null,
  });
  const [emailHeaderImage, setEmailHeaderImage] = useState({
    name:
      company.email && company.email.emailHeaderImage
        ? company.email.emailHeaderImage
        : "",
    file: null,
  });
  const [pdfCover, setPdfCover] = useState({
    name: company.pdf && company.pdf.cover ? company.pdf.cover : "",
    file: null,
  });
  const [pdfThanks, setPdfThanks] = useState({
    name: company.pdf && company.pdf.thanks ? company.pdf.thanks : "",
    file: null,
  });
  const [formValidations, setFormValidations] = useState({
    phone: {
      validators: [phoneValidation],
      isValid: true,
      accessor: "phone",
    },
  });

  useEffect(() => {
    listAllSuppliers().then((s) => {
      setSuppliers(s);
    });
  }, []);

  const isInputValid = (field, value) => {
    let fieldValidations = formValidations[field].validators;
    let result = fieldValidations.reduce((isValid, nextValidation) => {
      return isValid && nextValidation(value, company);
    }, true);
    if (formValidations[field].postValidate) {
      formValidations[field].postValidate(result);
    }
    return result;
  };

  const getHtmlValidations = (key, formValidations) => {
    return {
      [typeof formValidations[key].error === "undefined"
        ? "neutral"
        : formValidations[key].error
        ? "invalid"
        : "valid"]: "true",
    };
  };

  const isFormValid = () => {
    let keys = Object.keys(formValidations);
    return keys.reduce((isValid, key) => {
      return isValid && formValidations[key].isValid;
    }, true);
  };
  const isFieldValid = (validatorName, accessor) => {
    let value = getDescendantProp(company, accessor ? accessor : validatorName);
    let fieldValidations = formValidations[validatorName].validators;

    let result =
      formValidations[validatorName].required || value
        ? fieldValidations.reduce((isValid, nextValidation) => {
            return isValid && nextValidation(value, company);
          }, true)
        : true;
    return result;
  };

  const validate = () => {
    let fieldNames = Object.keys(formValidations);
    let validations = fieldNames.reduce((acc, current) => {
      const accessor = formValidations[current].accessor;
      acc[current] = isFieldValid(current, accessor);
      return acc;
    }, {});

    applyValidationsToForm(validations);
  };

  const applyValidationsToForm = (validations) => {
    let keys = Object.keys(formValidations);
    keys.forEach((key) => {
      formValidations[key].isValid = validations[key];
      if (!validations[key]) {
        formValidations[key].error = true;
      }
    });

    setFormValidations({ ...formValidations });
  };

  const onSaveClick = (ev) => {
    ev.preventDefault();
    validate();
    if (!isFormValid()) {
      alert("Existem campos inválidos");
      return;
    }

    const files = {};
    if (logo.file) {
      files.logo = logo.file;
    }
    if (favIcon.file) {
      files.favIcon = favIcon.file;
    }

    if (emailHeaderImage.file) {
      files.emailHeaderImage = emailHeaderImage.file;
    }

    if (pdfCover.file) {
      files.pdfCover = pdfCover.file;
    }

    if (pdfThanks.file) {
      files.pdfThanks = pdfThanks.file;
    }
    onSave(company, files);
    return false;
  };

  const onGenerateTokenClick = () => {
    generateToken(company._id).then((res) => {
      setToken(res.token);
    });
  };

  return (
    <Container fluid className="main-content-container px-4">
      <Row className="page-header py-4">
        <Col md="6">
          <button
            className="transparent"
            type="button"
            onClick={() => {
              history.goBack();
            }}
          >
            VOLTAR
          </button>
          <h3>{isNew ? "Cadastrar" : "Alterar"} Parceiro</h3>
        </Col>
      </Row>

      <Card>
        <CardBody className="pb-0 mx-auto" style={{ maxWidth: "1360px" }}>
          <Form className="py-4" onSubmit={onSaveClick}>
            <Row form>
              <Col md="3" className="form-group">
                <div className="label">Logotipo</div>
                <ButtonPictureUpload
                  onChange={(file, name) => setLogo({ file, name })}
                  isNew={isNew}
                  value={company.logo}
                  placeholder="Envie seu logotipo"
                />
              </Col>
              <Col md="3" className="form-group">
                <div className="label">Favicon</div>
                <ButtonPictureUpload
                  onChange={(file, name) => setFavIcon({ file, name })}
                  isNew={isNew}
                  value={company.favIcon}
                  placeholder="Envie seu Favicon"
                  validate={(file) => {
                    const maxSize = 1048576;
                    const isFileTypeValid = file.type
                      .toLowerCase()
                      .match("(/png|/ico)");
                    const isFileSizeValid = file.size < maxSize;
                    const isValid = isFileTypeValid && isFileSizeValid;

                    if (!isFileSizeValid) {
                      toast.error(
                        `O arquivo pode ter no máximo ${formatBytes(maxSize)}.`,
                        {
                          autoClose: 5000,
                        }
                      );
                    }
                    if (!isFileTypeValid) {
                      toast.error("Apenas arquivos .png são permitidos.", {
                        autoClose: 5000,
                      });
                    }
                    return isValid;
                  }}
                />
              </Col>
              <Col md="6">
                <Row form>
                  {company.label !== "ladecora" ? (
                    <>
                      <IfAllowed resource="admin-ladecora:partner:enabled">
                        <Col md="12" className="form-group">
                          <ConfirmationCheckbox
                            options={{
                              text: "Habilitado",
                              warningMessage: company.enabled
                                ? "Ao desabilitar o parceiro, os projetos, usuários, vouchers e formulários vinculados não serão exibidos na plataforma."
                                : "",
                            }}
                            toggle
                            disabled={isLoading}
                            checked={!!company.enabled}
                            onChange={() =>
                              onChange({
                                ...company,
                                enabled: !company.enabled,
                              })
                            }
                            small
                          />
                        </Col>
                      </IfAllowed>
                      <IfAllowed resource="admin-ladecora:partner:enabled">
                        <Col md="12" className="form-group">
                          <ConfirmationCheckbox
                            options={{
                              text: "Habilitar questionários ladecora",
                              warningMessage:
                                !company.useDefaultProjectTemplates
                                  ? "Ao habilitar, os questionários da ladecora serão exibidos juntamente com os do parceiro"
                                  : "",
                            }}
                            toggle
                            disabled={isLoading}
                            checked={!!company.useDefaultProjectTemplates}
                            onChange={() =>
                              onChange({
                                ...company,
                                useDefaultProjectTemplates:
                                  !company.useDefaultProjectTemplates,
                              })
                            }
                            small
                          />
                        </Col>
                      </IfAllowed>
                    </>
                  ) : null}

                  <Col md="12" className="form-group">
                    <div className="label">Identificador</div>
                    <div className="d-flex flex-row align-items-center ">
                      <FormInput
                        required
                        disabled={!isNew || isLoading}
                        id="identifier"
                        placeholder="Ex: aplicativo"
                        value={company.label}
                        onChange={(ev) =>
                          onChange({
                            ...company,
                            label: ev.target.value,
                          })
                        }
                        style={{ maxWidth: "120px" }}
                      />
                      <span
                        style={{
                          padding: "0.4375rem 0.75rem",
                          fontFamily: "sans-serif",
                        }}
                      >
                        .prodeco.com.br
                      </span>
                    </div>
                  </Col>
                  <Col md="12" className="form-group">
                    <div className="label">Nome do sistema</div>
                    <FormInput
                      required
                      disabled={isLoading}
                      onChange={(ev) =>
                        onChange({ ...company, name: ev.target.value })
                      }
                      id="name"
                      value={company.name}
                    />
                  </Col>
                  <IfAllowed resource="admin-ladecora:partner:enabled">
                    <Col md="12" className="form-group">
                      <div className="label">ID Merlin</div>
                      <FormInput
                        disabled={isLoading}
                        onChange={(ev) =>
                          onChange({ ...company, idMerlin: ev.target.value })
                        }
                        id="name"
                        value={company.idMerlin}
                      />
                    </Col>
                  </IfAllowed>
                  <Col md="12" className="form-group">
                    <div className="label">Whatsapp</div>
                    <PhoneBR
                      id="user-input-phone"
                      value={company.phone}
                      onChange={(value) => {
                        if (!value) {
                          formValidations.phone.error = undefined;
                        } else if (isInputValid("phone", value)) {
                          formValidations.phone.error = false;
                        } else {
                          formValidations.phone.error = true;
                        }
                        setFormValidations({ ...formValidations });
                        onChange({ ...company, phone: value });
                      }}
                      placeholder="Informe seu telefone"
                      className="form-control"
                      {...getHtmlValidations("phone", formValidations)}
                    />
                    <FormFeedback valid />
                    <FormFeedback invalid>Telefone inválido</FormFeedback>
                  </Col>
                </Row>
              </Col>
            </Row>
            <IfAllowed resource="admin-ladecora:partner:enabled">
              <div className="mb-3">
                <hr />
                <h5 className="text-center">Mobis</h5>
                <Row className="mb-5">
                  <Col md="12">
                    <Row className="d-flex align-items-center justify-content-start">
                      {Array.isArray(company.supplierBlacklist) ? (
                        <Col md="9">
                          <div>
                            <div>Fornecedores bloqueados</div>
                            <ReactTags
                              placeholderText="Digite aqui..."
                              tags={company.supplierBlacklist.map((e, i) => {
                                return { name: e, id: i + 1 };
                              })}
                              autofocus={false}
                              suggestions={suppliers.map(
                                (categorie, categorieIdx) => {
                                  return {
                                    id: categorieIdx + 1,
                                    name: categorie,
                                  };
                                }
                              )}
                              onDelete={(index) => {
                                company.supplierBlacklist =
                                  company.supplierBlacklist.filter(
                                    (tag, i) => i !== index
                                  );
                                onChange({
                                  ...company,
                                });
                              }}
                              allowNew={true}
                              onAddition={(obj) => {
                                if (
                                  !company.supplierBlacklist.find(
                                    (t) => t === obj
                                  )
                                ) {
                                  company.supplierBlacklist.push(obj.name);
                                  onChange({
                                    ...company,
                                  });
                                }
                              }}
                            />
                          </div>
                        </Col>
                      ) : null}
                    </Row>
                  </Col>
                </Row>
              </div>
            </IfAllowed>
            <div className="mb-3">
              <hr />
              <h5 className="text-center">Projetos</h5>
            </div>
            {company._id ? (
              <Row form>
                <Col md="6" className="form-group">
                  <div className="label">Projeto de exemplo</div>
                  <FormInput
                    disabled={isLoading}
                    onChange={(ev) =>
                      onChange({
                        ...company,
                        exampleProjectId: ev.target.value,
                      })
                    }
                    value={company.exampleProjectId}
                  />
                </Col>
              </Row>
            ) : null}

            <Row form>
              <Col lg="12">
                <Row form>
                  <Col md="6" className="form-group">
                    <div className="label">Preço Adicional</div>
                    <FormInput
                      required
                      type="number"
                      min="0"
                      disabled={isLoading}
                      onChange={(ev) =>
                        onChange({
                          ...company,
                          priceIncrease: Number(ev.target.value),
                        })
                      }
                      id="priceIncrease"
                      value={company.priceIncrease}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        marginTop: "15px",
                      }}
                    >
                      <label style={{ fontSize: "10px" }}>
                        1 ambiente R$ {299 + company.priceIncrease}
                      </label>
                      <label style={{ fontSize: "10px" }}>
                        2 ambientes R$ {598 + company.priceIncrease * 2}
                      </label>
                      <label style={{ fontSize: "10px" }}>
                        3 ambientes R$ {897 + company.priceIncrease * 3}
                      </label>
                      <label style={{ fontSize: "10px" }}>
                        Studio R$ {1196 + company.priceIncrease * 4}
                      </label>
                    </div>
                  </Col>
                  <Col md="6">
                    {company._id ? (
                      <div className="d-flex align-items-center justify-content-center h-100 flex-column">
                        <Button
                          disabled={isLoading}
                          className="mt-3 mb-3"
                          onClick={() =>
                            history.push(
                              `/partner/${company.label}/project-templates`
                            )
                          }
                        >
                          Editar projetos
                        </Button>
                        <IfAllowed resource="admin-ladecora:partner:gift">
                          <Button
                            className="mt-3 mb-3"
                            disabled={isLoading}
                            onClick={() =>
                              history.push(`/partner/${company.label}/gifts`)
                            }
                          >
                            Configurar presentes
                          </Button>
                        </IfAllowed>
                      </div>
                    ) : null}
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="mb-3">
              <hr />
              <h5 className="text-center">Cores</h5>
            </div>
            <Row className="d-flex justify-content-center align-items-center">
              <Col md="4" className="form-group">
                <div className="label">Fundo da Logo</div>
                <BlockPicker
                  id="navBarColor"
                  color={company.navBarColor}
                  onChangeComplete={(color) =>
                    onChange({
                      ...company,
                      navBarColor: color.hex,
                    })
                  }
                />
              </Col>
              <Col md="2" />
              <Col md="4" className="form-group">
                <div className="label">Botões</div>
                <BlockPicker
                  id="color"
                  color={company.color}
                  onChangeComplete={(color) =>
                    onChange({
                      ...company,
                      color: color.hex,
                    })
                  }
                />
              </Col>
            </Row>
            <div className="mb-3">
              <hr />
              <h5 className="text-center">Anúncios</h5>
            </div>
            <Row className="d-flex justify-content-center align-items-center">
              <Col md="4" className="form-group">
                <div className="label">Facebook Pixel</div>
                <FormInput
                  type="text"
                  disabled={isLoading}
                  onChange={(ev) => {
                    company.tracking.facebook = ev.target.value;
                    onChange(company);
                  }}
                  id="facebookPixel"
                  value={company.tracking.facebook}
                />
              </Col>
              <Col md="4" className="form-group">
                <div className="label">Google Tag Manager</div>
                <FormInput
                  type="text"
                  disabled={isLoading}
                  onChange={(ev) => {
                    company.tracking.tagManager = ev.target.value;
                    onChange(company);
                  }}
                  id="tagManager"
                  value={company.tracking.tagManager}
                />
              </Col>
              <Col md="4" className="form-group" />
            </Row>
            <div className="mb-3">
              <hr />
              <h5 className="text-center">Integração</h5>
              <Row className="d-flex justify-content-center align-items-center">
                <Col md="12">
                  <Row>
                    <Col md="6">
                      <FormCheckbox
                        toggle
                        checked={hasExternalLoginIntegration}
                        onChange={() => {
                          const value = !hasExternalLoginIntegration;
                          setHasExternalLoginIntegration(value);
                          if (!value) {
                            onChange({
                              ...company,
                              externalLoginUrl: "",
                            });
                          }
                        }}
                        small
                      >
                        Possui login externo
                      </FormCheckbox>
                    </Col>
                    {hasExternalLoginIntegration ? (
                      <Col md="6" className="form-group">
                        <div className="label">
                          URL de retorno (Login externo)
                        </div>
                        <div className="d-flex">
                          <FormInput
                            disabled={isLoading}
                            onChange={(ev) =>
                              onChange({
                                ...company,
                                externalLoginUrl: ev.target.value,
                              })
                            }
                            id="externalLoginUrl"
                            value={company.externalLoginUrl}
                          />
                        </div>
                      </Col>
                    ) : null}
                  </Row>
                </Col>
                {company._id ? (
                  <Col md="12" className="form-group">
                    <div className="label">Gerar token</div>
                    <div className="d-flex">
                      {token ? (
                        <FormInput
                          required
                          className="mr-2"
                          id="token"
                          value={token}
                          onChange={() => {}}
                        />
                      ) : null}
                      <Button onClick={onGenerateTokenClick}>
                        Gerar token
                      </Button>
                    </div>
                  </Col>
                ) : null}
              </Row>
            </div>

            <div className="mb-3">
              <hr />
              <h5 className="text-center">Email</h5>
              <Row className="mb-5">
                <Col md="12">
                  <Row className="d-flex align-items-center justify-content-start">
                    <Col md="3">
                      <FormCheckbox
                        toggle
                        checked={Array.isArray(company.email?.custom)}
                        onChange={() => {
                          onChange({
                            ...company,
                            email: {
                              ...company.email,
                              custom: Array.isArray(company.email?.custom)
                                ? undefined
                                : [],
                            },
                          });
                        }}
                        small
                      >
                        Email Projeto entregue
                      </FormCheckbox>
                    </Col>
                    {Array.isArray(company.email?.custom) ? (
                      <Col md="9">
                        <div>
                          <ReactTags
                            placeholderText="Digite o email..."
                            tags={company.email.custom.map((e) => {
                              return { name: e };
                            })}
                            autofocus={false}
                            onDelete={(index) => {
                              company.email.custom =
                                company.email.custom.filter(
                                  (tag, i) => i !== index
                                );
                              onChange({
                                ...company,
                              });
                            }}
                            allowNew={true}
                            onAddition={(obj) => {
                              if (
                                !company.email.custom.find((t) => t === obj)
                              ) {
                                company.email.custom.push(obj.name);
                                onChange({
                                  ...company,
                                });
                              }
                            }}
                          />
                        </div>
                      </Col>
                    ) : null}
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="label">Email para log</div>
                  <FormInput
                    type="email"
                    required
                    disabled={isLoading}
                    onChange={(ev) =>
                      onChange({
                        ...company,
                        logOrigin: ev.target.value,
                      })
                    }
                    id="logOrigin"
                    value={company.logOrigin}
                  />
                </Col>
              </Row>
              <div className="mt-3">
                <p style={{ fontSize: "16px" }} className="text-center">
                  Foto de fundo do email
                </p>

                <DropzonePicture
                  value={emailHeaderImage}
                  isLoading={isLoading}
                  imgStyle={{
                    // maxWidth: "100%",
                    maxHeight: "300px",
                    width: "auto",
                    height: "auto",
                  }}
                  onDrop={(file) =>
                    setEmailHeaderImage({
                      file: file,
                      name: file.name,
                    })
                  }
                />
              </div>
            </div>
            <div className="mb-3">
              <hr />
              <h5 className="text-center">PDF</h5>
              <div className="mt-3">
                <p style={{ fontSize: "16px" }} className="text-center">
                  Capa do book
                </p>
                <div className="text-center" style={{ fontSize: "12px" }}>
                  1122 × 794
                </div>
                <DropzonePicture
                  value={pdfCover}
                  isLoading={isLoading}
                  imgStyle={{
                    // maxWidth: "100%",
                    maxHeight: "300px",
                    width: "auto",
                    height: "auto",
                  }}
                  onDrop={(file) =>
                    setPdfCover({
                      file: file,
                      name: file.name,
                    })
                  }
                />
              </div>
              <div className="mt-3">
                <p style={{ fontSize: "16px" }} className="text-center">
                  Final do book
                </p>
                <div className="text-center" style={{ fontSize: "12px" }}>
                  1122 × 794
                </div>

                <DropzonePicture
                  value={pdfThanks}
                  isLoading={isLoading}
                  imgStyle={{
                    // maxWidth: "100%",
                    maxHeight: "300px",
                    width: "auto",
                    height: "auto",
                  }}
                  onDrop={(file) =>
                    setPdfThanks({
                      file: file,
                      name: file.name,
                    })
                  }
                />
              </div>
            </div>

            <Row form>
              <Col md="12" xs="12" sm="12" className="form-group">
                <hr className="mt-4" />
                <Button
                  size="lg"
                  theme="accent"
                  className="d-flex mx-auto mt-3"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? "Salvando..." : "Salvar"}
                  {isLoading && (
                    <Loader
                      width="30px"
                      height="15px"
                      type="Rings"
                      color="#ffffff"
                    />
                  )}
                </Button>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </Container>
  );
}

Partner.propTypes = {};

export default Partner;

import React from "react";
import VariationForm from "./VariationForm";
import { Col } from "shards-react";

function EnvironmentForm({
  onChange,
  activeStep,
  enterprise,
  environment,
  questions,
  room,
  environmentIndex,
}) {
  function removeRefRoom(env) {
    if (env.refRooms) {
      env.refRooms = env.refRooms.filter((rf) => rf !== room.name);
    }
  }

  const parentRoomName = environment.ref
    ? enterprise.rooms.find((r) => r.name === environment.ref.room).name
    : "";

  return (
    <div>
      <div className="w-100 mb-1 d-flex">
        <div>
          Cômodo: {parentRoomName ? <strong>{parentRoomName} - </strong> : ""}{" "}
          <strong>{environment.name}</strong>
        </div>
        <button
          type="button"
          className="material-icons hover-click ml-1 transparent"
          onClick={() => {
            if (environment.ref) {
              const refRoom = enterprise.rooms.find(
                (r) => r.name === environment.ref.room
              );
              const refEnverionment = refRoom.environments.find(
                (e) => e.name === environment.ref.environment
              );
              removeRefRoom(refEnverionment);
            }

            room.environments = room.environments.filter(
              (e, i) => i !== environmentIndex
            );
            onChange({ ...enterprise }, activeStep);
          }}
          style={{ color: "red" }}
        >
          delete
        </button>
      </div>
      {environment.variations.map((variation, variationsIndex) => {
        return (
          <VariationForm
            key={variationsIndex}
            variation={variation}
            environment={environment}
            onChange={onChange}
            activeStep={activeStep}
            variationsIndex={variationsIndex}
            enterprise={enterprise}
            questions={questions}
          />
        );
      })}
      {!environment.ref ? (
        <Col xs="12">
          <button
            type="button"
            className="w-100 p-2 mt-4 d-flex align-items-center add-hover hover-click justify-content-center transparent"
            onClick={() => {
              environment.variations.push({
                hash: "",
                tags: [],
              });
              onChange({ ...enterprise }, activeStep);
            }}
            style={{
              border: "1px dashed green",
              borderRadius: "5px",
              color: "green",
            }}
          >
            <div>Adicionar cômodo</div>
            <i className="material-icons">add</i>
          </button>
        </Col>
      ) : null}
    </div>
  );
}

EnvironmentForm.propTypes = {};

export default EnvironmentForm;

import React, { useContext, useState } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

import { Row, Col, Button, Modal, ModalBody, ModalHeader } from "shards-react";

import { AsyncTableContext } from "../FilteredTable/AsyncTableContext";
import { CSVLink } from "react-csv";
import PageTitle from "../common/PageTitle";
import IfAllowed from "../common/IfAllowed";
import { exportToCsv } from "../../actions/user";

function TableHeader({ newUser }) {
  const { filter } = useContext(AsyncTableContext);
  const [isLoading, setIsLoading] = useState(false);
  const [csv, setCsv] = useState();
  const onExportToCsvClick = () => {
    setIsLoading(true);
    exportToCsv(
      Object.keys(filter).reduce((acc, cur) => {
        acc[cur] = filter[cur].value;
        return acc;
      }, {})
    )
      .then((res) =>
        res.map((user) => {
          return {
            Email: user.email,
            "First Name":
              user.profile && user.profile.firstName
                ? user.profile.firstName
                : null,
            "Last Name":
              user.profile && user.profile.lastName
                ? user.profile.lastName
                : null,
            Telefone:
              user.profile && user.profile.phone ? user.profile.phone : "",
            Contexto: user.company,
            Criado: user.createdAt,
            Projetos: user._projects,
          };
        })
      )
      .then(setCsv)
      .then(() => setIsLoading(false))
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  return (
    <>
      <Row className="page-header py-4">
        <Col md="6">
          <PageTitle
            title="Usuários"
            subtitle="Visão Geral"
            className="text-sm-left mb-3"
          />
        </Col>
        <Col md="5" className="col d-flex align-items-center" />
      </Row>
      <Row className="mb-3">
        <Col md="12">
          <IfAllowed resource="admin-ladecora:user:edit-user">
            <Button
              disabled={isLoading}
              className="mb-2"
              onClick={newUser}
              id="users-button-add"
            >
              Adicionar usuário
            </Button>
          </IfAllowed>
          <Button
            theme="accent"
            className="btn-outline-primary float-right"
            onClick={onExportToCsvClick}
            disabled={isLoading}
          >
            {isLoading ? "Exportando..." : "Exportar CSV"}
          </Button>
          {csv
            ? ReactDOM.createPortal(
                <Modal
                  size="md"
                  modalContentClassName="card card-small"
                  open={true}
                  toggle={() => setCsv(undefined)}
                >
                  <ModalHeader
                    toggle={() => setCsv(undefined)}
                    className="w-100"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div>Baixe seu arquivo abaixo</div>
                  </ModalHeader>
                  <ModalBody
                    className="w-100"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CSVLink data={csv} filename={"usuarios.csv"}>
                      <Button
                        theme="accent"
                        size="lg"
                        className="btn-outline-primary float-right"
                      >
                        Clique aqui
                      </Button>
                    </CSVLink>
                  </ModalBody>
                </Modal>,
                document.body
              )
            : null}
        </Col>
      </Row>
    </>
  );
}

TableHeader.propTypes = {
  searchableColumns: PropTypes.arrayOf(PropTypes.string),
};

TableHeader.defaultProps = {
  list: [],
  customFilters: [],
  isLoading: false,
  config: {
    pageSizeOptions: [5, 10, 15, 20, 30],
    searchableColumns: null,
    pageSize: 10,
  },
};

export default TableHeader;

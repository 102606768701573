import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ButtonGroup,
} from "shards-react";

import DeleteButton from "./DeleteButton";

import {
  uploadRenderFile,
  deleteRenderFile,
  updateRender,
  addOtherFilesFromFurnitures,
} from "../../../actions/projects";
import { toast } from "react-toastify";

import FileDropzone from "../../file-manager-list/FileDropzone";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { download } from "../../../utils/util";
import { uploadFile } from "../../../services/file";
import IfAllowed from "../../common/IfAllowed";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const RenderItemPicture = ({
  name,
  value,
  project,
  type,
  onUpdate,
  limit,
  sendLightbox,
}) => {
  const [showModal, setShowModal] = useState(false);
  const isValid = value && value.length;
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [items, setItems] = useState(
    value.map((v, i) => {
      return {
        id: v._id,
        index: i + 1,
        value: v.source,
        name: v.name,
        source: v.source,
        labels: v.labels,
        thumbnail: v.thumbnail,
      };
    })
  );
  const [
    isLoadingOtherFilesFromFurnitures,
    setIsLoadingOtherFilesFromFurnitures,
  ] = useState(false);

  useEffect(() => {
    if (value) {
      setItems(
        value.map((v, i) => {
          return {
            id: v._id,
            index: i + 1,
            value: v.source,
            name: v.name,
            source: v.source,
            labels: v.labels,
            thumbnail: v.thumbnail,
          };
        })
      );
    }
  }, [value]);

  function onUpload(files) {
    toast.info("Enviando arquivos", {
      autoClose: 5000,
    });
    setIsUploading(true);

    Promise.all(
      files.map((file) => {
        return uploadFile({
          path: `projects/${project.id}/customer`,
          width: 200,
          file,
        }).then((res) => {
          toast.success("Um arquivo foi enviado com sucesso", {
            autoClose: 5000,
          });
          if (res.file) {
            return {
              name: file.name,
              source: res.file,
              size: file.size,
            };
          } else {
            return {
              name: file.name,
              source: res.fullSize,
              thumbnail: res.thumbnail,
              size: file.size,
            };
          }
        });
      })
    )
      .then((res) => {
        return uploadRenderFile({
          files: res,
          projectId: project.id,
          type,
        });
      })
      .then(onUpdate)
      .then((project) => {
        setItems(
          project.render[type].map((v, i) => {
            return {
              id: v._id,
              index: i + 1,
              value: v.source,
              name: v.name,
              source: v.source,
              thumbnail: v.thumbnail,
            };
          })
        );
        new Promise(function (success) {
          success();
          setIsUploading(false);
        });
      })
      .catch(() => {
        setIsUploading(false);
        toast.error(
          "Não possível foi enviar seu arquivo, por favor, tente novamente.",
          {
            autoClose: 5000,
          }
        );
      });
  }

  function onDelete(id) {
    setIsUploading(true);
    setIsDeleting(id);
    return deleteRenderFile({
      objectId: id,
      type,
      projectId: project.id,
    })
      .then(onUpdate)
      .then((project) => {
        setItems(
          project.render[type].map((v, i) => {
            return {
              id: v._id,
              index: i + 1,
              value: v.source,
              name: v.name,
              source: v.source,
              thumbnail: v.thumbnail,
            };
          })
        );
        setIsDeleting(false);
        setIsUploading(false);
      })
      .catch(() => {
        toast.error(
          "Não foi possível deletar este arquivo, por favor, tente novamente.",
          { autoClose: 5000 }
        );
        setIsDeleting(false);
        setIsUploading(false);
      });
  }

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const orderedItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    setItems(orderedItems);

    updateRender(
      project.id,
      type,
      orderedItems.map((i) => {
        i._id = i.id;
        // delete i.id;
        return i;
      })
    ).then((data) => {
      console.log(data);
    });

    // setItems(orderedItems);
  }

  function onAddDetailsFromFurnituresClick() {
    setIsLoadingOtherFilesFromFurnitures(true);
    addOtherFilesFromFurnitures(project.id)
      .then(onUpdate)
      .then(() => {
        setIsLoadingOtherFilesFromFurnitures(false);
        toast.success("Fotos adicionadas com sucesso", {
          autoClose: 3000,
        });
      })
      .catch(() => {
        setIsLoadingOtherFilesFromFurnitures(false);
        toast.error("Ocorreu um erro ao adicionar as fotos", {
          autoClose: 3000,
        });
      });
  }

  return (
    <>
      <div className="user-activity__item pr-3 py-3">
        <div className="user-activity__item__icon">
          {isValid ? (
            <i className="material-icons" style={{ color: "#17c67185" }}>
              &#xE5CA;
            </i>
          ) : (
            <i className="material-icons" style={{ color: "#c41a3c91" }}>
              &#xE5CD;
            </i>
          )}
        </div>
        <div
          className="user-activity__item__content"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <span className="text-light" />
          <p>{name}</p>
        </div>
        <div className="user-activity__item__action ml-auto">
          {type === "details" ? (
            <IfAllowed resource="admin-ladecora:project" perform="update:any">
              <Button
                theme="success"
                outline
                className="mr-2"
                onClick={onAddDetailsFromFurnituresClick}
                disabled={isLoadingOtherFilesFromFurnitures}
              >
                {isLoadingOtherFilesFromFurnitures
                  ? "Incluindo..."
                  : "Incluir produtos"}
              </Button>
            </IfAllowed>
          ) : null}
          <button
            className="ml-auto btn btn-sm btn-white"
            onClick={() => setShowModal(true)}
          >
            Detalhes
          </button>
        </div>
      </div>
      <Modal
        size="lg"
        modalContentClassName="card card-small"
        open={showModal}
        toggle={() => {
          !isUploading && !isDeleting && setShowModal(!showModal);
        }}
      >
        <ModalHeader
          toggle={() => {
            !isUploading && !isDeleting && setShowModal(!showModal);
          }}
        >
          {name}
        </ModalHeader>
        <ModalBody style={{ maxHeight: "70vh", overflow: "auto" }}>
          <IfAllowed resource="admin-ladecora:project" perform="update:any">
            <FileDropzone
              handleFileDrop={onUpload}
              multiple={true}
              disabled={value && value.length >= limit}
              isLoading={isUploading}
              style={{ marginBottom: "16px" }}
            />
          </IfAllowed>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {items.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.dragHandleProps}
                          {...provided.draggableProps}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              paddingLeft: "8px",
                              paddingRight: "8px",
                              justifyContent: "space-between",
                            }}
                          >
                            <table style={{ maxWidth: "200px" }}>
                              <tbody>
                                <tr>
                                  <td>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        minHeight: "200px",
                                        minWidth: "200px",
                                        maxHeight: "200px",
                                        maxWidth: "200px",
                                      }}
                                    >
                                      <button
                                        type="button"
                                        className="transparent"
                                        onClick={() => {
                                          sendLightbox(
                                            items.map((item) => item.source),
                                            index,
                                            showImageCaption(
                                              items.map((item) =>
                                                typeof item.labels ===
                                                "undefined"
                                                  ? []
                                                  : item.labels
                                              )
                                            )
                                          );
                                        }}
                                      >
                                        <img
                                          style={{
                                            maxHeight: "200px",
                                            maxWidth: "200px",
                                            marginLeft: "auto",
                                            marginRight: "auto",
                                          }}
                                          className="hover-click"
                                          alt=""
                                          src={item.thumbnail || item.source}
                                        />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div
                                      style={{
                                        fontSize: ".7rem",
                                        textAlign: "center",
                                      }}
                                    >
                                      {item.name}
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>

                            <div>
                              {item.labels && item.labels.length
                                ? item.labels.map((label, key) => {
                                    return (
                                      <table key={key} className="mx-2 mb-2">
                                        <tbody>
                                          <tr>
                                            <td>
                                              <div
                                                style={{
                                                  width: "10px",
                                                  height: "10px",
                                                  marginRight: "5px",
                                                  backgroundColor:
                                                    label.colorHex,
                                                }}
                                              />
                                            </td>
                                            <td>
                                              <div
                                                style={{ fontSize: ".7rem" }}
                                              >
                                                {label.mobi.name}
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    );
                                  })
                                : null}
                            </div>
                            <IfAllowed
                              resource="admin-ladecora:project"
                              perform="update:any"
                            >
                              <div style={{ minWidth: "81px" }}>
                                <ButtonGroup
                                  size="sm"
                                  className="d-table mx-auto"
                                >
                                  <Button
                                    theme="dark"
                                    outline
                                    onClick={() => {
                                      download(item.source);
                                    }}
                                  >
                                    <i className="material-icons">
                                      cloud_download
                                    </i>
                                  </Button>

                                  <DeleteButton
                                    row={item}
                                    onDelete={onDelete}
                                    disabled={isUploading}
                                    isDeleting={item.id === isDeleting}
                                  />
                                </ButtonGroup>
                              </div>
                            </IfAllowed>

                            {provided.placeholder}
                          </div>
                          {index !== items.length - 1 ? <hr /> : null}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </ModalBody>
      </Modal>
    </>
  );
};

export default RenderItemPicture;

export const showImageCaption = (imageLabels) => {
  if (imageLabels.length > 0) {
    let imagesCaption = [];
    imageLabels.map((labels) => {
      let tempImageCaption = [];
      if (labels.length > 0) {
        labels.forEach((elem) =>
          tempImageCaption.push(
            <div key={elem._id} className="d-flex ">
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  background: `${elem?.colorHex}`,
                }}
              />
              <p className="align-items-center  pl-2 w-100">
                {elem?.mobi?.name}
              </p>
            </div>
          )
        );
        imagesCaption.push(tempImageCaption);
      } else {
        imagesCaption.push([]);
      }
    });
    return imagesCaption;
  } else {
    return [];
  }
};

import React, { useState } from "react";
import {
  Button,
  Row,
  Col,
  ModalBody,
  ModalHeader,
  Modal,
  FormGroup,
  FormInput,
  Form,
} from "shards-react";
import ConfirmationButton from "../common/ConfirmationButton";
import {
  removeFurnitureCustomSenttings,
  updateFurnitures,
} from "../../actions/projects";
import { toast } from "react-toastify";

const ModalCustomXYZ = ({
  onClose,
  mobi,
  projectId,
  currentStep,
  type,
  onUpdate,
}) => {
  const [xyz, setXYZ] = useState<any>({
    refx: mobi.customRefx || mobi.refx,
    refy: mobi.customRefy || mobi.refy,
    refz: mobi.customRefz || mobi.refz,
  });
  const [isLoading, setIsLoading] = useState(false);

  function onChange(ev: any) {
    if (!ev.target.value || ev.target.value.match(/^(\d+,?\.?)+$/)) {
      setXYZ({ ...xyz, [ev.target.name]: ev.target.value });
    }
  }

  const onSave = () => {
    if (xyz?.refx == "" || xyz?.refy == "" || xyz?.refz == "") {
      toast.error("Favor preencher os campos acima!", {
        autoClose: 5000,
        position: "top-center",
      });
      // setMessageFeedback("Favor preencher os campos acima!");
    } else {
      setIsLoading(true);
      updateFurnitures(
        projectId,
        {
          id: mobi._id,
          count: mobi.count,
          refx: xyz.refx,
          refy: xyz.refy,
          refz: xyz.refz,
        },
        type
      )
        .then((res) => {
          onUpdate(
            {
              id: mobi._id,
              count: mobi.count,
              refx: xyz.refx,
              refy: xyz.refy,
              refz: xyz.refz,
            },
            res
          );
          setIsLoading(false);
          toast.success("Dados atualizados com sucesso.", {
            position: "top-right",
            autoClose: 2000,
          });
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.message === "Failed to fetch") {
            toast.error("Ocorreu um problema na conexão com a internet.");
          } else {
            toast.error(err.message);
          }
        });
    }
  };

  function onClean() {
    setIsLoading(true);
    removeFurnitureCustomSenttings(projectId, mobi._id, currentStep)
      .then((res: any) => {
        setXYZ({
          refx: mobi.refx,
          refy: mobi.refy,
          refz: mobi.refz,
        });
        onUpdate(
          {
            id: mobi._id,
            count: mobi.count,
            refx: "",
            refy: "",
            refz: "",
          },
          res
        );
        setIsLoading(false);
        toast.success("Dados limpados com sucesso.", {
          position: "top-right",
          autoClose: 2000,
        });
      })
      .catch(() => {
        setIsLoading(false);
        toast.error("Ocorreu um erro ao tentar limpar os dados.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      });
  }
  return (
    <Modal size="lg" open={true} toggle={onClose}>
      <ModalHeader
        toggle={onClose}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Configuração de customização de altura, largura e profundidade para o
        projeto
      </ModalHeader>
      <ModalBody>
        <Row className="d-flex justify-content-end">
          <ConfirmationButton
            title="Limpar dados customizados"
            outline
            theme="danger"
            disabled={isLoading}
            onClick={onClean}
          >
            Limpar dados customizados
          </ConfirmationButton>
        </Row>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            onSave();
          }}
        >
          <Row form>
            <Col md="4" className="form-group">
              <FormGroup>
                <div className="label">Ref L</div>
                <FormInput
                  name="refx"
                  placeholder="Informe o ref l"
                  value={xyz.refx}
                  onChange={onChange}
                  disabled={isLoading}
                />
              </FormGroup>
            </Col>
            <Col md="4" className="form-group">
              <FormGroup>
                <div className="label">Ref P</div>
                <FormInput
                  name="refy"
                  placeholder="Informe ref p"
                  value={xyz.refy}
                  onChange={onChange}
                  disabled={isLoading}
                />
              </FormGroup>
            </Col>
            <Col md="4" className="form-group">
              <FormGroup>
                <div className="label">Ref A</div>
                <FormInput
                  name="refz"
                  placeholder="Informe o ref a"
                  value={xyz.refz}
                  onChange={onChange}
                  disabled={isLoading}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row form className="d-flex justify-content-center">
            {/* {showMessageFeedback()} */}
          </Row>
          <Row form className="d-flex justify-content-center">
            <Button className="mt-3" disabled={isLoading}>
              Salvar
            </Button>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ModalCustomXYZ;

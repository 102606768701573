import React, { useState } from "react";
import AddingItem from "./AddingItem";
import ListCheckList from "./ListCheckList";
import { Button, Row, Col, Modal, ModalHeader, ModalBody } from "shards-react";

function ButtonCheckList({ checklist, setCheckList, projectId, type }) {
  const [openAdding, setOpenAdding] = useState(false);
  const [openList, setOpenList] = useState(false);
  const [openAddingInModal, setOpenAddingInModal] = useState(false);
  const [modalBackdrop, setModalBackdrop] = useState(true);

  return (
    <>
      <Modal
        size="lg"
        modalContentClassName="card card-small"
        open={openAdding}
        toggle={() => setOpenAdding(!openAdding)}
      >
        <ModalHeader>Adicionar item checklist</ModalHeader>
        <ModalBody>
          <AddingItem
            checklist={checklist}
            setCheckList={setCheckList}
            projectId={projectId}
            type={type}
          />
        </ModalBody>
      </Modal>

      <Modal
        size="lg"
        modalContentClassName="card card-small"
        backdrop={true}
        open={openList}
        toggle={() => modalBackdrop && setOpenList(!openList)}
      >
        <div className="medium-title text-center py-2">Checklist</div>
        <div className="pl-4">
          <Button
            size="sm"
            outline
            onClick={() => setOpenAddingInModal(!openAddingInModal)}
          >
            Adicionar novo item
          </Button>
        </div>

        <ModalBody
          style={{
            maxHeight: "70vh",
            overflow: "auto",
            padding: "1.275rem 1rem",
          }}
        >
          {openAddingInModal && (
            <AddingItem
              checklist={checklist}
              setCheckList={setCheckList}
              projectId={projectId}
              type={type}
            />
          )}
          <ListCheckList
            checklist={checklist}
            setCheckList={setCheckList}
            projectId={projectId}
            type={type}
            onImageView={setModalBackdrop}
          />
        </ModalBody>
      </Modal>

      <Row>
        <Col>
          <Button outline onClick={() => setOpenAdding(!openAdding)}>
            Adicionar
          </Button>
        </Col>
        <Col>
          <Button outline onClick={() => setOpenList(!openList)}>
            Checklist
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default ButtonCheckList;

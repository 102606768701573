import React from "react";
import { toast } from "react-toastify";
import {
  Row,
  Card,
  Col,
  Button,
  FormInput,
  CardFooter,
  FormTextarea,
} from "shards-react";
import Loader from "react-loader-spinner";
import Dropzone from "react-dropzone";

function StyleForm({
  style,
  enterprise,
  handleDrop,
  isLoading,
  activeStep,
  onChange,
}) {
  return (
    <div
      className="w-100 mb-3"
      style={{
        border: "1px dashed",
        padding: "10px",
      }}
    >
      <Col md="12" className="form-group">
        <div className="label">Nome do estilo</div>
        <FormInput
          required
          disabled={isLoading}
          onChange={(ev) => {
            style.name = ev.target.value;
            onChange({ ...enterprise }, activeStep);
          }}
          id="name"
          value={style.name}
        />
      </Col>
      <Col md="12" className="form-group">
        <div className="label">Comentários</div>
      </Col>
      <Col md="12" className="form-group">
        <FormTextarea
          rows="10"
          value={style.details}
          onChange={(ev) => {
            style.details = ev.target.value;
            onChange({ ...enterprise }, activeStep);
          }}
        />
      </Col>
      <Col md="12" className="form-group">
        <div className="label">Arquivos</div>
      </Col>
      <Col xs="12">
        <Dropzone
          onDrop={(files) => {
            handleDrop(files).then((responses) => {
              responses.forEach((res) => {
                style.files.push(res);
              });
              onChange({ ...enterprise }, activeStep);
              if (enterprise._id) {
                //save enterprise
              }
              toast.success("Upload feito com sucesso", {
                autoClose: 5000,
              });
              // setIsLoading(false);
            });
          }}
        >
          {({ getRootProps, getInputProps }) => {
            return (
              <div {...getRootProps()} className="dropzone--isActive dropzone">
                <input {...getInputProps()} />
                <p className="m-0">
                  {!isLoading &&
                    "Tente soltar alguns arquivos aqui ou clique para selecionar os arquivos a serem enviados."}
                  {isLoading && <Loader type="Rings" color="#0a775a" />}
                </p>
              </div>
            );
          }}
        </Dropzone>
      </Col>
      <Col xs="12">
        {style.files && style.files.length ? (
          <div className="p-4 mb-3">
            <Row>
              <Col>
                <h6 className="text-center m-0 mt-2">Arquivos</h6>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="d-flex" style={{ "flex-flow": "row wrap" }}>
                  {style.files.map((file, idx) => (
                    <div
                      key={idx}
                      className="mx-2 mt-5"
                      style={{
                        height: "200px",
                        width: "200px",
                      }}
                    >
                      <Card
                        className="ml-2 mr-2"
                        large
                        style={{
                          height: "100%",
                          width: "100%",
                          justifyContent: "center",
                        }}
                      >
                        <CardFooter>
                          <h6>{file.name}</h6>
                        </CardFooter>
                      </Card>
                      <Button
                        className="w-100 mx-2"
                        outline
                        theme="danger"
                        onClick={() => {}}
                      >
                        Excluir
                      </Button>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </div>
        ) : (
          <div
            className="text-center m-3"
            style={{
              fontSize: "40px",
              textShadow: "-1px 1px 0px #fff, 0px -1px #848484",
              color: "#d4d4d4",
            }}
          >
            Nenhum arquivo enviado
          </div>
        )}
      </Col>
    </div>
  );
}

StyleForm.propTypes = {};

export default StyleForm;

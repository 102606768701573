import { API } from "../config";
import { getCookie } from "../actions/auth";
import { requestWrapper } from "../utils/api";

export const recommendationsLogsSave = (body) => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(`${API}/recommendations/logs`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(body),
    })
  );
};

import React from "react";

function FurnitureListItem({ furniture, onSelect }) {
  return (
    <>
      <div style={{ display: "flex" }}>
        <div
          className="pl-2 pr-2"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "6.6rem",
          }}
        >
          <input
            type="checkbox"
            checked={!!furniture.chosen}
            onChange={() => {
              onSelect(!furniture.chosen);
            }}
          />
        </div>
        <div
          className="pl-2 pr-2"
          style={{
            maxWidth: "7rem",
            position: "relative",
            width: "100%",
            height: "4.5rem",

            padding: "2px",
          }}
        >
          <div
            src={furniture.refimage}
            alt=""
            style={{
              top: 0,
              backgroundImage: `url("${furniture.refimage}")`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center center",
              height: "100%",
              width: "100%",
            }}
          />
        </div>
        <div
          style={{
            paddingLeft: "1.5rem",
            display: "flex",
            alignItems: "center",
          }}
        >
          {furniture.name}
        </div>
        <div
          style={{
            paddingLeft: "1.5rem",
            display: "flex",
            marginLeft: "auto",
            paddingRight: "1.5rem",
            alignItems: "center",
            justifyContent: "center",
            width: "9.3rem",
          }}
        >
          {furniture.count === 0 ? (
            <div style={{ color: "green" }}>Por metragem</div>
          ) : (
            furniture.count
          )}
        </div>
      </div>
      <hr />
    </>
  );
}

FurnitureListItem.propTypes = {};

export default FurnitureListItem;

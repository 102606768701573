import React, { useContext } from "react";

import SearchInput from "../common/SearchInput";

import { AsyncTableContext } from "./AsyncTableContext";

import { Container, Row, Col, FormSelect } from "shards-react";

function AsyncFitlers({ pageSizeOptions }) {
  const { onFilterUpdate, filter, isSearchable } =
    useContext(AsyncTableContext);

  return (
    <>
      <div className="d-flex mb-3">
        <Container fluid className="file-manager__filters border-bottom">
          <Row>
            <Col className="file-manager__filters__rows d-flex" md="6">
              <span>Mostrar</span>
              <FormSelect
                size="sm"
                value={filter.pageSize.value}
                onChange={(ev) => {
                  onFilterUpdate("pageSize", parseInt(ev.target.value));
                }}
              >
                {pageSizeOptions.map((size, idx) => (
                  <option key={idx} value={size}>
                    {size} linhas
                  </option>
                ))}
              </FormSelect>
            </Col>
            {isSearchable ? (
              <SearchInput
                className="ml-auto w-auto"
                onChange={(value) => {
                  onFilterUpdate("search", value);
                }}
              />
            ) : null}
          </Row>
        </Container>
      </div>
    </>
  );
}

AsyncFitlers.propTypes = {};

export default AsyncFitlers;

import React from "react";
import ReactTags from "../ReactTags/ReactTags";
import { Row, Col, FormGroup } from "shards-react";

function Label({
  name,
  value,
  suggestions,
  onNewLabel,
  onDelete,
  allowNew,
  onAddLabel,
}) {
  return (
    <Row form>
      <Col md="12" className="form-group">
        <FormGroup>
          <label htmlFor="tags-input">{name}</label>

          <ReactTags
            placeholderText="Digite aqui..."
            tags={value}
            suggestions={suggestions}
            minQueryLength={0}
            maxSuggestionsLength={1000}
            onDelete={onDelete}
            allowNew={allowNew}
            allowDragDrop={false}
            onAddition={(obj) => {
              if (!obj.id) {
                onNewLabel(obj);
              }
              if (!value.find((t) => t.name === obj.name)) {
                value.push(obj);
                onAddLabel(obj);
              }
            }}
          />
        </FormGroup>
      </Col>
    </Row>
  );
}

Label.propTypes = {};

Label.defaultProps = {
  allowNew: true,
  onAddLabel: () => {},
};

export default Label;

import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, FormCheckbox } from "shards-react";
import { ShowLightbox } from "../common/ShowLightbox";
import Viewer from "../project/PDFPages/Viewer";
import { guestAllQuestion } from "../../utils/util";
import { getDefaultQuestionByUser } from "../../actions/questions";
import { ShowAnswers } from "../common/ShowAnswers";
import {
  YourSpaceOrInspiration,
  ShowFurnitureMeasures,
} from "../common/Environments";

const AnswersById = ({ project, defaultQuestionWithUser }) => {
  const [openQuestions, setOpenQuestions] = useState({
    Answers: false,
    AnswerDefaultQuestion: false,
    LearningEnvironment: false,
    Inspirations: false,
    FurnitureMeasures: false,
  });

  const [id, setId] = useState("");
  const [answers, setAnswers] = useState();
  const [answerDefaultQuestion, setAnswerDefaultQuestion] = useState({});
  const [learningEnvironment, setLearningEnvironment] = useState({});
  const [inspirations, setInspirations] = useState({});
  const [furnitureMeasures, setFurnitureMeasures] = useState({});
  const [lightbox, setLightbox] = useState({
    open: false,
    index: 0,
    images: [],
  });
  const [link, setLink] = useState("");
  const [allDefaultQuestion, setAllDefaultQuestion] = useState([]);
  const [allLearningEnvironmentQuestion, setAllLearningEnvironmentQuestion] =
    useState([]);

  useEffect(() => {
    setId(project._id);
    setAnswers(project.answers);
    setLearningEnvironment(project.learningEnvironment);
    setInspirations(project.inspirations);
    setFurnitureMeasures(project.furnitureMeasures);
    setAnswerDefaultQuestion(
      defaultQuestionWithUser.user.answerDefaultQuestion
    );
  }, []);

  useEffect(() => {
    getDefaultQuestionByUser(defaultQuestionWithUser.user._id).then((data) => {
      setAllDefaultQuestion(guestAllQuestion(data.template));
    });
    setAllLearningEnvironmentQuestion(
      guestAllQuestion(project.questionTemplate.template)
    );
  }, []);

  const sendLightbox = (data, idx) => {
    if (Array.isArray(data)) {
      setLightbox({ ...lightbox, images: data, open: true, index: idx });
    } else {
      setLightbox({ ...lightbox, images: [data], open: true, index: 0 });
    }
  };

  return (
    <Card small className="mb-4">
      <CardBody className="p-0">
        <Row>
          <Col>
            <h6 className="text-center m-0 mt-2">Questionários</h6>
          </Col>
        </Row>
        <Row>
          <Col className="ml-1">
            <FormCheckbox
              toggle
              checked={openQuestions.AnswerDefaultQuestion}
              onChange={() =>
                setOpenQuestions({
                  ...openQuestions,
                  AnswerDefaultQuestion: !openQuestions.AnswerDefaultQuestion,
                })
              }
              small
            >
              Questionário Perfil
            </FormCheckbox>
            <FormCheckbox
              toggle
              checked={openQuestions.Answers}
              onChange={() =>
                setOpenQuestions({
                  ...openQuestions,
                  Answers: !openQuestions.Answers,
                })
              }
              small
            >
              Questionário Ambiente
            </FormCheckbox>
            <FormCheckbox
              toggle
              checked={openQuestions.LearningEnvironment}
              onChange={() =>
                setOpenQuestions({
                  ...openQuestions,
                  LearningEnvironment: !openQuestions.LearningEnvironment,
                })
              }
              small
            >
              Conhecendo seu espaço
            </FormCheckbox>
            <FormCheckbox
              toggle
              checked={openQuestions.Inspirations}
              onChange={() =>
                setOpenQuestions({
                  ...openQuestions,
                  Inspirations: !openQuestions.Inspirations,
                })
              }
              small
            >
              Inspirações
            </FormCheckbox>
            <FormCheckbox
              toggle
              checked={openQuestions.FurnitureMeasures}
              onChange={() =>
                setOpenQuestions({
                  ...openQuestions,
                  FurnitureMeasures: !openQuestions.FurnitureMeasures,
                })
              }
              small
            >
              Moveis e Medidas
            </FormCheckbox>
          </Col>
        </Row>
        <Row>
          <Col>
            {/* Questionario Perfil */}
            {openQuestions.AnswerDefaultQuestion && (
              <>
                <h6 className="text-center m-0 mt-2">Questionário do Perfil</h6>
                <ShowAnswers
                  answers={answerDefaultQuestion}
                  allQuestions={allDefaultQuestion}
                  sendLightbox={sendLightbox}
                />
              </>
            )}

            {/* Questionario do ambiente */}
            {openQuestions.Answers && (
              <>
                <h6 className="text-center m-0 mt-2">
                  Questionário do Ambiente
                </h6>
                <ShowAnswers
                  answers={answers}
                  allQuestions={allLearningEnvironmentQuestion}
                  sendLightbox={sendLightbox}
                />
              </>
            )}

            {/* Conhecendo seu espaço */}
            {openQuestions.LearningEnvironment && (
              <>
                <h6 className="text-center m-0 mt-2">Conhecendo seu espaço</h6>
                <YourSpaceOrInspiration
                  data={learningEnvironment}
                  sendLightbox={sendLightbox}
                  setLink={setLink}
                  id={id}
                />
              </>
            )}

            {/* Inspirações */}
            {openQuestions.Inspirations && (
              <>
                <h6 className="text-center m-0 mt-2">Inspirações</h6>
                <YourSpaceOrInspiration
                  data={inspirations}
                  sendLightbox={sendLightbox}
                  setLink={setLink}
                  id={id}
                />
              </>
            )}

            {/* Moveis e medidas */}
            {openQuestions.FurnitureMeasures && (
              <>
                <h6 className="text-center m-0 mt-2">Moveis e Medidas</h6>
                <ShowFurnitureMeasures
                  data={furnitureMeasures}
                  sendLightbox={sendLightbox}
                  setLink={setLink}
                  id={id}
                />
              </>
            )}
          </Col>
          {lightbox.open && (
            <ShowLightbox lightbox={lightbox} setLightbox={setLightbox} />
          )}
        </Row>
        <Viewer
          link={link}
          toggle={() => {
            if (link) setLink("");
          }}
        />
      </CardBody>
    </Card>
  );
};

export default AnswersById;

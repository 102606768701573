import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Lightbox from "@ladecora/react-image-lightbox";
import {
  Container,
  Row,
  Card,
  Col,
  Button,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
} from "shards-react";
import Moment from "react-moment";
import { CSVLink } from "react-csv";

import { getUserByEnterprise, getEnterpriseById } from "../actions/enterprise";
import { getQueryStringValue } from "../actions/auth";
import { BUCKET_URL } from "../config";

import PageTitle from "../components/common/PageTitle";
import FilteredTable from "../components/FilteredTable/index";

const EnterpriseInfo = () => {
  const [enterprise, setEnterprise] = useState({});
  const [questionAnswers, setQuestionAnswers] = useState([]);
  const [dataToExport, setDataToExport] = useState([]);
  const [acceptedRooms, setAcceptedRooms] = useState({});
  const [rating, setRating] = useState(null);
  const [otherQuestions, setOtherQuestions] = useState({});
  const [show, setShow] = useState(false);
  const [enterpriseInfo, setEnterpriseInfo] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [lightbox, setLightbox] = useState({
    open: false,
    index: 0,
    images: [],
  });

  const { enterpriseId } = useParams();
  const enterpriseName = getQueryStringValue("name");

  useEffect(() => {
    reload();
  }, []);

  const translateUserType = (userType) => {
    const filteredType = enterprise?.profiles?.find(
      (element) => element.kinda === userType
    );
    return filteredType?.name;
  };

  const reload = () => {
    setIsLoading(true);
    getEnterpriseById(enterpriseId).then((res) => {
      setEnterprise(res);
    });

    return getUserByEnterprise(enterpriseId)
      .then((data) => {
        setEnterpriseInfo(data);
        let exportedAnswers = data.map((resp) => {
          let resposta = {};
          resposta.Enterprise = resp.enterprise.name;
          resposta.Usuario = fullName(resp.user);
          resposta.PlantaBaixa = resp.bluePrint;
          resposta.Data = resp.createdAt;
          resposta.TipoUsuario = translateUserType(resp.userType);
          let acceptedRooms = resp.acceptedRooms;
          resposta.AmbientesAceitos =
            acceptedRooms && Object.keys(acceptedRooms).length > 0
              ? Object.keys(acceptedRooms)
                  .map((key) => {
                    return `${key}: ${
                      acceptedRooms[key]?.name
                        ? acceptedRooms[key]?.name
                        : "Sem resposta"
                    }`;
                  })
                  .join("; ")
              : "Nenhum ambiente selecionado";
          let questionaryAnswers = resp.questionaryAnswers;
          resposta.Respostas =
            questionaryAnswers.length > 0
              ? questionaryAnswers
                  .map((question) => {
                    return `${question.question}: ${
                      question.answer ? question.answer : "Sem resposta"
                    }`;
                  })
                  .join(" ; ")
              : "Sem resposta";

          if (resp.enterprise.company === "eztec") {
            resposta.AgregouValor = resp.rating ? resp.rating : "Não informado";
            resposta.ChanceIndicar =
              resp.otherQuestions && resp.otherQuestions.indicate
                ? resp.otherQuestions.indicate
                : "Não informado";
            resposta.Contactar =
              resp.otherQuestions && resp.otherQuestions.contact
                ? resp.otherQuestions.contact
                : "Não informado";
            resposta.Comentario =
              resp.otherQuestions && resp.otherQuestions.comment
                ? resp.otherQuestions.comment
                : "Não informado";
          }
          return resposta;
        });
        setDataToExport(exportedAnswers);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const tableColumns = [
    {
      Header: "Usuários",
      accessor: "user",
      className: "text-center",
      Cell: (row) => (
        <span>{row.original.user && fullName(row.original.user)}</span>
      ),
    },

    {
      Header: "Planta Baixa",
      accessor: "bluePrint",
      className: "text-center",
      Cell: (row) => <span>{row.original.bluePrint}</span>,
    },
    {
      Header: "Tipo usuário",
      accessor: "bluePrint",
      className: "text-center",
      Cell: (row) => <span>{translateUserType(row.original.userType)}</span>,
    },
    {
      Header: "Data",
      acessor: "date",
      className: "text-center",
      Cell: (row) => {
        return (
          <span className="d-flex flex-column">
            <Moment format="DD/MM/YYYY">{row.original.createdAt}</Moment>
            <Moment format="HH:mm">{row.original.createdAt}</Moment>
          </span>
        );
      },
    },
    {
      Header: "Resposta",
      accessor: "questionaryAnswers",
      sortable: false,
      maxWidth: 90,
      Cell: (row) => (
        <>
          <Button
            outline
            theme={row.original.highlight ? "success" : "dark"}
            disabled={!(row.original.user && row.original.enterprise)}
            onClick={() => {
              if (
                row.original.acceptedRooms &&
                Object.keys(row.original.acceptedRooms).length > 0
              ) {
                setAcceptedRooms(row.original.acceptedRooms);
              } else {
                setAcceptedRooms({});
              }
              row.original.rating
                ? setRating(row.original.rating)
                : setRating(null);
              row.original.otherQuestions
                ? setOtherQuestions(row.original.otherQuestions)
                : setOtherQuestions({});
              setQuestionAnswers(row.original.questionaryAnswers);
              setShow(true);
            }}
          >
            <i className="material-icons">&#xe86d;</i>
          </Button>
        </>
      ),
    },
  ];

  const fullName = (row) => {
    if (!row) return "Usuario não encontrado";
    return `${row.profile.firstName} ${row.profile.lastName}`;
  };

  const sendLightbox = (data, idx) => {
    if (Array.isArray(data)) {
      setLightbox({ ...lightbox, images: data, open: true, index: idx });
    } else {
      setLightbox({ ...lightbox, index: 0, images: [data], open: true });
    }
  };

  return (
    <Container fluid className="main-content-container px-4">
      {show && (
        <Modal
          size="lg"
          open={show && !isLoading}
          toggle={() => {
            setShow(false);
          }}
        >
          <ModalHeader
            toggle={() => {
              setShow(false);
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Respostas Pro Deco
          </ModalHeader>
          <ModalBody style={{ maxHeight: "80vh", overflow: "auto" }}>
            <Card small className="mb-4">
              <CardBody>
                <center>Pesquisa de satisfação</center>
                <div>Agregou valor?: {rating ? rating : "Não informado"}</div>
                <div>
                  Chance de indicar:{" "}
                  {otherQuestions.indicate
                    ? otherQuestions.indicate
                    : "Não informado"}
                </div>
                <div>
                  Podemos contactar? :
                  {otherQuestions.contact
                    ? otherQuestions.contact
                    : "Não informado"}
                </div>
                <div>
                  Comentarios:{" "}
                  {otherQuestions.comment
                    ? otherQuestions.comment
                    : "Não informado"}
                </div>
              </CardBody>
            </Card>
            <Card small className="mb-4">
              <CardBody className="mb-4">
                <center>Ambientes aceitos</center>
                {Object.keys(acceptedRooms).length > 0 ? (
                  Object.keys(acceptedRooms).map((key, index) => {
                    return (
                      <div key={index}>
                        <p>{acceptedRooms[key]?.name}</p>
                      </div>
                    );
                  })
                ) : (
                  <p className="text-center">Nenhum ambiente selecionado</p>
                )}
              </CardBody>
            </Card>
            <Card small className="mb-4">
              <CardBody className="p-0">
                <div className="user-details__user-data border-top border-bottom p-4">
                  <center>Respostas</center>
                  {questionAnswers.length > 0 ? (
                    questionAnswers.map((question, idx) => {
                      return (
                        <div
                          className="user-activity__item pr-3 py-3"
                          key={idx}
                        >
                          <div className="user-activity__item__icon">
                            <i className="material-icons">&#xE7FE;</i>
                          </div>
                          <div className="user-activity__item__content">
                            <span className="text-light">
                              {question.question}
                            </span>
                            <p>
                              {Array.isArray(question.answer) ? (
                                question.answer.map((a) => {
                                  return a
                                    .toLowerCase()
                                    .match("(.jpeg|.png|.jpg|.jfif)") ? (
                                    <button
                                      type="button"
                                      className="transparent"
                                      onClick={() =>
                                        a.includes(BUCKET_URL)
                                          ? a
                                          : `${BUCKET_URL}/${a}`
                                      }
                                    >
                                      <img
                                        alt={a}
                                        className="border rounded"
                                        style={{
                                          width: "200px",
                                          cursor: "pointer",
                                          marginBottom: "8px",
                                        }}
                                        src={
                                          a.includes(BUCKET_URL)
                                            ? a
                                            : `${BUCKET_URL}/${a}`
                                        }
                                      />
                                    </button>
                                  ) : (
                                    <>
                                      {a} <br />
                                    </>
                                  );
                                })
                              ) : question.answer
                                  .toLowerCase()
                                  .match("(.jpeg|.png|.jpg|.jfif)") ? (
                                <button
                                  type="button"
                                  className="transparent"
                                  onClick={() =>
                                    sendLightbox(
                                      question.answer.includes(BUCKET_URL)
                                        ? question.answer
                                        : `${BUCKET_URL}/${question.answer}`
                                    )
                                  }
                                >
                                  <img
                                    alt={question.answer}
                                    className="border rounded"
                                    style={{
                                      width: "200px",
                                      cursor: "pointer",
                                      marginBottom: "8px",
                                    }}
                                    src={
                                      question.answer.includes(BUCKET_URL)
                                        ? question.answer
                                        : `${BUCKET_URL}/${question.answer}`
                                    }
                                  />
                                </button>
                              ) : (
                                question.answer
                              )}
                            </p>
                            <p>{question.label}</p>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p className="text-center">Sem resposta no questionario</p>
                  )}
                </div>
              </CardBody>
            </Card>
          </ModalBody>
        </Modal>
      )}

      <Row className="page-header py-4">
        <Col md="8">
          <PageTitle
            title={enterpriseName}
            subtitle="Banco de projeto"
            className="text-sm-left mb-3"
          />
        </Col>
      </Row>

      <Card>
        <CardBody>
          <div className="d-flex justify-content-end">
            <CSVLink
              data={dataToExport}
              separator=";"
              filename={"respostas.csv"}
            >
              <Button theme="accent" className="btn-outline-primary">
                Exportar CSV
              </Button>
            </CSVLink>
          </div>
          <FilteredTable
            list={enterpriseInfo}
            columns={tableColumns}
            isLoading={isLoading}
            customFilters={[]}
            config={{
              pageSizeOptions: [5, 10, 15, 20, 30],
              searchableColumns: ["user", "bluePrint"],
              pageSize: 10,
            }}
          />
        </CardBody>
      </Card>

      {lightbox.open && (
        <Lightbox
          mainSrc={lightbox.images[lightbox.index]}
          reactModalStyle={{ overlay: { zIndex: 10000 } }}
          onCloseRequest={() => setLightbox({ ...lightbox, open: false })}
        />
      )}
    </Container>
  );
};
export default EnterpriseInfo;

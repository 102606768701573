import React from "react";
import PropTypes from "prop-types";

import { FormInput } from "shards-react";
import { getCookie } from "../../actions/auth";
import { requestWrapper } from "../../utils/api";

const Cep = (props) => {
  const MASK = "99999-999";
  const MAX_LENGTH = clear(MASK).length;

  const { value, onChange } = props;

  function shouldSearchCep(cep) {
    return cep.length === MAX_LENGTH;
  }

  function searchCep(value) {
    const token = getCookie("token");
    return requestWrapper(
      fetch(`${props.url}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "x-access-token": token,
        },
        body: JSON.stringify({ cep: value }),
      })
    );
  }

  function onLocalChange(ev) {
    let newValue = clear(ev.target.value);

    let nextLength = newValue.length;

    if (nextLength > MAX_LENGTH) return;

    const maskedValue = applyMask(newValue, MASK);
    onChange(maskedValue);
    if (shouldSearchCep(newValue)) {
      searchCep(newValue)
        .then((res) => {
          res.cep = maskedValue;
          console.log(res);
          props.onSearch(res);
        })
        .catch((err) => console.log(err));
    }
  }

  function applyMask(value, mask) {
    let result = "";

    let inc = 0;
    Array.from(value).forEach((letter, index) => {
      if (!mask[index + inc].match(/[0-9]/)) {
        result += mask[index + inc];
        inc++;
      }
      result += letter;
    });
    return result;
  }

  function clear(value) {
    return value.replace(/[^0-9]/g, "");
  }

  return (
    <FormInput {...props} type="tel" value={value} onChange={onLocalChange} />
  );
};

Cep.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  url: PropTypes.string,
  onSearch: PropTypes.func,
};

export default Cep;

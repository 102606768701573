import React, { useState } from "react";
import Collapse from "react-bootstrap/Collapse";

import CollapseButton from "../common/CollapseButton";

import AddTextItem from "../project/PDFPages/AddTextItem";
import RemovableTextItem from "../project/PDFPages/RemovableTextItem";
import ConfirmationButton from "../common/ConfirmationButton";

import colors from "../../utils/colors";

const CategorySetting = ({
  name,
  id,
  items,
  onAddSubSetting,
  onDeleteSubSetting,
  onDeleteSetting,
  item,
}) => {
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState((item.items && item.items.length) || 0);

  function onAddClick(text) {
    onAddSubSetting({
      category: name,
      text: text,
      id: count,
      categoryId: id,
      isNew: true,
    });
    setCount(count + 1);
  }

  function onDeleteCategoryClick() {
    onDeleteSetting(item);
  }

  function onDeleteCustomSettingClick(subSetting) {
    onDeleteSubSetting(subSetting);
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <CollapseButton collapsed={open} onClick={() => setOpen(!open)} />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
          }}
        >
          <strong>{name}</strong>
          <ConfirmationButton
            type="icon"
            className="material-icons mr-1 transparent"
            onClick={onDeleteCategoryClick}
            style={{
              marginLeft: "auto",
              paddingLeft: "5px",
              paddingRight: "5px",
              fontSize: "26px",
              marginBottom: "2px",
              color: colors.danger.value,
            }}
          >
            delete
          </ConfirmationButton>
        </div>
      </div>

      <Collapse in={open} timeout={0}>
        <div
          style={{
            marginLeft: "60px ",
          }}
          id="example-collapse-texts"
        >
          {items.map((subSetting, index) => (
            <RemovableTextItem
              key={index}
              id={subSetting._id}
              onDelete={() => onDeleteCustomSettingClick(subSetting)}
              text={subSetting.text}
            />
          ))}

          <AddTextItem onAdd={onAddClick} />
        </div>
      </Collapse>
    </div>
  );
};

export default CategorySetting;

import { API } from "../config";
import { getCookie } from "../actions/auth";

export const listQuestion = () => {
  const token = getCookie("token");

  return fetch(`${API}/question/list`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "x-access-token": token,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getDefaultQuestionByUser = (userId) => {
  const token = getCookie("token");
  return fetch(`${API}/question/${userId}/default`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "x-access-token": token,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getDefaultQuestionWithUser = (idProject, withUser) => {
  const token = getCookie("token");

  return fetch(`${API}/project/${idProject}/?withUser=${withUser}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "x-access-token": token,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  Container,
  Row,
  Card,
  Col,
  CardBody,
  Button,
  ButtonGroup,
} from "shards-react";
import { getGifts, deleteGift } from "../../actions/gift";
import { toast } from "react-toastify";

import FilteredTable from "../FilteredTable/index";
import IfAllowed from "../common/IfAllowed";
import ConfirmationButton from "../common/ConfirmationButton";

const Gifts = ({ company }) => {
  const { id } = useParams();
  const [gifts, setGifts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const tableColumns = [
    {
      Header: "Label",
      accessor: "label",
      className: "text-center",
      Cell: (row) => <span>{row.original.label}</span>,
    },
    {
      Header: "Quantidade",
      accessor: "quantity",
      className: "text-center",
      Cell: (row) => <span>{row.original.quantity}</span>,
    },

    {
      Header: "AÇÕES",
      accessor: "actions",
      maxWidth: 150,
      sortable: false,
      Cell: (row) => (
        <ButtonGroup size="sm" className="mx-auto">
          <Button
            onClick={() =>
              history.push(`/partner/${id}/gift/${row.original.label}`)
            }
            outline
          >
            Editar
          </Button>
          <IfAllowed
            resource="admin-ladecora:partner:gift"
            perform="delete:any"
          >
            <ConfirmationButton
              title="Remover"
              theme="danger"
              onClick={() => onDeleteClick(row.original._id)}
            >
              <i className="material-icons">&#xE872;</i>
            </ConfirmationButton>
          </IfAllowed>
        </ButtonGroup>
      ),
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    getGifts(company.label)
      .then((data) => {
        setIsLoading(false);
        setGifts(data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  function onDeleteClick(id) {
    deleteGift(id)
      .then((res) => {
        toast.success(res.msg);
        getGifts(company.label)
          .then((data) => {
            setIsLoading(false);
            setGifts(data);
          })
          .catch(() => {
            setIsLoading(false);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <Container fluid className="main-content-container px-4">
      <Row className="page-header py-2">
        <Col md="6">
          <button
            className="transparent"
            type="button"
            onClick={() => {
              history.goBack();
            }}
          >
            VOLTAR
          </button>
          <h3>{`Presentes por usuário | ${company.name}`}</h3>
        </Col>
      </Row>
      <Button
        className="mb-2"
        onClick={() => history.push(`/partner/${id}/gift`)}
      >
        Adicionar presente
      </Button>
      <Card>
        <CardBody>
          <FilteredTable
            isLoading={isLoading}
            columns={tableColumns}
            list={gifts}
            config={{
              pageSizeOptions: [5, 10, 15, 20, 30],
              searchableColumns: ["name"],
              pageSize: 10,
            }}
          />
        </CardBody>
      </Card>
    </Container>
  );
};
export default Gifts;

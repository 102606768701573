const request = indexedDB.open("ladecora", 1);
let db;

request.onupgradeneeded = function () {
  const db = request.result;
  const store = db.createObjectStore("projects", { keyPath: "_id" });
  store.createIndex("by_id", "_id", { unique: true });
};

request.onsuccess = function () {
  db = request.result;
};

function getItemByIndex(objectStoreName, indexName, value) {
  return new Promise((resolve, reject) => {
    try {
      const tx = db.transaction(objectStoreName, "readonly");
      const store = tx.objectStore(objectStoreName);
      const index = store.index(indexName);
      const request = index.get(value);
      request.onsuccess = function () {
        const matching = request.result;
        if (matching !== undefined) {
          return resolve(matching);
        } else {
          return resolve(null);
        }
      };
    } catch (err) {
      return reject(err);
    }
  });
}

function setItem(objectStoreName, item) {
  return new Promise((resolve, reject) => {
    try {
      const tx = db.transaction(objectStoreName, "readwrite");
      const store = tx.objectStore(objectStoreName);
      const request = store.put(item);
      request.onsuccess = function () {
        return resolve(item);
      };
    } catch (err) {
      return reject(err);
    }
  });
}

export { getItemByIndex, setItem };

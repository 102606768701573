import React, { useMemo, useState } from "react";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { extendReviewAutomation } from "../../../actions/projects";
import Moment from "react-moment";
import ConfirmationButton from "../../common/ConfirmationButton";
import { addingDayWork } from "@ladecora/ladecora-utils";
import { toast } from "react-toastify";
import moment from "moment";

export function ExtendReviewAutomation({
  projectId,
  projectInfo,
  messages,
  reload,
}) {
  const [date, setDate] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const TODAY = new Date();

  const autoApproveRemainingDays = useMemo(() => {
    const logDates = projectInfo.logDates;
    const status = projectInfo.status;
    const extendReviewAutomation =
      logDates["extend_review_automation"] &&
      logDates["extend_review_automation"].date
        ? new Date(logDates["extend_review_automation"].date).getTime()
        : 0;
    if (extendReviewAutomation) {
      return moment(
        addingDayWork(new Date(logDates["extend_review_automation"].date), 20)
      )
        .add(5, "days")
        .toDate();
    }
    const preview =
      logDates["preview"] && logDates["preview"].date
        ? new Date(logDates["preview"].date).getTime()
        : 0;
    const firstReview =
      logDates["first_review"] && logDates["first_review"].date
        ? new Date(logDates["first_review"].date).getTime()
        : 0;
    const secondReview =
      logDates["second_review"] && logDates["second_review"].date
        ? new Date(logDates["second_review"].date).getTime()
        : 0;
    const userFeedback =
      logDates["waiting_user_feedback"] &&
      logDates["waiting_user_feedback"].date
        ? new Date(logDates["waiting_user_feedback"].date).getTime()
        : 0;

    let lastMessage = 0;
    messages.forEach((message) => {
      if (
        message.author === "them" &&
        (!lastMessage || lastMessage < message.time.getTime())
      ) {
        lastMessage = message.time.getTime();
      }
    });

    if (
      [
        "preview",
        "first_review",
        "second_review",
        "waiting_user_feedback",
      ].includes(status)
    ) {
      return moment(
        addingDayWork(
          new Date(
            Math.max(
              preview,
              firstReview,
              secondReview,
              userFeedback,
              lastMessage
            )
          ),
          20
        )
      )
        .add(5, "days")
        .toDate();
    }

    return 0;
  }, [projectInfo]);

  function onExtendReviewAutomation() {
    setIsLoading(true);
    extendReviewAutomation(projectId, date)
      .then(reload)
      .then(() => {
        setIsLoading(false);
        toast.success("Data extendida com sucesso.");
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
    return false;
  }

  function onRemoveReviewAutomation() {
    setIsLoading(true);
    extendReviewAutomation(projectId, null)
      .then(reload)
      .then(() => {
        setIsLoading(false);
        toast.success("Data removida com sucesso.");
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
    return false;
  }

  function onDateChange(d) {
    setDate(d);
  }

  return (
    <div className="d-flex flex-column align-items-center">
      <div className="d-flex align-items-center justify-content-center pt-3">
        <div className="mr-2"> Pausar lembretes até </div>
        <DatePicker
          required
          onChange={onDateChange}
          format="dd/MM/y"
          value={date}
          minDate={TODAY}
        />

        <ConfirmationButton
          type="button"
          className="ml-2"
          theme="warning"
          disabled={isLoading || !date}
          onClick={onExtendReviewAutomation}
        >
          Confirmar
        </ConfirmationButton>

        <ConfirmationButton
          type="button"
          className="ml-2"
          theme="danger"
          disabled={
            isLoading ||
            !(
              projectInfo.logDates["extend_review_automation"] &&
              projectInfo.logDates["extend_review_automation"].date
            )
          }
          onClick={onRemoveReviewAutomation}
        >
          Remover pausa
        </ConfirmationButton>
      </div>
      {autoApproveRemainingDays ? (
        <div className="mt-4" style={{ fontSize: ".85rem" }}>
          <div>
            {projectInfo.logDates.extend_review_automation?.date ? (
              <>
                <div className="d-inline">Prazo extendido para </div>
                <div className="d-inline" style={{ color: "red" }}>
                  <Moment format="DD/MM/YYYY">
                    {
                      new Date(
                        projectInfo.logDates.extend_review_automation?.date
                      )
                    }
                  </Moment>
                </div>
              </>
            ) : null}
          </div>
          <div className="d-inline">
            Encerramento automático marcado para o dia{" "}
          </div>
          <div className="d-inline" style={{ color: "red" }}>
            <Moment format="DD/MM/YYYY">{autoApproveRemainingDays}</Moment>
          </div>
        </div>
      ) : null}
    </div>
  );
}

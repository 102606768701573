import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

import RightSidebar from "../common/RightSidebar";
import AddingItem from "./Checklist/AddingItem";
import ListCheckList from "./Checklist/ListCheckList";

function CheckListSidebar({
  checkList,
  projectId,
  onChange,
  show,
  onToggle,
  customCss,
  type,
}) {
  return (
    <>
      <button
        type="button"
        className={`transparent checklist-float-button  ${
          customCss.floatButton ? customCss.floatButton : ""
        }`}
        onClick={() => {
          onToggle(!show);
        }}
      >
        <span className="material-icons" style={{ fontSize: "2.6rem" }}>
          assignment
        </span>
      </button>
      <>
        {ReactDOM.createPortal(
          <RightSidebar
            title="Tarefas"
            show={show}
            onClose={() => onToggle(false)}
            className={customCss.sidebar}
          >
            <AddingItem
              checklist={checkList}
              setCheckList={(value) => onChange(value)}
              projectId={projectId}
              type={type}
            />
            <ListCheckList
              checklist={checkList}
              setCheckList={(value) => onChange(value)}
              projectId={projectId}
              type={type}
            />
          </RightSidebar>,
          document.body
        )}
      </>
    </>
  );
}

CheckListSidebar.propTypes = {
  projectId: PropTypes.string.isRequired,
  checkList: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  customCss: PropTypes.object,
  type: PropTypes.string,
};

CheckListSidebar.defaultProps = {
  customCss: {},
  type: "checklist",
};

export default CheckListSidebar;

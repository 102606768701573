import React, { useState, useEffect } from "react";
import { FormInput, FormSelect, FormCheckbox, Button } from "shards-react";
import { listAllProducts } from "../../../actions/mobi";
import FurnitureListItem from "./FurnitureListItem";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import useReviewTypeOptions from "./useReviewTypeOptions";

const CopyReview = ({
  projectId,
  type,
  onCopyClick,
  projectFurnitures,
  hasOldFurnitures,
}) => {
  const [review, setReview] = useState("");
  const [furnitures, setFurnitures] = useState([]);
  const [sameProject, setSameProject] = useState(true);
  const [toReview, setToReview] = useState("");
  const [toProject, setToProject] = useState(projectId);
  const [hasAnySelection, setHasAnySelection] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const reviewTypeOptions = useReviewTypeOptions(hasOldFurnitures);

  useEffect(() => {
    if (type) {
      setReview(type);
    }
  }, [type]);

  useEffect(() => {
    if (furnitures.length) {
      setHasAnySelection(
        furnitures.reduce((acc, cur) => acc || !!cur.chosen, false)
      );
    }
  }, [furnitures]);

  useEffect(() => {
    if (review) {
      setIsLoading(true);
      listAllProducts({
        supplier: "",
        tags: [],
        styles: [],
        colors: [],
        features: [],
        colorTones: [],
        typologies: [],
        hasFile: false,
        onlyAvailable: "null",
        order: "frequency_used",
        notUse: "null",
        projectTemplateNames: [],
        limit: 1000,
        page: 0,
        search: "",
        hasOtherFiles: false,
        category: "",
        typology: "",
        subCategory: "",
        project: projectId,
        type: type,
        oldTags: {
          categories: [],
          styles: [],
          colors: [],
          typologies: [],
        },
        active: true,
      })
        .then((res) => {
          setIsLoading(false);
          const pf = projectFurnitures.map((f) => {
            const fur = res.find((r) => r._id === f.id);
            return {
              ...f,
              ...fur,
            };
          });
          setFurnitures(pf);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [review]);

  return (
    <div id="example-collapse-texts copy-review">
      <div>
        <div
          className="d-flex"
          style={{
            width: "100%",
            paddingRight: "10px",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              width: "6.6rem",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
            className="d-flex pl-2 pr-2"
          >
            <div style={{ marginBottom: "3px" }}>Selec. Todos</div>
            <input
              type="checkbox"
              onChange={(ev) => {
                furnitures.forEach((f) => (f.chosen = ev.target.checked));
                setFurnitures([...furnitures]);
              }}
            />
          </div>
          <div
            className="pl-2 pr-2"
            style={{ width: "7rem", textAlign: "center" }}
          >
            Imagem
          </div>
          <div style={{ flex: "auto", textAlign: "center" }}>Nome</div>
          <div style={{ width: "9.3rem", textAlign: "center" }}>Qtd.</div>
        </div>
        <hr style={{ width: "100%" }} />
        <div style={{ height: "15rem", overflowY: "scroll" }}>
          {isLoading ? (
            <div className="d-flex flex-column align-items-center justify-content-center w-100">
              <Loader width="100px" height="100px" type="Rings" color="black" />
            </div>
          ) : (
            furnitures.map((furniture) => {
              return (
                <FurnitureListItem
                  furniture={furniture}
                  key={furniture._id}
                  onSelect={(value) => {
                    furniture.chosen = value;
                    setFurnitures([...furnitures]);
                  }}
                />
              );
            })
          )}
        </div>

        <div className="label">Para etapa</div>
        <FormSelect
          size="sm"
          value={toReview}
          onChange={(ev) => {
            setToReview(ev.target.value);
          }}
        >
          <option disabled value="">
            Selecione uma etapa...
          </option>
          {reviewTypeOptions}
        </FormSelect>

        <FormCheckbox
          toggle
          checked={sameProject}
          onChange={() => {
            setSameProject(!sameProject);
          }}
          small
          className="mt-2"
        >
          <strong>Mesmo projeto</strong>
        </FormCheckbox>
        {!sameProject ? (
          <>
            <div className="label">Id do projeto</div>
            <FormInput
              size="sm"
              value={toProject}
              onChange={(ev) => {
                setToProject(ev.target.value);
              }}
            />
          </>
        ) : null}
        <Button
          style={{ float: "right" }}
          className="mt-3"
          theme="primary"
          disabled={
            isSaving ||
            !hasAnySelection ||
            !toReview ||
            (sameProject && toReview === type)
          }
          onClick={() => {
            const chosenOnes = furnitures
              .filter((f) => !!f.chosen)
              .map((elem) => {
                return elem.id;
              });

            setIsSaving(true);
            onCopyClick(
              chosenOnes,
              toReview,
              sameProject ? projectId : toProject
            )
              .then(() => {
                setIsSaving(false);
                toast.success("Copiado com sucesso");
              })
              .catch(() => {
                toast.error("Ocorreu um erro");
                setIsSaving(false);
              });
          }}
        >
          {isSaving ? "Copiando..." : "Copiar"}
        </Button>
      </div>
    </div>
  );
};

export default CopyReview;

import React from "react";
import { Row } from "shards-react";

function FurnitureGrid({ children }) {
  return (
    <div className="file-manager file-manager-cards p-2">
      <Row>{children}</Row>
    </div>
  );
}

FurnitureGrid.propTypes = {};

export default FurnitureGrid;

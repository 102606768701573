import React, { useState } from "react";
import {
  Row,
  Card,
  Col,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "shards-react";
import Moment from "react-moment";

const LogEmail = ({ logEmail }) => {
  const [subject, setSubject] = useState("");
  const [textMail, setTextMail] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const openMailModal = (text, subject) => {
    setTextMail(text);
    setSubject(subject);
  };
  return (
    <>
      <Card small className="mb-4">
        <CardBody className="p-0">
          <Row>
            <Col>
              <h6 className="text-center m-0 mt-2">Log Email</h6>
            </Col>
          </Row>
          <div className="user-details__user-data border-top border-bottom p-4">
            {logEmail.map((log, idx) => {
              return (
                <div className="user-activity__item pr-3 py-3" key={idx}>
                  <div className="user-activity__item__icon">
                    <i className="material-icons">&#xE7FE;</i>
                  </div>
                  <div className="user-activity__item__content">
                    <Row>
                      <span className="text-light">{log.subject}</span>
                    </Row>
                    {log.createdAt ? (
                      <Row>
                        <span className="text-light">
                          <Moment format="DD/MM/YYYY HH:mm">
                            {log.createdAt}
                          </Moment>
                        </span>
                      </Row>
                    ) : (
                      ""
                    )}

                    <Row>
                      <Button
                        onClick={() => {
                          openMailModal(log.html, log.subject);
                          setOpenModal(!openModal);
                        }}
                      >
                        Ver Email
                      </Button>
                    </Row>
                  </div>
                </div>
              );
            })}
          </div>
        </CardBody>
      </Card>

      <Modal
        size="lg"
        modalContentClassName="card card-small"
        open={openModal}
        toggle={() => {
          setOpenModal(!openModal);
        }}
      >
        <ModalHeader
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {subject}
        </ModalHeader>
        <ModalBody style={{ maxHeight: "70vh", overflow: "auto" }}>
          <div dangerouslySetInnerHTML={{ __html: textMail }} />
        </ModalBody>
      </Modal>
    </>
  );
};

export default LogEmail;

import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";

import { toast } from "react-toastify";
import ReactImageFallback from "react-image-fallback";
import { Row, Card, Col, CardBody, Button, FormSelect } from "shards-react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { showImageCaption } from "./PDFPages/RenderItemPicture";

import DropzoneRender from "./dropzoneRender";
import ConfirmationButton from "../common/ConfirmationButton";
import IfAllowed from "../../components/common/IfAllowed";

import {
  updateFinalProject,
  uploadFile,
  copyWorkPlanLayoutToRender,
} from "../../actions/projects";
import { useHistory } from "react-router-dom";

import QualityButton from "../../components/project/qualityButton";

import { BUCKET_URL } from "./../../config";
import { ProjectInfo } from "./data";
import "./renders.scss";
import ImageEditorToggle from "./renders/ImageEditorToggle";
import { getTagsV2 } from "../../actions/mobiTag";
import SetLinkIntoString from "../common/SetLinkIntoString";

const Renders = ({
  titleStep,
  stepName,
  statusName,
  sendLightbox,
  updateProjectStatus,
  onProjectInfoChanged,
  showQualityButton,
  showActions,
  projectInfo,
  reload,
}) => {
  const [showChangeImg, setShowChangeImg] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [idImg, setIdImg] = useState();
  const history = useHistory();
  const { finalProject } = projectInfo;

  const files = useMemo(() => {
    const arr = finalProject[stepName].files;
    arr.forEach((file) => {
      if (file.tag === "workPlanLayout") {
        if (file.finishingLayout === undefined) {
          file.finishingLayout = {
            active: true,
          };
        }
        if (file.lights === undefined) {
          file.lights = { active: true };
        }
      }
    });
    return arr;
  }, [finalProject]);

  const updateStep = (finalProjectObj, projectId) => {
    onProjectInfoChanged({ ...projectInfo, finalProject: finalProjectObj });
    return updateFinalProject(projectId, finalProjectObj);
  };

  const removeImg = (idx) => {
    finalProject[stepName].files.splice(idx, 1);
    updateStep(finalProject, projectInfo.id);
  };

  const updateImg = (e, idx) => {
    setIsLoading(true);
    const oldFile = e.target.files[0];
    let file = new File([oldFile], `${Date.now()}-${oldFile.name}`, {
      type: oldFile.type,
    });
    uploadFile(file, projectInfo.id, stepName).then(() => {
      finalProject[stepName].files[idx].file = file.name;
      updateStep(finalProject, projectInfo.id);
      setShowChangeImg(!showChangeImg);
      toast.success(`Arquivo enviado com sucesso`, {
        autoClose: 5000,
      });
      setIsLoading(false);
    });
  };

  const updateImgTag = (idx, value) => {
    setIsLoading(true);
    const file = finalProject[stepName].files[idx];
    file.tag = value;

    if (value === "workPlanLayout") {
      if (file.finishingLayout === undefined) {
        file.finishingLayout = {
          active: true,
        };
      }
      if (file.lights === undefined) {
        file.lights = { active: true };
      }
    }
    updateStep(finalProject, projectInfo.id)
      .then(() => {
        toast.success(`Categoria Atualizada com sucesso`, {
          autoClose: 5000,
        });
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  function handleOnDragEnd(result) {
    if (!result.destination) {
      return;
    }

    const [reorderedItem] = files.splice(result.source.index, 1);
    files.splice(result.destination.index, 0, reorderedItem);

    return updateFinalProject(projectInfo.id, finalProject).then(() => {
      onProjectInfoChanged({
        ...projectInfo,
        finalProject: {
          ...finalProject,
          [stepName]: {
            ...finalProject[stepName],
            files,
          },
        },
      });
    });
  }

  function openImageEditor(img, i, statusName, type) {
    const data = {
      workPlanLayout: img._id,
      fileName: img.file,
      index: i,
      type,
      review: statusName,
      projectId: projectInfo.id,
      previousLabels: img[`${type}`]?.labels,
    };
    const param = btoa(JSON.stringify(data));
    if (type === "lights") {
      getTagsV2("Iluminação", "typology", "").then((res) => {
        const tag = res[0];
        window.open(
          `${window.location.origin}/project/${projectInfo.id}/mobi?typology=${tag._id}&type=${statusName}`,
          "_blank"
        );
      });
    }

    history.push(`/image-editor?data=${param}`);
  }

  function onCopyToFinalPdfClick(img, tag) {
    return copyWorkPlanLayoutToRender(projectInfo.id, {
      tag,
      review: statusName,
      data: { workPlanLayout: img._id, ...img[tag] },
    })
      .then(reload)
      .then(() => {
        toast.success("Salvo com sucesso");
      });
  }

  function onToggle(field, img) {
    img[field].active = !img[field].active;

    updateStep(finalProject, projectInfo.id)
      .then(() => {
        toast.success(`Categoria Atualizada com sucesso`, {
          autoClose: 5000,
        });
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }

  return (
    <Card small>
      <CardBody className="p-0">
        <Row>
          <Col>
            <h6 className="text-center m-0 mt-2">{titleStep}</h6>
          </Col>
        </Row>

        {showActions ? (
          <IfAllowed resource="admin-ladecora:project:send-files">
            <Row>
              <Col>
                {showQualityButton ? (
                  <Button
                    teme="primary"
                    style={{ float: "right" }}
                    className="text-center m-0 m-2"
                    onClick={() => {
                      history.push(
                        `/project/${projectInfo.id}/quality/${statusName}`
                      );
                    }}
                  >
                    Tela de qualidade{" "}
                    <i className="material-icons mr-1">open_in_new</i>
                  </Button>
                ) : null}

                <Button
                  teme="primary"
                  style={{ float: "right" }}
                  className="text-center m-0 m-2"
                  onClick={() => {
                    history.push(
                      `/project/${projectInfo.id}/mobi?project=${projectInfo.id}&type=${statusName}`
                    );
                  }}
                >
                  Ver móveis
                </Button>
                {projectInfo.status === "waiting_quality" ||
                projectInfo.status == "final_project" ? null : (
                  <QualityButton
                    id={projectInfo.id}
                    updateProjectStatus={updateProjectStatus}
                    setProjectInfo={onProjectInfoChanged}
                    projectInfo={projectInfo}
                    style={{ float: "right" }}
                    status={"waiting_quality"}
                    message={"Projeto enviado para qualidade com sucesso"}
                    buttonText={"Enviar para qualidade"}
                  />
                )}
              </Col>
            </Row>
            <div className="user-details__user-data border-top border-bottom p-4">
              <DropzoneRender
                finalProject={finalProject}
                stepName={stepName}
                updateStep={updateStep}
                projectInfo={projectInfo}
                reload={reload}
                thumbnailSize={600}
              />
            </div>
          </IfAllowed>
        ) : null}

        {finalProject[stepName].details && (
          <div className="user-details__user-data border-top border-bottom p-4">
            <Row>
              <Col>
                <h6 className="text-center m-0 mt-2">Comentário</h6>
              </Col>
            </Row>

            {finalProject[stepName].details}
          </div>
        )}
        {files.length > 0 ? (
          <div className="user-details__user-data border-top border-bottom p-4">
            <Row>
              <Col>
                <h6 className="text-center m-0 mt-2">Arquivos</h6>
              </Col>
            </Row>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="dropprender">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {files.map((img, idx) => {
                      const src = `${BUCKET_URL}/projects/${projectInfo.id}/${stepName}/${img.file}`;
                      const thumbnail = `${BUCKET_URL}/projects/${projectInfo.id}/${stepName}/thumbnails/${img.file}`;
                      return (
                        <Draggable
                          key={img._id || src}
                          draggableId={img._id || src}
                          index={idx}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="user-details__user-data border-top border-bottom p-4"
                            >
                              <IfAllowed
                                resource="admin-ladecora:project"
                                perform="update:any"
                              >
                                <Row className="mb-3">
                                  {img.tag === "workPlanLayout" ? (
                                    <div>
                                      <ImageEditorToggle
                                        name="Planta de acabamentos"
                                        value={img.finishingLayout}
                                        onToggle={() =>
                                          onToggle("finishingLayout", img)
                                        }
                                        onCopyLink={() => {
                                          return onCopyToFinalPdfClick(
                                            img,
                                            "finishingLayout"
                                          );
                                        }}
                                        onViewImageClick={() => {
                                          sendLightbox(
                                            img.finishingLayout.link,
                                            0,
                                            showImageCaption([
                                              typeof img.finishingLayout
                                                .labels === "undefined"
                                                ? []
                                                : img.finishingLayout.labels,
                                            ])
                                          );
                                        }}
                                        onOpenLink={() =>
                                          openImageEditor(
                                            img,
                                            idx,
                                            statusName,
                                            "finishingLayout"
                                          )
                                        }
                                      />
                                      <ImageEditorToggle
                                        name="Distribuição de luminárias"
                                        value={img.lights}
                                        onToggle={() => onToggle("lights", img)}
                                        onCopyLink={() => {
                                          return onCopyToFinalPdfClick(
                                            img,
                                            "lights"
                                          );
                                        }}
                                        onViewImageClick={() => {
                                          sendLightbox(
                                            img.lights.link,
                                            0,
                                            showImageCaption([
                                              typeof img.lights.labels ===
                                              "undefined"
                                                ? []
                                                : img.lights.labels,
                                            ])
                                          );
                                        }}
                                        onOpenLink={() =>
                                          openImageEditor(
                                            img,
                                            idx,
                                            statusName,
                                            "lights"
                                          )
                                        }
                                      />
                                    </div>
                                  ) : null}
                                </Row>
                              </IfAllowed>
                              <Row className="d-flex align-items-center">
                                <Col xs={4}>
                                  <Row>
                                    <ReactImageFallback
                                      fallbackImage={src}
                                      initialImage="./images/loaders/loader.svg"
                                      alt={""}
                                      className="border rounded"
                                      style={{
                                        width: "200px",
                                        height: "200px",
                                        cursor: "pointer",
                                      }}
                                      src={thumbnail}
                                      onClick={() =>
                                        sendLightbox(
                                          files.map((img) => {
                                            return `${BUCKET_URL}/projects/${projectInfo.id}/${stepName}/${img.file}`;
                                          }),
                                          idx
                                        )
                                      }
                                    />
                                  </Row>
                                  <IfAllowed resource="admin-ladecora:project:send-files">
                                    <Row>
                                      <FormSelect
                                        className="form-control"
                                        disabled={isLoading}
                                        style={{ width: "200px" }}
                                        value={img.tag || ""}
                                        onChange={(ev) => {
                                          updateImgTag(idx, ev.target.value);
                                        }}
                                      >
                                        <option disabled value="">
                                          Selecione a categoria...
                                        </option>
                                        <option value="decorationPanel">
                                          Painel de decoração
                                        </option>
                                        <option value="workPlanLayout">
                                          Planta de Layout
                                        </option>
                                        <option value="lights">
                                          Distribuição de luminárias
                                        </option>
                                        <option value="finishingLayout">
                                          Planta de acabamentos
                                        </option>
                                        <option value="details">
                                          Detalhamentos
                                        </option>
                                      </FormSelect>
                                    </Row>
                                    {idImg === idx && showChangeImg && (
                                      <Row Style={"width:200px;"}>
                                        <Col>
                                          <input
                                            type="file"
                                            className="custom-file-input"
                                            name="thumbnail"
                                            onChange={(e) => updateImg(e, idx)}
                                          />
                                          <label
                                            className="custom-file-label"
                                            htmlFor="customFile2"
                                          >
                                            {isLoading && "Enviando arquivo"}
                                          </label>
                                        </Col>
                                      </Row>
                                    )}
                                    <Row>
                                      <Button
                                        outline
                                        style={{ width: "200px" }}
                                        theme="info"
                                        disabled={isLoading}
                                        onClick={() => {
                                          setShowChangeImg(!showChangeImg);
                                          setIdImg(idx);
                                        }}
                                      >
                                        Alterar Imagem
                                      </Button>
                                    </Row>

                                    <Row>
                                      <ConfirmationButton
                                        outline
                                        disabled={isLoading}
                                        style={{ width: "200px" }}
                                        theme="danger"
                                        onClick={() => {
                                          removeImg(idx);
                                        }}
                                      >
                                        Excluir
                                      </ConfirmationButton>
                                    </Row>
                                  </IfAllowed>
                                </Col>
                                <Col xs={8}>
                                  {img.likeComments && (
                                    <Col xs="12" className="m-1">
                                      <Row>
                                        <b>O que o cliente gostou:</b>
                                      </Row>
                                      <div
                                        contentEditable={true}
                                        suppressContentEditableWarning={true}
                                      >
                                        <div
                                          contentEditable={false}
                                          style={{ cursor: "auto" }}
                                        >
                                          <SetLinkIntoString
                                            string={img.likeComments}
                                          />
                                        </div>
                                      </div>
                                    </Col>
                                  )}
                                  {img.dislikeComments && (
                                    <Col xs="12" className="m-1 mt-3">
                                      <Row>
                                        <b>O que o cliente não gostou:</b>
                                      </Row>
                                      <div
                                        suppressContentEditableWarning={true}
                                        contentEditable={true}
                                      >
                                        <div
                                          contentEditable={false}
                                          style={{ cursor: "auto" }}
                                        >
                                          <SetLinkIntoString
                                            string={img.dislikeComments}
                                          />
                                        </div>
                                      </div>
                                    </Col>
                                  )}
                                </Col>
                              </Row>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        ) : (
          <div
            className="text-center m-3"
            style={{
              fontSize: "40px",
              textShadow: "-1px 1px 0px #fff, 0px -1px #848484",
              color: "#d4d4d4",
            }}
          >
            Nenhum arquivo enviado
          </div>
        )}
      </CardBody>
    </Card>
  );
};

Renders.propTypes = {
  projectInfo: PropTypes.instanceOf(ProjectInfo).isRequired,
  onProjectInfoChanged: PropTypes.func.isRequired,
};

export default Renders;

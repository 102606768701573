import React from "react";

import colors from "../../../utils/colors";
import ConfirmationButton from "../../common/ConfirmationButton";
import IfAllowed from "../../common/IfAllowed";

const RemovableTextItem = ({ text, onDelete, id }) => {
  function onDeleteClick() {
    onDelete(id);
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: "10px",
      }}
    >
      <IfAllowed resource="admin-ladecora:project" perform="update:any">
        <ConfirmationButton
          className="material-icons mr-1 clickable transparent"
          onClick={onDeleteClick}
          type={"icon"}
          style={{
            paddingLeft: "5px",
            paddingRight: "5px",
            fontSize: "26px",
            marginBottom: "2px",
            color: colors.danger.value,
          }}
        >
          delete
        </ConfirmationButton>
      </IfAllowed>

      <span>{text}</span>
    </div>
  );
};

export default RemovableTextItem;

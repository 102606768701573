import React, { useState } from "react";
import Collapse from "react-bootstrap/Collapse";

import CollapseButton from "../../common/CollapseButton";

import AddTextItem from "../PDFPages/AddTextItem";
import RemovableTextItem from "../PDFPages/RemovableTextItem";

import colors from "../../../utils/colors";

import IfAllowed from "../../common/IfAllowed";

const RemovableCategorySetting = ({
  name,
  id,
  items,
  onAddCustomSetting,
  onDeleteCustomSetting,
  onDeleteCategory,
  item,
}) => {
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState((item.items && item.items.length) || 0);

  function onAddClick(text) {
    onAddCustomSetting({
      category: name,
      text: text,
      id: count,
      categoryId: id,
      isNew: true,
    });
    setCount(count + 1);
  }

  function onDeleteCategoryClick() {
    onDeleteCategory(item.id);
  }

  function onDeleteCustomSettingClick(settingId) {
    onDeleteCustomSetting(settingId, id);
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <CollapseButton collapsed={open} onClick={() => setOpen(!open)} />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
          }}
        >
          <strong>{name}</strong>
          <IfAllowed resource="admin-ladecora:project" perform="update:any">
            <button
              className="material-icons mr-1 transparent"
              type="button"
              onClick={onDeleteCategoryClick}
              style={{
                marginLeft: "auto",
                paddingLeft: "5px",
                paddingRight: "5px",
                fontSize: "26px",
                marginBottom: "2px",
                color: colors.danger.value,
              }}
            >
              delete
            </button>
          </IfAllowed>
        </div>
      </div>

      <Collapse in={open} timeout={0}>
        <div
          style={{
            marginLeft: "60px ",
          }}
          id="example-collapse-texts"
        >
          {items.map((setting, index) => (
            <RemovableTextItem
              key={index}
              id={setting.id}
              onDelete={onDeleteCustomSettingClick}
              text={setting.text}
            />
          ))}

          <IfAllowed resource="admin-ladecora:project" perform="update:any">
            <AddTextItem onAdd={onAddClick} />
          </IfAllowed>
        </div>
      </Collapse>
    </div>
  );
};

export default RemovableCategorySetting;

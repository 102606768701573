import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  Container,
  Row,
  Card,
  Col,
  CardBody,
  Button,
  ButtonGroup,
} from "shards-react";
import { listProjectTemplatesFromCompany } from "../../actions/company";
import { BUCKET_URL } from "../../config";

import FilteredTable from "../FilteredTable/index";

const ProjectTemplatesOverride = ({ company }) => {
  const { id } = useParams();
  const [listQuestions, setListQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const tableColumns = [
    {
      Header: "Imagem",
      accessor: "thumbnail",
      className: "text-center",
      Cell: (row) => (
        <img
          style={{ maxWidth: "150px" }}
          alt=""
          src={`${BUCKET_URL}/${row.original.thumbnail}`}
        />
      ),
    },
    {
      Header: "Nome",
      accessor: "name",
      className: "text-center",
      Cell: (row) => <span>{row.original.name}</span>,
    },
    {
      Header: "Preço base",
      accessor: "price",
      className: "text-center",
      Cell: (row) => (
        <span>
          {row.original.price.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          })}
        </span>
      ),
    },
    {
      Header: "Preço total",
      accessor: "price",
      className: "text-center",
      Cell: (row) => (
        <span>
          {(row.original.price + company.priceIncrease).toLocaleString(
            "pt-br",
            {
              style: "currency",
              currency: "BRL",
            }
          )}
        </span>
      ),
    },
    {
      Header: "AÇÕES",
      accessor: "actions",
      maxWidth: 150,
      sortable: false,
      Cell: (row) => (
        <ButtonGroup size="sm" className="mx-auto">
          <Button
            onClick={() =>
              history.push(
                `/partner/${id}/project-template/${row.original._id}`
              )
            }
            outline
          >
            Editar
          </Button>
        </ButtonGroup>
      ),
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    listProjectTemplatesFromCompany(company.label)
      .then((data) => {
        setIsLoading(false);

        setListQuestions(data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <Container fluid className="main-content-container px-4">
      <Row className="page-header py-2">
        <Col md="6">
          <button
            className="transparent"
            type="button"
            onClick={() => {
              history.goBack();
            }}
          >
            VOLTAR
          </button>
          <h3>{`Projetos disponíveis | ${company.name}`}</h3>
        </Col>
      </Row>
      <Card>
        <CardBody>
          <FilteredTable
            isLoading={isLoading}
            columns={tableColumns}
            list={listQuestions}
            config={{
              pageSizeOptions: [5, 10, 15, 20, 30],
              searchableColumns: ["name"],
              pageSize: 10,
            }}
          />
        </CardBody>
      </Card>
    </Container>
  );
};
export default ProjectTemplatesOverride;

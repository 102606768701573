import React, { useState } from "react";
import { Row, Card, Col, CardBody, Button } from "shards-react";

import { CSVLink } from "react-csv";
import { guestAllQuestion } from "../../utils/util";
import { ShowAnswers } from "../common/ShowAnswers";

const Answers = ({
  answers,
  sendLightbox,
  originalQuestion,
  showExportButton,
}) => {
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const [inputList, setInputList] = useState([]);
  const allQuestions = guestAllQuestion(originalQuestion);

  return (
    <Card small className="mb-4">
      <CardBody className="p-0">
        <Row>
          <Col>
            <h6 className="text-center m-0 mt-2">Questionário do Ambiente</h6>
          </Col>
        </Row>
        <div className="user-details__user-data border-top border-bottom p-4">
          <CSVLink
            data={answers}
            filename={"questionarioAmbientes.csv"}
            style={{ display: showExportButton ? "block" : "none" }}
          >
            <Button theme="accent" className="btn-outline-primary float-right">
              Exportar CSV
            </Button>
          </CSVLink>
          <ShowAnswers
            seeAlternative={true}
            answers={answers}
            allQuestions={allQuestions}
            sendLightbox={sendLightbox}
            setInputList={setInputList}
            setShow={setShow}
            setTitle={setTitle}
            title={title}
            show={show}
            inputList={inputList}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default Answers;

import React, { useState } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  FormSelect,
} from "shards-react";
import ConfirmationButton from "../common/ConfirmationButton";
import { toast } from "react-toastify";
import {
  uploadFile,
  updateFinalProject,
  updateLogDates,
  reactivateProjectStatus,
} from "../../actions/projects";
import IfAllowed from "../../components/common/IfAllowed";
import QualityButton from "../../components/project/qualityButton";
import { finalProjectEmail } from "../../actions/email";
import { updateProjectStatus } from "../../actions/projects";

const FinalProject = ({
  lastVersion,
  setLastVersion,
  projectId,
  finalProject,
  setFinalProject,
  setProjectInfo,
  projectInfo,
  user,
  token,
  email,
  logDates,
}) => {
  const [loading, setLoading] = useState(false);

  const [showReactivateProjectModal, setShowReactivateProjectModal] =
    useState(false);
  const [projectReactivationType, setProjectReactivationType] = useState("");

  function hashCode(str) {
    var hash = 0,
      i,
      chr;
    for (i = 0; i < str.length; i++) {
      chr = str.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }

  const updatePdf = (e) => {
    setLoading(true);
    const oldFile = e.target.files[0];
    let file = new File(
      [oldFile],
      `${hashCode(oldFile.name + new Date())}.pdf`,
      {
        type: "application/pdf",
      }
    );
    uploadFile(file, projectId, "lastVersion").then(() => {
      const newFinalProjetct = finalProject;
      newFinalProjetct.lastVersion.pdf = file.name;
      setFinalProject(newFinalProjetct);
      setLastVersion({ ...lastVersion, pdf: file.name });
      updateFinalProject(projectId, newFinalProjetct);
      toast.dismiss();
      toast.success("Upload feito com sucesso!", { autoClose: 1500 });
      setLoading(false);
    });
  };

  function onReactivateProject() {
    setProjectReactivationType("");
    setShowReactivateProjectModal(true);
  }

  function onReactivateProjectConfirmationClick() {
    setLoading(true);
    reactivateProjectStatus(projectId, projectReactivationType)
      .then(() => {
        setLoading(false);
        setShowReactivateProjectModal(false);
        toast.success("Projeto reativado com sucesso!", { autoClose: 1500 });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch(() => {
        setShowReactivateProjectModal(false);
        setLoading(false);
        toast.error("Erro ao reativar projeto!", { autoClose: 1500 });
      });
  }

  return (
    <>
      <div style={{ float: "right" }}>
        {projectInfo.status === "waiting_quality" ? (
          <>
            <IfAllowed resource="admin-ladecora:project:send-to-client">
              <ConfirmationButton
                buttonTheme="success"
                className="text-center m-0 m-2"
                onClick={() => {
                  if (finalProject.lastVersion.pdf) {
                    updateProjectStatus(projectId, "final_project");
                    setProjectInfo({
                      ...projectInfo,
                      status: "final_project",
                      lastStep: 9,
                    });
                    updateLogDates(projectId, "final_project");
                    finalProjectEmail({
                      name: user.firstName,
                      _id: user._id,
                      token: token,
                      email: email,
                      status: "final_project",
                      subject:
                        "Material técnico disponível | " +
                        `${projectInfo.title}`,
                      projectId: projectId,
                    });
                    toast.success("Projeto final enviado com sucesso", {
                      autoClose: 5000,
                    });
                  } else {
                    toast.error("Nenhum pdf encontrado", {
                      autoClose: 5000,
                    });
                  }
                }}
              >
                Enviar Projeto Final
              </ConfirmationButton>
            </IfAllowed>
            <QualityButton
              id={projectId}
              updateProjectStatus={updateProjectStatus}
              setProjectInfo={setProjectInfo}
              projectInfo={projectInfo}
              status={"reviewing_quality"}
              message={"Projeto enviado para revisão com sucesso"}
              buttonText={"Revisão de qualidade"}
            />
          </>
        ) : (
          projectInfo.status !== "final_project" && (
            <QualityButton
              id={projectId}
              updateProjectStatus={updateProjectStatus}
              setProjectInfo={setProjectInfo}
              projectInfo={projectInfo}
              status={"waiting_quality"}
              message={"Projeto enviado para qualidade com sucesso"}
              buttonText={"Enviar para qualidade"}
            />
          )
        )}
        {projectInfo.status === "final_project" ||
        projectInfo.status === "waiting_render_approved" ||
        projectInfo.status === "waiting_render_reproved" ? (
          <IfAllowed resource="admin-ladecora:project:reactivate-project">
            <Button
              theme="danger"
              className="text-center m-0 m-2"
              onClick={onReactivateProject}
            >
              Reativar projeto
            </Button>
          </IfAllowed>
        ) : null}
      </div>
      <div className="user-details__user-data border-top border-bottom p-4">
        <Row>
          <Col>
            <h6 className="text-center m-0 mt-2">Enviar PDF Manualmente</h6>
            {finalProject.lastVersion.pdf && (
              <h6 className="text-center m-0 mt-2">Pdf enviado</h6>
            )}
          </Col>
        </Row>
        <IfAllowed resource="admin-ladecora:project:send-files">
          <Row>
            <Col>
              <input
                type="file"
                className="custom-file-input"
                name="thumbnail"
                onChange={(e) => updatePdf(e)}
              />
              <label className="custom-file-label" htmlFor="customFile2">
                {loading && "Enviando arquivo"}
              </label>
            </Col>
          </Row>
        </IfAllowed>
      </div>
      {showReactivateProjectModal ? (
        <Modal open={showReactivateProjectModal} toggle={() => {}}>
          <div className="text-center w-100 p-3">
            <h5>Escolha a etapa</h5>
            <button
              aria-label="Close"
              className="close"
              onClick={() => {
                setShowReactivateProjectModal(false);
              }}
            >
              <span
                style={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                  padding: "5px",
                }}
                aria-hidden={true}
              >
                ×
              </span>
            </button>
          </div>
          <ModalBody>
            <div className="d-flex align-items-center justify-content-center">
              <FormSelect
                defaultValue=""
                value={projectReactivationType}
                onChange={(ev) => {
                  setProjectReactivationType(ev.target.value);
                }}
              >
                <option disabled value="">
                  Selecione...
                </option>
                <option value="production">Em Produção</option>
                {logDates.preview ? (
                  <option value="preview">Proposta Inicial</option>
                ) : null}
                {logDates.first_review ? (
                  <option value="first_review">1º Revisão</option>
                ) : null}
                {logDates.second_review ? (
                  <option value="second_review">2º Revisão</option>
                ) : null}
              </FormSelect>
            </div>
          </ModalBody>
          <ModalFooter className="d-flex align-items-center justify-content-center">
            <Button
              theme="danger"
              className="mr-4"
              disabled={loading}
              onClick={() => {
                setShowReactivateProjectModal(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              theme="success"
              disabled={!projectReactivationType || loading}
              onClick={onReactivateProjectConfirmationClick}
            >
              Confirmar
            </Button>
          </ModalFooter>
        </Modal>
      ) : null}
    </>
  );
};

export default FinalProject;

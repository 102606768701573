import { API } from "../config";
import { getCookie } from "./auth";
import { requestWrapper } from "../utils/api";

export const sendMessage = (senderId, message, receiver, category) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/message/send`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify({
        user: senderId,
        text: message,
        project: receiver,
        category,
        timestamp: new Date(),
      }),
    })
  );
};

export const getMessages = (projectId, category) => {
  const token = getCookie("token");

  return fetch(`${API}/message/${projectId}/all?category=${category}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "x-access-token": token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        throw new Error("Server error");
      }
      return response;
    })
    .then((response) => {
      return response.json();
    });
};

export const readMessages = (projectId, type) => {
  const token = getCookie("token");

  return fetch(`${API}/message/${projectId}/${type}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "x-access-token": token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        throw new Error("Server error");
      }
      return response;
    })
    .then((response) => {
      return response.json();
    });
};

export const deleteMessage = (messageId) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/message/${messageId}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const uploadFile = (file, projectId, kind, source) => {
  const token = getCookie("token");
  const formData = new FormData();
  if (file) {
    formData.append("file", file);
    formData.append("kind", kind);
    formData.append("source", source);
    formData.append("timestamp", new Date());
    formData.append("folder", "chat");
  } else {
    throw new Error("No file");
  }

  return requestWrapper(
    fetch(`${API}/message/upload/${projectId}`, {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const setChatDone = (projectId) => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(`${API}/message/${projectId}/set_chat_done`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};
// eslint-disable-next-line
function consume(stream, total = 0) {
  while (stream.state === "readable") {
    var data = stream.read();
    total += data.byteLength;
    console.log(
      "received " + data.byteLength + " bytes (" + total + " bytes in total)."
    );
  }
  if (stream.state === "waiting") {
    stream.ready.then(() => consume(stream, total));
  }
  return stream.closed;
}

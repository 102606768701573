import React from "react";
import { Container } from "shards-react";
import Loader from "react-loader-spinner";

function Content({ value, children }) {
  const shouldRender = !!value;
  return shouldRender ? (
    children
  ) : (
    <Container
      fluid
      className="main-content-container d-flex align-items-center justify-content-center"
    >
      {" "}
      <Loader width="60px" height="60px" type="Rings" color="black" />
    </Container>
  );
}

Content.propTypes = {};

export default Content;

/* eslint-disable */

export interface MobiTagTinderParamsI {
  typology: TagI | null;
  category: TagI | null;
  subCategory: TagI | null;
  value: TagI | null;
}

export interface FileLinkI {
  kind: string;
  name: string;
  link: string;
  thumbnail: string;
}

export interface FileI {
  file: File | null;
  uri: string;
  kind: string;
  isLoading: boolean;
}

export interface TagI {
  _id: string;
  name: string;
  category: string;
  parent: string;
}

export interface MobiI {
  _id: string;
  sku: string;
  name: string;
  file: string;
  link: string;
  affiliateLink: string;
  unitOfMeasurement: string;
  value: string;
  refx: string;
  inventory: string;
  refy: string;
  refz: string;
  notUse: boolean;
  active: boolean;
  frequency_used: number;
  refimage: string;
  otherFiles: Array<FileLinkI>;
  tags: Array<TagI>;
  supplier: string;
  category: TagI | null;
  typology: TagI | null;
  subCategory: TagI | null;
  projectTemplateNames: Array<string>;
  oldTags: {
    styles: Array<{ name: string; _id: string }>;
    colors: Array<{ name: string; _id: string }>;
    typologies: Array<{ name: string; _id: string }>;
    categories: Array<{ name: string; _id: string }>;
  };
}

const TAG_CATEGORIES = {
  STYLE: "style",
  COLOR: "color",
  FEATURE: "feature",
  COLOR_TONE: "color_tone",
};

export class MobiTagTinderParams implements MobiTagTinderParamsI {
  typology = new Tag();
  category = new Tag();
  subCategory = new Tag();
  value: TagI | null = null;

  constructor(data: MobiTagTinderParamsI) {
    if (data) {
      if (data.typology) {
        const typology = new Tag(data.typology);
        this.typology = typology;
      }
      if (data.category) {
        const category = new Tag(data.category);
        this.category = category;
      }
      if (data.subCategory) {
        const subCategory = new Tag(data.subCategory);
        this.subCategory = subCategory;
      }
      if (data.value) {
        const value = new Tag(data.value);
        this.value = value;
      }
    }
  }
}

export class FileLink implements FileLinkI {
  kind = "";
  name = "";
  link = "";
  thumbnail = "";

  constructor(data: FileLinkI) {
    this.kind = data.kind;
    this.name = data.name;
    this.link = data.link;
    this.thumbnail = data.thumbnail;
  }
}

export class RawFile implements FileI {
  kind = "";
  file: File | null = null;
  uri = "";
  isLoading = false;

  constructor(data: FileI) {
    this.kind = data.kind;
    this.file = data.file;
    this.uri = data.uri;
    this.isLoading = data.isLoading;
  }
}

export class Tag implements TagI {
  _id = "";
  name = "";
  category = "";
  parent = "";

  constructor(data: TagI | null = null) {
    if (data) {
      this._id = data._id;
      this.name = data.name;
      this.category = data.category;
      this.parent = data.parent;
    }
  }
}

export class Mobi implements MobiI {
  _id = "";
  sku = "";
  name = "";
  file = "";
  link = "";
  affiliateLink = "";
  unitOfMeasurement = "";
  value = "";
  refx = "";
  inventory = "";
  refy = "";
  refz = "";
  notUse = false;
  active = true;
  refimage = "";
  otherFiles: Array<FileLinkI> = [];
  tags: Array<TagI> = [];
  frequency_used = 0;
  projectTemplateNames: Array<string> = [];
  supplier = "";
  category = new Tag();
  typology = new Tag();
  subCategory = new Tag();
  _styles: Array<TagI> = [];
  _features: Array<TagI> = [];
  _colorTones: Array<TagI> = [];
  _colors: Array<TagI> = [];
  oldTags: {
    styles: Array<{ name: string; _id: string }>;
    colors: Array<{ name: string; _id: string }>;
    typologies: Array<{ name: string; _id: string }>;
    categories: Array<{ name: string; _id: string }>;
  } = {
    styles: [],
    colors: [],
    typologies: [],
    categories: [],
  };

  get styles(): Array<TagI> {
    return this.tags.filter(
      (tag) => tag && tag.category === TAG_CATEGORIES.STYLE
    );
  }

  set styles(value: Array<TagI>) {
    this._styles = value;
  }

  get colors(): Array<TagI> {
    return this.tags.filter(
      (tag) => tag && tag.category === TAG_CATEGORIES.COLOR
    );
  }

  set colors(value: Array<TagI>) {
    this._colors = value;
  }

  get colorTones(): Array<TagI> {
    return this.tags.filter(
      (tag) => tag && tag.category === TAG_CATEGORIES.COLOR_TONE
    );
  }

  set colorTones(value: Array<TagI>) {
    this._colorTones = value;
  }

  get features(): Array<TagI> {
    return this.tags.filter(
      (tag) => tag && tag.category === TAG_CATEGORIES.FEATURE
    );
  }

  set features(value: Array<TagI>) {
    this._features = value;
  }

  constructor(data: MobiI) {
    if (data) {
      if (data.tags && data.tags.length > 0) {
        this.styles = data.tags.filter(
          (tag) => tag && tag.category === TAG_CATEGORIES.STYLE
        );
        this.colors = data.tags.filter(
          (tag) => tag && tag?.category === TAG_CATEGORIES.COLOR
        );
        this.colorTones = data.tags.filter(
          (tag) => tag && tag?.category === TAG_CATEGORIES.COLOR_TONE
        );
        this.features = data.tags.filter(
          (tag) => tag && tag?.category === TAG_CATEGORIES.FEATURE
        );
      }

      this._id = data._id || this._id;
      this.sku = data.sku || this.sku;
      this.name = data.name || this.name;
      this.file = data.file || this.file;
      this.link = data.link || this.link;
      this.affiliateLink = data.affiliateLink || this.affiliateLink;
      this.unitOfMeasurement = data.unitOfMeasurement || this.unitOfMeasurement;
      this.value = data.value || this.value;
      this.refx = data.refx || this.refx;
      this.inventory = data.inventory || this.inventory;
      this.refy = data.refy || this.refy;
      this.refz = data.refz || this.refz;
      this.notUse = data.notUse || this.notUse;
      this.active =
        typeof data.active === "boolean" ? data.active : this.active;
      this.refimage = data.refimage || this.refimage;
      this.tags = data.tags || this.tags;
      this.supplier = data.supplier || this.supplier;
      if (data.typology) {
        const typology = new Tag(data.typology);
        this.typology = typology;
      }
      if (data.category) {
        const category = new Tag(data.category);
        this.category = category;
      }
      if (data.subCategory) {
        const subCategory = new Tag(data.subCategory);
        this.subCategory = subCategory;
      }
      if (data.otherFiles && data.otherFiles.length) {
        this.otherFiles = data.otherFiles.map((file) => {
          if (file instanceof FileLink) {
            return file;
          } else return new FileLink(file);
        });
      }
      this.projectTemplateNames =
        data.projectTemplateNames || this.projectTemplateNames;

      if (data.oldTags) {
        this.oldTags = data.oldTags;
      }
      this.frequency_used =
        typeof data.frequency_used === "number"
          ? data.frequency_used
          : this.frequency_used;
    }
  }
}

import React from "react";

import { Modal, ModalHeader, ModalBody } from "shards-react";

import { Worker, Viewer as ViewerPDF } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import pt_PT from "@react-pdf-viewer/locales/lib/pt_PT.json";

const Viewer = ({ link, toggle }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [defaultTabs[0]],
  });

  return link ? (
    <Modal
      size="lg"
      modalContentClassName="card card-small"
      modalClassName="modal-pdf"
      open={!!link}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle} closeAriaLabel titleClass="w-100">
        <div className="d-flex align-items-center justify-content-center">
          <p>Visualizar PDF</p>
        </div>
      </ModalHeader>
      <ModalBody style={{ padding: "10px" }}>
        <Worker workerUrl={pdfjsWorker}>
          <div style={{ overflowY: "auto", height: "calc(100vh - 150px)" }}>
            <ViewerPDF
              fileUrl={link}
              plugins={[defaultLayoutPluginInstance]}
              localization={pt_PT}
            />
          </div>
        </Worker>
      </ModalBody>
    </Modal>
  ) : null;
};

Viewer.propTypes = {};

export default Viewer;

import React, { useMemo, useEffect, useState, useCallback } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { Button } from "shards-react";
import { getTagsV2, addTag, deleteCategory } from "../../actions/mobiTag";
import { debounce } from "../../utils/throttle";
import SafeDeleteButton from "../common/SafeDeleteButton";
import "./MobiTagManager.scss";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import { useAsyncReactStateFunction } from "../../utils/useAsyncReactStateFunction";
import { Tag } from "../../data/mobi.ts";

const MobiTagManager = ({
  onChange,
  container,
  category,
  allowNested,
  parent: initialParent,
  refreshTags,
}) => {
  const MAX_DEPTH = 3;

  const DISPLAY_TAG_NAME = {
    category: "Categoria",
    color: "Cor",
    style: "Estilo",
    projectTemplateName: "Ambiente",
    typology: "Tipologia",
    feature: "Característica",
    colorTone: "Tom do ambiente",
  };
  const DEFAULT_DEBOUNCE_TIME = 500;
  const MAX_LENGTH = 2;
  const ESC_KEY = 27;
  const ENTER_KEY = 13;
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [parent, setParent] = useState(null);
  const { listener } = useAsyncReactStateFunction(function (e) {
    if (!isLoading && e.keyCode == ESC_KEY) {
      if (parent && parent.length) {
        onParentChangeClick(1);
      } else {
        setShow(false);
      }
    }
  });

  const onSearchUpdate = useCallback(
    debounce((text, parent = null) => {
      setIsLoading(true);
      getTagsV2(
        text.length >= MAX_LENGTH ? text : "",
        category,
        parent && parent.length ? parent[parent.length - 1]._id : null
      )
        .then((response) => {
          setTags(response);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }, DEFAULT_DEBOUNCE_TIME),
    []
  );

  const hasSubcategories = useMemo(() => {
    return allowNested && (!parent || (parent && parent.length < MAX_DEPTH));
  }, [parent, allowNested]);

  useEffect(() => {
    return () => {
      document.onkeyup = null;
    };
  }, []);

  useEffect(() => {
    if (show) {
      document.getElementById("mobi-tag-manager-input").select();
      if (!document.onkeyup) document.onkeyup = (e) => listener.current(e);
      setIsLoading(true);
      if (initialParent) {
        setParent(initialParent);
      } else {
        setSearch("");
        onSearchUpdate("", parent);
      }
    } else {
      document.onkeyup = null;
      setParent(null);
      setTags([]);
    }
  }, [show]);

  useEffect(() => {
    if (Array.isArray(parent)) {
      setSearch("");
      onSearchUpdate("", parent);
    }
  }, [parent]);

  function onEditClick() {
    setShow(!show);
  }

  function onSearchChange(ev) {
    setSearch(ev.target.value);
    ((search.length >= MAX_LENGTH && ev.target.value.length < MAX_LENGTH) ||
      ev.target.value.length >= MAX_LENGTH) &&
      onSearchUpdate(ev.target.value, parent);
  }

  function onBackdropClick(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    setShow(false);
  }

  function onAddClick() {
    if (!search) return;
    if (search?.length < 2) {
      toast.error("A tag precisa de no mínimo 2 dígitos", { autoClose: 5000 });
      return;
    }
    const newTag = {
      category,
      name: search,
      parent: parent && parent.length ? parent[parent.length - 1]._id : null,
    };
    addTag(newTag)
      .then((t) => {
        const tag = new Tag(t);
        toast.success("Tag adicionada com sucesso");
        onChange("add", tag, parent);
      })
      .finally(() => {
        setSearch("");
        onSearchUpdate("", parent);
        refreshTags && refreshTags();
      });
  }

  function onRemoveClick(tagToBeRemoved) {
    return deleteCategory(tagToBeRemoved._id)
      .then(() => {
        toast.success("Tag removida com sucesso");
        onChange("delete", tagToBeRemoved._id, parent);
      })
      .finally(() => {
        setSearch("");
        onSearchUpdate("", parent);
      });
  }

  function onTagClick(tag) {
    setTags([]);
    setIsLoading(true);
    if (!parent) {
      setParent([tag]);
    } else {
      parent.push(tag);
      setParent([...parent]);
    }
  }

  function onBackToRootClick() {
    if (parent.length) {
      setIsLoading(true);
      setTags([]);
      setParent([]);
    }
  }

  function onParentChangeClick(stepsBack) {
    if (stepsBack > 0) {
      setIsLoading(true);
      setTags([]);
      setParent(parent.slice(0, parent.length - stepsBack));
    }
  }

  function onSearchInputKeyDown(ev) {
    if (ev.keyCode === ENTER_KEY) {
      ev.stopPropagation();
      ev.preventDefault();

      onAddClick();
    }
  }

  return (
    <>
      <button
        className="clickable tag-manager-button"
        onMouseDown={onEditClick}
      >
        <i
          className="fa fa-pencil-alt"
          style={{
            cursor: "pointer",
          }}
        />
      </button>
      {show
        ? ReactDOM.createPortal(
            //eslint-disable-next-line
            <div
              onClick={(ev) => {
                ev.stopPropagation();
                ev.preventDefault();
              }}
              className="d-flex align-items-center justify-content-center"
              style={{
                position: "fixed",
                width: "100vw",
                height: "100vh",
                top: 0,
                left: 0,
                zIndex: 10000,
              }}
            >
              {/* eslint-disable-next-line */}
              <div
                onClick={onBackdropClick}
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  backgroundColor: "black",
                  zIndex: 10000,
                  opacity: 0.75,
                }}
              />
              <div
                className="p-2 d-flex flex-column"
                style={{
                  width: "50%",
                  height: "60%",
                  zIndex: 10001,
                  backgroundColor: "white",
                  borderRadius: "5px",
                }}
              >
                <div className="mb-1">
                  <span>Gerenciar Tag</span>
                  <button
                    onClick={() => setShow(false)}
                    type="button"
                    className="close ml-auto d-flex align-items-center"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                    <span style={{ fontSize: "11px", color: "lightgray" }}>
                      [ESC]
                    </span>
                  </button>
                </div>
                <div className="mb-1">
                  <span>
                    <button
                      className="transparent"
                      type="button"
                      onClick={onBackToRootClick}
                      style={{
                        fontWeight: "700",
                        cursor: parent && parent.length ? "pointer" : "auto",
                      }}
                    >
                      {DISPLAY_TAG_NAME[category]}
                    </button>
                    {parent && parent.length
                      ? parent.map((p, key) => (
                          <button
                            key={key}
                            onClick={() => {
                              onParentChangeClick(parent.length - (key + 1));
                            }}
                            className="transparent text-hover-highlight"
                            type="button"
                          >{` > ${p.name}`}</button>
                        ))
                      : ""}
                  </span>
                </div>
                <div className="d-flex">
                  <input
                    id="mobi-tag-manager-input"
                    onKeyDown={onSearchInputKeyDown}
                    /* eslint-disable-next-line */
                    autoFocus
                    placeholder="Digite aqui para pesquisar"
                    className="w-100 pr-1 pl-1"
                    value={search}
                    onChange={onSearchChange}
                  />
                  <Button
                    theme="success"
                    className="ml-2"
                    onClick={onAddClick}
                    disabled={!search || isLoading}
                  >
                    <span>Adicionar</span>
                    <span style={{ fontSize: "10px", color: "#f1f1f1" }}>
                      {" "}
                      [ENTER]
                    </span>
                  </Button>
                </div>
                <div className="overflow-auto mt-2 pr-2 pl-2">
                  {isLoading ? (
                    <div className="d-flex align-items-center justify-content-center">
                      <Loader
                        width="30px"
                        height="15px"
                        type="Rings"
                        color="#000000"
                      />
                    </div>
                  ) : tags.length ? (
                    tags.map((tag) => (
                      <div
                        className="pt-1 pb-1  mobi-tag-li pl-1 d-flex"
                        key={tag._id}
                      >
                        <button
                          type="button"
                          className={`${
                            hasSubcategories ? "clickable" : ""
                          } w-100 transparent`}
                          onClick={() => hasSubcategories && onTagClick(tag)}
                        >
                          {tag.name}
                          {hasSubcategories ? (
                            <span>
                              {" - "}
                              <span style={{ color: "lightgrey" }}>
                                {tag.children && tag.children.length
                                  ? tag.children.length
                                  : 0}
                              </span>
                            </span>
                          ) : null}
                        </button>
                        <SafeDeleteButton
                          onRemove={() => {
                            return onRemoveClick(tag);
                          }}
                          className="ml-auto enlarge-on-hover"
                        />
                      </div>
                    ))
                  ) : (
                    <div className="d-flex align-items-center justify-content-center">
                      <div style={{ color: "grey" }}>
                        Nenhuma tag encontrada.
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>,
            container
          )
        : null}
    </>
  );
};

MobiTagManager.propTypes = {
  container: PropTypes.object.isRequired,
  category: PropTypes.string.isRequired,
  allowNested: PropTypes.bool,
  parent: PropTypes.arrayOf(PropTypes.object),
};

MobiTagManager.defaultProps = {
  container: document.body,
  allowNested: false,
  parent: null,
};

export default MobiTagManager;

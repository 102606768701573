import React from "react";
import { Nav } from "shards-react";

import SidebarNavItem from "./SidebarNavItem";
import IfAllowed from "../../common/IfAllowed";
import { Store } from "../../../flux";
import { signout } from "../../../actions/auth";

class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navItems: Store.getSidebarItems(),
      user: null,
    };

    this.onChange = this.onChange.bind(this);
  }

  //TODO Fix all UNSAFE_ methods.
  UNSAFE_componentWillMount() {
    Store.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({
      ...this.state,
      navItems: Store.getSidebarItems(),
    });
  }

  render() {
    const { navItems: items } = this.state;
    return (
      <div
        className="nav-wrapper"
        style={{
          overflowY: "scroll",
        }}
      >
        {items.map((nav, idx) => (
          <div key={idx}>
            {nav.title ? (
              <React.Fragment>
                <div key={idx}>
                  <h6 className="main-sidebar__nav-title">{nav.title}</h6>

                  {typeof nav.items !== "undefined" && nav.items.length && (
                    <Nav className="nav--no-borders flex-column">
                      {nav.items.map((item, idx) => {
                        if (item.permission) {
                          return (
                            <IfAllowed
                              key={idx}
                              resource={item.permission}
                              perform="read:any"
                            >
                              <SidebarNavItem item={item} />
                            </IfAllowed>
                          );
                        } else {
                          return <SidebarNavItem key={idx} item={item} />;
                        }
                      })}
                    </Nav>
                  )}
                </div>
              </React.Fragment>
            ) : (
              <button
                className="transparent"
                type="button"
                onClick={() => {
                  signout();
                  window.open(`/login`, "_self");
                }}
                style={{ width: "100%" }}
                key={idx}
              >
                {typeof nav.items !== "undefined" && nav.items.length && (
                  <Nav className="nav--no-borders flex-column">
                    {nav.items.map((item, idx) => (
                      <SidebarNavItem key={idx} item={item} />
                    ))}
                  </Nav>
                )}
              </button>
            )}
          </div>
        ))}
      </div>
    );
  }
}

export default SidebarNavItems;

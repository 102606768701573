import React, { useState, useEffect } from "react";

import { getAuthenticatedUser, getCookie } from "../../actions/auth";
import { AuthProvider } from "./AuthContext";

const Authorization = ({ children }) => {
  const [state, setState] = useState({
    authenticated: false,
    user: {
      role: "visitor",
    },
    roles: [],
    accessToken: "",
  });
  const [isLoading, setIsLoading] = useState(true);

  const authProviderValue = {
    ...state,
    initiateLogin: initiateLogin,
    handleAuthentication: handleAuthentication,
    logout: logout,
  };

  useEffect(() => {
    setIsLoading(true);
    const user = getAuthenticatedUser();
    const token = getCookie();

    if (user) {
      setState({
        authenticated: true,
        user: user,
        accessToken: token,
      });
    }

    setIsLoading(false);
  }, []);

  function initiateLogin() {}

  function logout() {}

  function handleAuthentication() {}

  return (
    <AuthProvider value={authProviderValue}>
      {isLoading ? null : children}
    </AuthProvider>
  );
};

export default Authorization;

import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getQueryStringValue } from "../../actions/auth";

export default () => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState({
    tags: [],
    makers: [],
    models: [],
    priceRanges: {
      maxDailyPrice: null,
      maxMonthlyPrice: null,
      maxSellPrice: null,
      maxWeeklyPrice: null,
      minDailyPrice: null,
      minMonthlyPrice: null,
      minSellPrice: null,
      minWeeklyPrice: null,
    },
  });

  const initialValues = {
    supplier: "",
    tags: [],
    styles: [],
    categories: [],
    colors: [],
    typologies: [],
    type: "preview",
    hasFile: false,
    onlyAvailable: "null",
    order: "frequency_used",
    notUse: "true",
    active: true,
    projectTemplateNames: [],
    limit: 28,
    page: 0,
    search: "",
    project: "",
    isLastPage: false,
    hasOtherFiles: false,
    colorTones: [],
    features: [],
    typology: null,
    category: null,
    subCategory: null,
    oldTags: {
      styles: [],
      categories: [],
      colors: [],
      typologies: [],
    },
  };
  const [values, setValues] = useState(initialValues);

  useEffect(() => {
    if (!isLoading) {
      let query = "?";

      query += `&search=${values.search}`;
      query += `&supplier=${values.supplier}`;
      query += `&type=${values.type}`;
      query += `&project=${values.project}`;
      query += `&notUse=${values.notUse}`;
      query += `&hasFile=${values.hasFile}`;
      query += `&hasOtherFiles=${values.hasOtherFiles}`;
      // query += `&isLastPage=${values.isLastPage}`;
      query += `&colors=${JSON.stringify(values.colors)}`;
      query += `&typologies=${JSON.stringify(values.typologies)}`;
      query += `&styles=${JSON.stringify(values.styles)}`;
      query += `&features=${JSON.stringify(values.features)}`;
      query += `&colorTones=${JSON.stringify(values.colorTones)}`;
      query += `&tags=${JSON.stringify(values.tags)}`;
      query += `&onlyAvailable=${values.onlyAvailable}`;
      query += `&order=${values.order}`;
      query += `&typology=${values.typology}`;
      query += `&category=${values.category}`;
      query += `&subCategory=${values.subCategory}`;

      history.replace({
        search: query,
      });
    }
  }, [values]);

  function refreshTags() {
    setIsLoading(true);
    const search = getQueryStringValue("search");
    const supplier = getQueryStringValue("supplier");
    const type = getQueryStringValue("type");
    const project = getQueryStringValue("project");

    const notUse = getQueryStringValue("notUse");
    const hasFile = getQueryStringValue("hasFile");
    const hasOtherFiles = getQueryStringValue("hasOtherFiles");
    const colors = getQueryStringValue("colors");
    const typologies = getQueryStringValue("typologies");
    const styles = getQueryStringValue("styles");
    const tags = getQueryStringValue("tags");
    const onlyAvailable = getQueryStringValue("onlyAvailable");
    const order = getQueryStringValue("order");
    const features = getQueryStringValue("features");
    const colorTones = getQueryStringValue("colorTones");
    const typology = getQueryStringValue("typology");
    const category = getQueryStringValue("category");
    const subCategory = getQueryStringValue("subCategory");

    const newValues = {
      ...initialValues,
      search: search !== undefined ? search : initialValues.search,
      order: order !== undefined ? order : initialValues.order,
      supplier: supplier !== undefined ? supplier : initialValues.supplier,
      type: type !== undefined ? type : initialValues.type,
      project:
        project !== undefined && project !== "false"
          ? project
          : initialValues.project,
      notUse:
        notUse !== undefined
          ? notUse === "null"
            ? "null"
            : notUse === "true"
          : initialValues.notUse,
      hasFile:
        hasFile !== undefined ? hasFile === "true" : initialValues.hasFile,
      hasOtherFiles:
        hasOtherFiles !== undefined
          ? hasOtherFiles === "true"
          : initialValues.hasOtherFiles,
      onlyAvailable:
        onlyAvailable !== undefined
          ? onlyAvailable === "null"
            ? "null"
            : onlyAvailable === "true"
          : initialValues.onlyAvailable,
      colors: colors ? JSON.parse(colors) : [],
      typologies: typologies ? JSON.parse(typologies) : [],
      tags: tags ? JSON.parse(tags) : [],
      styles: styles ? JSON.parse(styles) : [],
      features: features ? JSON.parse(features) : [],
      colorTones: colorTones ? JSON.parse(colorTones) : [],
      typology: typology === undefined || typology === "null" ? null : typology,
      category: category === undefined || category === "null" ? null : category,
      subCategory:
        subCategory === undefined || subCategory === "null"
          ? null
          : subCategory,
    };

    setValues(newValues);
    setIsLoading(false);

    return Promise.resolve(newValues);
  }

  function clear() {
    return { ...initialValues, type: values.type };
  }

  function onFilterUpdate(values) {
    return new Promise((s) => {
      if (values.maker) {
        setFilters((filters) => {
          return {
            ...filters,
            models: filters.makers.find((maker) => maker.name === values.maker)
              .models,
          };
        });
      }

      setValues((oldValues) => {
        if (values.maker && oldValues.maker !== values.maker) {
          values.model = "";
        }
        values.page = 0;
        values.isLastPage = !!values.isLastPage;
        const newValues = { ...oldValues, ...values };
        s(newValues);

        return { ...oldValues, ...newValues };
      });
    });
  }

  function countActiveFilters() {
    const fields = ["model", "maker"];
    let count = 0;
    for (let f of fields) {
      if (values[f]) {
        count++;
      }
    }

    if (values.priceType) {
      count++;
    } else if (
      values.priceRanges.min !== null &&
      values.priceRanges.max !== null &&
      (values.priceRanges.min !== filters.priceRanges.minDailyPrice ||
        values.priceRanges.max !== filters.priceRanges.maxSellPrice) &&
      filters.makers.length
    ) {
      count++;
    }
    return count;
  }

  function getRange(priceType, filters) {
    let prices = ["daily", "weekly", "monthly", "sell"];

    if (priceType.match(/rent/)) {
      prices = ["daily", "weekly", "monthly"];
      if (priceType.match(/rent-/)) {
        const type = priceType.match(/rent-(.*)/)[1];
        const word = `${type.charAt(0).toUpperCase() + type.slice(1)}`;
        prices = [word];
      }
    }
    if (priceType === "sell") {
      prices = ["sell"];
    }

    return {
      min: Math.min(
        ...prices
          .map((p) => {
            const word = `${p.charAt(0).toUpperCase() + p.slice(1)}`;
            return filters.priceRanges[`min${word}Price`];
          })
          .filter((v) => v !== null)
      ),
      max: Math.max(
        ...prices
          .map((p) => {
            const word = `${p.charAt(0).toUpperCase() + p.slice(1)}`;
            return filters.priceRanges[`max${word}Price`];
          })
          .filter((v) => v !== null)
      ),
    };
  }

  function onPageIncrease() {
    return new Promise((s) => {
      setValues((values) => {
        const pagedValues = { ...values, page: values.page + 1 };
        s([pagedValues, () => setValues({ ...pagedValues })]);
        return pagedValues;
      });
    });
  }

  function resetPageFilter() {
    const reset = { ...values, isLastPage: false, page: 0 };
    setValues(reset);
    return reset;
  }

  const clearCatAndSub = (name) => {
    if (name === "typology") {
      setValues({ ...values, category: null, subCategory: null });
    }
    if (name === "category") {
      setValues({ ...values, subCategory: null });
    }
  };
  return {
    values,
    setValues,
    isLoading,
    setIsLoading,
    resetPageFilter,
    refreshTags,
    filters,
    onFilterUpdate,
    onPageIncrease,
    getRange,
    countActiveFilters,
    clear,
    clearCatAndSub,
  };
};

import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Row,
  Card,
  Col,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ButtonGroup,
} from "shards-react";
import { MetadataContext } from "../components/metadata/MetadataContext";
import PageTitle from "../components/common/PageTitle";
import {
  listVouchers,
  updateStatusVoucher,
  deleteVoucher,
} from "../actions/voucher";
import Moment from "react-moment";
import FormVoucher from "../components/vouchers/Form";
import { toast } from "react-toastify";
import ConfirmationButton from "../components/common/ConfirmationButton";

import FilteredTable from "../components/FilteredTable/index";

const Vouchers = () => {
  const [vouchers, setVouchers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const { scopedByCompany, metadata } = useContext(MetadataContext);
  const listAllVouchersScopedByCompany = scopedByCompany(listVouchers);
  const [showModalVoucher, setShowModalVoucher] = useState(false);

  const [voucher, setVoucher] = useState();

  const refreshTableData = () => {
    setIsLoading(true);
    listAllVouchersScopedByCompany()
      .then((data) => {
        setIsLoading(false);
        if (data && data.length) {
          setTotal(data.length);
          setVouchers(data.reverse());
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const props = { voucher, refreshTableData };

  const handleItemStatus = (row) => {
    updateStatusVoucher(row.original._id)
      .then((result) => {
        refreshTableData();
        toast.success(result.msg, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        toast.error(err.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handleItemEdit = (row) => {
    setVoucher(row.original);
    modalVoucher();
  };

  const handleItemDelete = (row) => {
    deleteVoucher(row.original._id).then((result) => {
      refreshTableData();
      toast.success(result.msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
  };

  useEffect(() => {
    refreshTableData();
  }, []);

  const tableColumns = [
    {
      Header: "Nome",
      accessor: "name",
      className: "text-center",
      Cell: (row) => <span>{row.original.name}</span>,
    },
    {
      Header: "Código Desconto",
      accessor: "code",
      className: "text-center",
      Cell: (row) => <span>{row.original.code}</span>,
    },
    {
      Header: "Tipo de Desconto",
      accessor: "typeValue",
      className: "text-center",
      Cell: (row) => (
        <span>
          {row.original.typeValue === "percent" ? "Percentual" : "Fixo"}
        </span>
      ),
    },
    {
      Header: "Valor",
      accessor: "amount",
      className: "text-center",
      Cell: (row) => (
        <span>
          {row.original.typeValue === "percent"
            ? `${row.original.amount}%`
            : row.original.amount.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}
        </span>
      ),
    },
    {
      Header: "Contexto",
      accessor: "company",
      maxWidth: 80,
      className: "text-center",
      Cell: (row) =>
        row.original.company
          ? metadata.companies.find(
              (company) => company.label === row.original.company
            ).name
          : "Nenhum",
    },
    {
      Header: "Criado",
      accessor: "date",
      maxWidth: 130,
      className: "text-center",
      Cell: (row) => (
        <span>
          <Moment format="DD/MM/YYYY hh:mm">{row.original.createdAt}</Moment>
        </span>
      ),
    },
    {
      Header: "Ativo?",
      accessor: "isActive",
      maxWidth: 80,
      className: "text-center",
      Cell: (row) => <strong>{row.original.isActive ? "Sim" : "Não"}</strong>,
    },
    {
      Header: "AÇÕES",
      accessor: "actions",
      maxWidth: 150,
      sortable: false,
      Cell: (row) => (
        <ButtonGroup size="sm" className="mx-auto">
          <Button
            title="Editar"
            theme="white"
            onClick={() => handleItemEdit(row)}
          >
            <i className="material-icons">&#xE254;</i>
          </Button>

          <Button
            title={row.original.isActive ? "Desativar" : "Ativar"}
            theme="white"
            onClick={() => handleItemStatus(row)}
          >
            {row.original.isActive ? (
              <i className="material-icons">&#xe14c;</i>
            ) : (
              <i className="material-icons">&#xe5ca;</i>
            )}
          </Button>
          <ConfirmationButton
            title="Remover"
            theme="white"
            onClick={() => handleItemDelete(row)}
          >
            <i className="material-icons">&#xE872;</i>
          </ConfirmationButton>
        </ButtonGroup>
      ),
    },
  ];

  const newVoucher = () => {
    setVoucher();
    modalVoucher();
  };

  const modalVoucher = () => {
    setShowModalVoucher(!showModalVoucher);
  };

  return (
    <Container fluid className="main-content-container px-4">
      <Row className="page-header py-4">
        <Col md="6">
          <PageTitle
            title="Vouchers"
            subtitle="Visão Geral"
            className="text-sm-left mb-3"
          />
        </Col>
        <Col md="5" className="col d-flex align-items-center" />
      </Row>
      <Button className="mb-2" onClick={newVoucher}>
        Adicionar cupom
      </Button>
      <Card>
        <CardBody>
          Total: {total}
          <FilteredTable
            isLoading={isLoading}
            list={vouchers}
            columns={tableColumns}
            config={{
              pageSizeOptions: [5, 10, 15, 20, 30],
              searchableColumns: ["name", "code"],
              pageSize: 10,
            }}
          />
        </CardBody>
      </Card>
      <div>
        <Modal
          size="lg"
          modalContentClassName="card card-small"
          open={showModalVoucher}
          toggle={modalVoucher}
        >
          <ModalHeader>Detalhes do cupom</ModalHeader>
          <ModalBody style={{ maxHeight: "70vh", overflow: "auto" }}>
            <FormVoucher
              modalVoucher={modalVoucher}
              {...props}
              refreshTableData={refreshTableData}
            />
          </ModalBody>
        </Modal>
      </div>
    </Container>
  );
};
export default Vouchers;

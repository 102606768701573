import React, { useState } from "react";

import { toast } from "react-toastify";
import ReactImageFallback from "react-image-fallback";
import { Card, CardBody, Button, FormSelect } from "shards-react";

import DropzoneRender from "./dropzoneRender";
import ConfirmationButton from "../common/ConfirmationButton";
import IfAllowed from "../../components/common/IfAllowed";
import ButtonUpload from "../common/ButtonUpload";
import { updateFinalProject, uploadFile } from "../../actions/projects";
import { useHistory } from "react-router-dom";

import QualityButton from "../../components/project/qualityButton";
import Slider from "react-slick";

import { BUCKET_URL } from "./../../config";
import SetLinkIntoString from "../common/SetLinkIntoString";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      type="button"
      className={className}
      style={{ ...style, filter: "invert(1)" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      type="button"
      className={className}
      style={{ ...style, filter: "invert(1)" }}
      onClick={onClick}
    />
  );
}

const RendersV2 = ({
  titleStep,
  stepName,
  statusName,
  finalProject,
  setFinalProject,
  projectInfo,
  sendLightbox,
  updateProjectStatus,
  setProjectInfo,
  showQualityButton,
  showActions,
}) => {
  const [showChangeImg, setShowChangeImg] = useState(false);
  const [openedLikedComment, setOpenedLikedComment] = useState(true);
  const [openedDislikedComment, setOpenedDislikedComment] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    draggable: false,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    // afterChange: () => {
    // setOpenedLikedComment(null);
    // setOpenedDislikedComment(null);
    // },
  };

  const updateStep = (finalProjectObj, projectId) => {
    setFinalProject({ ...finalProjectObj });
    return updateFinalProject(projectId, finalProjectObj);
  };

  const removeImg = (idx) => {
    finalProject[stepName].files.splice(idx, 1);
    updateStep(finalProject, projectInfo.id);
  };

  const updateImg = (file, idx) => {
    setIsLoading(true);

    uploadFile(file, projectInfo.id, stepName).then(() => {
      finalProject[stepName].files[idx].file = file.name;
      updateStep(finalProject, projectInfo.id);
      setShowChangeImg(!showChangeImg);
      toast.success(`Arquivo enviado com sucesso`, {
        autoClose: 5000,
      });
      setIsLoading(false);
    });
  };

  const updateImgTag = (idx, value) => {
    setIsLoading(true);
    // const file = e.target.files[0];

    finalProject[stepName].files[idx].tag = value;
    updateStep(finalProject, projectInfo.id)
      .then(() => {
        toast.success(`Arquivo enviado com sucesso`, {
          autoClose: 5000,
        });
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <Card small>
      <CardBody className="p-0">
        <div>
          <h6 className="text-center m-0 mt-2">{titleStep}</h6>
        </div>

        {showActions ? (
          <IfAllowed resource="admin-ladecora:project:send-files">
            <div>
              {showQualityButton ? (
                <Button
                  teme="primary"
                  style={{ float: "right" }}
                  className="text-center m-0 m-2"
                  onClick={() => {
                    history.push(
                      `/project/${projectInfo.id}/quality/${statusName}`
                    );
                  }}
                >
                  Tela de qualidade{" "}
                  <i className="material-icons mr-1">open_in_new</i>
                </Button>
              ) : null}

              <Button
                teme="primary"
                style={{ float: "right" }}
                className="text-center m-0 m-2"
                onClick={() => {
                  history.push(
                    `/project/${projectInfo.id}/mobi?project=${projectInfo.id}&type=${statusName}`
                  );
                }}
              >
                Ver móveis
              </Button>
              {projectInfo.status === "waiting_quality" ? null : (
                <QualityButton
                  id={projectInfo.id}
                  updateProjectStatus={updateProjectStatus}
                  setProjectInfo={setProjectInfo}
                  projectInfo={projectInfo}
                  style={{ float: "right" }}
                  status={"waiting_quality"}
                  message={"Projeto enviado para qualidade com sucesso"}
                  buttonText={"Enviar para qualidade"}
                />
              )}
            </div>
            <div className="user-details__user-data border-top border-bottom p-4">
              <DropzoneRender
                finalProject={finalProject}
                stepName={stepName}
                updateStep={updateStep}
                projectInfo={projectInfo}
              />
            </div>
          </IfAllowed>
        ) : null}

        {finalProject[stepName].details && (
          <div className="user-details__user-data border-top border-bottom">
            <div>
              <h6 className="text-center m-0 mt-2">Comentário</h6>
            </div>

            {finalProject[stepName].details}
          </div>
        )}
        {finalProject[stepName].files.length > 0 ? (
          <div className="user-details__user-data border-bottom">
            <div>
              <Slider {...settings}>
                {finalProject[stepName].files.map((img, idx) => {
                  return (
                    <div
                      className="user-details__user-data border-bottom p-2"
                      key={idx}
                    >
                      <div className="d-flex align-items-center justify-content-center">
                        <div style={{ width: "200px" }}>
                          <div>
                            <ReactImageFallback
                              fallbackImage={
                                BUCKET_URL +
                                "/projects/" +
                                projectInfo.id +
                                `/${stepName}/` +
                                img.file
                              }
                              initialImage="./images/loaders/loader.svg"
                              alt={""}
                              className="border rounded"
                              style={{ width: "200px", height: "200px" }}
                              src={
                                BUCKET_URL +
                                "/projects/" +
                                projectInfo.id +
                                `/${stepName}/thumbnails/` +
                                img.file
                              }
                              onClick={() =>
                                sendLightbox(
                                  finalProject[stepName].files.map(
                                    (file) =>
                                      `${BUCKET_URL}/projects/${projectInfo.id}/${stepName}/${file.file}`
                                  ),
                                  idx
                                )
                              }
                            />
                          </div>
                          <IfAllowed resource="admin-ladecora:project:send-files">
                            <div>
                              <FormSelect
                                className="form-control mt-1"
                                disabled={isLoading}
                                style={{
                                  width: "200px",
                                  borderBottomLeftRadius: "0px",
                                  borderBottomRightRadius: "0px",
                                }}
                                value={
                                  finalProject[stepName].files[idx].tag || ""
                                }
                                onChange={(ev) => {
                                  updateImgTag(idx, ev.target.value);
                                }}
                              >
                                <option disabled value="">
                                  Selecione a categoria...
                                </option>
                                <option value="workPlanLayout">
                                  Planta de Layout
                                </option>
                                <option value="finishingLayout">
                                  Planta de acabamentos
                                </option>
                                <option value="lights">
                                  Distribuição de luminárias
                                </option>
                                <option value="decorationPanel">
                                  Painel de decoração
                                </option>
                                {/* <option value="coverageFloorPlans">
                                  Planta baixa de cobertura
                                </option> */}
                                <option value="details">Detalhamentos</option>
                              </FormSelect>
                            </div>

                            <div>
                              <ButtonUpload
                                onChange={(e) => updateImg(e, idx)}
                                outline
                                size="sm"
                                theme="primary"
                                disabled={isLoading}
                                style={{
                                  borderRadius: "0px",
                                  borderBottom: "0px",
                                }}
                                className="d-block w-100"
                              >
                                {isLoading ? "Enviando" : "Alterar imagem"}
                              </ButtonUpload>
                            </div>

                            <div>
                              <ConfirmationButton
                                disabled={isLoading}
                                style={{
                                  width: "200px",
                                  borderTopLeftRadius: "0px",
                                  borderTopRightRadius: "0px",
                                }}
                                theme="danger"
                                onClick={() => {
                                  removeImg(idx);
                                }}
                              >
                                Excluir
                              </ConfirmationButton>
                            </div>
                          </IfAllowed>
                        </div>
                      </div>

                      <div>
                        <>
                          <button
                            type="button"
                            className={`transparent w-100 mt-1 mb-1 ${
                              img.likeComments ? "clickable" : "click-blocked"
                            }`}
                            style={{
                              borderRadius: "0.4rem",
                              border: img.likeComments
                                ? "1px solid var(--newbackground-color)"
                                : "1px solid grey",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            onClick={() =>
                              img.likeComments &&
                              setOpenedLikedComment(
                                openedLikedComment === true ? false : true
                              )
                            }
                          >
                            <h6
                              style={{
                                margin: "auto",
                                color: img.likeComments
                                  ? "#3D5170"
                                  : "lightgrey",
                              }}
                            >
                              O que o cliente gostou
                            </h6>
                            <h3>
                              <span className="file-manager__item-icon">
                                {openedLikedComment && img.likeComments ? (
                                  <i
                                    className="material-icons"
                                    style={{ verticalAlign: "middle" }}
                                  >
                                    &#xe5ce;
                                  </i>
                                ) : (
                                  <i
                                    className="material-icons"
                                    style={{ verticalAlign: "middle" }}
                                  >
                                    &#xe5cf;
                                  </i>
                                )}
                              </span>
                            </h3>
                          </button>
                          {openedLikedComment && img.likeComments && (
                            <div
                              contentEditable={true}
                              suppressContentEditableWarning={true}
                              style={{ userSelect: "auto" }}
                            >
                              <div
                                contentEditable={false}
                                suppressContentEditableWarning={true}
                                style={{ cursor: "auto" }}
                              >
                                <SetLinkIntoString string={img.likeComments} />
                              </div>
                            </div>
                          )}
                        </>

                        <>
                          <button
                            type="button"
                            className={`transparent w-100 mt-1 mb-1 ${
                              img.dislikeComments
                                ? "clickable"
                                : "click-blocked"
                            }`}
                            style={{
                              borderRadius: "0.4rem",
                              border: img.dislikeComments
                                ? "1px solid var(--newbackground-color)"
                                : "1px solid grey",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            onClick={() =>
                              img.dislikeComments &&
                              setOpenedDislikedComment(
                                openedDislikedComment === true ? false : true
                                // openedDislikedComment === idx ? null : idx
                              )
                            }
                          >
                            <h6
                              style={{
                                margin: "auto",
                                color: img.dislikeComments
                                  ? "#3D5170"
                                  : "lightgrey",
                              }}
                            >
                              O que o cliente não gostou
                            </h6>
                            <h3>
                              <span className="file-manager__item-icon">
                                {openedDislikedComment &&
                                img.dislikeComments ? (
                                  <i
                                    className="material-icons"
                                    style={{ verticalAlign: "middle" }}
                                  >
                                    &#xe5ce;
                                  </i>
                                ) : (
                                  <i
                                    className="material-icons"
                                    style={{ verticalAlign: "middle" }}
                                  >
                                    &#xe5cf;
                                  </i>
                                )}
                              </span>
                            </h3>
                          </button>
                          {openedDislikedComment && img.dislikeComments && (
                            <div
                              contentEditable={true}
                              suppressContentEditableWarning={true}
                              style={{ userSelect: "auto" }}
                            >
                              <div
                                contentEditable={false}
                                suppressContentEditableWarning={true}
                                style={{ cursor: "auto" }}
                              >
                                <SetLinkIntoString
                                  string={img.dislikeComments}
                                />
                              </div>
                            </div>
                          )}
                        </>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        ) : (
          <div
            className="text-center m-3"
            style={{
              fontSize: "40px",
              textShadow: "-1px 1px 0px #fff, 0px -1px #848484",
              color: "#d4d4d4",
            }}
          >
            Nenhum arquivo enviado
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default RendersV2;

import React, { useContext } from "react";
import { FormSelect } from "shards-react";

import { MetadataContext } from "../metadata/MetadataContext";

const CompanySelector = () => {
  const { company, onCompanyChange, metadata } = useContext(MetadataContext);

  function onChange(ev) {
    onCompanyChange(ev.target.value);
  }
  return (
    <div
      style={{ minWidth: "180px" }}
      className="d-flex flex-row justify-content-center align-items-center mr-3"
    >
      <div className="label mb-0 mr-2">
        <strong>Contexto</strong>
      </div>
      <FormSelect
        style={{ height: "fit-content" }}
        className={company !== "all" ? "context-highlight" : ""}
        value={company}
        onChange={onChange}
      >
        {JSON.parse(localStorage.getItem("user")).company === "ladecora" ? (
          <>
            <option value="all">Todos</option>
            <option value="wl">White Label</option>
          </>
        ) : (
          ""
        )}

        {metadata.companies.map((company, idx) => (
          <option key={`${company.label}_${idx}`} value={company.label}>
            {company.name}
          </option>
        ))}
      </FormSelect>
    </div>
  );
};

export default CompanySelector;

import React, { useState } from "react";
import { updateChecklist } from "../../../actions/projects";
import AspectRatioPicture from "../../common/AspectRatioPicture";
import TaskCheckbox from "../../common/TaskCheckbox";
import { toast } from "react-toastify";
import Moment from "react-moment";
import Viewer from "../PDFPages/Viewer";
import SetLinkIntoString from "../../common/SetLinkIntoString";

const ListCheckList = ({
  checklist,
  setCheckList,
  projectId,
  type,
  onImageView,
}) => {
  const [link, setLink] = useState();

  const doneChecklist = (index) => {
    checklist[index].done = !checklist[index].done;
    setCheckList([...checklist]);
    updateChecklist(projectId, type, checklist)
      .then((data) => {
        toast.success(data.msg, {
          autoClose: 5000,
        });
      })
      .catch((error) => {
        setCheckList([...checklist]);
        console.log(error);
      });
  };

  const removeItem = (idx) => {
    checklist.splice(idx, 1);
    setCheckList([...checklist]);
    updateChecklist(projectId, type, checklist)
      .then(() => {
        toast.success("Item removido com sucesso!", {
          autoClose: 5000,
        });
      })
      .catch((error) => {
        setCheckList([...checklist]);
        console.log(error);
      });
  };

  return (
    <>
      <Viewer
        link={link}
        toggle={() => {
          if (link) setLink("");
        }}
      />

      {checklist.length == 0 && <h6>Nenhum item cadastrado</h6>}
      {checklist.length != 0 && (
        <div>
          {/* <div className="small-title text-center w-100 mb-1">Tarefas</div> */}
          {checklist.map((item, idx) => (
            <div className={"border-bottom pb-1 pt-1 d-flex mb-1"} key={idx}>
              {item.title ? (
                item.title
              ) : (
                <>
                  <div>
                    {" "}
                    <TaskCheckbox
                      checked={checklist[idx].done}
                      onDoneClick={() => {
                        doneChecklist(idx);
                      }}
                    />
                  </div>
                  <div className="ml-2" style={{ flex: 1 }}>
                    {item.description ? (
                      <SetLinkIntoString string={item.description} />
                    ) : null}
                    {item.file && item.description && (
                      <hr className="w-100 m-0 mb-2 mt-1" />
                    )}
                    {item.file ? (
                      <div className="d-flex align-items-start flex-column">
                        <AspectRatioPicture
                          height="100px"
                          width="100px"
                          src={item.file.link}
                          thumbnail={item.file.thumbnail}
                          alt={item.file.name}
                          isLoading={false}
                          isUploading={false}
                          isDeletable={false}
                          onImageView={onImageView}
                          viewPdf={() => {
                            setLink(item.file.link);
                          }}
                        />
                      </div>
                    ) : null}

                    {item.date ? (
                      <span
                        style={{ fontStyle: "italic", fontSize: "0.74rem" }}
                        className="float-right"
                      >
                        <Moment format="DD/MM/YYYY HH:mm">{item.date}</Moment>
                      </span>
                    ) : null}
                  </div>
                  <div className="d-flex justify-content-center align-items-start">
                    <button
                      type="button"
                      className="white-button clickable"
                      onClick={() => removeItem(idx)}
                    >
                      <span className="material-icons" style={{ color: "red" }}>
                        delete
                      </span>
                    </button>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default ListCheckList;

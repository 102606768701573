import React from "react";
import { Redirect } from "react-router-dom";

// Route Views
import Mobi from "./views/Mobi";
import Login from "./views/Login";
import ProjectTemplates from "./views/ProjectTemplates";
import ForgotPassword from "./views/ForgotPassword";
import ChangePassword from "./views/ChangePassword";
import WithoutSidebarLayout from "./layouts/WithoutSidebar";
import ProjetcsList from "./views/ProjectList";
import Project from "./views/Project";
import Users from "./views/Users";
import UsersOld from "./views/UsersOld";
import User from "./views/User";
import Vouchers from "./views/Vouchers";
import Enterprises from "./views/Enterprises";
import EnterpriseQuestions from "./views/EnterpriseQuestions";
import Enterprise from "./views/Enterprise";
import EnterpriseInfo from "./views/EnterpriseInfo";
import FurnitureGroups from "./views/FurnitureGroups";
import NewFurnitureGroup from "./views/NewFurnitureGroup";
import EditFurnitureGroup from "./views/EditFurnitureGroup";
import ProjectTemplate from "./views/ProjectTemplate";
import ProjectMobi from "./views/ProjectMobi";
import MobiTags from "./views/MobiTags";
import Partner from "./views/Partner";
import Partners from "./views/Partners";
import Stats from "./views/Stats";
import PDFConfig from "./views/PDFConfig";
import Error404 from "./components/common/404.js";
import MobiTagTinder from "./views/MobiTagTinder";
import ImageEditor from "./components/ImageEditor/index";
import Concierge from "./views/Concierge";

const publicRoutes = [
  {
    path: "/login",
    layout: WithoutSidebarLayout,
    component: Login,
  },
  {
    path: "/forgot-password",
    layout: WithoutSidebarLayout,
    component: ForgotPassword,
  },
  {
    path: "/change-password",
    layout: WithoutSidebarLayout,
    component: ChangePassword,
  },
  {
    path: "/404",
    layout: WithoutSidebarLayout,
    component: Error404,
  },
  {
    path: "/mobi_tag_tinder/:id",
    component: MobiTagTinder,
    layout: WithoutSidebarLayout,
  },
];
const privateRoutes = [
  {
    path: "/vouchers",
    component: Vouchers,
  },
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/projects" />,
  },
  {
    path: "/mobi",
    component: Mobi,
    exact: true,
  },
  {
    path: "/users",
    component: UsersOld,
  },
  {
    path: "/user",
    component: User,
  },
  {
    path: "/project-template",
    component: ProjectTemplate,
  },
  {
    path: "/list",
    component: ProjectTemplates,
    permission: {
      resource: "admin-ladecora:questions",
      perform: "read:any",
    },
  },
  {
    path: "/projects",
    component: ProjetcsList,
  },
  {
    path: "/enterprise-projects",
    component: ProjetcsList,
  },
  {
    path: "/project",
    component: Project,
    exact: true,
  },

  {
    path: "/furniture-groups",
    component: FurnitureGroups,
    exact: true,
  },
  {
    path: "/furniture-groups/new",
    exact: true,
    component: NewFurnitureGroup,
  },
  {
    path: "/furniture-groups-edit",
    exact: true,
    component: EditFurnitureGroup,
  },
  {
    path: "/mobi/tags",
    exact: true,
    component: MobiTags,
  },
  {
    path: "/project/:id/mobi",
    component: ProjectMobi,
    exact: true,
  },
  {
    path: "/project-byuser/:idUser/:userNameUrl",
    component: ProjetcsList,
    exact: true,
  },
  { path: "/partner", exact: true, component: Partner },
  { path: "/partner/:id", exact: false, component: Partner },

  { path: "/project", exact: true, component: Project },
  { path: "/project/:id", exact: false, component: Project },

  { path: "/partners", exact: true, component: Partners },
  { path: "/stats", exact: true, component: Stats },

  { path: "/enterprises", exact: true, component: Enterprises },
  { path: "/enterprise", exact: true, component: Enterprise },
  {
    path: "/enterprise-questions/:id",
    exact: true,
    component: EnterpriseQuestions,
  },
  {
    path: "/interprise-info/:enterpriseId",
    exact: true,
    component: EnterpriseInfo,
  },
  { path: "/pdf_config", exact: true, component: PDFConfig },
  {
    path: "/image-editor",
    component: ImageEditor,
  },
  { path: "/csv_concierge", exact: true, component: Concierge },
];

export { privateRoutes, publicRoutes };

/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  FormInput,
  Button,
} from "shards-react";
import {
  signin,
  isAuth,
  authenticate,
  getQueryStringValue,
} from "../actions/auth";
import Loader from "react-loader-spinner";

function Login() {
  const [values, setValues] = useState({
    email: "",
    password: "",
    loading: false,
    error: false,
  });
  const redirectUri = getQueryStringValue("redirectUri");

  const { email, password, loading, error } = values;

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setValues({ ...values, loading: true });
    const user = { email, password };

    signin(user).then((data) => {
      if (data.error) {
        setValues({ ...values, error: data.error, loading: false });
      } else {
        authenticate(data, () => {
          if (isAuth()) {
            if (redirectUri) {
              window.open(redirectUri, "_self");
            } else {
              if (data?.user.role === "just_mobi") {
                window.open(`/mobi`, "_self");
              } else {
                window.open(`/list`, "_self");
              }
            }
          }
        });
      }
    });
  };

  const handleChange = (name) => (e) => {
    setValues({ ...values, error: false, [name]: e.target.value });
  };

  const showError = () =>
    error ? <div className="alert alert-danger">{error}</div> : "";

  return (
    <Container fluid className="main-content-container h-100 px-4">
      <Row noGutters className="h-100">
        <Col lg="3" md="5" className="auth-form mx-auto my-auto">
          <Card>
            <CardBody>
              {/* Logo */}
              <img
                className="auth-form__logo d-table mx-auto mb-3"
                style={{
                  padding: "30px",
                  paddingBottom: "20px",
                  maxWidth: "215px",
                }}
                src={require("../images/La_Decora_principal.jpeg")}
                alt="La Decora - Login Template"
              />

              {/* Title */}
              <h5 className="auth-form__title text-center mb-4">
                Acesse sua conta
              </h5>

              {/* Form Fields */}
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <label htmlFor="exampleInputEmail1">Email</label>
                  <FormInput
                    type="email"
                    placeholder="Informe seu email"
                    autoComplete="email"
                    id="login-username"
                    value={email}
                    onChange={handleChange("email")}
                  />
                </FormGroup>
                <FormGroup>
                  <label htmlFor="exampleInputPassword1">Senha</label>
                  <FormInput
                    type="password"
                    id="login-password"
                    placeholder="Senha"
                    autoComplete="current-password"
                    value={password}
                    onChange={handleChange("password")}
                  />
                </FormGroup>
                {showError()}
                <Button
                  pill
                  theme="accent"
                  id="login-button"
                  className="d-table mx-auto"
                  type="submit"
                >
                  {loading && (
                    <Loader
                      width="30px"
                      height="15px"
                      type="Rings"
                      color="#ffffff"
                    />
                  )}
                  {!loading && "Acessar conta"}
                </Button>
              </Form>
            </CardBody>
          </Card>

          {/* Meta Details */}
          <div className="auth-form__meta d-flex mt-4">
            <Link to="/forgot-password">Esqueceu sua senha?</Link>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;

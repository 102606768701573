import React, { useState, useEffect } from "react";
import { Button } from "shards-react";
import Loader from "react-loader-spinner";

const ProjectMobiSelector = ({ item, onChange, disabled, onSelectUnique }) => {
  const [counter, setCounter] = useState(item.count);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setCounter(item.count);
  }, [item.selected]);

  function onLocalChange(value: number) {
    if (value >= 0) {
      setCounter(value);
    }
  }

  useEffect(() => {
    setCounter(item.count);
  }, [item.count]);

  function onSaveClick() {
    setIsLoading(true);
    onChange(item, counter)
      .then(() => {
        setIsLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  }
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      {(counter === 0 && item.count === 0 && !item.selected) ||
      (item.count > 0 && counter === item.count) ? (
        <Button
          title="Selecionar por metragem"
          className="ml-1"
          theme="white"
          disabled={isLoading}
          onClick={() => {
            setIsLoading(true);
            onSelectUnique(item, !item.selected)
              .then(() => {
                setIsLoading(false);
              })
              .catch(() => setIsLoading(false));
          }}
        >
          Conf. Metragem
        </Button>
      ) : null}
      {item.selected && !counter ? (
        <Button
          title="Selecionar por metragem"
          className="ml-1"
          theme="danger"
          disabled={isLoading}
          onClick={() => {
            setIsLoading(true);
            onSelectUnique(item, false)
              .then((res) => {
                res && setIsLoading(false);
              })
              .catch(() => setIsLoading(false));
          }}
        >
          Remover
        </Button>
      ) : null}
      {item.count !== counter && (
        <div>
          <Button theme="success" disabled={isLoading} onClick={onSaveClick}>
            {isLoading ? (
              <Loader width="30px" height="15px" type="Rings" color="#ffffff" />
            ) : (
              "Salvar"
            )}
          </Button>
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "1rem",
        }}
      >
        <Button
          onClick={() => {
            onLocalChange(counter - 1);
          }}
          disabled={disabled || isLoading}
          style={{
            color: "black",
            border: "solid 1px",
            padding: "0px",
            height: "20px",
            width: "20px",
            borderRadius: "30px",
            fontSize: "15px",
            backgroundColor: "white",
            paddingBottom: "2px",
          }}
        >
          <span>-</span>
        </Button>
        <input
          style={{
            width: "40px",
            marginLeft: "4px",
            marginRight: "4px",
            paddingLeft: "8px",
            textAlign: "center",
            color: counter > 0 ? "black" : "gray",
          }}
          type="tel"
          value={counter}
          disabled={true}
        />
        <Button
          disabled={disabled || isLoading}
          onClick={() => {
            onLocalChange(counter + 1);
          }}
          style={{
            color: "black",
            border: "solid 1px",
            padding: "0px",
            height: "20px",
            width: "20px",
            borderRadius: "30px",
            fontSize: "15px",
            backgroundColor: "white",
            paddingBottom: "2px",
          }}
        >
          <span>+</span>
        </Button>
      </div>
    </div>
  );
};

export default ProjectMobiSelector;

export default function () {
  return [
    {
      title: "Workspace",
      role: 1,
      items: [
        {
          title: "Formulários",
          to: "/list",
          htmlBefore: '<i class="material-icons">description</i>',
          htmlAfter: "",
          permission: "admin-ladecora:questions",
        },

        {
          title: "Projetos",
          to: "/projects",
          htmlBefore: '<i class="material-icons">perm_media</i>',
          htmlAfter: "",
          permission: "admin-ladecora:project",
        },
        {
          title: "Usuarios",
          to: "/users",
          htmlBefore: '<i class="material-icons">person</i>',
          htmlAfter: "",
          id: "menu-users",
          permission: "admin-ladecora:project",
        },
        {
          title: "Vouchers",
          to: "/vouchers",
          htmlBefore: '<i class="material-icons">confirmation_number</i>',
          htmlAfter: "",
          permission: "admin-ladecora:vouchers",
        },
        {
          title: "Configuração WL",
          to: "/partners",
          htmlBefore: '<i class="material-icons">people</i>',
          htmlAfter: "",
          permission: "admin-ladecora:partners",
        },
        {
          title: "Estatísticas",
          to: "/stats",
          htmlBefore: '<i class="material-icons">analytics</i>',
          htmlAfter: "",
          permission: "admin-ladecora:project",
        },
        {
          title: "Banco de projetos",
          to: "/enterprises",
          htmlBefore: '<i class="material-icons">business</i>',
          htmlAfter: "",
          permission: "admin-ladecora:enterprises",
        },
      ],
    },
    {
      title: "Mobi",
      role: 1,
      items: [
        {
          title: "Produtos",
          to: "/mobi",
          htmlBefore: '<i class="material-icons">&#xe011;</i>',
          htmlAfter: "",
          permission: "admin-ladecora:mobi",
        },
      ],
    },
    {
      title: "Concierge",
      role: 1,
      items: [
        {
          title: "CSV",
          to: "/csv_concierge",
          htmlBefore: '<i class="material-icons">table_view</i>',
          htmlAfter: "",
          permission: "admin-ladecora:pdf-config",
        },
      ],
    },
    {
      title: "Book",
      role: 1,
      items: [
        {
          title: "Configuração do PDF",
          to: "/pdf_config",
          htmlBefore: '<i class="material-icons">picture_as_pdf</i>',
          htmlAfter: "",
          permission: "admin-ladecora:pdf-config",
        },
      ],
    },

    // {
    //   title: "Configurações",
    //   role: 1,
    //   items: [
    //     {
    //       title: "Sistema do cliente",
    //       to: "/whitelabel_config",
    //       htmlBefore: '<i class="material-icons">dashboard</i>',
    //       htmlAfter: "",
    //     },
    //   ],
    // },
    {
      role: 1,
      items: [
        {
          title: "Sair",
          to: "",
          htmlBefore:
            '<a class="text-danger"><i class="material-icons text-danger">&#xE879;</i></a>',
          htmlAfter: "",
        },
      ],
    },
  ];
}

import React from "react";
import { Route } from "react-router-dom";
import withTracker from "../../withTracker";

const PublicRoutes = ({ routes }) => (
  <>
    {routes.map((route, index) => {
      return (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          component={withTracker((props) => {
            return (
              <route.layout {...props}>
                <route.component {...props} />
              </route.layout>
            );
          })}
        />
      );
    })}
  </>
);

export default PublicRoutes;

import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  FormInput,
  Button,
  FormCheckbox,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  FormSelect,
} from "shards-react";
import Loader from "react-loader-spinner";
import {
  createProduct,
  updateProduct,
  deleteBlockFile,
  uploadPicture,
  uploadFile as uploadFileMobi,
  checkSkuEndNameMobi,
} from "../../actions/mobi";
import { getTagsV2 } from "../../actions/mobiTag";
import { toast } from "react-toastify";
import Label from "../Label/index";
import ConfirmationButton from "../common/ConfirmationButton";
import { uploadFile } from "../../actions/file";
import { getFileExtension } from "../../utils/util";

import DropzoneFiles from "../common/DropzoneFiles";
import MobiTagManager from "./MobiTagManager";
import SelectSearch from "react-select-search";
import { Mobi } from "../../data/mobi.ts";
import ReactTagsV2 from "../ReactTags/ReactTags";
import IfAllowed from "../common/IfAllowed";
import Autocomplete from "react-autocomplete";
import { debounce } from "../../utils/throttle";

const ProductV2 = (props) => {
  let { product, suppliers } = props;
  const [form, setForm] = useState({
    categories: [],
    subCategories: [],
  });

  const [suggestions, _setSuggestions] = useState({
    suggestions: [],
    categories: [],
    styles: [],
    colorTones: [],
    features: [],
    colors: [],
    typologies: [],
  });
  const setSuggestions = useCallback((suggestions) => {
    const attrs = [
      "suggestions",
      "categories",
      "styles",
      "colorTones",
      "features",
      "colors",
      "typologies",
    ];
    attrs.forEach((attr) => {
      if (suggestions[attr]) {
        suggestions[attr] = suggestions[attr].map(fix);
      }
    });

    _setSuggestions(suggestions);

    function fix(item) {
      item.id = item._id;
      return item;
    }
  });
  const [mobi, _setMobi] = useState();
  const setMobi = (data) => {
    if (data instanceof Mobi) {
      _setMobi(data);
    } else {
      if (data && !data.oldTags) {
        data.oldTags = {
          styles: data.styles,
          colors: data.colors,
          categories: data.categories,
          typologies: data.typologies,
        };
      }
      data = new Mobi(data);
      _setMobi(data);
    }
    return data;
  };
  const [otherFiles, setOtherFiles] = useState([]);

  const [feedback, setFeedback] = useState({
    isLoading: false,
    message: "",
    error: "",
  });
  const [isPictureUploading, setIsPictureUploading] = useState(false);
  const [warningSkuAndName, setWarningSkuAndName] = useState({
    sku: "",
    name: "",
  });

  const fileInput = useRef(null);

  useEffect(() => {
    if (product) {
      const mobi = setMobi(product);
      if (mobi && mobi.otherFiles) {
        setOtherFiles(mobi.otherFiles);
      }
      const promises = [];
      if (product.typology) {
        promises.push(
          getTagsV2("", product.typology.category, product.typology._id).then(
            (res) => {
              return { categories: res };
            }
          )
        );
      }
      if (product.category) {
        promises.push(
          getTagsV2("", product.category.category, product.category._id).then(
            (res) => {
              return { subCategories: res };
            }
          )
        );
      }

      Promise.all(promises).then((arg) => {
        const newForm = arg.reduce((acc, cur) => {
          return { ...acc, ...cur };
        }, {});
        setForm({ ...form, ...newForm });
      });
    } else {
      setMobi();
    }
  }, [product]);

  useEffect(() => {
    if (props.styles && props.colorTones && props.features && props.colors) {
      setSuggestions({
        styles: props.styles,
        colorTones: props.colorTones,
        features: props.features,
        colors: props.colors,
        typologies: props.typologies,
        projectTemplateNames: props.projectTemplateNames,
      });
    }
  }, [props.styles]);

  function handleChange(name) {
    return (e) => {
      if (name === "notUse") {
        setMobi({
          ...mobi,
          [name]: !mobi.notUse,
        });
      } else if (name === "active") {
        setMobi({
          ...mobi,
          [name]: !mobi.active,
        });
      } else if (name == "sku" || name == "name") {
        setWarningSkuAndName({ ...warningSkuAndName, [name]: "" });

        setMobi({ ...mobi, [name]: e.target.value });
        onSkuEndNameUpdate(name, e.target.value, mobi.supplier);
      } else {
        setMobi({ ...mobi, [name]: e.target.value });
      }
    };
  }

  const setWarningSkuAndNameData = (name, res) => {
    if (name == "name") {
      if (res.name) {
        setWarningSkuAndName((prevStatus) => ({
          ...prevStatus,
          name: "Já existe um produto com esse nome",
        }));
      }
    } else if (name == "sku") {
      if (res.sku) {
        setWarningSkuAndName((prevStatus) => ({
          ...prevStatus,
          sku: "Já existe um produto com esse SKU",
        }));
      }
    }
  };

  const checkSkuEndName = (name, text, supplier) => {
    setFeedback({ ...feedback, isLoading: true });

    if (name === "supplier") {
      if (mobi?.name) {
        setWarningSkuAndName((prevStatus) => ({
          ...prevStatus,
          name: "",
        }));

        checkSkuEndNameMobi({
          id: mobi?._id,
          name: "name",
          text: mobi.name,
          supplier: text,
        }).then((res) => {
          setWarningSkuAndNameData("name", res);
        });
      }
      if (mobi?.sku) {
        setWarningSkuAndName((prevStatus) => ({
          ...prevStatus,
          sku: "",
        }));
        checkSkuEndNameMobi({
          id: mobi?._id,
          name: "sku",
          text: mobi.sku,
          supplier: text,
        }).then((res) => {
          setWarningSkuAndNameData("sku", res);
        });
      }
    } else {
      checkSkuEndNameMobi({ id: mobi?._id, name, text, supplier }).then(
        (res) => {
          setWarningSkuAndNameData(name, res);
        }
      );
    }

    setFeedback({ ...feedback, isLoading: false });
  };

  const onSkuEndNameUpdate = useCallback(
    debounce((name, text, supplier) => {
      checkSkuEndName(name, text, supplier);
    }, 800),
    [mobi]
  );

  function showError() {
    return feedback.error ? (
      <div className="alert alert-danger">{feedback.error}</div>
    ) : (
      ""
    );
  }

  function showMessage() {
    return feedback.message ? (
      <div className="alert alert-info">{feedback.message}</div>
    ) : (
      ""
    );
  }

  const showSkuEndNameMessage = (data) => {
    return data ? <div className="alert alert-info">{data}</div> : "";
  };

  function onFileSelect(ev) {
    setIsPictureUploading(true);
    if (getFileExtension(ev.target.files[0].name) === "webp") {
      setIsPictureUploading(false);
      return toast.error("Não é permitido enviar arquivo com extensão .webp", {
        autoClose: 5000,
      });
    }

    uploadPicture(ev.target.files[0], mobi._id)
      .then((data) => {
        toast.success("Upload feito com sucesso", {
          autoClose: 5000,
        });
        setMobi({ ...mobi, refimage: data.link });
        setFeedback({ ...feedback, isLoading: false });

        props.onUpdate({
          ...mobi,
          refimage: data.link,
        });
      })
      .catch(() => {
        toast.error("Ocorreu um problemas", {
          autoClose: 5000,
        });
      })
      .finally(() => {
        setIsPictureUploading(false);
      });
  }

  function onProjectTemplateNameDelete(index) {
    mobi.projectTemplateNames = mobi.projectTemplateNames.filter(
      (typology, i) => i !== index
    );
    setMobi({ ...mobi });
  }

  function removeDuplicates(arr) {
    const s = new Set(arr);
    const it = s.values();
    return Array.from(it);
  }

  function uploadFileS3(e) {
    uploadFileMobi(e.target.files[0], mobi._id)
      .then((data) => {
        toast.success("Upload feito com sucesso", {
          autoClose: 5000,
        });
        setMobi({ ...mobi, file: data.file });
        props.onUpdate({ ...mobi, file: data.file });
      })
      .catch(() => {
        toast.error("Ocorreu um problemas", {
          autoClose: 5000,
        });
      });
  }

  function onDuplicateClick() {
    let duplicatedProduct = JSON.parse(JSON.stringify(product));
    duplicatedProduct = {
      ...duplicatedProduct,
      typology: mobi.typology,
      category: mobi.category,
      subCategory: mobi.subCategory,
    };

    delete duplicatedProduct._id;
    delete duplicatedProduct.file;
    duplicatedProduct.sku = "";
    delete duplicatedProduct.frequency_used;
    delete duplicatedProduct.likes;
    duplicatedProduct.name = `${duplicatedProduct.name} - Duplicado`;
    setFeedback({ ...feedback, isLoading: true });
    onSaveClick(duplicatedProduct).then((projectResponse) => {
      setMobi({
        ...duplicatedProduct,
        name: duplicatedProduct.name,
        _id: projectResponse.id,
      });
    });
  }

  function handleDeleteBlock() {
    deleteBlockFile(mobi._id).then((result) => {
      setMobi({ ...mobi, file: "" });
      props.onMobiUpdate({ ...mobi, file: "" });
      toast.success(result.msg, { autoClose: 5000 });
    });
  }

  function onTypologyChange(value) {
    const tag = suggestions.typologies.find((tag) => tag._id === value);
    setMobi({ ...mobi, typology: tag, category: null, subCategory: null });
    getTagsV2("", tag.category, tag._id).then((res) => {
      setForm({ ...form, categories: res });
    });
  }

  function onCategoryChange(value) {
    const tag = form.categories.find((tag) => tag._id === value);
    setMobi({ ...mobi, category: tag, subCategory: null });
    getTagsV2("", tag.category, tag._id).then((res) => {
      setForm({ ...form, subCategories: res });
    });
  }

  function onCreate(data) {
    return createProduct(data).then((res) => {
      setMobi({ ...mobi, _id: res.id });
      props.refreshTableData({ ...mobi, _id: res.id });
      return res;
    });
  }

  function onUpdate(data) {
    return updateProduct(data._id, data).then((res) => {
      props.onUpdate(data);
      return res;
    });
  }

  function onSaveClick(data) {
    data = {
      ...data,
      typology: data.typology._id ? data.typology : null,
      category: data.category._id ? data.category : null,
      subCategory: data.subCategory._id ? data.subCategory : null,
    };
    setFeedback({ ...feedback, isLoading: true });
    const promise = (data._id ? onUpdate : onCreate)(data);

    return promise.then((projectResponse) => {
      if (projectResponse.error) {
        setFeedback({
          ...feedback,
          isLoading: false,
          error: projectResponse.error,
        });
      } else {
        setFeedback({
          ...feedback,
          message: projectResponse.msg,
          isLoading: false,
        });
        toast.success("Mobi salvo com sucesso", 2000);
      }
      return projectResponse;
    });
  }

  function onDetailsChange(files) {
    setFeedback({ ...feedback, isLoading: true });

    const filesToUpload = files.filter((item) => item.file);
    if (filesToUpload.length) {
      setOtherFiles(otherFiles.concat(filesToUpload));
      Promise.all(
        filesToUpload.map((acceptedFile) => {
          return uploadFile(
            acceptedFile.file,
            `mobi/${mobi._id}/other`,
            acceptedFile.kind
          );
        })
      ).then((files) => {
        const newList = mobi.otherFiles.concat(files);
        setOtherFiles(newList);
        setMobi({
          ...mobi,
          otherFiles: newList,
        });
        onSaveClick({
          ...mobi,
          otherFiles: newList,
        });
      });
    } else {
      const newMobi = { ...mobi, otherFiles: files };
      setOtherFiles(files);
      setMobi(newMobi);
      onSaveClick(newMobi);
    }
  }

  function onTagSuggestionsChange(action, value, parent) {
    const depth = parent ? parent.length : 0;
    const actions = {
      add: () => {
        switch (depth) {
          case 0: {
            setSuggestions({
              ...suggestions,
              typologies: [...suggestions.typologies, value],
            });
            break;
          }
          case 1: {
            if (mobi.typology._id === parent[0]._id) {
              setForm({
                ...form,
                categories: [...form.categories, value],
              });
            }
            break;
          }
          case 2: {
            if (mobi.category._id === parent[1]._id) {
              setForm({
                ...form,
                subCategories: [...form.subCategories, value],
              });
            }
            break;
          }
        }
      },
      delete: () => {},
    };
    const fn = actions[action];
    fn();
  }

  function onTagsValidate(tag) {
    return tag.name;
  }

  return mobi ? (
    <Modal
      className="card-mobi"
      modalContentClassName="card"
      open={true}
      toggle={props.toggle}
    >
      <ModalHeader toggle={props.toggle}>Detalhes do produto</ModalHeader>
      <ModalBody
        style={{
          overflowY: "scroll",
        }}
      >
        <Row>
          <Col>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                return false;
              }}
            >
              <Row>
                <Col>
                  {mobi._id ? (
                    <ConfirmationButton
                      theme="primary"
                      disabled={feedback.isLoading}
                      className="ml-auto float-right"
                      onClick={onDuplicateClick}
                    >
                      Duplicar
                    </ConfirmationButton>
                  ) : null}
                </Col>
              </Row>
              <Row form>
                <Col md="12" className="form-group">
                  {mobi._id ? (
                    <div style={{ maxWidth: "180px" }}>
                      <Card
                        style={{ maxWidth: "unset" }}
                        className="w-100 shadow-lg card-produt-img edit-user-details__avatar"
                      >
                        <img
                          className="shadow-sm img-fluid"
                          src={
                            mobi.refimage
                              ? `${mobi.refimage}`
                              : require("../../images/no_mobi.png")
                          }
                          alt=""
                          style={{
                            borderRadius: "12px",
                            aspectRatio: "1/1",
                            objectFit: "cover",
                          }}
                        />
                        <label className="edit-user-details__avatar__change">
                          <i className="material-icons mr-1">&#xE439;</i>
                          <input
                            type="file"
                            className="d-none"
                            onChange={onFileSelect}
                          />
                        </label>
                      </Card>
                      <div>
                        <input
                          type="file"
                          ref={fileInput}
                          className="d-none"
                          onChange={onFileSelect}
                        />
                        <Button
                          size="sm"
                          theme="primary"
                          disabled={isPictureUploading}
                          onClick={() =>
                            fileInput.current && fileInput.current.click()
                          }
                          className="mx-auto mt-4 d-block"
                        >
                          <i className="material-icons">&#xE2C3;</i>{" "}
                          {isPictureUploading ? "Enviando..." : "Enviar foto"}
                        </Button>
                      </div>
                    </div>
                  ) : null}
                </Col>
              </Row>
              <Row form>
                <Col md="4" className="form-group">
                  <FormGroup>
                    <div className="label">Fornecedor</div>
                    <Autocomplete
                      getItemValue={(item) => item.label}
                      wrapperProps={{
                        className: "w-100",
                      }}
                      items={removeDuplicates(
                        suppliers.map((s) => s && s.trim())
                      ).map((supplier) => {
                        return {
                          label: supplier,
                          id: supplier,
                        };
                      })}
                      inputProps={{
                        className: "form-control W-100",
                      }}
                      shouldItemRender={(item, value) =>
                        item.label &&
                        item.label.toLowerCase().indexOf(value.toLowerCase()) >
                          -1
                      }
                      renderItem={(item, isHighlighted) => (
                        <div
                          style={{
                            background: isHighlighted ? "lightgray" : "white",
                          }}
                        >
                          {item.label}
                        </div>
                      )}
                      value={mobi.supplier}
                      menuStyle={{
                        zIndex: 3000,
                        borderRadius: "3px",
                        boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
                        background: "rgba(255, 255, 255, 0.9)",
                        padding: "2px 0",
                        fontSize: "90%",
                        position: "fixed",
                        overflow: "auto",
                        maxHeight: "50%",
                      }}
                      onChange={(e) => {
                        setMobi({ ...mobi, supplier: e.target.value });
                      }}
                      onSelect={(val) => {
                        setMobi({ ...mobi, supplier: val });
                        onSkuEndNameUpdate("supplier", val, val);
                      }}
                      renderMenu={(children) => (
                        <div className="menu">{children}</div>
                      )}
                    />
                  </FormGroup>
                </Col>

                <Col md="4" className="form-group">
                  <FormGroup>
                    <div className="label">SKU</div>
                    <FormInput
                      type="text"
                      placeholder="SKU"
                      value={mobi.sku}
                      onChange={handleChange("sku")}
                    />
                  </FormGroup>
                  {showSkuEndNameMessage(warningSkuAndName["sku"])}
                </Col>
                <Col md="4" className="form-group">
                  <FormGroup>
                    <div className="label">Nome</div>
                    <FormInput
                      type="text"
                      required
                      placeholder="Nome do produto"
                      value={mobi.name}
                      onChange={handleChange("name")}
                    />
                  </FormGroup>
                  {showSkuEndNameMessage(warningSkuAndName["name"])}
                </Col>
              </Row>
              <Row form>
                <Col md="6" className="form-group">
                  <FormGroup>
                    <div className="label">Link</div>
                    <FormInput
                      type="text"
                      placeholder="Link para o site"
                      value={mobi.link}
                      onChange={handleChange("link")}
                    />
                  </FormGroup>
                </Col>
                <Col md="6" className="form-group">
                  <FormGroup>
                    <div className="label">Link Afiliado</div>
                    <FormInput
                      type="text"
                      placeholder="Link para o site"
                      value={mobi.affiliateLink}
                      onChange={handleChange("affiliateLink")}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md="2" className="form-group">
                  <FormGroup>
                    <div className="label">Preço (R$0.00)</div>
                    <FormInput
                      type="tel"
                      placeholder="Valor"
                      value={mobi.value}
                      onKeyDown={(ev) => {
                        if (
                          ev.key.length === 1 &&
                          !ev.key.match(/^([0-9]|\.)*$/)
                        ) {
                          ev.preventDefault();
                          ev.stopPropagation();
                        }
                      }}
                      onChange={handleChange("value")}
                    />
                  </FormGroup>
                </Col>
                <Col md="2" className="form-group">
                  <FormGroup>
                    <div className="label">Unidade de Medida</div>
                    <FormSelect
                      size="sm"
                      value={mobi.unitOfMeasurement}
                      onChange={handleChange("unitOfMeasurement")}
                    >
                      <option value="und.">und.</option>
                      <option value="m²">m²</option>
                      <option value="Metro linear">Metro linear</option>
                    </FormSelect>
                  </FormGroup>
                </Col>
                <Col md="2" className="form-group">
                  <FormGroup>
                    <div className="label">Estoque</div>
                    <FormInput
                      placeholder="Informe o estoque"
                      value={mobi.inventory}
                      onChange={handleChange("inventory")}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h5>
                    Configuração geral de altura, largura e profundidade do
                    produto
                  </h5>
                </Col>
              </Row>
              <Row form>
                <Col md="4" className="form-group">
                  <FormGroup>
                    <div className="label">Ref L</div>
                    <FormInput
                      placeholder="Informe o ref l"
                      value={mobi.refx}
                      onChange={handleChange("refx")}
                    />
                  </FormGroup>
                </Col>
                <Col md="4" className="form-group">
                  <FormGroup>
                    <div className="label">Ref P</div>
                    <FormInput
                      placeholder="Informe ref p"
                      value={mobi.refy}
                      onChange={handleChange("refy")}
                    />
                  </FormGroup>
                </Col>
                <Col md="4" className="form-group">
                  <FormGroup>
                    <div className="label">Ref A</div>
                    <FormInput
                      placeholder="Informe o ref a"
                      value={mobi.refz}
                      onChange={handleChange("refz")}
                    />
                  </FormGroup>
                </Col>
              </Row>{" "}
              <Row>
                <Col md="4" className="form-group">
                  <FormGroup>
                    <div className="label">Não Usar</div>
                    <FormCheckbox
                      toggle
                      small
                      checked={mobi.notUse}
                      onChange={handleChange("notUse")}
                    />
                  </FormGroup>
                </Col>
                <Col md="4" className="form-group">
                  <FormGroup>
                    <div className="label">Ativo</div>
                    <FormCheckbox
                      toggle
                      small
                      checked={mobi.active}
                      onChange={handleChange("active")}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {mobi._id ? (
                <Row form>
                  <Col md="4" className="form-group">
                    <div className="label">Bloco</div>
                    <div>
                      <div className="d-flex justify-content-around align-items-center mb-3">
                        {mobi.file ? mobi.file : "Nenhum arquivo enviado"}
                        {mobi.file && (
                          <ConfirmationButton
                            title="Remover"
                            theme="white"
                            onClick={handleDeleteBlock}
                          >
                            <i className="material-icons">&#xE872;</i>
                          </ConfirmationButton>
                        )}
                      </div>

                      <FormInput type="file" onChange={uploadFileS3} />
                    </div>
                  </Col>
                </Row>
              ) : null}
              <IfAllowed
                resource="admin-ladecora:mobi-tag"
                ifNot={() => {
                  return (
                    <>
                      <Row>
                        <Col md="12" className="form-group">
                          <FormGroup>
                            <div className="label">Estilos</div>
                            <ReactTagsV2
                              allowBackspace={false}
                              placeholderText="Digite aqui..."
                              tags={mobi.oldTags.styles.map((s) => {
                                return { id: s._id, name: s.name };
                              })}
                              suggestions={[]}
                              minQueryLength={0}
                              maxSuggestionsLength={1000}
                              onDelete={() => {}}
                              allowNew={false}
                              disabled={true}
                              allowDragDrop={false}
                              onAddition={() => {}}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12" className="form-group">
                          <FormGroup>
                            <div className="label" htmlFor="tags-input">
                              Tipologias
                            </div>
                            <ReactTagsV2
                              allowBackspace={false}
                              placeholderText="Digite aqui..."
                              tags={mobi.oldTags.typologies.map((s) => {
                                return { id: s._id, name: s.name };
                              })}
                              suggestions={[]}
                              minQueryLength={0}
                              maxSuggestionsLength={1000}
                              onDelete={() => {}}
                              allowNew={false}
                              disabled={true}
                              allowDragDrop={false}
                              onAddition={() => {}}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12" className="form-group">
                          <FormGroup>
                            <div className="label">Cores</div>
                            <ReactTagsV2
                              allowBackspace={false}
                              placeholderText="Digite aqui..."
                              tags={mobi.oldTags.colors.map((s) => {
                                return { id: s._id, name: s.name };
                              })}
                              suggestions={[]}
                              minQueryLength={0}
                              maxSuggestionsLength={1000}
                              onDelete={() => {}}
                              allowNew={false}
                              disabled={true}
                              allowDragDrop={false}
                              onAddition={() => {}}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </>
                  );
                }}
              >
                <Row form>
                  <Col md="12" className="form-group">
                    <FormGroup>
                      <div>
                        <div className="label">Tipologia</div>
                        <MobiTagManager
                          onChange={onTagSuggestionsChange}
                          category="typology"
                          allowNested={true}
                          refreshTags={props.refreshTags}
                        />
                      </div>
                      <SelectSearch
                        options={suggestions.typologies.map((typology) => {
                          return {
                            name: typology.name,
                            value: typology._id,
                          };
                        })}
                        search={true}
                        printOptions="on-focus"
                        closeOnSelect={true}
                        value={mobi.typology._id}
                        onChange={onTypologyChange}
                        placeholder="Selecione"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row form>
                  <Col md="12" className="form-group">
                    <FormGroup>
                      <div>
                        <div className="label">Categoria</div>
                        {mobi && mobi.typology && mobi.typology._id ? (
                          <MobiTagManager
                            onChange={onTagSuggestionsChange}
                            parent={[mobi.typology]}
                            category="typology"
                            allowNested={true}
                            refreshTags={props.refreshTags}
                          />
                        ) : null}
                      </div>
                      <SelectSearch
                        options={form.categories.map((category) => {
                          return {
                            name: category.name,
                            value: category._id,
                          };
                        })}
                        disabled={!mobi.typology._id}
                        search={true}
                        printOptions="on-focus"
                        closeOnSelect={true}
                        value={mobi.category._id}
                        onChange={onCategoryChange}
                        placeholder="Selecione"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row form>
                  <Col md="12" className="form-group">
                    <FormGroup>
                      <div>
                        <div className="label">Sub Categoria</div>
                        {mobi && mobi.category && mobi.category._id ? (
                          <MobiTagManager
                            onChange={onTagSuggestionsChange}
                            parent={[mobi.typology, mobi.category]}
                            category="typology"
                            allowNested={true}
                            refreshTags={props.refreshTags}
                          />
                        ) : null}
                      </div>
                      <SelectSearch
                        options={form.subCategories.map((category) => {
                          return {
                            name: category.name,
                            value: category._id,
                          };
                        })}
                        disabled={!mobi.category._id}
                        search={true}
                        printOptions="on-focus"
                        closeOnSelect={true}
                        value={mobi.subCategory._id}
                        onChange={(value) => {
                          const tag = form.subCategories.find(
                            (tag) => tag._id === value
                          );
                          setMobi({ ...mobi, subCategory: tag });
                        }}
                        placeholder="Selecione"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row form>
                  <Col md="12" className="form-group">
                    <FormGroup>
                      <div>
                        <div className="label">Estilo</div>
                        <MobiTagManager
                          onChange={props.refreshTags}
                          category="style"
                        />
                      </div>

                      <ReactTagsV2
                        allowBackspace={false}
                        placeholderText="Digite aqui..."
                        tags={mobi.styles.map((s) => {
                          return { id: s._id, name: s.name };
                        })}
                        suggestions={suggestions.styles}
                        minQueryLength={0}
                        maxSuggestionsLength={1000}
                        onDelete={(index) => {
                          const tagToBeDeleted = mobi.styles.find(
                            (style, i) => i === index
                          );
                          mobi.tags = mobi.tags.filter(
                            (tag) => tag._id !== tagToBeDeleted._id
                          );
                          setMobi({ ...mobi });
                        }}
                        allowNew={true}
                        allowDragDrop={false}
                        onValidate={onTagsValidate}
                        onAddition={(obj) => {
                          if (!mobi.styles.find((t) => t._id === obj.id)) {
                            const tag = suggestions.styles.find(
                              (s) => s._id === obj.id
                            );

                            mobi.tags.push(tag);
                            setMobi({ ...mobi });
                          }
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row form>
                  <Col md="12" className="form-group">
                    <FormGroup>
                      <div>
                        <div className="label">Cor</div>
                        <MobiTagManager
                          onChange={props.refreshTags}
                          category="color"
                        />
                      </div>

                      <ReactTagsV2
                        allowBackspace={false}
                        placeholderText="Digite aqui..."
                        tags={mobi.colors.map((s) => {
                          return { id: s._id, name: s.name };
                        })}
                        suggestions={suggestions.colors}
                        minQueryLength={0}
                        maxSuggestionsLength={1000}
                        onDelete={(index) => {
                          const tagToBeDeleted = mobi.colors.find(
                            (style, i) => i === index
                          );
                          mobi.tags = mobi.tags.filter(
                            (tag) => tag._id !== tagToBeDeleted._id
                          );
                          setMobi({ ...mobi });
                        }}
                        allowNew={true}
                        allowDragDrop={false}
                        onValidate={onTagsValidate}
                        onAddition={(obj) => {
                          if (!mobi.colors.find((t) => t._id === obj.id)) {
                            const tag = suggestions.colors.find(
                              (s) => s._id === obj.id
                            );

                            mobi.tags.push(tag);
                            setMobi({ ...mobi });
                          }
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row form>
                  <Col md="12" className="form-group">
                    <FormGroup>
                      <div>
                        <div className="label">Tom do ambiente</div>
                        <MobiTagManager
                          onChange={props.refreshTags}
                          category="color_tone"
                        />
                      </div>
                      <ReactTagsV2
                        allowBackspace={false}
                        placeholderText="Digite aqui..."
                        tags={mobi.colorTones.map((s) => {
                          return { id: s._id, name: s.name };
                        })}
                        suggestions={suggestions.colorTones}
                        minQueryLength={0}
                        maxSuggestionsLength={1000}
                        onDelete={(index) => {
                          const tagToBeDeleted = mobi.colorTones.find(
                            (style, i) => i === index
                          );
                          mobi.tags = mobi.tags.filter(
                            (tag) => tag._id !== tagToBeDeleted._id
                          );
                          setMobi({ ...mobi });
                        }}
                        allowNew={true}
                        allowDragDrop={false}
                        onValidate={onTagsValidate}
                        onAddition={(obj) => {
                          if (!mobi.colorTones.find((t) => t._id === obj.id)) {
                            const tag = suggestions.colorTones.find(
                              (s) => s._id === obj.id
                            );

                            mobi.tags.push(tag);
                            setMobi({ ...mobi });
                          }
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row form>
                  <Col md="12" className="form-group">
                    <FormGroup>
                      <div>
                        <div className="label">Característica</div>
                        <MobiTagManager
                          onChange={props.refreshTags}
                          category="feature"
                        />
                      </div>
                      <ReactTagsV2
                        allowBackspace={false}
                        placeholderText="Digite aqui..."
                        tags={mobi.features.map((s) => {
                          return { id: s._id, name: s.name };
                        })}
                        suggestions={suggestions.features}
                        minQueryLength={0}
                        maxSuggestionsLength={1000}
                        onDelete={(index) => {
                          const tagToBeDeleted = mobi.features.find(
                            (style, i) => i === index
                          );
                          mobi.tags = mobi.tags.filter(
                            (tag) => tag._id !== tagToBeDeleted._id
                          );
                          setMobi({ ...mobi });
                        }}
                        allowNew={true}
                        allowDragDrop={false}
                        onValidate={onTagsValidate}
                        onAddition={(obj) => {
                          if (!mobi.features.find((t) => t._id === obj.id)) {
                            const tag = suggestions.features.find(
                              (s) => s._id === obj.id
                            );

                            mobi.tags.push(tag);
                            setMobi({ ...mobi });
                          }
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </IfAllowed>
              <Row form>
                <Col md="12" className="form-group">
                  <FormGroup>
                    <Label
                      onAddLabel={(value) => {
                        setMobi({
                          ...mobi,
                          projectTemplateNames:
                            mobi.projectTemplateNames.concat([value.name]),
                        });
                      }}
                      name="Ambientes"
                      allowNew={false}
                      value={mobi.projectTemplateNames.map((name) => {
                        return {
                          id: name,
                          name: name,
                        };
                      })}
                      suggestions={suggestions.projectTemplateNames}
                      onDelete={onProjectTemplateNameDelete}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {mobi && mobi._id ? (
                <Row>
                  <Col xs="12">
                    <FormGroup>
                      <div className="label">Detalhamentos</div>
                      <DropzoneFiles
                        isMultiple={true}
                        items={otherFiles}
                        onImageView={props.onImageView}
                        onChange={onDetailsChange}
                        isLoading={feedback.isLoading}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              ) : null}
              {showError()}
              {showMessage()}
            </Form>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <div style={{ float: "right" }}>
          <Button onClick={() => onSaveClick(mobi)} theme="accent">
            {feedback.isLoading && (
              <Loader width="30px" height="15px" type="Rings" color="#ffffff" />
            )}
            {!feedback.isLoading && mobi._id && "Atualizar produto"}
            {!feedback.isLoading && !mobi._id && "Cadastrar produto"}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  ) : null;
};

ProductV2.defaultProps = {
  title: "Detalhes da produto",
};

export default ProductV2;

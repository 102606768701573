import { useRef, useEffect } from "react";

export function useAsyncReactStateFunction(functionToCall) {
  const savedFunction = useRef();

  useEffect(() => {
    savedFunction.current = functionToCall;
  }, [functionToCall]);

  return { listener: savedFunction };
}

import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Row,
  Card,
  Col,
  Button,
  ButtonGroup,
  CardBody,
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { listAllProjectFiltered } from "../actions/projects";
import { getQueryStringValue } from "../actions/auth";
import Filters from "../components/ProjectList/Filters";
import Moment from "react-moment";
import { translateStatus, limitDate } from "../services/project.js";
import { addingDayWork } from "@ladecora/ladecora-utils";

import { MetadataContext } from "../components/metadata/MetadataContext";

import "react-toastify/dist/ReactToastify.min.css";

import FuzzySearch from "fuzzy-search";
import { sanitizeString } from "../utils/sanitize";

import FilteredTable from "../components/FilteredTable/index";
import { useHistory, useParams } from "react-router-dom";

const ProjetcsList = () => {
  const { userNameUrl, idUser } = useParams();

  const history = useHistory();
  const [projects, setProjects] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [filterTypeStatus, setFilterTypeStatus] = useState(
    userNameUrl
      ? "all"
      : JSON.parse(localStorage.getItem("user")).company === "ladecora"
      ? "active"
      : "all"
  );

  const { metadata, company } = useContext(MetadataContext);
  const tableColumns = [
    {
      Header: "Nome",
      accessor: "name",
      className: "text-center",
      Cell: (row) => <span>{row.original.name}</span>,
    },
    {
      Header: "Ambiente",
      accessor: "name",
      className: "text-center",
      Cell: (row) => <span>{row.original.questionTemplate.name}</span>,
    },
    {
      Header: "Resp. Projeto",
      accessor: "owner",
      className: "text-center",
      Cell: (row) => (
        <span
          style={{
            display: "inlineBlock",
            wordBreak: "breakAll",
            whiteSpace: "normal",
          }}
        >
          {row.original.owner ? nameOwner(row.original, "owner") : "Nenhum"}
        </span>
      ),
    },
    {
      Header: "Resp. Revisão",
      accessor: "ownerReview",
      className: "text-center",
      Cell: (row) => (
        <span
          style={{
            display: "inlineBlock",
            wordBreak: "breakAll",
            whiteSpace: "normal",
          }}
        >
          {row.original.ownerReview
            ? nameOwner(row.original, "ownerReview")
            : "Nenhum"}
        </span>
      ),
    },
    {
      Header: "Usuário",
      accessor: "user",
      className: "text-center",
      Cell: (row) => (
        <button
          className="transparent"
          type="button"
          onClick={() => history.push(`/user?id=${row.original.user._id}`)}
        >
          {row.original.userName}
        </button>
      ),
    },
    {
      Header: "Status",
      accessor: "status",
      maxWidth: 160,
      className: "text-center",
      Cell: (row) => <span>{row.original.statusProd}</span>,
    },
    {
      Header: "Criado",
      accessor: "createdAt",
      maxWidth: 130,
      className: "text-center",
      Cell: (row) => {
        return (
          <span className="d-flex flex-column">
            <Moment format="DD/MM/YYYY">{row.original.createdAt}</Moment>
            <Moment format="HH:mm">{row.original.createdAt}</Moment>
          </span>
        );
      },
    },
    {
      Header: "Último Update",
      accessor: "updatedAt",
      maxWidth: 130,
      className: "text-center",
      Cell: (row) => {
        return (
          <span className="d-flex flex-column">
            <Moment format="DD/MM/YYYY">{row.original.updatedAt}</Moment>
            <Moment format="HH:mm">{row.original.updatedAt}</Moment>
          </span>
        );
      },
    },
    {
      Header: "Data Limite",
      accessor: "limitDate",
      sortMethod: (a, b) => {
        if (typeof a === "string" && typeof b !== "string") {
          return -1;
        }
        if (typeof b === "string" && typeof a !== "string") {
          return 1;
        }

        if (typeof b === "string" && typeof a === "string") {
          return 0;
        }

        if (a < b) {
          return 1;
        }

        if (a > b) {
          return -1;
        }
        return 0;
      },
      maxWidth: 130,
      className: "text-center",
      Cell: (row) =>
        typeof row.original.limitDate === "string" ? (
          <span>{row.original.limitDate}</span>
        ) : (
          <span className="d-flex flex-column">
            <Moment
              format="DD/MM/YYYY"
              style={{
                color: row.original.color,
              }}
            >
              {row.original.limitDate}
            </Moment>
            <Moment
              style={{
                color: row.original.color,
              }}
              format="HH:mm"
            >
              {row.original.limitDate}
            </Moment>
          </span>
        ),
    },
    {
      Header: "Step",
      accessor: "step",
      maxWidth: 50,
      className: "text-center",
      Cell: (row) => (
        <span>{Number(row.original.completedSteps.slice(-1)[0])}</span>
      ),
    },
    {
      Header: "Contexto",
      accessor: "company",
      maxWidth: 80,
      className: "text-center",
      Cell: (row) =>
        row.original.company
          ? metadata.companies.find(
              (company) => company.label === row.original.company
            ).name
          : "Nenhum",
    },
    {
      Header: "Pagamento",
      accessor: "payment",
      maxWidth: 80,
      className: "text-center",
      Cell: (row) => {
        let text = "";
        if (row.original.payment) {
          if (row.original.payment.gift) {
            text = `Gift ${row.original.payment.gift.label}`;
          } else if (row.original.payment.voucher) {
            text = `Voucher ${row.original.payment.voucher.name}`;
          } else if (row.original.payment.type === "admin") {
            text = "Admin";
          } else if (row.original.payment.type === "enterprise") {
            text = "Banco de projetos";
          } else {
            text = "Usuário";
          }
        } else {
          text = "Não informado.";
        }

        return (
          <div className="d-flex">
            <span>{text}</span>
          </div>
        );
      },
    },
    {
      Header: "Ações",
      accessor: "actions",
      sortable: false,
      maxWidth: 90,
      Cell: (row) => (
        <>
          <ButtonGroup size="sm" className="d-table mx-auto">
            <Button
              outline
              theme={row.original.highlight ? "success" : "dark"}
              onClick={() => {
                window.open("/project/" + row.original._id);
              }}
            >
              <i className="material-icons">&#xe86d;</i>
            </Button>
          </ButtonGroup>
          {/* {row.original.highlight ? (
            <span
              class="material-icons"
              style={{
                color: "green",
                marginBottom: "28px",
                position: "absolute",
                marginLeft: "45px",
              }}
            >
              fiber_manual_record
            </span>
          ) : null} */}
        </>
      ),
    },
  ];

  const updateEnviroment = (filterValue, data) => {
    let searcher = new FuzzySearch(data, ["searchables.enviromentName"], {
      caseSensitive: false,
      sort: true,
    });
    if (filterValue) data = searcher.search(sanitizeString(filterValue));
    return data;
  };

  useEffect(() => {
    reload();
  }, []);

  const sameDate = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  function reload() {
    setIsLoading(true);
    return listAllProjectFiltered(
      { company: company },
      filterTypeStatus,
      getQueryStringValue("enterprise")
    )
      .then((data) => {
        setIsLoading(false);
        let today = addingDayWork(new Date(), 0); // Vermelho
        let oneDay = addingDayWork(new Date(), 1); // laranja
        let twoDays = addingDayWork(new Date(), 2); // Amarelo
        let treeDays = addingDayWork(new Date(), 3); // Verde
        let fourDays = addingDayWork(new Date(), 4); // Verde
        let fiveDays = addingDayWork(new Date(), 5); // Verde
        data = idUser
          ? data.filter((dataByUser) => {
              return dataByUser.user._id.includes(idUser);
            })
          : data;

        if (data.length) {
          data.forEach((dado) => {
            try {
              if (dado.enterprise && dado.enterprise.room.length > 0) {
                dado.name = `${dado.enterprise.room[0]} - ${dado.name}`;
              }

              if (!dado.user) console.log(`teste ${dado._id}`);
              dado.userNameSearch = sanitizeString(userName(dado));
              dado.userName = userName(dado);
              dado.statusProd = translateStatus(dado.status, dado.forcedStep);
              dado.enviromentName = dado.questionTemplate.name;
              dado.userEmail = dado.user.email;
              dado.searchName = sanitizeString(dado.name);
              dado.limitDate = limitDate(
                dado.logDates,
                dado.status,
                dado.forcedStep
              );
              if (typeof dado.limitDate !== "string") {
                if (sameDate(dado.limitDate, today)) {
                  dado.color = "red";
                } else if (sameDate(dado.limitDate, oneDay)) {
                  dado.color = "orange";
                } else if (sameDate(dado.limitDate, twoDays)) {
                  dado.color = "#b1b11d";
                } else if (sameDate(dado.limitDate, treeDays)) {
                  dado.color = "green";
                } else if (sameDate(dado.limitDate, fourDays)) {
                  dado.color = "#17c671";
                } else if (sameDate(dado.limitDate, fiveDays)) {
                  dado.color = "#1adba2";
                } else if (dado.limitDate < new Date()) {
                  switch (dado.status) {
                    case "preview":
                      dado.color = "#30305b";
                      break;
                    case "first_review":
                      dado.color = "#30305b";
                      break;
                    case "second_review":
                      dado.color = "#30305b";
                      break;
                    default:
                      dado.color = "darkblue";
                  }
                }
              } else {
                dado.color = "#5A6169";
              }
            } catch (error) {
              console.log(dado);
              console.log(error);
            }
          });
          setProjects(data.reverse());
        }
        return data || [];
      })
      .catch(() => {
        setIsLoading(false);
      });
  }

  function userName(row) {
    if (row.user) {
      if (row.user.profile) {
        return row.user.profile.firstName + " " + row.user.profile.lastName;
      }
    } else {
      return "Não encontrado";
    }
  }

  function nameOwner(row, type) {
    if (row[type].profile) {
      return `${row[type].profile.firstName} ${row[type].profile.lastName}`;
    } else {
      return row[type].email;
    }
  }

  return (
    <Container fluid className="main-content-container px-4">
      <Row className="page-header py-4">
        <Col md="6">
          <PageTitle
            title="Projetos"
            subtitle={userNameUrl ? userNameUrl : "Visão Geral"}
            className="text-sm-left mb-3"
          />
        </Col>
      </Row>

      <Card>
        <CardBody>
          <FilteredTable
            list={projects}
            columns={tableColumns}
            isLoading={isLoading}
            customFilters={[
              {
                name: "userEmail",
                onUpdate: (filterValue, data) => {
                  let searcher = new FuzzySearch(
                    data,
                    ["userEmail", "userName", "userNameSearch"],
                    {
                      caseSensitive: false,
                      sort: true,
                    }
                  );
                  if (filterValue)
                    data = searcher.search(sanitizeString(filterValue));
                  return data;
                },
              },
              {
                name: "searchName",
                onUpdate: (filterValue, data) => {
                  let searcher = new FuzzySearch(data, ["searchName"], {
                    caseSensitive: false,
                    sort: true,
                  });
                  if (filterValue)
                    data = searcher.search(sanitizeString(filterValue));
                  return data;
                },
              },
              {
                name: "status",

                onUpdate: (filterValue, data) =>
                  data.filter(
                    (furniture) => furniture.statusProd === filterValue
                  ),
              },
              {
                name: "color",

                onUpdate: (filterValue, data) =>
                  data.filter((furniture) => furniture.color === filterValue),
              },
              {
                name: "highlight",
                onUpdate: (filterValue, data) =>
                  filterValue === "true"
                    ? data.filter((furniture) => furniture.highlight)
                    : data,
              },
              {
                name: "enviromentName",
                onUpdate: updateEnviroment,
              },
              {
                name: "owner",
                onUpdate: (filterValue, data) =>
                  data.filter(
                    (furnitureWrapper) =>
                      (furnitureWrapper.owner &&
                        furnitureWrapper.owner._id === filterValue) ||
                      (furnitureWrapper.ownerReview &&
                        furnitureWrapper.ownerReview._id === filterValue)
                  ),
              },
            ]}
            config={{
              pageSizeOptions: [5, 10, 15, 20, 30],
              searchableColumns: [
                "userEmail",
                "userName",
                "name",
                "enviromentName",
                "statusProd",
                "ownerReview",
                "owner",
                "limitDate",
                "color",
              ],
              pageSize: 10,
            }}
          >
            {!userNameUrl && (
              <Filters
                refresh={reload}
                isLoading={isLoading}
                setFilterTypeStatus={setFilterTypeStatus}
              />
            )}
          </FilteredTable>
        </CardBody>
      </Card>
    </Container>
  );
};
export default ProjetcsList;

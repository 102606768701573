import React, { useRef } from "react";

import { Button } from "shards-react";

function ButtonUpload(props) {
  const fileInput = useRef(null);
  const { onChange, children, required } = props;

  return (
    <div className="d-flex flex-row  align-items-center">
      <input
        type="file"
        ref={fileInput}
        className="d-none"
        required={required}
        onChange={(ev) => {
          onChange(ev.target.files[0], ev.target.files[0].name);
        }}
      />
      <Button
        {...props}
        onClick={() => fileInput.current && fileInput.current.click()}
      >
        {children}
      </Button>
    </div>
  );
}

export default ButtonUpload;

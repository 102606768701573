import React, { useState } from "react";

import { FormCheckbox } from "shards-react";
import Loader from "react-loader-spinner";

const ImageEditorToggle = ({
  name,
  value,
  onToggle,
  onOpenLink,
  onViewImageClick,
  onCopyLink,
}) => {
  const [copyLinkIsLoading, setCopyLinkIsLoading] = useState(false);
  return (
    <table>
      <tbody>
        <tr>
          <td style={{ width: "230px" }}>
            <FormCheckbox
              className="d-flex"
              toggle
              checked={value.active}
              onChange={onToggle}
              small
            >
              {name}
            </FormCheckbox>
          </td>
          <td>
            <div className="ml-5 d-flex">
              {value.link ? (
                <>
                  <button
                    type="button"
                    onClick={() => {
                      if (copyLinkIsLoading) return;
                      setCopyLinkIsLoading(true);
                      onCopyLink().then(() => {
                        setCopyLinkIsLoading(false);
                      });
                    }}
                    className={`transparent ${
                      copyLinkIsLoading
                        ? "renders_small_icon"
                        : "clickable renders_small_icon"
                    }`}
                  >
                    {copyLinkIsLoading ? (
                      <Loader
                        width="10px"
                        height="10px"
                        type="Circles"
                        color="black"
                      />
                    ) : (
                      <i className="material-icons" style={{ color: "black" }}>
                        picture_as_pdf
                      </i>
                    )}
                  </button>
                  <button
                    type="button"
                    onClick={onViewImageClick}
                    className="transparent renders_small_icon"
                  >
                    <i className="material-icons" style={{ color: "black" }}>
                      visibility
                    </i>
                  </button>
                </>
              ) : null}

              <button
                type="button"
                onClick={onOpenLink}
                className="transparent renders_small_icon"
              >
                <i className="material-icons" style={{ color: "black" }}>
                  open_in_new
                </i>
              </button>
              {value.link ? (
                <div
                  className="renders_small_icon"
                  style={{
                    color: "green",
                  }}
                >
                  <i className="material-icons">check_circle</i>
                </div>
              ) : (
                <div className="ml-3">
                  <i className="material-icons" style={{ color: "red" }}>
                    cancel
                  </i>
                </div>
              )}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

ImageEditorToggle.propTypes = {};

export default ImageEditorToggle;

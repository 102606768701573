import React, { useState } from "react";
import { Row, Card, Col, CardBody } from "shards-react";
import Viewer from "./PDFPages/Viewer";
import { ShowFurnitureMeasures } from "../common/Environments";

const FurnitureMeasures = ({ furnitureMeasures, id, sendLightbox }) => {
  const [link, setLink] = useState("");
  return (
    <Card small className="mb-4">
      <CardBody className="p-0">
        <Row>
          <Col>
            <h6 className="text-center m-0 mt-2">Móveis e medidas</h6>
            <div className="user-details__user-data border-top border-bottom p-4">
              <ShowFurnitureMeasures
                data={furnitureMeasures}
                setLink={setLink}
                id={id}
                sendLightbox={sendLightbox}
              />
            </div>
          </Col>
        </Row>
      </CardBody>
      <Viewer
        link={link}
        toggle={() => {
          if (link) setLink("");
        }}
      />
    </Card>
  );
};

export default FurnitureMeasures;

import React from "react";
import { Button } from "shards-react";
import Loader from "react-loader-spinner";
import "react-toastify/dist/ReactToastify.min.css";

const DeleteButton = ({ onDelete, row, disabled, isDeleting }) => {
  function onDeleteClick() {
    onDelete(row.id)
      .then(() => {})
      .catch(() => {});
  }

  return (
    <Button theme="danger" disabled={disabled} outline onClick={onDeleteClick}>
      {isDeleting ? (
        <Loader width="15px" height="15px" type="Rings" color="black" />
      ) : (
        <i className="material-icons">&#xE872;</i>
      )}
    </Button>
  );
};
export default DeleteButton;

/* eslint-disable-next-line */
import React, { useContext } from "react";
import PropTypes from "prop-types";

import { MetadataContext } from "../metadata/MetadataContext";

function IfAllowed({ children, perform, resource, ifNot }) {
  const { isAllowed } = useContext(MetadataContext);

  return isAllowed(resource, perform) ? children : ifNot();
}

IfAllowed.defaultProps = {
  perform: "read:any",
  ifNot: () => null,
};

IfAllowed.propTypes = {
  resource: PropTypes.string,
  perform: PropTypes.string,
  ifNot: PropTypes.func,
};

export default IfAllowed;

import React from "react";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import Loader from "react-loader-spinner";

class FileDropzone extends React.Component {
  constructor(props) {
    super(props);

    this.handleFileDrop = props.handleFileDrop;
    this.isLoading = props.isLoading;
  }

  /**
   * Mock method to handle the file drop.
   */
  // handleFileDrop(val) {
  //   alert(`You've dropped ${val.length} file${val.length > 1 ? "s" : ""}`);
  // }

  render() {
    return (
      <div className="file-manager__dropzone">
        <Dropzone
          multiple={this.props.multiple}
          disabled={this.props.disabled}
          onDrop={this.handleFileDrop}
        >
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div
                {...getRootProps()}
                className={classNames("dropzone", {
                  "dropzone--isActive": isDragActive,
                })}
              >
                {this.props.isLoading ? (
                  <Loader
                    width="15px"
                    height="15px"
                    type="Rings"
                    color="black"
                  />
                ) : (
                  <>
                    <input {...getInputProps()} />
                    <span>
                      {isDragActive
                        ? "Arrasta e solte seus arquivos aqui"
                        : "Clique aqui para fazer um upload"}
                    </span>
                  </>
                )}
              </div>
            );
          }}
        </Dropzone>
      </div>
    );
  }
}

FileDropzone.defaultProps = {
  multiple: true,
  disabled: false,
};

export default FileDropzone;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Card, CardBody, FormCheckbox } from "shards-react";

import AnswerDefaultQuestion from "./answerDefaultQuestion";
import Answers from "./answers";
import LearningEnvironment from "./learningEnvironment";
import Inspirations from "./inspirations";
import FurnitureMeasures from "./furnitureMeasures";

import RendersV2 from "./RendersV2";

import { updateProjectStatus } from "../../actions/projects";
import { PROJECT_PROGRESS_STATUS } from "../../utils/constants";

import "react-toastify/dist/ReactToastify.min.css";
import { useParams, useHistory } from "react-router-dom";
import ConfirmationButton from "../common/ConfirmationButton";
import IfAllowed from "../common/IfAllowed";
import QualityButton from "../project/qualityButton";
import LeftSidebar from "../common/LeftSidebar";
import { ProjectData } from "./data";
import ChecklistSidebar from "./ChecklistSidebar";

function QualityPreview({
  sendReview,
  projectData,
  onDataChanged,
  setShowChat,
  sendLightbox,
}) {
  if (!projectData) return null;
  const { answerDefaultQuestion, user, projectInfo, email, tokStokToken } =
    projectData;

  const {
    answers,
    learningEnvironment,
    inspirations,
    furnitureMeasures,
    template,
    id,
    finalProject,
    checkList,
  } = projectInfo;

  const { status: statusName } = useParams();
  const history = useHistory();
  const title = PROJECT_PROGRESS_STATUS.asTitle()[statusName];
  const pageTitle = `Qualidade da ${title}`;
  const openQuestionsDefault = {
    Answers: false,
    AnswerDefaultQuestion: false,
    setLearningEnvironment: false,
    Inspirations: false,
    FurnitureMeasures: false,
  };

  const [openQuestions, setOpenQuestions] = useState(openQuestionsDefault);
  const [showRightSidebar, setShowRightSidebar] = useState(false);
  const [showLeftSidebar, setShowLeftSidebar] = useState(false);
  useEffect(() => {
    setShowChat(true);
  }, []);

  return projectInfo.title ? (
    <>
      <ChecklistSidebar
        onChange={(value) =>
          onDataChanged({
            ...projectData,
            projectInfo: {
              ...projectData.projectInfo,
              checkList: value,
            },
          })
        }
        show={showRightSidebar}
        onToggle={(value) => setShowRightSidebar(value)}
        checkList={checkList}
        projectId={id}
        type="checklist"
      />
      <div
        className="overflow-y-auto px-4 quality-preview"
        style={{ height: "calc(100vh - 4.1rem)" }}
      >
        <LeftSidebar
          title=""
          show={showLeftSidebar}
          onClose={() => {
            setShowLeftSidebar(false);
            setOpenQuestions({
              Answers: false,
              AnswerDefaultQuestion: false,
              setLearningEnvironment: false,
              Inspirations: false,
              FurnitureMeasures: false,
            });
          }}
        >
          {openQuestions.AnswerDefaultQuestion && (
            <AnswerDefaultQuestion
              answerDefaultQuestion={answerDefaultQuestion}
              sendLightbox={sendLightbox}
              user={user}
            />
          )}
          {openQuestions.Answers && (
            <Answers
              showExportButton={false}
              answers={answers}
              sendLightbox={sendLightbox}
              originalQuestion={template}
            />
          )}
          {openQuestions.LearningEnvironment && (
            <LearningEnvironment
              learningEnvironment={learningEnvironment}
              id={id}
              sendLightbox={sendLightbox}
              setLearningEnvironment={(value) =>
                onDataChanged({
                  ...projectData,
                  projectInfo: {
                    ...projectData.projectInfo,
                    learningEnvironment: value,
                  },
                })
              }
            />
          )}
          {openQuestions.Inspirations && (
            <Inspirations
              inspirations={inspirations}
              id={id}
              sendLightbox={sendLightbox}
              setInspirations={(value) =>
                onDataChanged({
                  ...projectData,
                  projectInfo: {
                    ...projectData.projectInfo,
                    inspirations: value,
                  },
                })
              }
            />
          )}
          {openQuestions.FurnitureMeasures && (
            <FurnitureMeasures
              furnitureMeasures={furnitureMeasures}
              id={id}
              sendLightbox={sendLightbox}
            />
          )}
        </LeftSidebar>

        <div
          className={`d-flex flex-column h-100  ${
            !showRightSidebar && "ml-auto"
          } mr-auto`}
          style={{
            flex: 1,
            width: statusName === "preview" ? "950px" : "100%",
          }}
        >
          <div className="d-flex flex-row align-items-center">
            <button
              style={{ borderRight: "1px solid grey" }}
              className="transparent pr-2"
              onClick={() => {
                history.goBack();
              }}
              type="button"
            >
              VOLTAR
            </button>
            <div className="medium-title ml-2">{pageTitle}</div>
            <div className="ml-auto">
              <QualityButton
                id={projectInfo.id}
                disabled={projectInfo.status !== "waiting_quality"}
                updateProjectStatus={updateProjectStatus}
                setProjectInfo={(value) =>
                  onDataChanged({
                    ...projectData,
                    projectInfo: {
                      ...projectData.projectInfo,
                      ...value,
                    },
                  })
                }
                projectInfo={projectInfo}
                status={"reviewing_quality"}
                message={"Projeto enviado para revisão com sucesso"}
                buttonText={"Revisão de qualidade"}
              />

              <IfAllowed resource="admin-ladecora:project:send-to-client">
                <ConfirmationButton
                  className="text-center m-0 m-2"
                  id="renders-send-review"
                  disabled={projectInfo.status !== "waiting_quality"}
                  onClick={() => {
                    sendReview(statusName);
                  }}
                >
                  Enviar {title}
                </ConfirmationButton>
              </IfAllowed>
            </div>
          </div>

          <div className="mt-1 d-flex flex-column" style={{ flex: 1 }}>
            <>
              <Card small className="mb-1">
                <CardBody className="p-0">
                  <div className="mb-2">
                    <h6 className="text-center m-0 mt-2">Questionários</h6>
                  </div>

                  <div className="d-flex questionary-toggles align-items-center justify-content-center">
                    <FormCheckbox
                      toggle
                      checked={openQuestions.AnswerDefaultQuestion}
                      onChange={() => {
                        setShowLeftSidebar(
                          !openQuestions.AnswerDefaultQuestion
                        );
                        setOpenQuestions({
                          ...openQuestionsDefault,
                          AnswerDefaultQuestion:
                            !openQuestions.AnswerDefaultQuestion,
                        });
                      }}
                      small
                    >
                      Questionário Perfil
                    </FormCheckbox>
                    <FormCheckbox
                      toggle
                      checked={openQuestions.Answers}
                      onChange={() => {
                        setShowLeftSidebar(!openQuestions.Answers);
                        setOpenQuestions({
                          ...openQuestionsDefault,
                          Answers: !openQuestions.Answers,
                        });
                      }}
                      small
                    >
                      Questionário Ambiente
                    </FormCheckbox>
                    <FormCheckbox
                      toggle
                      checked={openQuestions.LearningEnvironment}
                      onChange={() => {
                        setShowLeftSidebar(!openQuestions.LearningEnvironment);
                        setOpenQuestions({
                          ...openQuestionsDefault,
                          LearningEnvironment:
                            !openQuestions.LearningEnvironment,
                        });
                      }}
                      small
                    >
                      Conhecendo seu espaço
                    </FormCheckbox>
                    <FormCheckbox
                      toggle
                      checked={openQuestions.Inspirations}
                      onChange={() => {
                        setShowLeftSidebar(!openQuestions.Inspirations);
                        setOpenQuestions({
                          ...openQuestionsDefault,
                          Inspirations: !openQuestions.Inspirations,
                        });
                      }}
                      small
                    >
                      Inspirações
                    </FormCheckbox>
                    <FormCheckbox
                      toggle
                      checked={openQuestions.FurnitureMeasures}
                      onChange={() => {
                        setShowLeftSidebar(!openQuestions.FurnitureMeasures);
                        setOpenQuestions({
                          ...openQuestionsDefault,
                          FurnitureMeasures: !openQuestions.FurnitureMeasures,
                        });
                      }}
                      small
                    >
                      Moveis e Medidas
                    </FormCheckbox>
                  </div>
                </CardBody>
              </Card>
            </>

            <div className="d-flex flex-row mt-2" style={{ flex: 1 }}>
              {statusName !== "preview" ? (
                <div
                  className="w-100 overflow-hidden"
                  style={{ paddingLeft: "50px", paddingRight: "50px" }}
                >
                  <RendersV2
                    titleStep={title}
                    stepName={PROJECT_PROGRESS_STATUS.asStep()[statusName]}
                    statusName={statusName}
                    finalProject={finalProject}
                    setFinalProject={(value) =>
                      onDataChanged({
                        ...projectData,
                        projectInfo: {
                          ...projectData.projectInfo,
                          finalProject: value,
                        },
                      })
                    }
                    projectInfo={projectInfo}
                    sendLightbox={sendLightbox}
                    user={user}
                    token={tokStokToken}
                    email={email}
                    updateProjectStatus={updateProjectStatus}
                    setProjectInfo={(value) =>
                      onDataChanged({
                        ...projectData,
                        projectInfo: {
                          ...projectData.projectInfo,
                          ...value,
                        },
                      })
                    }
                    showQualityButton={false}
                    showActions={false}
                  />
                </div>
              ) : null}

              <div
                className="w-100 overflow-hidden"
                style={{ paddingLeft: "50px", paddingRight: "50px" }}
              >
                <RendersV2
                  titleStep={
                    statusName === "preview"
                      ? PROJECT_PROGRESS_STATUS.asTitle()["preview"]
                      : PROJECT_PROGRESS_STATUS.asTitle()[
                          PROJECT_PROGRESS_STATUS.getKeys()[
                            PROJECT_PROGRESS_STATUS.getKeyIndex(statusName) - 1
                          ]
                        ]
                  }
                  stepName={
                    statusName === "preview"
                      ? PROJECT_PROGRESS_STATUS.asStep()["preview"]
                      : PROJECT_PROGRESS_STATUS.asStep()[
                          PROJECT_PROGRESS_STATUS.getKeys()[
                            PROJECT_PROGRESS_STATUS.getKeyIndex(statusName) - 1
                          ]
                        ]
                  }
                  statusName={
                    statusName === "preview"
                      ? "preview"
                      : PROJECT_PROGRESS_STATUS.getKeys()[
                          PROJECT_PROGRESS_STATUS.getKeyIndex(statusName) - 1
                        ]
                  }
                  finalProject={finalProject}
                  setFinalProject={(value) =>
                    onDataChanged({
                      ...projectData,
                      projectInfo: {
                        ...projectData.projectInfo,
                        finalProject: value,
                      },
                    })
                  }
                  projectInfo={projectInfo}
                  sendLightbox={sendLightbox}
                  user={user}
                  token={tokStokToken}
                  email={email}
                  updateProjectStatus={updateProjectStatus}
                  setProjectInfo={(value) =>
                    onDataChanged({
                      ...projectData,
                      projectInfo: {
                        ...projectData.projectInfo,
                        ...value,
                      },
                    })
                  }
                  showQualityButton={false}
                  showActions={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null;
}

QualityPreview.propTypes = {
  projectData: PropTypes.instanceOf(ProjectData),
  reload: PropTypes.func.isRequired,
  onDataChanged: PropTypes.func.isRequired,
  sendReview: PropTypes.func.isRequired,
};

export default QualityPreview;

import React, { useState, useEffect, useContext } from "react";

import { useParams, Route, Switch } from "react-router-dom";
import {
  createCompany,
  uploadPicture,
  getCompany,
  updateCompany,
  uploadPictureV2,
} from "../actions/company";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { useHistory } from "react-router-dom";
import ProjectTemplatesOverride from "../components/Partner/ProjectTemplatesOverride";
import Gifts from "../components/Partner/Gifts";
import Gift from "../components/Partner/Gift";
import ProjectTemplateOverride from "../components/Partner/ProjectTemplateOverride";
import PartnerContent from "../components/Partner";
import { MetadataContext } from "../components/metadata/MetadataContext";
import Content from "../components/common/Content";

const Partner = () => {
  const history = useHistory();
  const { id } = useParams();
  const isNew = !id;
  const { metadata: metadataCompany } = useContext(MetadataContext);
  const [company, setCompany] = useState({
    name: "",
    label: "",
    logo: "",
    priceIncrease: "",
    color: "",
    logOrigin: "",
    navBarColor: "",
    enabled: true,
    externalLoginUrl: "",
    email: {
      emailHeaderImage: "",
      logOrigin: metadataCompany.myCompany.email.logOrigin,
    },
    tracking: {
      facebook: "",
      tagManager: "",
    },
    pdf: { cover: "", thanks: "" },
    favIcon: "",
    useDefaultProjectTemplates: true,
    exampleProjectId: "",
    phone: "",
    idMerlin: "",
    supplierBlacklist: [],
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!isNew) {
      getCompany(id)
        .then((company) => {
          setIsLoading(false);
          let priceIncrease = company.priceIncrease ? company.priceIncrease : 0;
          setCompany({
            _id: company._id,
            name: company.name,
            label: company.label,
            logo: company.logo,
            priceIncrease: priceIncrease,
            color: company.color ? company.color : "#33666d",
            logOrigin: company.email.logOrigin,
            navBarColor: company.navBarColor ? company.navBarColor : "#fff",
            email: company.email,
            pdf: company.pdf,
            favIcon: company.favIcon,
            tracking: {
              facebook:
                company.tracking && company.tracking.facebook
                  ? company.tracking.facebook
                  : "",
              tagManager:
                company.tracking && company.tracking.tagManager
                  ? company.tracking.tagManager
                  : "",
            },
            enabled: company.enabled,
            externalLoginUrl: company.externalLoginUrl,
            useDefaultProjectTemplates: company.useDefaultProjectTemplates,
            exampleProjectId: company.exampleProjectId,
            phone: company.phone.replace("55", ""),
            idMerlin: company.idMerlin,
            supplierBlacklist: company.supplierBlacklist,
          });
        })
        .catch((err) => {
          console.log(err);
          history.replace("/404");
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  function getFileRequests(files, label) {
    const fileUploadRequests = [];
    if (files.logo) {
      fileUploadRequests.push(uploadPicture(files.logo, label, "logo", "logo"));
    }
    if (files.emailHeaderImage) {
      fileUploadRequests.push(
        uploadPicture(
          files.emailHeaderImage,
          label,
          "emailHeaderImage",
          "email.emailHeaderImage"
        )
      );
    }

    if (files.pdfCover) {
      fileUploadRequests.push(
        uploadPicture(files.pdfCover, label, "cover", "pdf.cover")
      );
    }

    if (files.pdfThanks) {
      fileUploadRequests.push(
        uploadPicture(files.pdfThanks, label, "thanks", "pdf.thanks")
      );
    }
    if (files.favIcon) {
      fileUploadRequests.push(
        uploadPictureV2(files.favIcon, label, "favIcon", "favIcon")
      );
    }
    return fileUploadRequests;
  }

  function onChange(data) {
    setCompany({ ...data });
  }

  function onCreate(data, getFileRequests) {
    return createCompany(data)
      .then((res) =>
        Promise.all([Promise.resolve(res), ...getFileRequests()])
          .then((responses) => {
            let createdPartner = responses[0];
            setIsLoading(false);
            toast.success("Parceiro criado com sucesso", { autoClose: 5000 });

            history.replace(`/partner/${createdPartner.label}`);
          })
          .catch((err) => {
            console.log(err);
          })
      )
      .catch((err) => {
        console.log(err);
      });
  }

  function onUpdate(data, getFileRequests) {
    return updateCompany(data.label, data)
      .then((res) =>
        Promise.all([Promise.resolve(res), ...getFileRequests()])
          .then(() => {
            setIsLoading(false);
            toast.success("Parceiro atualizado com sucesso", {
              autoClose: 1000,
            });
            setTimeout(() => window.location.reload(), 1000);
          })
          .catch((err) => {
            console.log(err);
          })
      )
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        toast.error(err.message, { autoClose: 5000 });
      });
  }

  function onSave(data, files) {
    setIsLoading(true);
    data = JSON.parse(JSON.stringify(data));
    if (data.email.custom?.length === 0) {
      data.email.custom = undefined;
    }
    if (data.phone) {
      data.phone = `55${data.phone}`;
    }

    (data._id ? onUpdate : onCreate)(data, () =>
      getFileRequests(files, data.label)
    );
  }

  return (
    <>
      <Switch>
        <Route path={"/partner/:id"} exact={true}>
          <Content value={company._id}>
            <PartnerContent
              company={company}
              onChange={onChange}
              isLoading={isLoading}
              onSave={onSave}
            />
          </Content>
        </Route>
        <Route path={"/partner"} exact={true}>
          <PartnerContent
            company={company}
            onChange={onChange}
            onSave={onSave}
            isLoading={isLoading}
          />
        </Route>
        <Route path={`/partner/:id/project-templates`} exact={true}>
          <Content value={company._id}>
            <ProjectTemplatesOverride company={company} isLoading={isLoading} />
          </Content>
        </Route>
        <Route
          path={`/partner/:id/project-template/:projectTemplateId`}
          exact={true}
        >
          <Content value={company._id}>
            <ProjectTemplateOverride company={company} isLoading={isLoading} />
          </Content>
        </Route>

        <Route path={`/partner/:id/gifts`} exact={true}>
          <Content value={company._id}>
            <Gifts company={company} isLoading={isLoading} />
          </Content>
        </Route>
        <Route
          path={[`/partner/:id/gift`, `/partner/:id/gift/:giftId`]}
          exact={true}
        >
          <Content value={company._id}>
            <Gift company={company} isLoading={isLoading} />
          </Content>
        </Route>
      </Switch>
    </>
  );
};
export default Partner;

import React, { useState } from "react";

function AsyncRequest({ onRequest, children }) {
  const [isLoading, setIsLoading] = useState(false);

  function middleware(...args) {
    setIsLoading(true);
    return onRequest(...args)
      .then((res) => {
        setIsLoading(false);
        return res;
      })
      .catch((err) => {
        setIsLoading(false);
        return err;
      });
  }

  return React.cloneElement(children, {
    onRequest: middleware,
    isLoading: isLoading,
  });
}

export default AsyncRequest;

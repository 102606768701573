import React from "react";

function LeftSidebar({ children, show, onClose, title }) {
  return (
    <div
      className={`left-sidebar d-flex flex-column ${
        show ? "left-sidebar-transition" : ""
      }`}
    >
      <div
        className="d-flex align-items-center justify-content-center h-100"
        style={{ borderBottom: "1px solid", maxHeight: "2.5rem" }}
      >
        <div className="small-title text-center w-100">{title}</div>
        <button
          type="button"
          className="transparent d-flex align-items-center justify-content-center"
          onClick={onClose}
          style={{
            borderLeft: "1px solid",
            height: "2.5rem",
            width: "2.5rem",
          }}
        >
          <span className="material-icons p-1 ">arrow_back</span>
        </button>
      </div>
      <div className="p-3 overflow-y-auto">{children}</div>
    </div>
  );
}

LeftSidebar.propTypes = {};

export default LeftSidebar;

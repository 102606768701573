import React, { useState, useMemo, useEffect } from "react";
import { ImageLabel, DEFAULT_COLORS } from "../../data/image-editor.ts";
import { Button, ButtonGroup } from "shards-react";
import LineItem from "./LineItem";
import SquareItem from "./SquareItem";

const Items = ({
  projectId,
  onAddLine,
  canvasRef,
  isLoading,
  onSave,
  type,
  review,
  onCancel,
  lightsTypology,
  previousLabels,
}) => {
  const [items, setItems] = useState([]);

  const canvas = useMemo(
    () => canvasRef.current?.imageEditorInst?._graphics._canvas,
    [canvasRef.current]
  );

  const Item = useMemo(() => {
    if (type === "finishingLayout") {
      return LineItem;
    }
    if (type === "lights") {
      return SquareItem;
    }
    return null;
  }, [type]);

  useEffect(() => {
    if (previousLabels?.length) {
      previousLabels.forEach((item) => {
        if (item?.sketch?.length) {
          const tempCanvasObjRef = onAddLine(
            item.colorHex,
            "",
            JSON.parse(item.sketch),
            true
          );
          if (tempCanvasObjRef) {
            onAddColorClick(
              item.colorHex,
              tempCanvasObjRef.__fe_id,
              item.mobi?.name,
              tempCanvasObjRef,
              item.mobi
            );
          }
        }
      });
    }
  }, []);

  const onAddColorClick = (
    colorHex = DEFAULT_COLORS[items.length + 1],
    idObj = "",
    prevInputValue = "",
    prevCanvasObjRef = {},
    mobi = {}
  ) => {
    const key = `${new Date().getTime()}-${Math.random()}`;

    const color = new ImageLabel({
      id: key,
      colorHex,
      label: "",
    });
    setItems((prevStats) => [
      ...prevStats,
      { ...color, idObj, prevInputValue, prevCanvasObjRef, mobi },
    ]);
  };

  function onDelete(color) {
    setItems(items.filter((i) => i.id !== color.id));
  }

  function onSaveClick() {
    onSave(items);
  }

  function onChange(param) {
    setItems((prevStats) =>
      prevStats.map((item) => {
        if (item.id == param.id) {
          if (param.idObj) {
            item.idObj = param.idObj;
          }
        }
        return item;
      })
    );
  }

  return (
    <>
      <div className="d-flex flex-column">
        {items.map((color) => {
          return (
            <div key={color.id}>
              <Item
                review={review}
                projectId={projectId}
                imageLabel={color}
                onChange={onChange}
                onAddLine={onAddLine}
                canvas={canvas}
                onDelete={onDelete}
                lightsTypology={lightsTypology}
              />
              <hr />
            </div>
          );
        })}
        <Button
          className="d-flex justify-content-center align-items-center mt-4"
          onClick={() => onAddColorClick()}
        >
          <i style={{ top: "1px" }} className="material-icons pr-2">
            add_circle_outline
          </i>
          <span>Adicionar</span>
        </Button>
      </div>
      <div className="d-flex mt-5">
        <div>
          <ButtonGroup size="sm" className="mx-auto">
            <Button disabled={isLoading} theme="success" onClick={onSaveClick}>
              Salvar
            </Button>
            <Button outline theme="success" onClick={() => {}}>
              <i className="material-icons">download</i>
            </Button>
          </ButtonGroup>
        </div>
        <div className="ml-auto">
          <Button theme="danger" onClick={onCancel}>
            Cancelar
          </Button>
        </div>
      </div>
    </>
  );
};

export default Items;

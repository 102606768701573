import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
} from "shards-react";
import { useHistory } from "react-router-dom";
import { getQueryStringValue } from "../actions/auth";
import { updateUser, createUser, getUser } from "../actions/user";
import { createProject, getProjectsByUser } from "../actions/projects";
import Moment from "react-moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FormUser from "../components/users/Form";
import FilteredTable from "../components/FilteredTable/index";
import { translateStatus } from "../services/project";
import AnswerDefaultQuestion from "../components/project/answerDefaultQuestion";
import { ShowLightbox } from "../components/common/ShowLightbox";

const User = () => {
  const history = useHistory();
  const userId = getQueryStringValue("id");
  const [isLoading, setIsLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [user, setUser] = useState({
    email: "",
    admin: false,
    role: "",
    tokenTokStok: "",
    userIdTokStok: "",
    profile: {
      firstName: "",
      lastName: "",
      documentType: "",
      document: "",
      phone: "",
    },
    address: {
      state: "SC",
      city: "Balneário Camboriú",
      street: "Avenida do Estado Dalmo Vieira",
      postalCode: "88339-060",
      number: 4770,
    },
    company: "",
    answerDefaultQuestion: [],
  });
  const [lightbox, setLightbox] = useState({
    open: false,
    img: "",
    index: 0,
    images: [],
    caption: [],
  });

  useEffect(() => {
    if (userId) {
      setIsLoading(true);
      getUser(userId)
        .then((user) => {
          setIsLoading(false);
          addDefaultAddressIfEmpty(user);
          user.profile.phone = user.profile.phone.replace("+55", "");
          setUser(user);
          getProjectsByUserFunction(userId);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, []);

  const getProjectsByUserFunction = (userId) => {
    getProjectsByUser(userId).then((projects) => {
      setProjects(projects);
    });
  };

  const tableColumns = [
    {
      Header: "Nome",
      accessor: "name",
      className: "text-center",
      Cell: (row) => {
        return <span className="d-flex flex-column">{row.original.name}</span>;
      },
    },
    {
      Header: "Ambiente",
      accessor: "ambiente",
      className: "text-center",
      Cell: (row) => {
        return (
          <span className="d-flex flex-column">
            {row.original.questionTemplate.name}
          </span>
        );
      },
    },
    {
      Header: "Status",
      accessor: "status",
      className: "text-center",
      Cell: (row) => {
        return (
          <span className="d-flex flex-column">
            {translateStatus(row.original.status, row.original.forcedStep)}
          </span>
        );
      },
    },
    {
      Header: "Data Criação",
      acessor: "date",
      className: "text-center",
      Cell: (row) => {
        return (
          <span className="d-flex flex-column">
            <Moment format="DD/MM/YYYY HH:mm">{row.original.createdAt}</Moment>
          </span>
        );
      },
    },
    {
      Header: "Ir para",
      acessor: "goToProject",
      className: "text-center",
      Cell: (row) => {
        return (
          <Button
            outline
            onClick={() => {
              window.open("/project/" + row.original._id);
            }}
          >
            <i className="material-icons">&#xe86d;</i>
          </Button>
        );
      },
    },
  ];

  const sendLightbox = (data, idx, caption = []) => {
    if (Array.isArray(data)) {
      setLightbox({
        ...lightbox,
        images: data,
        open: true,
        index: idx,
        caption,
      });
    } else {
      setLightbox({
        ...lightbox,
        index: 0,
        images: [data],
        open: true,
        caption,
      });
    }
  };

  function addDefaultAddressIfEmpty(user) {
    const defaultAddress = {
      state: "SC",
      city: "Balneário Camboriú",
      street: "Avenida do Estado Dalmo Vieira",
      postalCode: "88339-060",
      number: 4770,
    };

    if (
      Object.keys(defaultAddress).some((key) => {
        return !user.address[key];
      })
    ) {
      user.address = defaultAddress;
    }
  }

  function onUserChange(user) {
    setUser(user);
  }

  function onAddProject(projectTemplate) {
    for (let counter = projectTemplate.qty - 1; counter >= 0; counter--) {
      let project = {
        questionTemplateHash: projectTemplate.questionTemplateHash,
        status: "enabled",
        user: userId,
        company: user.company,
        completedSteps: [0, 1],
        learningEnvironment: {
          files: [],
          details: "",
        },
        inspirations: {
          files: [],
          details: "",
        },
        furnitureMeasures: [],
        name: "",
        paid: true,
      };
      setIsLoading(true);
      createProject(project)
        .then(() => {
          setIsLoading(false);
          getProjectsByUserFunction(userId);
          toast.success("Projeto criado com sucesso!", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
          toast.error(err.msg, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  }

  function onSaveClick() {
    if (user._id) {
      const cpyUser = JSON.parse(JSON.stringify(user));
      cpyUser.profile.phone = `+55${user.profile.phone.replace(/[^0-9]/g, "")}`;
      return updateUser(user._id, cpyUser).then((res) => {
        toast.success(res.msg, { autoClose: 5000 });
      });
    } else {
      return createUser(user)
        .then((res) => {
          history.replace({
            pathname: "/user",
            search: `?id=${res.user._id}`,
          });
          toast.success(res.msg, { autoClose: 5000 });
          setUser(res.user);
        })
        .catch((err) => {
          toast.error(err.message, { autoClose: 5000 });
        });
    }
  }

  return (
    <Container fluid className="main-content-container px-4">
      <Row className="page-header py-4">
        <Col md="6">
          <button
            type="button"
            className="transparent"
            onClick={() => {
              history.goBack();
            }}
          >
            VOLTAR
          </button>
          <h3>{userId ? "Editar" : "Novo"} usuário</h3>
        </Col>
      </Row>

      <div>
        <FormUser
          modalUser={user}
          user={user}
          isLoading={isLoading}
          onUserChange={onUserChange}
          onAddProject={onAddProject}
          onSaveClick={onSaveClick}
          refreshTableData={() => {}}
        />
      </div>
      <Card className="mt-4">
        <CardHeader>
          <h3>Projetos</h3>
        </CardHeader>
        <CardBody>
          {projects.length === 0 ? (
            <div className="text-center">Nenhum projeto encontrado</div>
          ) : (
            <FilteredTable
              list={projects}
              columns={tableColumns}
              isLoading={isLoading}
              customFilters={[]}
              config={{
                pageSizeOptions: [5, 10, 15, 20, 30],
                searchableColumns: ["user", "bluePrint"],
                pageSize: 10,
              }}
            />
          )}
        </CardBody>
      </Card>
      {user._id && user.answerDefaultQuestion.length ? (
        <div className="mt-4">
          <AnswerDefaultQuestion
            answerDefaultQuestion={user.answerDefaultQuestion}
            sendLightbox={sendLightbox}
            user={user}
          />
        </div>
      ) : null}

      {lightbox.open && (
        <ShowLightbox lightbox={lightbox} setLightbox={setLightbox} />
      )}
    </Container>
  );
};
export default User;

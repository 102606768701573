import { API } from "../config";
import { getCookie } from "./auth";
import { requestWrapper } from "../utils/api";
import { getCompanyUrlParams } from "../utils/sanitize";

export const getEnterprises = (params) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/enterprises${getCompanyUrlParams(params)}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};
export const getUserByEnterprise = (enterpriseId) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/user_enterprise/enterprise/${enterpriseId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};
export const getEnterpriseById = (enterpriseId) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/enterprise/${enterpriseId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};
export const updateEnterpriseById = (data) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/enterprise/${data._id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(data),
    })
  );
};

export const createEnterprise = (enterprise, projects) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/enterprise`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify({ enterprise, projects }),
    })
  );
};

// /enterprise/:enterpriseId/upload_link
export const salveEnterpriseProfiles = async (id, profiles) => {
  const token = getCookie("token");

  return await requestWrapper(
    fetch(`${API}/enterprise/${id}/update-profiles`, {
      method: "POST",
      body: JSON.stringify({
        profiles,
      }),
      headers: {
        accept: "application/json",
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};
export const deleteEnterpriseProfilesImg = async (path) => {
  const token = getCookie("token");
  return await requestWrapper(
    fetch(`${API}/enterprise/delete-profiles-img`, {
      method: "POST",
      body: JSON.stringify({ path }),
      headers: {
        accept: "application/json",
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};
export const getUrlEnterpriseFile = async (id, file) => {
  const token = getCookie("token");

  const res = await requestWrapper(
    fetch(`${API}/enterprise/${id}/upload_link`, {
      method: "POST",
      body: JSON.stringify({
        fileName: file.name,
      }),
      headers: {
        accept: "application/json",
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
  await fetch(res.uploadUrl, {
    method: "PUT",
    body: file,
  });
  const awsUrl = new URL(res.uploadUrl);
  const fileUrl = `${awsUrl.protocol}//${awsUrl.host}${awsUrl.pathname}`;

  return fileUrl;
};

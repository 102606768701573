import React, { useCallback, useContext, useMemo, useState } from "react";

import { createContext } from "react";
// @ts-ignore
import { debounce } from "../../utils/throttle";
import { MetadataContext } from "../metadata/MetadataContext";

function composeAsyncPipe<T>(
  ...fns: Array<(input: T) => Promise<T>>
): (input: T) => Promise<T> {
  return async (input: T) => {
    let result = input;
    for (const fn of fns) {
      result = await fn(result);
    }
    return result;
  };
}

export const AsyncTableContext = createContext({});

const ALL_STATUS = {
  PRISTINE: "undefined",
  SUCCESS: "boolean",
  ERROR: "Error",
};

export const AsyncTableProvider = ({ children, value }) => {
  const { searchableColumns, customFilters, pageSize, search } = value;
  const { company } = useContext(MetadataContext) as any;

  const [status, setStatus] = useState<undefined | boolean | Error>();
  const STATUS_NAME = useMemo(() => {
    const as = Object.keys(ALL_STATUS);
    const ts = typeof status;
    for (let index = 0; index < as.length; index++) {
      const n = as[index];
      if (ts === "boolean") {
        return status ? "LOADING" : "SUCCESS";
      }
      if (ALL_STATUS[n] === ts) {
        return n;
      }
    }
    throw new Error("Erro");
  }, [status]);

  const filterInitialValues = Object.keys(customFilters).reduce((acc, cur) => {
    if (customFilters[cur].value !== undefined) {
      acc[customFilters[cur].name] = { value: customFilters[cur].value };
    }
    return acc;
  }, {});

  const [filter, setFilter] = useState({
    search: {
      value: "",
    },
    pageSize: {
      value: pageSize,
    },
    page: {
      value: 0,
    },
    order: {
      value: null,
    },
    ...filterInitialValues,
  });
  const [results, setResults] = useState();

  const asyncFilters = async (f): Promise<any> => {
    setStatus(true);
    return search(
      Object.keys(f).reduce(
        (acc, cur) => {
          acc[cur] = f[cur].value;
          return acc;
        },
        { company: company }
      ) as any
    )
      .then((res) => {
        setStatus(false);
        return res;
      })
      .catch((err) => {
        console.log(err);
        setStatus(err);
      });
  };

  const filterData = composeAsyncPipe(asyncFilters);

  const updateFiltersWithDebounce = useCallback(
    debounce(function (f, p) {
      filterData(f).then((r) => {
        setResults(r);
        p.then(r);
      });
    }, 800),
    []
  );
  const deboucePromise = (f) => {
    const p = Promise.resolve(null);
    updateFiltersWithDebounce(f, p);

    return p;
  };

  function onFilterUpdate(name, value) {
    const f = JSON.parse(JSON.stringify(filter));
    f[name] = { value };
    if (name !== "page") {
      f.page.value = 0;
    }

    setFilter(f);
    if (name === "search") {
      return deboucePromise(f);
    } else {
      return filterData(f).then((r) => {
        setResults(r);
      });
    }
  }

  return (
    <AsyncTableContext.Provider
      value={{
        data: results ? results : [],
        filter: filter,
        isSearchable: !!searchableColumns,
        onFilterUpdate: onFilterUpdate,
        status: STATUS_NAME,
      }}
    >
      {children}
    </AsyncTableContext.Provider>
  );
};

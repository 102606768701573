import React, { useState, useContext } from "react";

import Collapse from "react-bootstrap/Collapse";
import CollapseButton from "../common/CollapseButton";

import { Container, Button, FormSelect, Row, Col } from "shards-react";

import { TableContext } from "../FilteredTable/TableContext";

function Filters({ refresh, isLoading }) {
  const { onFilterUpdate } = useContext(TableContext);

  const [isFilterShown, setIsFilterShown] = useState(true);

  function onCollapseClick() {
    setIsFilterShown(!isFilterShown);
  }

  function onRefreshClick() {
    refresh();
  }
  return (
    <>
      <div className="d-flex mt-2 mb-1 clickable">
        {/* eslint-disable-next-line */}
        <div
          className="d-flex flex-row justify-content-center align-items-center"
          onClick={onCollapseClick}
        >
          <CollapseButton theme="primary" collapsed={isFilterShown} />
          <strong>Filtros</strong>
        </div>
      </div>

      <Collapse in={isFilterShown} timeout={0}>
        <Container fluid className="file-manager__filters  p-2">
          <Row>
            <Col xs="2">
              <div className="form-group">
                <div className="label">Ativo</div>
                <FormSelect
                  className="form-control"
                  defaultValue="true"
                  onChange={(ev) => {
                    onFilterUpdate("enabled", ev.target.value);
                  }}
                >
                  <option value="">Todos</option>
                  <option value="true">Sim</option>
                  <option value="false">Não</option>
                </FormSelect>
              </div>
            </Col>
          </Row>

          <div className="d-flex flex-row justify-content-between">
            <div className="form-group">
              <Button
                theme="success"
                onClick={onRefreshClick}
                disabled={isLoading}
              >
                {isLoading ? "Recarregando" : "Recarregar"}
              </Button>
            </div>
          </div>
        </Container>
      </Collapse>
    </>
  );
}

Filters.propTypes = {};

export default Filters;

import React, { useContext, useState, useEffect } from "react";
import {
  Row,
  Card,
  Col,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "shards-react";
import { MetadataContext } from "../metadata/MetadataContext";

import Moment from "react-moment";

import IfAllowed from "../common/IfAllowed";
import { getProjectsByUser } from "../../actions/projects";
import { translateStatus } from "../../services/project";
import FilteredTable from "../FilteredTable/index";

const User = ({
  user,
  email,
  location,
  createUser,
  tokenTokStok,
  company,
  adminTokenAccess,
  numberProjects,
}) => {
  const { metadata } = useContext(MetadataContext);
  const companyInfo = metadata.companies.find((data) => data.label === company);

  // const userFullName = `${user.firstName} ${user.lastName}`;

  const [showModal, setShowModal] = useState(false);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    if (user._id) {
      getProjectsByUser(user._id).then((projects) => {
        setProjects(projects);
      });
    }
  }, []);

  const tableColumns = [
    {
      Header: "Nome",
      accessor: "name",
      className: "text-center",
      Cell: (row) => {
        return <span className="d-flex flex-column">{row.original.name}</span>;
      },
    },
    {
      Header: "Ambiente",
      accessor: "ambiente",
      className: "text-center",
      Cell: (row) => {
        return (
          <span className="d-flex flex-column">
            {row.original.questionTemplate.name}
          </span>
        );
      },
    },
    {
      Header: "Status",
      accessor: "status",
      className: "text-center",
      Cell: (row) => {
        return (
          <span className="d-flex flex-column">
            {translateStatus(row.original.status, row.original.forcedStep)}
          </span>
        );
      },
    },
    {
      Header: "Data Criação",
      acessor: "date",
      className: "text-center",
      Cell: (row) => {
        return (
          <span className="d-flex flex-column">
            <Moment format="DD/MM/YYYY HH:mm">{row.original.createdAt}</Moment>
          </span>
        );
      },
    },
    {
      Header: "Ir para",
      acessor: "goToProject",
      className: "text-center",
      Cell: (row) => {
        return (
          <Button
            outline
            onClick={() => {
              window.open("/project/" + row.original._id);
            }}
          >
            <i className="material-icons">&#xe86d;</i>
          </Button>
        );
      },
    },
  ];

  return (
    <Card small className="mb-4">
      <CardBody className="p-0">
        <h6 className="text-center m-0 mt-2">Usuário</h6>
        <h4 className="text-center m-0 mt-2" id="project-user-name-title">
          {user.firstName} {user.lastName}{" "}
        </h4>
        <IfAllowed
          resource="admin-ladecora:project:user-info"
          perform="read:any"
        >
          <div className="user-details__user-data border-top border-bottom p-4">
            <Row className="mb-3">
              <Col className="w-50">
                <span>
                  Email
                  <button
                    onClick={() => {
                      if (company === "tok_stok" || company === "") {
                        window.open(
                          `${companyInfo.host}/validator-user?u=` +
                            tokenTokStok +
                            "&page=myorders"
                        );
                      } else {
                        window.open(
                          `${companyInfo.host}/login-admin?token=` +
                            adminTokenAccess +
                            "&page=checkout"
                        );
                      }
                    }}
                    type="button"
                    className="transparent"
                  >
                    <i className="material-icons">&#xe8a6;</i>
                  </button>
                </span>
                <span>{email}</span>
              </Col>
              <Col className="w-50">
                <span>Localização</span>
                <span>
                  {location.city} - {location.state}
                </span>
              </Col>
            </Row>
            <Row>
              <Col className="w-50">
                <span>Telefone</span>
                <span>{user.phone}</span>
              </Col>
              <Col className="w-50">
                <span>Usuário criado</span>
                <span>
                  <Moment format="DD/MM/YYYY">{createUser}</Moment>
                </span>
              </Col>
            </Row>
            <Row>
              <Col className="w-50">
                <span>Projetos ({` ${numberProjects} `})</span>
                <span>
                  <Button
                    size="sm"
                    outline
                    onClick={() => {
                      // window.open(`project-byuser/${user._id}/${userFullName}`);
                      setShowModal(!showModal);
                    }}
                  >
                    Ver Todos
                  </Button>
                </span>
              </Col>
            </Row>
          </div>
        </IfAllowed>
      </CardBody>
      <Modal size="lg" open={showModal} toggle={() => setShowModal(!showModal)}>
        <ModalHeader toggle={() => setShowModal(!showModal)}>
          Projetos
        </ModalHeader>
        <ModalBody>
          {projects.length === 0 ? (
            <div className="text-center">Nenhum projeto encontrado</div>
          ) : (
            <FilteredTable
              list={projects}
              columns={tableColumns}
              // isLoading={isLoading}
              customFilters={[]}
              config={{
                pageSizeOptions: [5, 10, 15, 20, 30],
                searchableColumns: ["user", "bluePrint"],
                pageSize: 10,
              }}
            />
          )}
        </ModalBody>
      </Modal>
    </Card>
  );
};

export default User;

import React, { useState, useEffect } from "react";

//This is used for old projects compatibility. The "furnitures" field of project was discontiniued.
export default function (hasFurnitures) {
  const types = {
    project: "Lista Final",
    preview: "Proposta Inicial",
    firstReview: "1º Revisão",
    secondReview: "2º Revisão",
  };
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(
      Object.keys(types)
        .filter((type) => type !== "project" || hasFurnitures)
        .map((type, idx) => {
          return (
            <option key={idx} value={type}>
              {types[type]}
            </option>
          );
        })
    );
  }, [hasFurnitures]);
  return options;
}

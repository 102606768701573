import React, { useState } from "react";
import "./style.css";

const LiFab = (props) => {
  const [showLabel, setShoLabel] = useState(false);
  return (
    <li>
      {showLabel && <span className="fab-label">{props.label}</span>}
      <div
        style={{ background: props.background ? props.background : "#016fb9" }}
        className="fab-icon-holder"
      >
        <button onClick={props.onClick} className="transparent">
          <i
            onMouseEnter={() => setShoLabel(true)}
            onMouseLeave={() => setShoLabel(false)}
            className={props.icon}
          />
        </button>
      </div>
    </li>
  );
};

export default LiFab;

import React from "react";
import PropTypes from "prop-types";

function TaskCheckbox({ checked, onDoneClick }) {
  return (
    <>
      {checked ? (
        <button
          className="material-icons transparent"
          type="button"
          style={{ color: "green" }}
          onClick={onDoneClick}
        >
          check_circle
        </button>
      ) : (
        <button
          className="material-icons task-checkbox transparent"
          type="button"
          onClick={onDoneClick}
        >
          check_circle_outline
        </button>
      )}
    </>
  );
}

TaskCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onDoneClick: PropTypes.func.isRequired,
};

export default TaskCheckbox;

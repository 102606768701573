import React from "react";

import StepsToBeCorrected from "./StepsToBeCorrected";
import CorrectedSteps from "./CorrectedSteps";

function Steps({ steps, onRemove }) {
  if (!steps || !steps.length) return null;
  const STEPS_TO_BE_CORRECTED = steps.filter((step) => step.isPending);
  const CORRECTED_STEPS = steps.filter((step) => !step.isPending);
  return (
    <div
      className="mt-4"
      style={{ border: "1px solid #E9ECEF", borderRadius: "5px" }}
    >
      <StepsToBeCorrected onRemove={onRemove} steps={STEPS_TO_BE_CORRECTED} />
      <CorrectedSteps onRemove={onRemove} steps={CORRECTED_STEPS} />
    </div>
  );
}

Steps.propTypes = {};

export default Steps;

import { API } from "../config";
import { getCookie } from "../actions/auth";
import { requestWrapper } from "../utils/api";

export const requestInformationEmail = (email, files) => {
  const token = getCookie("token");
  const formData = new FormData();

  if (files) {
    files.forEach((file) => {
      formData.append("files", file);
    });
  }
  Object.keys(email).forEach((key) => {
    formData.append(key, email[key]);
  });

  return requestWrapper(
    fetch(`${API}/email`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: formData,
    })
  );
};

export const finalProjectEmail = (email) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/email/final-project`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(email),
    })
  );
};

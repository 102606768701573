import React, { useState, useEffect } from "react";
import { Row, Col, Container, Card } from "shards-react";

import { listAllProducts_deprecated as listAllProducts } from "../actions/mobi";
import { createMobiGroup } from "../actions/mobiGroup";
import "react-toastify/dist/ReactToastify.min.css";

import ActionHeader from "../components/FurnitureGroups/ActionHeader";
import Filters from "../components/FurnitureGroups/Filters";

import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import FilteredTable from "../components/FilteredTable/index";
import CountChanger from "../components/FilteredTable/CountChanger";

const NewFurnitureGroup = () => {
  const [furnitures, setFurnitures] = useState([]);
  const [furnituresFromGroup, setFurnituresFromGroup] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const tableColumns = [
    {
      Header: "FOTO",
      accessor: "refimage",
      className: "text-center",
      Cell: (row) => {
        return (
          <div>
            <img alt="" height={200} src={row.original.refimage} />
          </div>
        );
      },
      maxWidth: 200,
    },
    {
      Header: "SKU",
      accessor: "sku",
      className: "text-center",
      maxWidth: 200,
    },

    {
      Header: "NOME",
      accessor: "name",
      className: "text-center",
    },
    {
      Header: "MEDIDAS",
      accessor: "",
      className: "text-center",
      Cell: (row) => {
        return (
          <div>
            {row.original.refx} - {row.original.refy} - {row.original.refz}
          </div>
        );
      },
    },
    {
      Header: "AÇÕES",
      accessor: "selected",
      className: "text-center",
      Cell: (row) => {
        return <CountChanger onChange={onChange} item={row.original} />;
      },
      maxWidth: 200,
    },
  ];

  useEffect(() => {
    refreshTableData();
  }, []);

  function onChange(furniture) {
    let groupedFurniture = furnituresFromGroup.find(
      (groupFurniture) => groupFurniture._id === furniture._id
    );
    if (!groupedFurniture) {
      furnituresFromGroup.push(furniture);
    } else {
      groupedFurniture.count = furniture.count;
    }
    setFurnituresFromGroup([...furnituresFromGroup]);
  }

  const refreshTableData = () => {
    setIsLoading(true);
    return listAllProducts({
      supplier: "",
      tags: [],
      styles: [],
      colors: [],
      typologies: [],
      type: "project",
      hasFile: false,
      onlyAvailable: "null",
      order: "frequency_used",
      notUse: "true",
      projectTemplateNames: [],
      limit: 28,
      page: 0,
      search: "",
      project: "",
      isLastPage: false,
      hasOtherFiles: false,
      colorTones: [],
      features: [],
      oldTags: {
        styles: [],
        categories: [],
        colors: [],
        typologies: [],
      },
    })
      .then((results) => {
        results = results.map((furniture) => {
          return {
            ...furniture,
            count: 0,
          };
        });
        setFurnitures(results);

        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  function onSave(groupName) {
    return createMobiGroup({
      name: groupName,
      furnitures: furnituresFromGroup
        .filter((furnitureWrapper) => furnitureWrapper.count > 0)
        .map((furnitureWrapper) => {
          return {
            id: furnitureWrapper._id,
            count: furnitureWrapper.count,
          };
        }),
    })
      .then(() => {
        toast.success("Grupo criado com sucesso", { autoClose: 5000 });
        history.goBack();
      })
      .catch((err) => {
        toast.error("Ocorreu um erro, por favor, tente novamente", {
          autoClose: 5000,
        });
        console.log(err);
      });
  }

  return (
    <Container fluid className="main-content-container h100 px-4 pb-5">
      <Row className="page-header py-2">
        <Col md="6">
          <button
            type="button"
            className="transparent"
            onClick={() => {
              history.goBack();
            }}
          >
            VOLTAR
          </button>
          <h3>Criar novo grupo</h3>
        </Col>
      </Row>
      <Card>
        <Container>
          <FilteredTable
            list={furnitures}
            isLoading={isLoading}
            columns={tableColumns}
            config={{
              pageSizeOptions: [5, 10, 15, 20, 30],
              searchableColumns: ["name", "sku"],
              pageSize: 10,
            }}
            customFilters={[
              {
                name: "groupedOnly",
                onUpdate: (filterValue, data) => {
                  return data.filter((furniture) => furniture.count > 0);
                },
              },
            ]}
          >
            <ActionHeader onSave={onSave} />
            <Filters />
          </FilteredTable>
        </Container>
      </Card>
    </Container>
  );
};

export default NewFurnitureGroup;

import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
} from "shards-react";

const ConfirmationButton = (props) => {
  const [show, setShow] = useState(false);

  function onContinueClick(e) {
    e.preventDefault();
    e.stopPropagation();
    setShow(false);
    props.onClick();
  }

  function onClick() {
    setShow(true);
  }

  function onCancelClick() {
    setShow(false);
  }
  return (
    <>
      {props.type === "icon" ? (
        <button type="button" {...props} onClick={onClick} />
      ) : (
        <Button {...props} onClick={onClick} />
      )}

      {show && (
        <Modal
          size="md"
          modalContentClassName="card card-small"
          open={show}
          toggle={() => {}}
        >
          <ModalHeader
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {props.text}
          </ModalHeader>
          {props.details ? (
            <ModalBody>
              <div style={{ color: "red" }}>{props.details}</div>
            </ModalBody>
          ) : null}
          <ModalFooter>
            <Button onClick={onCancelClick} theme="danger">
              Cancelar
            </Button>
            <Button
              onClick={onContinueClick}
              theme="success"
              className="ml-1"
              id="confirmation-button-continue"
            >
              Continuar
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

ConfirmationButton.defaultProps = {
  text: "Tem certeza que deseja continuar?",
  details: "",
};

export default ConfirmationButton;

import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  Button,
  Row,
  Col,
  ModalBody,
  ModalHeader,
  Modal,
} from "shards-react";

import "@ladecora/react-image-lightbox/style.css";
import "react-toastify/dist/ReactToastify.min.css";
import { useParams, useHistory } from "react-router-dom";
import {
  getProject,
  updateFurnitures,
  selectUniqueFurniture,
  removeFurniture,
  addFurniture,
  copyFurnituresTo,
  removeAllFurnituresByReviewType,
} from "../actions/projects";
import { recommendationsLogsSave } from "../../src/actions/recommendations";
import useMobiFilter from "../components/mobi/useMobiFilter";
import ProjectsWithMobi from "../components/mobi/projectsWithMobi";

import ProductV2 from "./../components/mobi/ProductV2";

import AddingItem from "../components/project/Checklist/AddingItem";
import SidebarInfo from "../components/common/SidebarInfo";
import Fab from "../components/Fab";
import LiFab from "../components/Fab/LiFab";

import { getDefaultQuestionWithUser } from "../../src/actions/questions";

import {
  listAllProducts,
  getRecommendedMobis,
  getMobiFilters,
} from "../actions/mobi";
import { getTagsV2 } from "../actions/mobiTag";

import AnswersById from "../components/Answers/AnswersById";

import Filters, { types } from "../components/project/Furniture/Filters";
import CountChanger from "../components/FilteredTable/CountChanger";
import { toast } from "react-toastify";
import { BUCKET_URL } from "../config";
import {
  FurnitureGrid,
  FurnitureCard,
  FurnitureTitle,
  FurnitureSubtitle,
} from "../components/FurnitureGrid";
import CopyReview from "../components/project/Furniture/CopyReview";
import { PROJECT_PROGRESS_STATUS } from "../utils/constants";
import ListCheckList from "../components/project/Checklist/ListCheckList";
import { justSort, sortByName } from "../utils/sorting";
import AspectRatioPicture from "../components/common/AspectRatioPicture";
import { ShowLightbox } from "../components/common/ShowLightbox";
import SetLinkIntoString from "../components/common/SetLinkIntoString";
import ModalCustomXYZ from "../components/mobi/ModalCustomXYZ";
import ProjectMobiSelector from "../components/mobi/ProjectMobiSelector";

const ProjectMobi = () => {
  const { id } = useParams();
  const history = useHistory();
  const [furnitures, setFurnitures] = useState([]);
  const [recommendedFurnitures, setRecommendedFurnitures] = useState([]);
  const [openAnswers, setOpenAnswers] = useState(false);
  const [showChecklist, setShowChecklist] = useState(false);
  const [showChecklistBook, setShowChecklistBook] = useState(false);
  const [showStepImages, setShowStepImages] = useState(false);
  const [stepImages, setStepImages] = useState([]);
  const [currentStep, setCurrentStep] = useState();
  const [lightbox, setLightbox] = useState({
    open: false,
    index: 0,
    images: [],
  });
  const [project, setProject] = useState();
  const [projectFurnitures, setProjectFurnitures] = useState([]);
  const [defaultQuestionWithUser, setDefaultQuestionWithUser] = useState([]);

  const [showConfig, setShowConfig] = useState(false);
  const [environmentName, setEnvironmentName] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [checkListProject, setCheckListProject] = useState([]);
  const [checkListBookProject, setCheckListBookProject] = useState([]);

  const [isLinkMobiCopied, setIsLinkMobiCopied] = useState(false);
  const [keyLinkMobi, setKeylinkMobi] = useState("");
  const [loadingRecommendation, setLoadingRecommendation] = useState(true);
  const [showModalProduct, setShowModalProduct] = useState(false);
  const [mobiToShow, setMobiToShow] = useState({});
  const [mobisFildOptions, setMobisFildOptions] = useState({
    tags: [],
    suppliers: [],
    styles: [],
    colors: [],
    projectTemplateNames: [],
    colorTones: [],
    typologies: [],
    categories: [],
    features: [],
    subCategories: [],
    oldTags: {
      styles: [],
      colors: [],
      typologies: [],
      categories: [],
    },
  });
  const [backToDragDrop, setBackToDragDrop] = useState(true);
  const userAdmin = JSON.parse(localStorage.getItem("user"));
  const [editMobiXYZ, setEditMobiXYZ] = useState();
  const [showModalMobiProject, setShowModalMobiProject] = useState(false);
  const [mobiToSearch, setMobiToSearch] = useState({});

  useEffect(() => {
    getDefaultQuestionWithUser(id, 1).then((data) => {
      setDefaultQuestionWithUser(data);
    });
  }, []);

  const {
    values: filterValues,
    onFilterUpdate,
    refreshTags,
    onPageIncrease,
    clear,
    clearCatAndSub,
  } = useMobiFilter();

  useEffect(() => {
    displayTableDatas();
  }, []);

  const displayTableDatas = () => {
    setIsLoading(true);
    refreshTags()
      .then((newFilterValues) =>
        Promise.all([
          getProject(id),
          listAllProducts({ ...newFilterValues, projectId: id }),
          newFilterValues,
          getMobiFilters(),
        ])
      )

      .then((res) => {
        let [project, mobis, filters, mobisFildOptionsData] = res;
        setProject(project);
        setIsLoading(false);
        setCheckListProject(project.checklist);
        setCheckListBookProject(project.checklistbook);
        setMobisFildOptions({
          tags: mobisFildOptionsData.tags,
          colorTones: sortByName(mobisFildOptionsData.colorTones),
          features: sortByName(mobisFildOptionsData.features),
          styles: sortByName(mobisFildOptionsData.styles),
          typologies: sortByName(mobisFildOptionsData.typologies),
          categories: mobisFildOptionsData.categories
            ? mobisFildOptionsData.categories
            : [],
          subCategories: mobisFildOptionsData.subCategories
            ? mobisFildOptionsData.subCategories
            : [],
          colors: sortByName(mobisFildOptionsData.colors),
          suppliers: justSort(mobisFildOptionsData.suppliers),
          projectTemplateNames: justSort(
            mobisFildOptionsData.projectTemplateNames
          ),
          oldTags: {
            styles: sortByName(mobisFildOptionsData.oldStyles),
            colors: sortByName(mobisFildOptionsData.oldColors),
            typologies: sortByName(mobisFildOptionsData.oldTypologies),
            categories: sortByName(mobisFildOptionsData.oldCategories),
          },
        });

        let projectFurnitures = [];
        let projectStepImages = [];
        let tempStep = filters.type;
        switch (filters.type) {
          case "preview":
            projectFurnitures = project.finalProject.preview.furnitures;
            projectStepImages = project.finalProject.preview.files;
            break;
          case "first_review":
            tempStep = "firstReview";
            projectFurnitures = project.finalProject.firstReview.furnitures;
            projectStepImages = project.finalProject.firstReview.files;
            break;
          case "second_review":
            tempStep = "secondReview";
            projectFurnitures = project.finalProject.secondReview.furnitures;
            projectStepImages = project.finalProject.secondReview.files;
            break;
          default:
            projectFurnitures = project.furnitures;
            if (
              Object.prototype.hasOwnProperty.call(
                project.finalProject,
                "approvedProject"
              )
            ) {
              projectStepImages =
                project.finalProject[project.finalProject.approvedProject]
                  .files;
              tempStep = project.finalProject.approvedProject;
            } else {
              projectStepImages = [];
            }
        }
        setCurrentStep(tempStep);
        setStepImages(projectStepImages);
        setProjectFurnitures(projectFurnitures);
        setEnvironmentName(project.questionTemplate.name);
        getRecommendedFurnitures(
          projectFurnitures,
          project.questionTemplate.name
        );
        // let mobis = res[1];

        mobis = mobis.map((furniture) => {
          return {
            ...furniture,
            count: 0,
          };
        });

        projectFurnitures.forEach((projectFurniture) => {
          const mobi = mobis.find((mobi) => mobi._id === projectFurniture.id);
          if (mobi) {
            mobi.count = projectFurniture.count;
            mobi.selected = projectFurniture.selected;
            mobi.name = mobi.name.toString();
            mobi.sku = mobi.sku.toString();
            mobi.customRefx = projectFurniture.refx;
            mobi.customRefy = projectFurniture.refy;
            mobi.customRefz = projectFurniture.refz;
          }
        });

        setFurnitures(mobis);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getRecommendedFurnitures = (projectFurnitures, environmentName) => {
    setLoadingRecommendation(true);
    try {
      let ids = projectFurnitures.map((furniture) => furniture.id);
      getRecommendedMobis({
        _ids: ids,
        environmentName,
        projectId: id,
      }).then((res) => {
        if (res.length > 0) {
          let mobis = res.map((furniture) => {
            return {
              ...furniture,
              count: 0,
            };
          });

          projectFurnitures.forEach((projectFurniture) => {
            const mobi = mobis.find((mobi) => mobi._id === projectFurniture.id);
            if (mobi) {
              mobi.count = projectFurniture.count;
              mobi.selected = projectFurniture.selected;
              mobi.name = mobi.name.toString();
              mobi.sku = mobi.sku.toString();
            }
          });
          setRecommendedFurnitures(mobis);
        } else {
          setRecommendedFurnitures([]);
        }
      });
    } catch (error) {
      console.log(error);
      toast.error(error);
      setRecommendedFurnitures([]);
    }
    setLoadingRecommendation(false);
  };

  const onSave = (furniture, value) => {
    if (furniture.available === false) {
      toast.error("Você esta adicionando um item indisponivel.", {
        autoClose: 5000,
      });
    }
    if (value === 0) {
      return removeFurniture(id, furniture._id, filterValues.type)
        .then((res) => {
          furniture.count = 0;
          furniture.selected = false;
          if (filterValues.project) {
            setFurnitures(furnitures.filter((f) => f._id !== furniture._id));
          } else {
            setFurnitures(furnitures);
          }

          toast.success(`${furniture.name} Deletado com sucesso!`);
          setProjectFurnitures(res);
          getRecommendedFurnitures(res, environmentName);
        })
        .catch((err) => {
          if (err.message === "Failed to fetch") {
            toast.error("Ocorreu um problema na conexão com a internet.");
          } else {
            toast.error(err.message);
          }
          return Promise.reject(err);
        });
    } else if (furniture.count > 0 && value) {
      //Faz o update do item
      return updateFurnitures(
        id,
        {
          id: furniture._id,
          count: value,
          refx: furniture.customRefx,
          refy: furniture.customRefy,
          refz: furniture.customRefz,
        },
        filterValues.type
      )
        .then((res) => {
          furniture.count = value;
          furniture.selected = false;
          refreshFurnituresAfterUpdate(
            furnitures,
            res,
            `${furniture.name} Atualizado com sucesso!`
          );
        })
        .catch((err) => {
          if (err.message === "Failed to fetch") {
            toast.error("Ocorreu um problema na conexão com a internet.");
          } else {
            toast.error(err.message);
          }
          return Promise.reject(err);
        });
    } else {
      //Adiciona item no projeto
      let logRecomendation = {
        project: id,
        furniture: furniture._id,
        projectFurnitures: projectFurnitures.map((item) => {
          return item._id;
        }),
        recommendedFurnitures: recommendedFurnitures.map((item) => {
          return item._id;
        }),
        userAdmin: userAdmin._id,
        step: filterValues.type,
      };
      logRecomendation.isCorrect =
        logRecomendation.recommendedFurnitures.includes(furniture._id);
      recommendationsLogsSave(logRecomendation);
      return addFurniture(
        id,
        {
          id: furniture._id,
          count: value,
        },
        filterValues.type
      )
        .then((res) => {
          furniture.count = value;
          furniture.selected = false;

          res.forEach((projectFurniture) => {
            const mobi = furnitures.find(
              (mobi) => mobi._id === projectFurniture.id
            );
            if (mobi) {
              mobi.count = projectFurniture.count;
              mobi.name = mobi.name.toString();
              mobi.sku = mobi.sku.toString();
              mobi.selected = projectFurniture.selected;
            }
          });

          refreshFurnituresAfterUpdate(
            furnitures,
            res,
            `${furniture.name} Adicionado com sucesso!`
          );
        })
        .catch((err) => {
          if (err.message === "Failed to fetch") {
            toast.error("Ocorreu um problema na conexão com a internet.");
          } else {
            toast.error(err.message);
          }
          return Promise.reject(err);
        });
    }
  };

  function refreshFurnituresAfterUpdate(furnitures, projectFurnitures, msg) {
    setFurnitures(furnitures);
    toast.success(msg);
    setProjectFurnitures(projectFurnitures);
    getRecommendedFurnitures(projectFurnitures, environmentName);
  }

  function onFilterChange(data) {
    setIsLoading(true);
    if (data.typology) {
      clearCatAndSub("typology");
      // Update Category
      setMobisFildOptions({
        ...mobisFildOptions,
        category: "",
        subCategory: "",
      });
      getTagsV2("", "typology", data.typology).then((res) => {
        setMobisFildOptions({
          ...mobisFildOptions,
          categories: res,
          subCategories: [],
        });
      });
    }
    if (data.category) {
      clearCatAndSub("category");
      // Update Subcategory
      getTagsV2("", "typology", data.category).then((res) => {
        setMobisFildOptions({
          ...mobisFildOptions,
          subCategories: res,
        });
      });
    }
    return onFilterUpdate(data).then((newFilter) => {
      newFilter = { ...newFilter, ...data, page: 0, projectId: id };
      Promise.all([getProject(id), listAllProducts(newFilter)])
        .then((res) => {
          setIsLoading(false);
          let [project, mobis] = res;
          let pf = [];
          let projectStepImages = [];
          let tempStep = newFilter.type;
          switch (newFilter.type) {
            case "preview":
              pf = project.finalProject.preview.furnitures;
              projectStepImages = project.finalProject.preview.files;
              break;
            case "first_review":
              tempStep = "firstReview";
              pf = project.finalProject.firstReview.furnitures;
              projectStepImages = project.finalProject.firstReview.files;
              break;
            case "second_review":
              tempStep = "secondReview";
              pf = project.finalProject.secondReview.furnitures;
              projectStepImages = project.finalProject.secondReview.files;
              break;
            default:
              pf = project.furnitures;
              if (
                Object.prototype.hasOwnProperty.call(
                  project.finalProject,
                  "approvedProject"
                )
              ) {
                projectStepImages =
                  project.finalProject[project.finalProject.approvedProject]
                    .files;
                tempStep = project.finalProject.approvedProject;
              } else {
                projectStepImages = [];
              }
          }
          setCurrentStep(tempStep);
          setStepImages(projectStepImages);
          setProjectFurnitures(pf);
          mobis = mobis.map((furniture) => {
            return {
              ...furniture,
              count: 0,
            };
          });

          pf.forEach((projectFurniture) => {
            const mobi = mobis.find((mobi) => mobi._id === projectFurniture.id);
            if (mobi) {
              mobi.count = projectFurniture.count;
              mobi.name = mobi.name.toString();
              mobi.sku = mobi.sku.toString();
              mobi.selected = projectFurniture.selected;
              mobi.customRefx = projectFurniture.refx;
              mobi.customRefy = projectFurniture.refy;
              mobi.customRefz = projectFurniture.refz;
            }
          });
          setFurnitures(mobis);
          return pf;
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
      return newFilter;
    });
  }

  function moreItens() {
    if (filterValues.isLastPage) return;
    setIsLoading(true);
    onPageIncrease().then(([values]) => {
      listAllProducts(values)
        .then((data) => {
          setIsLoading(false);
          if (!data.length || data.length === 0) {
            onFilterUpdate({ isLastPage: true });
          }
          if (data.length) {
            data = data.map((furniture) => {
              return {
                ...furniture,
                count: 0,
              };
            });

            projectFurnitures.forEach((projectFurniture) => {
              const mobi = data.find(
                (mobi) => mobi._id === projectFurniture.id
              );
              if (mobi) {
                mobi.count = projectFurniture.count;
                mobi.name = mobi.name.toString();
                mobi.sku = mobi.sku.toString();
                mobi.selected = projectFurniture.selected;
                mobi.customRefx = projectFurniture.refx;
                mobi.customRefy = projectFurniture.refy;
                mobi.customRefz = projectFurniture.refz;
              }
            });
            setFurnitures(furnitures.concat(data));
          } else {
            toast.error("Nunhum item encontrado", { autoClose: 5000 });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    });
  }

  function onProjectUpdate(data) {
    if (data.project) {
      data.project = id;
    }
    onFilterChange(data);
  }

  function onSelectUnique(furniture, value) {
    if (furniture.available === false) {
      toast.error("Você esta adicionando um item indisponivel.", {
        autoClose: 5000,
      });
    }

    let logRecomendation = {
      project: id,
      furniture: furniture._id,
      projectFurnitures: projectFurnitures.map((item) => {
        return item._id;
      }),
      recommendedFurnitures: recommendedFurnitures.map((item) => {
        return item._id;
      }),
      userAdmin: userAdmin._id,
      step: filterValues.type,
    };
    logRecomendation.isCorrect =
      logRecomendation.recommendedFurnitures.includes(furniture._id);
    recommendationsLogsSave(logRecomendation);
    return selectUniqueFurniture(id, furniture._id, value, filterValues.type)
      .then((res) => {
        if (!value && filterValues.project) {
          //Remove the item from the project list.
          setFurnitures(furnitures.filter((f) => f._id !== furniture._id));
        }
        furniture.selected = value;
        furniture.count = 0;

        toast.success(`${furniture.name} Salvo com sucesso!`);
        setProjectFurnitures(res);
        getRecommendedFurnitures(res, environmentName);
        return !filterValues.project;
      })
      .catch((err) => {
        if (err.message === "Failed to fetch") {
          toast.error("Ocorreu um problema na conexão com a internet.");
        } else {
          toast.error(err.message);
        }
        return Promise.reject(err);
      });
  }

  async function onCopy(chosenOnes, reviewType, pId) {
    return await copyFurnituresTo({
      fromProjectId: id,
      toProjectId: pId,
      chosenOnes,
      fromReview: currentStep,
      toReview: reviewType,
    });
  }

  const copyToClipBoard = async (toCopy) => {
    await navigator.clipboard.writeText(toCopy);
    setIsLinkMobiCopied(true);

    setTimeout(() => {
      setIsLinkMobiCopied(false);
    }, 600);
  };

  const handleItemEdit = (mobi) => {
    setMobiToShow(mobi);
    setShowModalProduct(!showModalProduct);
  };

  const handleCloseModal = () => {
    backToDragDrop && setShowModalProduct(!showModalProduct);
  };

  const refreshMobisFildOptions = () => {
    setIsLoading(true);
    getMobiFilters().then((result) => {
      setMobisFildOptions({
        ...mobisFildOptions,
        tags: result.tags,
        categories: [],
        colorTones: sortByName(result.colorTones),
        features: sortByName(result.features),
        styles: sortByName(result.styles),
        typologies: sortByName(result.typologies),
        colors: sortByName(result.colors),
        suppliers: justSort(result.suppliers),
        projectTemplateNames: justSort(result.projectTemplateNames),
        oldTags: {
          styles: sortByName(result.oldStyles),
          typologies: sortByName(result.oldTypologies),
          colors: sortByName(result.oldColors),
          categories: sortByName(result.oldCategories),
        },
      });
      setIsLoading(false);
    });
  };

  function onMobiUpdate(mobi) {
    if (
      typeof furnitures.find((furniture) => mobi._id === furniture._id) ===
      "undefined"
    ) {
      Object.keys(mobi).forEach((key) => {
        if (key === "value") {
          mobi.value = parseFloat(mobi.value);
        }
      });
      setFurnitures(
        [...furnitures, mobi]
          .sort((a, b) => {
            return a[filterValues.order] != b[filterValues.order]
              ? a[filterValues.order]
                  ?.toString()
                  ?.localeCompare(b[filterValues.order]?.toString())
              : a._id.localeCompare(b._id);
          })
          .reverse()
      );
    }
    const mobiFromList = furnitures.find((m) => mobi._id === m._id);
    if (mobiFromList) {
      Object.keys(mobi).forEach((key) => {
        if (key === "value") {
          mobiFromList.value = parseFloat(mobi.value);
        } else {
          mobiFromList[key] = mobi[key];
        }
      });
      setFurnitures([...furnitures]);
    }
    if (!mobi.active) {
      setFurnitures(
        furnitures.filter((furniture) => furniture._id !== mobi._id)
      );
    }
  }

  const sendLightbox = (data, idx) => {
    if (Array.isArray(data)) {
      setLightbox({ ...lightbox, images: data, open: true, index: idx });
    } else {
      setLightbox({ ...lightbox, images: [data], open: true, index: 0 });
    }
  };

  const handleClickLiFab = (name) => {
    setShowStepImages(false);
    setShowChecklistBook(false);
    setShowChecklist(false);
    setOpenAnswers(false);

    switch (name) {
      case "setShowStepImages":
        setShowStepImages(true);
        break;
      case "setShowChecklistBook":
        setShowChecklistBook(true);
        break;
      case "setShowChecklist":
        setShowChecklist(true);
        break;
      case "setOpenAnswers":
        setOpenAnswers(true);
        break;
    }
  };

  const handleCheckLiFab = () => {
    return showStepImages || showChecklistBook || showChecklist || openAnswers
      ? "0.6"
      : "";
  };

  function onRemoveAllByReviewType() {
    if (filterValues.type) {
      return removeAllFurnituresByReviewType(id, filterValues.type)
        .then((res) => {
          displayTableDatas();
          return res;
        })

        .catch((err) => {
          console.log(err);
        });
    } else {
      return Promise.resolve(null);
    }
  }

  const listFilter = {
    filterValues,
    onFilterChange,
    types,
    project,
    id,
  };

  const returnLink = (mobi) => {
    if (mobi.affiliateLink) {
      if (mobi.supplier === "Tok&Stok" && project.company === "tok_stok") {
        return mobi.link;
      }
      return mobi.affiliateLink;
    }
    if (mobi.link) {
      return mobi.link;
    }
    return false;
  };

  function onCustomXYZUpdate(projectFurniture, list) {
    const mobi = furnitures.find((f) => f._id === projectFurniture.id);
    mobi.customRefx =
      mobi.refx === projectFurniture.refx ? "" : projectFurniture.refx;
    mobi.customRefy =
      mobi.refy === projectFurniture.refy ? "" : projectFurniture.refy;
    mobi.customRefz =
      mobi.refz === projectFurniture.refz ? "" : projectFurniture.refz;
    setFurnitures([...furnitures]);
    setProjectFurnitures(list);
    getRecommendedFurnitures(projectFurnitures, environmentName);
  }

  const handleItemProject = (mobi) => {
    setMobiToSearch(mobi);
    setShowModalMobiProject(true);
  };

  const checkLikedMobi = (mobi) => {
    const liked = project?.likedMobis.find((item) => item.idMobi === mobi._id);
    if (liked) {
      mobi.liked = true;
    }

    return mobi;
  };

  return (
    <Container fluid className="main-content-container h100 pr-4 pl-4 pt-2 ">
      {showModalMobiProject && (
        <ProjectsWithMobi
          showModalMobiProject={showModalMobiProject}
          setShowModalMobiProject={setShowModalMobiProject}
          mobiToSearch={mobiToSearch}
        />
      )}
      <Row className="page-header py-2">
        <Col md="6">
          <button
            className="transparent"
            type="button"
            onClick={() => {
              history.goBack();
            }}
          >
            VOLTAR
          </button>
          <h3>
            Móveis do projeto - Total{" "}
            <span style={{ color: "#17c671" }}>
              R${" "}
              {furnitures
                .reduce((acc, cur) => {
                  if (cur.value) {
                    acc += cur.value * cur.count;
                  }
                  return acc;
                }, 0)
                .toFixed(2)}
            </span>
          </h3>
        </Col>
      </Row>
      <Row>
        <Col
          className={
            showChecklistBook || openAnswers || showChecklist || showStepImages
              ? "col-8"
              : ""
          }
        >
          <Card
            style={{
              // maxHeight: "calc(100vh - 15rem)",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            <Filters
              values={filterValues}
              onFilterUpdate={onFilterChange}
              onProjectUpdate={onProjectUpdate}
              project={project}
              isLoading={isLoading}
              clear={clear}
              onCopyReviewClick={() => {
                setShowConfig(!showConfig);
              }}
              suppliers={mobisFildOptions.suppliers}
              tags={mobisFildOptions.tags}
              styles={mobisFildOptions.styles}
              colors={mobisFildOptions.colors}
              colorTones={mobisFildOptions.colorTones}
              typologies={mobisFildOptions.typologies}
              categories={mobisFildOptions.categories}
              subCategories={mobisFildOptions.subCategories}
              features={mobisFildOptions.features}
              projectTemplateNames={mobisFildOptions.projectTemplateNames}
              oldTags={mobisFildOptions.oldTags}
              refreshMobisFildOptions={refreshMobisFildOptions}
              onRemoveAllByReviewType={onRemoveAllByReviewType}
            />

            <FurnitureGrid>
              {furnitures.map((mobi, idx) => {
                const colors = mobi.tags
                  ? mobi.tags.filter(
                      (t) => t?.category && t.category === "color"
                    )
                  : [];
                return (
                  <FurnitureCard mobi={checkLikedMobi(mobi)} key={mobi._id}>
                    <FurnitureTitle title={mobi.name} />
                    <FurnitureSubtitle
                      subtitle={`
                      ${
                        mobi.inventory
                          ? mobi.inventory >= 0
                            ? "Est.: " + mobi.inventory + " - "
                            : "?"
                          : ""
                      }
                      Sku: ${mobi.sku ? mobi.sku : "Não informado"}  - ${
                        mobi.supplier
                      }

                      `}
                    />
                    <Row
                      className="mb-1 scroll-hidden"
                      style={{
                        height: "90px",
                        width: "280px",
                        alignItems: "center",
                        justifyContent: "center",
                        overflowY: "auto",
                      }}
                    >
                      <div className="w-100">
                        L:{" "}
                        <span
                          style={{
                            color:
                              mobi.customRefx &&
                              mobi.customRefx !== mobi.refx &&
                              "green",
                          }}
                        >
                          {mobi.customRefx
                            ? mobi.customRefx
                            : mobi.refx
                            ? mobi.refx
                            : "?"}
                        </span>{" "}
                        P:{" "}
                        <span
                          style={{
                            color:
                              mobi.customRefy &&
                              mobi.customRefy !== mobi.refy &&
                              "green",
                          }}
                        >
                          {mobi.customRefy
                            ? mobi.customRefy
                            : mobi.refy
                            ? mobi.refy
                            : "?"}
                        </span>{" "}
                        A:{" "}
                        <span
                          style={{
                            color:
                              mobi.customRefz &&
                              mobi.customRefz !== mobi.refz &&
                              "green",
                          }}
                        >
                          {mobi.customRefz
                            ? mobi.customRefz
                            : mobi.refz
                            ? mobi.refz
                            : "?"}
                        </span>
                        <button
                          type="button"
                          onClick={() => setEditMobiXYZ(mobi)}
                          className="transparent"
                        >
                          <i
                            style={{ cursor: "pointer" }}
                            className="material-icons ml-2"
                          >
                            &#xE254;
                          </i>
                        </button>
                        <br />
                        {colors.length > 0
                          ? colors.map((color) => `${color.name}`).join(" | ")
                          : "Nenhuma cor cadastrada"}
                      </div>
                    </Row>
                    <Row>
                      {mobi.value
                        ? mobi.value.toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                          })
                        : "Preço não encontrado"}
                    </Row>
                    <Row className="pt-1 pb-1 ">
                      <Col>
                        <Row className="m-1">
                          <Col>
                            <Button
                              title="Baixar Bloco"
                              theme="white"
                              disabled={!mobi.file}
                              onClick={() => {
                                if (mobi.count === 0 && !mobi.selected) {
                                  addFurniture(
                                    id,
                                    {
                                      id: mobi._id,
                                      count: 1,
                                    },
                                    filterValues.type
                                  )
                                    .then((res) => {
                                      mobi.count = 1;
                                      mobi.selected = false;

                                      res.forEach((projectFurniture) => {
                                        const m = furnitures.find(
                                          (mobi) =>
                                            mobi._id === projectFurniture.id
                                        );
                                        if (m) {
                                          m.count = projectFurniture.count;
                                          m.name = m.name.toString();
                                          m.sku = m.sku.toString();
                                          m.selected =
                                            projectFurniture.selected;
                                        }
                                      });

                                      refreshFurnituresAfterUpdate(
                                        furnitures,
                                        res,
                                        `${mobi.name} Adicionado com sucesso!`
                                      );
                                    })
                                    .catch((err) => {
                                      if (err.message === "Failed to fetch") {
                                        toast.error(
                                          "Ocorreu um problema na conexão com a internet."
                                        );
                                      } else {
                                        toast.error(err.message);
                                      }
                                      return Promise.reject(err);
                                    });
                                }
                                window.open(
                                  BUCKET_URL +
                                    "/mobi/" +
                                    mobi._id +
                                    "/file/" +
                                    mobi.file
                                );
                              }}
                            >
                              <i
                                className="fas fa-download fa-lg"
                                style={{
                                  cursor: mobi.file ? "pointer" : "",
                                }}
                              />
                            </Button>
                          </Col>
                          <Col>
                            <Button
                              title="Editar"
                              theme="white"
                              onClick={() => handleItemEdit(mobi)}
                            >
                              <i className="material-icons">&#xE254;</i>
                            </Button>
                          </Col>
                          <Col className="pr-0">
                            <Button
                              title="Projetos com o mobi"
                              theme="white"
                              onClick={() => handleItemProject(mobi)}
                            >
                              <i className="material-icons">perm_media</i>
                            </Button>
                          </Col>
                          <Col>
                            <Button
                              title="Copiar link do produto!"
                              theme="white"
                              disabled={!returnLink(mobi)}
                              onClick={() => {
                                copyToClipBoard(returnLink(mobi));
                                setKeylinkMobi(idx);
                              }}
                            >
                              <i
                                className="fa fa-link"
                                style={{
                                  cursor: returnLink(mobi) ? "pointer" : "",
                                  color: returnLink(mobi) ? "green" : "red",
                                }}
                              />
                            </Button>
                            {isLinkMobiCopied && keyLinkMobi === idx && (
                              <span className="text-success small ">
                                Copiado!
                              </span>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row className="pt-1 pb-1">
                      <Col>
                        <ProjectMobiSelector
                          onChange={onSave}
                          item={mobi}
                          onSelectUnique={onSelectUnique}
                        />
                      </Col>
                    </Row>
                  </FurnitureCard>
                );
              })}
            </FurnitureGrid>
            <hr />
            {!furnitures.length && !isLoading ? (
              <div
                className="text-center m-3"
                style={{
                  fontSize: "40px",
                  textShadow: "-1px 1px 0px #fff, 0px -1px #848484",
                  color: "#d4d4d4",
                }}
              >
                Nenhum móvel encontrado
              </div>
            ) : null}
            <Row style={{ justifyContent: "center" }} className="mb-3">
              {furnitures.length ? (
                <Button
                  onClick={moreItens}
                  disabled={filterValues.isLastPage || isLoading}
                >
                  {isLoading ? "CARREGANDO..." : "CARREGAR MAIS"}
                </Button>
              ) : null}
            </Row>
            {recommendedFurnitures.length ? (
              <>
                <hr />
                <Row style={{ justifyContent: "center" }}>
                  <h3>Recomendados</h3>
                </Row>
                <FurnitureGrid>
                  {recommendedFurnitures.map((mobi, idx) => {
                    const colors = mobi.tags
                      ? mobi.tags.filter(
                          (t) => t && t.category && t.category === "color"
                        )
                      : [];
                    return (
                      <FurnitureCard mobi={mobi} key={mobi._id}>
                        <FurnitureTitle title={mobi.name} />
                        <FurnitureSubtitle
                          subtitle={`Est.: ${
                            mobi.inventory >= 0 ? mobi.inventory : "?"
                          } - Sku: ${mobi.sku} - ${mobi.supplier}`}
                        />
                        <Row>
                          {" "}
                          L: {mobi.refx ? mobi.refx : "?"} P:{" "}
                          {mobi.refy ? mobi.refy : "?"} A:{" "}
                          {mobi.refz ? mobi.refz : "?"}
                        </Row>
                        <Row>
                          {colors.length
                            ? colors.map((color) => `${color.name}`).join(" | ")
                            : "Nenhuma cor cadastrada"}
                        </Row>
                        <Row>
                          {mobi.value
                            ? mobi.value.toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL",
                              })
                            : "Preço não encontrado"}
                        </Row>
                        <Row className="pt-1 pb-1 ">
                          <Col>
                            <Row className="m-1">
                              <Col>
                                <Button
                                  title="Baixar Bloco"
                                  theme="white"
                                  disabled={!mobi.file}
                                  onClick={() => {
                                    if (mobi.count === 0 && !mobi.selected) {
                                      addFurniture(
                                        id,
                                        {
                                          id: mobi._id,
                                          count: 1,
                                        },
                                        filterValues.type
                                      )
                                        .then((res) => {
                                          mobi.count = 1;
                                          mobi.selected = false;

                                          res.forEach((projectFurniture) => {
                                            const m = furnitures.find(
                                              (mobi) =>
                                                mobi._id === projectFurniture.id
                                            );
                                            if (m) {
                                              m.count = projectFurniture.count;
                                              m.name = m.name.toString();
                                              m.sku = m.sku.toString();
                                              m.selected =
                                                projectFurniture.selected;
                                            }
                                          });

                                          refreshFurnituresAfterUpdate(
                                            furnitures,
                                            res,
                                            `${mobi.name} Adicionado com sucesso!`
                                          );
                                        })
                                        .catch((err) => {
                                          if (
                                            err.message === "Failed to fetch"
                                          ) {
                                            toast.error(
                                              "Ocorreu um problema na conexão com a internet."
                                            );
                                          } else {
                                            toast.error(err.message);
                                          }
                                          return Promise.reject(err);
                                        });
                                    }
                                    window.open(
                                      BUCKET_URL +
                                        "/mobi/" +
                                        mobi._id +
                                        "/file/" +
                                        mobi.file
                                    );
                                  }}
                                >
                                  <i
                                    className="fas fa-download fa-lg"
                                    style={{
                                      cursor: mobi.file ? "pointer" : "",
                                    }}
                                  />
                                </Button>
                              </Col>
                              <Col className="pr-0">
                                <Button
                                  title="Projetos com o mobi"
                                  theme="white"
                                  onClick={() => handleItemProject(mobi)}
                                >
                                  <i className="material-icons">perm_media</i>
                                </Button>
                              </Col>
                              <Col>
                                <Button
                                  title="Copiar link do produto!"
                                  theme="white"
                                  disabled={!returnLink(mobi)}
                                  onClick={() => {
                                    copyToClipBoard(returnLink(mobi));
                                    setKeylinkMobi(idx);
                                  }}
                                >
                                  <i
                                    className="fa fa-link"
                                    style={{
                                      cursor: returnLink(mobi) ? "pointer" : "",
                                      color: returnLink(mobi) ? "green" : "red",
                                    }}
                                  />
                                </Button>
                                {isLinkMobiCopied && keyLinkMobi === idx && (
                                  <span className="text-success small ">
                                    Copiado!
                                  </span>
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                        <Row className="pt-1 pb-1">
                          <Col>
                            <ProjectMobiSelector
                              onChange={onSave}
                              item={mobi}
                              onSelectUnique={onSelectUnique}
                            />
                          </Col>
                        </Row>
                      </FurnitureCard>
                    );
                  })}
                </FurnitureGrid>
              </>
            ) : (
              <h3>
                <center>
                  {loadingRecommendation
                    ? "Carregando recomendação"
                    : "Nenhum item para recomendar"}
                </center>
              </h3>
            )}
          </Card>
        </Col>
        {showChecklist && (
          <Col className="col-4">
            <SidebarInfo
              title="Checklist"
              show={showChecklist}
              onToggle={(value) => {
                setShowChecklist(value);
              }}
            >
              <AddingItem
                checklist={checkListProject}
                setCheckList={(value) => setCheckListProject(value)}
                projectId={id}
                type="checklist"
              />
              <ListCheckList
                checklist={checkListProject}
                setCheckList={(value) => setCheckListProject(value)}
                projectId={id}
                type="checklist"
              />
            </SidebarInfo>
          </Col>
        )}
        {showStepImages && (
          <Col className="col-4">
            <SidebarInfo
              title={`Imagens da Etapa ${
                PROJECT_PROGRESS_STATUS.asTitle()[filterValues.type]
              }`}
              listFilter={listFilter}
              show={showStepImages}
              onToggle={(value) => {
                setShowStepImages(value);
              }}
            >
              {isLoading ? (
                <div className="d-flex justify-content-center">
                  <div className="divLoader " />
                </div>
              ) : stepImages.length > 0 ? (
                stepImages.map((elem, idx) => (
                  <div key={idx}>
                    <div className="mb-3 d-flex justify-content-center">
                      <AspectRatioPicture
                        height="250px"
                        width="250px"
                        isDeletable={false}
                        src={
                          BUCKET_URL +
                          "/projects/" +
                          id +
                          `/${currentStep}/thumbnails/` +
                          elem.file
                        }
                        alt={elem.file}
                        slide={true}
                        hideAlt={true}
                        showSlide={() => {
                          sendLightbox(
                            stepImages.map((elem) => {
                              return (
                                BUCKET_URL +
                                "/projects/" +
                                id +
                                `/${currentStep}/` +
                                elem.file
                              );
                            }),
                            idx
                          );
                        }}
                      />
                    </div>
                    <div
                      className="text-center"
                      style={{ wordWrap: "break-word" }}
                    >
                      {project?.finalProject[currentStep]?.files[idx]
                        ?.likeComments && (
                        <div className="mb-2">
                          <h6>O que o cliente gostou:</h6>

                          <SetLinkIntoString
                            string={
                              project.finalProject[currentStep].files[idx]
                                .likeComments
                            }
                          />
                        </div>
                      )}
                      {project?.finalProject[currentStep]?.files[idx]
                        ?.dislikeComments && (
                        <div className="mb-2">
                          <h6>O que o cliente não gostou:</h6>
                          <SetLinkIntoString
                            string={
                              project.finalProject[currentStep].files[idx]
                                ?.dislikeComments
                            }
                          />
                        </div>
                      )}
                      <hr />
                    </div>
                  </div>
                ))
              ) : (
                <h3 className="text-center ">
                  {filterValues.type === "project"
                    ? "Ainda não foi aprovado nenhuma revisão"
                    : "Sem imagem para exibir"}
                </h3>
              )}
            </SidebarInfo>
          </Col>
        )}
        {openAnswers && (
          <Col className="col-4">
            <SidebarInfo
              title="Respostas"
              show={openAnswers}
              onToggle={(value) => {
                setOpenAnswers(value);
              }}
            >
              <AnswersById
                defaultQuestionWithUser={defaultQuestionWithUser}
                project={project}
              />
            </SidebarInfo>
          </Col>
        )}
        {showChecklistBook && (
          <Col className="col-4">
            <SidebarInfo
              title="Checklist Book"
              show={showChecklistBook}
              onToggle={(value) => {
                setShowChecklistBook(value);
              }}
            >
              <AddingItem
                checklist={checkListBookProject}
                setCheckList={(value) => setCheckListBookProject(value)}
                projectId={id}
                type="checklistbook"
              />
              <ListCheckList
                checklist={checkListBookProject}
                setCheckList={(value) => setCheckListBookProject(value)}
                projectId={id}
                type="checklistbook"
              />
            </SidebarInfo>
          </Col>
        )}
      </Row>
      <Modal
        size="lg"
        modalContentClassName="card card-small"
        backdrop={true}
        open={showConfig}
        toggle={() => {
          setShowConfig(!showConfig);
        }}
      >
        <ModalHeader
          toggle={() => {
            setShowConfig(!showConfig);
          }}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {PROJECT_PROGRESS_STATUS.asTitle()[filterValues.type]}
        </ModalHeader>
        <ModalBody>
          <CopyReview
            onCopyClick={onCopy}
            projectId={id}
            project={project}
            projectFurnitures={projectFurnitures}
            type={(() => {
              if (project) {
                switch (filterValues.type) {
                  case "preview":
                    return "preview";
                  case "first_review":
                    return "firstReview";
                  case "second_review":
                    return "secondReview";
                  default:
                    return "project";
                }
              } else {
                return [];
              }
            })()}
            hasOldFurnitures={project && project.furnitures.length}
          />
        </ModalBody>
      </Modal>

      {editMobiXYZ ? (
        <ModalCustomXYZ
          mobi={editMobiXYZ}
          onClose={() => setEditMobiXYZ(null)}
          projectId={id}
          currentStep={currentStep}
          type={filterValues.type}
          onUpdate={onCustomXYZUpdate}
        />
      ) : null}

      {showModalProduct && mobiToShow ? (
        <ProductV2
          product={mobiToShow}
          projectTemplateNames={mobisFildOptions.projectTemplateNames.map(
            (name) => {
              return {
                id: name,
                name: name,
              };
            }
          )}
          suppliers={mobisFildOptions.suppliers}
          tags={mobisFildOptions.tags}
          styles={mobisFildOptions.styles}
          colors={mobisFildOptions.colors}
          colorTones={mobisFildOptions.colorTones}
          features={mobisFildOptions.features}
          typologies={mobisFildOptions.typologies}
          refreshTableData={displayTableDatas}
          refreshTags={refreshMobisFildOptions}
          maxHeight={"500px"}
          onImageView={setBackToDragDrop}
          toggle={handleCloseModal}
          onUpdate={onMobiUpdate}
        />
      ) : null}
      <Fab icon="fa fa-info-circle" fabOpacity={handleCheckLiFab()}>
        <LiFab
          icon="fas fa-image"
          background="#49B4CA"
          label="Imagens"
          onClick={() => handleClickLiFab("setShowStepImages")}
        />
        <LiFab
          icon="fa fa-list-alt"
          background="#e4491c"
          label="Checklist Book"
          onClick={() => handleClickLiFab("setShowChecklistBook")}
        />
        <LiFab
          icon="fa fa-list-alt"
          background="#ffb727"
          label="Checklist"
          onClick={() => handleClickLiFab("setShowChecklist")}
        />
        <LiFab
          icon="fa fa-reply-all"
          background="#538a95"
          label="Respostas"
          onClick={() => handleClickLiFab("setOpenAnswers")}
        />
      </Fab>
      {lightbox.open && (
        <ShowLightbox lightbox={lightbox} setLightbox={setLightbox} />
      )}
    </Container>
  );
};
export default ProjectMobi;

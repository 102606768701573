import React from "react";
import PropTypes from "prop-types";

import { FormSelect } from "shards-react";

const StateSelect = (props) => {
  const { value, onChange } = props;

  return (
    <FormSelect
      {...props}
      style={{
        maxWidth: "60px",
        display: "block",
      }}
      placeholder="--"
      autoComplete="state"
      value={value}
      onChange={onChange}
    >
      <option selected value="" disabled>
        --
      </option>
      <option value="AC">AC</option>
      <option value="AL">AL</option>
      <option value="AP">AP</option>
      <option value="AM">AM</option>
      <option value="BA">BA</option>
      <option value="CE">CE</option>
      <option value="DF">DF</option>
      <option value="ES">ES</option>
      <option value="GO">GO</option>
      <option value="MA">MA</option>
      <option value="MT">MT</option>
      <option value="MS">MS</option>
      <option value="MG">MG</option>
      <option value="PA">PA</option>
      <option value="PB">PB</option>
      <option value="PR">PR</option>
      <option value="PE">PE</option>
      <option value="PI">PI</option>
      <option value="RJ">RJ</option>
      <option value="RN">RN</option>
      <option value="RS">RS</option>
      <option value="RO">RO</option>
      <option value="RR">RR</option>
      <option value="SC">SC</option>
      <option value="SP">SP</option>
      <option value="SE">SE</option>
      <option value="TO">TO</option>
    </FormSelect>
  );
};

StateSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default StateSelect;

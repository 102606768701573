import { API } from "../config";
import { getCookie } from "../actions/auth";

import { requestWrapper } from "../utils/api";

export const getTempleteEmails = () => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/templeteEmails`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const createTempleteEmail = (name, subject, emailBody) => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(`${API}/templeteEmail`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify({ name, subject, emailBody }),
    })
  );
};

export const updateTempleteEmail = (id, name, subject, emailBody) => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(`${API}/templeteEmail/${id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify({ name, subject, emailBody }),
    })
  );
};

export const deleteTempleteEmail = (id) => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(`${API}/templeteEmail/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

import { API } from "../config";
import { getCookie } from "../actions/auth";
import { getCompanyUrlParams } from "../utils/sanitize";
import { requestWrapper } from "../utils/api";

export const listRoles = () => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/roles`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    })
  );
};

export const listUsers = (params) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/users${getCompanyUrlParams(params)}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    })
  );
};

export const listAdminUsers = () => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/users/admin`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    })
  );
};

export const createUser = (user) => {
  return requestWrapper(
    fetch(`${API}/signup-admin`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    })
  );
};

export const getUser = (id) => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(`${API}/user/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const updateUser = (userId, user) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/user/${userId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(user),
    })
  );
};

export const deleteUser = (id, user) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/user/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(user),
    })
  );
};

export const exportToCsv = (filter) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/users/export_to_csv`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(filter),
    })
  );
};

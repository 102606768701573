import React, { useContext } from "react";

import { Route, Redirect, Switch } from "react-router-dom";
import withTracker from "../../withTracker";

import { AuthContext } from "./AuthContext";
import { MetadataContext } from "../metadata/MetadataContext";

import IfAllowed from "./IfAllowed";
import { IconSidebar } from "../../layouts";

const PrivateRoutes = ({ routes }) => {
  const authContext = useContext(AuthContext);
  useContext(MetadataContext);

  function wrapPermissions(route, children) {
    if (route.permission) {
      return (
        <IfAllowed
          ifNot={() => <Redirect to="/" />}
          resource={route.permission.resource}
        >
          {children}
        </IfAllowed>
      );
    } else {
      return <>{children}</>;
    }
  }
  return (
    <Switch>
      {routes.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={
              authContext.authenticated
                ? withTracker((props) => {
                    return wrapPermissions(
                      route,
                      <IconSidebar>
                        {<route.component {...props} />}
                      </IconSidebar>
                    );
                  })
                : () => (
                    <Redirect
                      to={`/login${
                        window.location.pathname
                          ? `?redirectUri=${window.location.pathname}${window.location.search}`
                          : ""
                      }`}
                    />
                  )
            }
          />
        );
      })}
    </Switch>
  );
};

export default PrivateRoutes;

import React, { useState, useEffect } from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import LearningEnvironment from "./LearningEnvironment";
import RoomForm from "./RoomForm";
import StyleForm from "./StyleForm";

import {
  Container,
  Row,
  Card,
  Col,
  CardBody,
  Button,
  Form,
} from "shards-react";

import "react-toastify/dist/ReactToastify.min.css";
import { useHistory } from "react-router-dom";

import Loader from "react-loader-spinner";
import ReactTags from "react-tag-input-custom-search";
import { uploadFile } from "../../actions/file";

import AddTextItem from "../project/PDFPages/AddTextItem";
import { listProjectTemplates } from "../../actions/projectTemplate";
import BasicForm from "./BasicForm";
import FilteredTable from "../FilteredTable/index";

function Enterprise({ enterprise, onChange, onSave, validateStep }) {
  const isNew = !enterprise._id;
  const history = useHistory();
  const [isLoading, setIsLoading] = useState("");

  const [questions, setQuestions] = useState([]);
  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    "Nome do Empreendimento",
    "Conhecendo seu espaço",
    "Estilos",
    "Categorias",
    "Plantas",
    "Confirmação",
  ];
  const [projects, setProjects] = useState([]);

  const tableColumns = [
    {
      Header: "Índice",
      accessor: "index",
      className: "text-center",
      Cell: (row) => <span>{row.index + 1}</span>,
    },
    {
      Header: "Planta",

      id: "room-name",
      className: "text-center",

      Cell: (row) => (
        <span>
          {row.original.room.map((roomName, i) => {
            return <div key={i}>{roomName}</div>;
          })}
        </span>
      ),
    },
    {
      Header: "Cômodo",
      accessor: "environment.name",
      className: "text-center",
      Cell: (row) => <span>{row.original.environment.name}</span>,
    },
    {
      Header: "Estilo",
      accessor: "style.name",
      className: "text-center",
      Cell: (row) => <span>{row.original.style}</span>,
    },
    {
      Header: "Categoria",
      id: "category-id",
      accessor: (d) => d,
      className: "text-center",
      Cell: (row) => (
        <span>
          {row.original.category.map((category, i) => {
            return (
              <div key={i}>{`${category.category} - ${category.value}`}</div>
            );
          })}
        </span>
      ),
    },
    {
      Header: "Ambiente",
      accessor: "category",
      className: "text-center",
      Cell: (row) => <span>{row.original.variation.name}</span>,
    },
  ];

  useEffect(() => {
    listProjectTemplates({ enabled: true }).then((data) => {
      setQuestions(
        data.questions.filter(
          (question) =>
            (!enterprise.company ? "ladecora" : enterprise.company) ===
              question.company && question.status === "enabled"
        )
      );
    });
  }, []);

  function handleSkip() {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  }

  function handleNext() {
    if (validateStep(activeStep) && steps.length - 1 > activeStep) {
      const step = activeStep + 1;
      if (step === 5) {
        const projectsToBeCreated = [];

        const styles = enterprise.styles.map((s) => {
          return { type: "styles", value: s.name };
        });

        enterprise.rooms.forEach((room) => {
          room.environments.forEach((environment) => {
            environment.variations.forEach((variation) => {
              let categories = [];

              if (variation.tags && variation.tags.length) {
                categories = variation.tags.reduce((acc, variationTag) => {
                  const category = enterprise.categories.find(
                    (category) => category.name === variationTag.name
                  );

                  acc.push(
                    category.tags.map((tag) => {
                      return {
                        category: category.name,
                        value: tag.name,
                        type: "categories",
                      };
                    })
                  );

                  return acc;
                }, []);
              } else {
                categories = enterprise.categories.reduce((acc, cur) => {
                  acc.push(
                    cur.tags.map((tag) => {
                      return {
                        category: cur.name,
                        value: tag.name,
                        type: "categories",
                      };
                    })
                  );
                  return acc;
                }, []);
              }

              if (!environment.ref) {
                const result = cartesian(
                  [{ value: room.name, type: "environment" }],
                  ...categories,
                  styles,
                  [{ value: variation.hash, type: "hash" }]
                );

                variation.projects = result.map((d) => d.id);
                result.forEach((category) => {
                  projectsToBeCreated.push({
                    category: category.obj.categories,
                    variation,
                    style: category.obj.styles[0].value,
                    environment: environment,
                    room: [room.name, ...environment.refRooms],
                    projectName: enterprise.name,
                    user: enterprise.userMaster,
                    id: category.id,
                  });
                });
              }
            });
          });
        });

        setProjects(projectsToBeCreated);
      }

      setActiveStep(step);
    }
  }

  function cartesian(...args) {
    var r = [],
      max = args.length - 1;

    function getIdPartialValue(arr) {
      const types = {
        hash: (item) => item.value,
        styles: (item) => item.value,
        categories: (item) => `${item.category} - ${item.value}`,
        environment: (item) => item.value,
      };
      return arr.reduce((acc, cur) => {
        if (acc) {
          acc = `${acc} - ${types[cur.type](cur)}`;
        } else {
          acc = `${types[cur.type](cur)}`;
        }

        return acc;
      }, "");
    }

    function helper(arr, i, obj) {
      for (var j = 0, l = args[i].length; j < l; j++) {
        var a = arr.slice(0); // clone arr

        const cur = args[i][j];
        const item = JSON.parse(JSON.stringify(obj));
        if (cur.type === "categories") {
          item[cur.type].push({
            category: cur.category,
            value: cur.value,
          });
        } else {
          item[cur.type].push({
            value: cur.value,
          });
        }

        a.push(args[i][j]);
        if (i == max) {
          r.push({
            array: a,
            obj: item,
            id: getIdPartialValue(a),
          });
        } else helper(a, i + 1, item);
      }
    }
    helper([], 0, { categories: [], styles: [], hash: [], environment: [] });
    return r;
  }

  const handleDrop = (files) => {
    setIsLoading(true);
    return Promise.all(
      files.map((file) => {
        return uploadFile(file, "enterprise_pictures", "file");
      })
    ).then((res) => {
      setIsLoading(false);
      return res;
    });
  };

  function onSaveClick(ev) {
    ev.preventDefault();
    setIsLoading(true);
    onSave(projects).then(() => {
      setIsLoading(false);
      history.push("/enterprises");
    });

    return false;
  }

  return (
    <Container fluid className="main-content-container px-4">
      <Row className="page-header py-4">
        <Col md="6">
          <button
            className="transparent"
            type="button"
            onClick={() => {
              history.goBack();
            }}
          >
            VOLTAR
          </button>
          <h3>{isNew ? "Cadastrar" : "Alterar"} Empreendimento</h3>
        </Col>
      </Row>

      <Card>
        <CardBody className="pb-0">
          <Form className="py-4" onSubmit={onSaveClick}>
            <Row md="12" className="form-group justify-content-center">
              <Stepper alternativeLabel activeStep={activeStep}>
                {steps.map((label) => {
                  const stepProps = {};
                  const labelProps = {};
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </Row>
            {activeStep === 0 && (
              <BasicForm
                enterprise={enterprise}
                onChange={onChange}
                isLoading={isLoading}
                activeStep={activeStep}
                handleDrop={handleDrop}
              />
            )}
            {activeStep === 1 && (
              <Row>
                <Col md="12" className="form-group">
                  <LearningEnvironment
                    onChange={onChange}
                    enterprise={enterprise}
                  />
                </Col>
              </Row>
            )}
            {activeStep === 2 && (
              <Row>
                <Col md="12" className="form-group">
                  <div className="label">Estilos</div>
                </Col>
                {enterprise.styles.map((style, i) => {
                  return (
                    <StyleForm
                      key={i}
                      style={style}
                      enterprise={enterprise}
                      handleDrop={handleDrop}
                      isLoading={isLoading}
                      onChange={onChange}
                      activeStep={activeStep}
                    />
                  );
                })}
                <Col xs="12" className="mt-3">
                  <AddTextItem
                    onAdd={(name) => {
                      if (!enterprise.styles.find((e) => e.name === name)) {
                        enterprise.styles.push({
                          name,
                          type: "",
                          details: "",
                          files: [],
                        });
                        onChange({ ...enterprise }, activeStep);
                      }
                    }}
                    placeholder="Digite o nome do estilo..."
                  />
                </Col>
              </Row>
            )}
            {activeStep === 3 && (
              <Row>
                <Col xs="12" className="text-center">
                  <h3>Categorias</h3>
                </Col>
                {enterprise.categories.map((categorie, categorieIndex) => {
                  return (
                    <div
                      key={categorieIndex}
                      className="w-100 p-3 mb-2"
                      style={{ border: "1px dashed" }}
                    >
                      <Col md="12" className="form-group">
                        <label className="d-flex align-items-center">
                          <span className="mr-1" style={{ fontSize: "15px" }}>
                            Categoria {categorieIndex + 1} -{" "}
                          </span>
                          <strong style={{ fontSize: "16px" }}>
                            {categorie.name}
                          </strong>
                          <button
                            type="button"
                            className="material-icons hover-click ml-1 transparent"
                            onClick={() => {
                              enterprise.categories =
                                enterprise.categories.filter(
                                  (e, i) => i !== categorieIndex
                                );
                              onChange({ ...enterprise }, activeStep);
                            }}
                            style={{ color: "red" }}
                          >
                            delete
                          </button>
                        </label>
                      </Col>
                      <Col xs="12">
                        <div className="label">Tags</div>
                        <ReactTags
                          placeholder="Digite aqui..."
                          tags={categorie.tags}
                          autofocus={false}
                          handleDelete={(index) => {
                            categorie.tags = categorie.tags.filter(
                              (tag, i) => i !== index
                            );
                            onChange({ ...enterprise }, activeStep);
                          }}
                          allowNew={true}
                          handleAddition={(obj) => {
                            if (
                              !categorie.tags.find((t) => t.name === obj.name)
                            ) {
                              categorie.tags.push(obj);
                              onChange(
                                {
                                  ...enterprise,
                                },
                                activeStep
                              );
                            }
                          }}
                        />
                      </Col>
                    </div>
                  );
                })}
                <Col md="12" className="form-group">
                  <Col xs="12" className="mt-3">
                    <AddTextItem
                      onAdd={(name) => {
                        if (
                          !enterprise.categories.find((c) => c.name === name)
                        ) {
                          enterprise.categories.push({
                            name,
                            tags: [],
                          });
                          onChange({ ...enterprise }, activeStep);
                        }
                      }}
                      placeholder="Digite o nome da categoria..."
                    />
                  </Col>
                </Col>
              </Row>
            )}
          </Form>

          {activeStep === 4 && (
            <Row>
              <Col md="12" className="form-group">
                <h4 className="text-center">Plantas</h4>
              </Col>
              <Col>
                {enterprise.rooms.map((room, i) => {
                  return (
                    <RoomForm
                      key={i}
                      room={room}
                      enterprise={enterprise}
                      onChange={onChange}
                      activeStep={activeStep}
                      questions={questions}
                      handleDrop={handleDrop}
                      isLoading={isLoading}
                    />
                  );
                })}

                <div>
                  <AddTextItem
                    placeholder="Digite o nome da planta..."
                    onAdd={(name) => {
                      if (!enterprise.rooms.find((r) => r.name === name)) {
                        enterprise.rooms.push({
                          name,
                          open: true,
                          img: "",
                          environments: [],
                          bluePrints: [],
                          ref: "",
                        });
                        onChange({ ...enterprise }, activeStep);
                      }
                    }}
                  />
                </div>
              </Col>
            </Row>
          )}
          {activeStep !== 5 && (
            <Row md="12" className="form-group justify-content-center">
              <Button
                outline
                theme="success"
                style={{ marginRight: "18px" }}
                onClick={handleSkip}
              >
                Voltar
              </Button>
              <Button theme="success" onClick={handleNext}>
                Próximo
              </Button>
            </Row>
          )}
          {activeStep === 5 && (
            <Row form>
              <Col xs="12">
                <FilteredTable
                  list={projects}
                  columns={tableColumns}
                  isLoading={isLoading}
                  customFilters={[]}
                  config={{
                    pageSizeOptions: [5, 10, 15, 20, 30],
                    searchableColumns: ["room.name"],
                    pageSize: 10,
                  }}
                />
              </Col>
              <Col md="12" xs="12" sm="12" className="form-group">
                {/* <hr className="mt-4" /> */}
                <Button
                  size="lg"
                  theme="accent"
                  className="d-flex mx-auto mt-3"
                  type="button"
                  onClick={onSaveClick}
                  disabled={isLoading}
                >
                  {isLoading ? "Salvando..." : "Salvar"}
                  {isLoading && (
                    <Loader
                      width="30px"
                      height="15px"
                      type="Rings"
                      color="#ffffff"
                    />
                  )}
                </Button>
              </Col>
            </Row>
          )}
          {/* </Form> */}
        </CardBody>
      </Card>
    </Container>
  );
}

Enterprise.propTypes = {};

export default Enterprise;

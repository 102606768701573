import { debounce } from "./throttle";
import { signout } from "../actions/auth";
import { publicRoutes } from "../routes";
const ALLOWED_STATUS = [400, 200, 404, 401];
const { toast } = require("react-toastify");

const notify = debounce((msg) => {
  toast.error(msg, { autoClose: 2000 });
}, 100);

var isSigningOut = false;

export const requestWrapper = (requestPromise, errorStatusToFoward = [400]) => {
  return requestPromise
    .then(parseResponseBody)
    .then(([response, data]) => {
      if (errorStatusToFoward.includes(response.status)) {
        notify(data.error);
        throw new Error(data.error);
      }
      if (response.status === 401) {
        const paths = publicRoutes.map((route) => route.path);
        if (!paths.includes(window.location.pathname)) {
          if (isSigningOut) {
            return new Promise(() => {});
          }
          isSigningOut = true;
          notify("O seu login expirou");
          return signout().then(() => {
            window.location.href = `/login${
              window.location.pathname
                ? `?redirectUri=${window.location.pathname}${window.location.search}`
                : ""
            }`;
            return new Promise(() => {});
          });
        }
      }
      return data;
    })
    .catch((err) => {
      console.log(err.message);
      if (
        err.message === "Failed to fetch" ||
        err.message === "Preflight response is not successful"
      ) {
        notify("Sem conexão com a internet.");
        // toast.error("Sem conexão com a internet.");
      }
      console.log(err);
      return new Promise(() => {
        throw err;
      });
    });
};
function parseResponseBody(response) {
  if (ALLOWED_STATUS.includes(response.status)) {
    return Promise.all([Promise.resolve(response), response.json()]);
  } else {
    notify("Ocorreu um erro desconhecido.");
    // toast.error("Ocorreu um erro desconhecido.");
    throw new Error("Ocorreu um erro desconhecido.");
  }
}

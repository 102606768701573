import React from "react";
import PropTypes from "prop-types";
import { ListFilter } from "../project/Furniture/Filters";

function RightSidebar({
  children,
  show,
  onClose,
  className,
  title,
  listFilter,
}) {
  return (
    <div
      className={`right-sidebar d-flex flex-column ${
        show ? "right-sidebar-transition" : ""
      } ${className ? className : ""}`}
    >
      <div
        className="d-flex align-items-center justify-content-center h-100"
        style={{ borderBottom: "1px solid", maxHeight: "2.5rem" }}
      >
        <button
          className="transparent d-flex align-items-center justify-content-center"
          type="button"
          onClick={onClose}
          style={{
            borderRight: "1px solid",
            height: "2.5rem",
            width: "2.5rem",
          }}
        >
          <span className="material-icons p-1 ">arrow_forward</span>
        </button>
        <div className="small-title text-center w-100">{title}</div>
        {listFilter && (
          <div className="w-50">{<ListFilter {...listFilter} />}</div>
        )}
      </div>
      <div className="p-3 overflow-y-auto">{children}</div>
    </div>
  );
}

RightSidebar.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default RightSidebar;

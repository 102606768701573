import React, { useState, useEffect } from "react";
import { Button } from "shards-react";
import Loader from "react-loader-spinner";

const CountChanger = ({ item, onChange, disabled }) => {
  const [counter, setCounter] = useState(item.count);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setCounter(item.count);
  }, [item.selected]);

  function onLocalChange(value) {
    if (value >= 0) {
      setCounter(value);
    }
  }

  useEffect(() => {
    setCounter(item.count);
  }, [item.count]);

  function onSaveClick() {
    setIsLoading(true);
    onChange(item, counter)
      .then(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          onClick={() => {
            onLocalChange(counter - 1, "minus");
          }}
          disabled={disabled || isLoading}
          style={{
            color: "black",
            border: "solid 1px",
            padding: "0px",
            height: "20px",
            width: "20px",
            borderRadius: "30px",
            fontSize: "15px",
            backgroundColor: "white",
            paddingBottom: "2px",
          }}
        >
          <span>-</span>
        </Button>
        <input
          style={{
            width: "40px",
            marginLeft: "4px",
            marginRight: "4px",
            paddingLeft: "8px",
            textAlign: "center",
            color: counter > 0 ? "black" : "gray",
          }}
          type="tel"
          value={counter}
          disabled={true}
        />
        <Button
          disabled={disabled || isLoading}
          onClick={() => {
            onLocalChange(counter + 1, "plus");
          }}
          style={{
            color: "black",
            border: "solid 1px",
            padding: "0px",
            height: "20px",
            width: "20px",
            borderRadius: "30px",
            fontSize: "15px",
            backgroundColor: "white",
            paddingBottom: "2px",
          }}
        >
          <span>+</span>
        </Button>
      </div>
      {item.count !== counter && (
        <div className="mt-5">
          <Button theme="success" disabled={isLoading} onClick={onSaveClick}>
            {isLoading ? (
              <Loader width="30px" height="15px" type="Rings" color="#ffffff" />
            ) : (
              "Salvar"
            )}
          </Button>
        </div>
      )}
    </div>
  );
};

export default CountChanger;

import React, { useState } from "react";
import DropzoneCustomer from "./DropzoneCustomer";
import { Row, Card, Col, CardBody } from "shards-react";
import Viewer from "./PDFPages/Viewer";
import { YourSpaceOrInspiration } from "../common/Environments";

const Inspirations = ({
  inspirations,
  id,
  sendLightbox,
  onInspirationsChanged,
}) => {
  const [link, setLink] = useState("");

  return (
    <Card small className="mb-4">
      <CardBody className="p-0">
        <Row>
          <Col>
            <h6 className="text-center m-0 mt-2">Inspiração</h6>
            <div className="user-details__user-data border-top border-bottom p-4">
              <DropzoneCustomer
                projectId={id}
                folder={"customer"}
                step={inspirations}
                stepName={"inspirations"}
                onLearningEnvironmentChanged={onInspirationsChanged}
              />
              <YourSpaceOrInspiration
                data={inspirations}
                sendLightbox={sendLightbox}
                setLink={setLink}
                id={id}
              />
            </div>
          </Col>
        </Row>
      </CardBody>
      <Viewer
        link={link}
        toggle={() => {
          if (link) setLink("");
        }}
      />
    </Card>
  );
};

export default Inspirations;

import React, { useMemo, useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import { fabric } from "fabric"; // eslint-disable-line
import "tui-image-editor/dist/tui-image-editor.css";
import ImageEditor from "@toast-ui/react-image-editor";
import "./image-editor.scss";
import { useHistory } from "react-router-dom";
import { BUCKET_URL } from "./../../config";
import { updateRenderImageByWorkPlanLayout } from "../../actions/projects";
import { getTagsV2 } from "../../actions/mobiTag";
import { dataURItoBlob } from "../../utils/util";
import { PROJECT_PROGRESS_STATUS } from "../../utils/constants";
import { toast } from "react-toastify";
import Items from "./Items";
import { Button } from "shards-react";
import { uploadFile } from "../../services/file";

const ActionsCanvasRotate = ({ canvasRef, canvasObj }) => {
  return (
    <>
      <span className="mr-3">
        <i
          role="button"
          tabIndex="0"
          onKeyDown={() => {}}
          style={{ cursor: "pointer" }}
          className="fas fa-undo"
          onClick={() => {
            canvasObj.rotate(
              canvasObj.angle - 45 - ((canvasObj.angle - 45) % 45)
            );
            canvasRef.current.imageEditorInst._graphics.renderAll();
          }}
        />
        <sup>
          <small>-45</small>
          <sup>&#176;</sup>
        </sup>
      </span>
      <span>
        <i
          role="button"
          tabIndex="0"
          onKeyDown={() => {}}
          style={{ cursor: "pointer" }}
          className="fas fa-redo"
          onClick={() => {
            canvasObj.rotate(
              canvasObj.angle + 45 - ((canvasObj.angle + 45) % 45)
            );
            canvasRef.current.imageEditorInst._graphics.renderAll();
          }}
        />
        <sup>
          <small>+45</small>
          <sup>&#176;</sup>
        </sup>
      </span>
    </>
  );
};

const ImageEditorComponent = () => {
  const history = useHistory();
  const data = useMemo(() => {
    const obj = JSON.parse(
      window.atob(new URLSearchParams(history.location.search).get("data"))
    );
    const stepName = PROJECT_PROGRESS_STATUS.asStep()[obj.review];
    obj.link = `${BUCKET_URL}/projects/${obj.projectId}/${stepName}/${obj.fileName}`;
    return obj;
  }, []);
  const canvasRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const lightsTypology = useMemo(
    () =>
      getTagsV2("Iluminação", "typology", "").then((res) => {
        return res[0]._id;
      }),
    []
  );

  const handleSelectCanvasObj = (canvasObj) => {
    const canvaContent = canvasRef?.current
      ?.getRootElement()
      ?.querySelector(".tui-image-editor-controls");

    if (canvaContent) {
      canvaContent.style.display = "flex";
      canvaContent.style.justifyContent = "center";
      canvaContent.style.paddingTop = "10px";

      ReactDOM.render(
        <ActionsCanvasRotate canvasObj={canvasObj} canvasRef={canvasRef} />,
        canvaContent
      );
    }
  };

  const hideCanvasRotate = () => {
    const canvaContent = canvasRef?.current
      ?.getRootElement()
      ?.querySelector(".tui-image-editor-controls");

    if (canvaContent) {
      canvaContent.style.display = "none";
    }
  };

  useEffect(() => {
    if (
      canvasRef.current &&
      canvasRef.current.imageEditorInst._graphics._canvas.__eventListeners[
        "selection:cleared"
      ].length === 1
    ) {
      canvasRef.current.imageEditorInst._graphics._canvas.on(
        "selection:cleared",
        () => {
          hideCanvasRotate();
        }
      );
    }
  }, [canvasRef.current]);

  const onAddLine = (colorHex, size, elem, isSketch = false) => {
    const imageEditor = canvasRef.current.imageEditorInst;
    const canvas = imageEditor._graphics._canvas;

    let canvasObj = null;

    let props = {
      fill: colorHex,
      stroke: colorHex,
      cornerColor: "#17c671",
      cornerStrokeColor: "#17c671",
      transparentCorners: false,
      cornerStyle: "circle",
      cornerSize: 20,
      touchCornerSize: 10,
      onSelect: () => {
        handleSelectCanvasObj(canvasObj);
      },
    };

    if (isSketch) {
      const aCoords = {
        tl: new fabric.Point(elem.aCoords.tl.x, elem.aCoords.tl.y),
        tr: new fabric.Point(elem.aCoords.tr.x, elem.aCoords.tr.y),
        br: new fabric.Point(elem.aCoords.br.x, elem.aCoords.br.y),
        bl: new fabric.Point(elem.aCoords.bl.x, elem.aCoords.bl.y),
      };

      props = {
        aCoords,
        left: aCoords.tl.lerp(aCoords.br).x,
        top: aCoords.tl.lerp(aCoords.br).y,
        angle: elem.angle,
        originX: "center",
        originY: "center",
        ...props,
      };
    }

    if (!isSketch) {
      props.selectable = true;
    }

    if (data.type === "finishingLayout") {
      props.strokeWidth = 10;
      if (isSketch) {
        props.scaleX = elem.scaleX;
        props.scaleY = elem.scaleY;
      }
      canvasObj = new fabric.Line([250, 125, 250, 175], {
        ...props,
      });

      canvasObj.setControlsVisibility({
        bl: false,
        br: false,
        tr: false,
        tl: false,
        ml: false,
        mr: false,
      });
    }

    if (!isSketch && data.type === "lights") {
      if (size === "small") {
        props.height = 12;
        props.width = 12;
      }
      if (size === "medium") {
        props.height = 24;
        props.width = 24;
      }
      if (size === "large") {
        props.height = 48;
        props.width = 48;
      }
    }

    if (data.type === "lights") {
      props.strokeWidth = 0;
      if (isSketch) {
        props.width = elem.width * elem.scaleX;
        props.height = elem.height * elem.scaleY;
        props.scaleY = 1;
        props.scaleX = 1;
      }
      canvasObj = new fabric.Rect({
        ...props,
      });

      canvasObj.setControlsVisibility({
        bl: true,
        br: true,
        mb: false,
        mt: false,
        tr: true,
        tl: true,
        ml: false,
        mr: false,
        mtr: false,
      });
    }

    if (canvasObj) {
      canvas.add(canvasObj);
      if (!isSketch) {
        canvasObj.center();
      }
    }
    return canvasObj;
  };

  function onSave(items) {
    setIsLoading(true);
    const canvas = canvasRef.current.imageEditorInst;

    canvas._graphics._canvas._objects.forEach((elem) => {
      items = items.map((item) => {
        if (item.idObj == elem.__fe_id) {
          item.sketch = JSON.stringify({
            aCoords: elem.aCoords,
            scaleX: elem.scaleX,
            scaleY: elem.scaleY,
            angle: elem.angle,
            width: elem.width,
            height: elem.height,
          });
        }
        return item;
      });
    });

    canvas.resetZoom();
    canvas.ui.offZoomInButtonStatus();
    canvas._graphics.endZoomInMode();
    canvas._graphics.resetZoom();
    canvas._graphics.renderAll();

    const dataUrl = canvas.toDataURL();
    const blob = dataURItoBlob(dataUrl);

    const file = new File([blob], data.fileName, {
      type: "image/*",
    });
    const stepName = PROJECT_PROGRESS_STATUS.asStep()[data.review];
    return uploadFile({
      path: `projects/${data.projectId}/render`,
      destination: `projects/${data.projectId}/render/thumbnails`,
      width: 600,
      file: file,
      sameName: true,
    }).then((res) => {
      return updateRenderImageByWorkPlanLayout({
        file: file,
        source: res.fullSize,
        thumbnail: res.thumbnail,
        projectId: data.projectId,
        folder: "render",
        type: data.type,
        review: stepName,
        workPlanLayout: data.workPlanLayout,
        labels: items,
      }).then(() => {
        setIsLoading(false);
        toast.success("Imagem salva com sucesso");
      });
    });
  }

  function onCancel() {
    history.goBack();
  }

  return (
    <div
      className="w-100 d-flex image-editor"
      style={{ height: "calc(100vh - 100px)" }}
    >
      <div className="w-100">
        <div className="w-100">
          <Button theme="light" outline onClick={onCancel}>
            <i className="material-icons clickable">arrow_back</i>
            <span className="ml-1"> VOLTAR</span>
          </Button>
        </div>
        <ImageEditor
          ref={canvasRef}
          cssMaxHeight={500}
          cssMaxWidth={1000}
          includeUI={{
            loadImage: {
              path: `${data.link}?time=${new Date().getTime()}`,
              name: "SampleImage",
            },
          }}
        />
      </div>
      <div className="pl-3 pr-3 pt-2 pb-2" style={{ minWidth: "350px" }}>
        <span className="mb-3">Legendas</span>
        <div>
          <Items
            type={data.type}
            review={data.review}
            projectId={data.projectId}
            onSave={onSave}
            isLoading={isLoading}
            onAddLine={onAddLine}
            canvasRef={canvasRef}
            onCancel={onCancel}
            lightsTypology={lightsTypology}
            previousLabels={data.previousLabels}
          />
        </div>
      </div>
    </div>
  );
};

export default ImageEditorComponent;

import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import {
  Card,
  CardBody,
  Form,
  Button,
  CardHeader,
  CardFooter,
  FormInput,
  Row,
  Col,
  FormSelect,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "shards-react";
import { requestInformationEmail } from "../../actions/email";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Dropzone from "react-dropzone";
import classNames from "classnames";
import {
  getTempleteEmails,
  createTempleteEmail,
  updateTempleteEmail,
  deleteTempleteEmail,
} from "../../actions/templeteEmail";
import FilteredTable from "../FilteredTable";
import Moment from "react-moment";
import ConfirmationButton from "../common/ConfirmationButton";
import { sortByName } from "../../utils/sorting";

const Editor = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState({
    subject: "",
    editorHtml: "",
    files: [],
  });
  const initialTempleteData = {
    name: "",
    subject: "",
    emailBody: "",
    isUpdating: false,
  };
  const [templeteEmailData, setTempleteEmailData] =
    useState(initialTempleteData);
  const [showModalTemplete, setShowModalTemplete] = useState(false);
  const [templeteEmails, setTempleteEmails] = useState([]);
  const [showSettingsTemplete, setShowSettingsTemplete] = useState(false);

  useEffect(() => {
    refreshTempleteEmails();
  }, []);

  const refreshTempleteEmails = () => {
    setIsLoading(true);
    getTempleteEmails()
      .then((data) => {
        setTempleteEmails(sortByName(data));
      })
      .catch((err) => {
        console.log(err);
        toast.error("Ocorreu um erro", { autoClose: 5000 });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChangetempleteEmailData = (e) => {
    setTempleteEmailData({
      ...templeteEmailData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeEmailBody = (html) => {
    setTempleteEmailData({
      ...templeteEmailData,
      emailBody: html,
    });
  };

  const createTemplente = () => {
    const { name, subject, emailBody } = templeteEmailData;
    const checkName = templeteEmails.filter((elem) => elem.name === name);
    if (name === "" || subject === "" || emailBody === "") {
      return toast.error("Favor preencher todos os campos!", {
        autoClose: 5000,
      });
    }

    if (checkName.length > 0) {
      toast.error("já exite Templete de email com esse nome!", {
        autoClose: 5000,
      });
    } else {
      setIsLoading(true);
      createTempleteEmail(name, subject, emailBody)
        .then((result) => {
          toast.success(result.msg, { autoClose: 5000 });
          refreshTempleteEmails();
          setShowModalTemplete(false);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Ocorreu um erro", { autoClose: 5000 });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const updateTemplete = () => {
    setIsLoading(true);
    const { _id, name, subject, emailBody } = templeteEmailData;
    if (name === "" || subject === "" || emailBody === "") {
      return toast.error("Favor preencher todos os campos!", {
        autoClose: 5000,
      });
    }
    updateTempleteEmail(_id, name, subject, emailBody)
      .then((result) => {
        toast.success(result.msg, { autoClose: 5000 });
        refreshTempleteEmails();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Ocorreu um erro", { autoClose: 5000 });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteTemplete = (id) => {
    setIsLoading(true);
    deleteTempleteEmail(id)
      .then((result) => {
        toast.success(result.msg, { autoClose: 5000 });
        refreshTempleteEmails();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Ocorreu um erro", { autoClose: 5000 });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  function onFileSelect(selectedFiles) {
    setEmail({ ...email, files: email.files.concat(selectedFiles) });
  }

  function handleChange(html) {
    setEmail({
      ...email,
      editorHtml: html,
    });
  }

  function sendEmail() {
    requestInformationEmail(
      {
        name: props.user.firstName,
        token: props.token,
        message: email.editorHtml,
        subject: email.subject,
        email: props.email,
        _id: props.user._id,
        projectId: props.projectId,
      },
      email.files
    ).then((result) => {
      toast.success(result.msg, { autoClose: 5000 });
      setEmail({ subject: "", editorHtml: "", files: [] });
    });
  }

  function onSubjectChange(ev) {
    setEmail({ ...email, subject: ev.target.value });
  }

  function onDeleteAttachmentClick(index) {
    setEmail({ ...email, files: email.files.filter((file, i) => i !== index) });
  }

  const handleSelectTemplete = (e) => {
    const value = e.target.value;
    if (value !== "" && value !== "nenhum") {
      const templeteSelected = templeteEmails.filter(
        (elem) => elem.name === value
      );
      setEmail({
        ...email,
        subject: templeteSelected[0].subject,
        editorHtml: templeteSelected[0].emailBody,
      });
    } else if (value === "nenhum") {
      setEmail({
        ...email,
        subject: "",
        editorHtml: "",
      });
    }
  };
  const editTempleteEmail = (data) => {
    const { _id, name, subject, emailBody } = data.original;
    setShowModalTemplete(true);
    setTempleteEmailData({ _id, name, subject, emailBody, isUpdating: true });
  };

  const registerTemplete = () => {
    setShowModalTemplete(true);
    setTempleteEmailData({ ...initialTempleteData, isUpdating: false });
  };

  const openSettingsTemplete = () => {
    setShowSettingsTemplete(true);
  };

  const tableColumns = [
    {
      Header: "Nome",
      accessor: "name",
      className: "text-center",
      Cell: (row) => <span className="w-100">{row.original.name}</span>,
    },

    {
      Header: "Criado",
      accessor: "createdAt",
      className: "text-center",
      Cell: (row) => {
        return (
          <span className="d-flex flex-column">
            <Moment format="DD/MM/YYYY">{row.original.createdAt}</Moment>
            <Moment format="HH:mm">{row.original.createdAt}</Moment>
          </span>
        );
      },
    },
    {
      Header: "Última Atualização",
      accessor: "updatedAt",
      className: "text-center",
      Cell: (row) => {
        return (
          <span className="d-flex flex-column">
            <Moment format="DD/MM/YYYY">{row.original.updatedAt}</Moment>
            <Moment format="HH:mm">{row.original.updatedAt}</Moment>
          </span>
        );
      },
    },
    {
      Header: "Editar",
      maxWidth: 90,
      Cell: (row) => (
        <Button
          title="Editar"
          outline
          theme={row.original.highlight ? "success" : "dark"}
          onClick={() => {
            editTempleteEmail(row);
          }}
        >
          <i className="fas fa-edit" />
        </Button>
      ),
    },
    {
      Header: "Excluir",
      maxWidth: 90,
      Cell: (row) => (
        <ConfirmationButton
          title="Excluir"
          outline
          theme={row.original.highlight ? "success" : "dark"}
          onClick={() => deleteTemplete(row.original._id)}
        >
          <i className="fas fa-trash" />
        </ConfirmationButton>
      ),
    },
  ];

  return (
    <Card small className="mb-3">
      <CardHeader className="mt-3 d-flex justify-content-between">
        <h5>Envio de email</h5>
        <div className="d-flex flex-column justify-content-center text-center w-50">
          <h5>Template Email</h5>
          <div className="d-flex justify-content-around align-items-center ">
            <FormSelect
              onChange={handleSelectTemplete}
              style={{ maxWidth: "280px" }}
            >
              <option value="">Selecione um Template</option>

              {templeteEmails.map((elem, idx) => (
                <option key={idx} value={elem.name}>
                  {elem.name}
                </option>
              ))}
              <option value="nenhum">Nenhum</option>
            </FormSelect>

            <i
              onClick={openSettingsTemplete}
              style={{ cursor: "pointer" }}
              className="fa fa-cog ml-3"
              aria-hidden="true"
            />
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <Form className="add-new-post">
          <Row form>
            <Col lg="12" className="form-group">
              <label htmlFor="checkout-card-name">Assunto</label>
              <FormInput
                type="text"
                name="subject"
                required
                placeholder="Digite o assunto"
                value={email.subject}
                onChange={onSubjectChange}
              />
            </Col>
          </Row>
          <Row form>
            <Col lg="12" className="form-group">
              <label htmlFor="checkout-card-name">Anexo</label>
              <Dropzone multiple={true} disabled={false} onDrop={onFileSelect}>
                {({ getRootProps, getInputProps, isDragActive }) => {
                  return (
                    <div
                      {...getRootProps()}
                      className={classNames("dropzone", {
                        "dropzone--isActive": isDragActive,
                      })}
                    >
                      <input {...getInputProps()} />
                      <span>
                        {isDragActive
                          ? "Arrasta e solte seus arquivos aqui"
                          : "Clique aqui para fazer um upload"}
                      </span>
                    </div>
                  );
                }}
              </Dropzone>
            </Col>
          </Row>

          <Row>
            <Col lg="12" className="form-group">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {email.files.map((file, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        padding: "10px",
                        border: "solid 1px #6b6b6b",
                        borderRadius: "5px",
                        margin: "12px",
                      }}
                    >
                      <span
                        style={{ lineHeight: "0", verticalAlign: "middle" }}
                        className="material-icons d-inline"
                      >
                        file_present
                      </span>
                      <p
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "200px",
                          margin: "0px",
                          display: "inline",
                          lineHeight: 0,
                          verticalAlign: "middle",
                        }}
                      >
                        {file.name}
                      </p>
                      <button
                        type="button"
                        style={{
                          display: "inline",
                          position: "absolute",
                          marginTop: "-29px",
                          color: "rgb(196, 24, 60)",
                          cursor: "pointer",
                          backgroundColor: "transparent",
                          border: "none",
                          fontSize: "1.5rem",
                          padding: "0px",
                          marginBottom: "0px",
                          marginLeft: "auto",
                        }}
                        onClick={() => onDeleteAttachmentClick(i)}
                      >
                        <i className="material-icons">cancel</i>
                      </button>
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>

          <ReactQuill
            className="add-new-post__editor mb-1"
            onChange={(html, delta, source) => {
              if (source === "user") {
                handleChange(html);
              }
            }}
            value={email.editorHtml}
          />
        </Form>
      </CardBody>
      <CardFooter>
        <Button
          onClick={sendEmail}
          style={{ cssFloat: "right" }}
          theme="accent btn-outline"
        >
          Enviar
        </Button>
      </CardFooter>
      <Modal
        centered
        size="lg"
        open={showSettingsTemplete}
        toggle={() => setShowSettingsTemplete(false)}
      >
        <ModalHeader toggle={() => setShowSettingsTemplete(false)}>
          Configurações de template de email
        </ModalHeader>
        <ModalBody style={{ maxHeight: "80vh", overflow: "auto" }}>
          <div className="mb-5">
            <Button
              style={{ cssFloat: "right" }}
              onClick={registerTemplete}
              size="sm"
              outline
            >
              Cadastrar Template
            </Button>
          </div>
          <FilteredTable
            list={templeteEmails}
            columns={tableColumns}
            isLoading={isLoading}
            customFilters={[]}
            config={{
              pageSizeOptions: [5, 10, 15, 20, 30],
              searchableColumns: ["name"],
              pageSize: 10,
            }}
          />
        </ModalBody>
      </Modal>
      <Modal
        centered
        size="lg"
        open={showModalTemplete}
        toggle={() => setShowModalTemplete(false)}
      >
        <ModalHeader toggle={() => setShowModalTemplete(false)}>
          {templeteEmailData.isUpdating ? "Atualização " : "Cadastro "}de
          template de email
        </ModalHeader>
        <ModalBody style={{ maxHeight: "80vh", overflow: "auto" }}>
          <Form className="add-new-post">
            <Row form>
              <Col lg="12" className="form-group">
                <label htmlFor="subject">Nome do Templete</label>
                <FormInput
                  type="text"
                  name="name"
                  required
                  placeholder="Digite o nome do templete"
                  value={templeteEmailData.name}
                  onChange={handleChangetempleteEmailData}
                />
              </Col>
            </Row>
            <Row form>
              <Col lg="12" className="form-group">
                <label htmlFor="subject">Assunto</label>
                <FormInput
                  type="text"
                  name="subject"
                  required
                  placeholder="Digite o assunto"
                  value={templeteEmailData.subject}
                  onChange={handleChangetempleteEmailData}
                />
              </Col>
            </Row>
            <ReactQuill
              className="add-new-post__editor mb-1"
              onChange={handleChangeEmailBody}
              value={templeteEmailData.emailBody}
            />
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={isLoading}
            onClick={
              templeteEmailData.isUpdating ? updateTemplete : createTemplente
            }
            style={{ cssFloat: "right" }}
          >
            {templeteEmailData.isUpdating ? "Atualizar" : "Cadastrar"}
          </Button>
        </ModalFooter>
      </Modal>
    </Card>
  );
};

export default Editor;

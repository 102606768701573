import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Card,
  CardBody,
  FormInput,
} from "shards-react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import ButtonPictureUpload from "../common/ButtonPictureUpload";
import { BUCKET_URL } from "../../config";
import {
  uploadProjectTemplatePicture,
  getProjectTemplateById,
} from "../../actions/company";
import Loader from "react-loader-spinner";
import { toast } from "react-toastify";

const CompanyProjectTemplateOverride = ({ company }) => {
  const { id, projectTemplateId } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const [projectTemplate, setProjectTemplate] = useState({
    thumbnail: "",
    name: "",
  });

  const [thumbnailFile, setThumbnailFile] = useState();

  useEffect(() => {
    if (projectTemplateId) {
      setIsLoading(true);
      getProjectTemplateById(id, projectTemplateId)
        .then((res) => {
          setIsLoading(false);
          setProjectTemplate(res);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  }, []);

  function onSaveClick(ev) {
    ev.preventDefault();
    if (!thumbnailFile) {
      toast.success("Projeto atualizado com sucesso", {
        autoClose: 5000,
      });
    } else {
      setIsLoading(true);
      uploadProjectTemplatePicture(
        thumbnailFile,
        id,
        "thumbnail",
        "projectTemplateOverrides",
        projectTemplateId
      )
        .then((res) => {
          setIsLoading(false);
          toast.success("Projeto atualizado com sucesso", { autoClose: 5000 });
          console.log(res);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
          toast.error("Ocorreu um erro", { autoClose: 5000 });
        });
    }

    return false;
  }

  return (
    <Container fluid className="main-content-container px-4">
      <Row className="page-header py-4">
        <Col md="6">
          <button
            type="button"
            className="transparent"
            onClick={() => {
              history.goBack();
            }}
          >
            VOLTAR
          </button>
          <h3>Configurações de projeto | {company.name}</h3>
        </Col>
      </Row>

      <div>
        <Card>
          <CardBody>
            <Row noGutters className="h-100">
              <Col lg="8" md="12" sm="12" className=" mx-auto my-auto">
                {/* Logo */}
                {/* Title */}

                {/* Form Fields */}
                <Form onSubmit={onSaveClick}>
                  <Row form>
                    <Col md="6" className="form-group">
                      <div className="label">Logotipo</div>
                      <ButtonPictureUpload
                        onChange={(file, name) => {
                          setThumbnailFile(file);
                          setProjectTemplate({
                            ...projectTemplate,
                            thumbnail: name,
                          });
                        }}
                        isNew={false}
                        value={`${BUCKET_URL}/${projectTemplate.thumbnail}`}
                        placeholder="Envie seu logotipo"
                      />
                    </Col>
                    <Col md="6">
                      <div className="label">Nome</div>
                      <FormInput
                        type="text"
                        disabled={true}
                        value={projectTemplate.name}
                      />
                    </Col>
                  </Row>
                  <Row form>
                    <Col md="12" xs="12" sm="12" className="form-group">
                      <hr className="mt-4" />
                      <Button
                        size="lg"
                        theme="accent"
                        className="d-flex mx-auto mt-3"
                        type="submit"
                        disabled={isLoading}
                      >
                        {isLoading ? "Salvando..." : "Salvar"}
                        {isLoading && (
                          <Loader
                            width="30px"
                            height="15px"
                            type="Rings"
                            color="#ffffff"
                          />
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};
export default CompanyProjectTemplateOverride;

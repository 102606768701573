import React, { useState } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { Button } from "shards-react";
import Loader from "react-loader-spinner";
import Lightbox from "@ladecora/react-image-lightbox";
import { download } from "../../utils/util";

function AspectRatioPicture({
  src,
  thumbnail,
  height,
  width,
  alt,
  onDeleteClick,
  onImageView,
  isLoading,
  isUploading,
  isDeletable,
  viewPdf,
  slide,
  showSlide,
  hideAlt,
}) {
  const isPicture = alt.toLowerCase().match("(.jpeg|.png|.jpg|.jfif|.webp)");
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);

  return (
    <>
      {isLightboxOpen
        ? ReactDOM.createPortal(
            <Lightbox
              mainSrc={src}
              reactModalStyle={{ overlay: { zIndex: 10000 } }}
              onCloseRequest={() => {
                setIsLightboxOpen(false);
                onImageView(true);
              }}
            />,
            document.body
          )
        : null}

      <div
        style={{
          boxShadow:
            "0 2px 0 rgb(90 97 105 / 11%), 0 4px 8px rgb(90 97 105 / 12%), 0 10px 10px rgb(90 97 105 / 6%), 0 7px 70px rgb(90 97 105 / 10%)",
        }}
      >
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            borderTopLeftRadius: "0.625rem",
            borderTopRightRadius: "0.625rem",
            position: "relative",
            width: width,
            height: height,
            padding: "2px",
          }}
        >
          {isUploading ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                backgroundColor: "grey",
                opacity: "0.7",
              }}
            >
              <Loader width="60px" height="60px" type="Rings" color="black" />
            </div>
          ) : null}
          {isPicture ? (
            <button
              type="button"
              alt=""
              className="ml-auto mr-auto transparent"
              onClick={() => {
                onImageView(false);
                slide ? showSlide() : setIsLightboxOpen(true);
              }}
              style={{
                top: 0,
                backgroundImage: `url("${thumbnail || src}")`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundPosition: "center center",
                height: isPicture ? "100%" : "64px",
                width: isPicture ? "100%" : "64px",
              }}
            />
          ) : (
            <button
              type="button"
              alt=""
              className="ml-auto mr-auto transparent"
              onClick={() => {
                alt.toLowerCase().match("(.pdf)") ? viewPdf() : download(src);
              }}
              style={{
                top: 0,
                cursor: "pointer",
                backgroundImage: `url("${
                  alt.toLowerCase().match("(.pdf)")
                    ? require("../../images/pdf.png")
                    : require("../../images/file.png")
                }")`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                backgroundPosition: "center center",
                height: "64px",
                width: "64px",
              }}
            />
          )}
        </div>
        {!hideAlt && (
          <div style={{ textAlign: "center" }}>
            <span
              title={alt}
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "inline-block",
                maxWidth: "180px",
                width: width,
              }}
            >
              {alt}
            </span>
          </div>
        )}
        {isDeletable ? (
          <div className="p-2">
            <Button
              className="w-100"
              theme="danger"
              onClick={onDeleteClick}
              disabled={isLoading || isUploading}
              type="button"
            >
              Excluir
            </Button>
          </div>
        ) : null}
      </div>
    </>
  );
}

AspectRatioPicture.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  alt: PropTypes.string,
  src: PropTypes.string,
  isLoading: PropTypes.bool,
  isUploading: PropTypes.bool,
  isDeletable: PropTypes.bool,
  slide: PropTypes.bool,
  onDeleteClick: PropTypes.func,
  onImageView: PropTypes.func,
  viewPdf: PropTypes.func,
  showSlide: PropTypes.func,
};

AspectRatioPicture.defaultProps = {
  isDeletable: true,
  isLoading: false,
  isUploading: false,
  slide: false,
  onImageView: () => {},
  viewPdf: () => {},
  showSlide: () => {},
};

export default AspectRatioPicture;

import React from "react";
import { FormInput, Col, Row, Button } from "shards-react";
import { saveImage } from "../../../actions/projectTemplate";
import { BUCKET_URL } from "../../../config";
import { getFileExtension, isExtensionValid } from "../../../utils/util";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const MultipleQuestion = ({
  inputList,
  setInputList,
  projectHash,
  setLightbox,
}) => {
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;

    if (name === "image_path") {
      if (isExtensionValid(getFileExtension(e.target.files[0].name))) {
        toast.info("Fazendo upload da imagem!!!");
        saveImage(e.target.files[0], projectHash).then((data) => {
          const list = [...inputList];
          list[index][name] = data.key;
          setInputList(list);
        });
        toast.dismiss();
        toast.success("Upload feito com sucesso!!!", { autoClose: 5000 });
      } else {
        toast.error(
          "A extensão do arquivo não é permitido, Favor avisar a equipe de desenvolvimento.",
          { autoClose: 5000 }
        );
      }
    } else {
      const list = [...inputList];
      list[index][name] = value;
      setInputList(list);
    }
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleAddClick = () => {
    setInputList([...inputList, { label: "", image_path: "", action: "" }]);
  };

  return inputList.map((x, i) => {
    return (
      <div key={i}>
        <Col>
          <Row form>
            <Col md="10" className="form-group">
              <FormInput
                key={i}
                name="label"
                placeholder={"Insira a opção " + (i + 1).toString()}
                value={x.label}
                onChange={(e) => handleInputChange(e, i)}
              />
              {x.image_path && (
                <button
                  onClick={() =>
                    setLightbox({
                      img: BUCKET_URL + "/" + x.image_path,
                      open: true,
                    })
                  }
                  className="transparent"
                  type="button"
                >
                  <img
                    width="200"
                    src={BUCKET_URL + "/" + x.image_path}
                    alt=""
                  />
                </button>
              )}
            </Col>
            <Col md="1" className="form-group">
              {inputList.length !== 1 && (
                <Button
                  type="button"
                  className="mr10"
                  onClick={() => handleRemoveClick(i)}
                >
                  -
                </Button>
              )}
            </Col>
            <Col md="1" className="form-group">
              {inputList.length - 1 === i && (
                <Button onClick={handleAddClick}>+</Button>
              )}
            </Col>
            <Col md="12" className="form-group">
              {/* {inputList.length - 1 === i && ( */}
              <div className="custom-file mb-3">
                <input
                  type="file"
                  className="custom-file-input"
                  name="image_path"
                  onChange={(e) => handleInputChange(e, i)}
                />
                <label className="custom-file-label" htmlFor="customFile2">
                  {x.image_path
                    ? `Arquivo enviado: ` +
                      x.image_path.replace(projectHash + "/", "")
                    : "Selecione uma imagem"}
                </label>
              </div>
              {/* )} */}
            </Col>
            <Col md="12" className="form-group">
              <FormInput
                key={i}
                name="action"
                placeholder={"Insira o nº da questão"}
                value={x.action}
                onChange={(e) => handleInputChange(e, i)}
              />
              <hr />
            </Col>
          </Row>
        </Col>
      </div>
    );
  });
};

export default MultipleQuestion;

import React from "react";
import PropTypes from "prop-types";

import { FormInput } from "shards-react";

const PhoneBR = (props) => {
  const MASK = "(99) 99999-9999";
  const MAX_LENGTH = clear(MASK).length;

  const { onChange } = props;
  const value = applyMask(props.value, MASK);

  function onLocalChange(ev) {
    let value = clear(ev.target.value);

    let nextLength = value.length;

    if (nextLength > MAX_LENGTH) return;

    onChange(value);
  }

  function applyMask(value, mask) {
    let result = "";

    let inc = 0;
    Array.from(value).forEach((letter) => {
      let nextChars = "";
      while (!mask[inc].match(/[0-9]/)) {
        nextChars += mask[inc];
        inc++;
      }
      inc++;

      result += nextChars;
      result += letter;
    });
    return result;
  }

  function clear(value) {
    return value.replace(/[^0-9]/g, "");
  }

  return (
    <FormInput {...props} type="tel" value={value} onChange={onLocalChange} />
  );
};

PhoneBR.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default PhoneBR;

import React from "react";

import colors from "../../utils/colors";
import ConfirmationButton from "../common/ConfirmationButton";

const Setting = ({ name, onDeleteSetting, item }) => {
  function onDeleteCategoryClick() {
    onDeleteSetting(item);
  }

  return (
    <div className="mb-4">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
          }}
        >
          <strong>{name}</strong>
          <ConfirmationButton
            type="icon"
            className="material-icons mr-1 clickable"
            onClick={onDeleteCategoryClick}
            style={{
              marginLeft: "auto",
              paddingLeft: "5px",
              paddingRight: "5px",
              fontSize: "26px",
              marginBottom: "2px",
              color: colors.danger.value,
            }}
          >
            delete
          </ConfirmationButton>
        </div>
      </div>
    </div>
  );
};

export default Setting;

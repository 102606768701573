import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, Row, Card, Col, Button, CardBody } from "shards-react";

import { getAllMobiGroup, deleteMobiGroup } from "../actions/mobiGroup";
import SafeDeleteButton from "../components/common/SafeDeleteButton";

import FilteredTable from "../components/FilteredTable/index";

function FurnitureGroups() {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [furnitureGroups, setFurnitureGroups] = useState([]);

  const tableColumns = [
    {
      Header: "NOME",
      accessor: "name",
      className: "text-center",
    },
    {
      Header: "QTD. MOBI",
      accessor: "furnitures.length",
      className: "text-center",
      Cell: (row) =>
        row.original.furnitures.reduce((acc, current) => {
          acc += current.count;
          return acc;
        }, 0),
    },
    {
      Header: "AÇÕES",
      accessor: "actions",
      maxWidth: 150,
      sortable: false,
      Cell: (row) => (
        <div>
          <Button
            title="Alterar"
            theme="primary"
            outline
            onClick={() => {
              history.push(`/furniture-groups-edit?id=${row.original._id}`);
            }}
          >
            Detalhes
          </Button>
          <SafeDeleteButton
            onRemove={() => {
              return onDelete(row.original);
            }}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    refresh();
  }, []);

  function refresh() {
    return getAllMobiGroup()
      .then((res) => {
        setIsLoading(false);
        setFurnitureGroups(res);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }

  function onCreateNewGroup() {
    history.push("/furniture-groups/new");
  }

  function onDelete(group) {
    return deleteMobiGroup(group._id)
      .then((res) => {
        refresh();
        toast.success("Grupo deletado com sucesso", { autoClose: 5000 });
        return res;
      })
      .catch((err) => {
        toast.error("Ocorreu um erro, por favor tente novamente.", {
          autoClose: 5000,
        });
        console.log(err);
        throw err;
      });
  }

  return (
    <Container fluid className="main-content-container px-4">
      <Row className="page-header py-4">
        <Col md="6">
          <button
            type="button"
            className="transparent"
            onClick={() => {
              history.goBack();
            }}
          >
            VOLTAR
          </button>
          <h3>Grupos de produto</h3>
        </Col>
      </Row>

      <Button className="mb-2" onClick={onCreateNewGroup}>
        Criar novo grupo
      </Button>
      <Card>
        <CardBody>
          <FilteredTable
            isLoading={isLoading}
            columns={tableColumns}
            list={furnitureGroups}
            config={{
              pageSizeOptions: [5, 10, 15, 20, 30],
              searchableColumns: ["name"],
              pageSize: 10,
            }}
          />
        </CardBody>
      </Card>
    </Container>
  );
}

FurnitureGroups.propTypes = {};

export default FurnitureGroups;

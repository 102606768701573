import React, { useState, useEffect, useContext } from "react";

import {
  Container,
  Row,
  Card,
  Col,
  CardBody,
  CardHeader,
  Button,
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { dataConcierge } from "../actions/concierge";
import FilteredTable from "../components/FilteredTable/index";
import { CSVLink } from "react-csv";

import { MetadataContext } from "../components/metadata/MetadataContext";
import moment from "moment";

const Concierge = () => {
  const [start, end] = getInitialDate();
  const [date, setDate] = useState([start, end]);
  const [isLoading, setIsLoading] = useState(false);
  const { company } = useContext(MetadataContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    dataConcierge({ start: date[0], end: date[1], company }).then((resp) => {
      setData(resp.data);
      setIsLoading(false);
    });
  }, [date]);

  function getInitialDate() {
    const start = moment().subtract(30, "d").toDate();
    const end = moment().toDate();
    return [start, end];
  }

  const tableColumns = [
    {
      Header: "Nome",
      accessor: "name",
      className: "text-center",
      Cell: (row) => (
        <span
          style={{
            display: "inlineBlock",
            wordBreak: "breakAll",
            whiteSpace: "normal",
          }}
        >
          {row.original.name}
        </span>
      ),
    },
    {
      Header: "Data Entrega",
      accessor: "date",
      className: "text-center",
      maxWidth: 130,
      Cell: (row) => (
        <span
          style={{
            display: "inlineBlock",
            wordBreak: "breakAll",
            whiteSpace: "normal",
          }}
        >
          {row.original.final_project_date}
        </span>
      ),
    },
    {
      Header: "Ambiente",
      accessor: "ambiente",
      className: "text-center",
      Cell: (row) => (
        <span
          style={{
            display: "inlineBlock",
            wordBreak: "breakAll",
            whiteSpace: "normal",
          }}
        >
          {row.original.ambiente}
        </span>
      ),
    },
    {
      Header: "Telefone",
      accessor: "phone",
      className: "text-center",
      maxWidth: 130,
      Cell: (row) => (
        <span
          style={{
            display: "inlineBlock",
            wordBreak: "breakAll",
            whiteSpace: "normal",
          }}
        >
          {row.original.phone}
        </span>
      ),
    },
    {
      Header: "DDD",
      accessor: "ddd",
      className: "text-center",
      maxWidth: 40,
      Cell: (row) => (
        <span
          style={{
            display: "inlineBlock",
            wordBreak: "breakAll",
            whiteSpace: "normal",
          }}
        >
          {row.original.ddd}
        </span>
      ),
    },
    {
      Header: "Email",
      accessor: "email",
      className: "text-center",
      Cell: (row) => (
        <span
          style={{
            display: "inlineBlock",
            wordBreak: "breakAll",
            whiteSpace: "normal",
          }}
        >
          {row.original.email}
        </span>
      ),
    },
    {
      Header: "Company",
      accessor: "company",
      className: "text-center",
      Cell: (row) => (
        <span
          style={{
            display: "inlineBlock",
            wordBreak: "breakAll",
            whiteSpace: "normal",
          }}
        >
          {row.original.company}
        </span>
      ),
    },
    {
      Header: "Book",
      accessor: "book",
      maxWidth: 60,
      Cell: (row) => (
        <>
          <Button
            size="sm"
            outline
            onClick={() => {
              window.open(row.original.book);
            }}
          >
            <i className="material-icons">&#xe86d;</i>
          </Button>
        </>
      ),
    },
  ];

  return (
    <Container fluid className="main-content-container px-4">
      <Row className="page-header py-4">
        <Col md="8">
          <PageTitle
            title="Concierge"
            subtitle="Visão Geral"
            className="text-sm-left mb-3"
          />
        </Col>
      </Row>
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <div>Data</div>
              <div className="d-flex flex-row justify-content-between">
                <DateRangePicker
                  required
                  rangeDivider=" até "
                  onChange={(value) => {
                    console.log(value);
                    if (!value) {
                      value = getInitialDate();
                    }
                    setDate(value);
                  }}
                  maxDate={new Date()}
                  format="dd/MM/y"
                  value={date}
                />
              </div>
            </Col>
            <Col className="text-right">
              <CSVLink data={data} separator=";" filename={"concierge.csv"}>
                <Button theme="accent" className="btn-outline-primary">
                  Exportar CSV
                </Button>
              </CSVLink>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <FilteredTable
            list={data}
            columns={tableColumns}
            isLoading={isLoading}
            customFilters={[]}
            config={{
              pageSizeOptions: [5, 10, 15, 20, 30],
              searchableColumns: ["name", "company"],
              pageSize: 10,
            }}
          />
        </CardBody>
      </Card>
    </Container>
  );
};
export default Concierge;

import React, { useState, useContext } from "react";

import { ModalHeader, ModalBody, ModalFooter, Button } from "shards-react";
import { PageContext } from "./PageContext";
import PageItem from "./PageItem";
import AddTextItem from "./AddTextItem";
import DefaultPageItems from "./DefaultPageItems";

import { toast } from "react-toastify";
import IfAllowed from "../../common/IfAllowed";

const RenderPageContent = ({ name, onSave }) => {
  const {
    settings,
    customSettings,
    customCategorySettings,
    addCustomCategorySetting,
    addCustomCategory,
    addCustomSetting,
    deleteCustomSetting,
    deleteCustomCategorySetting,
    deleteCustomCategory,
    toggleSetting,
    toggleSettingsByCategory,
  } = useContext(PageContext);

  const [isSaving, setIsSaving] = useState(false);
  const [count, setCount] = useState(customCategorySettings.length);

  function onSaveClick() {
    setIsSaving(true);
    let itemsToSave = settings
      .filter((setting) => setting.enabled)
      .map((setting) => setting._id);

    let newCustomCategorySettings = customCategorySettings.map(
      (categorySetting) => {
        delete categorySetting.isNew;
        return categorySetting;
      }
    );

    onSave(itemsToSave, newCustomCategorySettings)
      .then(() => {
        setIsSaving(false);
        toast.success("Configurações salvas com sucesso.", {
          autoClose: 2000,
        });
      })
      .catch(() => {
        toast.error(
          "Ocorreu um erro desconhecido, por favor, tente novamente.",
          {
            autoClose: 2000,
          }
        );
        setIsSaving(false);
      });
  }

  function getCustomSettingsByCategory(category) {
    return customSettings
      .map((setting, index) => {
        setting = JSON.parse(JSON.stringify(setting));
        setting.id = index;
        return setting;
      })
      .filter((item) => item.category === category);
  }

  return (
    <>
      <ModalHeader>{name}</ModalHeader>
      <ModalBody style={{ maxHeight: "450px", overflow: "auto" }}>
        <DefaultPageItems
          items={settings}
          onAddCustomSetting={addCustomSetting}
          onDeleteCustomSetting={deleteCustomSetting}
          onToggleSetting={toggleSetting}
          onToggleByCategory={toggleSettingsByCategory}
        />

        {!!customCategorySettings.length &&
          customCategorySettings.map((setting, i) => {
            return (
              <PageItem
                key={i}
                item={setting}
                onAddCustomSetting={addCustomCategorySetting}
                onDeleteCustomSetting={deleteCustomCategorySetting}
                onDeleteCategory={deleteCustomCategory}
                onToggleSetting={toggleSetting}
                onToggleByCategory={toggleSettingsByCategory}
                name={setting.text}
                id={setting.id}
                enabled={setting.enabled}
                items={setting.items}
                customSettings={getCustomSettingsByCategory(setting.name)}
              />
            );
          })}
        <IfAllowed resource="admin-ladecora:project" perform="update:any">
          <AddTextItem
            onAdd={(value) => {
              setCount(count + 1);
              addCustomCategory(
                {
                  text: value,
                  type: "text",
                  id: count,
                },
                true
              );
            }}
            placeholder="Digite o texto da categoria aqui..."
          />
        </IfAllowed>
      </ModalBody>
      <ModalFooter>
        <IfAllowed resource="admin-ladecora:project" perform="update:any">
          <Button disabled={isSaving} theme="primary" onClick={onSaveClick}>
            {isSaving ? "Salvando..." : "Salvar"}
          </Button>
        </IfAllowed>
      </ModalFooter>
    </>
  );
};

export default RenderPageContent;

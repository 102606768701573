import React, { useEffect, useState, useContext } from "react";

import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ButtonGroup,
} from "shards-react";

import Moment from "react-moment";

import { MetadataContext } from "../metadata/MetadataContext";
import FilteredTable from "../FilteredTable/index";

import { searchProjectsWithMobi } from "../../actions/mobi";

const ProjectsWithMobi = ({
  showModalMobiProject,
  setShowModalMobiProject,
  mobiToSearch,
}) => {
  const { metadata } = useContext(MetadataContext);
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    console.log(mobiToSearch);
    searchProjectsWithMobi(mobiToSearch._id).then((res) => {
      setProjects(res);
      setLoading(false);
    });
  }, [mobiToSearch]);

  const tableColumns = [
    {
      Header: "Nome",
      accessor: "name",
      className: "text-center",
      Cell: (row) => <span>{row.original.name}</span>,
    },
    {
      Header: "Ambiente",
      accessor: "name",
      className: "text-center",
      Cell: (row) => <span>{row.original.questionTemplate.name}</span>,
    },
    {
      Header: "Usuário",
      accessor: "user",
      className: "text-center",
      Cell: (row) => (
        <button
          className="transparent"
          type="button"
          onClick={() => history.push(`/user?id=${row.original.user._id}`)}
        >
          {`${row.original.user.profile.firstName} ${row.original.user.profile.lastName}`}
        </button>
      ),
    },
    {
      Header: "Criado",
      accessor: "createdAt",
      maxWidth: 130,
      className: "text-center",
      Cell: (row) => {
        return (
          <span className="d-flex flex-column">
            <Moment format="DD/MM/YYYY">{row.original.createdAt}</Moment>
            <Moment format="HH:mm">{row.original.createdAt}</Moment>
          </span>
        );
      },
    },
    {
      Header: "Último Update",
      accessor: "updatedAt",
      maxWidth: 130,
      className: "text-center",
      Cell: (row) => {
        return (
          <span className="d-flex flex-column">
            <Moment format="DD/MM/YYYY">{row.original.updatedAt}</Moment>
            <Moment format="HH:mm">{row.original.updatedAt}</Moment>
          </span>
        );
      },
    },
    {
      Header: "Contexto",
      accessor: "company",
      maxWidth: 80,
      className: "text-center",
      Cell: (row) => {
        let value = row.original.company;
        if (value) {
          let meta = metadata.companies.find(
            (company) => company.label === value
          );
          console.log(meta);
          return meta ? meta?.name : "Company Excluido";
        } else {
          return "Nenhum";
        }
      },
    },
    {
      Header: "Ações",
      accessor: "actions",
      sortable: false,
      maxWidth: 90,
      Cell: (row) => (
        <>
          <ButtonGroup size="sm" className="d-table mx-auto">
            <Button
              outline
              theme={"dark"}
              onClick={() => {
                window.open("/project/" + row.original._id);
              }}
            >
              <i className="material-icons">&#xe86d;</i>
            </Button>
          </ButtonGroup>
        </>
      ),
    },
  ];

  return (
    <Modal
      size="lg"
      open={showModalMobiProject}
      toggle={() => setShowModalMobiProject(false)}
    >
      <ModalHeader toggle={() => setShowModalMobiProject(false)}>
        Projetos com o produto: {mobiToSearch.name}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col className="text-center">
            <FilteredTable
              isLoading={loading}
              columns={tableColumns}
              list={projects}
              config={{
                pageSizeOptions: [5, 10, 15, 20, 30],
                searchableColumns: ["name"],
                pageSize: 10,
              }}
            />
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};
export default ProjectsWithMobi;

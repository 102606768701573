import { API } from "../config";
import { getCookie } from "./auth";
import { getCompanyUrlParams } from "../utils/sanitize";
import { requestWrapper } from "../utils/api";
export const updateProjectTemplate = (projectTemplateId, data) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project-template/${projectTemplateId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify(data),
    })
  );
};

export const createProjectTemplate = (projectTemplate) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/project-template`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify(projectTemplate),
    })
  );
};

export const saveImage = (file, projectHash) => {
  const token = getCookie("token");

  const formData = new FormData();
  if (file) {
    formData.append("file", file);
  }

  return requestWrapper(
    fetch(`${API}/question/save-image/${projectHash}`, {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const listProjectTemplates = (params) => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(`${API}/question/list${getCompanyUrlParams(params)}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const getProjectTemplateNamesByCompany = (params) => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(`${API}/project-template/list${getCompanyUrlParams(params)}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const getProjectTemplateById = (projectTemplateId) => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(`${API}/question/${projectTemplateId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

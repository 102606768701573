import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Card,
  Col,
  CardBody,
  Button,
  ButtonGroup,
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { listProjectTemplates } from "../actions/projectTemplate";

import { MetadataContext } from "../components/metadata/MetadataContext";

import Moment from "react-moment";

import FilteredTable from "../components/FilteredTable/index";

const ProjectTemplates = () => {
  const [listQuestions, setListQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const { scopedByCompany, metadata } = useContext(MetadataContext);
  const listProjectTemplatesBySelectedCompany =
    scopedByCompany(listProjectTemplates);

  const tableColumns = [
    {
      Header: "Nome",
      accessor: "name",
      className: "text-center",
      Cell: (row) => <span>{row.original.name}</span>,
    },
    {
      Header: "Status",
      accessor: "status",
      className: "text-center",
      Cell: (row) => <span>{getStatus(row.original.status)}</span>,
    },
    {
      Header: "Preço",
      accessor: "price",
      className: "text-center",
      Cell: (row) => (
        <span>
          {row.original.price.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          })}
        </span>
      ),
    },
    {
      Header: "Último update",
      accessor: "updatedAt",
      maxWidth: 130,
      className: "text-center",
      Cell: (row) => (
        <span>
          <Moment format="DD/MM/YYYY HH:MM">{row.original.updatedAt}</Moment>
        </span>
      ),
    },
    {
      Header: "Contexto",
      accessor: "context",
      maxWidth: 130,
      className: "text-center",
      Cell: (row) => (
        <span>
          {
            metadata.companies.find(
              (company) => company.label === row.original.company
            ).name
          }
        </span>
      ),
    },
    {
      Header: "AÇÕES",
      accessor: "actions",
      maxWidth: 150,
      sortable: false,
      Cell: (row) => (
        <ButtonGroup size="sm" className="mx-auto">
          <Button
            onClick={() =>
              history.push(`/project-template?id=${row.original._id}`)
            }
            outline
          >
            Editar
          </Button>
        </ButtonGroup>
      ),
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    listProjectTemplatesBySelectedCompany()
      .then((data) => {
        setIsLoading(false);
        setListQuestions(data.questions);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  function onNewProjectTemplateClick() {
    history.push("/project-template");
  }

  function getStatus(status) {
    switch (status) {
      case "enabled":
        return "Ativo";
      case "disabled":
        return "Inativo";
      case "test":
        return "Teste";
      case "perfil":
        return "Questionário Perfil";
      default:
        return "Desconhecido";
    }
  }

  return (
    <Container fluid className="main-content-container px-4">
      <Row className="page-header py-4">
        <Col md="6">
          <PageTitle
            title="Formulários"
            subtitle="Visão Geral"
            className="text-sm-left mb-3"
          />
        </Col>
        <Col md="5" className="col d-flex align-items-center" />
      </Row>
      <Card>
        <CardBody>
          <Button outline onClick={onNewProjectTemplateClick}>
            Criar Novo
          </Button>
          <FilteredTable
            isLoading={isLoading}
            columns={tableColumns}
            list={listQuestions}
            config={{
              pageSizeOptions: [5, 10, 15, 20, 30],
              searchableColumns: ["name"],
              pageSize: 10,
            }}
          />
        </CardBody>
      </Card>
    </Container>
  );
};
export default ProjectTemplates;

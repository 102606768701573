import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Row, Card, Col, CardBody, Button } from "shards-react";
import Moment from "react-moment";
import {
  translateStatus,
  translateApprovedStatus,
} from "../../services/project.js";
import { MetadataContext } from "../metadata/MetadataContext";
import SafeDeleteButton from "../common/SafeDeleteButton";
import ModalProjectName from "./ModalProjectName.js";

import IfAllowed from "../common/IfAllowed";
import {
  archiveProject,
  updateProjectName,
  createDropboxFolder,
} from "../../actions/projects";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ProjectData } from "./data";
import Loader from "react-loader-spinner";

const TrelloLink = ({ link }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="ml-2"
      title="Abrir no trello"
    >
      <img alt="" width="25" src={require("../../images/trello.png")} />
    </a>
  );
};

const DropBoxLink = ({ folder }) => {
  return (
    <a
      href={`https://www.dropbox.com/home/plantas/${folder}`}
      target="_blank"
      rel="noopener noreferrer"
      className="ml-2"
      title="Abrir no DropBox"
    >
      <img alt="" width="25" src={require("../../images/dropbox.png")} />
    </a>
  );
};

const ProjectInfo = ({ projectData, reload }) => {
  const { projectInfo } = projectData;
  const { finalProject } = projectInfo;
  const history = useHistory();
  const { metadata } = useContext(MetadataContext);
  const [isDropboxCreationLoading, setIsDropboxCreationLoading] =
    useState(false);

  const companyData = metadata.companies.find(
    (company) => company.label === projectInfo.company
  );

  function onDeleteButtonClick() {
    return archiveProject(projectInfo.id)
      .then(() => {
        history.replace("/projects");
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }
  const editNameProject = (newValue) => {
    updateProjectName(projectInfo.id, newValue);
    if (newValue !== projectInfo.title) {
      return updateProjectName(projectInfo.id, newValue)
        .then(reload())
        .catch((err) => {
          toast.error(err.message);
        });
    }
  };

  function onCreateDropboxFolderClick() {
    setIsDropboxCreationLoading(true);
    createDropboxFolder(projectInfo.id)
      .then(() => {
        setIsDropboxCreationLoading(false);
        toast.success("Pasta criada com sucesso!");
        reload();
      })
      .catch((err) => {
        setIsDropboxCreationLoading(false);
        console.log(err);
      });
  }

  return (
    <Card
      small
      className="mb-4"
      style={{
        boxShadow: companyData?.color
          ? `3px 3px 3px 5px ${companyData.color}`
          : "",
      }}
    >
      <CardBody className="p-0">
        <Row>
          <Col>
            <h6 className="text-center m-0 mt-2">Projeto</h6>
            <h4 className="text-center m-0 mt-2">
              {projectInfo.title}
              <IfAllowed resource="admin-ladecora:project:trello">
                <ModalProjectName
                  outline
                  size="sm"
                  projectName={projectInfo.title}
                  theme="light"
                  onClick={editNameProject}
                  style={{ padding: "0px", border: "none" }}
                />
                {projectInfo.trelloCardUrl ? (
                  <TrelloLink link={projectInfo.trelloCardUrl} />
                ) : (
                  ""
                )}
                {projectInfo.dropBoxFolder ? (
                  <DropBoxLink folder={projectInfo.dropBoxFolder} />
                ) : (
                  ""
                )}
              </IfAllowed>
              <IfAllowed resource="admin-ladecora:project:actions:delete">
                <SafeDeleteButton
                  className="ml-4"
                  onRemove={onDeleteButtonClick}
                />
              </IfAllowed>
              <IfAllowed resource="admin-ladecora:project:trello">
                {!projectInfo.dropBoxFolder &&
                projectInfo.status !== "enabled" &&
                projectInfo.status !== "submitted" &&
                projectInfo.status !== "final_project" ? (
                  <Button
                    size="sm"
                    outline
                    className="ml-4"
                    onClick={onCreateDropboxFolderClick}
                    disabled={isDropboxCreationLoading}
                  >
                    {isDropboxCreationLoading ? (
                      <Loader
                        className="d-inline"
                        width="15px"
                        height="15px"
                        type="Rings"
                        color="black"
                      />
                    ) : (
                      <img
                        alt=""
                        width="20"
                        className="mr-1"
                        src={require("../../images/dropbox.png")}
                      />
                    )}
                    Criar Dropbpox
                  </Button>
                ) : (
                  ""
                )}
              </IfAllowed>
            </h4>
          </Col>
        </Row>
        <IfAllowed resource="admin-ladecora:project:info">
          <div className="user-details__user-data border-top border-bottom p-4">
            <Row>
              <Col>
                <span>Contexto</span>
                <div style={{ maxWidth: "120px" }}>
                  <div className="edit-user-details__avatar mx-auto">
                    {companyData?.logo ? (
                      <img
                        width="120"
                        src={companyData.logo}
                        alt={companyData.name}
                      />
                    ) : null}
                  </div>
                </div>
                <span className="d-flex flex-column justify-content-center">
                  {companyData ? companyData.name : ""}
                </span>
              </Col>
              <Col>
                <span>Projeto criado</span>
                <span className="d-flex flex-column justify-content-center h-100">
                  <Moment format="DD/MM/YYYY HH:mm">
                    {projectInfo.createdAt}
                  </Moment>
                </span>
              </Col>
              <Col>
                <span>Último update</span>
                <span className="d-flex flex-column justify-content-center h-100">
                  <Moment format="DD/MM/YYYY HH:mm">
                    {projectInfo.updatedAt}
                  </Moment>
                </span>
              </Col>
              <Col>
                <span>Status</span>
                <span
                  className="d-flex flex-column justify-content-center h-100"
                  id={projectInfo.status}
                >
                  {translateStatus(projectInfo.status, projectInfo.forcedStep)}
                </span>
              </Col>
            </Row>

            <Row className={"mt-4"}>
              {projectInfo && projectInfo.previewData && (
                <Col>
                  <span>Proposta Inicial</span>
                  <span className="d-flex flex-column justify-content-center h-100">
                    <Moment format="DD/MM/YYYY HH:mm">
                      {projectInfo.previewData}
                    </Moment>
                  </span>
                </Col>
              )}
              {projectInfo && projectInfo.firstReviewDate && (
                <Col>
                  <span>1º Revisão</span>
                  <span className="d-flex flex-column justify-content-center h-100">
                    <Moment format="DD/MM/YYYY HH:mm">
                      {projectInfo.firstReviewDate}
                    </Moment>
                  </span>
                </Col>
              )}{" "}
              {projectInfo && projectInfo.secondReviewDate && (
                <Col>
                  <span>2º Revisão</span>
                  <span className="d-flex flex-column justify-content-center h-100">
                    <Moment format="DD/MM/YYYY HH:mm">
                      {projectInfo.secondReviewDate}
                    </Moment>
                  </span>
                </Col>
              )}
              {finalProject && finalProject.approvedProject && (
                <Col>
                  <span>Projeto Aprovado</span>
                  <span className="d-flex flex-column justify-content-center h-100">
                    {translateApprovedStatus(finalProject.approvedProject)}
                  </span>
                </Col>
              )}
            </Row>
            <IfAllowed resource="admin-ladecora:project:trello">
              <Row className={"mt-4"}>
                <Col>
                  <span>Lista Trello</span>
                  <span className="d-flex flex-column justify-content-center h-100">
                    {projectInfo.trelloCardListName}
                  </span>
                </Col>
              </Row>
            </IfAllowed>
          </div>
        </IfAllowed>
      </CardBody>
    </Card>
  );
};

ProjectInfo.propTypes = {
  projectData: PropTypes.instanceOf(ProjectData),
};

export default ProjectInfo;

import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Card,
  Col,
  Button,
  Form,
  FormSelect,
  FormRadio,
  FormCheckbox,
  FormInput,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import SortableTree, {
  changeNodeAtPath,
  insertNode,
  removeNodeAtPath,
} from "react-sortable-tree";
import "react-toastify/dist/ReactToastify.min.css";
import "react-sortable-tree/style.css";
import { useParams } from "react-router-dom";
import AspectRatioPicture from "../components/common/AspectRatioPicture";
import {
  getEnterpriseById,
  salveEnterpriseProfiles,
  getUrlEnterpriseFile,
  deleteEnterpriseProfilesImg,
} from "../actions/enterprise";
import { sanitizeString } from "../utils/sanitize";
import ConfirmationButton from "../components/common/ConfirmationButton";
import { toast } from "react-toastify";

const EnterpriseQuestions = () => {
  const { id } = useParams();
  const [enterprise, setEnterprise] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showCheckedListData, setShowCheckedListData] = useState(false);
  const [idTree, setIdTree] = useState("");
  const [idProfile, setIdProfile] = useState("");
  const [checkedList, setCheckedList] = useState();
  const [states, setStates] = useState({
    searchString: "",
    searchFocusIndex: 0,
    currentNode: {},
    name: "",
    kinda: "",
    img: "",
    treeData: [],
  });
  const [addingProfile, setAddingProfile] = useState({
    show: false,
    name: "",
    img: "",
  });
  const [managingProfile, setManagingProfile] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  useEffect(() => {
    getEnterpriseById(id).then((res) => {
      setEnterprise(res);
      const checkedListTemp = settingCheckedList(res);
      setCheckedList(checkedListTemp);
    });
  }, []);
  const { searchString, searchFocusIndex, treeData } = states;

  const settingCheckedList = (data) => {
    data = JSON.parse(JSON.stringify(data));
    const checkedListTemp = [];
    data?.profiles?.map((elem, idx) => {
      checkedListTemp.push({
        idx: idx,
        name: elem.name,
        img: elem.img,
        checked: false,
      });
    });

    return checkedListTemp;
  };

  const handleCheckedList = (idx) => {
    setIdProfile(idx);
    setShowDetails(false);
    const checkedListTemp = checkedList.slice();
    const prevstate = checkedListTemp[idx].checked;
    checkedListTemp.map((elem) => {
      elem.checked = false;
    });
    checkedListTemp[idx].checked = !prevstate;
    setShowCheckedListData(checkedListTemp[idx].checked);

    setCheckedList(checkedListTemp);

    const enterpriseData = enterprise.profiles.slice();
    setStates({
      ...states,
      searchString: "",
      name: enterpriseData[idx].name,
      kinda: enterpriseData[idx].kinda,
      img: enterpriseData[idx].img,
      treeData: enterpriseData[idx].questions,
    });
  };

  const updateTreeData = (treeData) => {
    setStates({ ...states, treeData });
    const newProfiles = enterprise.profiles.slice();
    newProfiles[idProfile].questions = treeData;
    setEnterprise({ ...enterprise, profiles: newProfiles });
  };

  const removeNode = (path) => {
    const deleteImgsTree = JSON.parse(JSON.stringify(states.treeData[path[0]]));
    setShowDetails(false);

    let newTreeData;
    try {
      newTreeData = removeNodeAtPath({
        treeData: states.treeData,
        path,
        getNodeKey: ({ treeIndex }) => treeIndex,
      });
    } finally {
      setStates((states) => ({
        ...states,
        treeData: newTreeData,
      }));

      const newProfiles = enterprise.profiles.slice();
      newProfiles[idProfile].questions.splice(path[0], 1);
      setEnterprise({ ...enterprise, profiles: newProfiles });

      let imgsToDelete = [];
      deleteImgsTree.input_list.forEach((elem) => {
        elem.image_path.length > 0 && imgsToDelete.push(elem.image_path);
      });

      if (imgsToDelete.length > 0) {
        imgsToDelete.forEach((elem) => {
          deleteEnterpriseProfilesImg(elem);
        });
      }
    }
  };

  const selectThis = (node, path) => {
    setStates({ ...states, currentNode: node, path: path });
  };

  const insertNewNode = () => {
    const newProfiles = enterprise.profiles.slice();
    const newInputTreeData = {
      title: "",
      input_list: [
        {
          label: "",
          image_path: "",
          score: { kinda: "", name: "", value: "" },
          withScore: false,
        },
      ],
    };

    let newTreeData;
    try {
      newTreeData = insertNode({
        treeData: states.treeData,
        depth: 0,
        minimumTreeIndex: states.treeData.length,
        newNode: newInputTreeData,
        getNodeKey: ({ treeIndex }) => treeIndex,
      }).treeData;
    } finally {
      newProfiles[idProfile].questions = [
        ...newProfiles[idProfile].questions,
        newInputTreeData,
      ];

      setStates((states) => ({
        ...states,
        treeData: newTreeData,
      }));
      setEnterprise((enterprise) => ({ ...enterprise, profiles: newProfiles }));
    }
  };

  const getNodeKey = ({ treeIndex }) => treeIndex;

  const handleconfig = (param) => {
    setIdTree(param[0]);
    setShowDetails(true);
    document.querySelector("#inputQuestion")?.scroll(0, 0);
  };

  const handleChange = (name, idInputList) => (e) => {
    const newTreeDataInput_list = states.treeData.slice();
    const newProfiles = enterprise.profiles.slice();
    if (name === "image_path") {
      setIsLoading(true);
      getUrlEnterpriseFile(id, e.target.files[0]).then((res) => {
        setIsLoading(false);
        newTreeDataInput_list[idTree].input_list[idInputList] = {
          ...newTreeDataInput_list[idTree].input_list[idInputList],
          [name]: res,
        };
        newProfiles[idProfile].questions = newTreeDataInput_list;
        setStates({ ...states, treeData: newTreeDataInput_list });
        setEnterprise({ ...enterprise, profiles: newProfiles });
        toast.success("upload feito com sucesso!");
      });
    } else {
      if (name === "withScore") {
        newTreeDataInput_list[idTree].input_list[idInputList] = {
          ...newTreeDataInput_list[idTree].input_list[idInputList],
          withScore:
            !newTreeDataInput_list[idTree].input_list[idInputList].withScore,
        };
      }
      if (name === "label") {
        newTreeDataInput_list[idTree].input_list[idInputList] = {
          ...newTreeDataInput_list[idTree].input_list[idInputList],
          [name]: e.target.value,
        };
      }

      if (name === "value" || name === "name") {
        newTreeDataInput_list[idTree].input_list[idInputList].score = {
          ...newTreeDataInput_list[idTree].input_list[idInputList].score,
          [name]: e.target.value,
        };
      }
      setStates({ ...states, treeData: newTreeDataInput_list });
      newProfiles[idProfile].questions = newTreeDataInput_list;
      setEnterprise({ ...enterprise, profiles: newProfiles });
    }
  };

  const createInputList = () => {
    const newInput = {
      label: "",
      image_path: "",
      score: { kinda: "", name: "", value: "" },
    };

    const newTreeDataInput_list = treeData.slice();
    const newProfiles = enterprise.profiles.slice();

    newTreeDataInput_list[idTree].input_list = [
      ...newTreeDataInput_list[idTree].input_list,
      newInput,
    ];

    newProfiles[idProfile].questions = newTreeDataInput_list;

    setStates((states) => ({ ...states, treeData: newTreeDataInput_list }));
    setEnterprise((enterprise) => ({ ...enterprise, profiles: newProfiles }));
  };

  const removeInputList = (idInputList) => {
    const newTreeDataInput_list = states.treeData.slice();
    const inputDeleted = newTreeDataInput_list[idTree].input_list.splice(
      idInputList,
      1
    );
    const { image_path } = inputDeleted[0];
    setStates((states) => ({ ...states, treeData: newTreeDataInput_list }));
    image_path.length > 0 && deleteEnterpriseProfilesImg(image_path);
  };

  const handleDeleteImage = (idInputList) => {
    const newTreeDataInput_list = states.treeData.slice();
    const newProfiles = enterprise.profiles.slice();

    const pathImg =
      newTreeDataInput_list[idTree].input_list[idInputList].image_path;
    pathImg.length > 0 &&
      deleteEnterpriseProfilesImg(pathImg).then(() => {
        newTreeDataInput_list[idTree].input_list[idInputList] = {
          ...newTreeDataInput_list[idTree].input_list[idInputList],
          image_path: "",
        };

        newProfiles[idProfile].questions[idTree].input_list[idInputList] = {
          ...newProfiles[idProfile].questions[idTree].input_list[idInputList],
          image_path: "",
        };

        setStates({ ...states, treeData: newTreeDataInput_list });
        setEnterprise({ ...enterprise, profiles: newProfiles });
      });
  };

  const saveEnterPrise = (msg, profiles) => {
    setIsLoading(true);
    salveEnterpriseProfiles(id, profiles).then(() => {
      setIsLoading(false);
      msg.length > 0 && toast.success(msg);
    });
  };

  const handleProfile = (name, idx) => (e) => {
    if (idx >= 0) {
      const checkedListTemp = checkedList.slice();
      if (name === "name") {
        checkedListTemp[idx][name] = e.target.value;
        checkedListTemp[idx].kinda = sanitizeString(e.target.value);
        setCheckedList(checkedListTemp);
      } else {
        setIsLoading(true);
        getUrlEnterpriseFile(id, e.target.files[0]).then((res) => {
          setIsLoading(false);
          checkedListTemp[idx][name] = res;
          setCheckedList(checkedListTemp);
          toast.success("upload feito com sucesso!");
        });
      }
    } else {
      const newProfile = JSON.parse(JSON.stringify(addingProfile));

      if (name === "img") {
        setIsLoading(true);
        getUrlEnterpriseFile(id, e.target.files[0]).then((res) => {
          setIsLoading(false);
          newProfile[name] = res;
          setAddingProfile(newProfile);
          toast.success("upload feito com sucesso!");
        });
      } else {
        if (name === "name") {
          newProfile.kinda = sanitizeString(e.target.value);
        }
        newProfile[name] = e.target.value;
        setAddingProfile(newProfile);
      }
    }
  };

  const addingMoreProfile = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const newChekedList = {
      idx: checkedList.length,
      name: addingProfile.name,
      kinda: addingProfile.kinda,
      img: addingProfile.img,
      checked: false,
    };

    const newProfile = {
      name: addingProfile.name,
      kinda: addingProfile.kinda,
      img: addingProfile.img,
      questions: [],
    };

    setCheckedList([...checkedList, newChekedList]);
    setEnterprise({
      ...enterprise,
      profiles: [...enterprise.profiles, newProfile],
    });

    resetAddingProfile();

    toast.success("Perfil cadastrado!");
  };

  const closingCheckdData = () => {
    const checkedListTemp = checkedList.slice();
    checkedListTemp.map((elem) => {
      elem.checked = false;
    });
    setCheckedList(checkedListTemp);
    setShowCheckedListData(false);
  };

  const removeCheckedList = (idx) => {
    const checkedListTemp = checkedList.slice();
    const tempProfiles = enterprise.profiles.slice();

    checkedListTemp.splice(idx, 1);
    const profilesDeleted = tempProfiles.splice(idx, 1);
    let imgsToDelete = [];

    profilesDeleted[0]?.questions?.forEach((elem) => {
      elem?.input_list.forEach((item) => {
        item?.image_path.length > 0 && imgsToDelete.push(item.image_path);
      });
    });
    profilesDeleted[0]?.img.length > 0 &&
      imgsToDelete.push(profilesDeleted[0].img);

    imgsToDelete.length > 0 &&
      imgsToDelete.forEach((elem) => {
        deleteEnterpriseProfilesImg(elem);
      });

    setCheckedList(checkedListTemp);
    setEnterprise({
      ...enterprise,
      profiles: tempProfiles,
    });

    saveEnterPrise("", tempProfiles);
  };
  const resetAddingProfile = () => {
    setAddingProfile({
      name: "",
      kinda: "",
      img: "",
      show: false,
    });
  };

  const resetManagingProfile = () => {
    const tempCheckedList = settingCheckedList(enterprise);
    const imgToDelete = [];

    tempCheckedList.forEach((elem, idx) => {
      checkedList.forEach((item, id) => {
        if (idx === id) {
          tempCheckedList[idx].img !== item.img && imgToDelete.push(item.img);
        }
      });
    });

    setCheckedList(tempCheckedList);
    setManagingProfile(!managingProfile);

    imgToDelete.length > 0 &&
      imgToDelete.forEach((elem) => {
        elem.length > 0 && deleteEnterpriseProfilesImg(elem);
      });
  };

  const updateCheckedList = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const imgToDelite = [];
    const newProfile = enterprise.profiles.slice();
    newProfile.map((elem, idx) => {
      checkedList.map((item, id) => {
        if (item.idx === idx) {
          imgToDelite.push(
            newProfile[idx].img !== checkedList[id].img && newProfile[idx].img
          );
          newProfile[idx].name = checkedList[id].name;
          newProfile[idx].kinda = checkedList[id].kinda;
          newProfile[idx].img = checkedList[id].img;
        }
      });
    });

    imgToDelite.length > 0 &&
      imgToDelite.forEach((elem) => {
        elem.length > 0 && deleteEnterpriseProfilesImg(elem);
      });

    setEnterprise({ ...enterprise, profiles: newProfile });
    setManagingProfile(!managingProfile);
    saveEnterPrise("Atualizado com sucesso!", newProfile);
  };

  const onContinueClick = () => {
    setDeleteConfirmation(false);
    removeNode([idTree]);
  };

  return (
    <Container fluid className="main-content-container px-4">
      <Row className="page-header py-4">
        <Col md="8">
          <PageTitle
            title="Empreendimento"
            subtitle="Questionário"
            className="text-sm-left mb-3"
          />
        </Col>
      </Row>
      <Modal
        size="md"
        modalContentClassName="card card-small"
        open={deleteConfirmation}
        toggle={() => {}}
      >
        <ModalHeader
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Tem certeza que deseja continuar?
        </ModalHeader>
        <ModalFooter>
          <Button onClick={() => setDeleteConfirmation(false)} theme="danger">
            Cancelar
          </Button>
          <Button
            onClick={onContinueClick}
            theme="success"
            className="ml-1"
            id="confirmation-button-continue"
          >
            Continuar
          </Button>
        </ModalFooter>
      </Modal>
      <Modal size="lg" open={managingProfile} toggle={() => {}}>
        <ModalHeader
          toggle={() => {
            resetManagingProfile();
          }}
        >
          Lista de Perfis
        </ModalHeader>
        <ModalBody style={{ maxHeight: "495px", overflow: "auto" }}>
          <Form onSubmit={updateCheckedList}>
            {checkedList?.map((elem, idx) => (
              <div
                className="d-flex justify-content-between align-items-center mb-3"
                key={idx}
              >
                <div>
                  <FormInput
                    required
                    type="text"
                    placeholder="nome do perfil"
                    disabled={isLoading}
                    value={elem?.name}
                    onChange={handleProfile("name", idx)}
                  />
                </div>

                <div>
                  <FormInput
                    type="file"
                    disabled={isLoading}
                    onChange={handleProfile("img", idx)}
                  />
                </div>

                <div style={{ width: "110px" }}>
                  {elem.img && (
                    <AspectRatioPicture
                      height="90px"
                      width="110px"
                      src={elem.img}
                      alt={elem.img}
                      isLoading={isLoading}
                      isDeletable={false}
                    />
                  )}
                </div>
                <div>
                  <ConfirmationButton
                    title="Excluir"
                    outline
                    theme="danger"
                    onClick={() => removeCheckedList(idx)}
                  >
                    <i className="fas fa-trash" />
                  </ConfirmationButton>
                </div>
              </div>
            ))}
            <div className="d-flex justify-content-center mt-4">
              <Button disabled={isLoading} outline>
                Atualizar
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
      <Modal
        size="lg"
        open={addingProfile.show}
        toggle={() => {
          resetAddingProfile();
        }}
      >
        <ModalHeader
          toggle={() => {
            resetAddingProfile();
          }}
        >
          Adicionando perfil de empreendimento
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={addingMoreProfile}>
            <div className="d-flex justify-content-around align-items-center">
              <div>
                <div className="label">Nome do perfil:</div>
                <FormInput
                  required
                  type="text"
                  placeholder="nome do perfil"
                  disabled={isLoading}
                  value={addingProfile.name}
                  onChange={handleProfile("name")}
                />
              </div>
              <div className="ml-5">
                <div className="label">Imagem do perfil:</div>
                <FormInput
                  required
                  type="file"
                  disabled={isLoading}
                  onChange={handleProfile("img")}
                />
              </div>
            </div>
            <div className="d-flex justify-content-center mt-4">
              <Button disabled={isLoading} outline>
                Adicionar
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
      <Card>
        <CardBody style={{ minHeight: "395px" }}>
          <div className="d-flex justify-content-between mb-3">
            <h3>Perfis:</h3>
            <div>
              <Button
                className="ml-3"
                size="sm"
                outline
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setAddingProfile({
                    ...addingProfile,
                    show: true,
                  });
                  closingCheckdData();
                }}
              >
                <i className="fa fa-plus-square" />
              </Button>
              <Button
                className="ml-3"
                size="sm"
                outline
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  closingCheckdData();
                  setManagingProfile(true);
                }}
              >
                <i className="fa fa-cog" />
              </Button>
            </div>
          </div>
          <div
            className="d-flex justify-content-between align-items-center mb-3 p-2 w-100"
            style={{
              backgroundColor: "#F0F0F0",
              borderRadius: "10px",
              flexWrap: "wrap",
            }}
          >
            {checkedList &&
              (checkedList.length > 0 ? (
                checkedList.map((elem, idx) => (
                  <FormRadio
                    key={idx}
                    name={elem?.name}
                    checked={elem.checked}
                    onChange={() => {
                      handleCheckedList(idx);
                    }}
                  >
                    {elem?.name}
                  </FormRadio>
                ))
              ) : (
                <div className="d-flex justify-content-center w-100">
                  <h5>Nenhum perfil cadastrado clique em + para cadastrar</h5>
                </div>
              ))}
          </div>
          {showCheckedListData && (
            <>
              <h3 className="text-center">Questionário</h3>
              <Row>
                <Col md="6">
                  <Card>
                    <CardBody className="sortTableArea">
                      <div className="d-flex justify-content-around">
                        <Button
                          outline
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            insertNewNode();
                          }}
                        >
                          <i className="fa fa-plus-square" />
                        </Button>
                        <FormInput
                          className="ml-5"
                          placeholder="Buscar"
                          value={searchString}
                          onChange={(event) =>
                            setStates({
                              ...states,
                              searchString: event.target.value,
                            })
                          }
                        />
                      </div>

                      <SortableTree
                        searchQuery={searchString}
                        onChange={updateTreeData}
                        searchFocusOffset={searchFocusIndex}
                        treeData={treeData}
                        canNodeHaveChildren={() => {
                          return false;
                        }}
                        generateNodeProps={({ node, path }) => ({
                          title: (
                            <button
                              type="button"
                              className="transparent"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                selectThis(node, path);
                              }}
                            >
                              <form>
                                <input
                                  style={{ fontSize: "1rem", width: 200 }}
                                  value={node.title}
                                  onChange={(event) => {
                                    const title = event.target.value;
                                    setStates((states) => ({
                                      ...states,
                                      treeData: changeNodeAtPath({
                                        treeData: states.treeData,
                                        path,
                                        getNodeKey,
                                        newNode: { ...node, title },
                                      }),
                                    }));

                                    const newProfiles =
                                      enterprise.profiles.slice();
                                    newProfiles[idProfile].questions[
                                      path[0]
                                    ].title = title;

                                    setEnterprise({
                                      ...enterprise,
                                      profiles: newProfiles,
                                    });
                                  }}
                                  onClick={() => handleconfig(path)}
                                />
                                &nbsp;&nbsp;&nbsp;
                                <Button
                                  size="sm"
                                  theme="danger"
                                  outline
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setIdTree(path[0]);
                                    setDeleteConfirmation(true);
                                  }}
                                >
                                  <i className="fa fa-trash" />
                                </Button>
                              </form>
                            </button>
                          ),
                        })}
                      />
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6">
                  <Card>
                    <CardBody>
                      {showDetails ? (
                        <Row>
                          <Col md="12" className="h-100">
                            <div
                              className="d-flex justify-content-between mb-3"
                              style={{ height: "72px" }}
                            >
                              <h5>{`Questão: ${treeData[idTree]?.title}`}</h5>
                              <div>
                                <Button
                                  className="ml-3"
                                  size="sm"
                                  outline
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    createInputList();
                                  }}
                                >
                                  <i className="fa fa-plus-square" />
                                </Button>
                              </div>
                            </div>
                            <div
                              id="inputQuestion"
                              style={{
                                height: "249px",
                                overflow: "auto",
                                padding: "10px",
                              }}
                            >
                              {treeData[idTree]?.input_list?.map(
                                (elem, idx) => (
                                  <div key={idx}>
                                    <div>
                                      <div className="d-flex justify-content-between mb-3">
                                        <small>{`Opção: ${idx + 1}`}</small>
                                        <div>
                                          <ConfirmationButton
                                            title="Excluir"
                                            outline
                                            theme="danger"
                                            onClick={() => {
                                              removeInputList(idx);
                                            }}
                                          >
                                            <i className="far fa-minus-square" />
                                          </ConfirmationButton>
                                        </div>
                                      </div>
                                      <FormInput
                                        type="text"
                                        className="ml-2 mb-2"
                                        placeholder="Digite a opção"
                                        disabled={isLoading}
                                        value={elem?.label}
                                        onChange={handleChange("label", idx)}
                                      />

                                      <div className="label">
                                        Imagem da Opção:
                                      </div>
                                      {elem.image_path ? (
                                        <div style={{ width: "80px" }}>
                                          <AspectRatioPicture
                                            height="30px"
                                            width="80px"
                                            src={elem.image_path}
                                            alt={elem.image_path}
                                            isLoading={isLoading}
                                            onDeleteClick={() => {
                                              handleDeleteImage(idx);
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        <FormInput
                                          className="ml-2 mr-2"
                                          type="file"
                                          disabled={isLoading}
                                          value={elem?.image_path}
                                          onChange={handleChange(
                                            "image_path",
                                            idx
                                          )}
                                        />
                                      )}
                                      <div>
                                        <div className="label">
                                          {elem.withScore
                                            ? "Score:"
                                            : "Definir Score"}
                                        </div>
                                        <FormCheckbox
                                          toggle
                                          small
                                          checked={elem.withScore}
                                          onChange={handleChange(
                                            "withScore",
                                            idx
                                          )}
                                        />
                                      </div>

                                      {elem.withScore && elem?.score && (
                                        <div>
                                          <div className="d-flex justify-content-around ">
                                            <FormSelect
                                              size="sm"
                                              onChange={handleChange(
                                                "name",
                                                idx
                                              )}
                                            >
                                              <option value="">
                                                Selecione um tipo
                                              </option>
                                              {enterprise?.styles?.map(
                                                (item, id) => (
                                                  <option
                                                    selected={
                                                      elem.score.name ===
                                                        item?.name && true
                                                    }
                                                    value={item?.name}
                                                    key={id}
                                                  >
                                                    {item?.name}
                                                  </option>
                                                )
                                              )}
                                            </FormSelect>
                                            <FormInput
                                              className="ml-2 mr-2"
                                              type="number"
                                              placeholder="valor"
                                              disabled={isLoading}
                                              value={elem?.score?.value}
                                              onChange={handleChange(
                                                "value",
                                                idx
                                              )}
                                            />
                                          </div>
                                        </div>
                                      )}
                                      <hr />
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </Col>
                        </Row>
                      ) : (
                        <div
                          style={{ height: "336px" }}
                          className="d-flex justify-content-center align-items-center"
                        >
                          <h4>
                            {treeData.length <= 0
                              ? "Questionário vazio, clique no ícone de + para adicionar"
                              : "Clique na questão para visualizar os detalhes"}
                          </h4>
                        </div>
                      )}
                    </CardBody>
                  </Card>
                </Col>
                <Col md="12" className="d-flex justify-content-center mt-4">
                  <Button
                    disabled={isLoading}
                    onClick={() =>
                      saveEnterPrise("Salvo com sucesso!", enterprise.profiles)
                    }
                  >
                    Salvar
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </CardBody>
      </Card>
    </Container>
  );
};
export default EnterpriseQuestions;

import React from "react";
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  FormInput,
  FormGroup,
  FormSelect,
  Button,
  FormCheckbox,
} from "shards-react";
import MultipleQuestion from "./MultipleQuestion";
import ReactQuill from "react-quill";

const CompleteForm = ({
  callBackAddForm,
  callBackUpdateForm,
  projectHash,
  editForm,
  inputList,
  setInputList,
  values,
  setValues,
  hiddenUpdateButton,
  hiddenAddButton,
  setLightbox,
}) => {
  const {
    showMultipleForm,
    showInternalText,
    type,
    label,
    internalText,
    children,
    isMultiselect,
  } = values;

  const addQuestion = () => {
    let question = {
      label: label,
      type: type,
      inputList: inputList,
      internalText: internalText,
    };
    callBackAddForm(question);
    cleanForm();
  };

  const updateQuestion = () => {
    let question = {
      label: label,
      type: type,
      inputList: inputList,
      children: children,
      internalText: internalText,
      isMultiselect: isMultiselect,
    };
    callBackUpdateForm(question);
    cleanForm();
  };

  const cleanForm = () => {
    setValues({
      label: "",
      type: "text",
      showMultipleForm: false,
      showInternalText: false,
      internalText: "",
    });
    setInputList([]);
  };
  const handleChange = (name) => (e) => {
    if (name === "type" && e.target.value === "multiple") {
      if (!inputList.length) {
        setInputList([{ label: "", image_path: "", action: "" }]);
      } else {
        setInputList([...inputList]);
      }
      setValues({
        ...values,
        [name]: e.target.value,
        showMultipleForm: true,
        showInternalText: false,
        internalText: "",
      });
    } else if (name === "type" && e.target.value === "text") {
      setInputList([]);
      setValues({
        ...values,
        [name]: e.target.value,
        showMultipleForm: false,
        showInternalText: false,
        internalText: "",
      });
    } else if (name === "type" && e.target.value === "upload") {
      setInputList([]);
      setValues({
        ...values,
        [name]: e.target.value,
        showMultipleForm: false,
        showInternalText: false,
        internalText: "",
      });
    } else if (name === "type" && e.target.value === "internalText") {
      setInputList([{ label: "", image_path: "", action: "" }]);
      setValues({
        ...values,
        [name]: e.target.value,
        showMultipleForm: false,
        showInternalText: true,
        internalText: internalText,
      });
    } else if (name === "textoInterno") {
      setValues({ ...values, internalText: e });
      if (!inputList.length) {
        setInputList([{ label: e, image_path: "", action: "" }]);
      } else {
        const list = [...inputList];
        list[0].label = e;
        setInputList(list);
      }
    } else if (name === "textoInternoInput") {
      setInputList([
        { label: internalText, image_path: "", action: e.target.value },
      ]);
    } else if (name === "isMultiselect") {
      if (!inputList.length) {
        setInputList([{ label: "", image_path: "", action: "" }]);
      } else {
        setInputList([...inputList]);
      }
      setValues({
        ...values,
        [name]: !values[name],
      });
    } else {
      setValues({ ...values, [name]: e.target.value });
    }
  };

  const propsMultiple = { inputList, setInputList, projectHash, setLightbox };

  return (
    <React.Fragment>
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <FormGroup>
                <div className="label">Label</div>
                <FormInput
                  disabled={editForm}
                  type="text"
                  placeholder="Informe a label"
                  value={label}
                  onChange={handleChange("label")}
                />
              </FormGroup>
              <Row form>
                <Col md="12" className="form-group">
                  <div className="label">Tipo da questão</div>
                  <FormSelect
                    value={type}
                    onChange={handleChange("type")}
                    disabled={editForm}
                  >
                    <option value="text">Texto</option>
                    <option value="multiple">Multipla escolha</option>
                    <option value="upload">Upload</option>
                    <option value="internalText">Texto Interno</option>
                  </FormSelect>
                </Col>
                {type === "multiple" ? (
                  <Col md="12" className="form-group">
                    <FormCheckbox
                      toggle
                      checked={isMultiselect}
                      onChange={handleChange("isMultiselect")}
                      small
                    >
                      <strong>Permitir múltiplas respostas</strong>
                    </FormCheckbox>
                  </Col>
                ) : null}
                <Col md="12" className="form-group">
                  {showMultipleForm && <MultipleQuestion {...propsMultiple} />}
                  {showInternalText && (
                    <div>
                      <FormInput
                        placeholder="Insira o nº da questão"
                        onChange={handleChange("textoInternoInput")}
                        value={inputList[0].action}
                      />
                      <ReactQuill
                        className="add-new-post__editor mb-1"
                        onChange={(html, delta, source) => {
                          return (
                            source === "user" &&
                            handleChange("textoInterno")(html)
                          );
                        }}
                        placeholder="Adicione o texto interno"
                        value={internalText}
                      />
                      {/* <textarea
                        rows="10"
                        className="form-control"
                        placeholder="Adicione o texto interno"
                        onChange={handleChange("textoInterno")}
                        value={internalText}
                      /> */}
                    </div>
                  )}
                </Col>
              </Row>
              <Button
                onClick={updateQuestion}
                disabled={editForm}
                hidden={hiddenUpdateButton}
              >
                Atualizar
              </Button>
              <Button
                onClick={addQuestion}
                disabled={editForm}
                hidden={hiddenAddButton}
              >
                Adicionar
              </Button>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </React.Fragment>
  );
};

export default CompleteForm;

export function justSort(toSort) {
  toSort.sort((a, b) => {
    return a.trim().localeCompare(b.trim());
  });
  return toSort;
}

export function sortByName(toSort) {
  toSort.sort((a, b) => {
    return a.name.trim().localeCompare(b.name.trim());
  });
  return toSort;
}

export function sortBySupplier(toSort) {
  toSort.sort((a, b) => {
    return a.supplier.trim().localeCompare(b.supplier.trim());
  });
  return toSort;
}

import React, { useEffect, useState } from "react";
import { justSort, sortByName } from "../../utils/sorting";
import { Card, Button, CardBody, Row, Col, FormSelect } from "shards-react";
import ProductV2 from "../mobi/ProductV2";
import ProjectsWithMobi from "./projectsWithMobi";
import {
  listAllProducts,
  deleteProduct,
  getMobiFilters,
} from "../../actions/mobi";
import { getTagsV2 } from "../../actions/mobiTag";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "../../styles/react_tags.css";
import ConfirmationButton from "../common/ConfirmationButton";

import { useHistory } from "react-router-dom";

import Filters from "./Filters";

import {
  FurnitureGrid,
  FurnitureTitle,
  FurnitureSubtitle,
  FurnitureCard,
} from "../FurnitureGrid";
import "./Mobi.scss";
import { BUCKET_URL } from "../../config";

const MobiHOC = () => {
  const tinder = false;
  const [values, setValues] = useState({
    pageSizeOptions: [5, 10, 15, 20, 25, 30],
    pageSize: 10,
    showModalProduct: false,
    tableData: [],
    fullList: [],
  });
  const { showModalProduct } = values;
  const [filters, setFilters] = useState({
    tags: [],
    suppliers: [],
    styles: [],
    colors: [],
    typologies: [],
    projectTemplateNames: [],
    colorTones: [],
    features: [],
    categories: [],
    subCategories: [],
    oldTags: {
      styles: [],
      colors: [],
      typologies: [],
      categories: [],
    },
  });
  const [mobis, setMobis] = useState([]);

  const [newItem, setNewItem] = useState(true);

  const [initial, setInitial] = useState(0);
  const [filteredTinder, setFilteredTinder] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState("");

  const [product, setProduct] = useState();

  const props = { product, refreshTableData };

  const history = useHistory();

  const initialFilterValue = {
    supplier: "",
    tags: [],
    styles: [],
    colors: [],
    features: [],
    colorTones: [],
    typologies: [],
    hasFile: false,
    onlyAvailable: "null",
    order: "frequency_used",
    notUse: "null",
    projectTemplateNames: [],
    limit: 28,
    page: 0,
    search: "",
    hasOtherFiles: false,
    typology: "",
    category: "",
    subCategory: "",
    oldTags: {
      categories: [],
      styles: [],
      colors: [],
      typologies: [],
    },
    active: true,
  };

  const [filter, setFilter] = useState(initialFilterValue);

  const [modalBackdrop, setModalBackdrop] = useState(true);

  const [showModalMobiProject, setShowModalMobiProject] = useState(false);
  const [mobiToSearch, setMobiToSearch] = useState({});

  useEffect(() => {
    refreshTableData();
  }, []);

  function refreshTags() {
    getMobiFilters().then((result) => {
      setFilters({
        ...filters,
        styles: result.styles,
        typologies: result.typologies,
        colors: result.colors,
        suppliers: result.suppliers,
        projectTemplateNames: result.projectTemplateNames,
        colorTones: result.colorTones,
        features: result.features,
        oldTags: {
          styles: result.oldStyles,
          colors: result.oldColors,
          typologies: result.oldTypologies,
          categories: result.oldCategories,
        },
      });
      setIsLoading(false);
    });
  }

  function refreshTableData() {
    setIsLoading(true);
    const reset = { ...filter, page: 0 };
    setFilter(reset);
    Promise.all([getMobiFilters(), listAllProducts(reset)])
      .then((results) => {
        const [filtersData, products] = results;
        setFilters({
          ...filters,
          styles: filtersData.styles,
          typologies: filtersData.typologies,
          colors: filtersData.colors,
          suppliers: filtersData.suppliers,
          projectTemplateNames: filtersData.projectTemplateNames,
          colorTones: filtersData.colorTones,
          features: filtersData.features,
          oldTags: {
            styles: filtersData.oldStyles,
            colors: filtersData.oldColors,
            typologies: filtersData.oldTypologies,
            categories: filtersData.oldCategories,
          },
        });
        products.forEach((mobi) => {
          mobi.name = mobi.name.toString();
          mobi.sku = mobi.sku.toString();
        });
        setMobis(products);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.message);
      });
  }

  function newProduct() {
    setProduct();
    modalProduct();
  }

  function modalProduct() {
    modalBackdrop &&
      setValues({ ...values, showModalProduct: !showModalProduct });
  }

  function handleItemEdit(row) {
    setProduct(row);
    modalProduct();
  }

  function selectedTinder(ev) {
    setSelected(ev.target.value);
  }

  const filterFormSelectTinder = () => {
    Promise.resolve()
      .then(() => {
        setNewItem(false);
        refreshTableData();
      })
      .then(() => {
        const data = mobis.filter(
          (mobi) => mobi[selected].length === 0 && !mobi.notUse
        );
        setProduct(data[0]);
        setFilteredTinder(data);
      })
      .then(() => {
        setNewItem(true);
      });
  };

  function handleItemDelete(row) {
    deleteProduct(row._id).then((result) => {
      setMobis(mobis.filter((m) => m._id !== row._id));
      toast.success(result.msg, { autoClose: 5000 });
    });
  }

  function onFilterUpdate(data) {
    if (data.typology) {
      // Update Category
      setFilter({ ...filter, category: "", subCategory: "" });
      getTagsV2("", "typology", data.typology).then((res) => {
        setFilters({
          ...filters,
          categories: res,
          subCategories: [],
        });
      });
    }
    if (data.category) {
      // Update Subcategory
      getTagsV2("", "typology", data.category).then((res) => {
        setFilters({
          ...filters,
          subCategories: res,
        });
      });
    }
    setFilter((newFilter) => {
      newFilter = { ...newFilter, ...data, page: 0 };

      listAllProducts(newFilter)
        .then((mobis) => {
          setMobis(mobis);
        })
        .catch((err) => {
          console.log(err);
        });
      return newFilter;
    });
  }
  const moreItens = () => {
    setFilter((newFilter) => {
      newFilter.page++;
      listAllProducts(newFilter).then((data) => {
        if (data.length) {
          setMobis(mobis.concat(data));
        } else {
          toast.error("Nunhum item encontrado", { autoClose: 5000 });
        }
      });
      return newFilter;
    });
  };

  function onMobiUpdate(mobi) {
    if (filter.active === mobi.active) {
      if (typeof mobis.find((m) => mobi._id === m._id) === "undefined") {
        Object.keys(mobi).forEach((key) => {
          if (key === "value") {
            mobi.value = parseFloat(mobi.value);
          }
        });
        setMobis(
          [...mobis, mobi]
            .sort((a, b) => {
              return a[filter.order] != b[filter.order]
                ? a[filter.order]
                    ?.toString()
                    ?.localeCompare(b[filter.order]?.toString())
                : a._id.localeCompare(b._id);
            })
            .reverse()
        );
      }
    }
    const mobiFromList = mobis.find((m) => mobi._id === m._id);
    if (mobiFromList) {
      Object.keys(mobi).forEach((key) => {
        if (key === "value") {
          mobiFromList.value = parseFloat(mobi.value);
        } else {
          mobiFromList[key] = mobi[key];
        }
      });
      setMobis([...mobis]);
    }
    if (filter.active !== mobi.active) {
      setMobis(mobis.filter((m) => m._id !== mobi._id));
    }
  }

  const handleItemProject = (mobi) => {
    setMobiToSearch(mobi);
    setShowModalMobiProject(true);
  };

  return (
    <>
      {showModalMobiProject && (
        <ProjectsWithMobi
          showModalMobiProject={showModalMobiProject}
          setShowModalMobiProject={setShowModalMobiProject}
          mobiToSearch={mobiToSearch}
        />
      )}
      <Button className="mb-2" onClick={newProduct} disabled={isLoading}>
        Adicionar produto
      </Button>
      <Button
        className="mb-2 ml-2"
        outline
        theme="primary"
        onClick={() => {
          history.push("/furniture-groups");
        }}
      >
        Grupos
      </Button>

      <Button
        className="mb-2 ml-2"
        outline
        theme="primary"
        disabled={isLoading}
        onClick={() => {
          history.push("/mobi/tags");
        }}
      >
        Categorias
      </Button>

      <Button
        className="mb-2 ml-2"
        outline
        theme="primary"
        disabled={isLoading}
        onClick={() => {
          history.push("/mobi_tag_tinder/filter");
        }}
      >
        Tinder
      </Button>
      <Card>
        <CardBody>
          {!tinder ? (
            <>
              <Filters
                suppliers={justSort(filters.suppliers)}
                styles={sortByName(filters.styles)}
                colors={sortByName(filters.colors)}
                colorTones={sortByName(filters.colorTones)}
                features={sortByName(filters.features)}
                typologies={sortByName(filters.typologies)}
                categories={sortByName(filters.categories)}
                subCategories={sortByName(filters.subCategories)}
                oldTags={filters.oldTags}
                projectTemplateNames={justSort(filters.projectTemplateNames)}
                filter={filter}
                refreshTableData={refreshTableData}
                onFilterUpdate={onFilterUpdate}
                initialFilterValue={initialFilterValue}
                refreshTags={refreshTags}
              />
              <FurnitureGrid>
                {mobis.map((mobi, idx) => {
                  return (
                    <FurnitureCard mobi={mobi} key={idx}>
                      <FurnitureTitle title={mobi.name} />
                      <FurnitureSubtitle
                        subtitle={`
                          ${
                            mobi.inventory
                              ? mobi.inventory >= 0
                                ? "Est.: " + mobi.inventory + " - "
                                : "?"
                              : ""
                          }
                          Sku: ${mobi.sku ? mobi.sku : "Não informado"}  - ${
                          mobi.supplier
                        }

                          `}
                      />
                      {mobi.value
                        ? mobi.value.toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                          })
                        : "Preço não encontrado"}
                      <Row className="pt-1 pb-1">
                        <Col className="pr-0">
                          {mobi.file && (
                            <Button
                              title="Baixar Bloco"
                              theme="white"
                              onClick={() => {
                                window.open(
                                  BUCKET_URL +
                                    "/mobi/" +
                                    mobi._id +
                                    "/file/" +
                                    mobi.file
                                );
                                // toast.error(
                                //   "Download bloqueado, por favor baixar no mobi do projeto :(",
                                //   {
                                //     autoClose: 5000,
                                //   }
                                // );
                              }}
                            >
                              <i
                                className="fas fa-download fa-lg"
                                style={{
                                  cursor: "pointer",
                                }}
                              />
                            </Button>
                          )}
                        </Col>
                        <Col className="pr-0">
                          <Button
                            title="Editar"
                            theme="white"
                            onClick={() => handleItemEdit(mobi)}
                          >
                            <i className="material-icons">&#xE254;</i>
                          </Button>
                        </Col>
                        <Col className="pr-0">
                          <Button
                            title="Projetos com o mobi"
                            theme="white"
                            onClick={() => handleItemProject(mobi)}
                          >
                            <i className="material-icons">perm_media</i>
                          </Button>
                        </Col>
                        <Col className="pr-0">
                          <ConfirmationButton
                            title="Remover"
                            theme="white"
                            onClick={() => handleItemDelete(mobi)}
                          >
                            <i className="material-icons">&#xE872;</i>
                          </ConfirmationButton>
                        </Col>
                      </Row>
                    </FurnitureCard>
                  );
                })}
              </FurnitureGrid>
              <Row style={{ justifyContent: "center" }}>
                <Button onClick={moreItens}>CARREGAR MAIS</Button>
              </Row>
            </>
          ) : (
            <>
              <Row>
                <Col md="2">
                  <div className="form-group">
                    <div className="label">Filtro (Vazio)</div>
                    <FormSelect onChange={selectedTinder}>
                      <option value="all" />
                      <option value="tags">Categorias</option>
                      <option value="styles">Estilo</option>
                      <option value="colors">Cor</option>
                      <option value="typologies">Tipologia</option>
                    </FormSelect>
                  </div>
                  <Button
                    outline
                    onClick={() => {
                      filterFormSelectTinder();
                    }}
                  >
                    Filtrar
                  </Button>
                </Col>
              </Row>
              {filteredTinder.length > 0 && (
                <Row>
                  <Col md="2" />
                  <Col md="8">
                    {newItem && (
                      // Need to implement, old <Product/> was removed.
                      // <ProductV2

                      // />
                      <></>
                    )}
                  </Col>
                  <Col
                    md="2"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button
                      onClick={() => {
                        Promise.resolve()
                          .then(() => {
                            setNewItem(false);
                          })
                          .then(() => {
                            setProduct(filteredTinder[initial + 1]);
                          })
                          .then(() => {
                            setInitial(initial + 1);
                          })
                          .then(() => {
                            setNewItem(true);
                          });
                      }}
                    >
                      Proximo
                    </Button>
                  </Col>
                </Row>
              )}
            </>
          )}
        </CardBody>
      </Card>
      {showModalProduct ? (
        <ProductV2
          {...props}
          projectTemplateNames={filters.projectTemplateNames.map((name) => {
            return {
              id: name,
              name: name,
            };
          })}
          toggle={modalProduct}
          tags={filters.tags}
          styles={filters.styles}
          typologies={filters.typologies}
          colors={filters.colors}
          suppliers={filters.suppliers}
          refreshTableData={refreshTableData}
          onUpdate={onMobiUpdate}
          colorTones={filters.colorTones}
          categories={filters.categories}
          features={filters.features}
          refreshTags={refreshTags}
          maxHeight={"80vh"}
          onImageView={setModalBackdrop}
        />
      ) : null}
    </>
  );
};
export default MobiHOC;

import React, { useState } from "react";

import { createContext } from "react";

export const PageContext = createContext({});

export const PageProvider = ({ children, value }) => {
  value = JSON.parse(JSON.stringify(value));
  const [customSettings, setCustomSettings] = useState(
    value.customSettings.filter(
      (setting) => !setting.items || !setting.items.length
    )
  );

  const [settings, setSettings] = useState(value.settings);
  const [customCategorySettings, setCustomCategorySettings] = useState(
    value.customSettings.map((setting) => {
      setting.id = setting._id || setting.id;
      if (setting.type === "subtitle_list") {
        setting.items = setting.items.map((setting) => {
          setting.id = setting._id;
          return setting;
        });
      }

      return setting;
    })
  );

  function addCustomSetting(setting) {
    customSettings.push(setting);
    let categorySetting = getDefaultPageItemById(setting.category);
    categorySetting.enabled = getCategoryToggleValue(categorySetting);

    setCustomSettings([...customSettings]);
  }

  function addCustomCategorySetting(setting) {
    let customCategory = customCategorySettings.find(
      (customCategory) => customCategory.id === setting.categoryId
    );
    if (!customCategory.items) {
      customCategory.items = [];
      customCategory.type = "subtitle_list";
    }
    customCategory.items.push(setting);
    setCustomCategorySettings([...customCategorySettings]);
  }

  function addCustomCategory(setting) {
    setting.id = customCategorySettings.length;
    customCategorySettings.push(setting);
    setCustomCategorySettings([...customCategorySettings]);
  }

  function deleteCustomCategorySetting(id, categoryId) {
    let categorySetting = customCategorySettings.find(
      (categorySetting) => categorySetting.id === categoryId
    );

    categorySetting.items = categorySetting.items.filter(
      (setting) => setting.id !== id
    );
    if (categorySetting.length === 1) {
      categorySetting.type = "text";
      categorySetting.items = [];
    }
    setCustomCategorySettings([...customCategorySettings]);
  }

  function deleteCustomCategory(id) {
    let newCustomCategorySettings = customCategorySettings.filter(
      (customCategory) => customCategory.id !== id
    );
    setCustomCategorySettings([...newCustomCategorySettings]);
  }

  function deleteCustomSetting(index, category) {
    let newCustomSettings = customSettings.filter(
      (setting, idx) => idx !== index
    );

    let categorySetting = getDefaultPageItemById(category);
    categorySetting.enabled =
      getCategoryToggleValueByItems(categorySetting) ||
      getCategoryToggleValueByCustomSettings(
        categorySetting.name,
        newCustomSettings
      );
    setCustomSettings(newCustomSettings);
  }

  function toggleSetting(category, settingId) {
    let categorySetting = getDefaultPageItemById(category);
    let setting = categorySetting.items.find(
      (setting) => setting._id === settingId
    );
    setting.enabled = !setting.enabled;
    categorySetting.enabled = getCategoryToggleValue(categorySetting);

    setSettings([...settings]);
  }

  function getCategoryToggleValue(setting) {
    return (
      getCategoryToggleValueByItems(setting) ||
      getCategoryToggleValueByCustomSettings(setting.name, customSettings)
    );
  }

  function getCategoryToggleValueByCustomSettings(category, customSettings) {
    return customSettings.some(
      (customSetting) => customSetting.category === category
    );
  }

  function getCategoryToggleValueByItems(categorySetting) {
    return (
      categorySetting.items &&
      categorySetting.items.reduce((acc, current) => {
        return acc || current.enabled;
      }, false)
    );
  }

  function getDefaultPageItemById(item) {
    return settings.find((setting) => setting._id === item._id);
  }

  function toggleSettingsByCategory(item, value) {
    let categorySetting = getDefaultPageItemById(item);
    categorySetting.enabled = value;

    setSettings([...settings]);
  }

  return (
    <PageContext.Provider
      value={{
        settings,
        customSettings,
        customCategorySettings,
        setSettings,
        setCustomSettings,
        addCustomSetting,
        addCustomCategory,
        addCustomCategorySetting,
        deleteCustomSetting,
        deleteCustomCategorySetting,
        deleteCustomCategory,
        toggleSetting,
        toggleSettingsByCategory,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

import React, { useState, useContext, useEffect } from "react";

import Collapse from "react-bootstrap/Collapse";
import CollapseButton from "../common/CollapseButton";

import { FormCheckbox } from "shards-react";

import { TableContext } from "../FilteredTable/TableContext";

function Filters({ mode }) {
  const { filter, onFilterUpdate } = useContext(TableContext);

  const [isFilterShown, setIsFilterShown] = useState(mode === "edit");

  useEffect(() => {
    if (mode === "edit") onFilterUpdate("groupedOnly", true);
  }, []);

  function onCollapseClick() {
    setIsFilterShown(!isFilterShown);
  }
  return (
    <>
      <div className="d-flex mt-2">
        {/* eslint-disable-next-line */}
        <div
          className="d-flex flex-row justify-content-center align-items-center clickable"
          onClick={onCollapseClick}
        >
          <CollapseButton theme="primary" collapsed={isFilterShown} />
          <strong>Filtros</strong>
        </div>
      </div>
      <div id="example-collapse-texts" className="pl-4">
        <Collapse in={isFilterShown} timeout={0}>
          <FormCheckbox
            toggle
            checked={filter.groupedOnly && filter.groupedOnly.value}
            onChange={(ev) => {
              onFilterUpdate("groupedOnly", ev.target.checked);
            }}
            small
          >
            <strong>Mostrar apenas do grupo</strong>
          </FormCheckbox>
        </Collapse>
      </div>
    </>
  );
}

Filters.propTypes = {};

export default Filters;

import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";

import classNames from "classnames";
import { Row, Col } from "shards-react";

import { loadPictureInMemory } from "../../services/file";
import AspectRatioPicture from "../common/AspectRatioPicture";

function DropzoneFiles({
  onChange,
  isLoading,
  isMultiple,
  items = [],
  onImageView,
}) {
  const [renderedFiles, setRenderedFiles] = useState([]);

  useEffect(() => {
    setRenderedFiles(
      items.reduce((acc, file, i) => {
        if (i % 3 === 0) {
          acc.push([file]);
        } else {
          acc[acc.length - 1].push(file);
        }
        return acc;
      }, [])
    );
  }, [items]);

  return (
    <>
      <div className="d-flex flex-row justify-content-center align-items-center clickable">
        {isMultiple || items.length === 0 ? (
          <Dropzone
            multiple={isMultiple}
            disabled={isLoading}
            onDrop={async (acceptedFiles) => {
              try {
                const newFiles = await loadPictureInMemory(acceptedFiles);
                onChange([...items, ...newFiles]);
              } catch (err) {
                return toast.error(err.message, {
                  autoClose: 5000,
                });
              }
            }}
          >
            {/* TODO Fix loader */}
            {({ getRootProps, getInputProps, isDragActive }) => {
              return (
                <div
                  {...getRootProps()}
                  className={classNames("dropzone", {
                    "dropzone--isActive": isDragActive,
                  })}
                >
                  <input {...getInputProps()} />
                  <span>
                    {isDragActive
                      ? "Arrasta e solte seus arquivos aqui"
                      : "Clique aqui para fazer um upload"}
                  </span>
                </div>
              );
            }}
          </Dropzone>
        ) : null}
      </div>

      <div>
        {renderedFiles.map((group, i) => {
          return (
            <Row key={i}>
              <Col
                xs="12"
                className="d-flex flex-row justify-content-center align-items-center mt-3"
              >
                {group.map((img, j) => {
                  return (
                    <div className="ml-1 mr-1" key={j}>
                      <AspectRatioPicture
                        height="150px"
                        width="150px"
                        src={img.link || img.uri}
                        alt={img.name || img.file.name}
                        isLoading={isLoading}
                        isUploading={img.isLoading}
                        onDeleteClick={() => {
                          if (img.file) {
                            const index = i * 3 + j;
                            let newList = items.filter(
                              (file, i) => i !== index
                            );
                            onChange([...newList]);
                          } else {
                            let newList = items.filter(
                              (item) => item.link !== img.link
                            );

                            onChange([...newList]);
                          }
                        }}
                        onImageView={onImageView}
                      />
                    </div>
                  );
                })}
              </Col>
            </Row>
          );
        })}
      </div>
    </>
  );
}

export default DropzoneFiles;

import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import { createContext } from "react";

import { getMetadata } from "../../actions/metadata";
import { setCookie, getCookie, isAuth } from "../../actions/auth";
import * as timeago from "timeago.js";
import { AuthContext } from "../common/AuthContext";
import { io } from "socket.io-client";

const socketInstance = io("https://socket.ladecora.com.br", {
  secure: true,
  autoConnect: false,
});

export const MetadataContext = createContext({});

export const MetadataProvider = ({ children }) => {
  const initialCompany = getCookie("company") || "all";
  const { user } = useContext(AuthContext);
  const [company, setCompany] = useState(initialCompany);
  const [metadata, setMetadata] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const scopedByCompany =
    (requestFn) =>
    (...args) =>
      requestFn(...args, { company });

  const history = useHistory();

  const socket = socketInstance.connect();

  useEffect(() => {
    if (isAuth()) {
      getMetadata()
        .then((res) => {
          let userCompany = JSON.parse(localStorage.getItem("user")).company;
          if (userCompany != "ladecora") {
            res.companies = res.companies.filter((e) => e.label == userCompany);
            setCompany(userCompany);
          } else {
            const contextCompany = res.companies.find(
              (c) => c.label === initialCompany
            );
            if (!contextCompany) {
              onCompanyChange("all");
            } else {
              setCompany(initialCompany);
            }
          }

          setMetadata(res);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  configLocale();

  function configLocale() {
    const locale = function (number, index) {
      // number: the time ago / time in number;
      // index: the index of array below;
      // totalSec: total seconds between date to be formatted and today's date;
      return [
        ["agora mesmo", "agora"],
        ["há %s segundos", "em %s segundos"],
        ["há um minuto", "em um minuto"],
        ["há %s minutos", "em %s minutos"],
        ["há uma hora", "em uma hora"],
        ["há %s horas", "em %s horas"],
        ["há um dia", "em um dia"],
        ["há %s dias", "em %s dias"],
        ["há uma semana", "em uma semana"],
        ["há %s semanas", "em %s semanas"],
        ["há um mês", "em um mês"],
        ["há %s meses", "em %s meses"],
        ["há um ano", "em um ano"],
        ["há %s anos", "em %s anos"],
      ][index];
    };

    timeago.register("pt_BR", locale);
  }

  function onCompanyChange(value) {
    setCookie("company", value);
    setCompany(value);
  }

  function isAllowed(resource, perform = "read:any") {
    if (metadata && "roles" in metadata) {
      return !!(
        metadata.roles[user.role] &&
        metadata.roles[user.role][resource] &&
        metadata.roles[user.role][resource][perform]
      );
    } else {
      history.push("/login");
    }
  }

  return (
    <MetadataContext.Provider
      value={{
        company,
        onCompanyChange,
        metadata,
        scopedByCompany,
        isAllowed,
        socket,
      }}
    >
      {!isLoading && company && children}
    </MetadataContext.Provider>
  );
};

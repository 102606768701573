import React, { useState } from "react";

import {
  Row,
  Col,
  Button,
  InputGroupAddon,
  FormInput,
  InputGroup,
  Form,
} from "shards-react";

function ActionHeader({ onSave, name, isLoading }) {
  const [groupName, setGroupName] = useState("");

  if (name && !groupName && isLoading) {
    setGroupName(name);
  }

  function onSaveClick(ev) {
    ev.preventDefault();
    onSave(groupName)
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });
    return false;
  }

  return (
    <Row>
      <Col>
        <Form onSubmit={onSaveClick}>
          <InputGroup className="mt-3">
            <FormInput
              value={groupName}
              onChange={(ev) => setGroupName(ev.target.value)}
              required
              disabled={isLoading}
              placeholder="Nome do grupo"
            />
            <InputGroupAddon type="append">
              <Button disabled={isLoading} type="submit" theme="primary">
                {isLoading && groupName ? "Salvando..." : "Salvar"}
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </Form>
      </Col>
    </Row>
  );
}

ActionHeader.propTypes = {};

export default ActionHeader;

import React from "react";

const Tag = (props) => (
  <button
    type="button"
    className={props.classNames.selectedTag}
    title={props.removeButtonText}
    onClick={props.onDelete}
  >
    <span className={props.classNames.selectedTagName}>{props.tag.name}</span>
  </button>
);

export default Tag;

import React, { useState, useEffect } from "react";
import { listAdminUsers } from "../../../actions/user";
import SelectSearch from "react-select-search";

function Owner({ id, value, type, updateProjectOwner, toast, reload }) {
  const [adminUsers, setAdminUsers] = useState([]);

  useEffect(() => {
    listAdminUsers().then((res) => {
      setAdminUsers(res);
    });
  }, []);

  const onOwnerChange = (ownerId) => {
    updateProjectOwner(id, ownerId === 0 ? null : ownerId, type)
      .then((res) => {
        toast.success(res.msg, { autoClose: 5000 });
      })
      .catch((err) => {
        toast.error(err.message, { autoClose: 5000 });
        reload();
      });
  };

  return (
    <SelectSearch
      options={[
        {
          name: "Nenhum",
          value: 0,
        },
      ].concat(
        adminUsers.map((user) => {
          return { name: user.email, value: user._id };
        })
      )}
      search={true}
      value={value}
      onChange={onOwnerChange}
      placeholder="Selecione"
    />
  );
}

Owner.propTypes = {};

export default Owner;

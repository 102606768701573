import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Card, Col, CardBody, Button } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { getStats } from "../actions/stats";

import FilteredTable from "../components/FilteredTable/index";
import Moment from "react-moment";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import moment from "moment";
import AvgCard from "../components/common/AvgCard";
import { MetadataContext } from "../components/metadata/MetadataContext";
import * as XLSX from "xlsx";

const Stats = () => {
  const { scopedByCompany, metadata } = useContext(MetadataContext);
  const getStatsScopedByCompany = scopedByCompany(getStats);
  const [start, end] = getInitialDate();
  const [date, setDate] = useState([start, end]);
  const [stats, setStats] = useState([]);
  const [environmentCount, seteEvironmentCount] = useState([]);
  const [npsList, setNpsList] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [npsValue, setNpsValue] = useState(0);
  const [bdProjects, setBdProjects] = useState([]);

  const createXlsx = () => {
    const workbook = XLSX.utils.book_new();
    const worksheetBasicInfos = XLSX.utils.json_to_sheet(stats ? stats : []);
    XLSX.utils.book_append_sheet(workbook, worksheetBasicInfos, "Etapas");

    const worksheetProjects = XLSX.utils.json_to_sheet(
      bdProjects
        ? bdProjects.map((item) => {
            return {
              "Nome do projeto": item.name,
              Usuário:
                item.user.profile.firstName + " " + item.user.profile.lastName,
              "Data da compra": item.createdAt,
              Link: "https://admin.ladecora.com.br/project/" + item._id,
            };
          })
        : []
    );
    XLSX.utils.book_append_sheet(
      workbook,
      worksheetProjects,
      "Projetos Comprados"
    );
    const worksheetNps = XLSX.utils.json_to_sheet(
      npsList.projectsNps
        ? npsList.projectsNps.map((item) => {
            return {
              "Nome do projeto": item.name,
              Usuário:
                item.user.profile.firstName + " " + item.user.profile.lastName,
              "Data da compra": item.createdAt,
              Nota: item.rating.value,
              Comentario: item.rating.text,
              Projeto: "https://admin.ladecora.com.br/project/" + item._id,
            };
          })
        : []
    );
    XLSX.utils.book_append_sheet(workbook, worksheetNps, "NPS");

    const worksheetEnvironments = XLSX.utils.json_to_sheet(
      environmentCount
        ? environmentCount.map((item) => {
            return {
              Ambiente: item._id,
              Total: item.count,
            };
          })
        : []
    );
    XLSX.utils.book_append_sheet(workbook, worksheetEnvironments, "Ambientes");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const dataBlob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });

    // Creating a link to trigger the download
    const link = document.createElement("a");
    link.href = URL.createObjectURL(dataBlob);
    link.setAttribute("download", "relatório.xlsx");

    // Append link to the body, trigger click, and then remove it
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const [smallStats, setSmallStats] = useState([
    {
      label: "Usuários criados",
      column: "users",
      value: 0,
      percentage: "0",
      increase: false,
      chartLabels: [null, null, null, null, null, null, null],
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "#7CFC00",
          borderColor: "#7CFC00",
          data: [0],
        },
      ],
    },
    {
      label: "Usuarios com compras",
      column: "boughtProject",
      value: 0,
      percentage: "0",
      increase: false,
      chartLabels: [null, null, null, null, null, null, null],
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "#2C3A47",
          borderColor: "#2C3A47",
          data: [0],
        },
      ],
    },
    {
      label: "Projetos criados",
      column: "created",
      value: 0,
      percentage: "0",
      increase: false,
      chartLabels: [null, null, null, null, null, null, null],
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "#55efc4",
          borderColor: "#55efc4",
          data: [0],
        },
      ],
    },
    {
      label: "Propostas Iniciais",
      column: "preview",
      value: 0,
      percentage: "0",
      increase: false,
      chartLabels: [null, null, null, null, null, null, null],
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "#F97F51",
          borderColor: "#F97F51",
          data: [0],
        },
      ],
    },
    {
      label: "1º Revisão",
      column: "firstReview",
      value: 0,
      percentage: "0",
      increase: false,
      chartLabels: [null, null, null, null, null, null, null],
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "#1B9CFC",
          borderColor: "#1B9CFC",
          data: [0],
        },
      ],
    },
    {
      label: "2º Revisão",
      column: "secondReview",
      value: 0,
      percentage: "0",
      increase: false,
      chartLabels: [null, null, null, null, null, null, null],
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "#82589F",
          borderColor: "#82589F",
          data: [0],
        },
      ],
    },
    {
      label: "Revisões",
      column: "review",
      value: 0,
      percentage: "0",
      increase: false,
      chartLabels: [null, null, null, null, null, null, null],
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "#EAB543",
          borderColor: "#EAB543",
          data: [0],
        },
      ],
    },
    {
      label: "Projetos finais",
      column: "finalProject",
      value: 0,
      percentage: "0",
      increase: false,
      chartLabels: [null, null, null, null, null, null, null],
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "#2C3A47",
          borderColor: "#2C3A47",
          data: [0],
        },
      ],
    },
  ]);

  const refreshTableData = (date) => {
    setIsLoading(true);
    getStatsScopedByCompany(date)
      .then((data) => {
        const arrAvg = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;
        const columns = smallStats.map((s) => s.column);
        columns.forEach((c) => {
          const array = [];
          const total = data["stats"].reduce((acc, item) => {
            array.push(item[c]);
            if (c !== "boughtProject") {
              acc += item[c];
            } else {
              acc = item[c];
            }
            return acc;
          }, 0);
          const createdStat = smallStats.find((ss) => ss.column === c);
          createdStat.value = total;
          const avg = arrAvg(array);
          createdStat.percentage = avg < 1 ? avg.toFixed(2) : avg.toFixed(0);
          createdStat.datasets = [{ ...createdStat.datasets[0], data: array }];
        });

        setSmallStats([...smallStats]);
        setIsLoading(false);

        seteEvironmentCount(data["environmentCount"]);
        setStats(data["stats"]);
        let detratores = 0;
        let promoters = 0;
        let total = 0;
        data["nps"].projectsNps.map((nps) => {
          let value = nps.rating.value * 2;
          if (value >= 9) {
            promoters++;
          }
          if (value < 6) {
            detratores++;
          }
          total++;
        });
        let finalNps = Math.round(
          (promoters * 100) / total - (detratores * 100) / total
        );
        setNpsValue(finalNps);
        setNpsList(data["nps"]);
        setBdProjects(data["bdProjects"]);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    refreshTableData(date);
  }, []);

  const tableColumnsEnvironments = [
    {
      Header: "Ambiente",
      accessor: "created",
      className: "text-center",
      Cell: (row) => <span>{row.original._id}</span>,
    },
    {
      Header: "Total",
      accessor: "created",
      className: "text-center",
      Cell: (row) => <span>{row.original.count}</span>,
    },
  ];

  const tableNps = [
    {
      Header: "Nome do projeto",
      maxWidth: 130,
      accessor: "name",
      className: "text-center",
      Cell: (row) => <span>{row.original.name}</span>,
    },

    {
      Header: "Usuário",
      maxWidth: 130,
      accessor: "user",
      className: "text-center",
      Cell: (row) => (
        <span>
          {row.original?.user?.profile
            ? row.original.user.profile.firstName +
              " " +
              row.original.user.profile.lastName
            : "Não encontrado"}
        </span>
      ),
    },
    {
      Header: "Data da compra",
      accessor: "createdAt",
      maxWidth: 130,
      className: "text-center",
      Cell: (row) => {
        return (
          <span className="d-flex flex-column">
            <Moment format="DD/MM/YYYY">{row.original.createdAt}</Moment>
            <Moment format="HH:mm">{row.original.createdAt}</Moment>
          </span>
        );
      },
    },
    {
      Header: "Nota",
      accessor: "value",
      maxWidth: 90,
      className: "text-center",
      Cell: (row) => <span>{row.original.rating.value}</span>,
    },
    {
      Header: "Comentario",
      accessor: "text",
      className: "text-center",
      Cell: (row) => (
        <span className="wrap-text-ellipsis" title={row.original.rating.text}>
          {row.original.rating.text}
        </span>
      ),
    },
    {
      Header: "Projeto",
      accessor: "text",
      maxWidth: 90,
      className: "text-center",
      Cell: (row) => (
        <>
          <Button
            outline
            theme={row.original.highlight ? "success" : "dark"}
            onClick={() => {
              window.open("/project/" + row.original._id);
            }}
          >
            <i className="material-icons">&#xe86d;</i>
          </Button>
        </>
      ),
    },
  ];

  const tableColumns = [
    {
      Header: "Data",
      accessor: "date",
      className: "text-center",
      Cell: (row) => <Moment format="DD/MM/YYYY">{row.original.date}</Moment>,
    },
    {
      Header: "Usuários",
      accessor: "users",
      className: "text-center",
      Cell: (row) => <span>{row.original.users}</span>,
    },
    {
      Header: "Projetos novos",
      accessor: "created",
      className: "text-center",
      Cell: (row) => <span>{row.original.created}</span>,
    },
    {
      Header: "Informações enviadas",
      accessor: "created",
      className: "text-center",
      Cell: (row) => <span>{row.original.submitted}</span>,
    },
    {
      Header: "Propostas Iniciais",
      accessor: "preview",
      className: "text-center",
      Cell: (row) => <span>{row.original.preview}</span>,
    },
    {
      Header: "1º Revisão",
      accessor: "firstReview",
      className: "text-center",
      Cell: (row) => <span>{row.original.firstReview}</span>,
    },
    {
      Header: "2º Revisão",
      accessor: "secondReview",
      className: "text-center",
      Cell: (row) => <span>{row.original.secondReview}</span>,
    },

    {
      Header: "Revisões",
      accessor: "review",
      className: "text-center",
      Cell: (row) => <span>{row.original.review}</span>,
    },
    {
      Header: "Books",
      accessor: "finalProject",
      className: "text-center",
      Cell: (row) => <span>{row.original.finalProject}</span>,
    },
  ];

  const tableColumnsProjects = [
    {
      Header: "Nome do projeto",
      accessor: "name",
      className: "text-center",
      Cell: (row) => <span>{row.original.name}</span>,
    },

    {
      Header: "Usuário",
      accessor: "user",
      className: "text-center",
      Cell: (row) => (
        <span>
          {row.original?.user?.profile
            ? row.original.user.profile.firstName +
              " " +
              row.original.user.profile.lastName
            : "Não encontrado"}
        </span>
      ),
    },
    {
      Header: "Data da compra",
      accessor: "createdAt",
      maxWidth: 130,
      className: "text-center",
      Cell: (row) => {
        return (
          <span className="d-flex flex-column">
            <Moment format="DD/MM/YYYY">{row.original.createdAt}</Moment>
            <Moment format="HH:mm">{row.original.createdAt}</Moment>
          </span>
        );
      },
    },
    {
      Header: "Contexto",
      accessor: "company",
      maxWidth: 80,
      className: "text-center",
      Cell: (row) =>
        row.original.company
          ? metadata.companies.find(
              (company) => company.label === row.original.company
            ).name
          : "Nenhum",
    },

    {
      Header: "Link do Projeto",
      accessor: "actions",
      sortable: false,
      maxWidth: 90,
      Cell: (row) => (
        <Button
          outline
          theme={row.original.highlight ? "success" : "dark"}
          onClick={() => {
            window.open("/project/" + row.original._id);
          }}
        >
          <i className="material-icons">&#xe86d;</i>
        </Button>
      ),
    },
  ];

  function getInitialDate() {
    const start = moment().subtract(30, "d").toDate();
    const end = moment().toDate();
    return [start, end];
  }

  return (
    <Container fluid className="main-content-container px-4">
      <Row className="page-header py-4">
        <Col md="6">
          <PageTitle
            title="Estatísticas"
            subtitle="Visão Geral"
            className="text-sm-left mb-3"
          />
        </Col>
        <Col md="5" className="col d-flex align-items-center" />
      </Row>
      <Row className="mb-5">
        <Col xs="12" className="d-flex justify-content-between">
          {smallStats.map((stats, i) =>
            stats.value > 0 ? (
              <div key={i} style={{ width: "200px" }} className="mr-2 ml-2">
                <AvgCard
                  id={`small-stats-${i}`}
                  variation="1"
                  chartData={stats.datasets}
                  chartLabels={stats.chartLabels}
                  label={stats.label}
                  value={stats.value}
                  percentage={stats.percentage}
                  increase={stats.increase}
                  decrease={stats.decrease}
                />
              </div>
            ) : null
          )}
        </Col>
      </Row>

      <Card>
        <CardBody style={{ float: "right" }}>
          <Button onClick={() => createXlsx()}>Exportar</Button>
        </CardBody>
        <CardBody>
          <h2>Etapas</h2>
          <FilteredTable
            isLoading={isLoading}
            list={stats}
            columns={tableColumns}
            config={{
              pageSizeOptions: [31, 61, 91],
              searchableColumns: ["name", "label"],
              pageSize: 31,
            }}
          >
            <div className="label">Data</div>
            <div className="d-flex flex-row justify-content-between">
              <DateRangePicker
                required
                rangeDivider=" até "
                onChange={(value) => {
                  if (!value) {
                    value = getInitialDate();
                  }
                  setDate(value);
                  refreshTableData(value);
                }}
                maxDate={new Date()}
                format="dd/MM/y"
                value={date}
              />
            </div>
          </FilteredTable>
          <h2>Projetos Comprados</h2>
          <FilteredTable
            list={bdProjects}
            columns={tableColumnsProjects}
            isLoading={isLoading}
          />
          <h2>NPS</h2>
          {npsList
            ? `${npsList.nRating} respostas, score médio: ${
                npsList.totalRating / npsList.nRating
              }`
            : null}
          <br />
          {npsValue ? `NPS: ${npsValue}` : null}
          <FilteredTable
            isLoading={isLoading}
            list={npsList.projectsNps}
            columns={tableNps}
            config={{
              pageSizeOptions: [10, 20, 30],
              searchableColumns: ["_id", "count"],
              pageSize: 10,
            }}
          />
          <h2>Ambientes Comprados</h2>
          <FilteredTable
            isLoading={isLoading}
            list={environmentCount}
            columns={tableColumnsEnvironments}
            config={{
              pageSizeOptions: [10, 20, 30],
              searchableColumns: ["_id", "count"],
              pageSize: 10,
            }}
          />
        </CardBody>
      </Card>
    </Container>
  );
};
export default Stats;

import React from "react";
import PropTypes from "prop-types";

import AsyncTable from "./AsyncTable";
import AsyncFilters from "./AsyncFilters";

import { TableRowProvider } from "./TableRowContext";
import { AsyncTableProvider } from "./AsyncTableContext";
import { getUsersByFilter } from "../../actions/userV2";

function AsyncFilteredTable({ columns, config, children, customFilters }) {
  return (
    <>
      <AsyncTableProvider
        value={{
          searchableColumns: config.searchableColumns,
          pageSize: config.pageSize,
          customFilters,
          search: getUsersByFilter,
          onFilterChange: () => {
            console.log("Filter change");
          },
        }}
      >
        <TableRowProvider value={[]}>
          {children}
          <AsyncFilters pageSizeOptions={config.pageSizeOptions} />
          <AsyncTable columns={columns} />
        </TableRowProvider>
      </AsyncTableProvider>
    </>
  );
}

AsyncFilteredTable.propTypes = {
  searchableColumns: PropTypes.arrayOf(PropTypes.string),
};

AsyncFilteredTable.defaultProps = {
  list: [],
  customFilters: [],
  isLoading: false,
  config: {
    pageSizeOptions: [5, 10, 15, 20, 30],
    searchableColumns: null,
    pageSize: 10,
  },
};

export default AsyncFilteredTable;

import React, { useState } from "react";
import {
  Row,
  Card,
  Col,
  CardBody,
  Button,
  Form,
  FormTextarea,
} from "shards-react";
import { sendProjectNotification } from "../../../actions/projects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const Notifications = ({ projectId }) => {
  const [notification, setNotification] = useState({
    description: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  function sendNotification(ev) {
    ev.preventDefault();
    setIsLoading(true);
    sendProjectNotification(projectId, notification)
      .then(() => {
        setIsLoading(false);
        setNotification({ description: "" });
        toast.success("Notificação enviada com sucesso", { autoClose: 5000 });
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        toast.error(err.message, { autoClose: 5000 });
      });
    return false;
  }
  return (
    <Card small className="mb-4">
      <CardBody className="p-0">
        <Row>
          <Col>
            <h6 className="text-center m-0 mt-2">Notificações</h6>
          </Col>
        </Row>
        <div>
          <Form className="py-4" onSubmit={sendNotification}>
            <Row form className="mx-4">
              <Col md="12">
                <Row form>
                  <Col md="12" className="form-group">
                    <div className="label">Mensagem</div>
                    <div style={{ width: "100%" }}>
                      <FormTextarea
                        required
                        disabled={isLoading}
                        id="identifier"
                        placeholder="Escreva o texto de sua notificação aqui..."
                        value={notification.description}
                        onChange={(ev) => {
                          setNotification({
                            ...notification,
                            description: ev.target.value,
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Row form>
                  <Col md="12" className="form-group">
                    <Button
                      theme="accent"
                      className="ml-auto d-block"
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? "Enviando..." : "Enviar"}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      </CardBody>
    </Card>
  );
};

export default Notifications;

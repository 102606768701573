import React, { useState } from "react";

import { FormInput } from "shards-react";
import colors from "../../../utils/colors";

const AddTextItem = ({ onAdd, placeholder }) => {
  const [value, setValue] = useState("");
  function onAddClicked() {
    if (value) {
      onAdd(value);
      setValue("");
    }
  }

  function onKeyboardKeyDown(e) {
    if (e.key === "Enter") {
      e.target.value && onAddClicked();
    }
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        marginBottom: "10px",
      }}
    >
      <button
        className="material-icons mr-1 clickable transparent"
        type="button"
        onClick={onAddClicked}
        style={{
          paddingLeft: "5px",
          paddingRight: "5px",
          fontSize: "26px",
          marginTop: "2px",
          color: colors.success.value,
        }}
      >
        add
      </button>

      <FormInput
        placeholder={placeholder}
        value={value}
        onChange={(ev) => setValue(ev.target.value)}
        onKeyDown={onKeyboardKeyDown}
      />
    </div>
  );
};

AddTextItem.defaultProps = {
  placeholder: "Digite o texto aqui...",
};

export default AddTextItem;

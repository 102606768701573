import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";

import { MetadataContext } from "../../metadata/MetadataContext";
import { Modal } from "shards-react";
import { PageProvider } from "./PageContext";

import RenderPageContent from "./RenderPageContent";

import { getPDFDefaultPageItemsByCompany } from "../../../actions/projects";

const Page = (props) => {
  const { name, projectId, onUpdate, onSave, type } = props;
  const projectPDFDefaultPageItemsIds = props.page.defaults;
  const [showModal, setShowModal] = useState(false);
  const [page, setPage] = useState();

  const { scopedByCompany } = useContext(MetadataContext);
  const getPDFDefaultPageItemsScopedByCompany = scopedByCompany(
    getPDFDefaultPageItemsByCompany
  );

  const isValid =
    page && (projectPDFDefaultPageItemsIds.length || props.page.items.length);

  useEffect(() => {
    getPDFDefaultPageItemsScopedByCompany().then((pdfDefaultPageItems) => {
      pdfDefaultPageItems = pdfDefaultPageItems.filter(
        (item) => item.category === type
      );
      projectPDFDefaultPageItemsIds.forEach((id) => {
        let item = pdfDefaultPageItems.find((item) => item._id === id);
        if (item) {
          item.enabled = true;
        }
      });
      setPage({
        ...props.page,
        defaults: pdfDefaultPageItems,
      });
    });
  }, [props.page.defaults]);

  function onSaveClick(textItems, customTextItems) {
    return onSave(projectId, textItems, customTextItems, type).then(onUpdate);
  }

  return (
    <>
      <div className="user-activity__item pr-3 py-3">
        <div className="user-activity__item__icon">
          {isValid ? (
            <i className="material-icons" style={{ color: "#17c67185" }}>
              &#xE5CA;
            </i>
          ) : (
            <i className="material-icons" style={{ color: "#c41a3c91" }}>
              &#xE5CD;
            </i>
          )}
        </div>
        <div
          className="user-activity__item__content"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <span className="text-light" />
          <p>{name}</p>
        </div>
        <div className="user-activity__item__action ml-auto">
          <button
            className="ml-auto btn btn-sm btn-white"
            onClick={() => setShowModal(true)}
          >
            Detalhes
          </button>
        </div>
      </div>
      {showModal && page && (
        <Modal
          size="lg"
          modalContentClassName="card card-small"
          open={showModal}
          toggle={() => setShowModal(!showModal)}
        >
          <PageProvider
            value={{
              settings: page.defaults,
              customSettings: page.items,
            }}
          >
            <RenderPageContent name={name} onSave={onSaveClick} type={type} />
          </PageProvider>
        </Modal>
      )}
    </>
  );
};

Page.propTypes = {
  page: PropTypes.shape({
    title: PropTypes.string,
    defaults: PropTypes.arrayOf(PropTypes.string),
    items: PropTypes.array,
  }),
};

export default Page;

import React, { useEffect, useState, useContext } from "react";
import { listAdminUsers } from "../../actions/user";
import { required } from "../../utils/form_validation";
import {
  Row,
  Col,
  FormInput,
  FormSelect,
  FormGroup,
  FormFeedback,
} from "shards-react";
import { MetadataContext } from "../metadata/MetadataContext";

function BasicForm({
  enterprise,
  onChange,
  isLoading,
  activeStep,
  handleDrop,
}) {
  const { metadata } = useContext(MetadataContext);
  const companies = metadata.companies;
  const [users, setUsers] = useState([]);
  const [validations, setValidations] = useState({
    name: {
      validators: [required],
      isValid: true,
      accessor: "name",
      status: { neutral: true },
    },
    address: {
      validators: [required],
      isValid: true,
      accessor: "address",
      status: { neutral: true },
    },
    company: {
      validators: [required],
      isValid: true,
      accessor: "company",
      status: { neutral: true },
    },
    userMaster: {
      validators: [required],
      isValid: true,
      accessor: "userMaster",
      status: { neutral: true },
    },
    logo: {
      validators: [required],
      isValid: true,
      accessor: "logo",
      status: { neutral: true },
    },
  });

  function getHtmlValidations(key, formValidations) {
    return {
      [formValidations[key].isValid ? "valid" : "invalid"]: true,
    };
  }

  function onBasicFormChange(key, value) {
    validateField(key, validations, enterprise, value);

    onChange({ ...enterprise, [key]: value }, activeStep);
  }

  function validateField(validatorName, formValidations, enterprise, value) {
    let fieldValidations = formValidations[validatorName].validators;
    let isValid = fieldValidations.reduce((isValid, nextValidation) => {
      return isValid && nextValidation(value, enterprise);
    }, true);

    formValidations[validatorName].isValid = isValid;

    formValidations[validatorName].status = getHtmlValidations(
      validatorName,
      formValidations
    );
    setValidations({ ...formValidations });

    return isValid;
  }

  function uploadLogo(e) {
    handleDrop([e.target.files[0]]).then((responses) => {
      onBasicFormChange("logo", responses[0].link);
    });
  }

  useEffect(() => {
    listAdminUsers().then((res) => {
      setUsers(res);
    });
  }, []);

  return (
    <Row form className="mx-4">
      <Col md="12" className="form-group">
        <FormGroup>
          <div className="label">Nome do empreendimento</div>
          <FormInput
            required
            disabled={isLoading}
            onChange={(ev) => onBasicFormChange("name", ev.target.value)}
            id="name"
            value={enterprise.name}
            {...validations["name"].status}
          />
          <FormFeedback valid />
          <FormFeedback invalid>Nome inválido</FormFeedback>
        </FormGroup>
      </Col>
      <Col md="12" className="form-group">
        <FormGroup>
          <div className="label">Endereço</div>
          <FormInput
            required
            disabled={isLoading}
            onChange={(ev) => onBasicFormChange("address", ev.target.value)}
            id="address"
            value={enterprise.address}
            {...validations["address"].status}
          />
          <FormFeedback valid />
          <FormFeedback invalid>Endereço Invalido</FormFeedback>
        </FormGroup>
      </Col>
      <Col md="12" className="form-group">
        <FormGroup>
          <div className="label">Logo empreendimento</div>
          <div className="custom-file mb-3">
            <input
              type="file"
              className="custom-file-input"
              id="logo"
              onChange={(e) => uploadLogo(e)}
            />
            <label className="custom-file-label" htmlFor="customFile">
              Enviar logo do empreendimento
            </label>
          </div>
          {enterprise.logo && (
            <div>
              <img
                src={enterprise.logo}
                alt="logo"
                style={{ width: "300px" }}
              />
            </div>
          )}
        </FormGroup>
      </Col>
      <Col xs="12" className="form-group">
        <FormGroup>
          <div className="label">Contexto</div>
          <FormSelect
            size="sm"
            required
            id="user-input-company"
            value={enterprise.company}
            onChange={(ev) => onBasicFormChange("company", ev.target.value)}
            defaultValue=""
            {...validations["company"].status}
          >
            <option value="" disabled>
              Nenhum
            </option>
            {companies.map((comp, idx) => (
              <option key={idx} value={comp.label}>
                {comp.name}
              </option>
            ))}
          </FormSelect>
          <FormFeedback valid />
          <FormFeedback invalid>Contexto inválido</FormFeedback>
        </FormGroup>
      </Col>
      <Col xs="12" className="form-group">
        <FormGroup>
          <div className="label">Usuário Master</div>
          <FormSelect
            size="sm"
            required
            id="user-master-input"
            value={enterprise.userMaster}
            onChange={(ev) => onBasicFormChange("userMaster", ev.target.value)}
            defaultValue=""
            {...validations["userMaster"].status}
          >
            <option value="" disabled>
              Nenhum
            </option>
            {users.map((user, idx) => (
              <option key={idx} value={user._id}>
                {user.email}
              </option>
            ))}
          </FormSelect>
          <FormFeedback valid />
          <FormFeedback invalid>Usuário inválido</FormFeedback>
        </FormGroup>
      </Col>
    </Row>
  );
}

BasicForm.propTypes = {};

export default BasicForm;

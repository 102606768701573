import React from "react";

import { Row, Col, FormSelect, Button } from "shards-react";
import ReactTags from "react-tag-input-custom-search";

function VariationForm({
  variation,
  onChange,
  activeStep,
  variationsIndex,
  enterprise,
  environment,
  questions,
}) {
  return (
    <Row>
      {variationsIndex > 0 ? (
        <Col xs="12">
          <hr className="w-100" style={{ borderTop: "1px solid black" }} />
        </Col>
      ) : null}

      <Col xs="12" className="d-flex align-items-center">
        <FormSelect
          style={{
            height: "fit-content",
          }}
          disabled={environment.ref}
          value={variation.hash}
          onChange={(ev) => {
            variation.hash = ev.target.value;
            const index = ev.nativeEvent.target.selectedIndex;
            const text = ev.nativeEvent.target[index].text;
            variation.name = text;
            onChange({ ...enterprise }, activeStep);
          }}
        >
          <option value="" disabled>
            Escolha o ambiente...
          </option>
          {questions.map((q, i) => {
            return (
              <option key={i} value={q.hash}>
                {q.name}
              </option>
            );
          })}
        </FormSelect>

        <Button
          size="xs"
          outline
          squared
          theme="light"
          disabled={environment.ref}
          onClick={() => {
            environment.variations = environment.variations.filter(
              (v, i) => i !== variationsIndex
            );
            onChange({ ...enterprise }, activeStep);
          }}
        >
          <span className="material-icons hover-click" style={{ color: "red" }}>
            delete
          </span>
        </Button>
      </Col>
      {variation.hash ? (
        <Col xs="12">
          <Row className="px-3 mt-3">
            <Col xs="12" className="mt-2">
              <h5 className="text-center">Condições</h5>
              <ReactTags
                minQueryLength={0}
                placeholder="Digite aqui..."
                tags={variation.tags}
                suggestionsFilter={(item) => {
                  return !variation.tags.find((tag) => item.name === tag.name);
                }}
                suggestions={enterprise.categories.map(
                  (categorie, categorieIdx) => {
                    return {
                      id: categorieIdx + 1,
                      name: categorie.name,
                    };
                  }
                )}
                autofocus={false}
                handleDelete={(index) => {
                  if (environment.ref) return;
                  variation.tags = variation.tags.filter(
                    (tag, i) => i !== index
                  );
                  onChange({ ...enterprise }, activeStep);
                }}
                allowNew={false}
                handleAddition={(obj) => {
                  if (environment.ref) return;
                  if (!variation.tags.find((tag) => tag.name === obj.name)) {
                    variation.tags.push(obj);
                    onChange(
                      {
                        ...enterprise,
                      },
                      activeStep
                    );
                  }
                }}
              />
            </Col>
          </Row>
        </Col>
      ) : null}
    </Row>
  );
}

VariationForm.propTypes = {};

export default VariationForm;

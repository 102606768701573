import React, { useState } from "react";
import PropTypes from "prop-types";

import { Row, FormSelect, Card, Col, CardBody } from "shards-react";
import ConfirmationButton from "../common/ConfirmationButton";
import { updateReviewAdmin } from "../../actions/projects";
import { toast } from "react-toastify";

import { ExtendReviewAutomation } from "./AdminStatusChange/ExtendReviewAutomation";

function AdminStatusChange({
  projectId,
  logDates,
  reload,
  projectData,
  messages,
}) {
  const [review, setReview] = useState("");

  function onStatusChangeClick(action) {
    updateReviewAdmin(projectId, review, action)
      .then(() => {
        toast.success("Projeto atualizado com sucesso");
        setReview("");
        reload();
      })
      .catch(() => {
        setReview("");
        toast.error("Ocorreu um erro");
      });
  }

  return (
    <Card small className="mb-4">
      <CardBody className="p-0">
        <Row>
          <Col>
            <h6 className="text-center m-0 mt-2">Alterar step do projeto</h6>
          </Col>
        </Row>

        <div className="pb-4 border-top mt-4">
          <Row>
            <Col>
              <h6 className="text-center m-0 mt-2">Desativar Lembretes</h6>
            </Col>
          </Row>
          <Row>
            <Col>
              <ExtendReviewAutomation
                projectId={projectId}
                projectInfo={projectData.projectInfo}
                messages={messages}
                reload={reload}
              />
            </Col>
          </Row>
        </div>

        <div className="user-details__user-data border-top p-4">
          <Row>
            <Col>
              <h6 className="text-center m-0 mt-2">Revisão</h6>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center mt-1  mb-3">
              <FormSelect
                style={{ width: "105px", height: "fit-content" }}
                value={review}
                onChange={(ev) => setReview(ev.target.value)}
              >
                <option className="text-center" value="" disabled>
                  Selecionar...
                </option>
                <option className="text-center" value="approved">
                  Em produção
                </option>
                {logDates.preview ? (
                  <option value="preview">Proposta Inicial</option>
                ) : null}
                {logDates.first_review ? (
                  <option value="first_review">1º Revisão</option>
                ) : null}
                {logDates.second_review ? (
                  <option value="second_review">2º Revisão</option>
                ) : null}
              </FormSelect>

              <ConfirmationButton
                theme="success"
                onClick={() => onStatusChangeClick("client-approval")}
                className="ml-2"
                disabled={!review || review === "approved"}
              >
                Enviar para cliente
              </ConfirmationButton>
              <ConfirmationButton
                theme="success"
                onClick={() => onStatusChangeClick("approve")}
                className="ml-2"
                disabled={!review}
              >
                Aprovar Projeto
              </ConfirmationButton>
              <ConfirmationButton
                theme="danger"
                onClick={() => onStatusChangeClick("reject")}
                className="ml-2"
              >
                Solicitar revisão
              </ConfirmationButton>
            </Col>
          </Row>
          <Row>
            <Col>
              <h6 className="text-center m-0 mt-2">Legenda</h6>
              <ul>
                <li>
                  <b>Enviar para o cliente:</b> Irá abrir o step selecionado
                  para o cliente aprovar ou pedir revisão
                </li>
                <li>
                  <b>Aprovar projeto:</b> Ação que ira imitar o cliente
                  aprovando o projeto do step selecionado.
                </li>
                <li>
                  <b>Solicitar revisão:</b> Ação que ira imitar o cliente
                  solicitando revisão do projeto.
                </li>
              </ul>
            </Col>
          </Row>
        </div>
      </CardBody>
    </Card>
  );
}

AdminStatusChange.propTypes = {
  /**
   * The project ID.
   * */
  projectId: PropTypes.string.isRequired,
  logDates: PropTypes.object.isRequired,
  reload: PropTypes.func.isRequired,
};

export default AdminStatusChange;

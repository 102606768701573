import React, { useState, useEffect } from "react";
import { Container, Card } from "shards-react";

import { addCategory, getTags, deleteCategory } from "../../actions/mobiTag";

import "react-toastify/dist/ReactToastify.min.css";

import SafeDeleteButton from "../common/SafeDeleteButton";
import Moment from "react-moment";

import ActionHeader from "./ActionHeader";
import { toast } from "react-toastify";

import FilteredTable from "../FilteredTable/index";

const MobiTagsHOC = () => {
  const [tags, setTags] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const tableColumns = [
    {
      Header: "NOME",
      accessor: "name",
      className: "text-center",
    },
    {
      Header: "DATA CRIAÇÃO",
      accessor: "createdAt",
      className: "text-center",
      Cell: (row) => {
        return (
          <Moment format="DD/MM/YYYY HH:mm">{row.original.createdAt}</Moment>
        );
      },
    },
    {
      Header: "",
      accessor: "",
      className: "text-center",
      Cell: (row) => {
        return (
          <SafeDeleteButton
            onRemove={() => {
              return onRemove(row.original._id);
            }}
          />
        );
      },
    },
  ];

  useEffect(load, []);

  function load() {
    setIsLoading(true);
    getTags()
      .then((categories) => {
        setIsLoading(false);
        setTags(categories);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }

  function onAdd(name) {
    setIsLoading(true);
    return addCategory(name)
      .then(() => {
        setIsLoading(false);
        toast.success("Categoria criada com sucesso!", { autoClose: 3000 });
        load();
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.message, { autoClose: 5000 });
      });
  }

  function onRemove(id) {
    setIsLoading(true);
    return deleteCategory(id)
      .then((res) => {
        setIsLoading(false);
        toast.success(res.msg, { autoClose: 3000 });
        load();
      })
      .catch(() => {
        setIsLoading(false);
      });
  }

  return (
    <Card>
      <Container>
        <ActionHeader onAdd={onAdd} isLoading={isLoading} />
        <FilteredTable
          list={tags}
          columns={tableColumns}
          isLoading={isLoading}
          config={{
            pageSizeOptions: [5, 10, 15, 20, 30],
            searchableColumns: ["name"],
            pageSize: 10,
          }}
        />
      </Container>
    </Card>
  );
};

export default MobiTagsHOC;

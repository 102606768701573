import React, { useState, useEffect, useContext } from "react";

import {
  Container,
  Card,
  Button,
  CardBody,
  ModalFooter,
  Modal,
} from "shards-react";
import PageTitle from "../components/common/PageTitle";

import {
  getPDFTextItems,
  postPDFTextItems,
  getPDFDefaultPageItemsByCompany,
  postPDFDefaultPageItemsByCompany,
} from "../actions/projects";

import AddTextItem from "../components/project/PDFPages/AddTextItem";

import CategorySetting from "../components/PDFConfig/CategorySetting";
import Setting from "../components/PDFConfig/Setting";

import { toast } from "react-toastify";
import { MetadataContext } from "../components/metadata/MetadataContext";

function PDFConfig() {
  const { scopedByCompany } = useContext(MetadataContext);
  const getPDFDefaultPageItemsScopedByCompany = scopedByCompany(
    getPDFDefaultPageItemsByCompany
  );
  const [contractorSettings, setContractorSettings] = useState([]);
  const [woodWorkerSettings, setWoodWorkerSettings] = useState([]);
  const [showModal, setShowModal] = useState({
    woodWorker: false,
    contractor: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  function reload() {
    return Promise.all([
      getPDFTextItems(),
      getPDFDefaultPageItemsScopedByCompany(),
    ]).then((res) => {
      const [categorySettings, pdfDefaultPageItems] = res;
      setContractorSettings(categorySettings);
      setWoodWorkerSettings(pdfDefaultPageItems);
    });
  }

  useEffect(() => {
    reload();
  }, []);

  function onContractorSettingsSaveClick() {
    setIsLoading(true);
    const newSettings = contractorSettings
      .filter((s) => s.items.length)
      .reduce((acc, cur) => {
        acc = acc.concat(cur.items);
        return acc;
      }, []);
    postPDFTextItems(newSettings)
      .then(() => {
        toast.success("Salvo com sucesso!");

        reload().then(() => {
          setIsLoading(false);
        });
      })
      .catch(() => {
        toast.error("Erro ao salvar!");
        setIsLoading(false);
      });
  }

  function onWoodWorkerSettingsSaveClick() {
    setIsLoading(true);
    postPDFDefaultPageItemsByCompany(woodWorkerSettings)
      .then(() => {
        toast.success("Salvo com sucesso!");

        reload().then(() => {
          setIsLoading(false);
        });
      })
      .catch(() => {
        toast.error("Erro ao salvar!");
        setIsLoading(false);
      });
  }
  return (
    <>
      <Container
        fluid
        className="main-content-container px-4 ml-auto mr-auto"
        style={{ maxWidth: "1024px" }}
      >
        <div className="page-header py-4">
          <PageTitle
            title="Configurações do PDF"
            subtitle={"Visão Geral"}
            className="text-sm-left mb-3"
          />
        </div>

        <Card>
          <CardBody className="d-flex flex-column align-items-center justify-content-center">
            <div className="pr-3 py-3 w-100">
              <div
                className="user-activity__item__content"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <span className="text-light" />
                <p>Configurações para empreiteiro</p>
              </div>
              <div className="user-activity__item__action ml-auto">
                <button
                  className="ml-auto btn btn-sm btn-white"
                  onClick={() =>
                    setShowModal({ ...showModal, contractor: true })
                  }
                >
                  Detalhes
                </button>
              </div>
            </div>
            <div className="pr-3 py-3 w-100">
              <div
                className="user-activity__item__content"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <span className="text-light" />
                <p>Configurações para marceneiro</p>
              </div>
              <div className="user-activity__item__action ml-auto">
                <button
                  className="ml-auto btn btn-sm btn-white"
                  onClick={() =>
                    setShowModal({ ...showModal, woodWorker: true })
                  }
                >
                  Detalhes
                </button>
              </div>
            </div>
          </CardBody>
        </Card>
      </Container>
      <Modal
        size="lg"
        modalContentClassName="card card-small"
        open={showModal.contractor}
        toggle={() => {
          setShowModal({
            ...showModal,
            contractor: !showModal.contractor,
          });
        }}
      >
        {!!contractorSettings.length && (
          <div style={{ maxHeight: "70vh", overflowY: "auto" }}>
            <div className="p-3">
              {contractorSettings.map((setting) => {
                return (
                  <CategorySetting
                    key={setting.id}
                    onAddSubSetting={(value) => {
                      setting.items.push(value);
                    }}
                    onDeleteSubSetting={(value) => {
                      if (value.isNew) {
                        setting.items = setting.items.filter(
                          (item) => item.id !== value.id
                        );
                      } else {
                        setting.items = setting.items.filter(
                          (item) => item._id !== value._id
                        );
                      }

                      setContractorSettings([...contractorSettings]);
                    }}
                    onDeleteSetting={(value) => {
                      setContractorSettings(
                        contractorSettings.filter(
                          (item) => item.name !== value.name
                        )
                      );
                    }}
                    onToggleSetting={() => {}}
                    onToggleByCategory={() => {}}
                    name={setting.name}
                    id={setting.id}
                    enabled={setting.enabled}
                    items={setting.items}
                    item={setting}
                    customSettings={[]}
                  />
                );
              })}
            </div>
            <div className="pl-3 pr-2">
              <AddTextItem
                onAdd={(value) => {
                  if (
                    contractorSettings.find(
                      (cs) => cs.name.toLowerCase() === value.toLowerCase()
                    )
                  )
                    return;
                  contractorSettings.push({ name: value, items: [] });
                  setContractorSettings([...contractorSettings]);
                }}
                placeholder="Digite o texto da categoria aqui..."
              />
            </div>
          </div>
        )}
        <ModalFooter>
          <Button
            className="ml-auto"
            onClick={onContractorSettingsSaveClick}
            disabled={isLoading}
          >
            {isLoading ? "Salvando..." : "Salvar"}
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        size="lg"
        modalContentClassName="card card-small"
        open={showModal.woodWorker}
        toggle={() => {
          setShowModal({
            ...showModal,
            woodWorker: !showModal.woodWorker,
          });
        }}
      >
        {!!woodWorkerSettings.length && (
          <div style={{ maxHeight: "70vh", overflowY: "auto" }}>
            <div className="p-3">
              {woodWorkerSettings.map((setting) => {
                return (
                  <Setting
                    key={setting._id}
                    onDeleteSetting={(value) => {
                      setWoodWorkerSettings(
                        woodWorkerSettings.filter(
                          (item) => item.text !== value.text
                        )
                      );
                    }}
                    name={setting.text}
                    item={setting}
                  />
                );
              })}
            </div>
            <div className="pl-3 pr-2">
              <AddTextItem
                onAdd={(value) => {
                  if (woodWorkerSettings.find((ws) => ws.text === value))
                    return;
                  woodWorkerSettings.push({
                    text: value,
                    type: "text",
                    category: "woodWorker",
                  });
                  setWoodWorkerSettings([...woodWorkerSettings]);
                }}
                placeholder="Digite o texto da categoria aqui..."
              />
            </div>
          </div>
        )}
        <ModalFooter>
          <Button
            className="ml-auto"
            onClick={onWoodWorkerSettingsSaveClick}
            disabled={isLoading}
          >
            {isLoading ? "Salvando..." : "Salvar"}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

PDFConfig.propTypes = {};

export default PDFConfig;

import React, { useState, useEffect, useContext, useMemo } from "react";

import { useParams, Route, Switch, useHistory } from "react-router-dom";

import "react-toastify/dist/ReactToastify.min.css";

import { MetadataContext } from "../components/metadata/MetadataContext";
import { getQueryStringValue } from "../actions/auth";
import { getMessages, deleteMessage } from "../actions/message";
import { formatBytes } from "../utils/sanitize";
import { getProject, getNumberProjectsByUser } from "../actions/projects";
import { setAdminAccessToken } from "../actions/auth";
import ProjectManager from "../components/project/ProjectManager";
import QualityPreview from "../components/project/QualityPreview";
import { PROJECT_PROGRESS_STATUS } from "../utils/constants";
import { Chat as ProjectChat } from "../components/Chat";
import { ShowLightbox } from "../components/common/ShowLightbox";
import { finalProjectEmail } from "../actions/email";
import { updateProjectStatus, updateLogDates } from "../actions/projects";
import { toast } from "react-toastify";
import {
  ProjectData,
  ProjectInfo as ProjectInfoData,
} from "../components/project/data";

const Project = () => {
  const history = useHistory();
  const { id } = useParams();
  const { isAllowed, socket } = useContext(MetadataContext);
  const [lightbox, setLightbox] = useState({
    open: false,
    img: "",
    index: 0,
    images: [],
    caption: [],
  });
  const [adminTokenAccess, setAdminTokenAccess] = useState();
  const [, setState] = useState();

  // const [lastVersion, setLastVersion] = useState({ pdf: "", files: [] });

  const [showChat, setShowChat] = useState(false);
  const [chatLength, setChatLength] = useState(0);
  const [badgeCount, setBadgeCount] = useState(0);
  const [messages, setMessages] = useState([]);

  const [numberProjects, setNumberProjects] = useState(0);
  const { metadata } = useContext(MetadataContext);
  const [chatOpened, setChatOpened] = useState(false);

  const [data, setData] = useState(null);

  const createAdminAccessToken = (userIdGenerateAccess) => {
    setAdminAccessToken(userIdGenerateAccess).then((result) => {
      if (result && result.tokenAccess) {
        setAdminTokenAccess(result.tokenAccess);
      }
    });
  };

  useEffect(() => {
    /* Deprecated
      The old format was '/project?id=${id}', this is for compatibility reasons.
    */
    const oldId = getQueryStringValue("id");
    if (oldId) {
      history.replace(`/project/${oldId}`);
    }
    if (id) {
      reload();
    }
  }, []);

  useEffect(() => {
    socket.emit("notification_room", {
      room: `${id}`,
    });

    socket.on("notification", (res) => {
      if (res.room === id) {
        reload();
        toast.success("O cliente atualizou o projeto!", { autoClose: 5000 });
      }
    });

    return () => {
      socket.off("notification");
    };
  }, []);

  const parseMessage = (message) => {
    return {
      ...message,
      author: message.type === "user" ? "them" : "me",
      type: message.kind,
      data: {
        height: 250,
        width: 250,
        size: formatBytes(message.size),
        uri: message.thumbnail ? message.thumbnail : message.link,
        status: {
          click: false,
          loading: 0,
        },
      },
      deletable:
        message.type === "system" && isAllowed("admin-ladecora:project:chat"),
      time: new Date(message.createdAt),
      text: message.text,
    };
  };

  const reload = () => {
    return getProject(id, {
      isDetailed: true,
    }).then((data) => {
      if (data?.error === "projeto não encontrado") {
        history.replace(`/404`);
        return;
      }
      getMessages(id, "preview")
        .then((res) => {
          const messageList = res.sort((a, b) => {
            let d1 = new Date(a.createdAt);
            let d2 = new Date(b.createdAt);
            if (d1 > d2) return 1;
            if (d2 > d1) return -1;
            return 0;
          });

          setMessages(messageList.map(parseMessage));

          const badgeCount = messageList.filter(
            (message) => !message.isRead && message.type === "user"
          ).length;

          if (badgeCount > 0) {
            setShowChat(true);
          }

          setBadgeCount(badgeCount);
          setChatLength(messageList.length);
        })
        .catch((err) => {
          console.log(err);
        });

      let dados = data;
      if (dados.enterprise && dados.enterprise.room.length > 0) {
        dados.name = `${dados.enterprise.room[0]} - ${dados.name}`;
      }
      getNumberProjectsByUser(dados.user._id).then((res) => {
        setNumberProjects(res);
      });

      let projectData = {};

      if (dados.enterprise.id) {
        const enterprise = data.enterprise.id;
        const style = enterprise.styles.filter((style) => {
          if (style.name === data.enterprise.style) {
            return style;
          }
        })[0];
        const categories = data.enterprise.categories
          .map((cat) => `${cat.category} - ${cat.value}`)
          .join("\n");
        const roomFile = enterprise.rooms.filter((room) => {
          if (room.name === data.enterprise.room[0]) {
            return style;
          }
        })[0];
        data.learningEnvironment.details = `Planta: ${data.enterprise.room.reduce(
          (acc, cur, idx) => {
            acc += `${idx !== 0 ? "," : ""}${cur}`;
            if (data.enterprise.room.length - 1 === idx) {
              acc = `(${acc})`;
            }
            return acc;
          },
          ""
        )} \n Material da porta: ${
          enterprise.learningEnvironment.doorColor
        } \n Cor da esquadria: ${
          enterprise.learningEnvironment.frameColor
        } \n Possui Gesso? ${enterprise.learningEnvironment.plaster} \n ${
          enterprise.learningEnvironment.others
        }`;
        if (roomFile.bluePrints) {
          data.learningEnvironment.files = [
            ...data.learningEnvironment.files,
            ...roomFile.bluePrints.map((bluePrint) => bluePrint.link),
          ];
        }

        data.inspirations.details = `Estilo: ${data.enterprise.style} \n Comentario: ${style.details} \n\n Categorias: \n ${categories}`;
        data.inspirations.files = style.files.map((file) => {
          return { source: file.link, name: file.name };
        });
      }

      if (dados.user) {
        const getCompanyName = metadata.companies.find(
          (company) => company.label === dados.user.company
        );

        createAdminAccessToken(dados.user._id);

        projectData = {
          ...projectData,
          companyName: getCompanyName?.name,
          user: { ...dados.user.profile, _id: dados.user._id },
          region: {
            city: dados.user.address.city,
            state: dados.user.address.state,
          },
          createdUserDate: dados.user.createdAt,
          tokStokToken: dados.user.userIdTokStok,
          userIdTokStok: dados.user.userIdTokStok,
          company: dados.user.company,
          email: dados.user.email,
          answerDefaultQuestion: dados.user.answerDefaultQuestion,
        };
      }
      const pi = new ProjectInfoData({
        title: dados.name,
        id: dados._id,
        furnitures: dados.furnitures,
        createdAt: dados.createdAt,
        updatedAt: dados.updatedAt,
        status: dados.status,
        lastStep: dados.forcedStep || Math.max(...dados.completedSteps),
        render: dados.render || {},
        forcedStep: dados.forcedStep,
        owner: dados.owner,
        ownerReview: dados.ownerReview,
        company: dados.company,
        complexity: dados.complexity,
        trelloCardUrl: dados.trelloCardUrl,
        trelloCardName: dados.trelloCardName,
        trelloCardListName: dados.trelloCardListName,
        previewData: dados.finalProject.preview.lastUpdate,
        firstReviewDate: dados.finalProject.firstReview.lastUpdate,
        secondReviewDate: dados.finalProject.secondReview.lastUpdate,
        dropBoxFolder: dados.dropBoxFolder,
        answers: dados.answers,
        learningEnvironment: dados.learningEnvironment,
        inspirations: dados.inspirations,
        furnitureMeasures: dados.furnitureMeasures,
        finalProject: dados.finalProject,
        preview: dados.finalProject.preview,
        firstReview: dados.finalProject.firstReview,
        secondReview: dados.finalProject.secondReview,
        lastVersion: dados.finalProject.lastVersion,
        logEmail: dados.logEmail,
        logDates: dados.logDates,
        checkList: dados.checklist,
        checkListBook: dados.checklistbook,
        template: dados.questionTemplate.template,
        enterprise: dados.enterprise,
      });

      setData(
        new ProjectData({
          ...projectData,
          projectInfo: pi,
          comments: dados.comments.reverse(),
        })
      );
      return pi;
    });
  };

  const onNewMessages = (messages) => {
    setMessages(messages);
    setShowChat(true);
    setBadgeCount(0);
  };

  const onDeleteMessage = (messageToDelete) => {
    return new Promise((resolve, reject) => {
      deleteMessage(messageToDelete._id)
        .then(() => {
          setMessages(
            messages.filter((message) => message._id !== messageToDelete._id)
          );
          resolve();
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  };

  const onChatToggle = (value) => {
    if (value !== chatOpened) {
      setChatOpened(value);
    }
  };

  const sendLightbox = (data, idx, caption = []) => {
    if (Array.isArray(data)) {
      setLightbox({
        ...lightbox,
        images: data,
        open: true,
        index: idx,
        caption,
      });
    } else {
      setLightbox({
        ...lightbox,
        index: 0,
        images: [data],
        open: true,
        caption,
      });
    }
  };

  const sendReview = (status) => {
    const title = PROJECT_PROGRESS_STATUS.asTitle()[status];
    let text =
      data.projectInfo.company === "tok_stok"
        ? "Assessoria pronta para análise | "
        : "Projeto pronto para análise | ";
    finalProjectEmail({
      name: data.user.firstName,
      _id: data.user._id,
      token: data.tokStokToken,
      email: data.email,
      status: status,
      subject: `${text}` + `${data.projectInfo.title}`,
      projectId: data.projectInfo.id,
    });
    updateProjectStatus(data.projectInfo.id, status);
    updateLogDates(data.projectInfo.id, status);
    setData(
      new ProjectData({
        ...data,
        projectInfo: {
          ...data.projectInfo,
          status,
        },
      })
    );

    toast.success(`${title} enviado com sucesso`, {
      autoClose: 5000,
    });
  };
  if (!id) return null;

  const projetCompanyName = useMemo(() => data?.companyName, [data]);

  const projectChat = useMemo(
    () => (
      <ProjectChat
        readOnly={!isAllowed("admin-ladecora:project:chat")}
        onUpdate={() => setState({})}
        onNewMessages={onNewMessages}
        onDeleteMesssage={onDeleteMessage}
        senderId={id}
        show={showChat}
        chatOpened={chatOpened}
        messages={messages}
        badgeCount={badgeCount}
        onChatToggle={onChatToggle}
        companyName={projetCompanyName}
        reload={reload}
        sendLightbox={sendLightbox}
      />
    ),
    [showChat, messages, chatOpened, badgeCount, projetCompanyName]
  );
  return (
    <>
      {lightbox.open && (
        <ShowLightbox lightbox={lightbox} setLightbox={setLightbox} />
      )}
      <Switch>
        <Route path={"/project/:id"} exact={true}>
          <ProjectManager
            projectData={data}
            reload={reload}
            chatLength={chatLength}
            showChat={showChat}
            setShowChat={setShowChat}
            onDataChanged={(d) => setData(new ProjectData(d))}
            adminTokenAccess={adminTokenAccess}
            numberProjects={numberProjects}
            sendLightbox={sendLightbox}
            messages={messages}
          />
        </Route>
        <Route path={"/project/:id/quality/:status"} exact={true}>
          <QualityPreview
            sendLightbox={sendLightbox}
            projectData={data}
            reload={reload}
            onDataChanged={(d) => setData(new ProjectData(d))}
            sendReview={sendReview}
            setShowChat={setShowChat}
          />
        </Route>
      </Switch>
      {projectChat}
    </>
  );
};
export default Project;

exports.downloadLink = (fileURL, fileName) => {
  return fetch(fileURL)
    .then((response) => response.blob())
    .then((blob) => {
      const blobURL = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = blobURL;
      a.style = "display: none";

      if (fileName && fileName.length) a.download = fileName;
      document.body.appendChild(a);
      a.click();
      return;
    })
    .catch((err) => console.log(err));
};

exports.download = function (fileURL, fileName) {
  // for non-IE
  if (!window.ActiveXObject) {
    var save = document.createElement("a");
    save.href = fileURL;
    save.target = "_self";
    var filename = fileURL.substring(fileURL.lastIndexOf("/") + 1);
    save.download = fileName || filename;
    if (
      navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
      navigator.userAgent.search("Chrome") < 0
    ) {
      document.location = save.href;
      // window event not working here
    } else {
      var evt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: false,
      });
      save.dispatchEvent(evt);
      (window.URL || window.webkitURL).revokeObjectURL(save.href);
    }
  }

  // for IE < 11
  else if (!!window.ActiveXObject && document.execCommand) {
    var _window = window.open(fileURL, "_blank");
    _window.document.close();
    _window.document.execCommand("SaveAs", true, fileName || fileURL);
    _window.close();
  }
};

exports.getFileExtension = (fileName) => {
  return fileName.split(".").pop();
};

exports.isExtensionValid = (value) => {
  const validExtension = /(jpeg|png|jpg|jfif|heic|webp)/i;

  return value.match(validExtension);
};

exports.guestAllQuestion = (tree) => {
  let allQuestions = [];
  let childrens = [];

  tree.forEach((e) => {
    if (e.type === "multiple" || e.type === "upload") {
      allQuestions.push(e);
    }
    if (e.children) {
      if (e.children.length > 0) {
        e.children.forEach((node) => {
          childrens.push(node);
        });
      }
    }
  });

  var i = 0;
  while (childrens.length > 0 || i < 1000) {
    let newChildrens = [];
    i++;
    childrens.forEach((e) => {
      if (e.type === "multiple") {
        allQuestions.push(e);
      }
      if (e.children) {
        if (e.children.length > 0) {
          e.children.forEach((node) => {
            newChildrens.push(node);
          });
        }
      }
    });

    childrens = newChildrens;
  }

  return allQuestions;
};

exports.getlabel = (question, allQuestions) => {
  var result = "";
  allQuestions.forEach((originalQuestion) => {
    if (originalQuestion.title === question.question) {
      originalQuestion.input_list.forEach((input) => {
        if (input.image_path === question.answer) {
          result = input.label;
        }
      });
    }
  });
  return result;
};

exports.getQuestion = (question, allQuestions) => {
  var result = {};
  allQuestions.forEach((originalQuestion) => {
    if (originalQuestion.title === question.question) {
      result = originalQuestion;
    }
  });
  return result;
};

exports.getAnwerLabel = (question, img) => {
  let result;
  question.input_list &&
    question.input_list.forEach((elem) => {
      if (elem.image_path === img) {
        result = elem.label;
      }
    });
  return result;
};

exports.dataURItoBlob = function (dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  let byteString = atob(dataURI.split(",")[1]);

  // write the bytes of the string to an ArrayBuffer
  let ab = new ArrayBuffer(byteString.length);
  let ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  //Old Code
  //write the ArrayBuffer to a blob, and you're done
  //var bb = new BlobBuilder();
  //bb.append(ab);
  //return bb.getBlob(mimeString);

  //New Code
  return new Blob([ab]);
};

import React, { useState } from "react";
import {
  InputGroup,
  InputGroupAddon,
  FormInput,
  InputGroupText,
} from "shards-react";

const SearchInput = ({ className, onChange, disabled }) => {
  const [value, setValue] = useState("");

  function onLocalChange(ev) {
    setValue(ev.target.value);
    onChange(ev.target.value);
  }

  return (
    <InputGroup className={className && className} seamless size="sm">
      <InputGroupAddon type="prepend">
        <InputGroupText>
          <i className="material-icons">search</i>
        </InputGroupText>
      </InputGroupAddon>
      <FormInput
        value={value}
        placeholder="Digite para pesquisar"
        disabled={disabled}
        onChange={onLocalChange}
      />
    </InputGroup>
  );
};

export default SearchInput;

import React, { useState } from "react";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import { Row } from "shards-react";
import Loader from "react-loader-spinner";
import { uploadFile } from "../../services/file";

import { toast } from "react-toastify";

const DropzoneRender = ({
  finalProject,
  stepName,
  updateStep,
  projectInfo,
}) => {
  const [values, setValues] = useState({
    error: false,
    message: "",
    loading: false,
  });

  const handleDrop = (files) => {
    Promise.all(
      files.map((oldFile) => {
        setValues({ ...values, loading: true });

        return uploadFile({
          path: `projects/${projectInfo.id}/${stepName}`,
          destination: `projects/${projectInfo.id}/${stepName}/thumbnails`,
          width: 600,
          file: oldFile,
          sameName: true,
        }).then((data) => {
          const fileNameFullsize = data.fullSize.substring(
            data.fullSize.lastIndexOf("/") + 1
          );
          const fileNameThumbnail = data.thumbnail.substring(
            data.thumbnail.lastIndexOf("/") + 1
          );
          finalProject[stepName].files.push({
            file: fileNameFullsize,
            thumbnail: fileNameThumbnail,
            comments: "",
            tag: "decorationPanel",
          });

          return updateStep(finalProject, projectInfo.id);
        });
      })
    ).then(() => {
      toast.success("Upload feito com sucesso", {
        autoClose: 5000,
      });
      setValues({
        ...values,
        message: "Upload feito com sucesso",
        loading: false,
      });
      setTimeout(() => {
        setValues((values) => {
          return { ...values, message: "" };
        });
      }, 1500);
    });
  };

  const showError = () =>
    error ? <div className="alert alert-danger">{error}</div> : "";
  const showMessage = () =>
    message ? <div className="alert alert-info">{message}</div> : "";
  const { error, message, loading } = values;

  return (
    <Row noGutters>
      <div className="file-manager-cards__dropzone w-100 p-2">
        {showError()}
        {showMessage()}
        <Dropzone onDrop={handleDrop}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div
                {...getRootProps()}
                className={classNames("dropzone", {
                  "dropzone--isActive": isDragActive,
                })}
              >
                <input {...getInputProps()} />
                <div className="m-0">
                  {!loading &&
                    "Tente soltar alguns arquivos aqui ou clique para selecionar os arquivos a serem enviados."}
                  {loading && <Loader type="Rings" color="#0a775a" />}
                </div>
              </div>
            );
          }}
        </Dropzone>
      </div>
    </Row>
  );
};
export default DropzoneRender;

import React, { useState } from "react";
import { Row, Col, Button, Modal, ModalHeader, ModalBody } from "shards-react";

import { getQuestion, getAnwerLabel } from "../../utils/util";
import { BUCKET_URL } from "../../config";
import IfAllowed from "./IfAllowed";
import SetLinkIntoString from "./SetLinkIntoString";
import AspectRatioPicture from "./AspectRatioPicture";
import Viewer from "../project/PDFPages/Viewer";

export const ShowAnswers = ({
  allQuestions,
  sendLightbox,
  answers,
  setInputList,
  setShow,
  setTitle,
  show,
  title,
  inputList,
  seeAlternative,
}) => {
  const [link, setLink] = useState("");
  return (
    <>
      {answers &&
        answers.map((question, idx) => {
          const questionNew = getQuestion(question, allQuestions);

          return (
            <div className="user-activity__item pr-3 py-3" key={idx}>
              <div className="user-activity__item__icon">
                <i className="material-icons">&#xE7FE;</i>
              </div>
              <div className="user-activity__item__content">
                <span className="text-light">{question.question}</span>

                <div>
                  {Array.isArray(question.answer) ? (
                    question.answer.map((answer, idx) => {
                      const isUpload = !!answer.source;
                      const value = isUpload
                        ? answer.thumbnail || answer.source
                        : answer;

                      return isUpload ? (
                        <AspectRatioPicture
                          key={idx}
                          height="200px"
                          width="200px"
                          isDeletable={false}
                          alt={answer.name}
                          src={`${BUCKET_URL}/${answer.source}`}
                          thumbnail={
                            answer.thumbnail
                              ? `${BUCKET_URL}/${answer.thumbnail}`
                              : null
                          }
                          title={answer.name}
                          viewPdf={() => {
                            setLink(`${BUCKET_URL}/${answer.source}`);
                          }}
                        />
                      ) : (
                        <div key={idx}>
                          {value
                            .toLowerCase()
                            .match("(.jpeg|.png|.jpg|.jfif|.webp)") ? (
                            <>
                              <button
                                className="transparent"
                                type="button"
                                onClick={() =>
                                  sendLightbox(
                                    isUpload
                                      ? `${BUCKET_URL}/${answer.source}`
                                      : BUCKET_URL + "/" + value
                                  )
                                }
                              >
                                <img
                                  alt={value}
                                  className="border rounded"
                                  style={{
                                    width: "200px",
                                    cursor: "pointer",
                                    marginBottom: "8px",
                                  }}
                                  src={BUCKET_URL + "/" + value}
                                />
                              </button>

                              <p>{getAnwerLabel(questionNew, value)}</p>
                            </>
                          ) : (
                            <>
                              <SetLinkIntoString string={value} />
                              <br />
                            </>
                          )}
                        </div>
                      );
                    })
                  ) : question.answer
                      .toLowerCase()
                      .match("(.jpeg|.png|.jpg|.jfif|.webp)") ? (
                    <>
                      <button
                        className="transparent"
                        type="button"
                        onClick={() =>
                          sendLightbox(BUCKET_URL + "/" + question.answer)
                        }
                      >
                        <img
                          alt={question.answer}
                          className="border rounded"
                          style={{
                            width: "200px",
                            cursor: "pointer",
                            marginBottom: "8px",
                          }}
                          src={BUCKET_URL + "/" + question.answer}
                        />
                      </button>

                      <p>{getAnwerLabel(questionNew, question.answer)}</p>
                    </>
                  ) : (
                    <SetLinkIntoString string={question.answer} />
                  )}
                </div>
                {seeAlternative && (
                  <IfAllowed resource="admin-ladecora:project:view-question">
                    <p>
                      {questionNew.type === "multiple" && (
                        <Button
                          outline
                          onClick={() => {
                            setShow(true);
                            setTitle(questionNew.title);
                            setInputList(questionNew.input_list);
                          }}
                        >
                          Ver alternativas
                        </Button>
                      )}
                    </p>
                  </IfAllowed>
                )}
              </div>
            </div>
          );
        })}
      {show && (
        <Modal
          size="lg"
          modalContentClassName="card card-small"
          open={show}
          toggle={() => setShow(!show)}
        >
          <ModalHeader toggle={() => setShow(!show)}>{title}</ModalHeader>
          <ModalBody style={{ maxHeight: "70vh", overflow: "auto" }}>
            {inputList.map((input, idx) => {
              return (
                <Row className="border-top" key={idx}>
                  {input.image_path
                    .toLowerCase()
                    .match("(.jpeg|.png|.jpg|.jfif|.webp)") ? (
                    <Col>
                      <button
                        className="transparent"
                        type="button"
                        onClick={() =>
                          sendLightbox(BUCKET_URL + "/" + input.image_path)
                        }
                      >
                        <img
                          alt={input.image_path}
                          className="border rounded"
                          style={{
                            width: "200px",
                            cursor: "pointer",
                            marginBottom: "8px",
                          }}
                          src={BUCKET_URL + "/" + input.image_path}
                        />
                      </button>
                    </Col>
                  ) : (
                    input.image_path
                  )}
                  <Col>{input.label}</Col>
                </Row>
              );
            })}
          </ModalBody>
        </Modal>
      )}
      <Viewer
        link={link}
        toggle={() => {
          if (link) setLink("");
        }}
      />
    </>
  );
};

import React, { useState } from "react";

import ConfirmationButton from "./ConfirmationButton";
import Loader from "react-loader-spinner";

function SafeDeleteButton({ onRemove, className }) {
  const [isLoading, setIsloading] = useState(false);

  function onRemoveClick() {
    setIsloading(true);
    onRemove().catch(() => {
      setIsloading(false);
    });
  }
  return (
    <ConfirmationButton
      outline
      size="sm"
      theme="light"
      onClick={onRemoveClick}
      disabled={isLoading}
      className={`ml-1 ${className}`}
      style={{ padding: "0px", border: "none" }}
    >
      {isLoading ? (
        <Loader width="15px" height="15px" type="Rings" color="black" />
      ) : (
        <i
          className="material-icons"
          style={{ color: "red", fontSize: "24px" }}
        >
          delete
        </i>
      )}
    </ConfirmationButton>
  );
}

SafeDeleteButton.propTypes = {};

export default SafeDeleteButton;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Card,
  CardBody,
  FormInput,
  FormGroup,
  FormCheckbox,
} from "shards-react";
import { useHistory, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { getGiftById, createGift, updateGift } from "../../actions/gift";
import Loader from "react-loader-spinner";
import { toast } from "react-toastify";

const Gift = ({ company }) => {
  const { id, giftId } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [hasValue, setHasValue] = useState(false);

  const [gift, setGift] = useState({
    label: "",
    quantity: 0,
    value: 0,
    company: company.label,
  });

  useEffect(() => {
    if (giftId) {
      setIsLoading(true);
      getGiftById(id, giftId)
        .then((res) => {
          setIsLoading(false);
          setGift(res);
          setHasValue(res.value > 0);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  }, []);

  function onUpdate() {
    updateGift(company.label, giftId, gift)
      .then((res) => {
        setIsLoading(false);
        toast.success(res.msg, { autoClose: 5000 });
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        toast.error("Ocorreu um erro", { autoClose: 5000 });
      });
  }
  function onCreate() {
    createGift(company.label, gift)
      .then((res) => {
        setIsLoading(false);
        history.replace(`/partner/${company.label}/gift/${res.label}`);
        toast.success("Projeto atualizado com sucesso", { autoClose: 5000 });
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        toast.error("Ocorreu um erro", { autoClose: 5000 });
      });
  }

  function onSaveClick(ev) {
    ev.preventDefault();

    setIsLoading(true);
    (giftId ? onUpdate : onCreate)(gift);

    return false;
  }

  function onGiftCheckboxValueClicked() {
    setHasValue(!hasValue);
    if (hasValue) {
      setGift({ ...gift, value: 0 });
    }
  }

  return (
    <Container fluid className="main-content-container px-4">
      <Row className="page-header py-4">
        <Col md="6">
          <button
            type="button"
            className="transparent"
            onClick={() => {
              history.goBack();
            }}
          >
            VOLTAR
          </button>
          <h3>Configurações da tela de presente | {company.name}</h3>
        </Col>
      </Row>

      <div>
        <Card>
          <CardBody>
            <Row noGutters className="h-100">
              <Col lg="8" md="12" sm="12" className=" mx-auto my-auto">
                {/* Logo */}
                {/* Title */}

                {/* Form Fields */}
                <Form onSubmit={onSaveClick}>
                  <Row form>
                    <Col md="6">
                      <div className="label">Label</div>
                      <FormInput
                        disabled={isLoading}
                        onChange={(ev) => {
                          setGift({ ...gift, label: ev.target.value });
                        }}
                        required
                        type="text"
                        value={gift.label}
                      />
                    </Col>
                    <Col md="2">
                      <div className="label">Quantidade</div>
                      <FormInput
                        required
                        onChange={(ev) => {
                          setGift({ ...gift, quantity: ev.target.value });
                        }}
                        type="tel"
                        disabled={isLoading}
                        value={gift.quantity}
                      />
                    </Col>
                  </Row>
                  <Row form>
                    <Col md="12">
                      <div className="label"> </div>
                      <FormGroup>
                        <FormCheckbox
                          checked={hasValue}
                          onChange={onGiftCheckboxValueClicked}
                        >
                          Possui valor?
                        </FormCheckbox>
                      </FormGroup>
                    </Col>
                    {hasValue ? (
                      <Col md="6">
                        <div className="label">Valor</div>
                        <FormInput
                          disabled={isLoading}
                          onKeyDown={(ev) => {
                            if (
                              ev.key.length === 1 &&
                              !ev.key.match(/^([0-9]|\.)*$/)
                            ) {
                              ev.preventDefault();
                              ev.stopPropagation();
                            }
                          }}
                          onChange={(ev) => {
                            setGift({ ...gift, value: ev.target.value });
                          }}
                          required
                          type="tel"
                          value={gift.value}
                        />
                      </Col>
                    ) : null}
                  </Row>

                  <Row form>
                    <Col md="12" xs="12" sm="12" className="form-group">
                      <hr className="mt-4" />
                      <Button
                        size="lg"
                        theme="accent"
                        className="d-flex mx-auto mt-3"
                        type="submit"
                        disabled={isLoading}
                      >
                        {isLoading ? "Salvando..." : "Salvar"}
                        {isLoading && (
                          <Loader
                            width="30px"
                            height="15px"
                            type="Rings"
                            color="#ffffff"
                          />
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </Container>
  );
};

Gift.propTypes = {
  company: PropTypes.object,
};
export default Gift;

import React, { useState } from "react";

import { createContext } from "react";

export const RenderItemTextContext = createContext({});

export const RenderItemTextProvider = ({ children, value }) => {
  value = JSON.parse(JSON.stringify(value));
  const [customSettings, setCustomSettings] = useState(
    value.customSettings.filter(
      (setting) => !setting.items || !setting.items.length
    )
  );

  const [settings, setSettings] = useState(loadSettings(value.settings));
  const [customCategorySettings, setCustomCategorySettings] = useState(
    value.customSettings
      .filter(
        (setting) => !!setting.name && setting.items && setting.items.length
      )
      .map((setting) => {
        setting.id = setting._id;
        setting.items = setting.items.map((setting) => {
          setting.id = setting._id;
          return setting;
        });
        return setting;
      })
  );

  function addCustomSetting(setting) {
    customSettings.push(setting);
    let categorySetting = getCategorySetting(setting.category);
    categorySetting.enabled = getCategoryToggleValue(categorySetting);

    setCustomSettings([...customSettings]);
  }

  function addCustomCategorySetting(setting) {
    let customCategory = customCategorySettings.find(
      (customCategory) => customCategory.id === setting.categoryId
    );
    customCategory.items.push(setting);
    setCustomCategorySettings([...customCategorySettings]);
  }

  function addCustomCategory(setting) {
    setting.id = customCategorySettings.length;
    customCategorySettings.push(setting);
    setCustomCategorySettings([...customCategorySettings]);
  }

  function deleteCustomCategorySetting(id, categoryId) {
    let categorySetting = customCategorySettings.find(
      (categorySetting) => categorySetting.id === categoryId
    );
    categorySetting.items = categorySetting.items.filter(
      (setting) => setting.id !== id
    );
    setCustomCategorySettings([...customCategorySettings]);
  }

  function deleteCustomCategory(id) {
    let newCustomCategorySettings = customCategorySettings.filter(
      (customCategory) => customCategory.id !== id
    );
    setCustomCategorySettings([...newCustomCategorySettings]);
  }

  function deleteCustomSetting(index, category) {
    let newCustomSettings = customSettings.filter(
      (setting, idx) => idx !== index
    );

    let categorySetting = getCategorySetting(category);
    categorySetting.enabled =
      getCategoryToggleValueByItems(categorySetting) ||
      getCategoryToggleValueByCustomSettings(
        categorySetting.name,
        newCustomSettings
      );
    setCustomSettings(newCustomSettings);
  }

  function toggleSetting(category, settingId) {
    let categorySetting = getCategorySetting(category);
    let setting = categorySetting.items.find(
      (setting) => setting._id === settingId
    );
    setting.enabled = !setting.enabled;
    categorySetting.enabled = getCategoryToggleValue(categorySetting);

    setSettings([...settings]);
  }

  function getCategoryToggleValue(setting) {
    return (
      getCategoryToggleValueByItems(setting) ||
      getCategoryToggleValueByCustomSettings(setting.name, customSettings)
    );
  }

  function getCategoryToggleValueByCustomSettings(category, customSettings) {
    return customSettings.some(
      (customSetting) => customSetting.category === category
    );
  }

  function getCategoryToggleValueByItems(categorySetting) {
    return categorySetting.items.reduce((acc, current) => {
      return acc || current.enabled;
    }, false);
  }

  function loadSettings(categorySettings) {
    categorySettings.forEach((categorySetting) => {
      categorySetting.enabled = getCategoryToggleValue(categorySetting);
    });
    return categorySettings;
  }

  function getCategorySetting(category) {
    return settings.find((setting) => setting.name === category);
  }

  function toggleSettingsByCategory(category) {
    let categorySetting = getCategorySetting(category);
    categorySetting.enabled = !categorySetting.enabled;
    let value = categorySetting.enabled;
    categorySetting.items.forEach((setting) => {
      setting.enabled = value;
    });

    let categoryToggleValue = getCategoryToggleValue(categorySetting);

    categorySetting.enabled = categoryToggleValue;

    setSettings([...settings]);
  }

  return (
    <RenderItemTextContext.Provider
      value={{
        settings,
        customSettings,
        customCategorySettings,
        setSettings,
        setCustomSettings,
        addCustomSetting,
        addCustomCategory,
        addCustomCategorySetting,
        deleteCustomSetting,
        deleteCustomCategorySetting,
        deleteCustomCategory,
        toggleSetting,
        toggleSettingsByCategory,
      }}
    >
      {children}
    </RenderItemTextContext.Provider>
  );
};

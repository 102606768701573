import React, { useContext } from "react";
import { FormCheckbox } from "shards-react";
import { MetadataContext } from "../../metadata/MetadataContext";

const PageTextItem = ({ text, enabled, onToggleByCategory, item }) => {
  const { isAllowed } = useContext(MetadataContext);
  function onToggleByCategoryClick() {
    onToggleByCategory(item, getToggleValue());
  }

  function getToggleValue() {
    return !item.enabled;
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <FormCheckbox
          toggle
          checked={enabled}
          onChange={
            isAllowed("admin-ladecora:project", "update:any")
              ? onToggleByCategoryClick
              : null
          }
          small
        >
          <strong>{text}</strong>
        </FormCheckbox>
      </div>
    </div>
  );
};

export default PageTextItem;

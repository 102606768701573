import React, { useState, useEffect } from "react";
import {
  Row,
  Card,
  Col,
  FormSelect,
  CardBody,
  CardHeader,
  Container,
  Button,
} from "shards-react";
import FuzzySearch from "fuzzy-search";
import { useHistory } from "react-router-dom";

import "react-toastify/dist/ReactToastify.min.css";
import { CSVLink } from "react-csv";

import { debounce } from "../../utils/throttle";
import ModalAddGroup from "./Furniture/ModalAddGroup";
import FilteredTable from "../FilteredTable/index";
import { sortBySupplier } from "../../utils/sorting";
import IfAllowed from "../common/IfAllowed";

const types = {
  project: "Lista Final",
  preview: "Proposta Inicial",
  first_review: "1º Revisão",
  second_review: "2º Revisão",
};

const FurnitureTable = ({ data, projectId, onUpdate }) => {
  const history = useHistory();
  const [furnitureList, setFurnitureList] = useState([]);
  const [state, setState] = useState({
    table: {
      pageSizeOptions: [5, 10, 15, 20, 25, 30],
      pageSize: 5,
      data: furnitureList,
      searcher: new FuzzySearch(data, ["data.sku", "data.name"], {
        caseSensitive: false,
        sort: true,
      }),
      searchText: debounce((value) => {
        state.table.data = state.table.searcher.search(value);
        setState({ ...state });
      }, 800),
    },
  });
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [projetStage, setProjetStage] = useState("preview");
  const [csvFurnitureList, setCsvFurnitureList] = useState([]);

  useEffect(() => {
    if (data.finalProject && data.finalProject.approvedProject) {
      if (data.finalProject.approvedProject === "firstReview") {
        setProjetStage("first_review");
      } else if (data.finalProject.approvedProject === "secondReview") {
        setProjetStage("second_review");
      }
    }
  }, []);
  useEffect(() => {
    let tempListFurniture;
    if (projetStage === "preview") {
      tempListFurniture = checkIdproperty(data.finalProject.preview.furnitures);
    } else if (projetStage === "first_review") {
      tempListFurniture = checkIdproperty(
        data.finalProject.firstReview.furnitures
      );
    } else if (projetStage === "second_review") {
      tempListFurniture = checkIdproperty(
        data.finalProject.secondReview.furnitures
      );
    } else {
      tempListFurniture = data.furnitures;
    }
    tempListFurniture = sortBySupplier(tempListFurniture);
    setFurnitureList(tempListFurniture);
    state.table.data = tempListFurniture;

    let tempCsv = JSON.parse(JSON.stringify(tempListFurniture));
    tempCsv.map((elem, elemkey) => {
      Object.keys(elem).map((item, idx) => {
        tempCsv[elemkey].link =
          tempCsv[elemkey].supplier === "Tok&Stok"
            ? `https://www.tokstok.com.br/resultado-busca?q=${tempCsv[elemkey].sku}`
            : "";

        tempCsv[elemkey].count = tempCsv[elemkey].selected
          ? "CONFORME METRAGEM DO AMBIENTE"
          : tempCsv[elemkey].count;

        if (idx === 0) {
          tempCsv[elemkey].latestUnits = Object.prototype.hasOwnProperty.call(
            tempCsv[elemkey],
            "latestUnits"
          )
            ? tempCsv[elemkey].latestUnits
              ? "Sim"
              : "Não"
            : "Não informado";

          tempCsv[elemkey].available = Object.prototype.hasOwnProperty.call(
            tempCsv[elemkey],
            "available"
          )
            ? tempCsv[elemkey].available
              ? "Sim"
              : "Não"
            : "Não informado";
        }

        if (
          item !== "sku" &&
          item !== "refimage" &&
          item !== "name" &&
          item !== "supplier" &&
          item !== "count" &&
          item !== "available" &&
          item !== "latestUnits" &&
          item !== "link"
        ) {
          delete tempCsv[elemkey][item];
        }
      });
    });
    setCsvFurnitureList(tempCsv);
  }, [projetStage]);

  const checkIdproperty = (data) => {
    let tempList = [];
    data.map((elem) => {
      if (elem.id !== null && typeof elem.id === "object") {
        tempList.push({
          count: elem.count,
          selected: elem.selected,
          ...elem.id,
        });
      } else if (elem._id && elem.id !== null) {
        tempList.push({ ...elem });
      }
    });
    return tempList;
  };

  const tableColumns = [
    {
      Header: "Sku",
      accessor: "sku",
      className: "text-center",
      maxWidth: 200,
      Cell: (row) => {
        return row.original.supplier === "Tok&Stok" ? (
          <div>
            <a
              href={`https://www.tokstok.com.br/resultado-busca?q=${row.original.sku}`}
              target="_blank"
              rel="noreferrer"
            >
              {row.original.sku}
            </a>
          </div>
        ) : (
          row.original.sku
        );
      },
    },
    {
      Header: "Imagem",
      accessor: "refimage",
      className: "text-center",
      Cell: (row) => {
        return (
          <div>
            <img alt="" height={100} src={row.value} />
          </div>
        );
      },
      maxWidth: 100,
    },

    {
      Header: "Produto",
      accessor: "name",
      className: "text-center",
    },
    { Header: "Fornecedor", accessor: "supplier", className: "text-center" },

    {
      Header: "Quantidade",
      accessor: "count",
      className: "text-center",
      Cell: (row) => {
        let valorUnidade = null;
        let valorTotal = null;
        let unitOfMeasurement = "und.";
        if (row.original.unitOfMeasurement) {
          unitOfMeasurement = row.original.unitOfMeasurement;
        }
        if (row.original.value) {
          valorUnidade = row.original.value;
        }

        if (row.original.value && row.original.count) {
          valorTotal = row.original.value * row.original.count;
        }
        return row.original.selected ? (
          <>
            Conforme metragem do ambiente <br />
            {valorUnidade && `R$${valorUnidade} / ${unitOfMeasurement}`}
          </>
        ) : (
          <>
            {valorUnidade && `R$${valorUnidade} / ${unitOfMeasurement}`}
            <br />
            {`${row.original.count} ${unitOfMeasurement}`}
            <br />
            {valorTotal && `Total R$${valorTotal.toFixed(2)}`}
          </>
        );
      },
    },
    {
      Header: "Disponivel",
      accessor: "available",
      className: "text-center",
      Cell: (row) => {
        return typeof row.original.available !== "undefined"
          ? row.original.available
            ? "Sim"
            : "Não"
          : "Não informado";
      },
    },
    {
      Header: "Ultimas Unidades",
      accessor: "latestUnits",
      className: "text-center",
      Cell: (row) => {
        return typeof row.original.latestUnits !== "undefined"
          ? row.original.latestUnits
            ? "Sim"
            : "Não"
          : "Não informado";
      },
    },
    {
      Header: "Pode usar",
      accessor: "notUse",
      className: "text-center",
      maxWidth: 200,
      Cell: (row) => {
        return (
          <div>
            {row.original.notUse ? (
              <h6 style={{ backgroundColor: "red" }}>
                {" "}
                <b>Não</b>
              </h6>
            ) : (
              <>Sim</>
            )}
          </div>
        );
      },
    },
    {
      Header: "Link",
      accessor: "link",
      className: "text-center",
      maxWidth: 200,
      Cell: (row) => {
        return (
          <div>
            {row.original.link ? (
              <a href={row.original.link} target="_blank" rel="noreferrer">
                Abrir link
              </a>
            ) : (
              <div>Não possui</div>
            )}
          </div>
        );
      },
    },
  ];

  const newProduct = () => {
    history.push(
      `/project/${projectId}/mobi?type=${projetStage}&project=${projectId}`
    );
  };

  function addGroup() {
    setShowGroupModal(true);
  }

  const isAvailable = () => {
    const mobiFalse = furnitureList.filter((e) => {
      return e.notUse === true;
    });

    return mobiFalse.length;
  };

  return (
    <>
      <Card>
        <CardHeader className="p-0" />
        <CardBody className="p-0">
          <Row>
            <Col>
              <h6 className="text-center m-0 mt-2">Produtos do projeto</h6>
            </Col>
          </Row>
          {isAvailable() > 0 && (
            <Row>
              <Col style={{ textAlign: "center", color: "red" }}>
                {" "}
                ATENÇÃO: Temos produtos da lista não usar no projeto
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <h6 className="text-center m-0 mt-2">
                Valor total do carrinho - R$
                {state.table.data
                  .reduce((acc, cur) => {
                    if (cur.value) {
                      acc += cur.value * cur.count;
                    }
                    return acc;
                  }, 0)
                  .toFixed(2)}
              </h6>
            </Col>
          </Row>
          <Container fluid className="file-manager__filters border-bottom">
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {/* Filters :: Page Size */}
              <Col className="file-manager__filters__rows d-flex" md="6">
                <IfAllowed
                  resource="admin-ladecora:project"
                  perform="update:any"
                >
                  <Button className="mb-2" onClick={newProduct}>
                    Adicionar produtos
                  </Button>
                  <Button className="mb-2 ml-2" outline onClick={addGroup}>
                    Grupos
                  </Button>
                </IfAllowed>
              </Col>
              <Col md="6">
                <div className="d-flex justify-content-around">
                  <div className="text-center mb-2">
                    <div className="label">Etapas</div>
                    <FormSelect
                      className="form-select"
                      size="sm"
                      value={projetStage}
                      onChange={(ev) => {
                        setProjetStage(ev.target.value);
                      }}
                    >
                      {Object.keys(types)
                        .filter(
                          (type) => type !== "project" || data.furnitures.length
                        )
                        .map((type, idx) => {
                          return (
                            <option key={idx} value={type}>
                              {types[type]}
                            </option>
                          );
                        })}
                    </FormSelect>
                  </div>
                  <div className="d-flex align-items-end mb-2 ml-5">
                    <CSVLink
                      data={csvFurnitureList}
                      separator=";"
                      filename={"listademoveis.csv"}
                    >
                      <Button theme="accent" className="btn-outline-primary">
                        Exportar CSV
                      </Button>
                    </CSVLink>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
            </Row>

            <FilteredTable
              columns={tableColumns}
              list={furnitureList}
              config={{
                pageSizeOptions: [5, 10, 15, 20, 30],
                searchableColumns: ["name", "sku"],
                pageSize: 10,
              }}
            />
          </Container>
        </CardBody>
      </Card>

      {showGroupModal ? (
        <ModalAddGroup
          show={showGroupModal}
          projectId={projectId}
          onUpdate={onUpdate}
          toggle={(value) => {
            setShowGroupModal(value);
          }}
        />
      ) : null}
    </>
  );
};

export default FurnitureTable;

import { API } from "../config";
import { getCookie } from "./auth";
import { requestWrapper } from "../utils/api";

export const addCategory = (name) => {
  const token = getCookie("token");

  return fetch(`${API}/mobi/tag`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "x-access-token": token,
    },
    body: JSON.stringify({
      name,
    }),
  })
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response;
    })
    .then((response) => {
      return response.json();
    });
};

// Deprecated
export const getTags = () => {
  const token = getCookie("token");

  return fetch(`${API}/mobi/tag`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "x-access-token": token,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        throw new Error("Server error");
      }
      return response;
    })
    .then((response) => {
      return response.json();
    });
};

export const deleteCategory = (id) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/mobi/tag/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const getTagsV2 = (search = "", category, parent) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(
      `${API}/mobi_tags?search=${search}&category=${category}${`&parent=${
        parent ? parent : null
      }`}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "x-access-token": token,
        },
      }
    )
  );
};

export const addTag = (tag) => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(`${API}/mobi/tagV2`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(tag),
    })
  );
};

export const getTinderCompositeTags = (data) => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(`${API}/tinder_mobi_tag/filter/composite`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(data),
    })
  );
};

export const addTagToMobi = (mobiId, tag, depth) => {
  const token = getCookie("token");
  fetch(`${API}/mobi/${mobiId}/add_tag`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "x-access-token": token,
    },
    body: JSON.stringify({ tag, depth }),
  });
};

export const removeTagFromMobi = (mobiId, depth) => {
  const token = getCookie("token");
  fetch(`${API}/mobi/${mobiId}/${depth}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "x-access-token": token,
    },
  });
};

export const restart = (filter, depth) => {
  const token = getCookie("token");
  return fetch(`${API}/mobi/restart_tinder/${depth}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "x-access-token": token,
    },
    body: JSON.stringify(filter),
  });
};

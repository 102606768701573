import React, { useState, useEffect } from "react";

import { Modal } from "shards-react";
import { getPDFTextItems } from "../../../actions/projects";
import { RenderItemTextProvider } from "./RenderItemTextContext";

import RenderModalSettings from "./RenderModalSettings";

const RenderModal = ({ toggle, show, name, value, onSave }) => {
  const [items, setItems] = useState([]);
  const { contractorSettings, customContractorSettings } = value;

  useEffect(() => {
    getPDFTextItems().then((categorySettings) => {
      contractorSettings.forEach((id) => {
        categorySettings.forEach((d) => {
          let item = d.items.find((item) => item._id === id);

          if (item) {
            item.enabled = true;
            d.enabled = true;
          }
        });
      });
      setItems(categorySettings);
    });
  }, []);

  return (
    <Modal
      size="lg"
      modalContentClassName="card card-small"
      open={show}
      toggle={toggle}
    >
      {!!items.length && (
        <RenderItemTextProvider
          value={{
            settings: items,
            customSettings: customContractorSettings,
          }}
        >
          <RenderModalSettings name={name} onSave={onSave} />
        </RenderItemTextProvider>
      )}
    </Modal>
  );
};

export default RenderModal;

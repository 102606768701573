export const LAYOUT_TYPES = {
  DEFAULT: "DEFAULT",
  HEADER_NAVIGATION: "HEADER_NAVIGATION",
  ICON_SIDEBAR: "ICON_SIDEBAR",
};

const getProgressStatus = () => {
  const keys = ["preview", "first_review", "second_review", "project"];
  return {
    asTitle: () => {
      const values = [
        " Proposta Inicial",
        "1º Revisão",
        "2º Revisão",
        "Lista final",
      ];
      return keys.reduce((acc, current, i) => {
        acc[current] = values[i];
        return acc;
      }, {});
    },
    asStep: () => {
      const values = ["preview", "firstReview", "secondReview", "project"];
      return keys.reduce((acc, current, i) => {
        acc[current] = values[i];
        return acc;
      }, {});
    },
    getKeys: () => keys,
    getKeyIndex: (value) => keys.findIndex((key) => key === value),
  };
};

export const PROJECT_PROGRESS_STATUS = getProgressStatus();

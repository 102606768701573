import React, { useRef, useState, useEffect, useMemo } from "react";
import { Swipeable } from "react-deck-swiper";
import "../../styles/components/TinderMobiTag.scss";
import {
  getTinderCompositeTags,
  addTagToMobi,
  removeTagFromMobi,
  restart,
} from "../../actions/mobiTag";
import { useHistory, Redirect } from "react-router-dom";
import ConfirmationButton from "../common/ConfirmationButton";

const Card = ({ zIndex = 0, children }) => {
  const cardStyles = {
    background: "whitesmoke",
    borderRadius: 3,
    width: "100%",
    height: "400px",
    cursor: "pointer",
    userSelect: "none",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: 0,
  };
  return <div style={{ ...cardStyles, zIndex }}>{children}</div>;
};

const TinderMobiTag = ({ params }) => {
  const history = useHistory();
  const DEPTH_NAMES = [
    "Tipologia",
    "Categoria",
    "Sub Categoria",
    "Característica",
  ];
  const DEPTH_FIELDS = ["typology", "category", "subCategory", "feature"];

  const animatedDiv = useRef();
  const depth = useMemo(() => {
    let depth = 0;
    if (params.typology._id && params.category._id && params.subCategory._id) {
      depth = 3;
    } else if (params.typology._id && params.category._id) {
      depth = 2;
    } else if (params.typology._id) {
      depth = 1;
    }
    return depth;
  }, [params]);

  const wrapperStyles = {
    position: "relative",
    width: "400px",
    height: "400px",
  };

  const [page, setPage] = useState(0);
  const [cards, setCards] = useState({
    mobis: null,
    back: [],
  });
  const [newMobis, setNewMobis] = useState([]);

  useEffect(() => {
    if (params.value._id) {
      const data = JSON.parse(JSON.stringify(params));
      DEPTH_FIELDS.forEach((f) => {
        if (data[f] && !data[f]._id) {
          data[f] = null;
        }
      });
      const skipIds = cards.mobis
        ? cards.mobis.map((m) => m._id).concat(cards.back.map((m) => m._id))
        : [];
      getTinderCompositeTags({
        ...data,
        page,
        skipIds,
      }).then((res) => {
        if (page === 0) {
          setCards({
            mobis: res.mobis,
            back: cards.back,
            count: res.count,
          });
        } else {
          setNewMobis(res);
        }
      });
    }
  }, [page]);

  function remove(direction) {
    setTimeout(
      () => document.getElementsByTagName("html")[0].scrollTo(0, 0),
      100
    );
    const mobi = cards.mobis[0];
    if (direction === "right") {
      mobi[DEPTH_FIELDS[depth]] = params.value;
    }
    cards.back.push(mobi);
    const newCards = {
      back: cards.back,
      count: cards.count - 1,
    };

    if (cards.mobis.length === 1 && newMobis.length) {
      newCards.mobis = newMobis;
      setNewMobis([]);
    } else {
      newCards.mobis = cards.mobis.slice(1, cards.mobis.length);
    }
    setCards(newCards);
    if (cards.mobis.length === 20 && newMobis.length <= 1) {
      setPage(1 + page);
    }
  }

  function left() {
    if (animatedDiv.current) {
      animatedDiv.current.className = "left";
      setTimeout(() => {
        animatedDiv.current.className = "";
      }, 100);
    }
  }

  function right() {
    if (animatedDiv.current) {
      animatedDiv.current.className = "right";
      setTimeout(() => {
        animatedDiv.current.className = "";
      }, 100);
    }
  }

  function onBeforeSwipe(direction) {
    if (direction === "left") {
      removeTagFromMobi(cards.mobis[0]._id, depth, params.value._id);
      left();
    } else {
      addTagToMobi(cards.mobis[0]._id, params.value, depth);

      right();
    }
    remove(direction);
  }

  function undo(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    const mobi = cards.back.pop();
    const isValueUndefined = mobi[DEPTH_FIELDS[depth]] === undefined;

    if (isValueUndefined) mobi[DEPTH_FIELDS[depth]] = null;
    addTagToMobi(mobi._id, undefined, depth);
    cards.mobis = [mobi].concat(cards.mobis);
    setCards({
      mobis: cards.mobis,
      back: cards.back,
      count: cards.count + 1,
    });
  }

  function onBackClick() {
    history.push(`/mobi_tag_tinder/value${history.location.search}`);
  }

  function onRestartClick() {
    const data = JSON.parse(JSON.stringify(params));

    DEPTH_FIELDS.forEach((f) => {
      if (data[f] && !data[f]._id) {
        data[f] = null;
      }
    });
    restart(data, depth).then(() => {
      window.location.reload();
    });
  }

  if (!params.value._id) {
    return <Redirect to="/mobi_tag_tinder/filter" />;
  }

  return (
    <div className="tinder-container">
      <div
        style={{ fontSize: "1.5rem", fontWeight: 700 }}
        className="text-center"
      >
        <span className="mt-2 float-left">
          <button
            onClick={onBackClick}
            className="material-icons transparent"
            type="button"
          >
            arrow_back
          </button>
        </span>
        {DEPTH_NAMES[depth]}
      </div>
      <div style={{ fontSize: "1.2rem" }} className="text-center">
        {params.value.name}
      </div>
      <div style={{ fontSize: "1rem", color: "black" }} className="text-right">
        {cards.count}
      </div>

      <div
        ref={animatedDiv}
        style={{
          top: 0,
          left: 0,
          height: "100vh",
          zIndex: 20000,
          pointerEvents: "none",
          width: "100vw",
          position: "absolute",
          opacity: 0.3,
        }}
      />

      {cards.mobis && (cards.mobis.length || newMobis.length) ? (
        <>
          <div className="tinder-card-container">
            <div style={wrapperStyles}>
              <Swipeable onSwipe={onBeforeSwipe}>
                <Card>
                  <div className="d-flex flex-column w-100 h-100">
                    <div
                      style={{
                        backgroundImage: cards.mobis.length
                          ? "url(" + cards.mobis[0].refimage + ")"
                          : "url(" + newMobis[0].refimage + ")",
                      }}
                      className="tinder-card"
                    >
                      <h3>
                        {cards.mobis.length
                          ? cards.mobis[0].frequency_used || 0
                          : newMobis[0].frequency_used || 0}
                      </h3>
                    </div>
                    <div className="tinder-footer ">
                      <div
                        style={{ fontSize: "1.2rem" }}
                        className="text-center"
                      >
                        {cards.mobis.length
                          ? cards.mobis[0].name
                          : newMobis[0].name}
                      </div>
                      <div style={{ fontSize: "1rem" }} className="text-center">
                        {cards.mobis.length
                          ? cards.mobis[0].supplier
                          : newMobis[0].supplier}
                      </div>
                      <div className="ml-5 mt-1 mb-2 mr-5 d-flex justify-content-around">
                        {cards.mobis.length && cards.mobis[0].link ? (
                          <button
                            type="button"
                            title="Copiar link do produto!"
                            className="btn btn-white"
                            disabled=""
                          >
                            <i className="fa fa-link" />
                          </button>
                        ) : null}
                        {cards.mobis.length && cards.mobis[0].file ? (
                          <button
                            type="button"
                            title="Baixar Bloco"
                            className="btn btn-white"
                            disabled=""
                          >
                            <i className="fas fa-download fa-lg" />
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </Card>
              </Swipeable>
            </div>
          </div>
          {cards.mobis
            .filter((m, i) => i > 1 && i < 10)
            .concat(newMobis)
            .map((m) => (
              <div
                key={m._id}
                style={{
                  backgroundImage: "url(" + m.refimage + ")",
                  position: "absolute",
                  zIndex: -3,
                }}
              />
            ))}
          <div className="w-100 d-flex mt-2 justify-content-center">
            <button
              className="left-button"
              onClick={(ev) => {
                ev.stopPropagation();
                ev.preventDefault();
                onBeforeSwipe("left");
              }}
            >
              <i className="material-icons">close</i>
            </button>
            <button
              className="right-button"
              onClick={(ev) => {
                ev.stopPropagation();
                ev.preventDefault();
                onBeforeSwipe("right");
              }}
            >
              <i className="material-icons">check</i>
            </button>
          </div>
          <div className="w-100 d-flex mt-1">
            {cards.back.length ? (
              <button className="undo-button" onClick={undo}>
                <i className="material-icons">undo</i>
              </button>
            ) : (
              <ConfirmationButton
                title="Remover"
                className="restart-button"
                onClick={onRestartClick}
                details="Após a confirmação, todos os mobis que foram rejeitados ou arrastados para a esquerda, irão novamente aparecer na listagem. Utilize essa função apenas quando for iniciar o cadastro de uma nova tag."
              >
                <i className="material-icons">restart_alt</i>
              </ConfirmationButton>
            )}
          </div>
        </>
      ) : null}
      {cards.mobis && cards.mobis.length === 0 ? (
        <div className="text-center">
          <div style={{ fontSize: "3rem" }}>Fim</div>
          <ConfirmationButton
            title="Remover"
            className="restart-button"
            onClick={onRestartClick}
            details="Após a confirmação, todos os mobis que foram rejeitados ou arrastados para a esquerda, irão novamente aparecer na listagem. Utilize essa função apenas quando for iniciar o cadastro de uma nova tag."
          >
            <i className="material-icons">restart_alt</i>
          </ConfirmationButton>
        </div>
      ) : null}
    </div>
  );
};

export default TinderMobiTag;

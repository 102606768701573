import { addingDayWork } from "@ladecora/ladecora-utils";

export const limitDate = (logDates, status, forcedStep) => {
  try {
    if (status === "enabled") {
      return "Sem Limite";
    } else if (status === "submitted") {
      if (forcedStep != null) {
        let force = false;
        forcedStep.forEach((row) => {
          if (row.isPending) {
            force = true;
          }
        });
        if (force) {
          return "Sem Limite";
        } else {
          return addingDayWork(logDates.submitted.date, 1);
        }
      } else {
        return addingDayWork(logDates.submitted.date, 1);
      }
    } else if (status === "approved") {
      return addingDayWork(logDates.approved.date, 5);
    } else if (status === "waiting_quality") {
      return addingDayWork(logDates.customer_reproved.date, 3);
    } else if (status === "reviewing_quality") {
      return addingDayWork(logDates.customer_reproved.date, 3);
    } else if (status === "preview") {
      return addingDayWork(logDates.preview.date, 20);
    } else if (status === "first_review") {
      return addingDayWork(logDates.preview.date, 20);
    } else if (status === "second_review") {
      return addingDayWork(logDates.preview.date, 20);
    } else if (status === "waiting_confirm_review") {
      return addingDayWork(logDates.waiting_confirm_review.date, 1);
    } else if (status === "waiting_user_feedback") {
      return addingDayWork(logDates.waiting_user_feedback.date, 1);
    } else if (status === "customer-reproved") {
      return addingDayWork(logDates.customer_reproved.date, 3);
    } else if (status === "waiting_render_approved") {
      return addingDayWork(logDates.waiting_render_approved.date, 3);
    } else if (status === "final_project") {
      return "Projeto Entregue";
    } else {
      return "Erro no Status";
    }
  } catch (error) {
    return "Faltando dados";
  }
};

export function translateStatus(status, forcedStep) {
  if (status === "enabled") {
    return "Em progresso";
  } else if (status === "submitted") {
    if (forcedStep != null) {
      let force = false;
      forcedStep.forEach((row) => {
        if (row.isPending) {
          force = true;
        }
      });
      if (force) {
        return "Aguardando resposta";
      } else {
        return "Confirmar dados";
      }
    } else {
      return "Confirmar dados";
    }
  } else if (status === "approved") {
    return "Em produção";
  } else if (status === "waiting_render_approved") {
    return "Aguardando Projeto Final";
  } else if (status === "waiting_render_reproved") {
    return "Aguardando Projeto Final";
  } else if (status === "waiting_confirm_review") {
    return "Confirmar dados revisão";
  } else if (status === "waiting_user_feedback") {
    return "Aguardando resposta cliente";
  } else if (status === "customer-reproved") {
    return "Em Revisão";
  } else if (status === "preview") {
    return "Proposta Inicial";
  } else if (status === "waiting_quality") {
    return "Aguardando qualidade";
  } else if (status === "reviewing_quality") {
    return "Revisando qualidade";
  } else if (status === "first_review") {
    return "1º Revisão";
  } else if (status === "second_review") {
    return "2º Revisão";
  } else if (status === "final_project") {
    return "Projeto Entregue";
  } else {
    return status;
  }
}

export function translateApprovedStatus(statusDataBase) {
  let status = "";
  switch (statusDataBase) {
    case "firstReview":
      status = "1º Revisão";
      break;
    case "secondReview":
      status = "2º Revisão";
      break;
    case "preview":
      status = "Proposta Inicial";
      break;
  }
  return status;
}

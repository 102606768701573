import fetch from "isomorphic-fetch";
import cookie from "js-cookie";
import { API } from "../config";
import { requestWrapper } from "../utils/api";
const qs = require("query-string");

export const setAdminAccessToken = (userId) => {
  const token = cookie.get("token");
  return requestWrapper(
    fetch(`${API}/set-admin-access`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify({ id: userId }),
    })
  );
};

export const signup = (user) => {
  return fetch(`${API}/signup`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const signin = (user) => {
  return fetch(`${API}/signin`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const signout = () => {
  removeCookie("token");
  removeLocalStorage("user");

  return fetch(`${API}/signout`, {
    method: "GET",
  })
    .then(() => {
      console.log("signout success");
    })
    .catch((err) => console.log(err));
};

// set cookie
export const setCookie = (key, value) => {
  cookie.set(key, value, {
    expires: 10,
  });
};

export const removeCookie = (key) => {
  cookie.remove(key, {
    expires: 1,
  });
};
// get cookie
export const getCookie = (key) => {
  return cookie.get(key);
};
// localstorage
export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getLocalStorage = (key) => {
  return localStorage.getItem(key);
};

export const removeLocalStorage = (key) => {
  localStorage.removeItem(key);
};
// autheticate user by pass data to cookie and localstorage
export const authenticate = (data, next) => {
  setCookie("token", data.token);
  setLocalStorage("user", data.user);
  next();
};

export const isAuth = () => {
  const cookieChecked = getCookie("token");
  if (cookieChecked) {
    if (localStorage.getItem("user")) {
      return JSON.parse(localStorage.getItem("user"));
    } else {
      return false;
    }
  }
};

export const forgotPassword = (email) => {
  return fetch(`${API}/forgot`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(email),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const resetPassword = (resetInfo) => {
  return fetch(`${API}/reset/${resetInfo.token}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      password: resetInfo.password,
      confirm: resetInfo.confirmPassword,
    }),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getQueryStringValue = (
  key,
  queryString = window.location.search
) => {
  const values = qs.parse(queryString);
  return values[key];
};

export const getRoles = () => {
  const token = getCookie("token");
  return fetch(`${API}/roles`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "x-access-token": token,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getAuthenticatedUser = () => {
  const cookieChecked = getCookie("token");
  const stringifyedUser = localStorage.getItem("user");
  if (cookieChecked && stringifyedUser) {
    return JSON.parse(stringifyedUser);
  }
  return null;
};

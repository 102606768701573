import React from "react";
import "./style.css";

const Fab = (props) => {
  const fabContainer = {
    position: props.position ? props.position : "fixed",
    bottom: props.bottom ? props.bottom : "50px",
    right: props.right ? props.right : "50px",
    left: props.right ? props.left : "",
    zIndex: props.zIndex ? props.zIndex : 9999,
    cursor: "pointer",
  };

  return (
    <div style={fabContainer}>
      <div
        style={{
          background: props.background ? props.background : "#995133",
          opacity: props.fabOpacity ? props.fabOpacity : "",
        }}
        className="fab fab-icon-holder"
      >
        <button type="button" className="transparent">
          <i className={props.icon} />
        </button>
      </div>
      <ul className="fab-options">{props.children}</ul>
    </div>
  );
};

export default Fab;

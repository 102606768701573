import React from "react";

function StepIcon({ children }) {
  return (
    <div
      className="user-activity__item__icon"
      style={{
        border: "none",
        boxShadow: "none",
        backgroundColor: "transparent",
      }}
    >
      {children}
    </div>
  );
}

StepIcon.propTypes = {};

export default StepIcon;

export interface ImageLabelI {
  id: string;
  colorHex: string;
  _label: string;
  mobi: {
    name: string;
    value: string;
  } | null;
}

export class ImageLabel implements ImageLabelI {
  id = "";
  colorHex = "";
  _label = "";
  mobi: {
    name: string;
    value: string;
  } | null = null;

  get label(): string | null {
    return this.mobi ? this.mobi.name : null;
  }

  constructor(data?: ImageLabelI) {
    if (data) {
      this.id = data.id;
      this.colorHex = data.colorHex;
      this.mobi = data.mobi;
    }
  }
}

export const DEFAULT_COLORS = [
  "#1abc9c",
  "#3498db",
  "#9b59b6",
  "#f1c40f",
  "#e74c3c",
  "#d35400",
  "#7f8c8d",
  "#34495e",
  "#2ecc71",
  "#341f97",
  "#e84393",
  "#1e272e",
];

export const INITIAL_FILTER = {
  supplier: "",
  tags: [],
  styles: [],
  colors: [],
  features: [],
  colorTones: [],
  typologies: [],
  hasFile: false,
  active: true,
  onlyAvailable: "null",
  order: "frequency_used",
  notUse: true,
  projectTemplateNames: [],
  limit: 100,
  page: 0,
  search: "",
  hasOtherFiles: false,
  category: "",
  typology: "",
  subCategory: "",
  oldTags: {
    categories: [],
    styles: [],
    colors: [],
    typologies: [],
  },
};

import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

import RightSidebar from "./RightSidebar";

const SidebarInfo = ({ title, onToggle, children, show, listFilter }) => {
  return (
    <>
      {ReactDOM.createPortal(
        <RightSidebar
          title={title}
          listFilter={listFilter}
          show={show}
          onClose={() => onToggle(false)}
          className="sidebar-right-mobiList"
        >
          {children}
        </RightSidebar>,
        document.body
      )}
    </>
  );
};

SidebarInfo.propTypes = {
  show: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default SidebarInfo;

import React, { useState, useEffect } from "react";

import Collapse from "react-bootstrap/Collapse";
import CollapseButton from "../../common/CollapseButton";

import {
  FormCheckbox,
  FormSelect,
  Row,
  Col,
  FormInput,
  Button,
} from "shards-react";

import SelectSearch from "react-select-search";
import { debounce } from "../../../utils/throttle";
import MobiTagManager from "../../mobi/MobiTagManager";
import ConfirmationButton from "../../common/ConfirmationButton";
import AsyncRequest from "../../common/AsyncRequest";
import { toast } from "react-toastify";

let onSearchUpdate = () => {};

const types = {
  project: "Lista Final",
  preview: "Proposta Inicial",
  first_review: "1º Revisão",
  second_review: "2º Revisão",
};

function RemoveAllFurnituresConfirmationButton({
  isLoading,
  onRequest,
  reviewType,
}) {
  return (
    <ConfirmationButton
      theme="danger"
      size="md"
      className="ml-2 mr-4"
      disabled={isLoading || !reviewType}
      onClick={onRequest}
      details={`Todos is itens inseridos na etapa "${types[reviewType]}" serão removidos.`}
    >
      <i className="fa fa-trash" />{" "}
      {isLoading ? "Removendo..." : "Remover todos"}
    </ConfirmationButton>
  );
}

function Filters({
  values,
  onFilterUpdate,
  onProjectUpdate,
  clear,
  onCopyReviewClick,
  suppliers,
  projectTemplateNames,
  oldTags,
  styles,
  categories,
  subCategories,
  typologies,
  colors,
  colorTones,
  features,
  refreshMobisFildOptions,
  onRemoveAllByReviewType,
  project,
}) {
  const projectId = project && project._id;
  const [isFilterShown, setIsFilterShown] = useState(true);
  const [search, setSearch] = useState("");

  useEffect(() => {
    setSearch(values.search);
  }, [values.search]);

  useEffect(() => {
    onSearchUpdate = debounce((text) => {
      onFilterUpdate({ search: text });
    }, 800);
  }, []);

  function onCollapseClick() {
    setIsFilterShown(!isFilterShown);
  }

  return (
    <>
      <div className="d-flex mt-2 mb-3">
        {/* eslint-disable-next-line */}
        <div
          className="d-flex flex-row justify-content-center align-items-center clickable"
          onClick={onCollapseClick}
        >
          <CollapseButton theme="primary" collapsed={isFilterShown} />
          <strong>Filtros</strong>
        </div>
      </div>

      <div id="example-collapse-texts" className="pl-4 pb-4">
        <Collapse in={isFilterShown} timeout={0}>
          <div>
            <div className="d-flex filter-row">
              <div className="mr-1" style={{ minWidth: "300px" }}>
                <div>Lista</div>
                <div className="d-flex">
                  <ListFilter
                    filterValues={values}
                    onFilterChange={onFilterUpdate}
                    types={types}
                    project={project}
                    id={projectId}
                  />
                  <div
                    className="d-flex
                  "
                  >
                    <Button
                      theme="primary"
                      size="md"
                      outline
                      className="ml-2"
                      onClick={onCopyReviewClick}
                    >
                      Copiar para outra etapa
                    </Button>
                    <AsyncRequest
                      onRequest={() =>
                        onRemoveAllByReviewType().then((res) => {
                          toast.success(
                            `Itens da etapa ${
                              types[values.type]
                            } foram removidos com sucess!`
                          );
                          return res;
                        })
                      }
                    >
                      <RemoveAllFurnituresConfirmationButton
                        reviewType={values.type}
                      />
                    </AsyncRequest>
                  </div>
                </div>
              </div>
              <div>
                <div style={{ marginTop: "2.0rem" }} />
                <FormCheckbox
                  toggle
                  checked={values.project}
                  onChange={() => {
                    onProjectUpdate({
                      project: !values.project,
                    });
                  }}
                  small
                >
                  <strong>Apenas {types[values.type]}</strong>
                </FormCheckbox>
              </div>
              <div>
                <div style={{ marginTop: "2.0rem" }} />

                <FormCheckbox
                  toggle
                  checked={values.hasOtherFiles}
                  onChange={() => {
                    onFilterUpdate({
                      hasOtherFiles: !values.hasOtherFiles,
                    });
                  }}
                  small
                >
                  <strong>Possui detalhamento</strong>
                </FormCheckbox>
              </div>
              <div>
                <div style={{ marginTop: "2.0rem" }} />

                <FormCheckbox
                  toggle
                  checked={values.hasFile}
                  onChange={() => {
                    onFilterUpdate({
                      hasFile: !values.hasFile,
                    });
                  }}
                  small
                >
                  <strong>Apenas com bloco</strong>
                </FormCheckbox>
              </div>
            </div>

            <Row className="mb-3 mt-3">
              <Col
                className="file-manager__filters__search mt-2"
                sm="12"
                md="2"
              >
                <div className="label">Nome/SKU</div>
                <FormInput
                  value={search}
                  onChange={(ev) => {
                    setSearch(ev.target.value);
                    onSearchUpdate(ev.target.value);
                  }}
                  placeholder="Digite aqui..."
                />
              </Col>
              <Col
                className="file-manager__filters__search mt-2"
                sm="12"
                md="2"
              >
                <div className="label">Pode Usar</div>
                <FormSelect
                  size="sm"
                  value={values.notUse}
                  className="form-select"
                  onChange={(ev) => {
                    onFilterUpdate({
                      notUse:
                        ev.target.value === "null"
                          ? "null"
                          : ev.target.value === "true",
                    });
                  }}
                >
                  <option value="null">Todos</option>
                  <option value="true">Sim</option>
                  <option value="false">Não</option>
                </FormSelect>
              </Col>
              <Col
                className="file-manager__filters__search mt-2"
                sm="12"
                md="2"
              >
                <div className="label">Disponivel</div>
                <FormSelect
                  value={values.onlyAvailable}
                  size="sm"
                  className="form-select"
                  onChange={(ev) => {
                    onFilterUpdate({
                      onlyAvailable:
                        ev.target.value === "null"
                          ? "null"
                          : ev.target.value === "true",
                    });
                  }}
                >
                  <option value="null">Todos</option>
                  <option value="true">Sim</option>
                  <option value="false">Não</option>
                </FormSelect>
              </Col>
              <Col
                className="file-manager__filters__search mt-2"
                sm="12"
                md="2"
              >
                <div className="label">Ambientes</div>
                <SelectSearch
                  options={projectTemplateNames.map((tag) => {
                    return { name: tag, value: tag };
                  })}
                  search={true}
                  multiple
                  closeOnSelect={false}
                  printOptions="on-focus"
                  value={values.projectTemplateNames}
                  onChange={(values) => {
                    onFilterUpdate({
                      projectTemplateNames: values,
                    });
                  }}
                  placeholder="Selecione"
                />
              </Col>
              <Col
                className="file-manager__filters__search mt-2"
                sm="12"
                md="2"
              >
                <div className="label">Fornecedor</div>
                <SelectSearch
                  options={suppliers.map((sup) => {
                    return { name: sup, value: sup };
                  })}
                  search={true}
                  closeOnSelect={true}
                  printOptions="on-focus"
                  value={values.supplier && values.supplier}
                  onChange={(value) => {
                    onFilterUpdate({
                      supplier: value === values.supplier ? "" : value,
                    });
                  }}
                  placeholder="Selecione"
                />{" "}
              </Col>
            </Row>
            <Row>
              <Col
                className="file-manager__filters__search mt-2"
                sm="12"
                md="3"
              >
                <div className="mt-2 mr-1 ml-1">
                  <div className="label">Categorias Antigas</div>
                  <div className="d-flex">
                    <SelectSearch
                      options={oldTags.categories.map((tag) => {
                        return { name: tag.name, value: tag._id };
                      })}
                      search={true}
                      multiple
                      closeOnSelect={false}
                      printOptions="on-focus"
                      value={values.categories}
                      onChange={(values) => {
                        onFilterUpdate({
                          categories: values,
                        });
                      }}
                      placeholder="Selecione"
                    />
                  </div>
                </div>
              </Col>
              <Col
                className="file-manager__filters__search mt-2"
                sm="12"
                md="3"
              >
                <div className="mt-2 mr-1 ml-1">
                  <div className="label">Estilos Antigos</div>
                  <div className="d-flex">
                    <SelectSearch
                      options={oldTags.styles.map((tag) => {
                        return { name: tag.name, value: tag._id };
                      })}
                      search={true}
                      multiple
                      closeOnSelect={false}
                      printOptions="on-focus"
                      value={values.oldTags.styles}
                      onChange={(valuesInput) => {
                        onFilterUpdate({
                          oldTags: {
                            ...values.oldTags,
                            styles: valuesInput,
                          },
                        });
                      }}
                      placeholder="Selecione"
                    />
                  </div>
                </div>
              </Col>
              <Col
                className="file-manager__filters__search mt-2"
                sm="12"
                md="3"
              >
                <div className="mt-2 mr-1 ml-1">
                  <div className="label">Cores Antigas</div>
                  <div className="d-flex">
                    <SelectSearch
                      options={oldTags.colors.map((tag) => {
                        return { name: tag.name, value: tag._id };
                      })}
                      search={true}
                      multiple
                      closeOnSelect={false}
                      printOptions="on-focus"
                      value={values.oldTags.colors}
                      onChange={(valuesInput) => {
                        onFilterUpdate({
                          oldTags: {
                            ...values.oldTags,
                            colors: valuesInput,
                          },
                        });
                      }}
                      placeholder="Selecione"
                    />
                  </div>
                </div>
              </Col>
              <Col
                className="file-manager__filters__search mt-2"
                sm="12"
                md="3"
              >
                <div className="mt-2 mr-1 ml-1">
                  <div className="label">Tipologias Antigas</div>
                  <div className="d-flex">
                    <SelectSearch
                      options={oldTags.typologies.map((tag) => {
                        return { name: tag.name, value: tag._id };
                      })}
                      search={true}
                      multiple
                      closeOnSelect={false}
                      printOptions="on-focus"
                      value={values.typologies}
                      onChange={(values) => {
                        onFilterUpdate({
                          typologies: values,
                        });
                      }}
                      placeholder="Selecione"
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <div className="d-flex filter-row">
              <div className="mt-2 mr-1 ml-1">
                <div className="label">Estilos</div>
                <div className="d-flex">
                  <SelectSearch
                    options={styles.map((tag) => {
                      return { name: tag.name, value: tag._id };
                    })}
                    search={true}
                    multiple
                    closeOnSelect={false}
                    printOptions="on-focus"
                    value={values.styles}
                    onChange={(values) => {
                      onFilterUpdate({ styles: values });
                    }}
                    placeholder="Selecione"
                  />
                  <MobiTagManager
                    onChange={refreshMobisFildOptions}
                    category="style"
                  />
                </div>
              </div>
              <div className="mt-2 mr-1 ml-1">
                <div className="label">Cores</div>
                <div className="d-flex">
                  <SelectSearch
                    options={colors.map((tag) => {
                      return { name: tag.name, value: tag._id };
                    })}
                    search={true}
                    closeOnSelect={false}
                    multiple
                    printOptions="on-focus"
                    value={values.colors}
                    onChange={(values) => {
                      onFilterUpdate({ colors: values });
                    }}
                    placeholder="Selecione"
                  />
                  <MobiTagManager
                    onChange={refreshMobisFildOptions}
                    category="color"
                  />
                </div>
              </div>
              <div className="mt-2 mr-1 ml-1">
                <div className="label">Tom do ambiente</div>
                <div className="d-flex">
                  <SelectSearch
                    options={colorTones.map((tag) => {
                      return { name: tag.name, value: tag._id };
                    })}
                    search={true}
                    closeOnSelect={false}
                    multiple
                    printOptions="on-focus"
                    value={values.colorTones}
                    onChange={(values) => {
                      onFilterUpdate({ colorTones: values });
                    }}
                    placeholder="Selecione"
                  />
                  <MobiTagManager
                    onChange={refreshMobisFildOptions}
                    category="colorTone"
                  />
                </div>
              </div>
            </div>
            <div className="d-flex filter-row">
              <div className="mt-2 mr-1 ml-1">
                <div className="label">Tipologia</div>
                <div className="d-flex">
                  <SelectSearch
                    options={typologies.map((tag) => {
                      return { name: tag.name, value: tag._id };
                    })}
                    search={true}
                    closeOnSelect={true}
                    printOptions="on-focus"
                    value={values.typology}
                    onChange={(value) => {
                      onFilterUpdate({
                        typology: value === values.typology ? "" : value,
                      });
                    }}
                    placeholder="Selecione"
                  />
                  <MobiTagManager
                    allowNested={true}
                    onChange={refreshMobisFildOptions}
                    category="typology"
                  />
                </div>
              </div>
              <div className="mt-2 mr-1 ml-1">
                <div className="label">Categoria</div>
                <div className="d-flex">
                  <SelectSearch
                    options={categories.map((tag) => {
                      return { name: tag.name, value: tag._id };
                    })}
                    search={true}
                    closeOnSelect={true}
                    printOptions="on-focus"
                    value={values.category}
                    onChange={(value) => {
                      onFilterUpdate({
                        category: value === values.category ? "" : value,
                      });
                    }}
                    placeholder="Selecione"
                  />
                </div>
              </div>
              <div className="mt-2 mr-1 ml-1">
                <div className="label">Sub Categoria</div>
                <div className="d-flex">
                  <SelectSearch
                    options={subCategories.map((tag) => {
                      return { name: tag.name, value: tag._id };
                    })}
                    search={true}
                    closeOnSelect={true}
                    printOptions="on-focus"
                    value={values.subCategory}
                    onChange={(value) => {
                      onFilterUpdate({
                        subCategory: value === values.subCategory ? "" : value,
                      });
                    }}
                    placeholder="Selecione"
                  />
                </div>
              </div>
              <div className="mt-2 mr-1 ml-1">
                <div className="label">Características</div>
                <div className="d-flex">
                  <SelectSearch
                    options={features.map((tag) => {
                      return { name: tag.name, value: tag._id };
                    })}
                    search={true}
                    closeOnSelect={false}
                    multiple
                    printOptions="on-focus"
                    value={values.features}
                    onChange={(values) => {
                      onFilterUpdate({ features: values });
                    }}
                    placeholder="Selecione"
                  />
                  <MobiTagManager
                    onChange={refreshMobisFildOptions}
                    category="feature"
                  />
                </div>
              </div>
            </div>
            <Row style={{ justifyContent: "flex-end", padding: "10px" }}>
              <Col
                className="file-manager__filters__search mt-2"
                sm="12"
                md="8"
                style={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <div style={{ width: "120px" }}>
                  <FormSelect
                    size="sm"
                    value={values.order}
                    className="form-select"
                    onChange={(ev) => {
                      onFilterUpdate({
                        order: ev.target.value,
                      });
                    }}
                  >
                    <option value="frequency_used">Frequência</option>
                    <option value="likes">Likes</option>
                    <option value="createdAt">Data</option>
                  </FormSelect>
                </div>
                <div>
                  <Button
                    theme="success"
                    size="md"
                    className="ml-4"
                    outline
                    onClick={() => {
                      onFilterUpdate(clear());
                    }}
                  >
                    Limpar filtros
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </Collapse>
      </div>
    </>
  );
}

const ListFilter = ({ filterValues, onFilterChange, types, project, id }) => {
  return (
    <FormSelect
      className="form-select"
      size="lg"
      value={filterValues.type}
      onChange={(ev) => {
        onFilterChange({
          type: ev.target.value,
          project: ev.target.value ? id : "",
        });
      }}
    >
      <option value="">Selecione uma etapa...</option>
      {Object.keys(types)
        .filter(
          (type) => type !== "project" || (project && project.furnitures.length)
        )
        .map((type, idx) => {
          return (
            <option key={idx} value={type}>
              {types[type]}
            </option>
          );
        })}
    </FormSelect>
  );
};

Filters.propTypes = {};

export { types, ListFilter };
export default Filters;

import React from "react";

function StepContainer({ children }) {
  return (
    <div className="d-flex flex-row align-items-center w-100">
      <div className="user-activity__item pr-3 py-3 w-100">{children}</div>
    </div>
  );
}

StepContainer.propTypes = {};

export default StepContainer;

import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { privateRoutes, publicRoutes } from "./routes";
// eslint-disable-next-line
import "../node_modules/shards-ui/node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./assets/main.scss";

import Authorization from "./components/common/Authorization";
import { MetadataProvider } from "./components/metadata/MetadataContext";
import PublicRoutes from "./components/common/PublicRoutes";
import PrivateRoutes from "./components/common/PrivateRoutes";
import { ToastContainer } from "react-toastify";

const App = () => {
  return (
    <Router basename={process.env.REACT_APP_BASENAME || ""}>
      <Authorization>
        <>
          <ToastContainer />
          <MetadataProvider>
            <PublicRoutes routes={publicRoutes} />
            <PrivateRoutes routes={privateRoutes} />
          </MetadataProvider>
        </>
      </Authorization>
    </Router>
  );
};
export default App;

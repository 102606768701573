import React, { useRef } from "react";
import Dropzone from "react-dropzone";
import classNames from "classnames";

function DropzonePicture({ onDrop, value, isLoading, imgStyle }) {
  const imgRef = useRef();

  function onFileSelect(ev) {
    const file = ev.target.files[0];
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      function () {
        imgRef.current.src = reader.result;
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
    onDrop(ev.target.files[0]);
  }

  return (
    <div className="d-flex flex-row justify-content-center align-items-center clickable">
      {value.name ? (
        <div className="edit-user-details__fullImage mx-auto">
          <img ref={imgRef} src={value.name} alt="" style={imgStyle} />

          <label className="edit-user-details__fullImage__change">
            <i className="material-icons mr-1">&#xE439;</i>
            <input
              type="file"
              disabled={isLoading}
              className="d-none"
              onChange={onFileSelect}
            />
          </label>
        </div>
      ) : (
        <Dropzone
          multiple={false}
          disabled={isLoading}
          onDrop={(file) => onFileSelect({ target: { files: file } })}
          hidden={!value.file}
        >
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div
                {...getRootProps()}
                className={classNames("dropzone", {
                  "dropzone--isActive": isDragActive,
                })}
              >
                {/*TODO Fix loader*/}
                <input {...getInputProps()} />
                <span>
                  {isDragActive
                    ? "Arrasta e solte seus arquivos aqui"
                    : "Clique aqui para fazer um upload"}
                </span>
              </div>
            );
          }}
        </Dropzone>
      )}
    </div>
  );
}

export default DropzonePicture;

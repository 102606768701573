import React from "react";

import "react-toastify/dist/ReactToastify.min.css";

import { getQueryStringValue } from "../actions/auth";
import ProjectTemplateConfig from "../components/ProjectTemplate/ProjectTemplateConfig";

const NewProjectTemplate = () => <ProjectTemplateConfig />;
const EditProjectTemplate = ({ projectTemplateId }) => (
  <ProjectTemplateConfig projectTemplateId={projectTemplateId} />
);

const ProjectTemplate = () => {
  let projectTemplateId = getQueryStringValue("id");

  return projectTemplateId ? (
    <EditProjectTemplate projectTemplateId={projectTemplateId} />
  ) : (
    <NewProjectTemplate />
  );
};
export default ProjectTemplate;

import React from "react";
import PropTypes from "prop-types";
import StepContainer from "./StepContainer";
import StepIcon from "./StepIcon";
import { Button } from "shards-react";
import StepDescription from "./StepDescription";

import DeleteStepButton from "./DeleteStepButton";
const WarningIcon = (
  <i
    className="material-icons"
    style={{ color: "#ffb400", fontSize: "25px", marginTop: "-2px" }}
  >
    warning
  </i>
);

const StepsToBeCorrected = ({ steps, onRemove }) => {
  return (
    <>
      {steps.map((step, index) => {
        return (
          <StepContainer key={`${index}-${step.key}`}>
            <StepIcon>{WarningIcon}</StepIcon>
            <StepDescription text={step.description} />
            <div className="ml-auto">
              <Button
                outline
                disabled
                theme="warning"
                className="ml-auto"
                size="sm"
              >
                Pendente
              </Button>
              <DeleteStepButton onRemove={() => onRemove(step)} />
            </div>
          </StepContainer>
        );
      })}
    </>
  );
};

StepsToBeCorrected.propTypes = {
  steps: PropTypes.array.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default StepsToBeCorrected;

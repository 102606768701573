import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalFooter,
  Button,
  Form,
  FormInput,
} from "shards-react";

const ModalProjectName = (props) => {
  const [show, setShow] = useState(false);
  const [formValue, setFormValue] = useState(props.projectName);

  function onUpdateClick() {
    setShow(false);
    props.onClick(formValue);
  }

  function onCancelClick() {
    setShow(false);
    setFormValue(props.projectName);
  }

  const handleChange = (event) => {
    setFormValue(event.target.value);
  };

  return (
    <>
      <Button
        title="Editar o Nome do Projeto!"
        theme="none"
        size="sm"
        className="ml-1 mb-1"
        onClick={() => {
          setShow(true);
        }}
      >
        <i
          className="fa fa-pencil-alt"
          style={{
            cursor: "pointer",
          }}
        />
      </Button>

      {show && (
        <Modal size="sm" open={show}>
          <ModalHeader
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Form>
              <label htmlFor="#projectname">Editar nome do projeto</label>
              <FormInput
                type="text"
                id="#projectname"
                value={formValue}
                onChange={handleChange}
              />
            </Form>
          </ModalHeader>
          <ModalFooter>
            <Button onClick={onCancelClick} theme="danger">
              Cancelar
            </Button>
            <Button onClick={onUpdateClick} theme="success" className="ml-1">
              Atualizar
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default ModalProjectName;

import React from "react";

import RemovableCategorySetting from "../Empreiteiro/RemovableCategorySetting";

function PageItem({
  item,
  onAddCustomSetting,
  onDeleteCustomSetting,
  onDeleteCategory,
}) {
  switch (item.type) {
    case "text":
      return (
        <RemovableCategorySetting
          onAddCustomSetting={onAddCustomSetting}
          onDeleteCustomSetting={onDeleteCustomSetting}
          onDeleteCategory={onDeleteCategory}
          onToggleSetting={() => {}}
          onToggleByCategory={() => {}}
          name={item.text}
          id={item.id}
          enabled={item.enabled}
          items={item.items || []}
          customSettings={item.items}
          item={item}
        />
      );
    case "subtitle_list":
      return (
        <RemovableCategorySetting
          onAddCustomSetting={onAddCustomSetting}
          onDeleteCustomSetting={onDeleteCustomSetting}
          onDeleteCategory={onDeleteCategory}
          onToggleSetting={() => {}}
          onToggleByCategory={() => {}}
          name={item.text}
          id={item.id}
          enabled={item.enabled}
          items={item.items || []}
          customSettings={item.items}
          item={item}
        />
      );
    default:
      return null;
  }
}

PageItem.propTypes = {};

export default PageItem;

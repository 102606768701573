import React from "react";
import { Container, Row, Col } from "shards-react";
import PageTitle from "../components/common/PageTitle";

import "react-toastify/dist/ReactToastify.min.css";
import "../styles/react_tags.css";

import MobiHOC from "../components/mobi/MobiHOC";

const Mobi = () => {
  return (
    <Container fluid className="main-content-container px-4">
      <Row className="page-header py-4">
        <Col md="6">
          <PageTitle
            title="Mobi"
            subtitle="Visão Geral"
            className="text-sm-left mb-3"
          />
        </Col>
      </Row>

      <MobiHOC />
    </Container>
  );
};
export default Mobi;

import React, { useState, useContext } from "react";

import Collapse from "react-bootstrap/Collapse";
import CollapseButton from "../common/CollapseButton";
import Owner from "./OwnerFilter";

import {
  Container,
  Button,
  FormSelect,
  FormInput,
  Row,
  Col,
} from "shards-react";

import { TableContext } from "../FilteredTable/TableContext";
import Moment from "react-moment";

function Filters({ refresh, isLoading, setFilterTypeStatus }) {
  const { onFilterUpdate, filter } = useContext(TableContext);

  const [isFilterShown, setIsFilterShown] = useState(true);
  const [lastReload, setLastReload] = useState(new Date());

  function onCollapseClick() {
    setIsFilterShown(!isFilterShown);
  }

  function onRefreshClick() {
    refresh().then(() => {
      setLastReload(new Date());
    });
  }
  return (
    <>
      <div className="d-flex mt-2 mb-1 clickable">
        {/* eslint-disable-next-line */}
        <div
          className="d-flex flex-row justify-content-center align-items-center"
          onClick={onCollapseClick}
        >
          <CollapseButton theme="primary" collapsed={isFilterShown} />
          <strong>Filtros</strong>
        </div>
      </div>

      <Collapse in={isFilterShown} timeout={0}>
        <Container fluid className="file-manager__filters  p-2">
          <Row>
            <Col xs="2">
              <div className="form-group">
                <div className="label">Nome do Projeto</div>
                <FormInput
                  onChange={(ev) => {
                    onFilterUpdate("searchName", ev.target.value);
                  }}
                />
              </div>
            </Col>
            <Col xs="2">
              <div className="form-group">
                <div className="label">Ambiente</div>
                <FormInput
                  value={filter.enviromentName && filter.enviromentName.value}
                  onChange={(ev) => {
                    onFilterUpdate("enviromentName", ev.target.value);
                  }}
                />
              </div>
            </Col>
            <Col xs="2">
              <div className="form-group">
                <div className="label">Responsável</div>
                <Owner
                  onOwnerChange={(ownerId) => {
                    onFilterUpdate("owner", ownerId);
                  }}
                />
              </div>
            </Col>
            <Col xs="2">
              <div className="form-group">
                <div className="label">Usuário/Email</div>
                <FormInput
                  onChange={(ev) => {
                    onFilterUpdate("userEmail", ev.target.value);
                  }}
                />
              </div>
            </Col>
            <Col xs="2">
              <div className="form-group">
                <div className="label">Mensagem</div>
                <FormSelect
                  className="form-control"
                  onChange={(ev) => {
                    onFilterUpdate("highlight", ev.target.value);
                  }}
                >
                  <option value="">Todas</option>
                  <option value="true">Não lidas</option>
                </FormSelect>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="2">
              <div className="form-group">
                <div className="label">Status</div>
                <FormSelect
                  className="form-control"
                  onChange={(ev) => {
                    onFilterUpdate("status", ev.target.value);
                  }}
                >
                  <option value="">Todos</option>
                  <option value="Em progresso">Em progresso</option>
                  <option value="Confirmar dados">Confirmar dados</option>
                  <option value="Aguardando resposta">
                    Aguardando resposta
                  </option>
                  <option value="Em produção">Em produção</option>
                  <option value="Confirmar dados revisão">
                    Confirmar dados revisão
                  </option>
                  <option value="Aguardando resposta cliente">
                    Aguardando resposta cliente
                  </option>
                  <option value="Em Revisão">Em Revisão</option>
                  <option value="Proposta Inicial">Proposta Inicial</option>
                  <option value="1º Revisão">1º Revisão</option>
                  <option value="2º Revisão">2º Revisão</option>
                  <option value="Aguardando qualidade">
                    Aguardando qualidade
                  </option>
                  <option value="Revisando qualidade">
                    Revisando qualidade
                  </option>
                  <option value="Aguardando Projeto Final">
                    Aguardando Projeto Final
                  </option>
                  <option value="Projeto Entregue">Projeto Entregue</option>
                </FormSelect>
              </div>
            </Col>
            <Col xs="2">
              <div className="form-group">
                <div className="label">Entrega</div>
                <FormSelect
                  className="form-control"
                  onChange={(ev) => {
                    onFilterUpdate("color", ev.target.value);
                  }}
                >
                  <option value="">Todos</option>
                  <option value="darkblue">Atrasada</option>
                  <option value="#30305b">Vencido</option>
                  <option value="red">Hoje</option>
                  <option value="orange">Um Dia</option>
                  <option value="#b1b11d">Dois Dias</option>
                  <option value="green">Três Dias</option>
                  <option value="#17c671">Quatro Dias</option>
                  <option value="#1adba2">Cinco Dias</option>
                </FormSelect>
              </div>
            </Col>
            <Col xs="2">
              <div className="form-group">
                <div className="label">Tipo de projetos</div>
                <FormSelect
                  className="form-control"
                  onChange={(ev) => {
                    setFilterTypeStatus(ev.target.value);
                  }}
                >
                  <option value="active">Ativos</option>
                  <option value="enabled">Em progresso</option>
                  <option value="final_project">Finalizados</option>
                  <option value="all">Todos</option>
                </FormSelect>
              </div>
            </Col>
            {/* <Col className="file-manager__filters__search mt-2" sm="12" md="2">
              <label>Ambientes</label>
              <SelectSearch
                options={projectTemplateNames.map((tag) => {
                  return { name: tag, value: tag };
                })}
                search={true}
                multiple
                closeOnSelect={false}
                printOptions="on-focus"
                value={filter.projectTemplateNames}
                onChange={(values) => {
                  onFilterUpdate({
                    projectTemplateNames: values,
                  });
                }}
                placeholder="Selecione"
              />
            </Col> */}
          </Row>

          <div className="d-flex flex-row justify-content-between">
            <div className="form-group">
              <Button
                theme="success"
                onClick={onRefreshClick}
                disabled={isLoading}
              >
                {isLoading ? "Recarregando" : "Recarregar"}
              </Button>
              <span className="d-flex flex-column mt-2">
                Data da pesquisa:
                <Moment format="DD/MM HH:mm">{lastReload}</Moment>
              </span>
            </div>
          </div>
        </Container>
      </Collapse>
    </>
  );
}

Filters.propTypes = {};

export default Filters;

import React, { useState, useEffect } from "react";

import { useParams, Route, Switch } from "react-router-dom";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import { Enterprise as EnterpriseContent } from "../components/Enterprise";

import { required } from "../utils/form_validation";
import { getDescendantProp } from "../utils/sanitize";
import { createEnterprise } from "../actions/enterprise";

const Enterprise = () => {
  const { id } = useParams();
  const isNew = !id;

  const [enterprise, setEnterprise] = useState({
    name: "",
    company: "",
    userMaster: "",
    address: "",
    logo: "",
    rooms: [],
    learningEnvironment: {
      doorColor: "",
      frameColor: "",
      plaster: "",
      others: "",
    },
    categories: [],
    styles: [],
  });

  const [stepValidations, setStepValidations] = useState([
    {
      name: {
        validators: [required],
        isValid: true,
        accessor: "name",
        status: { neutral: true },
      },
      address: {
        validators: [required],
        isValid: true,
        accessor: "address",
        status: { neutral: true },
      },
      company: {
        validators: [required],
        isValid: true,
        accessor: "company",
        status: { neutral: true },
      },
      userMaster: {
        validators: [required],
        isValid: true,
        accessor: "userMaster",
        status: { neutral: true },
      },
    },
    {
      learningEnvironment1: {
        validators: [required],
        isValid: true,
        accessor: "learningEnvironment.doorColor",
        status: { neutral: true },
      },
      learningEnvironment2: {
        validators: [required],
        isValid: true,
        accessor: "learningEnvironment.frameColor",
        status: { neutral: true },
      },
      learningEnvironment3: {
        validators: [required],
        isValid: true,
        accessor: "learningEnvironment.plaster",
        status: { neutral: true },
      },
    },
    {},
    {},
    {},
  ]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isNew) {
      setIsLoading(false);
    }
  }, []);

  function onChange(data) {
    setEnterprise({ ...data });
  }

  function onCreate(data, projects) {
    return createEnterprise(data, projects)
      .then(() => {
        toast.success("Parceiro criado com sucesso", { autoClose: 5000 });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function onUpdate() {}

  function validateStep(step, etp = enterprise) {
    const stepValidation = stepValidations[step];
    resetValidations(stepValidation);
    validate(stepValidation, etp);
    setStepValidations([...stepValidations]);
    return isFormValid(stepValidation);
  }

  function isFormValid(formValidations) {
    let keys = Object.keys(formValidations);
    return keys.reduce((isValid, key) => {
      return isValid && formValidations[key].isValid;
    }, true);
  }

  function validate(formValidations, enterprise) {
    let fieldNames = Object.keys(formValidations);
    let validations = fieldNames.reduce((acc, current) => {
      const accessor = formValidations[current].accessor;
      acc[current] = isFieldValid(
        current,
        accessor,
        formValidations,
        enterprise
      );
      return acc;
    }, {});
    applyValidationsToForm(validations, formValidations);
  }

  function resetValidations(formValidations) {
    let fieldNames = Object.keys(formValidations);
    fieldNames.forEach((field) => {
      formValidations[field].isValid = true;
      formValidations[field].error = false;
      formValidations[field].status = { neutral: true };
    });
  }

  function applyValidationsToForm(validations, formValidations) {
    let keys = Object.keys(formValidations);
    keys.forEach((key) => {
      formValidations[key].isValid = validations[key];
      if (!validations[key]) {
        formValidations[key].error = true;
      }
      formValidations[key].status = getHtmlValidations(key, formValidations);
    });
  }

  function isFieldValid(validatorName, accessor, formValidations, enterprise) {
    let value = getDescendantProp(
      enterprise,
      accessor ? accessor : validatorName
    );
    let fieldValidations = formValidations[validatorName].validators;
    let result = fieldValidations.reduce((isValid, nextValidation) => {
      return isValid && nextValidation(value, enterprise);
    }, true);
    return result;
  }

  function onSave(projects) {
    setIsLoading(true);

    return (enterprise._id ? onUpdate : onCreate)(enterprise, projects);
  }

  function getHtmlValidations(key, formValidations) {
    return {
      [typeof formValidations[key].error === "undefined"
        ? "neutral"
        : formValidations[key].error
        ? "invalid"
        : "valid"]: true,
    };
  }

  return (
    <>
      <Switch>
        <Route path={"/enterprise"} exact={true}>
          <EnterpriseContent
            enterprise={enterprise}
            onChange={onChange}
            onSave={onSave}
            isLoading={isLoading}
            validateStep={validateStep}
            stepValidations={stepValidations}
          />
        </Route>
      </Switch>
    </>
  );
};
export default Enterprise;

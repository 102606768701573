// @ts-ignore
import { API } from "../config";
// @ts-ignore
import { requestWrapper } from "../utils/api";
// @ts-ignore
import { getCookie } from "./auth";

export interface UserI {
  _id: string;
}

export interface FilterI {
  page: number;
}

export const getUsersByFilter = (data: FilterI): Promise<UserI[]> => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/users/filter`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(data),
    })
  );
};

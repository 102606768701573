import { API } from "../config";
import { getCookie } from "../actions/auth";
import { getCompanyUrlParams } from "../utils/sanitize";

export const createMobiGroup = (group) => {
  const token = getCookie("token");

  return fetch(`${API}/mobi-group`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "x-access-token": token,
    },
    body: JSON.stringify(group),
  })
    .then((res) => {
      if (res.status !== 200) {
        throw new Error("");
      }
      return res;
    })
    .then((response) => {
      return response.json();
    });
};

export const updateMobiGroup = (group) => {
  const token = getCookie("token");

  return fetch(`${API}/mobi-group/${group._id}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "x-access-token": token,
    },
    body: JSON.stringify(group),
  })
    .then((res) => {
      if (res.status !== 200) {
        throw new Error("");
      }
      return res;
    })
    .then((response) => {
      return response.json();
    });
};

export const getAllMobiGroup = () => {
  const token = getCookie("token");

  return fetch(`${API}/mobi-group/all`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "x-access-token": token,
    },
  })
    .then((res) => {
      if (res.status !== 200) {
        throw new Error("");
      }
      return res;
    })
    .then((response) => {
      return response.json();
    });
};

export const getFurnituresByGroup = (groupId, params) => {
  const token = getCookie("token");

  return fetch(
    `${API}/mobi-group/${groupId}/furnitures${getCompanyUrlParams(params)}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    }
  )
    .then((res) => {
      if (res.status !== 200) {
        throw new Error("");
      }
      return res;
    })
    .then((response) => {
      return response.json();
    });
};

export const deleteMobiGroup = (groupId) => {
  const token = getCookie("token");

  return fetch(`${API}/mobi-group/${groupId}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "x-access-token": token,
    },
  })
    .then((res) => {
      if (res.status !== 200) {
        throw new Error("");
      }
      return res;
    })
    .then((response) => {
      return response.json();
    });
};

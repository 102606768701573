import React, { useState, useEffect } from "react";
import { Container, Modal, Button } from "shards-react";

import "react-toastify/dist/ReactToastify.min.css";
import Loader from "react-loader-spinner";

import FuzzySearch from "fuzzy-search";
import { toast } from "react-toastify";

import { getAllMobiGroup } from "../../../actions/mobiGroup";
import { addGroupToProject } from "../../../actions/projects";
import { debounce } from "../../../utils/throttle";

import ConfirmationButton from "../../common/ConfirmationButton";
import FilteredTable from "../../FilteredTable/index";

const ModalAddGroup = ({ toggle, projectId, show, onUpdate }) => {
  const [state, setState] = useState({
    table: {
      pageSizeOptions: [5, 10, 15, 20, 25, 30],
      pageSize: 5,
      data: [],
      searcher: null,
      searchText: null,
      fullList: null,
    },
    modal: false,
  });

  const [isLoading, setIsLoading] = useState(false);

  const tableColumns = [
    {
      Header: "NOME",
      accessor: "name",
      className: "text-center",
    },
    {
      Header: "QTD. MOBI",
      accessor: "furnitures.length",
      className: "text-center",
      Cell: (row) =>
        row.original.furnitures.reduce((acc, current) => {
          acc += current.count;
          return acc;
        }, 0),
    },
    {
      Header: "AÇÕES",
      accessor: "actions",
      maxWidth: 150,
      sortable: false,
      Cell: (row) => {
        return (
          <>
            <ConfirmationButton
              disabled={isLoading}
              theme="primary"
              onClick={() => {
                onAddGroup(row.original._id);
              }}
            >
              Adicionar
            </ConfirmationButton>
            <Button
              className="ml-2"
              onClick={() => {
                window.open(
                  `/furniture-groups-edit?id=${row.original._id}`,
                  "_blank"
                );
              }}
              outline
              theme="primary"
            >
              <i className="material-icons">remove_red_eye</i>
            </Button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    refresh();
  }, []);

  function refresh() {
    setIsLoading(true);
    return getAllMobiGroup()
      .then((res) => {
        setIsLoading(false);
        state.table.data = res;
        let searcher = new FuzzySearch(res, ["name"], {
          caseSensitive: false,
          sort: true,
        });
        state.table.searchText = debounce((value) => {
          state.table.data = searcher.search(value);
          setState({ ...state });
        }, 800);
        setState({ ...state });
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }

  function onAddGroup(id) {
    setIsLoading(true);
    addGroupToProject(projectId, id)
      .then(() => {
        toast.success("Grupo adicionado com sucesso", { autoClose: 5000 });
        setIsLoading(false);
        toggle(false);
        onUpdate();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Ocorreu um erro, por favor, tente novamente", {
          autoClose: 5000,
        });
        setIsLoading(false);
      });
  }

  return (
    <Modal
      size="lg"
      modalContentClassName="card card-small"
      open={show}
      toggle={() => toggle(!show)}
    >
      <Container>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              height: "150px",
            }}
          >
            <Loader width="50px" height="50px" type="Rings" color="black" />
          </div>
        ) : (
          <FilteredTable
            columns={tableColumns}
            list={state.table.data}
            config={{
              pageSizeOptions: [5, 10, 15, 20, 30],
              searchableColumns: ["name"],
              pageSize: 10,
            }}
          />
        )}
      </Container>
    </Modal>
  );
};

export default ModalAddGroup;

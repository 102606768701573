import React, { useState, useEffect } from "react";
import { Row, Card, Col, FormTextarea, CardFooter } from "shards-react";

import classNames from "classnames";

function LearningEnvironment({ onChange, enterprise }) {
  const [questions, setQuestions] = useState([
    {
      questionText: "Material da porta",
      nameState: "doorColor",
      options: [
        { text: "Madeira clara", selected: false },
        { text: "Laqueada", selected: false },
        { text: "Madeira Escura", selected: false },
      ],
    },
    {
      questionText: "Cor da esquadria",
      nameState: "frameColor",
      options: [
        { text: "Branca", selected: false },
        { text: "Preta", selected: false },
        { text: "Aluminio", selected: false },
        { text: "Madeira", selected: false },
        { text: "Vidro temperado", selected: false },
      ],
    },
    {
      questionText: "Possui Gesso?",
      nameState: "plaster",
      options: [
        { text: "Sim", selected: false },
        { text: "Não", selected: false },
      ],
    },
  ]);

  useEffect(() => {
    const fields = ["doorColor", "frameColor", "plaster"];
    fields.forEach((field) => {
      const question = questions.find(
        (question) => question.nameState === field
      );
      const value = enterprise.learningEnvironment[field];
      const option = question.options.find((option) => option.text === value);
      if (option) {
        option.selected = true;
      }
    });
    setQuestions([...questions]);
  }, []);

  const updateText = (text, nameState) => {
    let newEnterprise = enterprise;
    newEnterprise.learningEnvironment[nameState] = text;

    onChange({ ...newEnterprise }, 1);
  };

  const handleInputClick = (questionText, optionText, nameState) => {
    let newEnterprise = enterprise;
    newEnterprise.learningEnvironment[nameState] = optionText;

    onChange({ ...newEnterprise }, 1);
    setQuestions(
      questions.map((item) => {
        if (item.questionText === questionText) {
          item.options.map((option) => {
            option.selected = false;
            if (option.text === optionText) {
              option.selected = true;
            }
          });
        }
        return item;
      })
    );
  };
  return (
    <Col>
      <Row>
        <h3>Conhecendo seu espaço</h3>
      </Row>
      <div className="file-manager file-manager-cards">
        {questions.map((question, i) => {
          return (
            <Row key={i}>
              <h5>{question.questionText}</h5>
              <Row className="justify-content-md-left col-md-12">
                {question.options.map((option, j) => {
                  const classes = classNames(
                    "mb-3",
                    "file-manager__item",
                    option.selected && "file-manager__item--selected"
                  );

                  return (
                    <Col lg="3" sm="6" key={j}>
                      <Card
                        small
                        className={classes}
                        onClick={() =>
                          handleInputClick(
                            question.questionText,
                            option.text,
                            question.nameState
                          )
                        }
                      >
                        <CardFooter className="border-top">
                          <h6 className="file-manager__item-title">
                            {option.text}
                          </h6>
                        </CardFooter>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </Row>
          );
        })}
      </div>
      <Row>
        <h6 className="file-manager__item-title">Outros</h6>
        <FormTextarea
          rows="10"
          value={enterprise.learningEnvironment.others}
          onChange={(text) => {
            updateText(text.target.value, "others");
          }}
        />
      </Row>
    </Col>
  );
}

export default LearningEnvironment;

import React from "react";
import colors from "../../utils/colors";

const CollapseButton = ({ collapsed, onClick, theme }) => {
  return (
    <button
      onClick={onClick}
      aria-controls="example-collapse-texts"
      aria-expanded={collapsed}
      style={{
        width: "25px",
        height: "25px",
        backgroundColor: "white",
        padding: "0px",
        border: "none",
        color: !theme ? "black" : colors[theme].value,
        marginRight: "8px",
      }}
    >
      <span className="material-icons">
        {collapsed ? "keyboard_arrow_down" : "keyboard_arrow_right"}
      </span>
    </button>
  );
};

export default CollapseButton;

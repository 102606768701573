/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { useEffect, useState, useContext } from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  FormCheckbox,
  Button,
  CardBody,
  FormFeedback,
  Card,
  Collapse,
} from "shards-react";
import Loader from "react-loader-spinner";
import {
  valida_cpf_cnpj,
  validateCep,
  required,
  emailValidation,
  phoneValidation,
} from "../../utils/form_validation";

import AddProduct from "./AddProduct";
import CpfCnpj from "../shared/CpfCnpj";
import PhoneBR from "../shared/PhoneBR";
import StateSelect from "../shared/StateSelect";
import Cep from "../shared/Cep";

import { API } from "../../config";
import { getAuthenticatedUser } from "../../actions/auth";
import { getDescendantProp } from "../../utils/sanitize";
import { MetadataContext } from "../metadata/MetadataContext";
import { setAdminAccessToken } from "../../actions/auth";

import IfAllowed from "../../components/common/IfAllowed";

const FormUser = ({
  user,
  onUserChange,
  onSaveClick,
  onAddProject,
  isLoading,
}) => {
  const { metadata } = useContext(MetadataContext);
  const [adminTokenAccess, setAdminTokenAccess] = useState("");
  const [tokenTokStok, setTokenTokStok] = useState("");

  const currentUser = getAuthenticatedUser();

  const [roles, setRoles] = useState([
    "admin",
    "customer",
    "p1_manager",
    "p1_user",
    "white_label",
    "just_mobi",
    "p1_user_mobi-tags",
  ]);

  const companies = metadata.companies.map((company) => ({
    value: company.label,
    tag: company.name,
  }));

  useEffect(() => {
    if (currentUser.role === "master") {
      setRoles([...roles, "master"]);
    } else if (!["master", "admin"].includes(currentUser.role)) {
      setRoles([]);
    }

    if (user._id) {
      setAdminAccessToken(user._id).then((res) => {
        setAdminTokenAccess(res?.tokenAccess);
      });
      setTokenTokStok(user?.userIdTokStok);
    }
  }, [user._id]);

  const { admin, role, company } = user;

  const { lastName, document, phone, firstName } = user.profile;

  const { street, number, postalCode, state, city } = user.address;

  const companyInfo = metadata.companies.find((data) => data.label === company);

  const [formValidations, setFormValidations] = useState({
    firstName: {
      validators: [required],
      isValid: true,
      accessor: "profile.firstName",
    },
    lastName: {
      validators: [required],
      isValid: true,
      accessor: "profile.lastName",
    },
    email: {
      validators: [required, emailValidation],
      isValid: true,
    },
    document: {
      validators: [required, valida_cpf_cnpj],
      isValid: true,
      accessor: "profile.document",
    },
    phone: {
      validators: [required, phoneValidation],
      isValid: true,
      accessor: "profile.phone",
    },
    postalCode: {
      validators: [required, validateCep],
      isValid: true,
      accessor: "address.postalCode",
    },
    street: {
      validators: [required],
      isValid: true,
      accessor: "address.street",
    },
    number: {
      validators: [required],
      isValid: true,
      accessor: "address.number",
    },
    city: {
      validators: [required],
      isValid: true,
      accessor: "address.city",
    },
    state: {
      validators: [required],
      isValid: true,
      accessor: "address.state",
    },
    company: {
      validators: [required],
      isValid: true,
      accessor: "company",
    },
  });
  //TODO
  // const createProjects = (userId) => {
  //   values.projects.forEach((p, idx) => {

  //   });
  // };

  const onSubmit = (e) => {
    e.preventDefault();
    validate();
    if (isFormValid()) {
      onSaveClick().then(() => {});
    }

    return false;
  };

  const handleChange = (name) => (e) => {
    onUserChange({
      ...user,
      [name]: e.target.value,
    });
    if (isInputValid(name, e.target.value)) {
      formValidations[name].error = false;
    } else {
      formValidations[name].error = true;
    }
    setFormValidations({ ...formValidations });
  };

  function isInputValid(field, value) {
    let fieldValidations = formValidations[field].validators;
    let result = fieldValidations.reduce((isValid, nextValidation) => {
      return isValid && nextValidation(value, user);
    }, true);
    if (formValidations[field].postValidate) {
      formValidations[field].postValidate(result);
    }
    return result;
  }

  function isFieldValid(validatorName, accessor) {
    let value = getDescendantProp(user, accessor ? accessor : validatorName);
    let fieldValidations = formValidations[validatorName].validators;
    let result = fieldValidations.reduce((isValid, nextValidation) => {
      return isValid && nextValidation(value, user);
    }, true);
    return result;
  }

  function onCepAddressInfo(info) {
    onUserChange({
      ...user,
      address: {
        street: info.street,
        state: info.state,
        city: info.city,
        postalCode: info.cep,
      },
    });
  }

  function isFormValid() {
    let keys = Object.keys(formValidations);
    return keys.reduce((isValid, key) => {
      return isValid && formValidations[key].isValid;
    }, true);
  }

  function validate() {
    let fieldNames = Object.keys(formValidations);
    let validations = fieldNames.reduce((acc, current) => {
      const accessor = formValidations[current].accessor;
      acc[current] = isFieldValid(current, accessor);
      return acc;
    }, {});
    applyValidationsToForm(validations);
  }

  function applyValidationsToForm(validations) {
    let keys = Object.keys(formValidations);
    keys.forEach((key) => {
      formValidations[key].isValid = validations[key];
      if (!validations[key]) {
        formValidations[key].error = true;
      }
    });
    onUserChange({
      ...user,
    });
  }

  function getHtmlValidations(key) {
    return {
      [typeof formValidations[key].error === "undefined"
        ? "neutral"
        : formValidations[key].error
        ? "invalid"
        : "valid"]: true,
    };
  }

  const handleSelectRole = (e) => {
    onUserChange({
      ...user,
      role: e.target.value,
    });
  };

  const handleSelectCompany = (e) => {
    onUserChange({
      ...user,
      company: e.target.value,
    });
  };

  const canCreate = () => {
    if (currentUser.role === "admin" || currentUser.role === "master") {
      return true;
    }
    return false;
  };

  const [projectsCollapse, setProjectsCollapse] = useState(false);

  const toggleAddProjects = () => {
    setProjectsCollapse(!projectsCollapse);
  };

  return (
    <Card>
      <CardBody>
        <Row noGutters className="h-100">
          <Col lg="8" md="12" sm="12" className=" mx-auto my-auto">
            {/* Logo */}
            {/* Title */}
            <div className="auth-form__title text-center mb-4" />

            {/* Form Fields */}
            <Form onSubmit={onSubmit}>
              <FormGroup>
                <div className="d-flex">
                  <div htmlFor="exampleInputEmail1">Email</div>
                  {user._id && (
                    <IfAllowed resource="admin-ladecora:user:dash-access">
                      <span>
                        <button
                          type="button"
                          className="material-icons transparent"
                          onClick={() => {
                            if (company === "tok_stok" || company === "") {
                              window.open(
                                `${companyInfo.host}/validator-user?u=` +
                                  tokenTokStok +
                                  "&page=myorders"
                              );
                            } else {
                              window.open(
                                `${companyInfo.host}/login-admin?token=` +
                                  adminTokenAccess +
                                  "&page=checkout"
                              );
                            }
                          }}
                        >
                          &#xe8a6;
                        </button>
                      </span>
                    </IfAllowed>
                  )}
                </div>
                <FormInput
                  required
                  type="email"
                  id="user-input-email"
                  placeholder="Informe seu email"
                  autoComplete="email"
                  value={user.email}
                  onChange={handleChange("email")}
                  {...getHtmlValidations("email")}
                />
                <FormFeedback valid />
                <FormFeedback invalid>Email inválido</FormFeedback>
              </FormGroup>
              <Row form>
                <Col md="6" className="form-group">
                  <div>Nome</div>
                  <FormInput
                    id="user-input-first-name"
                    required
                    type="text"
                    placeholder="Informe seu nome"
                    autoComplete="firstName"
                    value={firstName}
                    onChange={(e) => {
                      onUserChange({
                        ...user,
                        profile: {
                          ...user.profile,
                          firstName: e.target.value,
                        },
                      });
                    }}
                    {...getHtmlValidations("firstName")}
                  />
                  <FormFeedback valid />
                  <FormFeedback invalid>Nome inválido</FormFeedback>
                </Col>
                <Col md="6">
                  <div>Sobrenome</div>
                  <FormInput
                    required
                    id="user-input-last-name"
                    type="text"
                    placeholder="Digite seu sobrenome"
                    autoComplete="lastName"
                    value={lastName}
                    onChange={(e) => {
                      onUserChange({
                        ...user,
                        profile: {
                          ...user.profile,
                          lastName: e.target.value,
                        },
                      });
                    }}
                    {...getHtmlValidations("lastName")}
                  />
                  <FormFeedback valid />
                  <FormFeedback invalid>Sobrenome inválido</FormFeedback>
                </Col>
              </Row>

              <FormGroup>
                <div>CPF/CNPJ</div>
                <CpfCnpj
                  required
                  value={document}
                  id="user-input-cpf-cnpj"
                  onChange={(document) => {
                    onUserChange({
                      ...user,
                      profile: {
                        ...user.profile,
                        document: document.value,
                        documentType: document.type,
                      },
                    });
                    if (isInputValid("document", document.value)) {
                      formValidations.document.error = false;
                    } else {
                      formValidations.document.error = true;
                    }
                    setFormValidations({
                      ...formValidations,
                    });
                  }}
                  placeholder="Informe seu CPF/CNPJ"
                  autoComplete="document"
                  class="form-control"
                  {...getHtmlValidations("document")}
                />
                <FormFeedback valid />
                <FormFeedback invalid>CPF/CNPJ inválido</FormFeedback>
              </FormGroup>
              <FormGroup>
                <div>Telefone</div>
                <PhoneBR
                  required
                  id="user-input-phone"
                  value={phone}
                  onChange={(phone) => {
                    onUserChange({
                      ...user,
                      profile: {
                        ...user.profile,
                        phone: phone,
                      },
                    });
                    if (isInputValid("phone", phone)) {
                      formValidations.phone.error = false;
                    } else {
                      formValidations.phone.error = true;
                    }
                    setFormValidations({
                      ...formValidations,
                    });
                  }}
                  placeholder="Informe seu telefone"
                  autoComplete="phone"
                  name="phone"
                  class="form-control"
                  {...getHtmlValidations("phone")}
                />
                <FormFeedback valid />
                <FormFeedback invalid>Telefone inválido</FormFeedback>
              </FormGroup>

              <FormGroup>
                <div>Cep</div>
                <Cep
                  required
                  value={postalCode}
                  url={`${API}/cep`}
                  onSearch={onCepAddressInfo}
                  onChange={(postalCode) => {
                    onUserChange({
                      ...user,
                      address: {
                        ...user.address,
                        postalCode: postalCode,
                      },
                    });
                    if (isInputValid("postalCode", postalCode)) {
                      formValidations.postalCode.error = false;
                    } else {
                      formValidations.postalCode.error = true;
                    }
                    setFormValidations({
                      ...formValidations,
                    });
                  }}
                  placeholder="Informe seu cep"
                  autoComplete="postalCode"
                  name="postalCode"
                  class="form-control"
                  {...getHtmlValidations("postalCode")}
                />
              </FormGroup>
              <Row form>
                <Col md="3" xs="12" sm="12" className="form-group">
                  <div>Estado</div>
                  <StateSelect
                    required
                    value={state}
                    onChange={(state) => {
                      onUserChange({
                        ...user,
                        address: {
                          ...user.address,
                          state: state.target.value,
                        },
                      });
                    }}
                    {...{
                      [typeof formValidations.state.error === "undefined"
                        ? "neutral"
                        : formValidations.state.error
                        ? "invalid"
                        : "neutral"]: true,
                    }}
                  />
                </Col>
                <Col md="9">
                  <div>Cidade</div>
                  <FormInput
                    required
                    type="text"
                    placeholder="Cidade"
                    autoComplete="city"
                    value={city}
                    onChange={(city) => {
                      onUserChange({
                        ...user,
                        address: {
                          ...user.address,
                          city: city.target.value,
                        },
                      });
                    }}
                    {...getHtmlValidations("city")}
                  />
                  <FormFeedback valid />
                  <FormFeedback invalid>Cidade inválido</FormFeedback>
                </Col>
              </Row>
              <Row form>
                <Col md="9">
                  <div>Endereço</div>
                  <FormInput
                    required
                    type="text"
                    placeholder="Endereço"
                    autoComplete="street"
                    value={street}
                    onChange={(street) => {
                      onUserChange({
                        ...user,
                        address: {
                          ...user.address,
                          street: street.target.value,
                        },
                      });
                    }}
                    {...getHtmlValidations("street")}
                  />
                  <FormFeedback valid />
                  <FormFeedback invalid>Endereço inválido</FormFeedback>
                </Col>
                <Col md="3" xs="12" sm="12" className="form-group">
                  <div>Número</div>
                  <FormInput
                    required
                    type="tel"
                    placeholder="Número"
                    autoComplete="addressNumber"
                    name="number"
                    value={number}
                    onChange={(number) => {
                      onUserChange({
                        ...user,
                        address: {
                          ...user.address,
                          number: number.target.value,
                        },
                      });
                    }}
                    {...getHtmlValidations("number")}
                  />
                  <FormFeedback valid />
                  <FormFeedback invalid>Número inválido</FormFeedback>
                </Col>
              </Row>

              <IfAllowed resource="admin-ladecora:user:edit-user">
                <Row className={!canCreate ? "hidden" : ""} form>
                  <Col xs="12" className="form-group">
                    <div>Contexto</div>
                    {
                      <FormSelect
                        size="sm"
                        required
                        id="user-input-company"
                        value={company}
                        onChange={handleSelectCompany}
                        defaultValue=""
                      >
                        <option value="" disabled>
                          Nenhum
                        </option>
                        {companies.map((comp, idx) => (
                          <option key={idx} value={comp.value}>
                            {comp.tag}
                          </option>
                        ))}
                      </FormSelect>
                    }
                  </Col>
                </Row>
                {user.company === "tok_stok" ? (
                  <>
                    <Row form>
                      <Col xs="12" className="form-group">
                        <div>
                          <strong>tokenTokStok</strong>
                        </div>
                        <FormInput
                          type="text"
                          placeholder="Informe o token..."
                          value={user.tokenTokStok}
                          onChange={(ev) => {
                            onUserChange({
                              ...user,
                              tokenTokStok: ev.target.value,
                            });
                          }}
                        />
                      </Col>
                    </Row>
                    <Row form>
                      <Col xs="12" className="form-group">
                        <div>
                          <strong>userIdTokStok</strong>
                        </div>
                        <FormInput
                          type="text"
                          placeholder="Informe o userIdTokStok..."
                          value={user.userIdTokStok}
                          onChange={(ev) => {
                            onUserChange({
                              ...user,
                              userIdTokStok: ev.target.value,
                            });
                          }}
                        />
                      </Col>
                    </Row>
                  </>
                ) : null}
                <Row form>
                  <Col md="12">
                    <div> </div>
                    <FormGroup>
                      <FormCheckbox
                        checked={admin}
                        onChange={() => {
                          onUserChange({
                            ...user,
                            admin: !admin,
                          });
                        }}
                      >
                        Administrador
                      </FormCheckbox>
                    </FormGroup>
                  </Col>
                </Row>
                {user.admin && currentUser.company === "ladecora" ? (
                  <>
                    <Row className={!canCreate ? "hidden" : ""} form>
                      <Col md="3" xs="12" sm="12" className="form-group">
                        <div>Permissão</div>

                        <FormSelect
                          size="sm"
                          required
                          value={role}
                          onChange={handleSelectRole}
                          defaultValue=""
                        >
                          <option disabled value="">
                            Nenhum
                          </option>
                          {roles.map((roleName, idx) => (
                            <option key={idx} value={roleName}>
                              {roleName}
                            </option>
                          ))}
                        </FormSelect>
                      </Col>
                    </Row>
                  </>
                ) : null}

                {user._id && currentUser.company === "ladecora" ? (
                  <Row className={"my-5"}>
                    <Col className={"text-center"}>
                      <Button onClick={toggleAddProjects}>
                        Cadastrar Projetos
                      </Button>
                      <Collapse open={projectsCollapse}>
                        <AddProduct
                          onAddProject={onAddProject}
                          isLoading={isLoading}
                          values={user}
                          company={user.company}
                        />
                      </Collapse>
                    </Col>
                  </Row>
                ) : null}
                <Row form>
                  <Col md="12" xs="12" sm="12" className="form-group">
                    <hr className="mt-4" />
                    <Button
                      size="lg"
                      id="user-button-save"
                      theme="accent"
                      className="d-table mx-auto mt-3"
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading && (
                        <Loader
                          width="30px"
                          height="15px"
                          type="Rings"
                          color="#ffffff"
                        />
                      )}
                      {!isLoading && "Salvar"}
                    </Button>
                  </Col>
                </Row>
              </IfAllowed>
            </Form>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default FormUser;

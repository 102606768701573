import { API } from "../config";
import { getCookie } from "../actions/auth";
import { requestWrapper } from "../utils/api";

export const listVouchers = (params) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/vouchers?company=${params.company}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "x-access-token": token,
      },
    })
  );
};

export const createVoucher = (voucher) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/voucher/create`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify(voucher),
    })
  );
};

export const updateVoucher = (voucherId, voucher) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/voucher/update/${voucherId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(voucher),
    })
  );
};

export const updateStatusVoucher = (voucherId, voucher) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/voucher/update/status/${voucherId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(voucher),
    })
  );
};

export const deleteVoucher = (voucherId, voucher) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/voucher/${voucherId}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(voucher),
    })
  );
};

import React, { useState, useEffect } from "react";
import { listAdminUsers } from "../../actions/user";
import SelectSearch from "react-select-search";
import { Button } from "shards-react";

function OwnerFilter({ value, onOwnerChange }) {
  const [adminUsers, setAdminUsers] = useState([]);
  const [owner, setOwner] = useState(value);

  useEffect(() => {
    listAdminUsers().then((res) => {
      setAdminUsers(res);
    });
  }, []);

  function reset() {
    setOwner(null);
    onOwnerChange("");
  }

  function onChange(value) {
    onOwnerChange(value);
    setOwner(value);
  }

  return (
    <div className="d-flex owner-filter">
      <div className="owner-filter">
        <SelectSearch
          options={adminUsers.map((user) => {
            return { name: user.email, value: user._id };
          })}
          search={true}
          value={owner}
          onChange={onChange}
          placeholder="Selecione"
        />
      </div>
      <Button
        style={{
          borderTopLeftRadius: "0px",
          borderBottomLeftRadius: "0px",
        }}
        theme="white"
        className="pl-2 pr-2 pt-1 pb-1"
        onClick={reset}
      >
        <span
          className="material-icons"
          style={{ fontSize: "15px", color: "red" }}
        >
          delete
        </span>
      </Button>
    </div>
  );
}

OwnerFilter.propTypes = {};

export default OwnerFilter;

import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Button,
  CardFooter,
  ButtonGroup,
} from "shards-react";

import RenderItemPicture from "./RenderItemPicture";
import RenderItemText from "../Empreiteiro/RenderItemText";
import Page from "./Page";
import { download } from "../../../utils/util";
import { BUCKET_URL } from "../../../config";
import IfAllowed from "../../common/IfAllowed";

import {
  generatePDF,
  updatePDFContractorSettings,
  updatePDFWoodWorkerSettings,
} from "../../../actions/projects";

import "react-toastify/dist/ReactToastify.min.css";
import Viewer from "./Viewer";
import FinalProject from "../finalProject";
import ChecklistSidebar from "../ChecklistSidebar";

const types = {
  project: "Lista Final",
  preview: "Proposta Inicial",
  firstReview: "1º Revisão",
  secondReview: "2º Revisão",
};

const PDFPages = ({
  projectInfo,
  onUpdate,
  sendLightbox,
  onProjectInfoChanged,
  user,
  email,
  token,
}) => {
  let { render, finalProject } = projectInfo;
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);
  const [link, setLink] = useState("");
  const [showChecklist, setShowChecklist] = useState(false);
  const { checkListBook } = projectInfo;

  if (!render) {
    return null;
  }

  function createPDF() {
    setIsGeneratingPDF(true);
    generatePDF(projectInfo.id)
      .then(showFile)
      .then(() => {
        setIsGeneratingPDF(false);
        onUpdate();
      })
      .catch(() => {
        setIsGeneratingPDF(false);
      });
  }

  function showFile({ link }) {
    setLink(link);
  }

  function onItemPDFSave(id, textItems, customTextItems, type) {
    switch (type) {
      case "contractor": {
        return updatePDFContractorSettings(id, textItems, customTextItems);
      }
      case "woodWorker": {
        return updatePDFWoodWorkerSettings(id, textItems, customTextItems);
      }
      default:
        break;
    }
  }

  return (
    <>
      <Card small className="user-activity mb-4">
        <CardHeader className="border-bottom">
          <h6 className="m-0">PDF do projeto</h6>
          <div className="block-handle" />
        </CardHeader>
        <CardBody className="p-0">
          <IfAllowed resource="admin-ladecora:project:actions:render-config">
            <FinalProject
              project={projectInfo}
              finalProject={finalProject}
              onUpdate={onUpdate}
              sendLightbox={sendLightbox}
              lastVersion={finalProject.lastVersion}
              setLastVersion={(value) =>
                onProjectInfoChanged({
                  ...projectInfo,
                  lastVersion: value,
                })
              }
              projectId={projectInfo.id}
              logDates={projectInfo.logDates}
              setFinalProject={(value) =>
                onProjectInfoChanged({
                  ...projectInfo,
                  finalProject: value,
                })
              }
              setProjectInfo={(value) =>
                onProjectInfoChanged({
                  ...projectInfo,
                  ...value,
                })
              }
              projectInfo={projectInfo}
              user={user}
              token={token}
              email={email}
            />
          </IfAllowed>

          {projectInfo ? (
            <>
              {" "}
              <RenderItemPicture
                name="Planta de Layout"
                project={projectInfo}
                value={render.workPlanLayout}
                type="workPlanLayout"
                onUpdate={onUpdate}
                sendLightbox={sendLightbox}
              />
              <RenderItemPicture
                name="Planta de acabamentos"
                project={projectInfo}
                value={render.finishingLayout}
                type="finishingLayout"
                onUpdate={onUpdate}
                sendLightbox={sendLightbox}
              />
              <RenderItemPicture
                name="Distribuição de luminárias"
                project={projectInfo}
                value={render.lights}
                type="lights"
                onUpdate={onUpdate}
                sendLightbox={sendLightbox}
              />
              <RenderItemPicture
                name="Painel de decoração"
                project={projectInfo}
                value={render.decorationPanel}
                type="decorationPanel"
                onUpdate={onUpdate}
                sendLightbox={sendLightbox}
              />
              <RenderItemText
                name="Considerações"
                project={projectInfo}
                onUpdate={onUpdate}
                type="contractorSettings"
                onSave={onItemPDFSave}
                allowEmpty={false}
                value={{
                  contractorSettings: render.contractorSettings,
                  customContractorSettings: render.customContractorSettings,
                }}
              />
              <Page
                page={projectInfo.render.woodWorker}
                type="woodWorker"
                name="Configurações para marceneiro"
                projectId={projectInfo.id}
                onUpdate={onUpdate}
                onSave={onItemPDFSave}
              />
              <RenderItemPicture
                name="Detalhamentos"
                project={projectInfo}
                value={render.details}
                type="details"
                onUpdate={onUpdate}
                sendLightbox={sendLightbox}
              />
            </>
          ) : null}
        </CardBody>

        <CardFooter className="border-top d-flex flex-column align-items-center justify-content-center">
          {projectInfo.finalProject.approvedProject ? (
            <div className="d-flex mb-2 flex-row align-items-center justify-content-center">
              <span style={{ color: "orange" }} className="material-icons">
                info
              </span>
              <div>
                Serão utilizados os itens da{" "}
                <span style={{ color: "green" }}>
                  {projectInfo.furnitures.length
                    ? '"Lista Final"'
                    : types[projectInfo.finalProject.approvedProject]}
                </span>{" "}
                para geração do PDF.
              </div>
            </div>
          ) : null}
          <ButtonGroup className="mb-4">
            <IfAllowed resource="admin-ladecora:project" perform="update:any">
              <Button
                disabled={isGeneratingPDF}
                size="sm"
                theme="primary"
                // className="mr-4"
                onClick={createPDF}
              >
                {isGeneratingPDF ? "Gerando..." : "Gerar PDF"}
              </Button>
            </IfAllowed>
            <Button
              theme="accent"
              disabled={!finalProject.lastVersion.pdf}
              outline
              // className="mr-4"
              onClick={() => {
                setLink(
                  `${BUCKET_URL}/projects/${projectInfo.id}/lastVersion/${finalProject.lastVersion.pdf}`
                );
              }}
            >
              <i className="material-icons">visibility</i>
            </Button>
            <Button
              theme="accent"
              disabled={!finalProject.lastVersion.pdf}
              outline
              onClick={() => {
                download(
                  `${BUCKET_URL}/projects/${projectInfo.id}/lastVersion/${finalProject.lastVersion.pdf}`,
                  ""
                );
              }}
            >
              <i className="material-icons">download</i>
            </Button>
          </ButtonGroup>
        </CardFooter>
      </Card>
      <Viewer
        link={link}
        toggle={() => {
          if (link) setLink("");
        }}
      />
      {link ? (
        <ChecklistSidebar
          checkList={checkListBook}
          projectId={projectInfo.id}
          show={showChecklist}
          onToggle={(value) => {
            setShowChecklist(value);
          }}
          onChange={(value) => {
            onProjectInfoChanged({ ...projectInfo, checkListBook: value });
          }}
          customCss={{
            sidebar: "project-info-right-sidebar",
            floatButton: "project-checklist-float-button",
          }}
          type="checklistbook"
        />
      ) : null}
    </>
  );
};

export default PDFPages;

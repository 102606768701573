import React, { useContext, useEffect, useState } from "react";

import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  flexRender,
} from "@tanstack/react-table";

import { AsyncTableContext } from "./AsyncTableContext";
import Loader from "react-loader-spinner";

function Table({ columns }) {
  const { data, onFilterUpdate, status, filter } =
    useContext(AsyncTableContext);
  const isLoading = status === "LOADING";

  const table = useReactTable({
    state: {
      pagination: {
        pageIndex: filter?.page.value,
        pageSize: filter?.pageSize.value,
      },
    },
    data: data?.list ? data.list : [],
    columns,
    manualPagination: true,
    pageCount: data?.pages ? data.pages : -1,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: (fn) => {
      const pg = table.getState().pagination;
      let v = fn(pg);

      onFilterUpdate("page", v.pageIndex);
    },

    debugTable: false,
  });

  //On column order change
  useEffect(() => {
    if (table?.options?.state?.columnOrder) {
      const order = table.options.state.columnOrder.length
        ? table.options.state.columnOrder[0]
        : null;
      onFilterUpdate("order", order);
    }
  }, [table?.options?.state?.columnOrder]);

  const nextPage = () => table.nextPage();

  const previousPage = () => table.previousPage();

  return (
    <div className="ReactTable">
      <div className="pagination-top">
        <div className="-pagination">
          <div className="-previous">
            <button
              type="button"
              disabled=""
              className="-btn"
              onClick={previousPage}
            >
              Anterior
            </button>
          </div>
          <div className="-center">
            <span className="-pageInfo">
              Página{" "}
              <div className="-pageJump">
                <input
                  aria-label="jump to page"
                  type="number"
                  onChange={(e) => {
                    table.setPageIndex(e.target.value - 1);
                  }}
                  value={filter?.page.value ? filter.page.value + 1 : 1}
                />
              </div>{" "}
              de{" "}
              <span className="-totalPages">
                {data?.pages ? data?.pages : 1}
              </span>
            </span>
          </div>
          <div className="-next">
            <button type="button" className="-btn" onClick={nextPage}>
              Próxima
            </button>
          </div>
        </div>
      </div>

      <div
        className="rt-table"
        style={{
          backgroundColor: isLoading ? "lightgrey" : "",
          opacity: isLoading ? "0.6" : 1,
        }}
      >
        <div className="rt-thead">
          <div>Total: {data?.total}</div>
          {table.getHeaderGroups().map((headerGroup) => (
            <div className="rt-tr" key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                let s = {};
                const cSize = header.getSize();
                if (cSize !== 150) {
                  s = { maxWidth: header.getSize() };
                }
                return (
                  <button
                    onClick={() => {
                      if (
                        filter?.order?.value &&
                        filter?.order?.value.id === header.id
                      ) {
                        onFilterUpdate("order", {
                          id: header.id,
                          desc: !filter.order.value.desc,
                        });
                      } else {
                        onFilterUpdate("order", { id: header.id, desc: true });
                      }
                    }}
                    className={`rt-th transparent -cursor-pointer ${
                      filter?.order?.value?.id === header.id
                        ? filter?.order?.value.desc
                          ? "-sort-desc"
                          : "-sort-asc"
                        : ""
                    }`}
                    key={header.id}
                    colSpan={header.colSpan}
                    style={s}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: ` ${
                            header.column.getCanSort()
                              ? "cursor-pointer select-none"
                              : ""
                          }`,
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                    )}
                  </button>
                );
              })}
            </div>
          ))}
        </div>
        <div
          className="rt-tbody"
          style={{ minHeight: table.getRowModel().rows.length ? "" : "300px" }}
        >
          {isLoading ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                position: "absolute",
                gridArea: "1 / 1",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                opacity: 0.7,
                padding: "20px",
                borderRadius: "8px",
              }}
            >
              <Loader width="60px" height="60px" type="Rings" color="black" />
            </div>
          ) : null}
          {table.getRowModel().rows.length ? (
            table.getRowModel().rows.map((row) => {
              return (
                <div className="rt-tr" key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    let s = {};
                    const cSize = cell.column.getSize();
                    if (cSize !== 150) {
                      s = { maxWidth: cell.column.getSize() };
                    }
                    return (
                      <div
                        className="rt-td text-center"
                        key={cell.id}
                        style={s}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            })
          ) : (
            <div className="text-center mt-5">Nenhum item encontrado</div>
          )}
        </div>
      </div>

      <div className="pagination-bottom">
        <div className="-pagination">
          <div className="-previous">
            <button
              type="button"
              disabled=""
              className="-btn"
              onClick={previousPage}
            >
              Anterior
            </button>
          </div>
          <div className="-center">
            <span className="-pageInfo">
              Página{" "}
              <div className="-pageJump">
                <input
                  aria-label="jump to page"
                  type="number"
                  onChange={(e) => {
                    table.setPageIndex(e.target.value ? e.target.value - 1 : 1);
                  }}
                  value={filter?.page.value ? filter.page.value + 1 : 1}
                />
              </div>{" "}
              de{" "}
              <span className="-totalPages">
                {data?.pages ? data?.pages : 1}
              </span>
            </span>
          </div>
          <div className="-next">
            <button type="button" className="-btn" onClick={nextPage}>
              Próxima
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

Table.propTypes = {};

export default Table;

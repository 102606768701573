import { API } from "../config";
import { getCookie } from "../actions/auth";
import { requestWrapper } from "../utils/api";

export const listAllCompanies = () => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/company`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const getCompany = (key) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/company/${key}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const uploadPicture = (file, id, name, attr) => {
  const token = getCookie("token");
  const formData = new FormData();
  if (file) {
    formData.append("file", file);
  } else {
    return;
  }

  return requestWrapper(
    fetch(`${API}/company/upload?label=${id}&name=${name}&attr=${attr}`, {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const uploadProjectTemplatePicture = (
  file,
  id,
  name,
  attr,
  projectTemplateId
) => {
  const token = getCookie("token");
  const formData = new FormData();
  if (file) {
    formData.append("file", file);
  } else {
    return;
  }

  return requestWrapper(
    fetch(
      `${API}/company/projectTemplate/upload/${projectTemplateId}?label=${id}&name=${name}&attr=${attr}`,
      {
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "x-access-token": token,
        },
      }
    )
  );
};

export const updateCompany = (key, data) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/company/${key}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(data),
    })
  );
};

export const createCompany = (data) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/company`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(data),
    })
  );
};

export const listProjectTemplatesFromCompany = (company) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/company/${company}/project_templates`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const getProjectTemplateById = (company, projectTemplateId) => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(`${API}/company/${company}/project_template/${projectTemplateId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const uploadPictureV2 = (file, id, name, attr) => {
  const token = getCookie("token");
  const formData = new FormData();
  if (file) {
    formData.append("file", file);
  } else {
    return;
  }

  return requestWrapper(
    fetch(`${API}/v2/company/upload?label=${id}&name=${name}&attr=${attr}`, {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const generateToken = (companyId) => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(`${API}/company/${companyId}/generate_token`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Row,
  Card,
  Col,
  CardBody,
  Button,
  ButtonGroup,
} from "shards-react";
import { MetadataContext } from "../components/metadata/MetadataContext";
import PageTitle from "../components/common/PageTitle";
import { listUsers, deleteUser } from "../actions/user";
import Moment from "react-moment";
import { toast } from "react-toastify";
import ConfirmationButton from "../components/common/ConfirmationButton";
import IfAllowed from "../components/common/IfAllowed";
import { useHistory } from "react-router-dom";
import { CSVLink } from "react-csv";

import FilteredTable from "../components/FilteredTable/index";

const UsersOld = () => {
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const { scopedByCompany, metadata } = useContext(MetadataContext);
  const listAllUsersScopedByCompany = scopedByCompany(listUsers);

  const refreshTableData = () => {
    setIsLoading(true);
    listAllUsersScopedByCompany()
      .then((data) => {
        setIsLoading(false);
        data = data.map((user) => {
          user.username = user.profile
            ? `${user.profile.firstName} ${user.profile.lastName}`
            : "";
          return user;
        });
        if (data.length) {
          setTotal(data.length);
          setUsers(data.reverse());
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    refreshTableData();
  }, []);

  const userName = (row) => {
    if (row.original.profile) {
      return row.original.username;
    } else {
      return "Não encontrado";
    }
  };

  const handleItemEdit = (row) => {
    history.push(`/user?id=${row.original._id}`);
  };

  const handleItemDelete = (row) => {
    deleteUser(row.original._id).then((result) => {
      refreshTableData();
      toast.success(result.msg, { autoClose: 5000 });
      toast.error(result.err, { autoClose: 5000 });
    });
  };

  const tableColumns = [
    {
      Header: "Usuário",
      accessor: "user",
      className: "text-center",
      Cell: (row) => <span>{userName(row)}</span>,
    },
    {
      Header: "E-mail",
      accessor: "email",
      className: "text-center",
      Cell: (row) => <span>{row.original.email}</span>,
    },
    {
      Header: "Contexto",
      accessor: "company",
      maxWidth: 80,
      className: "text-center",
      Cell: (row) =>
        row.original.company
          ? metadata.companies.find(
              (company) => company.label === row.original.company
            ).name
          : "Nenhum",
    },
    {
      Header: "Tipo",
      accessor: "admin",
      maxWidth: 60,
      className: "text-center",
      Cell: (row) => {
        if (row.original.role) {
          return (
            <span>
              {row.original.role === "white_label" ? "White Label" : "Admin"}
            </span>
          );
        }
        return <span>Usuário</span>;
      },
    },
    {
      Header: "Criado",
      accessor: "date",
      maxWidth: 130,
      className: "text-center",
      Cell: (row) => (
        <span>
          <Moment format="DD/MM/YYYY HH:mm">{row.original.createdAt}</Moment>
        </span>
      ),
    },
    {
      Header: "Projetos",
      maxWidth: 80,
      className: "text-center",
      Cell: (row) => (row.original._projects ? row.original._projects : 0),
    },
    {
      Header: "AÇÕES",
      accessor: "actions",
      maxWidth: 150,
      sortable: false,
      Cell: (row) => (
        <ButtonGroup size="sm" className="mx-auto">
          <Button
            title="Editar"
            theme="white"
            onClick={() => handleItemEdit(row)}
          >
            <i className="material-icons">&#xE254;</i>
          </Button>
          <IfAllowed resource="admin-ladecora:user:delete-user">
            <ConfirmationButton
              title="Remover"
              theme="white"
              onClick={() => handleItemDelete(row)}
            >
              <i className="material-icons">&#xE872;</i>
            </ConfirmationButton>
          </IfAllowed>
        </ButtonGroup>
      ),
    },
  ];

  const newUser = () => {
    history.push("/user");
  };

  return (
    <Container fluid className="main-content-container px-4">
      <Row className="page-header py-4">
        <Col md="6">
          <PageTitle
            title="Usuários"
            subtitle="Visão Geral"
            className="text-sm-left mb-3"
          />
        </Col>
        <Col md="5" className="col d-flex align-items-center" />
      </Row>
      <Row className="mb-3">
        <Col md="12">
          <IfAllowed resource="admin-ladecora:user:edit-user">
            <Button className="mb-2" onClick={newUser} id="users-button-add">
              Adicionar usuário
            </Button>
          </IfAllowed>
          <CSVLink
            data={users.map((user) => {
              return {
                Email: user.email,
                "First Name":
                  user.profile && user.profile.firstName
                    ? user.profile.firstName
                    : null,
                "Last Name":
                  user.profile && user.profile.lastName
                    ? user.profile.lastName
                    : null,
                Telefone:
                  user.profile && user.profile.phone ? user.profile.phone : "",
                Contexto: user.company,
                Criado: user.createdAt,
              };
            })}
            filename={"usuarios.csv"}
          >
            <Button theme="accent" className="btn-outline-primary float-right">
              Exportar CSV
            </Button>
          </CSVLink>
        </Col>
      </Row>
      <Card>
        <CardBody>
          Total: {total}
          <FilteredTable
            isLoading={isLoading}
            list={users}
            columns={tableColumns}
            config={{
              pageSizeOptions: [5, 10, 15, 20, 30],
              searchableColumns: ["email", "username", "company", "admin"],
              pageSize: 10,
            }}
          />
        </CardBody>
      </Card>
    </Container>
  );
};
export default UsersOld;

import React, { useState } from "react";

import RenderModal from "./RenderModal";

import { updatePDFTextItems } from "../../../actions/projects";

const RenderItemText = ({ name, value, project, onUpdate }) => {
  const [showModal, setShowModal] = useState(false);

  const isValid =
    value &&
    ((value.contractorSettings && value.contractorSettings.length) ||
      (value.customContractorSettings &&
        value.customContractorSettings.length));

  function onSave(textItems, customTextItems) {
    return updatePDFTextItems(project.id, textItems, customTextItems).then(
      onUpdate
    );
  }

  return (
    <>
      <div className="user-activity__item pr-3 py-3">
        <div className="user-activity__item__icon">
          {isValid ? (
            <i className="material-icons" style={{ color: "#17c67185" }}>
              &#xE5CA;
            </i>
          ) : (
            <i className="material-icons" style={{ color: "#c41a3c91" }}>
              &#xE5CD;
            </i>
          )}
        </div>
        <div
          className="user-activity__item__content"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <span className="text-light" />
          <p>{name}</p>
        </div>
        <div className="user-activity__item__action ml-auto">
          <button
            className="ml-auto btn btn-sm btn-white"
            onClick={() => setShowModal(true)}
          >
            Detalhes
          </button>
        </div>
      </div>
      {showModal && (
        <RenderModal
          show={showModal}
          name={name}
          toggle={() => setShowModal(!showModal)}
          onSave={onSave}
          value={value}
        />
      )}
    </>
  );
};

export default RenderItemText;

import React from "react";
import { Card } from "shards-react";

function FurniturePicture({ mobi }) {
  return (
    <Card
      className="w-100 shadow-lg card-produt-img"
      style={{ position: "relative" }}
    >
      <img
        className="m-3 shadow-sm img-fluid"
        src={`${mobi.refimage}`}
        alt=""
        style={{
          borderRadius: "12px",
          aspectRatio: "1/1",
          objectFit: "cover",
        }}
      />
      {mobi.likes > 0 ? (
        <div
          style={{
            position: "absolute",
            top: "1px",
            left: "4px",
            backgroundColor: "white",
            textAlign: "center",
            borderRadius: "2rem",
            fontSize: "15px",
            padding: "4px",
            boxShadow: "0px 0px 8px black",
          }}
        >
          <i className="material-icons mr-1" style={{ color: "red" }}>
            {mobi.liked ? "favorite" : "favorite_border"}
          </i>
          {mobi.likes}
        </div>
      ) : null}
      {!mobi.likes && mobi.liked ? (
        <div
          style={{
            position: "absolute",
            top: "1px",
            left: "4px",
            backgroundColor: "white",
            textAlign: "center",
            borderRadius: "60%",
            fontSize: "15px",
            padding: "3px 6px 3px 6px",
            boxShadow: "0px 0px 8px black",
          }}
        >
          <i className="material-icons" style={{ color: "red" }}>
            favorite
          </i>
        </div>
      ) : null}

      {mobi.frequency_used > 0 && (
        <div
          style={{
            position: "absolute",
            top: "1px",
            right: "16px",
            backgroundColor: "white",
            textAlign: "center",
            borderRadius: "2rem",
            fontSize: "15px",
            padding: "4px",
            boxShadow: "0px 0px 8px black",
            lineHeight: "1.5",
            paddingLeft: "0.5rem",
            paddingRight: "0.5rem",
          }}
        >
          <i className="material-icons mr-1" style={{ color: "#78e08f" }}>
            arrow_upward
          </i>
          {mobi.frequency_used}
        </div>
      )}
    </Card>
  );
}

FurniturePicture.propTypes = {};

export default FurniturePicture;

/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { useEffect, useState } from "react";
import { Container, Row, Col, FormInput, FormSelect } from "shards-react";
import ConfirmationButton from "../common/ConfirmationButton";
import { listProjectTemplatesFromCompany } from "../../actions/company";

const AddProduct = ({ company, onAddProject, isLoading }) => {
  const [questions, setQuestions] = useState([]);
  const [qtyProjectAdd, setQtyProjectAdd] = useState(1);
  const [projectSelected, setProjectSelected] = useState({
    name: "",
    questionTemplateHash: "",
    qty: qtyProjectAdd,
  });

  useEffect(() => {
    getQuestions();
  }, []);

  const getQuestions = () => {
    listProjectTemplatesFromCompany(company).then((data) => {
      setQuestions(data);
    });
  };

  const handleChangeQtyAdd = (e) => {
    setQtyProjectAdd(e.target.value);

    setProjectSelected({ ...projectSelected, qty: e.target.value });
  };

  const handleSelect = (e) => {
    setProjectSelected({
      name: e.target[e.target.selectedIndex].getAttribute("data-name"),
      questionTemplateHash: e.target.value,
      qty: qtyProjectAdd,
    });
  };

  const handleClickAdd = () => {
    if (qtyProjectAdd < 1) {
      //TODO
      // props.toast.error("A quantidade deve ser maior ou igual a 1.", {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
    } else {
      if (
        projectSelected &&
        projectSelected.name &&
        projectSelected.name !== ""
      ) {
        onAddProject(projectSelected);
      }
    }
  };

  return (
    <Container>
      <Row className={" mb-2 mt-2 p-2"}>
        <Col className={"mb-4"} xs="6">
          <span>Projetos</span>
          {
            <FormSelect
              size="sm"
              value={projectSelected.questionTemplateHash}
              onChange={handleSelect}
            >
              <option value="" />
              {questions.length > 0 &&
                questions.map((q, idx) => (
                  <option
                    key={idx}
                    data-name={q.name}
                    value={q.hash}
                    name={q.name}
                  >
                    {q.name}
                  </option>
                ))}
            </FormSelect>
          }
        </Col>
        <Col className={"mb-4"} xs="2">
          <span>Quantidade</span>
          <FormInput
            type="number"
            placeholder="1"
            autoComplete="number"
            value={qtyProjectAdd}
            onChange={handleChangeQtyAdd}
          />
        </Col>
        <Col className={"mb-4 mt-4"} xs="4">
          <ConfirmationButton
            theme="accent"
            onClick={handleClickAdd}
            disabled={isLoading || !projectSelected.questionTemplateHash}
          >
            Adicionar Projeto(s)
          </ConfirmationButton>
        </Col>
      </Row>
    </Container>
  );
};

export default AddProduct;

import { API } from "../config";
import { getCookie } from "../actions/auth";

import { requestWrapper } from "../utils/api";

export const getGifts = (company) => {
  const token = getCookie("token");

  return requestWrapper(
    fetch(`${API}/gift/${company}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const getGiftById = (company, id) => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(`${API}/gift/${company}/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

export const createGift = (company, data) => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(`${API}/gift/${company}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(data),
    })
  );
};

export const updateGift = (company, label, data) => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(`${API}/gift/${company}/${label}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
      body: JSON.stringify(data),
    })
  );
};

export const deleteGift = (id) => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(`${API}/gift/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

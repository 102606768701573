import React, { useState } from "react";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import { Row } from "shards-react";
import Loader from "react-loader-spinner";
import { updateLearningEnvironmentAndInspirations } from "../../actions/projects";

import { toast } from "react-toastify";
import { uploadFile } from "../../services/file";

const DropzoneCustomer = ({
  projectId,
  step,
  stepName,
  onLearningEnvironmentChanged,
}) => {
  const [values, setValues] = useState({
    error: false,
    message: "",
    loading: false,
  });

  const handleDrop = (files) => {
    setValues({ ...values, loading: true });
    Promise.all(
      files.map((file) => {
        return uploadFile({
          path: `projects/${projectId}/customer`,
          file,
        })
          .then((res) => {
            let fileLink;
            if (res.file) {
              fileLink = {
                name: file.name,
                source: res.file,
                size: file.size,
              };
            } else {
              fileLink = {
                name: file.name,
                source: res.fullSize,
                thumbnail: res.thumbnail,
                size: file.size,
              };
            }
            step.files.push({
              ...fileLink,
              source: fileLink.source.match(/([^/]*)$/)[0],
              thumbnail: fileLink.thumbnail
                ? fileLink.thumbnail.match(/([^/]*)$/)[0]
                : null,
            });

            onLearningEnvironmentChanged({ ...step, files: [...step.files] });
            return file;
          })
          .catch((err) => {
            console.log(err);
            return null;
          });
      })
    )
      .then(() => {
        updateLearningEnvironmentAndInspirations(
          projectId,
          stepName,
          step
        ).then(() => {
          toast.success("Arquivos enviados com sucesso.", {
            autoClose: 5000,
          });
        });
        setValues({
          ...values,
          message: "Todos os arquivos foram enviados",
          loading: false,
        });
        setTimeout(() => {
          setValues((values) => {
            return { ...values, message: "" };
          });
        }, 1000);
      })
      .catch(() => {
        setValues({ ...values, loading: false });
      });
  };

  const showError = () =>
    error ? <div className="alert alert-danger">{error}</div> : "";
  const showMessage = () =>
    message ? <div className="alert alert-info">{message}</div> : "";
  const { error, message, loading } = values;

  return (
    <Row noGutters>
      <div className="file-manager-cards__dropzone w-100 p-2">
        {showError()}
        {showMessage()}
        <Dropzone onDrop={handleDrop}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div
                {...getRootProps()}
                className={classNames("dropzone", {
                  "dropzone--isActive": isDragActive,
                })}
              >
                <input {...getInputProps()} />
                <div className="m-0">
                  {!loading &&
                    "Tente soltar alguns arquivos aqui ou clique para selecionar os arquivos a serem enviados."}
                  {loading && <Loader type="Rings" color="#0a775a" />}
                </div>
              </div>
            );
          }}
        </Dropzone>
      </div>
    </Row>
  );
};
export default DropzoneCustomer;

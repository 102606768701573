import React, { useState } from "react";
import {
  ListGroup,
  ListGroupItem,
  FormInput,
  Button,
  Row,
  Col,
} from "shards-react";
import SortableTree, { removeNodeAtPath } from "react-sortable-tree";
import "react-sortable-tree/style.css"; // This only needs to be imported once in your app

const Tree = ({ treeData, setTreeData, completeFormInfo }) => {
  const [searchString, setSearchString] = useState("");
  const [searchFoundCount, setSearchFoundCount] = useState(null);
  const [searchFocusIndex, setSearchFocusIndex] = useState(0);

  const nodeInfo = (row) => completeFormInfo(row);

  const removeChild = (row) => {
    let path = row.path;
    setTreeData(
      removeNodeAtPath({
        treeData: treeData,
        path,
        getNodeKey,
      })
    );
  };

  const selectPrevMatch = () =>
    setSearchFocusIndex(
      searchFocusIndex !== null
        ? (searchFoundCount + searchFocusIndex - 1) % searchFoundCount
        : searchFoundCount - 1
    );

  const selectNextMatch = () =>
    setSearchFocusIndex(
      searchFocusIndex !== null ? (searchFocusIndex + 1) % searchFoundCount : 0
    );

  const customSearchMethod = ({ node, searchQuery }) =>
    searchQuery &&
    ((node.title &&
      node.title.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1) ||
      (node.type &&
        node.type.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1));

  const getNodeKey = ({ treeIndex }) => treeIndex;
  return (
    <React.Fragment>
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row form>
            <Col md="8" className="form-group">
              <FormInput
                id="find-box"
                type="text"
                placeholder="Buscar..."
                style={{ fontSize: "1rem" }}
                value={searchString}
                onChange={(event) => setSearchString(event.target.value)}
              />
            </Col>
            <Col md="1" className="form-group">
              <Button
                type="button"
                disabled={!searchFoundCount}
                onClick={selectPrevMatch}
              >
                &lt;
              </Button>
            </Col>
            <Col md="1" className="form-group">
              <Button
                type="submit"
                disabled={!searchFoundCount}
                onClick={selectNextMatch}
              >
                &gt;
              </Button>
            </Col>
            <Col md="2" className="form-group">
              <span>
                &nbsp;
                {searchFoundCount > 0 ? searchFocusIndex + 1 : 0}
                &nbsp;/&nbsp;
                {searchFoundCount || 0}
              </span>
            </Col>
          </Row>

          <div style={{ height: 500 }}>
            <SortableTree
              treeData={treeData}
              onChange={(treeData) => {
                setTreeData(treeData);
              }}
              searchMethod={customSearchMethod}
              searchQuery={searchString}
              searchFocusOffset={searchFocusIndex}
              searchFinishCallback={(matches) => {
                setSearchFoundCount(matches.length);
                setSearchFocusIndex(
                  matches.length > 0 ? searchFocusIndex % matches.length : 0
                );
              }}
              generateNodeProps={(row) => {
                return {
                  title: row.node.title,
                  type: (
                    <div style={{ lineHeight: "2em" }}>{row.node.type}</div>
                  ),
                  buttons: [
                    <span
                      key={0}
                      className="badge badge-outline-salmon mr-1 mt-1"
                    >
                      {row.treeIndex}
                    </span>,
                    <Button
                      key={1}
                      type="button"
                      className="btn mr-1"
                      style={{
                        verticalAlign: "middle",
                      }}
                      onClick={() => removeChild(row)}
                    >
                      -
                    </Button>,
                    <Button
                      key={2}
                      type="button"
                      className="btn mr-1"
                      style={{
                        verticalAlign: "middle",
                      }}
                      onClick={() => nodeInfo(row)}
                    >
                      ℹ
                    </Button>,
                  ],
                };
              }}
            />
          </div>
        </ListGroupItem>
      </ListGroup>
    </React.Fragment>
  );
};

export default Tree;

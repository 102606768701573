import React from "react";
import { toast } from "react-toastify";
import { updateLogDates, updateComments } from "../../actions/projects";

import ConfirmationButton from "../common/ConfirmationButton";

const QualityButton = ({
  id,
  updateProjectStatus,
  setProjectInfo,
  status,
  message,
  buttonText,
  style,
  disabled,
}) => {
  return (
    <ConfirmationButton
      className="text-center m-0 m-2"
      style={style}
      id="quality-button-send"
      size="sm"
      theme="info"
      disabled={disabled}
      onClick={() => {
        updateLogDates(id, status);
        updateProjectStatus(id, status);
        setProjectInfo({ status: status });
        const date = new Date().toISOString();
        const user = JSON.parse(localStorage.getItem("user"));
        updateComments(id, {
          comment: buttonText,
          date: date,
          user: user._id,
        });
        toast.success(message, {
          autoClose: 5000,
        });
      }}
    >
      {buttonText}
    </ConfirmationButton>
  );
};

export default QualityButton;

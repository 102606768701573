import React, { useState, useCallback, useEffect } from "react";
import { listAllProducts } from "../../actions/mobi";
import { INITIAL_FILTER } from "../../data/image-editor.ts";
import { sortByName } from "../../utils/sorting";

import { SketchPicker } from "react-color"; // eslint-disable-line
import Downshift from "downshift";
import { debounce } from "../../utils/throttle";

const Item = ({
  projectId,
  onAddLine,
  imageLabel,
  onDelete,
  onChange,
  review,
  lightsTypology,
  canvas,
}) => {
  const [size, setSize] = useState(
    !Object.keys(imageLabel.prevCanvasObjRef).length == 0 ? "" : "medium"
  );
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [mobis, setMobis] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState(imageLabel.prevInputValue);
  const [canvasObjRef, setCanvasObjRef] = useState(imageLabel.prevCanvasObjRef);

  useEffect(() => {
    canvas.on("object:scaled", (options) => {
      if (!Object.keys(canvasObjRef).length == 0) {
        if (options.target.__fe_id == canvasObjRef.__fe_id) {
          setSize("");
        }
      }
    });
  }, [canvas?._objects, canvasObjRef]);

  const searchMobis = useCallback(
    debounce((text) => {
      setIsLoading(true);
      const filter = JSON.parse(JSON.stringify(INITIAL_FILTER));
      filter.project = projectId;
      filter.search = text;
      filter.type = review;

      lightsTypology.then((id) => {
        filter.typology = id;
        listAllProducts(filter)
          .then((res) => {
            setIsLoading(false);
            res = [
              ...res,
              {
                name: "Acabamento existente",
                _id: `63a58d6c40d${Date.now()}`,
              },
              {
                name: "Iluminação existente",
                _id: `73a58d6c45d${Date.now()}`,
              },
            ];
            setMobis(sortByName(res));
          })
          .catch(() => {
            setIsLoading(false);
          });
      });
    }, 800),
    []
  );

  const onAddLineClick = () => {
    const tempCanvasObjRef = onAddLine(imageLabel.colorHex, size);
    if (tempCanvasObjRef) {
      setCanvasObjRef(tempCanvasObjRef);
      onChange({ id: imageLabel.id, idObj: tempCanvasObjRef.__fe_id });
    }
  };

  const onDeleteClick = () => {
    onDelete(imageLabel);
  };

  const onMobiChange = (mobi) => {
    imageLabel.mobi = mobi;
    setInputValue(mobi.name);
    onChange({ ...imageLabel, label: mobi.name });
  };

  const onColorChange = (c) => {
    imageLabel.colorHex = c.hex;
    canvas._objects.map((item) => {
      if (item.__fe_id == canvasObjRef?.__fe_id) {
        canvas.setActiveObject(item);
      }
    });

    canvas.getActiveObject()?.set("fill", "");
    canvasObjRef.fill = c.hex;
    canvasObjRef.stroke = c.hex;
    onChange({ ...imageLabel, colorHex: c.hex });
    canvas.renderAll();
  };

  const onSizeChange = (ev) => {
    let size = ev.target.value;
    if (!Object.keys(canvasObjRef).length == 0) {
      canvas._objects.map((item) => {
        if (item.__fe_id == canvasObjRef.__fe_id) {
          canvas.setActiveObject(item);
        }
      });

      canvas.getActiveObject()?.set("height", 0);
      canvas.getActiveObject()?.set("width", 0);

      if (size === "small") {
        canvasObjRef.height = 12;
        canvasObjRef.width = 12;
      }
      if (size === "medium") {
        canvasObjRef.height = 24;
        canvasObjRef.width = 24;
      }
      if (size === "large") {
        canvasObjRef.height = 48;
        canvasObjRef.width = 48;
      }
      canvasObjRef.scale(1);
      canvas.renderAll();
    }
    setSize(size);
  };

  const handleChangeInput = (e) => {
    setInputValue(e);
  };

  return (
    <div className="d-flex align-items-end">
      <button
        disabled={Object.keys(imageLabel.mobi).length == 0}
        onClick={onAddLineClick}
        className="clickable mr-1"
      >
        <i className="material-icons">add_circle_outline</i>
      </button>

      <button
        onClick={() => setShowColorPicker(!showColorPicker)}
        className="clickable mr-1 ml-1"
        style={{
          border: "none",
          outline: "none",
          padding: ".5rem",
          backgroundColor: "white",
          borderRadius: ".2rem",
          boxShadow: "rgba(0,0,0,.2) 2px 2px 8px 0px",
        }}
      >
        <div
          style={{
            width: "10px",
            height: "10px",
            backgroundColor: imageLabel.colorHex,
          }}
        />
      </button>
      <select
        className="mr-1 clickable"
        onChange={onSizeChange}
        value={size}
        style={{ fontSize: "1.1rem" }}
      >
        <option disabled value="">
          {" "}
        </option>
        <option value="small">P</option>
        <option value="medium">M</option>
        <option value="large">G</option>
      </select>
      <Downshift
        onChange={onMobiChange}
        itemToString={(item) => (item ? item.name : "")}
      >
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          isOpen,
          highlightedIndex,
          selectedItem,
          toggleMenu,
        }) => (
          <div>
            <div {...getLabelProps()} className="mb-0 label">
              Escolha o mobi
            </div>
            <input
              {...getInputProps({
                value: inputValue,
                onFocus() {
                  setIsLoading(true);
                  searchMobis("");
                  toggleMenu();
                },
                onChange(e) {
                  searchMobis(e.target.value);
                  handleChangeInput(e.target.value);
                },
              })}
            />
            <ul
              {...getMenuProps()}
              style={{
                marginLeft: "0px",
                paddingLeft: "0px",
                position: "absolute",
                backgroundColor: "white",
                zIndex: 10000,
              }}
              className="clickable"
            >
              {isOpen ? (
                isLoading ? (
                  <div>Carregando...</div>
                ) : mobis.length > 0 ? (
                  mobis.map((item, index) => (
                    <div
                      key={item._id}
                      {...getItemProps({
                        item,
                        index,
                        style: {
                          backgroundColor:
                            highlightedIndex === index ? "gray" : "white",
                          fontWeight: selectedItem === item ? "bold" : "normal",
                        },
                      })}
                    >
                      <div>{item.name}</div>
                      {index !== mobis.length - 1 ? (
                        <hr className="mt-1 mb-1" />
                      ) : null}
                    </div>
                  ))
                ) : (
                  <div
                    style={{
                      backgroundColor: "white",
                      fontWeight: "normal",
                    }}
                  >
                    <div>Nenhum item encontrado.</div>
                  </div>
                )
              ) : null}
            </ul>
          </div>
        )}
      </Downshift>

      <button
        onClick={onDeleteClick}
        className="clickable"
        style={{
          border: "none",
          outline: "none",
          color: "red",
          marginLeft: "auto",
          backgroundColor: "transparent",
        }}
      >
        <i className="fas fa-trash" />
      </button>

      {showColorPicker ? (
        <>
          <button
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100vw",
              height: "100vh",
              backgroundColor: "transparent",
              zIndex: 9,
            }}
            onClick={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();
              setShowColorPicker(false);
              return false;
            }}
          />

          <div
            style={{
              position: "absolute",
              marginTop: "2rem",
              zIndex: 10,
              marginLeft: "-12rem",
              top: "40px",
            }}
          >
            <SketchPicker
              color={imageLabel.colorHex}
              onChangeComplete={onColorChange}
            />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default Item;

import React from "react";

const SetLinkIntoString = ({ string }) => {
  const stringSplited = string?.split(" ");

  const sideStr = (str) => {
    let petternStr = /([()<>""''«»“”‘’]|[^0-9a-zA-Z/\-._:=%?&])/gi;

    if (petternStr.test(str)) {
      return str;
    }
  };

  const constainLink = (elem) => {
    return elem.includes("https:") || elem.includes("http:");
  };

  const isLink = (elem, idx) => {
    let pattern =
      /(?:(?:https?|ftp):\/\/|\b(?:[a-z\d]+\.))(?:(?:[^\s()<>]+|\((?:[^\s()<>]+|(?:\([^\s()<>]+\)))?\))+(?:\((?:[^\s()<>]+|(?:\(?:[^\s()<>]+\)))?\)|[^\s`!()[\]{};:'".,<>?«»“”‘’]))?/gi;
    let result = pattern.test(elem);
    let iStr = sideStr(elem[0]);
    let fStr = sideStr(elem[elem.length - 1]);

    if (result) {
      return (
        <>
          {iStr && iStr}
          <a
            className="ml-1 mr-1"
            href={setHref(elem)}
            target="_blank"
            rel="noreferrer"
            key={idx}
          >
            {setHref(elem)}
          </a>
          {fStr && fStr}
        </>
      );
    } else {
      return ` ${elem} `;
    }
  };

  const setHref = (str) => {
    return str
      .normalize("NFD")
      .replace(/([\u0300-\u036f]|[^0-9a-zA-Z/._:\-=%?&])/gi, "");
  };

  return (
    <div style={{ overflowWrap: "anywhere" }}>
      <p>
        {stringSplited?.map((elem, idxElem) => {
          return constainLink(elem)
            ? elem.includes("\n")
              ? elem.split("\n").map((item, idxItem) => {
                  return isLink(item, idxItem);
                })
              : isLink(elem, idxElem)
            : ` ${elem} `;
        })}
      </p>
    </div>
  );
};

export default SetLinkIntoString;

import React, { useRef, useState } from "react";

import {
  Row,
  Col,
  Button,
  InputGroupAddon,
  FormInput,
  InputGroup,
  Form,
} from "shards-react";

function ActionHeader({ onAdd, isLoading }) {
  const [name, setName] = useState("");
  const nameInputRef = useRef();

  function onAddClick(ev) {
    ev.preventDefault();
    onAdd(name).then(() => {
      setName("");
      nameInputRef.current.focus(true);
    });
    return false;
  }

  function onChange(ev) {
    setName(ev.target.value);
  }
  return (
    <Row>
      <Col>
        <Form onSubmit={onAddClick}>
          <InputGroup className="mt-3">
            <FormInput
              value={name}
              ref={nameInputRef}
              onChange={onChange}
              required
              disabled={isLoading}
              placeholder="Nome da categoria"
            />
            <InputGroupAddon type="append">
              <Button disabled={isLoading} type="submit" theme="success">
                Adicionar
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </Form>
      </Col>
    </Row>
  );
}

ActionHeader.propTypes = {};

export default ActionHeader;

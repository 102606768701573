import React from "react";
import { Row, Col, Container, Card } from "shards-react";

import { useHistory } from "react-router-dom";

import MobiTagsHOC from "../components/MobiCategories/MobiTagsHOC";

const MobiCategories = () => {
  const history = useHistory();

  return (
    <Container fluid className="main-content-container h100 px-4 pb-5">
      <Row className="page-header py-2">
        <Col md="6">
          <button
            className="transparent"
            type="button"
            onClick={() => {
              history.goBack();
            }}
          >
            VOLTAR
          </button>
          <h3>Categorias</h3>
        </Col>
      </Row>

      <Card>
        <MobiTagsHOC />
      </Card>
    </Container>
  );
};

export default MobiCategories;

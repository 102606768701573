import React, { useState, useEffect } from "react";
import { Row, Col, Container, Card } from "shards-react";

import { listAllProducts_deprecated as listAllProducts } from "../actions/mobi";
import { getFurnituresByGroup, updateMobiGroup } from "../actions/mobiGroup";
import "react-toastify/dist/ReactToastify.min.css";
import ActionHeader from "../components/FurnitureGroups/ActionHeader";
import { getQueryStringValue } from "../actions/auth";

import CountChanger from "../components/FilteredTable/CountChanger";

import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import FilteredTable from "../components/FilteredTable/index";
import Filters from "../components/FurnitureGroups/Filters";

const EditFurnitureGroup = () => {
  let mobiGroupId = getQueryStringValue("id");
  const [furnitures, setFurnitures] = useState([]);
  const [furnitureGroup, setFurnitureGroup] = useState({
    furnitures: [],
    name: "",
  });

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const tableColumns = [
    {
      Header: "FOTO",
      accessor: "refimage",
      className: "text-center",
      Cell: (row) => {
        return (
          <div>
            <img height={200} alt="" src={row.original.refimage} />
          </div>
        );
      },
      maxWidth: 200,
    },
    {
      Header: "SKU",
      accessor: "sku",
      className: "text-center",
      maxWidth: 200,
    },

    {
      Header: "NOME",
      accessor: "name",
      className: "text-center",
    },
    {
      Header: "MEDIDAS",
      accessor: "",
      className: "text-center",
      Cell: (row) => {
        return (
          <div>
            {row.original.refx} - {row.original.refy} - {row.original.refz}
          </div>
        );
      },
    },
    {
      Header: "AÇÕES",
      accessor: "selected",
      className: "text-center",
      Cell: (row) => {
        return <CountChanger onChange={onChange} item={row.original} />;
      },
      maxWidth: 200,
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      getFurnituresByGroup(mobiGroupId, { furnitures: true }),
      listAllProducts({
        supplier: "",
        tags: [],
        styles: [],
        colors: [],
        typologies: [],
        type: "project",
        hasFile: false,
        onlyAvailable: "null",
        order: "frequency_used",
        notUse: "true",
        projectTemplateNames: [],
        limit: 28,
        page: 0,
        search: "",
        project: "",
        isLastPage: false,
        hasOtherFiles: false,
        colorTones: [],
        features: [],
        oldTags: {
          styles: [],
          categories: [],
          colors: [],
          typologies: [],
        },
      }),
    ])
      .then((res) => {
        const groupFurnitures = res[0];
        let results = res[1];

        results = results.map((furniture) => {
          return {
            ...furniture,
            count: 0,
          };
        });

        setFurnitureGroup(groupFurnitures);

        results.forEach((furniture) => {
          const wrappedFurniture = groupFurnitures.furnitures.find(
            (projectFurniture) => projectFurniture._id === furniture._id
          );
          if (wrappedFurniture) furniture.count = wrappedFurniture.count;
          return furniture;
        });
        setFurnitures(results);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, []);

  function onChange(furniture) {
    let groupedFurniture = furnitureGroup.furnitures.find(
      (groupFurniture) => groupFurniture._id === furniture._id
    );
    if (!groupedFurniture) {
      furnitureGroup.furnitures.push(furniture);
    } else {
      groupedFurniture.count = furniture.count;
    }
    setFurnitureGroup({ ...furnitureGroup });
  }

  function onSave(groupName) {
    setIsLoading(true);
    return updateMobiGroup({
      ...furnitureGroup,
      name: groupName,
      furnitures: furnitureGroup.furnitures
        .filter((furnitureWrapper) => furnitureWrapper.count > 0)
        .map((furnitureWrapper) => {
          return {
            id: furnitureWrapper._id,
            count: furnitureWrapper.count,
          };
        }),
    })
      .then(() => {
        setIsLoading(false);
        toast.success("Grupo atualizado com sucesso", { autoClose: 5000 });
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Ocorreu um erro, por favor, tente novamente", {
          autoClose: 5000,
        });
        console.log(err);
      });
  }

  return (
    <Container fluid className="main-content-container h100 px-4 pb-5">
      <Row className="page-header py-2">
        <Col md="6">
          <button
            type="button"
            className="transparent"
            onClick={() => {
              history.goBack();
            }}
          >
            VOLTAR
          </button>
          <h3>Alterar grupo</h3>
        </Col>
      </Row>
      <Card>
        <Container>
          <FilteredTable
            list={furnitures}
            columns={tableColumns}
            isLoading={isLoading}
            config={{
              pageSizeOptions: [5, 10, 15, 20, 30],
              searchableColumns: ["name", "sku"],
              pageSize: 10,
            }}
            customFilters={[
              {
                name: "groupName",
                onUpdate: (filterValue, data) => data,
              },
              {
                name: "groupedOnly",
                default: true,
                onUpdate: (filterValue, data) => {
                  return data.filter((furniture) => furniture.count > 0);
                },
              },
            ]}
          >
            <ActionHeader
              name={furnitureGroup.name}
              isLoading={isLoading}
              onSave={onSave}
            />
            <Filters mode="edit" />
          </FilteredTable>
        </Container>
      </Card>
    </Container>
  );
};

export default EditFurnitureGroup;

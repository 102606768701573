import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Card,
  Col,
  CardBody,
  Button,
  ButtonGroup,
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { listAllCompanies } from "../actions/company";
import { useHistory } from "react-router-dom";
import IfAllowed from "../components/common/IfAllowed";

import FilteredTable from "../components/FilteredTable/index";
import Filters from "../components/Partner/Filters";
import Moment from "react-moment";

const Partners = () => {
  const history = useHistory();
  const [companies, setCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const refreshTableData = () => {
    setIsLoading(true);
    listAllCompanies()
      .then((data) => {
        setIsLoading(false);
        let userCompany = JSON.parse(localStorage.getItem("user")).company;
        if (userCompany != "ladecora") {
          data = data.filter((e) => e.label == userCompany);
        }
        setCompanies(data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    refreshTableData();
  }, []);

  const tableColumns = [
    {
      Header: "Identificador",
      accessor: "label",
      className: "text-center",
      Cell: (row) => <span>{row.original.label}</span>,
    },
    {
      Header: "Nome",
      accessor: "name",
      className: "text-center",
      Cell: (row) => <span>{row.original.name}</span>,
    },
    {
      Header: "Ativo",
      accessor: "enabled",
      className: "text-center",
      Cell: (row) => <span>{row.original.enabled ? "Sim" : "Não"}</span>,
    },
    {
      Header: "Criado",
      accessor: "createdAt",
      maxWidth: 130,
      className: "text-center",
      Cell: (row) => {
        return (
          <span className="d-flex flex-column">
            <Moment format="DD/MM/YYYY">{row.original.createdAt}</Moment>
            <Moment format="HH:mm">{row.original.createdAt}</Moment>
          </span>
        );
      },
    },
    {
      Header: "Último Update",
      accessor: "updatedAt",
      maxWidth: 130,
      className: "text-center",
      Cell: (row) => {
        return (
          <span className="d-flex flex-column">
            <Moment format="DD/MM/YYYY">{row.original.updatedAt}</Moment>
            <Moment format="HH:mm">{row.original.updatedAt}</Moment>
          </span>
        );
      },
    },
    {
      Header: "AÇÕES",
      accessor: "actions",
      maxWidth: 150,
      sortable: false,
      Cell: (row) => (
        <ButtonGroup size="sm" className="mx-auto">
          <Button
            title="Editar"
            theme="white"
            onClick={() => handleItemEdit(row.original.label)}
          >
            <i className="material-icons">&#xE254;</i>
          </Button>
        </ButtonGroup>
      ),
    },
  ];

  function handleItemEdit(key) {
    history.push(`/partner/${key}`);
  }

  return (
    <Container fluid className="main-content-container px-4">
      <Row className="page-header py-4">
        <Col md="6">
          <PageTitle
            title="Parceiros"
            subtitle="Visão Geral"
            className="text-sm-left mb-3"
          />
        </Col>
        <Col md="5" className="col d-flex align-items-center" />
      </Row>
      <IfAllowed resource="admin-ladecora:partners:create-partiner">
        <Button className="mb-2" onClick={() => history.push("/partner")}>
          Adicionar parceiro
        </Button>
      </IfAllowed>
      <Card>
        <CardBody>
          <FilteredTable
            isLoading={isLoading}
            list={companies}
            columns={tableColumns}
            customFilters={[
              {
                name: "enabled",
                value: "true",
                onUpdate: (filterValue, data) =>
                  filterValue
                    ? data.filter((furniture) =>
                        filterValue === "true"
                          ? furniture.enabled
                          : !furniture.enabled
                      )
                    : data,
              },
            ]}
            config={{
              pageSizeOptions: [5, 10, 15, 20, 30],
              searchableColumns: ["name", "label"],
              pageSize: 10,
            }}
          >
            <Filters refresh={refreshTableData} isLoading={isLoading} />
          </FilteredTable>
        </CardBody>
      </Card>
    </Container>
  );
};
export default Partners;

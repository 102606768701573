import React, { useMemo, useState, useEffect } from "react";
import SelectSearch from "react-select-search";
import { getMobiFilters } from "../../actions/mobi";
import { getTagsV2 } from "../../actions/mobiTag";
import MobiTagManager from "../mobi/MobiTagManager.js";
import { Button } from "shards-react";
import { Switch, Route, useHistory, useParams } from "react-router-dom";
import TinderMobiTag from "./index";

const Configuration = ({ params }) => {
  const history = useHistory();
  const { id } = useParams();

  const [tags, setTags] = useState({
    typologies: [],
    categories: [],
    subCategories: [],
    features: [],
    isLoading: true,
  });

  const typology = useMemo(() => {
    const t = tags.typologies.find((t) => t._id === params.typology._id);
    if (!t) return params.typology;
    return t;
  }, [params.typology._id, tags.typologies]);

  const category = useMemo(() => {
    const t = tags.categories.find((t) => t._id === params.category._id);
    if (!t) return params.category;
    return t;
  }, [params.category._id, tags.categories]);

  const subCategory = useMemo(() => {
    const t = tags.subCategories.find((t) => t._id === params.subCategory._id);
    if (!t) return params.subCategory;
    return t;
  }, [params.subCategory._id, tags.subCategories]);

  const isNextButtonDisabled = useMemo(() => {
    return id === "value" && !params.value._id && id !== "filter";
  }, [params.value._id, id]);

  useEffect(refreshTags, []);

  useEffect(() => {
    if (params.typology._id && tags.typologies && tags.typologies.length) {
      const typology = tags.typologies.find(
        (t) => t._id === params.typology._id
      );

      setTags({ ...tags, categories: [], subCategories: [], isLoading: true });
      getTagsV2("", typology.category, typology._id).then((res) => {
        setTags({
          ...tags,
          categories: res,
          subCategories: [],
          isLoading: false,
        });
      });
    }
  }, [params.typology._id]);

  useEffect(() => {
    if (params.category._id && tags.categories && tags.categories.length) {
      const category = tags.categories.find(
        (t) => t._id === params.category._id
      );

      setTags({ ...tags, subCategories: [], isLoading: true });
      getTagsV2("", "typology", category._id).then((res) => {
        setTags({
          ...tags,
          subCategories: res,
          isLoading: false,
        });
      });
    }
  }, [params.category._id]);

  useEffect(() => {
    if (
      params.subCategory._id &&
      tags.subCategories &&
      tags.subCategories.length
    ) {
      const subCategory = tags.subCategories.find(
        (t) => t._id === params.subCategory._id
      );

      setTags({ ...tags, features: [], isLoading: true });
      getTagsV2("", "typology", subCategory._id).then((res) => {
        setTags({
          ...tags,
          features: res,
          isLoading: false,
        });
      });
    }
  }, [params.subCategory._id]);

  function refreshTags() {
    const promises = [getMobiFilters()];
    if (params.typology._id) {
      promises.push(getTagsV2("", "typology", params.typology._id));
    }
    if (params.category._id) {
      promises.push(getTagsV2("", "typology", params.category._id));
    }
    if (params.subCategory._id) {
      promises.push(getTagsV2("", "typology", params.subCategory._id));
    }
    Promise.all(promises).then((res) => {
      const [filters, categories, subCategories, features] = res;
      setTags({
        ...tags,
        isLoading: false,
        typologies: filters.typologies,
        categories: categories || [],
        subCategories: subCategories || [],
        features: features || [],
      });
    });
  }

  function onTypologyChange(value) {
    const newParams = {
      typology: "",
      category: "",
      subCategory: "",
      feature: "",
    };

    if (value !== -1 && params.typology._id !== value) {
      newParams.typology = value;
    }

    const queryString = new URLSearchParams(newParams).toString();
    history.push(`/mobi_tag_tinder/filter?${queryString}`);
  }

  function onCategoryChange(value) {
    const newParams = {
      typology: params.typology._id,
      category: "",
      subCategory: "",
      feature: "",
    };
    if (value !== -1 && params.category._id !== value) {
      newParams.category = value;
    }

    const queryString = new URLSearchParams(newParams).toString();
    history.push(`/mobi_tag_tinder/filter?${queryString}`);
  }

  function onSubCategoryChange(value) {
    const newParams = {
      typology: params.typology._id,
      category: params.category._id,
      subCategory: "",
      feature: "",
    };
    if (value !== -1 && params.subCategory._id !== value) {
      newParams.subCategory = value;
    }

    const queryString = new URLSearchParams(newParams).toString();
    history.push(`/mobi_tag_tinder/filter?${queryString}`);
  }

  function onNext() {
    if (id === "filter") {
      history.push(`/mobi_tag_tinder/value${history.location.search}`);
    }

    if (id === "value") {
      history.push(`/mobi_tag_tinder/result${history.location.search}`);
    }
  }

  function onPrevious() {
    history.push(`/mobi_tag_tinder/filter${history.location.search}`);
  }

  function onTypologyValueChange(value) {
    const newParams = {
      typology: params.typology._id,
      category: params.category._id,
      subCategory: "",
      feature: "",
      value: "",
    };

    if (value !== -1 && params.value._id !== value) {
      const typology = tags.typologies.find((t) => t._id === value);
      const hash = btoa(JSON.stringify(typology));
      newParams.value = hash;
    }

    const queryString = new URLSearchParams(newParams).toString();
    history.push(`/mobi_tag_tinder/value?${queryString}`);
  }

  function onCategoryValueChange(value) {
    const newParams = {
      typology: params.typology._id,
      category: params.category._id,
      subCategory: "",
      feature: "",
      value: "",
    };

    if (value !== -1 && params.value._id !== value) {
      const category = tags.categories.find((t) => t._id === value);
      const hash = btoa(JSON.stringify(category));
      newParams.value = hash;
    }

    const queryString = new URLSearchParams(newParams).toString();
    history.push(`/mobi_tag_tinder/value?${queryString}`);
  }

  function onSubcategoryValueChange(value) {
    const newParams = {
      typology: params.typology._id,
      category: params.category._id,
      subCategory: "",
      feature: "",
      value: "",
    };

    if (value !== -1 && params.value._id !== value) {
      const subCategory = tags.subCategories.find((t) => t._id === value);
      const hash = btoa(JSON.stringify(subCategory));
      newParams.value = hash;
    }

    const queryString = new URLSearchParams(newParams).toString();
    history.push(`/mobi_tag_tinder/value?${queryString}`);
  }

  function onFeatureValueChange(value) {
    const newParams = {
      typology: params.typology._id,
      category: params.category._id,
      subCategory: params.subCategory._id,
      value: "",
    };

    if (value !== -1 && params.value._id !== value) {
      const feature = tags.features.find((t) => t._id === value);
      const hash = btoa(JSON.stringify(feature));
      newParams.value = hash;
    }

    const queryString = new URLSearchParams(newParams).toString();
    history.push(`/mobi_tag_tinder/value?${queryString}`);
  }

  return (
    <div className="w-100 h-100">
      <div className="configuration">
        <Switch>
          <Route exact path="/mobi_tag_tinder/filter">
            <>
              <div
                className="text-center mt-5 mb-2"
                style={{ fontSize: "2rem" }}
              >
                Filtros
              </div>
              <div>
                <div>Tipologia</div>
                <div className="d-flex">
                  <SelectSearch
                    disabled={tags.isLoading}
                    options={[{ name: "Nenhum", value: -1 }].concat(
                      tags.typologies.map((tag) => {
                        return { name: tag.name, value: tag._id };
                      })
                    )}
                    search={true}
                    closeOnSelect={true}
                    printOptions="on-focus"
                    value={params.typology._id}
                    onChange={onTypologyChange}
                    placeholder="Selecione..."
                  />
                  <MobiTagManager
                    parent={typology._id ? [typology] : []}
                    allowNested={true}
                    onChange={refreshTags}
                    category="typology"
                  />
                </div>
              </div>
              <div className="mt-2">
                <div>Categoria</div>
                <div className="d-flex">
                  <SelectSearch
                    disabled={!params.typology._id}
                    options={[{ name: "Nenhum", value: -1 }].concat(
                      tags.categories.map((tag) => {
                        return { name: tag.name, value: tag._id };
                      })
                    )}
                    search={true}
                    closeOnSelect={true}
                    printOptions="on-focus"
                    value={params.category._id}
                    onChange={onCategoryChange}
                    placeholder="Selecione..."
                  />
                  {typology._id ? (
                    <MobiTagManager
                      onChange={refreshTags}
                      parent={category._id ? [typology, category] : [typology]}
                      category="typology"
                      allowNested={true}
                      disabled={!!params.typology._id}
                    />
                  ) : (
                    <button disabled={true} className="tag-manager-button">
                      <i
                        className="fa fa-pencil-alt"
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </button>
                  )}
                </div>
              </div>
              <div className="mt-2">
                <div>Subcategoria</div>
                <div className="d-flex">
                  <SelectSearch
                    disabled={!params.category._id}
                    options={[{ name: "Nenhum", value: -1 }].concat(
                      tags.subCategories.map((tag) => {
                        return { name: tag.name, value: tag._id };
                      })
                    )}
                    search={true}
                    closeOnSelect={true}
                    printOptions="on-focus"
                    value={params.subCategory._id}
                    onChange={onSubCategoryChange}
                    placeholder="Selecione..."
                  />
                  {category._id ? (
                    <MobiTagManager
                      onChange={refreshTags}
                      parent={
                        subCategory._id
                          ? [typology, category, subCategory]
                          : [typology, category]
                      }
                      category="typology"
                      allowNested={true}
                      disabled={!!params.category._id}
                    />
                  ) : (
                    <button disabled={true} className="tag-manager-button">
                      <i
                        className="fa fa-pencil-alt"
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </button>
                  )}
                </div>
              </div>
            </>
          </Route>
          <Route path="/mobi_tag_tinder/value">
            <>
              <div
                className="text-center mt-5 mb-2"
                style={{ fontSize: "2rem" }}
              >
                Valor
              </div>
              {!params.typology._id ? (
                <div>
                  <div>Tipologia</div>
                  <div className="d-flex">
                    <SelectSearch
                      disabled={tags.isLoading}
                      options={[{ name: "Nenhum", value: -1 }].concat(
                        tags.typologies.map((tag) => {
                          return { name: tag.name, value: tag._id };
                        })
                      )}
                      search={true}
                      closeOnSelect={true}
                      printOptions="on-focus"
                      value={params.value._id}
                      onChange={onTypologyValueChange}
                      placeholder="Selecione..."
                    />
                  </div>
                </div>
              ) : null}
              {params.typology._id && !params.category._id ? (
                <div>
                  <div>Categoria</div>
                  <div className="d-flex">
                    <SelectSearch
                      disabled={tags.isLoading}
                      options={[{ name: "Nenhum", value: -1 }].concat(
                        tags.categories.map((tag) => {
                          return { name: tag.name, value: tag._id };
                        })
                      )}
                      search={true}
                      closeOnSelect={true}
                      printOptions="on-focus"
                      value={params.value._id}
                      onChange={onCategoryValueChange}
                      placeholder="Selecione..."
                    />
                  </div>
                </div>
              ) : null}
              {params.typology._id &&
              params.category._id &&
              !params.subCategory._id ? (
                <div>
                  <div>Sub Categoria</div>
                  <div className="d-flex">
                    <SelectSearch
                      disabled={tags.isLoading}
                      options={[{ name: "Nenhum", value: -1 }].concat(
                        tags.subCategories.map((tag) => {
                          return { name: tag.name, value: tag._id };
                        })
                      )}
                      search={true}
                      closeOnSelect={true}
                      printOptions="on-focus"
                      value={params.value._id}
                      onChange={onSubcategoryValueChange}
                      placeholder="Selecione..."
                    />
                  </div>
                </div>
              ) : null}
              {params.typology._id &&
              params.category._id &&
              params.subCategory._id ? (
                <div>
                  <div>Característica</div>
                  <div className="d-flex">
                    <SelectSearch
                      disabled={tags.isLoading}
                      options={[{ name: "Nenhum", value: -1 }].concat(
                        tags.features.map((tag) => {
                          return { name: tag.name, value: tag._id };
                        })
                      )}
                      search={true}
                      closeOnSelect={true}
                      printOptions="on-focus"
                      value={params.value._id}
                      onChange={onFeatureValueChange}
                      placeholder="Selecione..."
                    />
                  </div>
                </div>
              ) : null}
            </>
          </Route>
          <Route path="/mobi_tag_tinder/result">
            <TinderMobiTag params={{ ...params, typology }} />
          </Route>
        </Switch>

        {id !== "result" ? (
          <div className="w-100 d-flex mt-4">
            <Button theme="white" className="mr-auto" onClick={onPrevious}>
              Anterior
            </Button>
            <Button
              disabled={isNextButtonDisabled}
              className="ml-auto"
              onClick={onNext}
            >
              Próximo
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Configuration;

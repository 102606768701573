import React, { useEffect, useState, useContext } from "react";

import "@ladecora/react-image-lightbox/style.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import { Launcher } from "@ladecora/react-chat-elements";
import "@ladecora/react-chat-elements/lib/react-chat-elements.css";

import {
  sendMessage,
  readMessages,
  uploadFile,
  setChatDone,
} from "../../actions/message";
import { AuthContext } from "../common/AuthContext";

import { formatBytes } from "../../utils/sanitize";
import { download } from "../../utils/util";
import { MetadataContext } from "../metadata/MetadataContext";

const ProjectChat = ({
  senderId,
  onNewMessages,
  show,
  onChatToggle,
  chatOpened,
  messages,
  badgeCount,
  companyName,
  onDeleteMesssage,
  onUpdate,
  readOnly,
  reload,
}) => {
  const { user } = useContext(AuthContext);
  const { socket } = useContext(MetadataContext);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    socket.emit("select_room", {
      username: `${user._id}`,
      room: `${senderId}`,
    });

    socket.on("message", (data) => {
      if (data.username !== user._id) {
        reload();
      }
    });

    return () => {
      socket.off("message");
    };
  }, []);

  function onUserNewMessage(message) {
    message.deletable = false;
    const time = message.time;

    messages = [
      ...messages,
      {
        ...message,
        position: message.author === "me" ? "right" : "left",
        type: "text",
        text: message.data.text,
        deletable: !readOnly,
        date: message.time,
      },
    ];

    onNewMessages(messages);

    sendMessage(user._id, message.data.text, senderId, "preview").then(
      (res) => {
        onNewMessages([
          ...messages.filter((m) => m.time !== time),
          {
            ...res,
            author: res.type === "user" ? "them" : "me",
            type: "text",
            deletable: res.type === "system" && !readOnly,
            time: new Date(res.createdAt),
            text: res.text,
          },
        ]);
        // Send message to socket
        const data = {
          username: `${user._id}`,
          room: `${senderId}`,
          message: message.data.text,
        };
        socket.emit("message", data);
      }
    );

    readMessages(senderId, "user").then(() => {});
  }

  return show ? (
    <div className={`${readOnly ? "read-only" : ""}`}>
      <Launcher
        agentProfile={{
          teamName: companyName,
          imageUrl: "",
          headerButtonText: readOnly ? null : "Marcar como lido",
        }}
        headerButtonClick={
          readOnly
            ? null
            : () => {
                setChatDone(senderId).then(() => {
                  toast.success("Chat encerrado com sucesso.");
                  setIsOpen(false);
                  onChatToggle(false);
                });
              }
        }
        isOpen={isOpen || chatOpened}
        onMessageWasSent={onUserNewMessage}
        messageList={messages}
        markdown={true}
        onFilesSelected={(files) => {
          const promises = [];
          for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (file.size > 10000000) {
              toast.error("Arquivo não pode ser maior que 10mb");
              return;
            }
            const kind = ["image/png", "image/jpeg", "image/jpg"].includes(
              file.type
            )
              ? "photo"
              : "file";

            const newMessage = {
              author: "me",
              type: kind,
              deletable: false,
              date: new Date(),
              text: file.name,
              data: {
                height: 250,
                width: 250,
                size: formatBytes(file.size),
                uri: "",
                status: {
                  click: true,
                  loading: 0,
                  isSending: true,
                },
              },
            };
            promises.push(
              new Promise((success) => {
                const reader = new FileReader();
                reader.addEventListener(
                  "load",
                  function () {
                    newMessage.data.uri = reader.result;
                    success({
                      message: newMessage,
                      kind,
                      file,
                    });
                  },
                  false
                );

                if (file) {
                  reader.readAsDataURL(file);
                }
              })
            );
          }
          Promise.all(promises).then((results) => {
            const newMessages = messages.concat(
              results.map((result) => result.message)
            );

            results.forEach((result) => {
              const { file, message, kind } = result;
              uploadFile(file, senderId, kind, "system").then((res) => {
                message.data.status.click = false;
                message.deletable = true;
                message.data.status.isSending = false;
                message._id = res._id;
                message.data.uri = res.link;
                message.time = new Date(res.createdAt);
                message.link = res.link;
                message.createdAt = new Date(res.createdAt);

                // Send message to socket
                const data = {
                  username: `${user._id}`,
                  room: `${senderId}`,
                  message: "File Send",
                };
                socket.emit("message", data);

                onNewMessages(newMessages);
                onUpdate();
              });
            });
          });
        }}
        onDeleteClick={(a) => {
          onDeleteMesssage(a).then(() => {
            // Send message to socket
            const data = {
              username: `${user._id}`,
              room: `${senderId}`,
              message: a.text,
            };
            socket.emit("message", data);
          });
        }}
        onDownload={(message) => {
          download(message.link, message.text);
        }}
        newMessagesCount={badgeCount}
        showEmoji={false}
        placeholder="Escreva sua mensagem..."
        handleClick={() => {
          setIsOpen(!(isOpen || chatOpened));
          onChatToggle(!(isOpen || chatOpened));
        }}
      />{" "}
    </div>
  ) : null;
};

export default ProjectChat;

import React from "react";

function StepDescription({ text }) {
  return (
    <div
      className="user-activity__item__content"
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <p style={{ fontSize: "11px" }}>{text}</p>
    </div>
  );
}

StepDescription.propTypes = {};

export default StepDescription;

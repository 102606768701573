import { API } from "../config";
import { getCookie } from "../actions/auth";
import { requestWrapper } from "../utils/api";

export const getMetadata = () => {
  const token = getCookie("token");
  return requestWrapper(
    fetch(`${API}/metadata`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};

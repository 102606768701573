// import React from "react";
import { PhoneNumberUtil } from "google-libphonenumber";
const phoneUtil = PhoneNumberUtil.getInstance();

export function verifica_cpf_cnpj(valor) {
  // Garante que o valor é uma string
  valor = valor.toString();

  // Remove caracteres inválidos do valor
  valor = valor.replace(/[^0-9]/g, "");

  // Verifica CPF
  if (valor.length === 11) {
    return "CPF";
  }

  // Verifica CNPJ
  else if (valor.length === 14) {
    return "CNPJ";
  }

  // Não retorna nada
  else {
    return false;
  }
}

export function format(value, pattern) {
  var i = 0,
    v = value.toString();
  return pattern.replace(/#/g, () => v[i++]);
}

export function getNumbersOnlyFromString(value) {
  return value.replace(/[^0-9]/g, "");
}

export function validateCep(value) {
  value = getNumbersOnlyFromString(value);
  return value.length === 8;
}
export function valida_cpf_cnpj(valor) {
  // Verifica se é CPF ou CNPJ
  var valida = verifica_cpf_cnpj(valor);

  // Garante que o valor é uma string
  valor = valor.toString();

  // Remove caracteres inválidos do valor
  valor = valor.replace(/[^0-9]/g, "");

  // Valida CPF
  if (valida === "CPF") {
    // Retorna true para cpf válido
    return valida_cpf(valor);
  }

  // Valida CNPJ
  else if (valida === "CNPJ") {
    // Retorna true para CNPJ válido
    return valida_cnpj(valor);
  }

  // Não retorna nada
  else {
    return false;
  }
}

export function required(value) {
  return !!value;
}
export function emailValidation(value) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; /* eslint-disable-line no-useless-escape*/
  return re.test(String(value).toLowerCase());
}

export function phoneValidation(value) {
  try {
    value = phoneUtil.parse(value, "BR");
    return phoneUtil.isValidNumberForRegion(value, "BR");
  } catch (err) {
    return false;
  }
}

export const equalsTo = (key) => (value, values) => values[key] === value;

function valida_cpf(valor) {
  valor = valor.toString();

  valor = valor.replace(/[^0-9]/g, "");
  var digitos = valor.substr(0, 9);

  // Faz o cálculo dos 9 primeiros dígitos do CPF para obter o primeiro dígito
  var novo_cpf = calc_digitos_posicoes(digitos);

  // Faz o cálculo dos 10 dígitos do CPF para obter o último dígito
  novo_cpf = calc_digitos_posicoes(novo_cpf, 11);

  // Verifica se o novo CPF gerado é idêntico ao CPF enviado
  if (novo_cpf === valor) {
    // CPF válido
    return true;
  } else {
    // CPF inválido
    return false;
  }
}

function valida_cnpj(valor) {
  // Garante que o valor é uma string
  valor = valor.toString();

  // Remove caracteres inválidos do valor
  valor = valor.replace(/[^0-9]/g, "");

  // O valor original
  var cnpj_original = valor;

  // Captura os primeiros 12 números do CNPJ
  var primeiros_numeros_cnpj = valor.substr(0, 12);

  // Faz o primeiro cálculo
  var primeiro_calculo = calc_digitos_posicoes(primeiros_numeros_cnpj, 5);

  // O segundo cálculo é a mesma coisa do primeiro, porém, começa na posição 6
  var segundo_calculo = calc_digitos_posicoes(primeiro_calculo, 6);

  // Concatena o segundo dígito ao CNPJ
  var cnpj = segundo_calculo;

  // Verifica se o CNPJ gerado é idêntico ao enviado
  if (cnpj === cnpj_original) {
    return true;
  }

  // Retorna falso por padrão
  return false;
}

function calc_digitos_posicoes(digitos, posicoes = 10, soma_digitos = 0) {
  // Garante que o valor é uma string
  digitos = digitos.toString();
  for (var i = 0; i < digitos.length; i++) {
    soma_digitos = soma_digitos + digitos[i] * posicoes;
    posicoes--;
    if (posicoes < 2) {
      posicoes = 9;
    }
  }

  soma_digitos = soma_digitos % 11;
  if (soma_digitos < 2) {
    soma_digitos = 0;
  } else {
    soma_digitos = 11 - soma_digitos;
  }

  var cpf = digitos + soma_digitos;
  return cpf;
}

import React, { useContext } from "react";

import { FormCheckbox } from "shards-react";
import { MetadataContext } from "../../metadata/MetadataContext";

const TextItem = ({ text, enabled, toggle, id }) => {
  const { isAllowed } = useContext(MetadataContext);
  function onChange() {
    toggle(id);
  }
  return (
    <FormCheckbox
      toggle
      checked={enabled}
      onChange={
        isAllowed("admin-ladecora:project", "update:any") ? onChange : null
      }
      small
    >
      {text}
    </FormCheckbox>
  );
};

export default TextItem;

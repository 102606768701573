import React, { useEffect, useState, useContext } from "react";
import {
  Container,
  Row,
  Card,
  Col,
  Button,
  ButtonGroup,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  FormCheckbox,
} from "shards-react";
import { toast } from "react-toastify";
import PageTitle from "../components/common/PageTitle";
import { getEnterprises, updateEnterpriseById } from "../actions/enterprise";
import IfAllowed from "../components/common/IfAllowed";
import { MetadataContext } from "../components/metadata/MetadataContext";
import "react-toastify/dist/ReactToastify.min.css";
import FilteredTable from "../components/FilteredTable/index";
import { useHistory } from "react-router-dom";

const Enterprises = () => {
  const { scopedByCompany } = useContext(MetadataContext);
  const listAllEnterprisesScopedByCompany = scopedByCompany(getEnterprises);
  const history = useHistory();
  const [enterprises, setEnterprises] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [showModalConfig, setShowModalConfig] = useState(false);
  const [currentEnterprise, setCurrentEnterprise] = useState();
  const [enterpriseConfig, setEnterpriseConfig] = useState([
    { label: "Mostrar Preço", key: "showPrise", value: false },
    {
      label: "Mostrar Planta de layout",
      key: "showWorkPlanLayout",
      value: false,
    },
    {
      label: "Planta baixa de cobertura",
      key: "showCoverageFloorPlans",
      value: false,
    },
    {
      label: "Mostrar Planta de acabamentos",
      key: "showFinishingLayout",
      value: false,
    },
    {
      label: "Mostrar Distribuição de luminárias",
      key: "showLights",
      value: false,
    },
    { label: "Mostrar Detalhamentos", key: "showDetails", value: false },
    { label: "Mostrar Considerações", key: "showConsiderations", value: false },
    {
      label: "Mostrar Lista de Produtos",
      key: "showProductList",
      value: false,
    },
    { label: "Mostrar Guia de decoração", key: "showGuide", value: false },
  ]);

  const tableColumns = [
    {
      Header: "Nome",
      accessor: "name",
      className: "text-center",
      Cell: (row) => <span>{row.original.name}</span>,
    },

    {
      Header: "Company",
      accessor: "company",
      className: "text-center",
      Cell: (row) => <span>{row.original.company}</span>,
    },

    {
      Header: "Respostas",
      accessor: "actions",
      sortable: false,
      maxWidth: 90,
      Cell: (row) => (
        <>
          <ButtonGroup size="sm" className="d-table mx-auto">
            <Button
              size="sm"
              outline
              theme={row.original.highlight ? "success" : "dark"}
              onClick={() => {
                window.open(
                  `/interprise-info/${row.original._id}?name=${row.original.name}`
                );
              }}
            >
              <i className="material-icons">&#xe86d;</i>
            </Button>
          </ButtonGroup>
        </>
      ),
    },
    {
      Header: "Questionário",
      accessor: "actions",
      sortable: false,
      maxWidth: 90,
      Cell: (row) => (
        <>
          <ButtonGroup size="sm" className="d-table mx-auto">
            <Button
              outline
              theme={row.original.highlight ? "success" : "dark"}
              onClick={() => {
                window.open("/enterprise-questions/" + row.original._id);
              }}
            >
              <i className="fas fa-book" />
            </Button>
          </ButtonGroup>
        </>
      ),
    },
    {
      Header: "Projetos",
      accessor: "actions",
      sortable: false,
      maxWidth: 90,
      Cell: (row) => (
        <>
          <ButtonGroup size="sm" className="d-table mx-auto">
            <Button
              outline
              theme={row.original.highlight ? "success" : "dark"}
              onClick={() => {
                window.open("/projects?enterprise=" + row.original._id);
              }}
            >
              <i className="material-icons">&#xe86d;</i>
            </Button>
          </ButtonGroup>
        </>
      ),
    },
    {
      Header: "Configurações",
      accessor: "actions",
      sortable: false,
      maxWidth: 90,
      Cell: (row) => (
        <>
          <ButtonGroup size="sm" className="d-table mx-auto">
            <Button
              outline
              theme={row.original.highlight ? "success" : "dark"}
              onClick={() => {
                setCurrentEnterpriseFn(row.original._id);
                setShowModalConfig(true);
              }}
            >
              <i className="fas fa-cog" />
            </Button>
          </ButtonGroup>
        </>
      ),
    },
  ];

  useEffect(() => {
    reload();
  }, []);

  function reload() {
    setIsLoading(true);
    return listAllEnterprisesScopedByCompany()
      .then((data) => {
        setEnterprises(data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }

  function onNewEnterpriseClick() {
    history.push("/enterprise");
  }

  const handleEnterpriseConfig = (param) => {
    const tempEnterpriseConfig = [...enterpriseConfig];

    tempEnterpriseConfig.map((elem, idx) => {
      if (elem.key === param) {
        let prevalue = tempEnterpriseConfig[idx].value;
        tempEnterpriseConfig[idx].value = !prevalue;
      }
    });
    setEnterpriseConfig(tempEnterpriseConfig);
  };

  const setCurrentEnterpriseFn = (id) => {
    let tempCurrentEnterprise = [];
    let tempEnterpriseConfig = [...enterpriseConfig];

    enterprises.map((elem) => {
      if (elem._id === id) {
        tempCurrentEnterprise = elem;
      }
    });

    const {
      showPrise,
      showWorkPlanLayout,
      showFinishingLayout,
      showLights,
      showDetails,
      showConsiderations,
      showProductList,
      showGuide,
      showCoverageFloorPlans,
    } = tempCurrentEnterprise;

    tempEnterpriseConfig.map((elem, idx) => {
      switch (elem.key) {
        case "showPrise":
          tempEnterpriseConfig[idx].value = showPrise;
          break;
        case "showWorkPlanLayout":
          tempEnterpriseConfig[idx].value = showWorkPlanLayout;
          break;
        case "showCoverageFloorPlans":
          tempEnterpriseConfig[idx].value = showCoverageFloorPlans;
          break;
        case "showFinishingLayout":
          tempEnterpriseConfig[idx].value = showFinishingLayout;
          break;
        case "showLights":
          tempEnterpriseConfig[idx].value = showLights;
          break;
        case "showDetails":
          tempEnterpriseConfig[idx].value = showDetails;
          break;
        case "showConsiderations":
          tempEnterpriseConfig[idx].value = showConsiderations;
          break;
        case "showProductList":
          tempEnterpriseConfig[idx].value = showProductList;
          break;
        case "showGuide":
          tempEnterpriseConfig[idx].value = showGuide;
          break;
        default:
          tempEnterpriseConfig[idx].value = true;
      }
    });
    setCurrentEnterprise(tempCurrentEnterprise);
    setEnterpriseConfig(tempEnterpriseConfig);
  };

  const saveConfigEnterprise = () => {
    const tempEnterpreises = [...enterprises];
    const tempcurrentEnterprise = JSON.parse(JSON.stringify(currentEnterprise));

    enterpriseConfig.map((elem) => {
      switch (elem.key) {
        case "showPrise":
          tempcurrentEnterprise.showPrise = elem.value;
          break;
        case "showWorkPlanLayout":
          tempcurrentEnterprise.showWorkPlanLayout = elem.value;
          break;
        case "showFinishingLayout":
          tempcurrentEnterprise.showFinishingLayout = elem.value;
          break;
        case "showLights":
          tempcurrentEnterprise.showLights = elem.value;
          break;
        case "showDetails":
          tempcurrentEnterprise.showDetails = elem.value;
          break;
        case "showCoverageFloorPlans":
          tempcurrentEnterprise.showCoverageFloorPlans = elem.value;
          break;
        case "showConsiderations":
          tempcurrentEnterprise.showConsiderations = elem.value;
          break;
        case "showProductList":
          tempcurrentEnterprise.showProductList = elem.value;
          break;
        case "showGuide":
          tempcurrentEnterprise.showGuide = elem.value;
          break;
      }
    });

    tempEnterpreises.map((elem, idx) => {
      if (elem._id === tempcurrentEnterprise._id) {
        tempEnterpreises[idx] = tempcurrentEnterprise;
      }
    });

    setEnterprises(tempEnterpreises);

    setIsBtnLoading(true);
    updateEnterpriseById(tempcurrentEnterprise)
      .then((res) => {
        setIsBtnLoading(false);
        toast.success(res.msg, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch(() => {
        setIsBtnLoading(false);
      });
  };
  return (
    <Container fluid className="main-content-container px-4">
      <Row className="page-header py-4">
        <Col md="8">
          <PageTitle
            title="Banco de projetos"
            subtitle="Visão Geral"
            className="text-sm-left mb-3"
          />
        </Col>
      </Row>
      <IfAllowed resource="admin-ladecora:enterprises:edit-create">
        {/* <IfAllowed resource="admin-ladecora:enterprises:edit-create"> */}
        <Button
          className="mb-2"
          onClick={onNewEnterpriseClick}
          id="users-button-add"
        >
          Adicionar empreendimento
        </Button>
      </IfAllowed>

      <Card>
        <CardBody>
          <FilteredTable
            list={enterprises}
            columns={tableColumns}
            isLoading={isLoading}
            customFilters={[]}
            config={{
              pageSizeOptions: [5, 10, 15, 20, 30],
              searchableColumns: ["name", "company"],
              pageSize: 10,
            }}
          />
        </CardBody>
      </Card>

      <Modal
        open={showModalConfig}
        toggle={() => {
          setShowModalConfig(false);
        }}
      >
        <ModalHeader
          toggle={() => {
            setShowModalConfig(false);
          }}
        >
          {showModalConfig && currentEnterprise?.name}
        </ModalHeader>
        <ModalBody>
          {enterpriseConfig?.map((elem, idx) => (
            <FormCheckbox
              key={idx}
              toggle
              checked={elem?.value}
              onChange={() => {
                handleEnterpriseConfig(elem?.key);
              }}
              small
            >
              <strong>{elem?.label}</strong>
            </FormCheckbox>
          ))}
          <div className="d-flex justify-content-center mt-3">
            <Button disabled={isBtnLoading} onClick={saveConfigEnterprise}>
              Salvar
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </Container>
  );
};
export default Enterprises;

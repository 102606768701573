import React, { useState, useCallback, useEffect } from "react";
import { Button, Row, Col, FormTextarea } from "shards-react";
import DropzoneFiles from "../../common/DropzoneFiles";
import { toast } from "react-toastify";
import { updateChecklist } from "../../../actions/projects";
import { uploadFile } from "../../../actions/file";
import { debounce } from "../../../utils/throttle";
import { getItemByIndex, setItem } from "../../../services/db";

function AddingItem({ checklist, setCheckList, projectId, type }) {
  const [description, setDescription] = useState("");
  const [picture, setPicture] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const onTextDraftUpdate = useCallback(
    debounce((text) => {
      updateProjectChecklistCache(text);
    }, 800),
    []
  );

  function updateProjectChecklistCache(text) {
    getItemByIndex("projects", "by_id", projectId).then((item) => {
      let newItem = {
        [type]: text,
        _id: projectId,
        timestamp: new Date().getTime(),
      };
      if (item) {
        newItem = { ...item, ...newItem };
      }
      setItem("projects", newItem);
    });
  }

  useEffect(() => {
    getItemByIndex("projects", "by_id", projectId).then((projectCache) => {
      if (projectCache) {
        if (projectCache[type]) {
          setDescription(projectCache[type]);
        }
      }
    });
  }, []);

  const addingItem = () => {
    if (description.length > 0 || picture.length) {
      setIsLoading(true);

      updateProjectChecklistCache("");

      (picture.length
        ? uploadFile(
            picture[0].file,
            `projects/${projectId}/checklist`,
            picture[0].kind
          )
        : Promise.resolve()
      ).then((uploadedFile) => {
        const newItem = {
          description: description,
          done: false,
          date: new Date(),
        };
        newItem.file = uploadedFile;
        updateChecklist(projectId, type, [...checklist, newItem])
          .then((data) => {
            setIsLoading(false);
            setCheckList([...checklist, newItem]);
            toast.success(data.msg, {
              autoClose: 5000,
            });
            setDescription("");
            setPicture([]);
          })
          .catch((err) => {
            setIsLoading(false);
            if (err.message === "Failed to fetch") {
              toast.error("Ocorreu um problema na conexão com a internet.");
            } else {
              toast.error(err.message);
            }
          });
      });
    }
  };
  return (
    <>
      <Row>
        <Col>
          <FormTextarea
            rows="3"
            value={description}
            className="form-control"
            placeholder="Adicionar item checklist"
            onChange={(e) => {
              onTextDraftUpdate(e.target.value);

              setDescription(e.target.value);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col className="mt-2">
          <DropzoneFiles
            isMultiple={false}
            items={picture}
            onChange={(files) => {
              files = files.map((file) => {
                file.isLoading = false;
                return file;
              });
              setPicture(files);
            }}
            isLoading={isLoading}
          />
        </Col>
      </Row>
      <Row>
        <Col className="pt-3">
          <Button
            outline
            size="sm"
            theme="primary"
            className="mb-2 mr-1"
            onClick={addingItem}
            disabled={isLoading || (!description && !picture.length)}
          >
            {isLoading ? "Adicionando item..." : "Adicionar item"}
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default AddingItem;

import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Card,
  FormInput,
  Col,
  CardHeader,
  Button,
  FormSelect,
  Modal,
  ModalBody,
  ModalHeader,
} from "shards-react";
import PageTitle from "../common/PageTitle";
import CompleteForm from "../forms/form/CompleteForm";
import Tree from "../forms/logical-tree/Tree";
import {
  updateProjectTemplate,
  createProjectTemplate,
  getProjectTemplateById,
} from "../../actions/projectTemplate";
import { changeNodeAtPath } from "react-sortable-tree";
import { saveImage } from "../../actions/projectTemplate";
import { BUCKET_URL } from "../../config";
import { getFileExtension, isExtensionValid } from "../../utils/util";

import Lightbox from "@ladecora/react-image-lightbox";
import "@ladecora/react-image-lightbox/style.css";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { MetadataContext } from "../metadata/MetadataContext";
import ConfirmationButton from "../common/ConfirmationButton";

const ProjectTemplateConfig = ({ projectTemplateId }) => {
  const history = useHistory();
  const { metadata, company } = useContext(MetadataContext);

  const [isLoading, setIsLoading] = useState(true);
  //TODO
  // eslint-disable-next-line
  const [editForm, setEditForm] = useState(false);
  const [projectTemplate, setProjectTemplate] = useState({
    status: "test",
    price: "",
    name: "",
    thumbnail: null,
    hash: "",
    numberOfEnvironments: "",
    treeData: [],
    company: !projectTemplateId
      ? company === "all"
        ? "ladecora"
        : company
      : "ladecora",
  });
  const [treeData, setTreeData] = useState([]);
  const [hiddenUpdateButton, sethiddenUpdateButton] = useState(true);
  const [hiddenAddButton, sethiddenAddButton] = useState(false);
  const [currentNodeToUpdate, setCurrentNodeToUpdate] = useState();
  const [inputList, setInputList] = useState([]);
  const [lightbox, setLightbox] = useState({ open: false, img: "" });
  const [values, setValues] = useState({
    showMultipleForm: false,
    showInternalText: false,
    type: "text",
    label: "",
    internalText: "",
    children: [],
    isMultiselect: false,
  });
  const [showTreeError, setShowTreeError] = useState(false);

  useEffect(() => {
    if (projectTemplateId) {
      getProjectTemplateById(projectTemplateId)
        .then((data) => {
          setIsLoading(false);
          projectTemplate.name = data.name;
          if (data.template) {
            projectTemplate.template = data.template;
            setTreeData(data.template);
          }
          projectTemplate.status = data.status;
          projectTemplate.price = data.price;
          projectTemplate.thumbnail = data.thumbnail;
          projectTemplate.hash = data.hash;
          projectTemplate.company = data.company;
          projectTemplate.numberOfEnvironments = data.numberOfEnvironments
            ? data.numberOfEnvironments
            : 1;
          setProjectTemplate({ ...projectTemplate });
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [projectTemplateId]);

  const addFormCallback = (values) => {
    sethiddenUpdateButton(true);
    sethiddenAddButton(false);
    setTreeData((treeData) => [
      ...treeData,
      {
        title: values.label,
        type: values.type,
        input_list: values.inputList,
        internalText: values.internalText,
      },
    ]);
  };

  const getNodeKey = ({ treeIndex }) => treeIndex;
  const updateFormCallback = (values) => {
    let path = currentNodeToUpdate.path;

    setTreeData(
      changeNodeAtPath({
        treeData: treeData,
        path,
        expandParent: true,
        getNodeKey,
        newNode: {
          title: values.label,
          type: values.type,
          input_list: values.inputList,
          children: values.children,
          internalText: values.internalText,
          isMultiselect: values.isMultiselect,
        },
      })
    );
    sethiddenUpdateButton(true);
    sethiddenAddButton(false);
  };

  const saveProject = () => {
    if (projectTemplate.name) {
      let statusVerificado = projectTemplate.status
        ? projectTemplate.status
        : "enabled";
      let priceVerificado = projectTemplate.price ? projectTemplate.price : 300;

      if (!validate()) {
        return;
      }

      const payload = {
        name: projectTemplate.name,
        template: treeData,
        status: statusVerificado,
        price: priceVerificado,
        thumbnail: projectTemplate.thumbnail,
        company: projectTemplate.company,
        numberOfEnvironments: projectTemplate.numberOfEnvironments,
      };

      if (projectTemplateId) {
        updateProjectTemplate(projectTemplateId, payload).then(() => {
          toast.success("Salvo com sucesso", { autoClose: 3000 });
        });
      } else {
        createProjectTemplate(payload).then((data) => {
          toast.success("Questionário criado com sucesso", { autoClose: 3000 });
          setTimeout(
            () => history.replace(`project-template?id=${data.id}`),
            1000
          );
        });
      }
    }
  };

  const completeFormInfo = (values) => {
    let children = values.node.children ? values.node.children : [];
    cleanForm();
    sethiddenUpdateButton(false);
    sethiddenAddButton(true);
    if (values.node.input_list.length && values.node.type === "multiple") {
      setInputList([...values.node.input_list]);
      setValues({
        ...values,
        type: values.node.type,
        label: values.node.title,
        children: children,
        showMultipleForm: true,
        showInternalText: false,
        internalText: values.node.internalText,
        isMultiselect: values.node.isMultiselect,
      });
    } else {
      let resultInternal = values.node.type === "internalText" ? true : false;
      if (resultInternal) {
        if (!values.node.input_list.length) {
          setInputList([{ label: "", image_path: "", action: "" }]);
        } else {
          setInputList([...values.node.input_list]);
        }
      }
      setValues({
        ...values,
        type: values.node.type,
        label: values.node.title,
        children: children,
        showMultipleForm: false,
        showInternalText: resultInternal,
        internalText: values.node.internalText,
      });
    }
    setCurrentNodeToUpdate(values);
  };

  const cleanForm = () => {
    setValues({
      label: "",
      type: "text",
      showMultipleForm: false,
      showInternalText: false,
      internalText: "",
    });
    setInputList([]);
  };

  const updateStatus = (e) => {
    projectTemplate.status = e.target.value;
    setProjectTemplate({ ...projectTemplate });
  };
  const updatePrice = (e) => {
    projectTemplate.price = parseFloat(e.target.value);
    setProjectTemplate({ ...projectTemplate });
  };

  const updateNumberOfEnvironments = (e) => {
    projectTemplate.numberOfEnvironments = parseInt(e.target.value);
    setProjectTemplate({ ...projectTemplate });
  };

  const updateThumbnail = (e) => {
    if (isExtensionValid(getFileExtension(e.target.files[0].name))) {
      saveImage(e.target.files[0], projectTemplate.hash).then((data) => {
        projectTemplate.thumbnail = data.key;
        setProjectTemplate({ ...projectTemplate });
      });
      toast.dismiss();
      toast.success("Upload feito com sucesso!", { autoClose: 1500 });
    } else {
      toast.error(
        "A extensão do arquivo não é permitido, Favor avisar a equipe de desenvolvimento.",
        { autoClose: 5000 }
      );
    }
  };

  const validate = () => {
    function concatChildren(item, children = []) {
      children.push(item);
      if (item.children && item.children.length) {
        item.children.forEach((child) => {
          concatChildren(child, children);
        });
      }
      return children;
    }

    const flatList = treeData.reduce((acc, cur) => {
      const children = concatChildren(cur);

      acc = acc.concat(children);

      return acc;
    }, []);

    const invalidQuestions = flatList.reduce((acc, item, i) => {
      acc = acc.concat(
        item.input_list
          .filter((subItem) => subItem.action === i + "")
          .map((subItem) => {
            return {
              action: subItem.action,
              answer: subItem,
            };
          })
      );
      return acc;
    }, []);
    setShowTreeError(invalidQuestions);
    const isValid = !invalidQuestions.length;
    if (isValid) toast.success("Validado com sucesso");
    return !invalidQuestions.length;
  };

  const onDuplicateClick = () => {
    let priceVerificado = projectTemplate.price ? projectTemplate.price : 300;

    if (!validate()) {
      return;
    }
    const payload = {
      name: `${projectTemplate.name} - Duplicado`,
      template: treeData,
      status: "disabled",
      price: priceVerificado,
      thumbnail: projectTemplate.thumbnail,
      company: projectTemplate.company,
      numberOfEnvironments: projectTemplate.numberOfEnvironments,
    };
    setIsLoading(true);
    createProjectTemplate(payload).then((data) => {
      toast.success("Questionário duplicado com sucesso", { autoClose: 3000 });
      setTimeout(() => history.push(`project-template?id=${data.id}`), 1000);
    });
  };

  const propsTree = { treeData, setTreeData, completeFormInfo };
  return (
    <Container fluid className="main-content-container px-4">
      {lightbox.open && (
        <Lightbox
          mainSrc={lightbox.img}
          reactModalStyle={{ overlay: { zIndex: 10000 } }}
          onCloseRequest={() => setLightbox({ ...lightbox, open: false })}
        />
      )}
      <Row className="page-header py-4">
        <Col md="6">
          <PageTitle
            title="Formulários"
            subtitle="Visão Geral"
            className="text-sm-left mb-3"
          />
        </Col>
        <Col md="5" className="col d-flex align-items-center" />
        <Col md="1" className="d-flex" />
      </Row>

      <Row>
        <Col lg="6" className="mb-4">
          <Card small>
            <CardHeader className="border-bottom">
              {projectTemplateId ? (
                <Row>
                  <Col>
                    <ConfirmationButton
                      disabled={isLoading}
                      className="float-right"
                      onClick={onDuplicateClick}
                    >
                      Duplicar
                    </ConfirmationButton>
                  </Col>
                </Row>
              ) : null}

              <Row>
                <Col md="12" className="form-group">
                  <div className="label">Nome do Ambiente:</div>
                  <FormInput
                    placeholder="Digite o nome do ambiente"
                    disabled={isLoading}
                    value={projectTemplate.name}
                    onChange={(e) => {
                      projectTemplate.name = e.target.value;
                      setProjectTemplate({ ...projectTemplate });
                      // setProjectName(e.target.value);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="12" className="form-group">
                  <div className="label">Contexto:</div>
                  <FormSelect
                    disabled={isLoading}
                    value={projectTemplate.company}
                    onChange={(e) => {
                      projectTemplate.company = e.target.value;
                      setProjectTemplate({ ...projectTemplate });
                    }}
                  >
                    {metadata.companies.map((company, idx) => (
                      <option
                        key={`${company.label}_${idx}`}
                        value={company.label}
                      >
                        {company.name}
                      </option>
                    ))}
                  </FormSelect>
                </Col>
              </Row>
              <Row>
                <Col md="4" className="form-group">
                  <div className="label">Status:</div>
                  <FormSelect
                    disabled={isLoading}
                    value={projectTemplate.status}
                    onChange={updateStatus}
                  >
                    <option value="enabled">Ativo</option>
                    <option value="disabled">Inativo</option>
                    <option value="test">Teste</option>
                    <option value="perfil">Questionário Perfil</option>
                  </FormSelect>
                </Col>
                <Col md="4" className="form-group">
                  <div className="label">Valor do Projeto:</div>
                  <FormInput
                    disabled={isLoading}
                    placeholder="300"
                    value={projectTemplate.price}
                    type="number"
                    onChange={updatePrice}
                  />
                </Col>
                <Col md="4" className="form-group">
                  <div className="label">Ambientes:</div>
                  <FormInput
                    disabled={isLoading}
                    placeholder="1"
                    value={projectTemplate.numberOfEnvironments}
                    type="number"
                    onChange={updateNumberOfEnvironments}
                  />
                </Col>
              </Row>
              {projectTemplateId && (
                <Row>
                  <Col>
                    <Row>
                      <Col className="mb-2">Capa do questionario:</Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="custom-file mb-3">
                          <input
                            type="file"
                            disabled={isLoading}
                            className="custom-file-input"
                            name="thumbnail"
                            onChange={(e) => updateThumbnail(e)}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="customFile2"
                          >
                            {projectTemplate.thumbnail
                              ? projectTemplate.thumbnail.replace(
                                  projectTemplate.hash + "/",
                                  ""
                                )
                              : "Faça o upload do thumbnail"}
                          </label>
                          {projectTemplate.thumbnail ? (
                            <button
                              type="button"
                              className="transparent"
                              onClick={() => {
                                setLightbox({
                                  img:
                                    BUCKET_URL +
                                    "/" +
                                    projectTemplate.thumbnail,
                                  open: true,
                                });
                              }}
                            >
                              Ver imagem
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </CardHeader>
            {projectTemplateId && (
              <CompleteForm
                callBackAddForm={addFormCallback}
                callBackUpdateForm={updateFormCallback}
                projectHash={projectTemplate.hash}
                editForm={editForm}
                inputList={inputList}
                setInputList={setInputList}
                values={values}
                setValues={setValues}
                hiddenUpdateButton={hiddenUpdateButton}
                hiddenAddButton={hiddenAddButton}
                setLightbox={setLightbox}
              />
            )}
          </Card>
        </Col>
        <Col lg="6" className="mb-4">
          <Card small>
            <CardHeader className="border-bottom">
              <Row>
                <Col md="6">
                  <h6 className="m-0">Fluxo lógico</h6>
                </Col>
                <Col md="6">
                  <Button
                    className="mr-2"
                    theme="warning"
                    style={{ color: "white" }}
                    onClick={() => validate()}
                    disabled={isLoading}
                  >
                    Validar
                  </Button>
                  <Button onClick={() => saveProject()} disabled={isLoading}>
                    SALVAR VERSÃO
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <Tree {...propsTree} />
          </Card>
        </Col>
      </Row>
      {showTreeError && showTreeError.length ? (
        <Modal
          size="md"
          modalContentClassName="card card-small"
          open={showTreeError.length}
          toggle={() => {
            if (showTreeError.length) {
              setShowTreeError([]);
            }
          }}
        >
          <ModalHeader closeAriaLabel>
            Existem questões com referências inválidas
          </ModalHeader>
          <ModalBody>
            {showTreeError.map((e, i) => {
              return (
                <div key={i} className="mb-2" style={{ color: "red" }}>
                  Questão número {e.action}: {e.answer.label}
                  {showTreeError.length - 1 !== i ? <hr /> : null}
                </div>
              );
            })}
          </ModalBody>
        </Modal>
      ) : null}
    </Container>
  );
};
export default ProjectTemplateConfig;

import { API } from "../config";
import { requestWrapper } from "../utils/api";
import { getCookie } from "../actions/auth";

export const uploadFile = (file, path, kind) => {
  const token = getCookie("token");
  const formData = new FormData();

  if (file) {
    formData.append("file", file);
    formData.append("path", path);
    formData.append("kind", kind);
  } else {
    throw new Error("No file");
  }

  return requestWrapper(
    fetch(`${API}/file/upload`, {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        "x-access-token": token,
      },
    })
  );
};
